import { Box, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import type { MeasurementTaskModel } from "@app/domain/api/measurementTask.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { methodKeyToName } from "@app-components/modals/task/mapping.ts";
import { SampleHazards } from "@app-components/sample/SampleHazards.tsx";
import { SampleProperties } from "@app-components/sample/SampleProperties.tsx";

import {
    Property,
    PropertyLabel,
    PropertyValue,
} from "@mt-components/Input/Property";
import { Divider } from "@mt-components/Layout/Divider.tsx";

import { YesNoIndicator } from "src/packages/components/Display/YesNoIndicator";

const config = {
    true: { color: "green", label: "Yes", value: true },
    false: { color: "gray", label: "No", value: false },
};

const measurementTypes: MeasurementTaskModel["method"][] = [
    "tspdf",
    "hrxrpd",
    "saxs",
];

type Props = {
    hideHazards?: boolean;
    sample: SampleTasksModel;
    measurementGroup: MeasurementGroupModel;
};

export const SampleInfo = ({ hideHazards, sample }: Props) => {
    return (
        <Box display="flex" flexDirection="column" overflowY="auto">
            <SampleProperties sample={sample} />

            {!hideHazards && (
                <>
                    <Divider />
                    <SampleHazards sample={sample} />
                </>
            )}

            <Divider></Divider>
            <Text fontSize="12px" color="gray.600">
                Measurements
            </Text>
            {measurementTypes.map((h) => {
                return (
                    <Property key={h}>
                        <PropertyLabel fontSize="14px" w="250px">
                            {methodKeyToName[h].short}
                        </PropertyLabel>
                        <PropertyValue
                            color="gray.800"
                            fontSize="14px"
                            w="100px"
                        >
                            <YesNoIndicator<"true" | "false">
                                config={config}
                                value={
                                    sample.tasks[h]?.taskId ? "true" : "false"
                                }
                            />
                        </PropertyValue>
                    </Property>
                );
            })}
        </Box>
    );
};
