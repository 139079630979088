import type { ReactNode } from "react";
import type { Props as SelectProps } from "react-select";
import Select from "react-select";

import { selectComponents } from "@mt-components/Input/Select/components.tsx";
import { dfo } from "@mt-components/Input/Select/helpers.ts";

import { useTheme } from "@mt-design/useTheme.ts";

type SingleSelectProps<Option> = {
    isDisabled?: boolean;
    isClearable?: boolean;
    control?: {
        width?: string | number;
    };
    placeholder?: string;
    value?: Option;
    onChange: (value?: Option | null) => void;
    options: Option[];
    formatOptionLabel?: SelectProps<Option>["formatOptionLabel"];
};

export type BaseOption = {
    value: string | null;
    label: ReactNode;
    rawLabel: string;
};

export function SingleSelect<Option extends BaseOption>({
    isDisabled,
    isClearable,
    control,
    placeholder,
    value,
    onChange,
    options,
    formatOptionLabel,
}: SingleSelectProps<Option>) {
    const theme = useTheme();

    return (
        <Select<Option, false>
            isDisabled={isDisabled}
            isClearable={isClearable || false}
            placeholder={placeholder}
            styles={{
                container: (base) => ({
                    ...base,
                    height: 36,
                    fontSize: 14,
                    zIndex: 10,
                    borderRadius: 12,
                }),
                control: (base) => ({
                    ...base,
                    minWidth: 220,
                    width: control?.width,
                    zIndex: 200,
                    height: "36px",
                    maxHeight: "36px",
                    minHeight: "unset",
                    borderRadius: 6,
                    borderColor: theme.colors.blackAlpha["300"],
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }),
            }}
            components={selectComponents}
            maxMenuHeight={250}
            filterOption={dfo}
            formatOptionLabel={formatOptionLabel}
            value={value}
            options={options}
            onChange={onChange}
        />
    );
}
