import type { FC, PropsWithChildren } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { Crumbs } from "@mt-components/Crumbs/Crumbs.tsx";
import type { Action } from "@mt-components/Layout/Actions.tsx";
import { Actions } from "@mt-components/Layout/Actions.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Title } from "@mt-components/Layout/Title.tsx";

type PageHeaderProps = {
    title?: string;
    crumbs?: Crumb[];
    subtitle?: string;
    actions?: Action[];
    topActions?: Action[];
    menuActions?: Action[];
    actionsId?: string;
} & BoxProps;

export const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = ({
    children,
    crumbs = [],
    actionsId,
    title,
    subtitle,
    actions,
    topActions,
    menuActions,
    ...props
}) => (
    <Box>
        <Box
            width="100%"
            {...props}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            alignItems="center"
        >
            <Box>
                <Box mb="12px">
                    {Boolean(crumbs.length) && <Crumbs items={crumbs} />}
                </Box>
                {!!title && <Title title={title} />}
                {subtitle ? (
                    <Text
                        mb="12px"
                        whiteSpace="wrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        color="gray.500"
                    >
                        {subtitle}
                    </Text>
                ) : null}
            </Box>
            <Actions actions={topActions} />
        </Box>
        <Box>
            <Actions
                id={actionsId}
                actions={actions}
                menuActions={menuActions}
                maxH="32px"
                minH="32px"
            />
        </Box>
        <Box>{children ? <Divider /> : null}</Box>
    </Box>
);
