import type { FC, MouseEvent } from "react";
import { memo } from "react";
import { useLocalStorage } from "react-use";

import type Plotly from "plotly.js";
import { isNotNil } from "ramda";
import type { Promisable } from "type-fest";
import { v4 as uuid } from "uuid";

import {
    Box,
    Card,
    CardBody,
    CardHeader,
    Center,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import { routes } from "@app/Routes/routes.ts";
import { DatapickerUser } from "@app-components/widgets/Datapicker/Datapicker.user.tsx";

import { AlphaBanner } from "@mt-components/AlphaBanner.tsx";
import type { Action } from "@mt-components/Layout/Actions.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

type PlotCardProps = {
    onDelete: (plotId: string) => Promisable<void>;
    title: string;
    orgId: string;
    plotId: string;
};
const PlotCard: FC<PlotCardProps> = ({ title, orgId, plotId, onDelete }) => {
    const navigate = useNavigate();

    const goToPlot = (e: MouseEvent) => {
        e.stopPropagation();
        return navigate({
            to: routes.user.plotDetails.url,
            params: {
                orgId,
                plotId,
            },
        });
    };
    return (
        <Card w="190px" pb="24px">
            <CardHeader display="flex" justifyContent="space-between">
                <Text as="h4" textStyle="h5" size="md">
                    {title.length ? title : "Untitled"}
                </Text>
                <Menu key="chart-menu">
                    <MenuButton
                        onClick={(e) => e.stopPropagation()}
                        aria-label="Menu"
                        colorScheme="gray"
                        as={IconButton}
                        icon={<Icon.Menu />}
                    />
                    <MenuList fontSize="md">
                        <MenuItem onClick={() => onDelete(plotId)}>
                            Remove
                        </MenuItem>
                    </MenuList>
                </Menu>
            </CardHeader>
            <CardBody
                cursor="pointer"
                aria-roledescription="button"
                onClick={goToPlot}
            >
                <Center>
                    <Icon.AnalyzeImage width="90px" />
                </Center>
            </CardBody>
        </Card>
    );
};

PlotCard.displayName = "PlotCard";

export type Plot = {
    id: string;
    name: string;
    artifacts: string[];
    orgId: string;
    plot: {
        data?: Plotly.PlotData[];
        layout?: Partial<Plotly.Layout>;
    };
};

export type PlotDB = Record<string, Plot | undefined>;
export const PlotsPage: FC = memo(() => {
    const { currentOrg } = useOrgContext();
    const navigate = useNavigate();
    const [plotDB, setPlotDB] = useLocalStorage<PlotDB>(
        LocalStorageKeys.plots,
        {},
    );
    const createPlotDisclosure = useDisclosure();
    const plots = plotDB ? Object.values(plotDB).filter(isNotNil) : [];

    const actions: Action[] = [
        {
            id: "create-plot",
            label: "Create",
            onClick: createPlotDisclosure.onToggle,
            isTruncated: true,
            hasTooltip: true,
            isPrimary: true,
        },
    ];

    const onClose = (params: { artifacts: string[]; orgId: string }) => {
        const plot: Plot = {
            id: uuid(),
            name: "Untitled",
            artifacts: params.artifacts,
            orgId: params.orgId,
            plot: {},
        };

        setPlotDB((prev) => {
            return {
                ...prev,
                [plot.id]: plot,
            };
        });
        createPlotDisclosure.onClose();
        void navigate({
            to: routes.user.plotDetails.url,
            params: {
                orgId: params.orgId,
                plotId: plot.id,
            },
        });
    };

    const onDelete = (plotId: string) => {
        setPlotDB((prevDb) => {
            return {
                ...prevDb,
                [plotId]: undefined,
            };
        });
    };

    return (
        <>
            {Boolean(createPlotDisclosure.isOpen) && (
                <DatapickerUser
                    orgId={currentOrg}
                    isOpen={createPlotDisclosure.isOpen}
                    onClose={createPlotDisclosure.onClose}
                    onSelect={onClose}
                />
            )}
            <Box
                id="plots-page"
                mx="auto"
                display="flex"
                flexDirection="row"
                h="100%"
                overflow="hidden"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    overflowX="auto"
                    flex={1}
                    h="100%"
                    overflow="hidden"
                >
                    {plots.length === 0 ? (
                        <Center
                            height="100%"
                            width="100%"
                            overflowY="auto"
                            overflowX="auto"
                            py="8"
                        >
                            <EmptyState
                                icon={() => <Icon.AnalyzeImage />}
                                heading="No Plots"
                                body="You don't have any plots yet. Start by creating one."
                                onClick={createPlotDisclosure.onToggle}
                                buttonLabel="Create your first plot"
                            />
                        </Center>
                    ) : (
                        <Box
                            display="flex"
                            flexDir="column"
                            w="100%"
                            h="100%"
                            overflow="hidden"
                        >
                            <AlphaBanner mb="24px" borderRadius="8px" />
                            <PageHeader
                                actionsId="samples-page-actions"
                                title="Plots"
                                subtitle="Create plots to visualze your data"
                                actions={actions}
                            />

                            <Box gap="24px" display="flex" flexWrap="wrap">
                                {plots.map((plot) => {
                                    return (
                                        <PlotCard
                                            plotId={plot.id}
                                            orgId={plot.orgId}
                                            key={plot.id}
                                            title={plot.name}
                                            onDelete={onDelete}
                                        />
                                    );
                                })}
                            </Box>
                        </Box>
                    )}
                </PageLayout>
            </Box>
        </>
    );
});

PlotsPage.displayName = "PlotsPage";
