import type { FC, KeyboardEventHandler, MouseEventHandler } from "react";
import React, { useState } from "react";

import { Box, Image as ChakraImage, Skeleton } from "@chakra-ui/react";

type ImageLoadingState = "loading" | "loaded" | "error";

const isLoading = (state: ImageLoadingState) => state === "loading";
const isError = (state: ImageLoadingState) => state === "error";
const isLoaded = (state: ImageLoadingState) => state === "loaded";

const getStyle = (isVisible: boolean): React.CSSProperties => {
    const visibilityStyles = isVisible
        ? {}
        : ({
              visibility: "hidden",
              position: "absolute",
              width: "0",
              height: "0",
          } as const);

    return {
        ...visibilityStyles,
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    } as const;
};

interface ImageProps {
    src: string;
    alt?: string;
    fallback: React.ReactNode;
    onClick?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
}

const interactiveStyle = {
    cursor: "pointer",
    tabIndex: 0,
};

export const Image: FC<ImageProps> = ({
    alt,
    src,
    fallback,
    onClick,
    onKeyDown,
}) => {
    const [imageLoadingState, setImageLoadingState] =
        useState<ImageLoadingState>("loading");

    const onError = () => {
        setImageLoadingState("error");
    };

    const onLoad = () => {
        setImageLoadingState("loaded");
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            px="10px"
        >
            {(isLoaded(imageLoadingState) || isLoading(imageLoadingState)) && (
                <ChakraImage
                    {...(onClick
                        ? { ...interactiveStyle, onClick, onKeyDown: onKeyDown }
                        : undefined)}
                    src={src}
                    alt={alt}
                    style={getStyle(isLoaded(imageLoadingState))}
                    onLoad={onLoad}
                    onError={onError}
                />
            )}
            {isLoading(imageLoadingState) && (
                <Box width="100%" height="100%">
                    <Skeleton speed={2} p="20px" width="100%" height="100%" />
                </Box>
                // <DelayedLoadingLine delay={500} loadingLineColor="lightGrey2" />
            )}
            {isError(imageLoadingState) && fallback}
        </Box>
    );
};
