import type { FC } from "react";

import { head } from "ramda";

import { Center } from "@chakra-ui/react";

import { Measurement } from "@app/domain/api/measurement.ts";
import { PDFMeasurementContainer } from "@app-components/measurement/PDFMeasurement.container.tsx";
import { DataEmptyState } from "@app-components/measurementTask/DataEmptyState.tsx";

export const PDFTaskContainer: FC<{
    taskId: string;
    orgId: string;
}> = ({ taskId, orgId }) => {
    const measurementsQuery = Measurement.useGetAll({
        path: {
            organizationId: orgId,
        },
        query: {
            filter: {
                measurementTaskId: taskId,
            },
        },
    });

    // Assuming that there's only 1 measurement per task
    const measurementsForTask = head(measurementsQuery.data || []);

    if (measurementsQuery.isPending) {
        return null;
    }

    if (!measurementsQuery.data || !measurementsForTask) {
        return (
            <Center flex={1} h="100%" w="100%">
                <DataEmptyState />
            </Center>
        );
    }

    return (
        <PDFMeasurementContainer
            key={measurementsForTask.id}
            orgId={orgId}
            measurement={measurementsForTask}
        />
    );
};
