import type { FC } from "react";
import { useRef } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";

import { Box, Button, useDisclosure, useOutsideClick } from "@chakra-ui/react";

type ColorPickerProps = {
    value?: string;
    onChange?: (color: string) => void;
};
export const ColorPicker: FC<ColorPickerProps> = ({ value, onChange }) => {
    const ref = useRef<HTMLDivElement>(null);
    const disclosure = useDisclosure();
    useOutsideClick({ ref, handler: disclosure.onClose });

    return (
        <Box>
            <Button
                size="xs"
                p={0}
                backgroundColor={value}
                onClick={disclosure.onToggle}
            />
            {disclosure.isOpen && (
                <Box position="absolute" zIndex="1000">
                    <div ref={ref}>
                        <HexColorPicker color={value} onChange={onChange} />
                        <HexColorInput color={value} onChange={onChange} />
                    </div>
                </Box>
            )}
        </Box>
    );
};
