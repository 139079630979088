// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";

import PropTypes from "prop-types";

import { Box, Text } from "@chakra-ui/react";

import {
    containerConnectedContextTypes,
    isVisibleGivenCustomConfig,
    unpackPlotProps,
} from "../../lib";

// class Section extends Component {
//     constructor() {
//         super();
//         this.sectionVisible = true;
//     }
//
//     render() {
//         if (!this.sectionVisible) {
//             return null;
//         }
//
//         return (
//             <div className="section">
//                 {this.props.name ? (
//                     <div className="section__heading">
//                         <div className="section__heading__text">
//                             {this.props.name}
//                         </div>
//                     </div>
//                 ) : null}
//                 {this.props.children}
//             </div>
//         );
//     }
// }

// Section.plotly_editor_traits = { no_visibility_forcing: false };
// Section.propTypes = {
//     children: PropTypes.node,
//     name: PropTypes.string,
//     attr: PropTypes.string,
// };

const determineVisibility = (nextProps, nextContext) => {
    const { isVisible } = unpackPlotProps(nextProps, nextContext);
    const sectionVisible = isVisibleGivenCustomConfig(
        isVisible,
        nextProps,
        nextContext,
    );

    return React.Children.toArray(nextProps.children).some((child) => {
        if (!child) {
            return false;
        }

        if (child.props.attr) {
            const plotProps = unpackPlotProps(child.props, nextContext);
            if (child.type.modifyPlotProps) {
                child.type.modifyPlotProps(child.props, nextContext, plotProps);
            }

            const isVisibleThroughCustomConfig = isVisibleGivenCustomConfig(
                sectionVisible || plotProps.isVisible,
                child.props,
                nextContext,
                child.type && child.type.displayName
                    ? child.type.displayName
                    : null,
            );
            return isVisibleThroughCustomConfig;
        }

        if (!(child.type.plotly_editor_traits || {}).no_visibility_forcing) {
            // non-attr components force visibility (unless they don't via traits)
            return true;
        }
    });
};

export const PlotlySection = (props, context) => {
    const sectionVisible = determineVisibility(props, context);
    if (!sectionVisible) {
        return null;
    }

    return (
        <Box>
            {props.name ? (
                <Box
                    position="relative"
                    display="flex"
                    fontSize="md"
                    color="gray.800"
                    fontWeight="500"
                    bg="gray.100"
                    p="6px 12px"
                    textTransform="capitalize"
                >
                    <Text textStyle="T2">{props.name}</Text>
                </Box>
            ) : null}
            {props.children}
        </Box>
    );
};

PlotlySection.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string,
    attr: PropTypes.string,
};

PlotlySection.plotly_editor_traits = { no_visibility_forcing: true };
PlotlySection.contextTypes = containerConnectedContextTypes;

export default PlotlySection;
