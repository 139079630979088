import type { FC } from "react";

import { useFormik } from "formik";
import { equals, pick } from "ramda";
import { z, ZodError } from "zod";

import { Box, IconButton, Text } from "@chakra-ui/react";

import { useRemoteConfigContext } from "@app/contexts/ConfigContext/ConfigContext.tsx";
import type { ShippingStatusType } from "@app/domain/api/package.ts";
import {
    carrierCodeToLabel,
    carrierOptions,
    Carriers,
    Package,
    ShippingStatus,
    shippingStatusOptions,
} from "@app/domain/api/package.ts";
import {
    createFormRegistrator,
    formatLabel,
    removeEmptyStringValues,
} from "@app/domain/form";
import {
    maxLength160,
    maxLength340,
} from "@app/domain/services/sample/utils.ts";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { FileUpload } from "@mt-components/Input/FileUpload.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { SelectField } from "@mt-components/Input/SelectField.tsx";
import { StaticField2 } from "@mt-components/Input/StaticField2.tsx";
import { TextField } from "@mt-components/Input/TextField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useConfirm } from "@mt-hooks/useConfirm.tsx";
import { useFileUpload } from "@mt-hooks/useFileUpload.ts";
import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

const zodSchema = z.object({
    id: z.string().optional(),
    carrier: z.enum(carrierOptions),
    trackingLink: z
        .string()
        .max(maxLength160.value, maxLength160.message)
        .regex(urlRegex, "Please provide a valid URL starting with 'https'")
        .optional(),
    status: z.enum(shippingStatusOptions),
    message: z
        .string()
        .max(maxLength340.value, maxLength340.message)
        .optional(),
    proformaInvoice: z.string().optional(),
});

type FormShape = z.infer<typeof zodSchema>;

type Props = {
    isOpen: boolean;
    canDelete?: boolean;
    requestId: string;
    statusOptions: ShippingStatusType[];
    orgId: string;
    initialData?: FormShape & { proformaInvoice?: string };
    onCancel: () => void;
    canEdit?: ("status" | "carrier" | "message" | "trackingLink")[];
};

const validateForm = (values: FormShape) => {
    try {
        zodSchema.parse(values);
    } catch (error) {
        if (error instanceof ZodError) {
            return error.formErrors.fieldErrors;
        }
    }
};

export const PackageModal: FC<Props> = ({
    isOpen,
    orgId,
    initialData,
    statusOptions,
    requestId,
    onCancel,
    canDelete,
    canEdit,
}) => {
    const isEditMode = Boolean(initialData);

    const snackbar = useSnackbar();
    const remoteConfig = useRemoteConfigContext();

    const createPackage = Package.useCreate();
    const updatePackage = Package.useUpdate();
    const deletePackage = Package.useDelete();
    const downloadProformaInvoice = Package.useDownloadProformaInvoice();
    const deleteProformaInvoice = Package.useDeleteProformaInvoice();
    const uploadProformaInvoice = Package.useUploadProformaInvoice();

    const confirmDeletePackage = useConfirm({
        title: "Delete Package",
    });

    const form = useFormik({
        validateOnMount: true,
        validateOnChange: true,
        initialValues: {
            message: initialData?.message ?? "",
            carrier: initialData?.carrier ?? Carriers.DHL,
            trackingLink: initialData?.trackingLink ?? "",
            status: initialData?.status ?? ShippingStatus.DRAFT,
            proformaInvoice: initialData?.proformaInvoice,
        },
        onSubmit: () => {},
        validate: (values: FormShape) => {
            const newValues = removeEmptyStringValues<FormShape>(values);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
            return validateForm(newValues as any);
        },
    });

    const get = createFormRegistrator(form);
    const onClose = () => {
        form.resetForm();
        uploadProps.clearFiles();
        onCancel();
    };

    const handleUploadNotifications = ({ ok }: { ok: boolean }) => {
        if (!ok) {
            snackbar.error("Proforma invoice could not be attached");
        }

        snackbar.success("Proforma invoice attached");
    };

    const onDelete = async (packageId: string) => {
        return await new Promise<void>((resolve) => {
            confirmDeletePackage({
                title: "Delete Package",
                body: (
                    <Box>
                        <Text display="block">
                            Are you sure you want to remove tracking information
                            of this package?
                        </Text>
                    </Box>
                ),
                onCancel: () => {},
                onConfirm: async () => {
                    await deletePackage.mutateAsync({
                        params: {
                            path: {
                                organizationId: orgId,
                                packageId,
                            },
                        },
                    });
                    onCancel();
                    resolve();
                    return;
                },
            });
        });
    };

    const uploadProps = useFileUpload({
        accept: [".pdf"],
        uploadLabel: "Attach a proforma invoice",
    });

    const onCreate = async () => {
        const creationResponse = await createPackage.mutateAsync({
            params: {
                path: {
                    organizationId: orgId,
                },
            },
            data: {
                message: form.values.message,
                carrier: form.values.carrier,
                status: form.values.status,
                trackingLink: form.values.trackingLink,
                measurementGroupId: requestId,
                organizationId: orgId,
            },
        });

        if (creationResponse.response.ok && creationResponse.data?.id) {
            if (uploadProps.files?.length) {
                const uploadResponse = await uploadProformaInvoice.mutateAsync({
                    file: uploadProps.files[0],
                    path: {
                        organizationId: orgId,
                        packageId: creationResponse.data.id,
                    },
                });
                handleUploadNotifications({ ok: uploadResponse.ok });
            }
        }

        onClose();
    };

    const onUpdate = async () => {
        if (!initialData?.id) {
            return;
        }

        const updateResponse = await updatePackage.mutateAsync({
            params: {
                path: {
                    organizationId: orgId,
                    packageId: initialData.id,
                },
            },
            data: {
                message: form.values.message,
                carrier: form.values.carrier,
                status: form.values.status,
                trackingLink: form.values.trackingLink,
            },
        });

        if (!updateResponse.response.ok) {
            snackbar.error("Package info could not be updated");
            return;
        }

        // decide what to do with the proforma
        // update/overwrite or delete
        if (
            initialData.proformaInvoice &&
            form.values.proformaInvoice &&
            !uploadProps.files?.length
        ) {
            // don't do anything
            onClose();
        }

        if (
            initialData.proformaInvoice &&
            !form.values.proformaInvoice &&
            !uploadProps.files?.length
        ) {
            //delete
            const response = await deleteProformaInvoice.mutateAsync({
                path: {
                    organizationId: orgId,
                    packageId: initialData.id,
                },
            });

            if (!response.response.ok) {
                snackbar.error("Proforma invoice could not be deleted");
            }
            snackbar.success("Proforma invoice deleted");
            onClose();
        }

        if (
            initialData.proformaInvoice &&
            !form.values.proformaInvoice &&
            uploadProps.files?.length
        ) {
            // update
            const response = await uploadProformaInvoice.mutateAsync({
                path: {
                    organizationId: orgId,
                    packageId: initialData.id,
                },
                file: uploadProps.files[0],
            });

            handleUploadNotifications(response);
            onClose();
        }

        if (
            !initialData.proformaInvoice &&
            !form.values.proformaInvoice &&
            !uploadProps.files?.length
        ) {
            // don't do anything
            onClose();
        }

        onClose();
    };

    const downloadProforma = async () => {
        if (initialData?.id) {
            await downloadProformaInvoice.mutateAsync({
                path: {
                    organizationId: orgId,
                    packageId: initialData.id,
                },
            });
        }
    };

    const markProformaAsRemoved = async () => {
        await form.setFieldValue("proformaInvoice", undefined);
    };

    const b = initialData
        ? equals(
              form.values,
              pick(
                  [
                      "carrier",
                      "status",
                      "message",
                      "trackingLink",
                      "proformaInvoice",
                  ],
                  initialData,
              ),
          )
        : false;

    return (
        <Modal
            id="package-modal"
            isOpen={isOpen}
            onClose={() => {
                onCancel();
                form.resetForm();
            }}
            header={isEditMode ? "Update Tracking Info" : "Add Tracking Info"}
            buttonLeft={
                initialData && canDelete ? (
                    <LoadingButton
                        colorScheme="red"
                        onClick={async () => {
                            if (!initialData.id) {
                                return;
                            }
                            await onDelete(initialData.id);
                        }}
                    >
                        Delete
                    </LoadingButton>
                ) : null
            }
            buttonRight={
                <LoadingButton
                    colorScheme="blue"
                    isDisabled={!form.isValid || b}
                    onClick={async () => {
                        if (initialData) {
                            await onUpdate();
                            return;
                        }
                        await onCreate();
                    }}
                >
                    {isEditMode ? "Update" : "Add"}
                </LoadingButton>
            }
        >
            <Box display="flex" flexDirection="column" gap="16px" width="100%">
                <Text fontSize="md" color="gray.900" my="24px">
                    Add tracking information about your shipped samples to your
                    measurement request. This allows us to track the status of
                    your packages and plan its arrival and preparation
                    accordingly to make sure you get your data in time.
                </Text>
                <SelectField
                    options={statusOptions}
                    label={formatLabel({
                        label: "Status",
                        required: true,
                    })}
                    {...get("status")}
                    isReadOnly={!canEdit?.includes("status")}
                />

                <SelectField
                    options={carrierOptions.map((c) => ({
                        value: c,
                        label: carrierCodeToLabel(c),
                    }))}
                    label={formatLabel({
                        label: "Carrier",
                        required: true,
                    })}
                    {...get("carrier")}
                    isReadOnly={!canEdit?.includes("carrier")}
                />

                <InputField
                    label="Tracking Link"
                    {...get("trackingLink")}
                    maxLength={160}
                    isReadOnly={!canEdit?.includes("trackingLink")}
                />

                <TextField
                    label="Message"
                    {...get("message")}
                    maxLength={340}
                    isReadOnly={!canEdit?.includes("message")}
                />
                {remoteConfig.featureFlags?.proforma &&
                    (form.values.proformaInvoice ? (
                        <StaticField2
                            title="Proforma Invoice"
                            value={
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <Box
                                        as="button"
                                        display="flex"
                                        gap="12px"
                                        alignItems="center"
                                        tabIndex={0}
                                        onClick={downloadProforma}
                                        cursor="pointer"
                                    >
                                        <Icon.Document />{" "}
                                        {form.values.proformaInvoice}{" "}
                                    </Box>
                                    <IconButton
                                        aria-label="delete proforma invoice"
                                        variant="ghost"
                                        icon={<Icon.Delete />}
                                        onClick={markProformaAsRemoved}
                                    />
                                </Box>
                            }
                            pl="4px"
                        />
                    ) : (
                        <StaticField2
                            pl="4px"
                            title="Proforma Invoice"
                            value={<FileUpload {...uploadProps} />}
                        />
                    ))}
            </Box>
        </Modal>
    );
};
