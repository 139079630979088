import type { FC } from "react";

import { Center, Text } from "@chakra-ui/react";

export const NoSearchMatch: FC<{ searchTerm: string }> = ({ searchTerm }) => (
    <Center w="100%" h="100%">
        <Text>
            No matches
            {searchTerm ? (
                <>
                    {" "}
                    for{" "}
                    <Text as="span" fontWeight="semibold">
                        {searchTerm}
                    </Text>
                </>
            ) : null}
        </Text>
    </Center>
);

NoSearchMatch.displayName = "NoSearchMatch";
