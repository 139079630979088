import type { MeasurementGroupModel } from "@app/domain";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { MultipleSelectionView } from "@app-components/measurementGroup/SampleTaskSidebar/MultipleSelection.view.tsx";
import { NoSelectionView } from "@app-components/measurementGroup/SampleTaskSidebar/NoSelection.view.tsx";
import { SingleSelectionView } from "@app-components/measurementGroup/SampleTaskSidebar/SingleSelection.view.tsx";

import { Footer } from "@mt-components/Layout/Sidebar/Footer.tsx";
import { Sidebar } from "@mt-components/Layout/Sidebar/Sidebar.tsx";

type Props = {
    isOpen: boolean;
    canEdit?: boolean;
    canViewSample?: boolean;
    hideHazards?: boolean;
    sampleTasks?: SampleTasksModel[];
    measurementGroup: MeasurementGroupModel;
    onToggle: () => void;
    onOpenQRCodeReader?: (sampleTask: SampleTasksModel) => void;
    onSelectToEdit?: (sampleTask: SampleTasksModel) => void;
    goToSample?: (sampleId: string) => void;
};

export const SampleTaskSidebar = ({
    isOpen,
    canEdit,
    canViewSample,
    hideHazards,
    sampleTasks,
    onToggle,
    measurementGroup,
    onSelectToEdit,
    goToSample,
    onOpenQRCodeReader,
}: Props) => {
    return (
        <Sidebar
            boxShadow="none"
            borderLeft="solid 1px"
            borderColor="gray.200"
            height="100%"
            isOpen={Boolean(isOpen)}
        >
            {isOpen && (!sampleTasks || sampleTasks.length === 0) && (
                <NoSelectionView />
            )}
            {isOpen && sampleTasks && sampleTasks.length > 1 && (
                <MultipleSelectionView />
            )}
            {isOpen && sampleTasks && sampleTasks.length === 1 && (
                <SingleSelectionView
                    canEdit={canEdit}
                    canViewSample={canViewSample}
                    hideHazards={hideHazards}
                    sampleTask={sampleTasks[0]}
                    measurementGroup={measurementGroup}
                    goToSample={goToSample}
                    onSelectToEdit={onSelectToEdit}
                    onOpenQRCodeReader={onOpenQRCodeReader}
                />
            )}

            <Footer
                pl="12px"
                flex="0 0 32px"
                mt="auto"
                onToggle={onToggle}
                isOpen={isOpen}
            />
        </Sidebar>
    );
};
