import { memo } from "react";

import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { RouteApi } from "@tanstack/react-router";

import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { organizationsQueryKey, usePublicClient } from "src/api/client.ts";

import { OrganizationDetail } from "./OrganizationDetail.tsx";

const Route = new RouteApi({
    id: "/organizations/$orgId",
});

export const OrganizationDetailsPage = memo(() => {
    const params = Route.useParams();
    const { isOpen, onToggle } = useDisclosure();

    const client = usePublicClient();
    const organizationQuery = useQuery({
        queryKey: organizationsQueryKey(params.orgId),
        queryFn: () =>
            client.GET("/organizations/{organizationId}", {
                params: { path: { organizationId: params.orgId } },
            }),
    });

    if (!organizationQuery.data?.data) {
        return <NotFound />;
    }

    return (
        <OrganizationDetail
            organization={organizationQuery.data.data}
            organizationId={params.orgId}
            isFormOpen={isOpen}
            onToggleForm={onToggle}
        />
    );
});

OrganizationDetailsPage.displayName = "OrganizationDetailsPage";
