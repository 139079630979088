// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { connectTraceToPlot, plotlyTraceToCustomTrace } from "lib";
import { EDITOR_ACTIONS } from "lib/constants";
import { traceTypes } from "lib/traceTypes";
import PropTypes from "prop-types";

import { PanelMessage } from "./PanelEmpty";
import PlotlyFold from "./PlotlyFold";
import PlotlyPanel from "./PlotlyPanel";
import TraceRequiredPanel from "./TraceRequiredPanel";

const traceHelp = (
    <PanelMessage heading="Trace your data.">
        <p>
            Traces of various types like line and scatter charts are the
            building blocks of your figure.
        </p>
        <p>You can add as many as you like into a figure.</p>
        <p>'Click on the + button above to add a trace.'</p>
    </PanelMessage>
);

const TraceFold = connectTraceToPlot(PlotlyFold);

type Props = {
    canGroup?: boolean;
    canAdd?: boolean;
    canReorder?: boolean;
    traceFilterCondition?: (trace, fullTrace) => boolean;
};

const setLocals = (props: Props, context) => {
    const base = props.canGroup ? context.fullData : context.data;
    const traceFilterCondition = props.traceFilterCondition || (() => true);

    const filteredTracesDataIndexes = [];
    let filteredTraces = [];

    if (base && base.length && context.fullData.length) {
        filteredTraces = base.filter((t, i) => {
            const fullTrace = props.canGroup
                ? t
                : context.fullData.filter((tr) => tr.index === i)[0];

            if (fullTrace) {
                const trace = context.data[fullTrace.index];
                if (traceFilterCondition(trace, fullTrace)) {
                    filteredTracesDataIndexes.push(fullTrace.index);
                    return true;
                }
            }

            return false;
        });
    }

    return {
        filteredTracesDataIndexes,
        filteredTraces,
    };
};

const TraceAccordion = (props, context) => {
    const { filteredTracesDataIndexes, filteredTraces } = setLocals(
        props,
        context,
    );

    const renderGroupedTraceFolds = () => {
        if (!filteredTraces.length || filteredTraces.length <= 1) {
            return null;
        }

        const dataArrayPositionsByTraceType = {};
        const fullDataArrayPositionsByTraceType = {};

        filteredTraces.forEach((trace) => {
            const traceType = plotlyTraceToCustomTrace(trace);
            if (!dataArrayPositionsByTraceType[traceType]) {
                dataArrayPositionsByTraceType[traceType] = [];
            }

            if (!fullDataArrayPositionsByTraceType[traceType]) {
                fullDataArrayPositionsByTraceType[traceType] = [];
            }

            dataArrayPositionsByTraceType[traceType].push(trace.index);
            // _expandedIndex is the trace's index in the fullData array
            fullDataArrayPositionsByTraceType[traceType].push(
                trace._expandedIndex,
            );
        });

        return Object.keys(fullDataArrayPositionsByTraceType).map(
            (type, index) => (
                <TraceFold
                    key={index}
                    traceIndexes={dataArrayPositionsByTraceType[type]}
                    name={traceTypes().find((t) => t.value === type).label}
                    fullDataArrayPosition={
                        fullDataArrayPositionsByTraceType[type]
                    }
                >
                    {props.children}
                </TraceFold>
            ),
        );
    };

    const renderUngroupedTraceFolds = () => {
        if (filteredTraces.length) {
            return filteredTraces.map((d, i) => (
                <TraceFold
                    key={i}
                    traceIndexes={[d.index]}
                    canDelete={props.canAdd}
                    fullDataArrayPosition={[d._expandedIndex]}
                >
                    {props.children}
                </TraceFold>
            ));
        }
        return null;
    };

    const renderTraceFolds = () => {
        if (filteredTraces.length) {
            return filteredTraces.map((d, i) => (
                <TraceFold
                    key={i}
                    traceIndexes={[filteredTracesDataIndexes[i]]}
                    canDelete={props.canAdd}
                >
                    {props.children}
                </TraceFold>
            ));
        }
        return null;
    };

    const { canAdd, canGroup, canReorder } = props;

    if (canAdd) {
        const addAction = {
            label: "Trace",
            handler: ({ onUpdate }) => {
                if (onUpdate) {
                    onUpdate({
                        type: EDITOR_ACTIONS.ADD_TRACE,
                    });
                }
            },
        };
        const traceFolds = renderTraceFolds();
        return (
            <PlotlyPanel addAction={addAction} canReorder={canReorder}>
                {traceFolds ? traceFolds : traceHelp}
            </PlotlyPanel>
        );
    }

    if (canGroup) {
        if (filteredTraces.length === 1) {
            return (
                <TraceRequiredPanel>
                    {renderUngroupedTraceFolds()}
                </TraceRequiredPanel>
            );
        }

        if (filteredTraces.length > 1) {
            return (
                <TraceRequiredPanel noPadding>
                    {/*<Tabs>*/}
                    {/*    <TabList>*/}
                    {/*        <Tab>Individually</Tab>*/}
                    {/*        <Tab>By Type</Tab>*/}
                    {/*    </TabList>*/}
                    {/*    <TabPanel>*/}
                    <PlotlyPanel>{renderUngroupedTraceFolds()}</PlotlyPanel>
                    {/*</TabPanel>*/}
                    {/*<TabPanel>*/}
                    {/*    <PlotlyPanel>*/}
                    {/*        {renderGroupedTraceFolds()}*/}
                    {/*    </PlotlyPanel>*/}
                    {/*</TabPanel>*/}
                    {/*</Tabs>*/}
                </TraceRequiredPanel>
            );
        }
    }

    return <TraceRequiredPanel>{renderTraceFolds()}</TraceRequiredPanel>;
};

TraceAccordion.contextTypes = {
    fullData: PropTypes.array,
    data: PropTypes.array,
};

TraceAccordion.propTypes = {
    canAdd: PropTypes.bool,
    canGroup: PropTypes.bool,
    canReorder: PropTypes.bool,
    children: PropTypes.node,
    traceFilterCondition: PropTypes.func,
};

export default TraceAccordion;
