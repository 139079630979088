// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/**
 * Trace type constants
 */

export const chartCategory = () => {
    return {
        SIMPLE: {
            value: "SIMPLE",
            label: "Simple",
        },
        FINANCIAL: {
            value: "FINANCIAL",
            label: "Finance",
            maxColumns: 1,
        },
        DISTRIBUTIONS: {
            value: "DISTRIBUTIONS",
            label: "Distributions",
        },
        MAPS: {
            value: "MAPS",
            label: "Maps",
            maxColumns: 1,
        },
        SPECIALIZED: {
            value: "SPECIALIZED",
            label: "Specialized",
            maxColumns: 1,
        },
        THREE_D: {
            value: "3D",
            label: "3D",
            maxColumns: 1,
        },
        HIDDEN: {
            value: "HIDDEN",
            label: "",
        },
    };
};

// Layout specification for TraceTypeSelector.js
export const categoryLayout = () => [
    chartCategory().SIMPLE,
    chartCategory().DISTRIBUTIONS,
    chartCategory().THREE_D,
    chartCategory().MAPS,
    chartCategory().FINANCIAL,
    chartCategory().SPECIALIZED,
];

export const traceTypes = () =>
    [
        {
            value: "scatter",
            label: "Scatter",
            category: chartCategory().SIMPLE,
        },
        //   {
        //     value: 'bar',
        //     label: 'Bar',
        //     category: chartCategory().SIMPLE,
        //   },
        {
            value: "line",
            label: "Line",
            category: chartCategory().SIMPLE,
        },
        {
            value: "area",
            label: "Area",
            category: chartCategory().SIMPLE,
        },
        {
            value: "heatmap",
            label: "Heatmap",
            category: chartCategory().SIMPLE,
        },
        {
            value: "table",
            label: "Table",
            category: chartCategory().SIMPLE,
        },
        {
            value: "contour",
            label: "Contour",
            category: chartCategory().SIMPLE,
        },
        {
            value: "pie",
            label: "Pie",
            category: chartCategory().SIMPLE,
        },
        {
            value: "scatter3d",
            label: "3D Scatter",
            category: chartCategory().THREE_D,
        },
        {
            value: "line3d",
            label: "3D Line",
            category: chartCategory().THREE_D,
        },
        {
            value: "surface",
            label: "3D Surface",
            category: chartCategory().THREE_D,
        },
        {
            value: "mesh3d",
            label: "3D Mesh",
            category: chartCategory().THREE_D,
        },
        {
            value: "cone",
            label: "Cone",
            category: chartCategory().THREE_D,
        },
        {
            value: "streamtube",
            label: "Streamtube",
            category: chartCategory().THREE_D,
        },
        {
            value: "box",
            label: "Box",
            category: chartCategory().DISTRIBUTIONS,
        },
        {
            value: "violin",
            label: "Violin",
            category: chartCategory().DISTRIBUTIONS,
        },
        {
            value: "histogram",
            label: "Histogram",
            category: chartCategory().DISTRIBUTIONS,
        },
        {
            value: "histogram2d",
            label: "2D Histogram",
            category: chartCategory().DISTRIBUTIONS,
        },
        {
            value: "histogram2dcontour",
            label: "2D Contour Histogram",
            category: chartCategory().DISTRIBUTIONS,
        },
        {
            value: "scattermapbox",
            label: "Tile Map",
            category: chartCategory().MAPS,
        },
        {
            value: "scattergeo",
            label: "Atlas Map",
            category: chartCategory().MAPS,
        },
        {
            value: "choroplethmapbox",
            label: "Choropleth Tile Map",
            category: chartCategory().MAPS,
        },
        {
            value: "choropleth",
            label: "Choropleth Atlas Map",
            category: chartCategory().MAPS,
        },
        {
            value: "densitymapbox",
            label: "Density Tile Map",
            category: chartCategory().MAPS,
        },
        {
            value: "scatterpolar",
            label: "Polar Scatter",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "barpolar",
            label: "Polar Bar",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "scatterternary",
            label: "Ternary Scatter",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "sunburst",
            label: "Sunburst",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "treemap",
            label: "Treemap",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "sankey",
            label: "Sankey",
            category: chartCategory().SPECIALIZED,
        },
        {
            value: "candlestick",
            label: "Candlestick",
            category: chartCategory().FINANCIAL,
        },
        {
            value: "ohlc",
            label: "OHLC",
            category: chartCategory().FINANCIAL,
        },
        {
            value: "waterfall",
            label: "Waterfall",
            category: chartCategory().FINANCIAL,
        },
        {
            value: "funnel",
            label: "Funnel",
            category: chartCategory().FINANCIAL,
        },
        {
            value: "funnelarea",
            label: "Funnel Area",
            category: chartCategory().FINANCIAL,
        },
        {
            value: "scattergl",
            icon: "scatter",
            label: "Scatter",
            category: chartCategory().THREE_D,
        },
        {
            value: "scatterpolargl",
            icon: "scatterpolar",
            label: "Polar Scatter",
            category: chartCategory().THREE_D,
        },
        {
            value: "heatmapgl",
            icon: "heatmap",
            label: "Heatmap GL",
            category: chartCategory().HIDDEN,
        },
        {
            value: "pointcloud",
            label: "Point Cloud",
            category: chartCategory().HIDDEN,
        },
        {
            value: "parcoords",
            label: "Parallel Coordinates",
            category: chartCategory().HIDDEN,
        },
        {
            value: "parcats",
            label: "Parallel Categories",
            category: chartCategory().HIDDEN,
        },
        {
            value: "splom",
            label: "Scatterplot Matrix",
            category: chartCategory().HIDDEN,
        },
        {
            value: "scattercarpet",
            label: "Scatter Carpet",
            category: chartCategory().HIDDEN,
        },
        {
            value: "contourcarpet",
            label: "Contour Carpet",
            category: chartCategory().HIDDEN,
        },
        {
            value: "carpet",
            label: "Carpet",
            category: chartCategory().HIDDEN,
        },
        {
            value: "isosurface",
            label: "Isosurface",
            category: chartCategory().HIDDEN,
        },
    ] as const;
