import { createStylesContext } from "@chakra-ui/react";
import { createContext } from "@chakra-ui/react-utils";

import type { useAppShell } from "@mt-components/AppShell/useAppShell.tsx";

export const [StylesProvider] = createStylesContext("SuiAppShell");

export const [AppShellProvider, useAppShellContext] = createContext<
    ReturnType<typeof useAppShell>
>({
    strict: false,
    errorMessage: "AppShell context not available.",
});
