import type { FC } from "react";
import { useState } from "react";

import { groupBy, head } from "ramda";

import { Box, Flex, Text, useDisclosure } from "@chakra-ui/react";

import { Artifact, type ArtifactModel } from "@app/domain/api/artifact.ts";
import { Measurement } from "@app/domain/api/measurement.ts";
import {
    isDetectorImage,
    isPattern,
    renameArtifact,
} from "@app/domain/services/artifact/artifact-service.ts";

import { PreviewImage } from "@mt-components/Display/PreviewImage/PreviewImage.tsx";
import { Lightbox } from "@mt-components/Lightbox/Lightbox.tsx";

export const ResultsPreview: FC<{
    orgId: string;
    taskId: string;
    onSelectArtifact?: (artifact?: ArtifactModel) => void;
}> = ({ orgId, taskId }) => {
    const [index, setIndex] = useState(0);

    const measurements = Measurement.useGetAll({
        path: {
            organizationId: orgId,
        },
        query: {
            filter: {
                measurementTaskId: taskId,
            },
        },
    });

    // Assuming that there's only 1 measurement per task
    const measurementsForTask = head(measurements.data ?? []);

    const disclosure = useDisclosure();
    const artficatsQuery = Artifact.useGetAll({
        params: {
            path: {
                organizationId: orgId,
            },
            query: {
                filter: {
                    measurementId: measurementsForTask?.id,
                },
            },
        },
    });

    if (!artficatsQuery.data) {
        return null;
    }

    const openLightBox = (idx: number) => {
        disclosure.onOpen();
        setIndex(idx);
    };

    const artifactsWithPreview = artficatsQuery.data.filter((a) =>
        Boolean(a.thumbnailUrl),
    );

    const artifactGroups = groupBy((a) => {
        if (isPattern(a)) {
            return "pattern";
        }

        if (isDetectorImage(a)) {
            return "raw";
        }

        return "other";
    }, artifactsWithPreview);

    const artifactsToRender = [
        artifactGroups.raw ?? [],
        artifactGroups.pattern ?? [],
    ].flat();

    return (
        <Box width="100%">
            <Lightbox
                index={index}
                open={disclosure.isOpen}
                close={disclosure.onClose}
                on={{
                    view: ({ index }) => setIndex(index),
                }}
                slides={artifactsToRender.map((a) => ({
                    src: a.thumbnailUrl ?? "",
                }))}
            />

            <Box display="flex" gap="24px" flexWrap="wrap">
                {artifactsToRender.map((artifact, idx) => (
                    // render a preview image for each artifact and handle the fallback case
                    <Box key={artifact.id} width="300px" height="300px">
                        {artifact.thumbnailUrl ? (
                            <Flex flexDir="column" alignItems="center">
                                <PreviewImage
                                    onKeyDown={(e) => {
                                        if (
                                            e.code === "Enter" ||
                                            e.code === "Space"
                                        ) {
                                            e.preventDefault();
                                            openLightBox(idx);
                                            return;
                                        }
                                    }}
                                    onClick={() => openLightBox(idx)}
                                    key={artifact.id}
                                    url={artifact.thumbnailUrl}
                                />
                                <Text
                                    w="100%"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                    textAlign="center"
                                >
                                    {renameArtifact(artifact)}
                                </Text>
                            </Flex>
                        ) : null}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

ResultsPreview.displayName = "ResultsPreview";
