// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, ReactNode } from "react";

import nestedProperty from "plotly.js/src/lib/nested_property";
import PropTypes from "prop-types";

import type { UnconnectedDropdownProps } from "./Dropdown";
import Dropdown from "./Dropdown";

import { COLORS, MULTI_VALUED } from "../../lib/constants";
import { tooLight } from "../../libts/index";

const strokeDashes = [
    { value: "solid", strokeDasharray: "" },
    { value: "dot", strokeDasharray: "3px, 3px" },
    { value: "dash", strokeDasharray: "9px, 9px" },
    { value: "longdash", strokeDasharray: "15px, 15px" },
    { value: "dashdot", strokeDasharray: "9px, 3px, 3px, 3px" },
    { value: "longdashdot", strokeDasharray: "15px, 6px, 3px, 6px" },
];

const strokeShapes = [
    { d: "M2,14L14,2", value: "linear" },
    { d: "M2,14C4,4 16,16 18,2", value: "spline" },
    { d: "M2,14H14V2", value: "hv" },
    { d: "M2,14V2H14", value: "vh" },
    { d: "M2,14H8V2H14", value: "hvh" },
    { d: "M2,14V8H14V2", value: "vhv" },
];

type StrokeDataPoint = {
    strokeDasharray?: string;
    value: string;
    d?: string;
};

type LineOption = {
    value: string | null;
    label: string | ReactNode;
};

type LineSelectorProps = {
    attr: string;
    stroke?: string;
    lineColor?: string;
    computeOptions?: (color: string) => LineOption[];
} & UnconnectedDropdownProps;

const computeOptionsFactory =
    (strokeData: StrokeDataPoint[]) =>
    (lineColor: string): LineOption[] =>
        strokeData.map(({ value, strokeDasharray, d = "M0,8h100" }) => ({
            label: (
                <svg width="100" height="16">
                    <g>
                        <path
                            d={d}
                            style={{
                                fill: "none",
                                strokeWidth: "4px",
                                stroke:
                                    !lineColor || lineColor === MULTI_VALUED
                                        ? COLORS.mutedBlue
                                        : lineColor,
                                strokeDasharray,
                            }}
                        />
                    </g>
                </svg>
            ),
            value,
        }));

const LineSelector: FC<LineSelectorProps> = (
    { clearable = false, ...props },
    context,
) => {
    const lineColor: string = nestedProperty(
        context.fullContainer,
        "line.color",
    ).get() as string;
    const options = props.computeOptions(lineColor);

    return (
        <Dropdown
            {...props}
            clearable={clearable}
            options={options}
            backgroundDark={tooLight(lineColor)}
        />
    );
};

LineSelector.contextTypes = {
    fullContainer: PropTypes.object,
};

export const LineShapeSelector: FC<LineSelectorProps> = (props) => (
    <LineSelector
        {...props}
        computeOptions={computeOptionsFactory(strokeShapes)}
    />
);

const computeOptionsFactory1 = computeOptionsFactory(strokeDashes);
export const LineDashSelector: FC<LineSelectorProps> = (props) => (
    <LineSelector
        {...props}
        computeOptions={(lineColor) =>
            computeOptionsFactory1(lineColor).concat([
                {
                    label: "",
                    value: null,
                },
            ])
        }
    />
);
