import { indexBy, isNotNil } from "ramda";

import type { MeasurementTaskModel } from "./measurementTask.ts";
import type { SampleModel } from "./sample.ts";

type Methods = MeasurementTaskModel["method"];

export type SampleTasksModel = {
    tasks: {
        [Method in Methods]?: {
            taskId: string;
        };
    };
} & SampleModel & {
        measurementTaskIds: string[];
    };

type Aggregator = Record<string, SampleTasksModel | undefined>;

export const mergeSamplesWithTasks = (
    samples: SampleModel[],
    measurementTasks: MeasurementTaskModel[],
): SampleTasksModel[] => {
    const samplesById = indexBy((s) => s.id, samples);

    const aggregation: Aggregator = measurementTasks.reduce(
        (acc: Aggregator, task: MeasurementTaskModel): Aggregator => {
            const method = task.method;
            const aggregation = acc[task.sampleId];
            if (aggregation) {
                aggregation.tasks[method] = {
                    taskId: task.id,
                };

                aggregation.measurementTaskIds = [
                    ...aggregation.measurementTaskIds,
                    task.id,
                ];
                return {
                    ...acc,
                    [task.sampleId]: aggregation,
                };
            }
            return {
                ...acc,
                [task.sampleId]: {
                    tasks: {
                        [method]: {
                            taskId: task.id,
                        },
                    },
                    measurementTaskIds: [task.id],
                    ...samplesById[task.sampleId],
                },
            };
        },
        {} as Aggregator,
    );

    return Object.values(aggregation).filter(isNotNil);
};
