import type { FC, PropsWithChildren } from "react";
import { useMemo } from "react";

import {
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from "@tanstack/react-query";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";
import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { isLocalEnvironment } from "@mt-tools/env.ts";

export const CustomQueryClientProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const snack = useSnackbar();
    const snackRef = useLatestRef(snack);
    const client = useMemo(() => {
        const queryCache = new QueryCache({
            onError: (_, query) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
                const status = (query.state.error as any)?.response
                    ?.status as unknown;

                if (status === 404) {
                    snackRef.current.error({
                        title: "Not Found",
                        description: "This resource does not exist",
                    });
                    return;
                }

                if (status === 403) {
                    snackRef.current.error({
                        title: "Not Authorized",
                        description:
                            "You are not authorized to access this resources",
                    });
                    return;
                }

                if (!status) {
                    // eslint-disable-next-line no-console
                    isLocalEnvironment() && console.log("error", _);
                    snackRef.current.error("An error occurred");
                    return;
                }
                return;
            },
        });

        return new QueryClient({
            queryCache,
        });
    }, [snackRef]);
    return (
        <QueryClientProvider client={client}>{children}</QueryClientProvider>
    );
};

CustomQueryClientProvider.displayName = "CustomQueryClientProvider";
