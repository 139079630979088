import isNumeric from "fast-isnumeric";
import { connectToContainer } from "lib";

import { UnconnectedAxisInterval } from "./AxisInterval";
import { UnconnectedAxisRangeValue } from "./AxisRangeValue";
import { UnconnectedNumeric } from "./Numeric";

import { UnconnectedRadio } from "./Radio";
import { UnconnectedVisibilitySelect } from "./VisibilitySelect";

export const AxisSide = connectToContainer(UnconnectedRadio, {
    modifyPlotProps: (props, context, plotProps) => {
        const _ = context.localize;

        if (plotProps.fullValue === "left" || plotProps.fullValue === "right") {
            plotProps.options = [
                { label: "Left", value: "left" },
                { label: "Right", value: "right" },
            ];
            return;
        }

        if (plotProps.fullValue === "top" || plotProps.fullValue === "bottom") {
            plotProps.options = [
                { label: "Top", value: "top" },
                { label: "Bottom", value: "bottom" },
            ];
            return;
        }

        if (
            plotProps.fullValue === "clockwise" ||
            plotProps.fullValue === "counterclockwise"
        ) {
            plotProps.options = [
                { label: "Clockwise", value: "clockwise" },
                { label: "Counterclockwise", value: "counterclockwise" },
            ];
            return;
        }

        plotProps.isVisible = false;
    },
});

export const ShowInLegend = connectToContainer(UnconnectedVisibilitySelect, {
    modifyPlotProps: (props, context, plotProps) => {
        if (
            context.container.type &&
            context.container.type !== "sunburst" &&
            context.container.type !== "treemap"
        ) {
            plotProps.isVisible = context.fullLayout.showlegend;
        }

        return plotProps;
    },
});

export const AxesRange = connectToContainer(UnconnectedAxisRangeValue, {
    modifyPlotProps: (props, context, plotProps) => {
        const { fullContainer } = plotProps;
        if (plotProps.isVisible && fullContainer && fullContainer.autorange) {
            plotProps.isVisible = false;
        }
        return plotProps;
    },
});

export const NTicks = connectToContainer(UnconnectedNumeric, {
    modifyPlotProps: (props, context, plotProps) => {
        const { fullContainer } = plotProps;
        if (
            plotProps.isVisible &&
            fullContainer &&
            fullContainer.tickmode !== "auto"
        ) {
            plotProps.isVisible = false;
        }
        return plotProps;
    },
});

export const DTicks = connectToContainer(UnconnectedAxisRangeValue, {
    modifyPlotProps: (props, context, plotProps) => {
        const { fullContainer } = plotProps;
        if (
            fullContainer &&
            fullContainer._name &&
            (fullContainer._name.startsWith("lat") ||
                fullContainer._name.startsWith("lon"))
        ) {
            // don't mess with visibility on geo axes
            return plotProps;
        }
        if (
            plotProps.isVisible &&
            fullContainer &&
            fullContainer.tickmode !== "linear"
        ) {
            plotProps.isVisible = false;
        }
        return plotProps;
    },
});

export const DTicksInterval = connectToContainer(UnconnectedAxisInterval, {
    modifyPlotProps: (props, context, plotProps) => {
        const { fullContainer } = plotProps;
        if (
            fullContainer &&
            fullContainer._name &&
            (fullContainer._name.startsWith("lat") ||
                fullContainer._name.startsWith("lon"))
        ) {
            // don't mess with visibility on geo axes
            return plotProps;
        }
        if (
            plotProps.isVisible &&
            fullContainer &&
            fullContainer.tickmode !== "linear"
        ) {
            plotProps.isVisible = false;
        }
        return plotProps;
    },
});

class UnconnectedNumericFraction extends UnconnectedNumeric {}
UnconnectedNumericFraction.propTypes = UnconnectedNumeric.propTypes;
UnconnectedNumericFraction.defaultProps = {
    units: "%",
    showSlider: true,
};
UnconnectedNumericFraction.displayName = "UnconnectedNumericFraction";

const numericFractionModifyPlotProps = (props, context, plotProps) => {
    const { attrMeta, fullValue, updatePlot } = plotProps;
    const min = (attrMeta && attrMeta.min) || 0;
    const max = (attrMeta && attrMeta.max) || 1;
    if (isNumeric(fullValue)) {
        plotProps.fullValue = Math.round(
            (100 * (fullValue - min)) / (max - min),
        );
    }

    plotProps.updatePlot = (v) => {
        if (isNumeric(v)) {
            updatePlot((v / 100) * (max - min) + min);
        } else {
            updatePlot(v);
        }
    };
    plotProps.max = 100;
    plotProps.min = 0;
};

export const NumericFraction = connectToContainer(UnconnectedNumericFraction, {
    modifyPlotProps: numericFractionModifyPlotProps,
});
