import type { FC } from "react";
import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Center } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { mergeSamplesWithTasks } from "@app/domain/api/transformer.tsx";
import { routes } from "@app/Routes/routes.ts";
import { GenericErrorUser } from "@app-components/shell/errors/GenericError/GenericError.user.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";

import { getById } from "@mt-tools/iterating/filter.ts";

import { agent } from "src/App/Routes/agentRoutes.tsx";

import { RequestResultInnerContainer } from "./RequestResultInner.container.tsx";

const RequestResultContainerB: FC = () => {
    const params = agent.requestResult.useParams();
    const navigate = useNavigate();
    const { sample: selectedSampleId } = agent.requestResult.useSearch();
    const requestQuery = Request.useGet({
        organizationId: params.orgId,
        measurementGroupId: params.orderId,
    });

    const samplesQuery = Sample.useGetAll({
        params: {
            path: {
                organizationId: params.orgId,
            },
        },
    });

    const tasksOfRequestQuery = MeasurementTask.useGetAll({
        isActive: true,
        path: {
            organizationId: params.orgId,
        },
        query: {
            filter: {
                measurementGroupId: params.orderId,
            },
        },
    });

    const sampleTasksOfGroup = mergeSamplesWithTasks(
        samplesQuery.data ?? [],
        tasksOfRequestQuery.data ?? [],
    );

    const selectedSample = sampleTasksOfGroup.find(getById(selectedSampleId));

    const onSelectSample = (sampleId: string) => {
        void navigate({
            to: routes.agent.requestResult.url,
            params: {
                orgId: params.orgId,
                orderId: params.orderId,
            },
            search: {
                sample: sampleId,
            },
        });
    };

    const selectSampleRef = useLatestRef(onSelectSample);

    useEffect(() => {
        if (!selectedSampleId && tasksOfRequestQuery.data?.length) {
            selectSampleRef.current(tasksOfRequestQuery.data[0].sampleId);
        }
    }, [selectedSampleId, tasksOfRequestQuery.data, selectSampleRef]);

    if (requestQuery.error || samplesQuery.error || tasksOfRequestQuery.error) {
        return <GenericErrorUser />;
    }

    if (
        !requestQuery.data ||
        !samplesQuery.data ||
        !tasksOfRequestQuery.data ||
        !selectedSample
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    return (
        <RequestResultInnerContainer
            sampleTasks={sampleTasksOfGroup}
            measurementGroup={requestQuery.data}
            orgId={params.orgId}
            selectedSample={selectedSample}
            onSelectSample={onSelectSample}
        />
    );
};

RequestResultContainerB.displayName = "RequestResultContainer";

export const RequestResultContainer = () => (
    <ErrorBoundary fallback={<GenericErrorUser hideButton />}>
        <RequestResultContainerB />
    </ErrorBoundary>
);
