import { memo } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { routes } from "@app/Routes/routes.ts";

import {
    type publicSchema,
    selfOrganizationsQueryKey,
    usePublicClient,
} from "src/api/client.ts";

import { Organizations as OrganizationC } from "./Organizations.tsx";

export const OrganizationsPage = memo(() => {
    const navigate = useNavigate();

    const client = usePublicClient();
    const organizationsQuery = useQuery({
        queryKey: selfOrganizationsQueryKey(),
        queryFn: () => {
            return client.GET("/self/organizations");
        },
    });

    const queryClient = useQueryClient();
    const createOrganizationMutation = useMutation({
        mutationFn: (
            data: publicSchema.operations["createOrganization"]["requestBody"]["content"]["application/json"],
        ) => {
            return client.POST("/organizations", {
                body: data,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: selfOrganizationsQueryKey(),
            });
        },
    });

    const onSubmit = async (
        data: publicSchema.operations["createOrganization"]["requestBody"]["content"]["application/json"],
    ) => {
        await createOrganizationMutation.mutateAsync(data);
    };

    const onGoToOrg = (orgId: string) => {
        void navigate({
            to: routes.organizationDetails.url,
            params: {
                orgId,
            },
        });
    };

    return (
        <OrganizationC
            organizations={organizationsQuery.data?.data?.items ?? []}
            onSubmit={onSubmit}
            onGoToOrg={onGoToOrg}
        />
    );
});

OrganizationsPage.displayName = "OrganizationsPage";
