import type { FC } from "react";

import {
    Box,
    ListItem,
    OrderedList,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";

import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";

import { Title } from "@mt-components/Layout/Title.tsx";
import { InfoModal } from "@mt-components/Modal/Modal.tsx";
import {
    Timeline,
    TimelineContent,
    TimelineIcon,
    TimelineItem,
    TimelineSeparator,
    TimelineTrack,
} from "@mt-components/Timeline";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { onEnter } from "@mt-tools/interaction.ts";

import { ESRFIcon } from "@mt-design/images.tsx";

type Props = { title: string; beamtimes: BeamtimeModel[] };
export const BeamtimesPanel: FC<Props> = ({ title, beamtimes }) => {
    const disclosuse = useDisclosure();

    return (
        <>
            <InfoModal
                header=""
                isOpen={disclosuse.isOpen}
                onClose={disclosuse.onClose}
                bodyProps={{
                    p: 8,
                }}
            >
                <Box>
                    Measurements will be performed at beamline ID31 at the
                    European Synchrotron Radiation Facility (ESRF) in Grenoble,
                    France. We will perform two separate ex situ measurement
                    runs at ambient conditions using setups optimized
                    respectively for:
                    <OrderedList py="4">
                        <ListItem py="2">
                            <Text textStyle="H6" fontWeight="600">
                                Total scattering pair distribution function
                                (PDF) analysis
                            </Text>
                            <Text textStyle="T1" fontStyle="italic">
                                Ideal for: local structure characterization,
                                amorphous, nanostructured, and disordered
                                materials
                            </Text>
                        </ListItem>
                        <ListItem>
                            <Text textStyle="H6" fontWeight="600">
                                High-resolution X-ray powder diffraction (XRPD)
                            </Text>
                            <Text textStyle="T1" fontStyle="italic">
                                Ideal for: crystal structure identification and
                                determination, assessment of minority
                                crystalline phases, Rietveld and whole powder
                                pattern modeling (WPPM)
                            </Text>
                        </ListItem>
                    </OrderedList>
                    <Box as="p" py="4">
                        Samples will be measured in transmission geometry using
                        a high-throughput sample changer with an incident X-ray
                        energy of approximately 75.0 keV (λ = 0.165 Å). For
                        sample preparation and configuration, please see our
                        guide: {" link to guide "}. Measured intensities will be
                        collected using a Pilatus CdTe 2M detector positioned
                        with the incident beam in the corner of the detector.
                    </Box>
                    <Box as="p">
                        Backgrounds and NIST SRMs will be measured. Detector
                        calibration, image correction, and integration will be
                        performed using the software pyFAI with corrections for
                        flat-field, geometry, solid-angle, and polarization.
                        Reference validation, reference measurements, corrected
                        and background subtracted data, and pair distribution
                        functions generated from total scattering measurements
                        using the software PDFgetX3 will be provided to the
                        users.
                    </Box>
                </Box>
            </InfoModal>
            <VStack
                alignItems="flex-start"
                fontSize="md"
                overflowY="hidden"
                h="100%"
                w="100%"
            >
                <Title textStyle="h5" title={title} />
                <Box flex={1} overflowY="auto" w="100%" p="2" pr="4">
                    <Timeline>
                        {beamtimes.length === 0 ? (
                            <Text width="120px">
                                New beamtimes are coming soon
                            </Text>
                        ) : (
                            beamtimes.map((beamtime) => (
                                <TimelineItem
                                    p="2"
                                    transition="background-color ease-in-out 150ms"
                                    _hover={{
                                        backgroundColor: "gray.200",
                                    }}
                                    borderRadius="6px"
                                    onKeyDown={(e) => {
                                        onEnter(disclosuse.onOpen)(e);
                                    }}
                                    tabIndex={0}
                                    cursor="pointer"
                                    key={beamtime.id}
                                    onClick={disclosuse.onOpen}
                                >
                                    <TimelineSeparator>
                                        <TimelineIcon
                                            width="48px"
                                            height="64px"
                                        >
                                            <ESRFIcon />
                                            {/*<Icon.Beamtime />*/}
                                        </TimelineIcon>
                                        <TimelineTrack />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Text textStyle="h6" color="gray.800">
                                            {beamtime.date
                                                ? formatDate(beamtime.date)
                                                : null}
                                        </Text>
                                    </TimelineContent>
                                </TimelineItem>
                            ))
                        )}
                    </Timeline>
                </Box>
            </VStack>
        </>
    );
};
