import type { FC } from "react";
import { useRef, useState } from "react";

import { Icon } from "components/icons.tsx";

import { Box, IconButton, Tag } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";
import type { Table } from "@tanstack/react-table";

import type {
    PackageModel,
    ShippingStatusType,
} from "@app/domain/api/package.ts";
import {
    carrierCodeToLabel,
    getColorSchemeForShippingStatus,
} from "@app/domain/api/package.ts";
import { routes } from "@app/Routes/routes.ts";
import { ShippingStatusFilter } from "@app-components/package/ShippingStatusFilter.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";
import { useDebouncedSearch } from "@mt-hooks/useSearch.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

type ExtendedPackageModel = PackageModel & {
    organizationName: string;
    requestName: string;
};

type TableMeta = {
    onGoToRequest: (params: {
        requestId: string;
        organizationId: string;
    }) => void;
};

const columns: ColumnDef<ExtendedPackageModel>[] = [
    {
        accessorKey: "organizationId" satisfies keyof ExtendedPackageModel,
        header: "Organization",
        cell: (cell) => {
            return <>{cell.row.original.organizationName}</>;
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "requestName" satisfies keyof ExtendedPackageModel,
        header: "Request Name",
        cell: (cell) => {
            const meta = cell.table.options.meta as TableMeta;
            const onClick = () => {
                const measurementGroupId = cell.row.original.measurementGroupId;
                const organizationId = cell.row.original.organizationId;
                if (!measurementGroupId) {
                    return;
                }
                meta.onGoToRequest({
                    requestId: measurementGroupId,
                    organizationId,
                });
            };

            return (
                <Box onClick={onClick}>
                    {cell.row.original.requestName}
                    <IconButton
                        ml="12px"
                        colorScheme="blue"
                        size="xs"
                        onClick={onClick}
                        aria-label="trackling link"
                        icon={<Icon.Link />}
                    />
                </Box>
            );
        },
        thProps: makeWidth("80px"),
    },
    {
        accessorKey: "carrier" satisfies keyof ExtendedPackageModel,
        header: "Carrier",
        cell: (cell) => {
            return carrierCodeToLabel(cell.row.original.carrier);
        },
        thProps: makeWidth("80px"),
    },
    {
        accessorKey: "status" satisfies keyof ExtendedPackageModel,
        header: "Status",
        cell: (cell) => {
            return (
                <Tag
                    bg={getColorSchemeForShippingStatus(cell.row.original)}
                    color="white"
                >
                    {cell.row.original.status}
                </Tag>
            );
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "trackingLink" satisfies keyof ExtendedPackageModel,
        header: "Tracking",
        cell: (cell) => {
            const onClick = () =>
                window.open(cell.row.original.trackingLink, "_blank");

            return (
                <IconButton
                    colorScheme="blue"
                    size="xs"
                    onClick={onClick}
                    aria-label="trackling link"
                    icon={<Icon.Link />}
                />
            );
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "message" satisfies keyof ExtendedPackageModel,
        header: "Message",
        cell: (cell) => {
            return cell.row.original.message;
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "updatedAt" satisfies keyof ExtendedPackageModel,
        header: "Updated",
        cell: (cell) => {
            return formatDate(cell.row.original.updatedAt);
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "createdAt" satisfies keyof ExtendedPackageModel,
        header: "Created",
        cell: (cell) => {
            return formatDate(cell.row.original.createdAt);
        },
        thProps: makeWidth("140px"),
    },
] satisfies ColumnDef<ExtendedPackageModel>[];

const initialState = {
    sorting: [
        {
            id: "updatedAt",
            desc: true,
        },
    ],
};

type Props = {
    packages: ExtendedPackageModel[];
    selectedSamples?: {
        list: string[];
        obj: Record<string, boolean>;
    };
    onRowClick: (row: ExtendedPackageModel) => void;
};

export const PackagesTable: FC<Props> = ({ packages, onRowClick }) => {
    const navigate = useNavigate();
    const tableRef = useRef<Table<ExtendedPackageModel>>(null);
    const [ref] = useScrollbar<HTMLDivElement>();

    const [statusFilter, setStatusFilter] = useState<
        ShippingStatusType | undefined
    >();

    const {
        filteredOrders: searched,
        onClear,
        onChange,
    } = useDebouncedSearch<ExtendedPackageModel>({
        items: packages,
        keys: ["status", "carrier", "message"],
    });

    const filtered = searched.filter((p) => {
        if (!statusFilter) {
            return true;
        }

        return p.status === statusFilter;
    });

    const meta: TableMeta = {
        onGoToRequest: (params: {
            requestId: string;
            organizationId: string;
        }) => {
            void navigate({
                to: routes.agent.measurementGroupDetail.url,
                params: {
                    orderId: params.requestId,
                    orgId: params.organizationId,
                },
            });
        },
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            flex={1}
            h="100%"
            alignItems="stretch"
            overflow="hidden"
            w="100%"
        >
            <SearchFilterBar
                value={undefined}
                onReset={onClear}
                onChange={onChange}
                placeholder="Search by message, status, carrier"
                filter={
                    <ShippingStatusFilter
                        value={statusFilter}
                        onChange={setStatusFilter}
                    />
                }
            />
            <Box ref={ref} flex={1} width="100%" overflow="hidden">
                <DataTable<ExtendedPackageModel>
                    variant="unstyled"
                    instanceRef={tableRef}
                    isSortable
                    columns={columns}
                    data={filtered}
                    meta={meta}
                    onRowClick={onRowClick}
                    getRowId={(row) => row.id}
                    initialState={initialState}
                />
            </Box>
        </Box>
    );
};
