import { type AnyRoute, NotFoundRoute, Route } from "@tanstack/react-router";

import AuthPage from "@app/pages/common/auth/Auth.tsx";
import { SigninCallbackPage } from "@app/pages/common/auth/SigninCallback.tsx";
import { NotFoundPage } from "@app/pages/common/notFound/NotFound.page.tsx";
import { AccountPage } from "@app/pages/common/profile/Account.page.tsx";
import { notAuthGoToLogin } from "@app/Routes/beforeLoad.tsx";
import { routes } from "@app/Routes/routes.ts";

export const createNotFoundRoute = (root: AnyRoute) =>
    new NotFoundRoute({
        getParentRoute: () => root,
        component: NotFoundPage,
    });

export const createProfileRoute = (root: AnyRoute) =>
    new Route({
        beforeLoad: notAuthGoToLogin,
        getParentRoute: () => root,
        path: routes.profile.url,
        component: AccountPage,
    });

export const createSigningCallback = (root: AnyRoute) =>
    new Route({
        getParentRoute: () => root,
        path: routes.signinCallback.url,
        component: SigninCallbackPage,
    });

export const createLoginRoute = (root: AnyRoute) =>
    new Route({
        getParentRoute: () => root,
        path: routes.login.url,
        component: AuthPage,
    });
