import { useLocalStorage } from "react-use";

export const useDisclosureWithStorage = (key: string, defaultValue = true) => {
    const [value, setValue] = useLocalStorage(key, defaultValue);
    const disclosure = {
        isOpen: Boolean(value),
        onOpen: () => {
            setValue(true);
        },
        onClose: () => {
            setValue(false);
        },
        onToggle: () => {
            setValue(!value);
        },
    };

    return disclosure;
};
