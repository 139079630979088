import type { FC } from "react";
import { useState } from "react";

import { Box, Center, Text, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { OrganizationsTable } from "@app/pages/user/organizations/Organizations.table.tsx";
import { routes } from "@app/Routes/routes.ts";
import { OrganizationModal } from "@app-components/modals/organization/Organization.modal.tsx";
import { OrganizationEmptyState } from "@app-components/organization/OrganizationEmptyState.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { Icon } from "@mt-design/icons.tsx";

import {
    type publicSchema,
    selfOrganizationsQueryKey,
    usePublicClient,
} from "src/api/client";
import { useUserContext } from "src/App/contexts/useUserContext";
import { useSnackbar } from "src/packages/hooks/useSnackbar";

type Props = {
    organizations: publicSchema.components["schemas"]["Organization"][];
    onSubmit: (
        data: publicSchema.operations["createOrganization"]["requestBody"]["content"]["application/json"],
    ) => Promise<void>;
    onGoToOrg: (orgId: string) => void;
};

export const Organizations: FC<Props> = ({
    organizations,
    onSubmit: submit,
    onGoToOrg,
}) => {
    const disclosure = useDisclosure();
    const [toUpdate, setToUpdate] = useState<OrganizationModel | undefined>(
        undefined,
    );

    const snackbar = useSnackbar();

    const user = useUserContext();
    const client = usePublicClient();
    const queryClient = useQueryClient();

    const organizationPatchMutation = useMutation({
        mutationFn: (data: { organizationId: string; name: string }) =>
            client.PATCH("/organizations/{organizationId}", {
                params: { path: { organizationId: data.organizationId } },
                body: { name: data.name },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: selfOrganizationsQueryKey(),
            });
        },
    });
    const leaveOrganiationMutation = useMutation({
        mutationFn: (vars: { organizationId: string }) =>
            client
                .DELETE("/organizations/{organizationId}/authzs/{userId}", {
                    params: { path: { userId: user.id, ...vars } },
                })
                .then((x) => {
                    if (x.error) throw new Error();
                    return x;
                }),
        onSuccess: (_data, vars) => {
            snackbar.success(
                `You left ${organizations.find((x) => x.id === vars.organizationId)?.name} organization`,
            );
            queryClient.invalidateQueries({
                queryKey: selfOrganizationsQueryKey(),
            });
        },
        onError: (_error, vars) => {
            snackbar.error(
                `Can't leave ${organizations.find((x) => x.id === vars.organizationId)?.name} organization. Please check organization settings`,
            );
        },
    });

    const onUpdate = (data: { name: string }) => {
        if (toUpdate?.id) {
            organizationPatchMutation.mutate({
                organizationId: toUpdate.id,
                ...data,
            });
        }

        setToUpdate(undefined);
    };

    const actions = [
        {
            id: "create",
            label: (
                <Box display="flex" gap="8px" alignItems="center">
                    <Icon.Plus size="20px" /> Create
                </Box>
            ),
            isPrimary: true,
            onClick: disclosure.onOpen,
        },
    ];

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
    ];

    return (
        <>
            {disclosure.isOpen && (
                <OrganizationModal
                    isOpen={disclosure.isOpen}
                    onCreate={(data) => {
                        submit(data);
                        disclosure.onToggle();
                    }}
                    onCancel={disclosure.onOpen}
                />
            )}
            {Boolean(toUpdate) && (
                <OrganizationModal
                    isOpen={Boolean(toUpdate)}
                    onUpdate={onUpdate}
                    onCancel={() => setToUpdate(undefined)}
                    initialData={toUpdate}
                />
            )}

            <PageLayout>
                <Box
                    overflow="hidden"
                    mx="auto"
                    display="flex"
                    flexDirection="column"
                    w="100%"
                    h="100%"
                >
                    {organizations.length === 0 ? (
                        <Center w="100%" h="100%">
                            <OrganizationEmptyState
                                onClick={() => disclosure.onOpen()}
                            />
                        </Center>
                    ) : (
                        <>
                            <PageHeader crumbs={crumbs} actions={actions} />
                            <OrganizationsTable
                                onGoToOrg={onGoToOrg}
                                onEdit={setToUpdate}
                                onLeave={(organizationId) => {
                                    leaveOrganiationMutation.mutate({
                                        organizationId,
                                    });
                                }}
                                items={organizations}
                                onDelete={() => {
                                    throw new Error("Not Implemented");
                                }}
                            />
                        </>
                    )}
                </Box>
            </PageLayout>
        </>
    );
};

Organizations.displayName = "Organization";
