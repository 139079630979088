import type { FC } from "react";
import { memo } from "react";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import { Request } from "@app/domain/api/request.ts";
import { Requests } from "@app/pages/user/requests/Requests.tsx";
import { GenericErrorUser } from "@app-components/shell/errors/GenericError/GenericError.user.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { isFetchError404 } from "@mt-tools/fetch.ts";

export const RequestsContainer: FC = memo(() => {
    const { currentOrg } = useOrgContext();
    const { data: groups, error: groupError } = Request.useGetAllB({
        path: {
            organizationId: currentOrg,
        },
    });

    if (isFetchError404(groupError)) {
        return <NotFound />;
    }

    if (groupError) {
        return <GenericErrorUser />;
    }

    if (groups) {
        return <Requests groups={groups.items} />;
    }
    return null;
});

RequestsContainer.displayName = "MeasurementGroupContainer";
