import { Box, Center, Text } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

export const NoSelectionView = () => (
    <Center h="100%">
        <Box display="flex" flexDirection="column" alignItems="center">
            <Icon.SamplesImage width="90px" h="90px" />
            <Center mt="16px">
                <Text color="gray.600" textStyle="T2">
                    Select an item to see to see details
                </Text>
            </Center>
        </Box>
    </Center>
);
