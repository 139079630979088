import { last, values } from "ramda";

import type { ArtifactModel } from "@app/domain/api/artifact.ts";

export const defaultStringRanker = (cand1: string, cand2: string) => {
    return cand1.localeCompare(cand2);
};

export const isDetectorImage = (a: ArtifactModel) =>
    a.name === "raw-image.tiff";

export const isPattern = (a: ArtifactModel) =>
    a.name === "integrated.2th.subtracted_bkg.corr.xye";

export const renameArtifact = (artifact: ArtifactModel) => {
    if (isPattern(artifact)) {
        return "Corrected Pattern";
    }
    if (isDetectorImage(artifact)) {
        return "Raw Image";
    }

    return artifact.name;
};

export const PDFType = {
    gr: "gr",
    xye: "xye",
    sq: "sq",
    fq: "fq",
} as const;

export type PDFTypes = keyof typeof PDFType;

export const getFileExtension = (a: ArtifactModel) =>
    last(a.name.split(".")) ?? "";

export const isTiffArtifact = (a: ArtifactModel) => a.format === "tiff";
export const thumbnailToUrl = (a: ArtifactModel) => ({
    src: a.thumbnailUrl ?? "",
});

export function isPDFType(value: string): value is PDFTypes {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
    return values(PDFType).includes(value as any);
}

export function isXYEArtifact(a: { format: string }) {
    return a.format === PDFType.xye;
}

export function isGRArtifact(a: { format: string }) {
    return a.format === PDFType.gr;
}

export function isFQArtifact(a: { format: string }) {
    return a.format === PDFType.fq;
}

export function isSQArtifact(a: { format: string }) {
    return a.format === PDFType.sq;
}

function extractQmaxNumber(input: string) {
    const regex = /\.qmax(\d+)\./;
    const match = input.match(regex);

    if (match?.[1]) {
        return parseInt(match[1], 10);
    }

    return null;
}
export function renamePDFArtifacts(a: { name: string }) {
    if (a.name.endsWith("gr")) {
        const qmaxx = extractQmaxNumber(a.name);

        const qmaxParam = qmaxx ? `[Qmax ${qmaxx}]` : "";

        return a.name.includes("lorch")
            ? `Pair distribution function G(r) ${qmaxParam} [Lorch]`
            : `Pair distribution function G(r) ${qmaxParam}`;
    }

    if (a.name.endsWith("xye")) {
        return `Intensities I(Q) [${a.name}]`;
    }

    if (a.name.endsWith("sq")) {
        return `Structure function S(Q)`;
    }

    if (a.name.endsWith("fq")) {
        return `Reduced structure function F(Q)`;
    }

    return a.name;
}

export function isSQWithQmaxSmaller30(a: ArtifactModel) {
    return (
        a.format === "sq" &&
        (a.name.includes(".qmax15.") ||
            a.name.includes(".qmax20.") ||
            a.name.includes(".qmax25."))
    );
}

export function mapPdfTypeToLabel(key: PDFTypes) {
    switch (key) {
        case "gr":
            return "Pair distribution function G(r)";
        case "xye":
            return "Intensities I(Q)";
        case "sq":
            return "Structure factor S(Q)";
        case "fq":
            return "Reduced structure factor F(Q)";
    }
}
