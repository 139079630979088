import { useQuery } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";

export type SimpleQueryOptions = {
    enabled?: boolean;
    staleTime?: number;
};

export const usePublicAccessQuery = (queryOptions: SimpleQueryOptions) => {
    const api = useAPI();
    return useQuery({
        ...queryOptions,
        queryKey: ["public-api-access"],
        queryFn: () => api.publicClient.GET("/access").then((x) => x.response),
    });
};

export const useAgentAccessQuery = (queryOptions: SimpleQueryOptions) => {
    const api = useAPI();

    return useQuery({
        ...queryOptions,
        queryKey: ["agent-api-access"],
        queryFn: () => api.agentClient.GET("/access").then((x) => x.response),
    });
};
