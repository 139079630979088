import type { FC, PropsWithChildren } from "react";

import {
    Avatar,
    Box,
    Center,
    Divider,
    HStack,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { type StackProps } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { isNotFoundError } from "@app/domain/api/errors.ts";
import { agent } from "@app/Routes/agentRoutes.tsx";
import { UploadAvatarModal } from "@app-components/modals/UploadAvatarModal.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { DataTable } from "@mt-components/DataTable.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { GenericError } from "@mt-components/states/Error/GenericError.tsx";
import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import { usersOrganizationsKey, usersQueryKey } from "src/api/client";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";

export const UserPage = () => {
    const userId = agent.user.useParams({ select: (x) => x.userId });
    const uploadAvatarDisclosure = useDisclosure();

    const api = useAPI();
    const userQuery = useQuery({
        queryKey: usersQueryKey(userId),
        queryFn: () => {
            return api.agentClient.GET("/users/{userId}", {
                params: { path: { userId } },
            });
        },
    });

    const organizationsQuery = useQuery({
        queryKey: usersOrganizationsKey(userId),
        queryFn: () =>
            api.agentClient.GET("/index/organizations", {
                params: { query: { first: 1000, userId } },
            }),
    });

    if (userQuery.isPending) {
        return (
            <Center h="100%" w="100%">
                <LoadingSpinner />
            </Center>
        );
    }

    if (userQuery.error && isNotFoundError(userQuery.error)) {
        return <NotFound />;
    }

    if (userQuery.error) {
        return <GenericError />;
    }

    const user = userQuery.data.data;

    if (!user) {
        return <>Something went wrong. Try reloading the page</>;
    }

    return (
        <PageLayout>
            <VStack h="100%" alignItems="stretch">
                <PageHeader
                    actionsId="user-page-header"
                    crumbs={[
                        {
                            id: "user",
                            label: "Users",
                        },
                        {
                            id: "user",
                            label: user.name ? user.name : user.email,
                        },
                    ]}
                    subtitle="User profile"
                />
                <Box w="100%">
                    <Text textStyle="h5" fontWeight="600">
                        General
                    </Text>

                    <PropertyContainer name="Photo">
                        <Box
                            borderRadius="100%"
                            as="button"
                            onClick={uploadAvatarDisclosure.onOpen}
                            p="2px"
                        >
                            <Avatar size="xl" src={user.avatarUrl} />
                        </Box>
                    </PropertyContainer>

                    <PropertyContainer name="Name">
                        <Box>{user.name}</Box>
                    </PropertyContainer>

                    <PropertyContainer name="Email">
                        <Box>{user.email}</Box>
                    </PropertyContainer>

                    {uploadAvatarDisclosure.isOpen && (
                        <UploadAvatarModal
                            isOpen={uploadAvatarDisclosure.isOpen}
                            onClose={uploadAvatarDisclosure.onClose}
                            userId={userId}
                        />
                    )}
                </Box>
                <Divider></Divider>
                <Box flex="1" w="100%" overflow="hidden">
                    <Text mb="8" textStyle="h6">
                        Organizations
                    </Text>

                    <DataTable
                        initialState={{
                            sorting: [
                                {
                                    id: "createdAt",
                                    desc: true,
                                },
                            ],
                        }}
                        variant="unstyled"
                        columns={[
                            {
                                accessorKey: "name",
                                header: "Name",
                                cell: (cell) => (
                                    <OrganizationLink
                                        label={
                                            cell.row.original.organization.name
                                        }
                                        organizationId={
                                            cell.row.original.organization.id
                                        }
                                    ></OrganizationLink>
                                ),
                            },
                            {
                                accessorKey: "createdAt",
                                header: "Created",
                                cell: (cell) => {
                                    return formatDate(
                                        cell.row.original.organization
                                            .createdAt,
                                    );
                                },
                            },
                        ]}
                        data={organizationsQuery.data?.data?.items ?? []}
                        isSortable
                    />
                </Box>
            </VStack>
        </PageLayout>
    );
};

const PropertyContainer: FC<
    PropsWithChildren<{
        name: string;
    }> &
        StackProps
> = (props) => {
    return (
        <HStack margin="20px 0 20px 0" textStyle="T2">
            <Box w="100%" maxW="200px" textStyle="T2" fontWeight="600">
                {props.name}
            </Box>
            <HStack minW="200px" w="100%" maxW="500px">
                {props.children}
            </HStack>
        </HStack>
    );
};
