import type { FC } from "react";

import { Box, Button, Center, Text, VStack } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

const errorQuotes: { text: string; author: string; prof: string }[] = [
    {
        text: "Mistakes are a fact of life. It is the response to the error that counts.",
        author: "Nikki Giovanni",
        prof: "Poet & Writer [1943]",
    },
    {
        text: "Any man can make mistakes, but only an idiot persists in his error.",
        author: "Marcus Tullius Cicero",
        prof: "Philosopher [106 - 43 BC]",
    },
    {
        text: "Truth emerges more readily from error than from confusion.",
        author: "Francis Bacon",
        prof: "Philosopher [1561 - 1626]",
    },
    {
        text: "An error doesn't become a mistake until you refuse to correct it.",
        author: "Orlando Aloysius Battista",
        prof: "Canadian Chemist [1917 - 1995]",
    },
    {
        text: "To me, error analysis is the sweet spot for improvement.",
        author: "Donald Norman",
        prof: "Researcher Cognitive Sciences [1935]",
    },
];

function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const GenericError: FC<{
    onClick?: () => void;
}> = (props) => {
    const errorQuote = errorQuotes[getRandomInt(0, 4)] ?? errorQuotes[0];
    return (
        <Center w="100%" h="100%">
            <VStack alignItems="flex-start" gap="12px">
                <Box alignItems="center" display="flex" gap="10px">
                    <Box width="48px" height="48px">
                        <Icon.Error size="sm" />
                    </Box>
                    <Text as="h2" textStyle="h3">
                        An error occurred
                    </Text>
                </Box>

                <Box mt="24px" mb="36px">
                    <Text as="p" maxW="440px" textStyle="subtitle">
                        {errorQuote.text}
                    </Text>
                    <Box mt="14px">
                        <Text as="p" textStyle="h6">
                            {errorQuote.author}
                        </Text>
                        <Text as="p" textStyle="T2">
                            {errorQuote.prof}
                        </Text>
                    </Box>
                </Box>

                {props.onClick && (
                    <Button colorScheme="secondary" onClick={props.onClick}>
                        Try again later
                    </Button>
                )}
            </VStack>
        </Center>
    );
};
