import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export const MeasurementGroupEmptyState = () => (
    <EmptyState
        icon={(props) => <Icon.Request {...props} />}
        heading="No requests"
        body="No requests scheduled yet"
    />
);
