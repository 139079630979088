import {
    ColorscalePicker,
    Dropdown,
    Flaglist,
    FontSelector,
    LineDashSelector,
    LineShapeSelector,
    MultiColorPicker,
    Numeric,
    NumericFraction,
    PlotlySection,
    Radio,
    TextEditor,
    TraceAccordion,
    TraceTypeSection,
    VisibilitySelect,
    MarkerColor,
    MarkerSize,
    SymbolSelector,
    TraceMarkerSection,
} from "../components";
import { ShowInLegend } from "../components/fields/derived";

const StyleTracesPanel = () => (
    <TraceAccordion>
        <NumericFraction label={"Trace Opacity"} attr="opacity" />
        <PlotlySection name={"Legend"}>
            <ShowInLegend
                label={"Show in Legend"}
                attr="showlegend"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
                showOn={true}
            ></ShowInLegend>
        </PlotlySection>

        <MultiColorPicker label={"Color"} attr="color" />
        <PlotlySection name={"Decreasing Marker Styles"}>
            <TextEditor label={"Name"} attr="decreasing.name" richTextOnly />
            <Numeric label={"Width"} attr="decreasing.line.width" />
            <MultiColorPicker
                label={"Line Color"}
                attr="decreasing.line.color"
            />
            <MultiColorPicker
                label={"Marker Color"}
                attr="decreasing.marker.color"
            />
            <MultiColorPicker
                label={"Line Color"}
                attr="decreasing.marker.line.color"
            />
            <Numeric label={"Line Width"} attr="decreasing.marker.line.width" />
            <MultiColorPicker
                label={"Fill Color"}
                attr="decreasing.fillcolor"
            />
            <LineDashSelector label={"Type"} attr="decreasing.line.dash" />
            <Radio
                label={"Show in Legend"}
                attr="decreasing.showlegend"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
            />
        </PlotlySection>
        <PlotlySection name={"Subplot Title"} attr="title.text">
            <TextEditor label={"Name"} attr="title.text" />
            <Dropdown
                label="Title Position"
                attr="titleposition"
                options={[
                    { label: "Top Left", value: "top left" },
                    { label: "Top Center", value: "top center" },
                    { label: "Top Right", value: "top right" },
                    { label: "Middle Center", value: "middle center" },
                    { label: "Bottom Left", value: "bottom left" },
                    { label: "Bottom Center", value: "bottom center" },
                    { label: "Bottom Right", value: "bottom right" },
                ]}
            />
            <FontSelector
                label={"Typeface"}
                attr="title.font.family"
                clearable={false}
            />
            <Numeric label={"Font Size"} attr="title.font.size" units="px" />
        </PlotlySection>

        <PlotlySection name={"Display"}>
            <Flaglist
                attr="mode"
                options={[
                    { label: "Points", value: "markers" },
                    { label: "Lines", value: "lines" },
                    { label: "Text", value: "text" },
                ]}
            />
        </PlotlySection>

        <TraceMarkerSection>
            <Radio
                label={"Order"}
                attr="sort"
                options={[
                    { label: "Sorted", value: true },
                    { label: "Unsorted", value: false },
                ]}
            />
            <Radio
                label={"Direction"}
                attr="direction"
                options={[
                    { label: "Clockwise", value: "clockwise" },
                    { label: "Counterclockwise", value: "counterclockwise" },
                ]}
            />
            <Numeric label={"Rotation"} attr="rotation" />
            <NumericFraction label={"Hole Size"} attr="hole" />
            <NumericFraction label={"Pull"} attr="pull" />
            <Dropdown
                options={[
                    { label: "Show All", value: "all" },
                    { label: "Outliers", value: "outliers" },
                    {
                        label: "Suspected Outliers",
                        value: "suspectedoutliers",
                    },
                    { label: "Hide", value: false },
                ]}
                attr="boxpoints"
                clearable={false}
            />
            <Dropdown
                options={[
                    { label: "Show All", value: "all" },
                    { label: "Outliers", value: "outliers" },
                    {
                        label: "Suspected Outliers",
                        value: "suspectedoutliers",
                    },
                    { label: "Hide", value: false },
                ]}
                attr="points"
                clearable={false}
            />
            <NumericFraction label={"Jitter"} attr="jitter" />
            <Numeric label={"Position"} attr="pointpos" step={0.1} showSlider />
            <MarkerColor
                suppressMultiValuedMessage
                label={"Color"}
                attr="marker.color"
                labelWidth={80}
            />
            <NumericFraction label={"Point Opacity"} attr="marker.opacity" />
            <MarkerSize label={"Size"} attr="marker.size" />
            {/*<NumericReciprocal*/}
            {/*    label={"Size Scale"}*/}
            {/*    attr="marker.sizeref"*/}
            {/*    step={0.2}*/}
            {/*    stepmode="relative"*/}
            {/*/>*/}
            <Radio
                label={"Size Mode"}
                attr="marker.sizemode"
                options={[
                    { label: "Area", value: "area" },
                    { label: "Diameter", value: "diameter" },
                ]}
            />
            <Numeric label={"Minimum Size"} attr="marker.sizemin" />
            <SymbolSelector label={"Symbol"} attr="marker.symbol" />
            <Numeric label={"Border Width"} attr="marker.line.width" />
            <MultiColorPicker label={"Border Color"} attr="marker.line.color" />
            <Numeric
                label={"Max Number of Points"}
                attr="marker.maxdisplayed"
            />
        </TraceMarkerSection>

        <PlotlySection name={"Ticks"}>
            <Numeric label={"Width"} attr="tickwidth" />
        </PlotlySection>

        <TraceTypeSection
            name={"Lines"}
            traceTypes={[
                "scatter",
                "contour",
                "scatterternary",
                "scatterpolar",
                "scatterpolargl",
                "box",
                "violin",
                "scatter3d",
                "scattergl",
                "scattergeo",
                "parcoords",
                "parcats",
                "scattermapbox",
                "scattercarpet",
                "contourcarpet",
                "ohlc",
                "candlestick",
                "histogram2dcontour",
            ]}
            mode="trace"
        >
            <Numeric label={"Width"} attr="line.width" />
            <MultiColorPicker label={"Color"} attr="line.color" />
            <Radio
                label={"Color Bar"}
                attr="line.showscale"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
            />
            <ColorscalePicker label={"Colorscale"} attr="line.colorscale" />
            <LineDashSelector label="Type" attr="line.dash" stroke="#FF0000" />
            <LineShapeSelector label="Shape" attr="line.shape" />
            <Numeric
                label={"Smoothing"}
                attr="line.smoothing"
                showSlider
                step={0.1}
            />
            <Radio
                label={"Connect Gaps"}
                attr="connectgaps"
                options={[
                    { label: "Connect", value: true },
                    { label: "Blank", value: false },
                ]}
            />
        </TraceTypeSection>
        <PlotlySection name={"Notches"}>
            <Radio
                attr="notched"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
            />
            <Numeric
                label={"Width"}
                attr="notchwidth"
                min={0}
                max={0.5}
                step={0.1}
            />
        </PlotlySection>

        <PlotlySection name={"Colorscale"}>
            <ColorscalePicker label={"Colorscale"} attr="colorscale" />
            <Radio
                label={"Color Bar"}
                attr="showscale"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
            />
            <Radio
                label={"Orientation"}
                attr="reversescale"
                options={[
                    { label: "Normal", value: false },
                    { label: "Reversed", value: true },
                ]}
            />
            <VisibilitySelect
                label={"Range"}
                attr="zauto"
                options={[
                    { label: "Auto", value: true },
                    { label: "Custom", value: false },
                ]}
                showOn={false}
                defaultOpt={true}
            >
                <Numeric label={"Min"} attr="zmin" />
                <Numeric label={"Max"} attr="zmax" />
            </VisibilitySelect>
            <VisibilitySelect
                label={"Range"}
                attr="cauto"
                options={[
                    { label: "Auto", value: true },
                    { label: "Custom", value: false },
                ]}
                showOn={false}
                defaultOpt={true}
            >
                <Numeric label={"Min"} attr="cmin" />
                <Numeric label={"Max"} attr="cmax" />
            </VisibilitySelect>
            <Radio
                label={"Smoothing"}
                attr="zsmooth"
                options={[
                    { label: "On", value: "best" },
                    { label: "Off", value: false },
                ]}
            />
        </PlotlySection>
    </TraceAccordion>
);

export default StyleTracesPanel;
