import type { FC } from "react";
import { memo } from "react";

import { Box, Center } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { RouteApi } from "@tanstack/react-router";

import { Beamtime } from "@app/domain/api/agent/beamtime.ts";

import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { beamtimesQueryKey } from "src/api/client.ts";
import { useAPI } from "src/App/contexts/APIContext/useApiContext.tsx";

import { BeamtimeDetails } from "./BeamtimeDetails.tsx";

const routeApi = new RouteApi({ id: "/agent/beamtimes/$beamtimeId" });

export const BeamtimeDetailsContainer: FC = () => {
    const params = routeApi.useParams();

    const api = useAPI();
    const beamtimeStats = useQuery({
        queryKey: [...beamtimesQueryKey(params.beamtimeId), "stats"],
        queryFn: async () => {
            return await api.agentClient.GET("/beamtimes/{beamtimeId}/stats", {
                params: {
                    path: {
                        beamtimeId: params.beamtimeId,
                    },
                },
            });
        },
    });
    const beamtimeSamples = useQuery({
        queryKey: [...beamtimesQueryKey(params.beamtimeId), "samples"],
        queryFn: async () => {
            return await api.agentClient.GET(
                "/beamtimes/{beamtimeId}/samples",
                {
                    params: {
                        path: {
                            beamtimeId: params.beamtimeId,
                        },
                        query: {
                            first: 1000,
                        },
                    },
                },
            );
        },
    });
    const beamtimeRequests = useQuery({
        queryKey: [...beamtimesQueryKey(params.beamtimeId), "requests"],
        queryFn: async () => {
            return await api.agentClient.GET(
                "/beamtimes/{beamtimeId}/measurement-requests",
                {
                    params: {
                        path: { beamtimeId: params.beamtimeId },
                        query: {
                            first: 1000,
                        },
                    },
                },
            );
        },
    });

    const beamtimeQuery = Beamtime.useAdminGet({
        beamtimeId: params.beamtimeId,
    });

    if (
        (!beamtimeQuery.data && beamtimeQuery.isLoading) ||
        (!beamtimeStats.data && beamtimeStats.isLoading) ||
        (!beamtimeSamples.data && beamtimeSamples.isLoading) ||
        (!beamtimeRequests.data && beamtimeRequests.isLoading)
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    if (
        !beamtimeQuery.data?.data ||
        !beamtimeStats.data?.data ||
        !beamtimeSamples.data?.data ||
        !beamtimeRequests.data?.data
    ) {
        return <Box>Beamtime not found</Box>;
    }

    return (
        <BeamtimeDetails
            beamtime={beamtimeQuery.data.data}
            beamtimeStats={beamtimeStats.data.data}
            beamtimeSamples={beamtimeSamples.data.data.items}
            beamtimeRequests={beamtimeRequests.data.data.items}
        />
    );
};

BeamtimeDetailsContainer.displayName = "BeamtimeDetailsContainer";

export const BeamtimeDetailsPage = memo(() => {
    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <BeamtimeDetailsContainer />
            </Box>
        </PageLayout>
    );
});

BeamtimeDetailsPage.displayName = "BeamtimeDetailsPage";
