import type { FC } from "react";
import { memo, useCallback, useMemo } from "react";

import { fromPairs } from "ramda";

import { Center, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { Organization } from "@app/domain/api/organization.ts";
import { type CreateSamplePayload, Sample } from "@app/domain/api/sample.ts";
import { AdminOrgContainer } from "@app/pages/agent/samples/AdminOrgContainer.tsx";
import { routes } from "@app/Routes/routes.ts";
import type { SampleModalProps } from "@app-components/modals/sample/Sample.modal.tsx";
import { SampleModal } from "@app-components/modals/sample/Sample.modal.tsx";

import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { byId } from "@mt-tools/iterating/filter.ts";

import { agent } from "src/App/Routes/agentRoutes.tsx";

import { Samples } from "./Samples.tsx";

export const SamplesContainer: FC = memo(() => {
    const snackbar = useSnackbar();
    const createSampleDisclosure = useDisclosure();

    const samplesQuery = Sample.useAdminGetAll();
    const createSample = Sample.useCreate();
    const organizationsQuery = Organization.useAdminGetAll();

    const organizationsById = byId(organizationsQuery.data || []);
    const { samples: selectedSampleIds } = agent.samples.useSearch();
    const navigate = useNavigate();

    const sampleSelection = useMemo(
        () => ({
            list: selectedSampleIds,
            byId: fromPairs(selectedSampleIds.map((id) => [id, true])),
        }),
        [selectedSampleIds],
    );

    const onSelectSamples = useCallback(
        (sampleIds: string[]) => {
            void navigate({
                to: routes.agent.samples.url,
                search: {
                    samples: sampleIds,
                },
            });
        },
        [navigate],
    );

    const onCreateSample = useCallback(
        async (params: {
            originalArgs: [CreateSamplePayload];
            orgId: string;
        }) => {
            if (!params.orgId) {
                return;
            }

            const response = await createSample.mutateAsync({
                params: {
                    path: {
                        organizationId: params.orgId,
                    },
                },
                body: params.originalArgs[0],
            });

            if (!response.response.ok) {
                snackbar.error({ title: "Could not create sample" });
                return;
            }
            snackbar.success({ title: "Sample created" });
        },
        [createSample, snackbar],
    );

    return (
        <>
            {samplesQuery.data ? (
                <>
                    {createSampleDisclosure.isOpen && (
                        <AdminOrgContainer<SampleModalProps>
                            Component={SampleModal}
                            organizations={organizationsQuery.data ?? []}
                            isOpen={createSampleDisclosure.isOpen}
                            onClose={createSampleDisclosure.onClose}
                            onSubmit={onCreateSample}
                        />
                    )}
                    <Samples
                        sampleSelection={sampleSelection}
                        samples={samplesQuery.data.map((s) => ({
                            ...s,
                            organizationName:
                                organizationsById[s.organizationId]?.name,
                        }))}
                        onSelectSamples={onSelectSamples}
                        onToggleForm={createSampleDisclosure.onToggle}
                    />
                </>
            ) : (
                <Center h="100%" w="100%">
                    <LoadingSpinner />
                </Center>
            )}
        </>
    );
});

SamplesContainer.displayName = "SamplesContainer";
