import type { ChangeEvent, FC, PropsWithChildren } from "react";
import { useMemo, useState } from "react";

import { groupBy, toPairs } from "ramda";

import {
    Box,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tag,
    type TagProps,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";

import { BeamtimeStatus } from "@app/domain";
import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import type { PatchSamplePayload } from "@app/domain/api/sample.ts";
import { Sample } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { BeamtimeRequests } from "@app/pages/agent/beamtimeDetails/BeamtimeRequests.tsx";
import { routes } from "@app/Routes/routes.ts";
import { BeamtimeModal } from "@app-components/modals/beamtime/Beamtime.modal.tsx";
import { SampleModal } from "@app-components/modals/sample/Sample.modal.tsx";

import {
    Property,
    PropertyLabel,
    PropertyList,
    PropertyValue,
} from "@mt-components/Input/Property";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";

import { useConfirm } from "@mt-hooks/useConfirm.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { downloadArrayAsCSV } from "@mt-tools/io/downloadFile.ts";

import type { agentSchema } from "src/api/client";
import { UploadAuxFileModal } from "src/App/components/modals/UploadAuxFileModal";
import { BeamtimeSamples } from "src/App/pages/agent/beamtimeDetails/BeamtimeSamples";

import { BeamtimeSSW } from "./BeamtimeSSW";

const options = [
    { id: BeamtimeStatus.Draft, title: "Draft" },
    { id: BeamtimeStatus.Published, title: "Published" },
    { id: BeamtimeStatus.Active, title: "Active" },
    { id: BeamtimeStatus.Completed, title: "Finished" },
    { id: BeamtimeStatus.Canceled, title: "Canceled" },
];

type BeamtimeDetailProps = {
    beamtime: BeamtimeModel;
    beamtimeStats: agentSchema.components["schemas"]["BeamtimeStats"];
    beamtimeSamples: agentSchema.components["schemas"]["BeamtimeSample"][];
    beamtimeRequests: agentSchema.components["schemas"]["BeamtimeMeasurementRequest"][];
};

type SampleTaskWithQRCode = Omit<SampleTasksModel, "qrCode"> & {
    qrCode: string;
};

const findDuplicateQRCodes = (
    sampleTasks: Pick<agentSchema.components["schemas"]["Sample"], "qrCode">[],
) => {
    const tasksWithQRCode = sampleTasks.filter(
        (x): x is SampleTaskWithQRCode => !!x.qrCode,
    );
    const duplicateQRCodes = groupBy((t) => t.qrCode, tasksWithQRCode);

    const duplicates = toPairs(duplicateQRCodes).filter(
        (pair: [string, SampleTaskWithQRCode[] | undefined]) => {
            const samplesForQrCode = pair[1];
            return samplesForQrCode && samplesForQrCode.length > 1;
        },
    );

    return duplicates;
};

export const BeamtimeDetails: FC<BeamtimeDetailProps> = ({
    beamtime,
    beamtimeStats,
    beamtimeSamples,
    beamtimeRequests,
}) => {
    const navigate = useNavigate();
    const confirm = useConfirm();

    const [toUpdate, setToUpdate] = useState<BeamtimeModel | undefined>(
        undefined,
    );

    const { mutateAsync: update } = Beamtime.useAdminUpdate();
    const { mutateAsync: onUpdateSample } = Sample.useUpdate();
    const [sampleToUpdate, setSampleToUpdate] = useState<
        agentSchema.components["schemas"]["BeamtimeSample"] | null
    >(null);

    const { mutateAsync: deleteFn } = Beamtime.useAdminDelete();

    const uploadAuxFilesDisclosure = useDisclosure();

    const onDelete = (id: string) => {
        confirm({
            title: "Delete Beamtime",
            body: (
                <Box>
                    <Text display="block">
                        Are you sure you want to delete the beamtime?
                    </Text>
                </Box>
            ),
            onCancel: () => {},
            onConfirm: () => {
                void deleteFn(id);
            },
        });
    };

    const onUpdate = (data: PatchSamplePayload) => {
        if (
            !sampleToUpdate?.sample.id ||
            !sampleToUpdate.sample.organizationId
        ) {
            return;
        }

        void onUpdateSample({
            params: {
                path: {
                    sampleId: sampleToUpdate.sample.id,
                    organizationId: sampleToUpdate.sample.organizationId,
                },
            },
            body: data,
        });
    };

    const onChangeStatus = async (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value as BeamtimeModel["status"]; // @todo add type guard

        await update({
            id: beamtime.id,
            data: { ...beamtime, status: value },
        });
    };

    const duplicates = useMemo(
        () => findDuplicateQRCodes(beamtimeSamples.map((x) => x.sample)),
        [beamtimeSamples],
    );

    return (
        <>
            {Boolean(toUpdate) && (
                <BeamtimeModal
                    isOpen={Boolean(toUpdate)}
                    onUpdate={(data: BeamtimeModel) => {
                        if (toUpdate) {
                            void update({
                                id: toUpdate.id,
                                data,
                            });
                            setToUpdate(undefined);
                        }
                    }}
                    onCancel={() => setToUpdate(undefined)}
                    initialData={toUpdate}
                />
            )}
            {uploadAuxFilesDisclosure.isOpen && (
                <UploadAuxFileModal
                    isOpen={uploadAuxFilesDisclosure.isOpen}
                    onClose={uploadAuxFilesDisclosure.onClose}
                    measurementRequests={beamtimeRequests}
                />
            )}
            {sampleToUpdate && (
                <SampleModal
                    sample={sampleToUpdate.sample}
                    isOpen={Boolean(sampleToUpdate)}
                    onClose={() => setSampleToUpdate(null)}
                    onSubmit={onUpdate}
                />
            )}
            <Box
                display="flex"
                flexDirection="column"
                px="12px"
                h="100%"
                overflowY="auto"
            >
                <PageHeader
                    crumbs={[
                        {
                            id: "beamtimes",
                            label: (
                                <Link to={routes.agent.beamtimes.url}>
                                    Beamtimes
                                </Link>
                            ),
                        },
                        {
                            id: "beamtime-detail",
                            label: beamtime.name,
                        },
                    ]}
                    actions={[
                        {
                            id: "edit",
                            label: "Edit",
                            onClick: () => setToUpdate(beamtime),
                        },
                        {
                            id: "delete",
                            label: "Delete",
                            onClick: () => onDelete(beamtime.id),
                        },
                        {
                            id: "download-tasks",
                            label: "Download tasks",
                            onClick: () => {
                                const keys = [
                                    "Sample-ID",
                                    "QR-Code",
                                    "HR-XRPD",
                                    "TS-PDF",
                                    "SAXS",
                                ] as const satisfies string[];

                                const data = beamtimeSamples.map((x) => ({
                                    [keys[0]]: x.sample.name,
                                    [keys[1]]: x.sample.qrCode!,
                                    [keys[2]]: x.sampleTasks.xrd ? "yes" : "no",
                                    [keys[3]]: x.sampleTasks.pdf ? "yes" : "no",
                                    [keys[4]]: x.sampleTasks.saxs
                                        ? "yes"
                                        : "no",
                                }));

                                downloadArrayAsCSV(data, keys, "task-list.csv");
                            },
                        },
                        {
                            id: "aux-files-upload",
                            label: "Aux files",
                            isAction: true,
                            onClick: () => {},
                            items: [
                                {
                                    id: "aux-files-upload",
                                    label: "Upload files",
                                    onClick: uploadAuxFilesDisclosure.onOpen,
                                },
                            ],
                        },
                        {
                            id: "sync-connect",
                            label: "Sync & Connect",
                            isPrimary: true,
                            isDisabled:
                                beamtime.status !== BeamtimeStatus.Completed ||
                                !beamtimeSamples.every((s) => s.sample.qrCode),
                            help: !beamtimeSamples.every((s) => s.sample.qrCode)
                                ? "not all samples have qr codes"
                                : undefined,
                            onClick: () =>
                                navigate({
                                    to: routes.agent.beamtimeSync.url,
                                    params: {
                                        beamtimeId: beamtime.id,
                                    },
                                }),
                        },
                    ]}
                />
                <Box maxW="680px" py="24px">
                    <PropertyList width="100%" fontSize="md">
                        <Property key="title">
                            <PropertyLabel width="240px">Title</PropertyLabel>
                            <PropertyValue>{beamtime.name}</PropertyValue>
                        </Property>
                        <Property key="status">
                            <PropertyLabel width="240px">Status</PropertyLabel>
                            <PropertyValue>
                                <Select
                                    value={beamtime.status}
                                    onChange={onChangeStatus}
                                >
                                    {options.map((b) => (
                                        <option key={b.id} value={b.id}>
                                            {b.title}
                                        </option>
                                    ))}
                                </Select>
                            </PropertyValue>
                        </Property>
                        <Property key="beamtime">
                            <PropertyLabel width="240px">Date</PropertyLabel>
                            {/* @todo localization */}
                            <PropertyValue>
                                {beamtime.date ? formatDate(beamtime.date) : ""}
                            </PropertyValue>
                        </Property>
                        <Property
                            key="duplicate-qr-codes"
                            alignItems="flex-start"
                        >
                            <PropertyLabel width="240px">
                                Duplicate QR codes
                            </PropertyLabel>
                            <PropertyValue maxH="240px">
                                <Box>
                                    {duplicates.map((t) => {
                                        return (
                                            <Box
                                                key={t[0]}
                                                flex="0 0 1"
                                                w="100%"
                                                display="flex"
                                                overflow="hidden"
                                            >
                                                <Box flex="0 0 130px">
                                                    {t[0]}
                                                </Box>
                                                <Box flex={1}>
                                                    {t[1]?.map((t) => t.name)}
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </PropertyValue>
                        </Property>
                    </PropertyList>
                </Box>

                <Tabs size="lg" variant="line" flex={1} fontSize="md">
                    <TabList h="36px">
                        <Tab>Requests ({beamtimeStats.totalRequests})</Tab>
                        <Tab>
                            Samples ({beamtimeStats.totalSamples})
                            <TabTag>
                                measurements: {beamtimeStats.totalMeasurements}
                            </TabTag>
                        </Tab>
                        <Tab>
                            Sample-Stick-Well Mapping ({beamtimeStats.totalSSW})
                            <TabTag
                                colorScheme={
                                    beamtimeStats.totalSamples -
                                        beamtimeStats.coveredSSW <=
                                    0
                                        ? "green"
                                        : "red"
                                }
                            >
                                covered:&nbsp;
                                {beamtimeStats.coveredSSW}/
                                {beamtimeStats.totalSamples}
                            </TabTag>
                        </Tab>
                    </TabList>
                    <TabPanels h="calc(100% - 36px)">
                        <TabPanel h="100%" px={0}>
                            <BeamtimeRequests requests={beamtimeRequests} />
                        </TabPanel>
                        <TabPanel h="100%">
                            <Box h="100%">
                                <BeamtimeSamples
                                    isWide
                                    beamtimeId={beamtime.id}
                                    beamtimeSamples={beamtimeSamples}
                                    onUpdateSample={setSampleToUpdate}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel h="100%">
                            <Box h="100%">
                                <BeamtimeSSW beamtimeId={beamtime.id} />
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </>
    );
};

export const TabTag: FC<PropsWithChildren & TagProps> = (props) => {
    return (
        <Tag
            size="sm"
            variant="solid"
            ml="4px"
            colorScheme="blue"
            borderRadius="full"
            {...props}
        >
            {props.children}
        </Tag>
    );
};
