import type { FetchResponse } from "openapi-fetch";

export class ServerError extends Error {
    constructor(
        public readonly message: string = "",
        public readonly status: number | undefined = undefined,
    ) {
        super(message);
        this.name = "ServerError";
    }
}

export class NetWorkError extends Error {
    constructor(public readonly message: string = "") {
        super(message);
        this.name = "NetworkError";
    }

    public readonly name: string;
}

export class NotFoundError extends Error {
    constructor(public readonly message: string = "") {
        super(message);
        this.name = "NotFoundError";
        this.status = 404;
    }
    readonly status: number;
}

export class BadRequestError extends Error {
    constructor(public readonly message: string = "") {
        super(message);
        this.name = "BadRequestError";
        this.status = 400;
    }
    readonly status: number;
}

/*
 * This function maps a fetch response to a thrown error.
 */
export const mapErrorResponseToThrownError = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: FetchResponse<any, any>,
): never => {
    if (response.response.status === 404) {
        throw new NotFoundError();
    }

    if (response.response.status === 400) {
        throw new BadRequestError();
    }

    if (response.response.status >= 500) {
        throw new ServerError();
    }

    throw new Error(response.response.statusText);
};

export const isNotFoundError = (e: Error): e is NotFoundError =>
    e instanceof NotFoundError;

export const isNetworkError = (e: Error): e is NetWorkError =>
    e instanceof NetWorkError;

export const isBadRequest = (e: Error): e is BadRequestError =>
    e instanceof BadRequestError;
