import type { PropsWithChildren } from "react";
import { createContext, memo, useEffect, useState } from "react";

import { last } from "ramda";

import { useNavigate, useParams, useRouterState } from "@tanstack/react-router";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { OrgOnboarding } from "@app-components/organization/Onboarding.tsx";

import { GenericError } from "@mt-components/states/Error/GenericError.tsx";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";

import { usePublicClient } from "src/api/client";

export const orgsLoadingKey = "orgs";

export type OrgContextValueRaw = {
    currentOrg?: string;
    currentOrgName: string;
    setCurrentOrg: (id: string) => void;
};

export type OrgContextValue = {
    currentOrg: string;
    currentOrgName: string;
    setCurrentOrg: (id: string) => void;
};

const getOrg = async (
    client: APIContextValue["publicClient"],
    organizationId: string,
) => {
    return await client.GET("/organizations/{organizationId}", {
        params: {
            path: {
                organizationId: organizationId,
            },
        },
    });
};

export const OContext = createContext<OrgContextValueRaw>(null!);

type OrgContextState =
    | {
          state: "initial";
      }
    | {
          state: "onboarding";
      }
    | {
          state: "selected";
          orgId: { id: string; name: string };
      }
    | {
          state: "error";
      };

export const OrgContext = memo(
    (props: PropsWithChildren<{ organizations: OrganizationModel[] }>) => {
        const navigate = useNavigate();
        const routerState = useRouterState();
        const params = useParams({ strict: false });

        const [status, setStatus] = useState<OrgContextState>({
            state: "initial",
        });

        const loadingCtx = useLoadingContext();
        const loadingCtxRef = useLatestRef(loadingCtx);

        const client = usePublicClient();

        useEffect(() => {
            const paramOrganizationId =
                "orgId" in params ? params.orgId : undefined;

            const autoOrganizationId =
                props.organizations.length >= 1
                    ? props.organizations[0].id
                    : undefined;

            const orgIdCandidate = paramOrganizationId || autoOrganizationId;

            if (!orgIdCandidate) {
                setStatus({
                    state: "onboarding",
                });
                loadingCtxRef.current.removeLoading(orgsLoadingKey);
                return;
            }

            getOrg(client, orgIdCandidate)
                .then((r) => {
                    if (!r.data) {
                        return;
                    }
                    setStatus({
                        orgId: r.data,
                        state: "selected",
                    });
                    loadingCtxRef.current.removeLoading(orgsLoadingKey);
                })
                .catch(() => {
                    setStatus({
                        state: "error",
                    });
                    loadingCtxRef.current.removeLoading(orgsLoadingKey);
                });
        }, []);

        const setSelectedOrg = async (id: string) => {
            // Retrieve the current route, and replace the current orgId,
            // with the 'to-be-selected' orgId
            // Sync the selected org with the route
            const currentRoute = last(routerState.matches);
            if (!currentRoute) {
                return;
            }

            const org = await getOrg(client, id);

            if (!org.data || !org.response.ok) {
                setStatus({
                    state: "error",
                });
                return;
            }

            setStatus({
                state: "selected",
                orgId: {
                    name: org.data.name,
                    id: org.data.id,
                },
            });

            void navigate({
                // @ts-ignore
                to: currentRoute.routeId,
                params: {
                    ...params,
                    orgId: id,
                },
            });
        };

        if (loadingCtx.loadingKeys.includes(orgsLoadingKey)) {
            return null;
        }

        if (status.state === "error") {
            return <GenericError />;
        }

        if (status.state === "onboarding") {
            return (
                <OrgOnboarding
                    onSelectOrg={(org) =>
                        setStatus({
                            state: "selected",
                            orgId: org,
                        })
                    }
                />
            );
        }

        if (status.state === "selected") {
            return (
                <OContext.Provider
                    value={{
                        currentOrg: status.orgId.id,
                        setCurrentOrg: setSelectedOrg,
                        currentOrgName: status.orgId.name,
                    }}
                >
                    {props.children}
                </OContext.Provider>
            );
        }
    },
);

OrgContext.displayName = "OrgContext";
