import type { IconBaseProps } from "react-icons";
import { BsFillFileEarmarkFill, BsThreeDotsVertical } from "react-icons/bs";
import { FaCheck, FaX } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { IoMdDownload } from "react-icons/io";
import {
    LuArrowDown,
    LuArrowLeft,
    LuArrowRight,
    LuArrowUp,
    LuChartLine,
    LuChartScatter,
    LuCheck,
    LuChevronDown,
    LuChevronLeft,
    LuChevronRight,
    LuChevronUp,
    LuCircuitBoard,
    LuClipboardList,
    LuCog,
    LuDownload,
    LuExternalLink,
    LuEye,
    LuEyeOff,
    LuFile,
    LuFileText,
    LuFlaskConical,
    LuGlobe,
    LuHouse,
    // LuGlobe2,
    // LuHome,
    LuInfo,
    // LuLineChart,
    LuMail,
    LuMaximize2,
    LuMicroscope,
    LuMinimize2,
    LuPackage,
    LuPencil,
    LuPlus,
    LuQrCode,
    LuReceiptText,
    LuRefreshCcw,
    // LuScatterChart,
    LuSearch,
    LuSquareCheck,
    LuTally2,
    LuTally4,
    LuTelescope,
    LuTrash,
    LuUser,
    LuUsers,
    LuX,
    LuZoomIn,
    LuZoomOut,
} from "react-icons/lu";
import { MdErrorOutline, MdUpload } from "react-icons/md";
import { TbSquareRoot } from "react-icons/tb";

import {
    AnalyzeImage,
    MeasurementsImage,
    SamplesImage,
} from "@mt-design/images.tsx";

export const Icon = {
    AnalyzeImage,
    ArrowUp: LuArrowUp,
    ArrowDown: LuArrowDown,
    ArrowLeft: LuArrowLeft,
    ArrowRight: LuArrowRight,
    Beamtime: LuTelescope,
    Charts: LuChartLine,
    Check: LuCheck,
    SquareCheck: LuSquareCheck,
    Check2: LuCheck,
    ChevronDown: LuChevronDown,
    ChevronLeft: LuChevronLeft,
    ChevronRight: LuChevronRight,
    ChevronUp: LuChevronUp,
    ClipboardList: LuClipboardList,
    Cog: LuCog,
    CloudDone: LuCheck,
    Close: LuX,
    Cross: ImCross,
    Document: LuFileText,
    Delete: LuTrash,
    Download: IoMdDownload,
    ExternalLink: LuExternalLink,
    Edit: LuPencil,
    Error: MdErrorOutline,
    Form: LuReceiptText,
    File: LuFile,
    File2: BsFillFileEarmarkFill,
    FourColumn: LuTally4,
    Globe: LuGlobe,
    Hidden: LuEyeOff,
    Home: LuHouse,
    Info: LuInfo,
    LineChart: LuChartLine,
    MeasurementsImage,
    Maximize: LuMaximize2,
    Minimize: LuMinimize2,
    Mail: LuMail,
    Menu: BsThreeDotsVertical,
    Organization: LuUsers,
    Package: LuPackage,
    Plus: LuPlus,
    Profile: LuUser,
    QRCode: LuQrCode,
    Request: LuMicroscope,
    Sample: LuFlaskConical,
    SamplesImage,
    ScatterChart: LuChartScatter,
    Search: LuSearch,
    Shown: LuEye,
    TwoColumn: LuTally2,
    Upload: MdUpload,
    Users: LuUsers,
    SSWMapping: LuCircuitBoard,
    Success: (props: IconBaseProps) => <FaCheck {...props} color="green" />,
    Failure: (props: IconBaseProps) => <FaX {...props} color="red" />,
    graph: {
        Square: TbSquareRoot,
        Download: LuDownload,
        ZoomIn: LuZoomIn,
        ZoomOut: LuZoomOut,
        Reload: LuRefreshCcw,
    },
};
