// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC } from "react";

import { Box, Center, Text, useDisclosure } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

import SidebarItem from "./SidebarItem";

type Props = {
    panels: string[];
    group: string;
    selectedGroup: string;
    selectedPanel: string;
    onChangeGroup: (group: string, panel: string) => void;
};

export const SidebarGroup: FC<Props> = (props) => {
    const disclosure = useDisclosure({
        defaultIsOpen: props.group === props.selectedGroup,
    });
    const onChangeGroup = (panel: string) => {
        props.onChangeGroup(props.group, panel);
    };

    const { group, panels, selectedGroup } = props;

    const isSelected = selectedGroup === group;
    return (
        // <Box p="2" bg={isSelected ? "gray.50" : "white"}>
        <Box px="12px">
            <Box
                onClick={disclosure.onToggle}
                display="flex"
                alignItems="center"
                position="relative"
                fontSize="14px"
                py="12px"
                pl="4px"
                textTransform="capitalize"
                textAlign="left"
                borderBottom="solid 1px"
                borderColor="gray.50"
                cursor="pointer"
                w="100%"
            >
                <Center
                    transition="transform ease-in-out 200ms"
                    transform={`rotate(${disclosure.isOpen ? "90deg" : 0})`}
                    w="16px"
                    h="16px"
                >
                    <Icon.ChevronRight />
                </Center>
                <Text textStyle="T2" color="gray.800" pl="4px">
                    {group}
                </Text>
            </Box>
            {disclosure.isOpen &&
                panels.map((panel: string, i: number) => {
                    const isActive =
                        props.selectedPanel === panel &&
                        props.group === props.selectedGroup;

                    return (
                        <SidebarItem
                            key={"subitem-" + i}
                            active={isActive}
                            onClick={() => onChangeGroup(panel)}
                            label={panel}
                        />
                    );
                })}
        </Box>
    );
};

SidebarGroup.displayName = "SidebarGroup";

export default SidebarGroup;
