import type { FC } from "react";
import { memo, useMemo, useRef, useState } from "react";

import { identity, isNotNil, omit } from "ramda";
import type { Promisable } from "type-fest";

import {
    Box,
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
} from "@chakra-ui/react";
import type {
    CellContext,
    ColumnDefTemplate,
    HeaderContext,
    OnChangeFn,
    Table,
} from "@tanstack/react-table";

import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { keyToName } from "@app/domain/services/sample/ioMapping.ts";
import { SampleFormFilter } from "@app-components/sample/SampleFormFilter.tsx";

import type { ToggleItem } from "@mt-components/Button/ToggleButtonBar.tsx";
import { ToggleButtonBar } from "@mt-components/Button/ToggleButtonBar.tsx";
import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";
import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import type { IndexBoolean } from "@mt-tools/types.ts";

import { Icon } from "@mt-design/icons.tsx";

import { useDebouncedSearch } from "src/packages/hooks/useSearch.tsx";

const cell: ColumnDefTemplate<CellContext<SampleModel, unknown>> = (row) => {
    const value = row.getValue(); // @todo not typesafe
    return (
        <Tag variant="solid" colorScheme={value === true ? "red" : "green"}>
            <>{value === true ? "Yes" : "No"}</>
        </Tag>
    );
};

interface TableMeta {
    isDisabled?: boolean;
    onDelete?: (id: string) => void;
    onUpdate: (data: SampleModel) => void;
    onCreate?: (data: Omit<SampleModel, "id">) => void;
}

const columns: ColumnDef<SampleModel>[] = [
    {
        accessorKey: "name" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("260px"),
    },
    {
        accessorKey: "qrCode" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("200px"),
    },
    {
        accessorKey: "substance" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("200px"),
    },
    {
        accessorKey: "casNumber" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "composition" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("240px"),
    },
    {
        accessorKey: "form" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        thProps: makeWidth("160px"),
    },
    {
        accessorKey: "formDescription" satisfies keyof SampleModel,
        header: (arg: HeaderContext<SampleModel, unknown>) =>
            keyToName[arg.header.id as keyof SampleModel],
        size: 300,
    },
    {
        accessorKey: "toxic" satisfies keyof SampleModel,
        header: "Toxic?",
        cell,
    },
    {
        accessorKey: "flammable" satisfies keyof SampleModel,
        header: "Flammable?",
        cell,
    },
    {
        accessorKey: "corrosive" satisfies keyof SampleModel,
        header: "Corrosive?",
        cell,
    },
    {
        accessorKey: "oxidizing" satisfies keyof SampleModel,
        header: "Oxidizing?",
        cell,
    },
    {
        accessorKey: "airSensitive" satisfies keyof SampleModel,
        header: "Air-sensitive?",
        cell,
    },
    {
        accessorKey: "otherHazards" satisfies keyof SampleModel,
        header: "Other hazards?",
        cell,
    },
    {
        accessorKey: "sampleHandlingRisk" satisfies keyof SampleModel,
        header: "Sample handling risk?",
        cell,
    },
    {
        accessorKey:
            "sampleHandlingRiskDescription" satisfies keyof SampleModel,
        header: "Details",
    },
    {
        accessorKey: "containsHazardousSubstances" satisfies keyof SampleModel,
        header: "Contains hazardous substances?",
        cell,
    },
    {
        accessorKey:
            "containsHazardousSubstancesDescription" satisfies keyof SampleModel,
        header: "Details",
    },
    {
        accessorKey: "createdAt" satisfies keyof SampleModel,
        header: "Created",
        cell: (cell) => {
            return formatDate(cell.row.original.createdAt);
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "updatedAt" satisfies keyof SampleModel,
        header: "Updated",
        cell: (cell) => {
            return formatDate(cell.row.original.updatedAt);
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "menu",
        stopBubbling: true,
        header: " ",
        cell: (cell) => {
            const value = cell.row.original.id;

            const meta = cell.table.options.meta as TableMeta;

            return (
                <Menu>
                    <MenuButton
                        isDisabled={meta.isDisabled}
                        as={Button}
                        rightIcon={<Icon.ChevronDown />}
                    >
                        Actions
                    </MenuButton>
                    <MenuList>
                        {meta.onDelete ? (
                            <MenuItem onClick={() => meta.onDelete?.(value)}>
                                Delete
                            </MenuItem>
                        ) : null}
                        <MenuItem
                            onClick={() => meta.onUpdate(cell.row.original)}
                        >
                            Edit
                        </MenuItem>
                        {meta.onCreate ? (
                            <MenuItem
                                onClick={() =>
                                    meta.onCreate?.({
                                        ...omit(["id"], cell.row.original),
                                    })
                                }
                            >
                                Duplicate
                            </MenuItem>
                        ) : null}
                    </MenuList>
                </Menu>
            );
        },
    },
] satisfies ColumnDef<SampleModel>[];

const initialState = {
    sorting: [
        {
            id: "updatedAt",
            desc: true,
        },
    ],
};

type Props = {
    isDisabled?: boolean;
    samples: SampleModel[];
    selectedSamples?: {
        list: string[];
        obj: Record<string, boolean>;
    };
    onDeleteSample?: (id: string) => Promisable<unknown>;
    onSelectSampleToUpdate: (sample: SampleModel) => void;
    onSelectSample: OnChangeFn<IndexBoolean>;
    onCreateSample?: (payload: CreateSamplePayload) => Promisable<unknown>;
};

const toggleButtonBar: ToggleItem<"wide" | "narrow">[] = [
    {
        id: "wide",
        label: "Wide table",
        icon: <Icon.FourColumn />,
        ariaLabel: "expand table",
    },
    {
        id: "narrow",
        label: "Narrow table",
        icon: <Icon.TwoColumn />,
        ariaLabel: "collapse table",
    },
];

export const SamplesTable: FC<Props> = memo(
    ({ isDisabled, samples, selectedSamples, ...actions }) => {
        const tableRef = useRef<Table<SampleModel>>(null);
        const [ref] = useScrollbar<HTMLDivElement>();

        const [formFilter, setFormFilter] = useState<
            SampleModel["form"] | undefined
        >(undefined);
        const [buttonOption, setButtonOption] = useState<"narrow" | "wide">(
            "narrow",
        );

        const {
            filteredOrders: stringFilteredSamples,
            onClear,
            onChange,
        } = useDebouncedSearch<SampleModel>({
            items: samples,
            keys: ["name", "qrCode", "substance", "casNumber", "composition"],
        });

        const filteredSamples = useMemo(() => {
            return formFilter
                ? stringFilteredSamples.filter(({ form }) => {
                      const result = [
                          formFilter ? formFilter === form : null,
                      ].filter(isNotNil);

                      return result.every(identity<boolean>);
                  })
                : stringFilteredSamples;
        }, [formFilter, stringFilteredSamples]);

        const onDeleteSample = useLatestRef(actions.onDeleteSample);
        const onSelectSampleToUpdate = useLatestRef(
            actions.onSelectSampleToUpdate,
        );
        const onCreateSample = useLatestRef(actions.onCreateSample);
        // const onSelectSample = useLatestRef(actions.onSelectSample);

        const tableContext = useMemo(
            () => ({
                onDelete: onDeleteSample.current,
                onUpdate: onSelectSampleToUpdate.current,
                onCreate: onCreateSample.current,
                isDisabled,
            }),
            [
                isDisabled,
                onDeleteSample,
                onSelectSampleToUpdate,
                onCreateSample,
            ],
        );

        const state = useMemo(
            () => ({
                rowSelection: selectedSamples?.obj || {},
                columnVisibility:
                    buttonOption === "wide"
                        ? {
                              qrCode: true,
                              casNumber: false,
                              toxic: true,
                              corrosive: true,
                              oxidizing: true,
                              flammable: true,
                              menu: false,
                              formDescription: false,
                              sampleHandlingRisk: true,
                              sampleHandlingRiskDescription: false,
                              containsHazardousSubstances: true,
                              containsHazardousSubstancesDescription: false,
                              airSensitive: true,
                              otherHazards: true,
                              createdAt: true,
                              updatedAt: true,
                          }
                        : {
                              qrCode: false,
                              casNumber: false,
                              toxic: false,
                              corrosive: false,
                              oxidizing: false,
                              flammable: false,
                              menu: false,
                              formDescription: false,
                              sampleHandlingRisk: false,
                              sampleHandlingRiskDescription: false,
                              containsHazardousSubstances: false,
                              containsHazardousSubstancesDescription: false,
                              airSensitive: false,
                              otherHazards: false,
                              createdAt: false,
                              updatedAt: false,
                          },
            }),
            [selectedSamples?.obj, buttonOption],
        );

        return (
            <Box
                display="flex"
                flexDirection="column"
                flex={1}
                h="100%"
                alignItems="stretch"
                overflow="hidden"
                w="100%"
            >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box flex={1}>
                        <SearchFilterBar
                            value={undefined}
                            onReset={onClear}
                            onChange={onChange}
                            placeholder="Search by property"
                            filter={
                                <SampleFormFilter
                                    onChange={setFormFilter}
                                    value={formFilter}
                                />
                            }
                        />
                    </Box>
                    <Box>
                        <ToggleButtonBar
                            onToggle={setButtonOption}
                            option={buttonOption}
                            items={toggleButtonBar}
                        />
                    </Box>
                </Box>
                <Box ref={ref} flex={1} width="100%" overflow="hidden">
                    <DataTable<SampleModel>
                        variant="unstyled"
                        instanceRef={tableRef}
                        isSelectable
                        enableMultiRowSelection
                        isSortable
                        columns={columns}
                        data={filteredSamples}
                        meta={tableContext}
                        getRowId={(row) => row.id}
                        onRowSelectionChange={actions.onSelectSample}
                        initialState={initialState}
                        state={state}
                    />
                </Box>
            </Box>
        );
    },
);

SamplesTable.displayName = "SamplesTable";
