import { partial } from "ramda";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { use2SDownloadQuery } from "@app/domain/2s-download.ts";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { applyOrg, defaultHandling } from "@mt-tools/fetch.ts";
import { downloadFile } from "@mt-tools/io/downloadFile.ts";

import type {
    MeasurementGroupModel,
    MeasurementMethodType,
} from "src/App/domain";

import { downloadReport } from "./request/index.ts";

export interface GetMeasurementGroupRequest {
    organizationId: string;
    measurementGroupId: string;
}

export type GetMeasurementGroupsParams =
    operations["getMeasurementGroups"]["parameters"];

export type GetAdminMeasurementGroupsRequest =
    operations["getAdminMeasurementGroups"]["parameters"];

export type PatchMeasurementGroupPayload =
    components["schemas"]["PatchMeasurementGroupPayload"];

export interface PatchMeasurementGroupRequest {
    organizationId: string;
    measurementGroupId: string;
    patchMeasurementGroupPayload: PatchMeasurementGroupPayload;
}

export interface PatchAdminMeasurementGroupRequest {
    organizationId: string;
    measurementGroupId: string;
    patchMeasurementGroupPayload: operations["patchAdminMeasurementGroup"]["requestBody"]["content"]["application/json"];
}

export interface CreateMeasurementTaskPayload {
    method: MeasurementMethodType;
    sampleId: string;
    measurementGroupId?: string;
}

const entityKey = QueryKeys.measurementGroup;

export type CreateMeasurementGroupRequest = {
    organizationId: string;
    data: {
        name: string;
    };
};

function getAllRequests(
    api: APIContextValue,
    params: GetMeasurementGroupsParams,
) {
    return api.client.GET(
        "/organizations/{organizationId}/measurement-groups",
        {
            params: applyPageSizeDefault(params),
        },
    );
}

const useGetAllA = (params: GetMeasurementGroupsParams) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        retry: false,
        queryFn: async () => {
            const response = await getAllRequests(
                api,
                applyPageSizeDefault(params),
            );
            return defaultHandling(response);
        },
    });
};

const getAdminAllRequests = (
    api: APIContextValue,
    params: operations["getAdminMeasurementGroups"]["parameters"],
) => {
    return api.client.GET("/admin/measurement-groups", {
        params: applyPageSizeDefault(params),
    });
};

const useAdminGetAll = (params?: GetAdminMeasurementGroupsRequest) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            const fetcher = partial(getAdminAllRequests, [api]);
            try {
                const requests = await collectAllPages<
                    MeasurementGroupModel,
                    operations["getAdminMeasurementGroups"]["parameters"],
                    typeof fetcher
                >(fetcher, params);
                return requests;
            } catch {
                throw new Error("Could not fetch admin requests");
            }
        },
    });
};

const useGet = (params: GetMeasurementGroupRequest) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            if (!params.measurementGroupId) {
                return null;
            }

            const response = await api.client.GET(
                "/organizations/{organizationId}/measurement-groups/{measurementGroupId}",
                {
                    params: {
                        path: params,
                    },
                },
            );

            return defaultHandling(response);
        },
    });
};

const useAdminGet = (params: GetMeasurementGroupRequest) => {
    const api = useAPI();
    return useQuery({
        queryKey: [
            entityKey,
            "admin",
            params.organizationId,
            params.measurementGroupId,
        ],
        queryFn: async () => {
            if (!params.measurementGroupId) {
                return null;
            }

            const response = await api.client.GET(
                "/admin/organizations/{organizationId}/measurement-groups/{measurementGroupId}",
                {
                    params: {
                        path: params,
                    },
                },
            );

            return defaultHandling(response);
        },
    });
};

const useCreateQuick = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            requestName: string;
            tasks: Omit<CreateMeasurementTaskPayload, "measurementGroupId">[];
        }) => {
            const r = await api.client.POST(
                "/organizations/{organizationId}/measurement-groups",
                {
                    params: {
                        path: {
                            organizationId: data.organizationId,
                        },
                    },
                    body: {
                        name: data.requestName,
                    },
                },
            );

            if (!r.response.ok || !r.data) {
                throw new Error("");
            }

            const promisses = data.tasks.map((task) => {
                return api.client.POST(
                    "/organizations/{organizationId}/measurement-tasks",
                    {
                        params: {
                            path: {
                                organizationId: data.organizationId,
                            },
                        },
                        body: {
                            sampleId: task.sampleId,
                            measurementGroupId: r.data.id,
                            method: task.method,
                        },
                    },
                );
            });

            await Promise.all(promisses);

            await client.invalidateQueries({
                queryKey: [entityKey, QueryKeys.measurementTask],
            });
            return r;
        },
    });
};

const useCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateMeasurementGroupRequest) => {
            const params = applyOrg(data.organizationId);
            const r = await api.client.POST(
                "/organizations/{organizationId}/measurement-groups",
                {
                    params,
                    body: data.data,
                },
            );
            return r;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useAdminCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateMeasurementGroupRequest) => {
            const params = applyOrg(data.organizationId);
            const r = await api.client.POST(
                "/admin/organizations/{organizationId}/measurement-groups",
                {
                    params,
                    body: data.data,
                },
            );
            return r;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: PatchMeasurementGroupRequest) => {
            const response = await api.client.PATCH(
                "/organizations/{organizationId}/measurement-groups/{measurementGroupId}",
                {
                    params: {
                        path: {
                            organizationId: data.organizationId,
                            measurementGroupId: data.measurementGroupId,
                        },
                    },
                    body: data.patchMeasurementGroupPayload,
                },
            );
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return response;
        },
    });
};

const useAdminUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: PatchAdminMeasurementGroupRequest) => {
            const response = await api.client.PATCH(
                "/admin/organizations/{organizationId}/measurement-groups/{measurementGroupId}",
                {
                    params: {
                        path: {
                            organizationId: data.organizationId,
                            measurementGroupId: data.measurementGroupId,
                        },
                    },
                    body: data.patchMeasurementGroupPayload,
                },
            );

            return response;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            measurementGroupId: string;
        }) => {
            await api.client.DELETE(
                "/organizations/{organizationId}/measurement-groups/{measurementGroupId}",
                {
                    params: {
                        path: data,
                    },
                },
            );
            return;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useUploadAuxFiles = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            measurementGroupId: string;
            file: File;
        }) => {
            const formData = new FormData();
            formData.append("file", data.file);

            return await fetch(
                `${api.basePath}/rpc/organizations/${data.organizationId}/measurement-groups/${data.measurementGroupId}/uploadFiles`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${api.token}`,
                    },
                },
            );
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useDownloadAuxFiles = () => {
    const api = useAPI();
    const client = useQueryClient();
    const snack = useSnackbar();
    return useMutation({
        mutationFn: async (params: {
            organizationId: string;
            measurementGroupId: string;
        }) => {
            snack.info({
                title: "Preparing download",
                description:
                    "Preparing your data for download can take a moment. The download will start automatically once all data has been collected.",
            });
            return await api.client
                .POST(
                    "/rpc/organizations/{organizationId}/measurement-groups/{measurementGroupId}/downloadFiles",
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/octet-stream",
                        },
                        parseAs: "blob",
                        params: {
                            path: {
                                organizationId: params.organizationId,
                                measurementGroupId: params.measurementGroupId,
                            },
                        },
                    },
                )
                .then(async (r) => {
                    const b = r.data;
                    if (!b) {
                        snack.info("No data available for download");
                        return;
                    }

                    const url = URL.createObjectURL(b);
                    downloadFile(url, "auxiliary-files.zip");
                    snack.success({
                        description: "Data downloaded",
                    });
                });
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};
const useDeleteAuxFiles = () => {
    const api = useAPI();
    const client = useQueryClient();
    const snack = useSnackbar();
    return useMutation({
        mutationFn: async (params: {
            organizationId: string;
            measurementGroupId: string;
            fileNames: string[];
        }) => {
            return await api.client
                .POST(
                    "/rpc/organizations/{organizationId}/measurement-groups/{measurementGroupId}/deleteFiles",
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        params: {
                            path: {
                                organizationId: params.organizationId,
                                measurementGroupId: params.measurementGroupId,
                            },
                        },
                        body: {
                            files: params.fileNames,
                        },
                    },
                )
                .then(async (r) => {
                    if (r.response.ok) {
                        const description =
                            params.fileNames.length === 1
                                ? "File deleted"
                                : `${params.fileNames.length} files deleted`;
                        snack.success({
                            description,
                        });
                    }
                });
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const useUploadReport = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            measurementGroupId: string;
            file: File;
        }) => {
            const formData = new FormData();
            formData.append("file", data.file);

            return await fetch(
                `${api.basePath}/admin/organizations/${data.organizationId}/measurement-groups/${data.measurementGroupId}/report`,
                {
                    method: "POST",
                    body: formData,
                    headers: {
                        Authorization: `Bearer ${api.token}`,
                    },
                },
            );
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const useDownloadReportTemplate1 = (
    organizationId: string,
    requestId: string,
) => {
    const api = useAPI();

    return use2SDownloadQuery({
        fn: async () => {
            return await fetch(
                `${api.basePath}/admin/organizations/${organizationId}/measurement-groups/${requestId}/report-template`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${api.token}`,
                    },
                },
            );
        },
    });
};

export const useDownloadReportTemplate2 = (
    organizationId: string,
    requestId: string,
) => {
    const api = useAPI();

    return useMutation({
        mutationFn: async (token: string) => {
            return await api.client.GET(
                "/admin/organizations/{organizationId}/measurement-groups/{measurementGroupId}/report-template",
                {
                    params: {
                        path: {
                            organizationId,
                            measurementGroupId: requestId,
                        },
                        query: {
                            token,
                        },
                    },
                    parseAs: "blob",
                },
            );
        },
    });
};

export const useAdminDeleteReport = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            measurementGroupId: string;
        }) => {
            return await api.client.DELETE(
                "/admin/organizations/{organizationId}/measurement-groups/{measurementGroupId}/report",
                {
                    params: {
                        path: {
                            organizationId: data.organizationId,
                            measurementGroupId: data.measurementGroupId,
                        },
                    },
                },
            );
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const useDownloadReport = () => {
    const api = useAPI();
    const client = useQueryClient();
    const snackbar = useSnackbar();
    return useMutation({
        mutationFn: async (request: MeasurementGroupModel) => {
            snackbar.info({
                title: "Downloading report",
            });
            const promise = downloadReport(api)(request);
            snackbar.success({
                title: "Report downloaded",
            });

            return await promise;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const Request = {
    useGetAllB: useGetAllA,
    useGet,
    // useAdminGet,
    useCreate,
    useAdminCreate,
    useCreateQuick,
    useUpdate,
    useAdminUpdate,
    useAdminGet,
    useDelete,
    useAdminGetAll,
    useUploadAuxFiles,
    useDownloadAuxFiles,
    useDeleteAuxFiles,
    useUploadReport,
    useDownloadReport,
    useAdminDeleteReport,
};
