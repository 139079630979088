import type { FC } from "react";

import { Box } from "@chakra-ui/react";

import type { SampleModel } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { SampleHazards } from "@app-components/sample/SampleHazards.tsx";
import { SampleProperties } from "@app-components/sample/SampleProperties.tsx";
import { MultiSelectionView } from "@app-components/sample/Sidebar/MultiSelection.view.tsx";
import { NoSelectionView } from "@app-components/sample/Sidebar/NoSelection.view.tsx";

import { Actions } from "@mt-components/Layout/Actions.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Footer } from "@mt-components/Layout/Sidebar/Footer.tsx";
import { Sidebar } from "@mt-components/Layout/Sidebar/Sidebar.tsx";
import { Title } from "@mt-components/Layout/Title.tsx";
import { SidebarSection } from "@mt-components/Sidebar";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

import type { Fn } from "@mt-tools/types.ts";

type SampleSidebarProps = {
    isOpen?: boolean;
    showToggler?: boolean;
    samples?: (SampleModel | SampleTasksModel)[] | null;
    onToggle: () => void;
    onSelectSampleToEdit: (sample: SampleModel) => void;
    onDuplicateSample: (sample: SampleModel) => void;
    onDeleteSample: (sampleId: string) => void;
    onOpenTasksModal: Fn;
};

export const SampleSidebar: FC<SampleSidebarProps> = ({
    isOpen,
    onToggle,
    showToggler,
    samples,
    onSelectSampleToEdit,
    onDuplicateSample,
    onDeleteSample,
    onOpenTasksModal,
}) => {
    const [ref] = useScrollbar<HTMLDivElement>();

    return (
        <Sidebar
            isOpen={isOpen}
            boxShadow="none"
            borderLeft="solid 1px"
            borderColor="gray.200"
            height="100%"
            px="12px"
        >
            {isOpen && !samples?.length && (
                <SidebarSection h="100%">
                    <NoSelectionView />
                </SidebarSection>
            )}

            {isOpen && samples && samples.length > 1 && (
                <SidebarSection h="100%">
                    <MultiSelectionView
                        amount={samples.length}
                        onClick={onOpenTasksModal}
                    />
                </SidebarSection>
            )}

            {isOpen && samples?.length === 1 && (
                <>
                    <SidebarSection flex="0 0 40px">
                        <Title textStyle="h5" title={samples[0].name} />
                        <Actions
                            id="sample-actions"
                            actions={[
                                {
                                    id: "edit",
                                    label: "Edit",
                                    isPrimary: true,
                                    onClick: () =>
                                        onSelectSampleToEdit(samples[0]),
                                },
                                {
                                    id: "get-measurement",
                                    label: "Get measurement",
                                    onClick: () => onOpenTasksModal(),
                                },
                            ]}
                            menuActions={[
                                {
                                    id: "duplicate",
                                    label: "Duplicate",
                                    onClick: () =>
                                        onDuplicateSample(samples[0]),
                                },
                                {
                                    id: "delete",
                                    label: "Delete",
                                    onClick: () =>
                                        onDeleteSample(samples[0].id),
                                },
                            ]}
                        />
                    </SidebarSection>
                    <Divider px="12px" my={0} mt="0 !important" />
                    <SidebarSection flex="1" overflow="hidden">
                        <Box overflowY="auto" ref={ref} height="100%">
                            <SampleProperties sample={samples[0]} />
                            <Divider my={0} />
                            <SampleHazards sample={samples[0]} />
                        </Box>
                    </SidebarSection>
                </>
            )}
            {showToggler && (
                <Footer
                    flex="0 0 32px"
                    mt="auto"
                    isOpen={isOpen}
                    onToggle={onToggle}
                />
            )}
        </Sidebar>
    );
};
