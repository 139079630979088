import type * as Plotly from "plotly.js";
import * as PlotlyPlotter from "plotly.js";

const getChartElement = (): Plotly.PlotlyHTMLElement =>
    document.getElementById("chart") as Plotly.PlotlyHTMLElement;

export const usePlotControls = () => {
    const redraw = () => {
        const el = getChartElement();

        void PlotlyPlotter.relayout(el, {
            "xaxis.autorange": true,
            "yaxis.autorange": true,
        });
    };

    const downloadPreview = (plotTitle?: string) => {
        const el = getChartElement();

        const dimension = el.getBoundingClientRect();
        const width = dimension.width;
        const height = dimension.height;

        if (!width || !height) {
            return;
        }

        void PlotlyPlotter.downloadImage(el, {
            filename: plotTitle ?? "plot",
            format: "png",
            width: width * 2,
            height: height * 2,
        });
    };
    const zoomIn = () => {
        const el = getChartElement();

        const xRange = el.layout.xaxis.range;
        const yRange = el.layout.yaxis.range;

        if (!xRange || !yRange) {
            return;
        }

        void PlotlyPlotter.relayout(el, {
            "xaxis.range[0]": xRange[0] * 0.75,
            "xaxis.range[1]": xRange[1] * 0.75,
            "yaxis.range[0]": yRange[0] * 0.75,
            "yaxis.range[1]": yRange[1] * 0.75,
        });
    };
    const zoomOut = () => {
        const el = getChartElement();

        const xRange = el.layout.xaxis.range;
        const yRange = el.layout.yaxis.range;

        if (!xRange || !yRange) {
            return;
        }

        void PlotlyPlotter.relayout(el, {
            "xaxis.range[0]": xRange[0] * 1.25,
            "xaxis.range[1]": xRange[1] * 1.25,
            "yaxis.range[0]": yRange[0] * 1.25,
            "yaxis.range[1]": yRange[1] * 1.25,
        });
    };

    return {
        redraw,
        zoomIn,
        zoomOut,
        downloadPreview,
    };
};
