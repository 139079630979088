import { MeasurementGroupStatus } from "@app/domain";
import type { StatusColorScheme } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";

export const getColorScheme = (status: string): StatusColorScheme => {
    switch (status) {
        case MeasurementGroupStatus.Draft:
            return "gray";
        case MeasurementGroupStatus.Submitted:
            return "yellow";
        case MeasurementGroupStatus.Accepted:
            return "cyan";
        case MeasurementGroupStatus.Scheduled:
            return "teal";
        case MeasurementGroupStatus.Completed:
            return "green";
        case MeasurementGroupStatus.Declined:
            return "red";
        default:
            return "gray";
    }
};
