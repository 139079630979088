import type { FC, PropsWithChildren } from "react";
import { memo, useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { buildApiContextValue } from "@app/contexts/APIContext/apiClient.tsx";
import { AContext } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useRemoteConfigContext } from "@app/contexts/ConfigContext/ConfigContext.tsx";

export const APIContext: FC<PropsWithChildren> = memo(({ children }) => {
    const config = useRemoteConfigContext();

    const auth = useAuth();

    const api = useMemo(
        () =>
            buildApiContextValue({
                token: auth.user?.id_token,
                apiPath: config.apiPath,
            }),
        [auth.user?.id_token, config.apiPath],
    );

    return <AContext.Provider value={api}>{children}</AContext.Provider>;
});

APIContext.displayName = "ConfigContext";
