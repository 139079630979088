import type {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    ReactNode,
} from "react";

import type { BoxProps } from "@chakra-ui/react";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    forwardRef,
    Select,
} from "@chakra-ui/react";

import { InputField } from "@mt-components/Input/InputField.tsx";

export const SelectField: FC<{
    isDisabled?: boolean;
    isReadOnly?: boolean;
    options: string[] | { label: string; value: string }[];
    value: string;
    help?: ReactNode;
    name?: string;
    label?: ReactNode;
    onChange?: ChangeEventHandler<HTMLSelectElement>;
    onBlur?: FocusEventHandler<HTMLSelectElement>;
    formControlProps?: BoxProps;
}> = forwardRef(
    (
        {
            label,
            options,
            formControlProps,
            isDisabled,
            isReadOnly,
            errorMessage,
            help,
            ...rest
        },
        ref,
    ) => {
        return (
            <>
                {isReadOnly ? (
                    <InputField label={label} isReadOnly value={rest.value} />
                ) : (
                    <FormControl
                        px="4px"
                        isDisabled={isDisabled}
                        isReadOnly={isReadOnly}
                        {...formControlProps}
                    >
                        <FormLabel mb={1} width="100%">
                            {label}
                        </FormLabel>

                        <Select
                            ref={ref}
                            isDisabled={isDisabled}
                            isReadOnly={isReadOnly}
                            {...rest}
                        >
                            {options.map((o) => {
                                if (typeof o === "string") {
                                    return <option key={o}>{o}</option>;
                                }
                                return (
                                    <option key={o.value} value={o.value}>
                                        {o.label}
                                    </option>
                                );
                            })}
                        </Select>
                        {(errorMessage || help) && (
                            <FormHelperText
                                color={!errorMessage ? "gray" : "red.600"}
                            >
                                {!errorMessage ? help : errorMessage}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            </>
        );
    },
);
