import { tableAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";

import { FontFamily, textStyles } from "@mt-design/fontStyles.ts";

const {
    definePartsStyle: definePartsStyleTable,
    defineMultiStyleConfig: defineMultiStyleConfigTable,
} = createMultiStyleConfigHelpers(tableAnatomy.keys);

const baseStyleTable = definePartsStyleTable({
    // define the part you're going to style
    table: {
        fontSize: "24px",
    },
    td: {
        ...textStyles.T2,
        borderBottom: "1px solid",
        borderColor: "gray.300",
        color: "gray.800",
        fontFamily: FontFamily.PRIMARY,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
    },
    th: {
        borderColor: "gray.300",
        color: "gray.800",
        fontFamily: FontFamily.PRIMARY,
        fontSize: "24px !important",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
    },
    thead: {
        borderBottom: "1px solid",
        borderColor: "gray.300",
        color: "gray.800",
        fontFamily: FontFamily.PRIMARY,
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "18px",
    },
    sizes: {
        sm: {
            th: {
                fontSize: "24px",
            },
            caption: {
                fontSize: "24px",
            },
        },
        md: {
            th: {
                fontSize: "24px",
            },
            caption: {
                fontSize: "24px",
            },
        },
        lg: {
            th: {
                fontSize: "24px",
            },
            caption: {
                fontSize: "24px",
            },
        },
    },
});

const tableStyles = defineMultiStyleConfigTable({
    baseStyle: baseStyleTable,
});

export const tableTheme = extendTheme({
    components: { Table: tableStyles },
});
