import type { FC, ReactNode } from "react";

import type {
    ModalBodyProps as ChakraModalBodyProps,
    ModalProps as ChakraModalProps,
} from "@chakra-ui/react";
import {
    Box,
    Modal as ChakraModal,
    ModalBody as ChakraModalBody,
    ModalCloseButton,
    ModalContent as ChakraModalContent,
    ModalFooter as ChakraModalFooter,
    ModalHeader as ChakraModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";

export interface ModalProps extends ChakraModalProps {
    name?: string;
    showProgress?: boolean;
    buttonRight?: ReactNode;
    buttonLeft?: ReactNode;
    header: ReactNode;
    bodyProps?: ChakraModalBodyProps;
    contentProps?: {
        width?: string;
        maxWidth?: string;
    };
}

export const Modal: FC<ModalProps> = ({
    name,
    children,
    buttonRight,
    buttonLeft,
    header,
    showProgress,
    bodyProps,
    ...props
}) => {
    return (
        <ChakraModal
            useInert={false}
            onOverlayClick={
                props.onOverlayClick ? props.onOverlayClick : props.onClose
            }
            {...props}
            motionPreset="none"
        >
            <ModalOverlay
                backdropFilter="blur(0.5px)"
                backgroundColor="rgba(0,0,0,0.05)"
            />
            <ChakraModalContent
                maxHeight="90%"
                display="flex"
                // REVIEW: This conflicts with chakra modal size attribute at the top. Needed for large modals, like Set SSW from Image for beamtime.
                // minWidth="350px"
                // maxWidth="550px"
                overflow="hidden"
                {...props.contentProps}
            >
                <ChakraModalHeader
                    flex="0 0 60px"
                    aria-label={name}
                    overflow="hidden"
                    borderBottom="1px solid"
                    borderBottomColor="gray.100"
                >
                    <ModalCloseButton onClick={props.onClose} />
                    {header}
                </ChakraModalHeader>

                {showProgress && (
                    <Box
                        className="progress"
                        width="1%"
                        height="6px"
                        id="progress"
                        bg="secondary.200"
                    ></Box>
                )}

                <ChakraModalBody
                    px={6}
                    id="container2"
                    flex="1"
                    overflowY="auto"
                    overflowX="hidden"
                    {...bodyProps}
                >
                    {children}
                </ChakraModalBody>

                {buttonLeft || buttonRight ? (
                    <ChakraModalFooter
                        overflowY="hidden"
                        borderTop="1px solid"
                        borderTopColor="gray.100"
                        gap="12px"
                        flex="0 0 68px"
                        overflow="hidden"
                    >
                        {buttonLeft}
                        {buttonRight}
                    </ChakraModalFooter>
                ) : null}
            </ChakraModalContent>
        </ChakraModal>
    );
};

Modal.displayName = "Modal";

export interface Dialog extends ChakraModalProps {
    name?: string;
    bodyProps?: ChakraModalBodyProps;
    contentProps?: {
        width?: string;
        maxWidth?: string;
    };
}

export const InfoModal: FC<ModalProps> = ({
    children,
    bodyProps,
    ...props
}) => {
    return (
        <ChakraModal
            useInert={false}
            onOverlayClick={
                props.onOverlayClick ? props.onOverlayClick : props.onClose
            }
            {...props}
            motionPreset="none"
        >
            <ModalOverlay
                backdropFilter="blur(0.5px)"
                backgroundColor="rgba(0,0,0,0.05)"
            />
            <ChakraModalContent
                maxHeight="90%"
                display="flex"
                minWidth="350px"
                maxWidth="550px"
                overflow="hidden"
                {...props.contentProps}
            >
                <ChakraModalBody
                    px={6}
                    id="container2"
                    flex="1"
                    overflowY="auto"
                    overflowX="hidden"
                    {...bodyProps}
                >
                    {children}
                </ChakraModalBody>
            </ChakraModalContent>
        </ChakraModal>
    );
};

InfoModal.displayName = "Dialog";
