import type { ReactNode } from "react";
import * as React from "react";

import type { FormikValues, useFormik } from "formik";
import { fromPairs, toPairs } from "ramda";
import type { ValueOf } from "type-fest";

import { Box, Text, Tooltip } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

export type Property = {
    label: string;
    required?: boolean;
    rules?: {
        required?: boolean;
    };
};

export function formatLabel(property: Property, tooltip?: string): ReactNode {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box position="relative">
                <Text as="span">{property.label}</Text>
                {property.rules?.required || property.required ? (
                    <Text
                        lineHeight="8px"
                        top="4px"
                        position="absolute"
                        as="span"
                        color="red.600"
                        fontSize="22px"
                    >
                        *
                    </Text>
                ) : null}
            </Box>
            <Box>
                {tooltip && (
                    <Tooltip label={tooltip}>
                        <Box w="14px" color="gray.600">
                            <Icon.Info size="14px" />
                        </Box>
                    </Tooltip>
                )}
            </Box>
        </Box>
    );
}

export function removeEmptyStringValues<Values extends Record<string, unknown>>(
    values: Values,
): {
    [p in keyof Values]: Values[keyof Values] | undefined;
} {
    const list: [keyof Values, ValueOf<Values>][] = toPairs(values);

    const cleaned = list.map((e): [keyof Values, (typeof e)[1] | undefined] => {
        const key = e[0];
        return [key, e[1] === "" ? undefined : e[1]];
    });

    return fromPairs(cleaned);
}

export const createFormRegistrator =
    <FormShape extends FormikValues>(
        form: ReturnType<typeof useFormik<FormShape>>,
    ) =>
    <Name extends keyof FormShape>(
        name: Name,
    ): {
        onChange: (e: React.ChangeEvent<HTMLElement>) => void;
        onBlur: (e: React.FocusEvent<HTMLElement>) => void;
        value: FormShape[Name];
        name: Name;
        errorMessage: string | undefined;
    } => {
        const thisError = form.errors[name];
        let error: string | undefined;

        if (typeof thisError === "string") {
            error = thisError;
        } else if (
            Array.isArray(thisError) &&
            typeof thisError[0] === "string"
        ) {
            error = thisError[0];
        } else {
            error = undefined;
        }

        return {
            onChange: form.handleChange,
            onBlur: form.handleBlur,
            value: form.values[name],
            name,
            errorMessage: form.touched[name] ? error : undefined,
        };
    };
