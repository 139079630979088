import type { FC } from "react";
import { memo, useCallback, useMemo } from "react";

import { identity, indexBy, toPairs } from "ramda";

import { Box, Center } from "@chakra-ui/react";
import type { OnChangeFn } from "@tanstack/react-table";

import { LocalStorageKeys } from "@app/config.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import { agentSampleTableSpec } from "@app/domain/services/sample/ioMapping.ts";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import {
    downloadArrayAsCSV,
    formatDataForDownload,
} from "@mt-tools/io/downloadFile.ts";
import { filterMap, toId } from "@mt-tools/iterating/filter.ts";
import type { IndexBoolean } from "@mt-tools/types.ts";

import { Icon } from "@mt-design/icons.tsx";

import { useDisclosureWithStorage } from "src/packages/hooks/useDisclosureWithStorage.ts";

import { SamplesTable } from "./Samples.table.tsx";
import { SampleSidebar } from "./SampleSidebar.tsx";

type SampleSelection = {
    list: string[];
    byId: Record<string, boolean>;
};

type Props = {
    samples: SampleModel[];
    sampleSelection: SampleSelection;
    onSelectSamples: (sampleIds: string[]) => void;
    onToggleForm: () => void;
};

export const Samples: FC<Props> = memo(
    ({ samples, sampleSelection, onSelectSamples, onToggleForm }) => {
        const sidebarDisclosure = useDisclosureWithStorage(
            LocalStorageKeys.samplesSidebar,
        );

        const setSelectedSamples: OnChangeFn<IndexBoolean> = useCallback(
            (updater) => {
                if (typeof updater === "function") {
                    const newRowSelection = updater(sampleSelection.byId);
                    const entries = toPairs(newRowSelection);
                    const ids = filterMap(identity, entries);
                    onSelectSamples(ids);
                    return;
                }

                const entries = toPairs(updater);
                const ids = filterMap(identity, entries);

                onSelectSamples(ids);
                return;
            },
            [onSelectSamples, sampleSelection.byId],
        );

        const samplesById = useMemo(() => indexBy(toId, samples), [samples]);

        const selectedSamples = useMemo(
            () =>
                filterMap((id) => {
                    return [samplesById[id], Boolean(samplesById[id])];
                }, sampleSelection.list),
            [sampleSelection.list, samplesById],
        );

        const onDownloadList = useCallback(() => {
            const downloadable = formatDataForDownload<SampleModel>(
                agentSampleTableSpec,
                samples,
            );
            downloadArrayAsCSV(
                downloadable.data,
                downloadable.keys,
                "sample-list.csv",
            );
        }, [samples]);

        return (
            <Box
                id="samples-main"
                mx="auto"
                display="flex"
                flexDirection="row"
                h="100%"
                overflow="hidden"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    overflowX="auto"
                    flex={1}
                    h="100%"
                    overflow="hidden"
                >
                    {samples.length === 0 ? (
                        <Center height="100%" width="100%" overflow="hidden">
                            <EmptyState
                                isPrimaryDisabled
                                icon={() => (
                                    <Icon.SamplesImage
                                        height="120px"
                                        width="120px"
                                    />
                                )}
                                heading="No Samples"
                                body="You don't have any samples yet. Start by creating one."
                            />
                        </Center>
                    ) : (
                        <Box
                            w="100%"
                            overflow="hidden"
                            height="100%"
                            overflowX="hidden"
                            display="flex"
                            flexDirection="column"
                        >
                            <PageHeader
                                crumbs={[{ id: "samples", label: "Samples" }]}
                                actions={[
                                    {
                                        id: "download-list",
                                        isPrimary: true,
                                        label: "Download list",
                                        onClick: onDownloadList,
                                    },
                                    {
                                        id: "add-sample",
                                        label: "Add sample",
                                        onClick: onToggleForm,
                                        isPrimary: true,
                                        isTruncated: true,
                                        hasTooltip: true,
                                    },
                                ]}
                            />
                            <SamplesTable
                                samples={samples}
                                onSelectSample={setSelectedSamples}
                                sampleSelection={sampleSelection}
                            />
                        </Box>
                    )}
                </PageLayout>
                {Boolean(samples.length) && (
                    <SampleSidebar
                        isOpen={sidebarDisclosure.isOpen}
                        samples={selectedSamples}
                        onToggle={sidebarDisclosure.onToggle}
                    />
                )}
            </Box>
        );
    },
);

Samples.displayName = "Samples";
