import type { FC } from "react";

import { Tag } from "@chakra-ui/react";

import {
    type OrganizationRole,
    OrganizationRoles,
} from "../domain/services/organization";

export const RoleTag: FC<{ role: OrganizationRole }> = (props) => {
    switch (props.role) {
        case OrganizationRoles.OWNER:
            return <Tag colorScheme="blue">Owner</Tag>;
        case OrganizationRoles.COLLABORATOR:
            return <Tag colorScheme="purple">Collaborator</Tag>;
        case OrganizationRoles.VIEWER:
            return <Tag colorScheme="green">Viewer</Tag>;
        default:
            throw new Error(`Unknown role ${props.role}`);
    }
};
