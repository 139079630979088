import type { FC } from "react";
import { useState } from "react";

import { SingleDatepicker } from "chakra-dayzed-datepicker";
import type { Promisable } from "type-fest";

import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";

import type {
    BeamtimeModel,
    CreateBeamtimePayload,
} from "@app/domain/api/agent/beamtime.ts";
import { BeamtimeStatus } from "@app-components/beamtime/BeamtimeStatus.tsx";

import { Modal } from "@mt-components/Modal/Modal.tsx";

type Props = {
    isOpen: boolean;
    initialData?: BeamtimeModel;
    onCreate?: (params: CreateBeamtimePayload) => Promisable<unknown>;
    onUpdate?: (params: BeamtimeModel) => Promisable<unknown>;
    onCancel: () => Promisable<unknown>;
};
export const BeamtimeModal: FC<Props> = ({
    onCreate,
    onUpdate,
    onCancel,
    isOpen,
    initialData,
}) => {
    const date1 = initialData?.date
        ? new Date(initialData.date)
        : new Date(new Date().setHours(12));

    const [title, setTitle] = useState<string>(initialData?.name ?? "");
    const [dateObj, setDate] = useState<Date>(date1);

    const onClick = () => {
        if (!title) {
            return;
        }

        const date3 = dateObj.toISOString();
        if (initialData?.id) {
            onUpdate?.({
                ...initialData,
                name: title,
                date: date3,
            });
            return;
        }
        onCreate?.({
            name: title,
            date: date3,
            status: BeamtimeStatus.DRAFT,
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={onCancel}
            header="New beamtime"
            buttonRight={
                <Button
                    isDisabled={!title}
                    onClick={onClick}
                    colorScheme="blue"
                >
                    Create
                </Button>
            }
        >
            <Box overflow="visible" w="100%" h="500px">
                <FormControl onSubmit={onClick}>
                    <FormLabel mt="4">Title</FormLabel>
                    <Input
                        required
                        title="Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                    <FormLabel mt="4">Date</FormLabel>
                    <SingleDatepicker date={dateObj} onDateChange={setDate} />
                </FormControl>
            </Box>
        </Modal>
    );
};
