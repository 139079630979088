// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */

// @ts-nocheck
import { Component } from "react";
import { Box } from "@chakra-ui/react";

import * as Plotly from "plotly.js";
import * as React from "react";
import type { Figure, GraphDiv } from "@app/pages/agent/plot/types.tsx";

export interface PlotParams {
    data: Partial<Plotly.Data>[];
    layout: Partial<Plotly.Layout>;
    frames?: Plotly.Frame[] | undefined;
    config?: Partial<Plotly.Config> | undefined;
    /**
     * When provided, causes the plot to update only when the revision is incremented.
     */
    revision?: number | undefined;
    /**
     * Callback executed after plot is initialized.
     * @param figure Object with three keys corresponding to input props: data, layout and frames.
     * @param graphDiv Reference to the DOM node into which the figure was rendered.
     */
    onInitialized?:
        | ((figure: Readonly<Figure>, graphDiv: GraphDiv) => void)
        | undefined;
    /**
     * Callback executed when when a plot is updated due to new data or layout, or when user interacts with a plot.
     * @param figure Object with three keys corresponding to input props: data, layout and frames.
     * @param graphDiv Reference to the DOM node into which the figure was rendered.
     */
    onUpdate?:
        | ((figure: Readonly<Figure>, graphDiv: GraphDiv) => void)
        | undefined;
    /**
     * Callback executed when component unmounts, before Plotly.purge strips the graphDiv of all private attributes.
     * @param figure Object with three keys corresponding to input props: data, layout and frames.
     * @param graphDiv Reference to the DOM node into which the figure was rendered.
     */
    onPurge?:
        | ((figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => void)
        | undefined;
    /**
     * Callback executed when a plotly.js API method rejects
     * @param err Error
     */
    onError?: ((err: Readonly<Error>) => void) | undefined;
    /**
     * id assigned to the <div> into which the plot is rendered.
     */
    divId?: string | undefined;
    /**
     * applied to the <div> into which the plot is rendered
     */
    className?: string | undefined;
    /**
     * used to style the <div> into which the plot is rendered
     */
    style?: React.CSSProperties | undefined;
    /**
     * Assign the graph div to window.gd for debugging
     */
    debug?: boolean | undefined;
    /**
     * When true, adds a call to Plotly.Plot.resize() as a window.resize event handler
     */
    useResizeHandler?: boolean | undefined;

    onAfterExport?: (() => void) | undefined;
    onAfterPlot?: (() => void) | undefined;
    onAnimated?: (() => void) | undefined;
    onAnimatingFrame?:
        | ((event: Readonly<Plotly.FrameAnimationEvent>) => void)
        | undefined;
    onAnimationInterrupted?: (() => void) | undefined;
    onAutoSize?: (() => void) | undefined;
    onBeforeExport?: (() => void) | undefined;
    onBeforeHover?:
        | ((event: Readonly<Plotly.PlotMouseEvent>) => boolean)
        | undefined;
    onButtonClicked?:
        | ((event: Readonly<Plotly.ButtonClickEvent>) => void)
        | undefined;
    onClick?: ((event: Readonly<Plotly.PlotMouseEvent>) => void) | undefined;
    onClickAnnotation?:
        | ((event: Readonly<Plotly.ClickAnnotationEvent>) => void)
        | undefined;
    onDeselect?: (() => void) | undefined;
    onDoubleClick?: (() => void) | undefined;
    onFramework?: (() => void) | undefined;
    onHover?: ((event: Readonly<Plotly.PlotHoverEvent>) => void) | undefined;
    onLegendClick?:
        | ((event: Readonly<Plotly.LegendClickEvent>) => boolean)
        | undefined;
    onLegendDoubleClick?:
        | ((event: Readonly<Plotly.LegendClickEvent>) => boolean)
        | undefined;
    onRelayout?:
        | ((event: Readonly<Plotly.PlotRelayoutEvent>) => void)
        | undefined;
    onRestyle?:
        | ((event: Readonly<Plotly.PlotRestyleEvent>) => void)
        | undefined;
    onRedraw?: (() => void) | undefined;
    onSelected?:
        | ((event: Readonly<Plotly.PlotSelectionEvent>) => void)
        | undefined;
    onSelecting?:
        | ((event: Readonly<Plotly.PlotSelectionEvent>) => void)
        | undefined;
    onSliderChange?:
        | ((event: Readonly<Plotly.SliderChangeEvent>) => void)
        | undefined;
    onSliderEnd?:
        | ((event: Readonly<Plotly.SliderEndEvent>) => void)
        | undefined;
    onSliderStart?:
        | ((event: Readonly<Plotly.SliderStartEvent>) => void)
        | undefined;
    onTransitioning?: (() => void) | undefined;
    onTransitionInterrupted?: (() => void) | undefined;
    onUnhover?: ((event: Readonly<Plotly.PlotMouseEvent>) => void) | undefined;
    onWebGlContextLost?: (() => void) | undefined;
}

// The naming convention is:
//   - events are attached as `'plotly_' + eventName.toLowerCase()`
//   - react props are `'on' + eventName`
const eventNames = [
    "AfterExport",
    "AfterPlot",
    "Animated",
    "AnimatingFrame",
    "AnimationInterrupted",
    "AutoSize",
    "BeforeExport",
    "BeforeHover",
    "ButtonClicked",
    "Click",
    "ClickAnnotation",
    "Deselect",
    "DoubleClick",
    "Framework",
    "Hover",
    "LegendClick",
    "LegendDoubleClick",
    "Relayout",
    "Relayouting",
    "Restyle",
    "Redraw",
    "Selected",
    "Selecting",
    "SliderChange",
    "SliderEnd",
    "SliderStart",
    "SunburstClick",
    "Transitioning",
    "TransitionInterrupted",
    "Unhover",
    "WebGlContextLost",
] as const;

type EventName = (typeof eventNames)[number];

const updateEvents = [
    "plotly_restyle",
    "plotly_redraw",
    "plotly_relayout",
    "plotly_relayouting",
    "plotly_doubleclick",
    "plotly_animated",
    "plotly_sunburstclick",
] as const;

// Check if a window is available since SSR (server-side rendering)
// breaks unnecessarily if you try to use it server-side.
const isBrowser = typeof window !== "undefined";

type EventTypes = `on${(typeof eventNames)[0]}`;

export class Charty extends Component<PlotParams> {
    private p;
    private resizeHandler;
    private handlers: {
        [e in EventTypes]?: (e: Event) => void;
    };

    private el: any = null;
    private unmounting: boolean | undefined;
    constructor(props: PlotParams) {
        super(props);

        this.p = Promise.resolve();
        this.resizeHandler = null;
        this.handlers = {};

        this.syncWindowResize = this.syncWindowResize.bind(this);
        this.syncEventHandlers = this.syncEventHandlers.bind(this);
        this.attachUpdateEvents = this.attachUpdateEvents.bind(this);
        this.getRef = this.getRef.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.figureCallback = this.figureCallback.bind(this);
        this.updatePlotly = this.updatePlotly.bind(this);
    }

    updatePlotly(
        shouldInvokeResizeHandler: boolean,
        figureCallbackFunction: PlotParams["onInitialized"],
        shouldAttachUpdateEvents: boolean,
    ) {
        this.p = this.p
            .then(() => {
                if (this.unmounting) {
                    return;
                }
                if (!this.el) {
                    throw new Error("Missing element reference");
                }

                return Plotly.react(this.el, {
                    data: this.props.data,
                    layout: this.props.layout,
                    config: this.props.config,
                    frames: this.props.frames,
                });
            })
            .then(() => {
                if (this.unmounting) {
                    return;
                }
                this.syncWindowResize(shouldInvokeResizeHandler);
                this.syncEventHandlers();
                this.figureCallback(figureCallbackFunction);
                if (shouldAttachUpdateEvents) {
                    this.attachUpdateEvents();
                }
            })
            .catch((err) => {
                if (this.props.onError) {
                    console.log(err);
                    this.props.onError(err);
                }
            });
    }

    componentDidMount() {
        this.unmounting = false;
        this.updatePlotly(true, this.props.onInitialized, true);
    }

    componentDidUpdate(prevProps: PlotParams) {
        this.unmounting = false;

        // frames *always* changes identity so fall back to check length only :(
        const numPrevFrames =
            prevProps.frames && prevProps.frames.length
                ? prevProps.frames.length
                : 0;
        const numNextFrames =
            this.props.frames && this.props.frames.length
                ? this.props.frames.length
                : 0;

        const figureChanged = !(
            prevProps.layout === this.props.layout &&
            prevProps.data === this.props.data &&
            prevProps.config === this.props.config &&
            numNextFrames === numPrevFrames
        );
        const revisionDefined = prevProps.revision !== void 0;
        const revisionChanged = prevProps.revision !== this.props.revision;

        if (
            !figureChanged &&
            (!revisionDefined || (revisionDefined && !revisionChanged))
        ) {
            return;
        }

        this.updatePlotly(false, this.props.onUpdate, false);
    }

    // componentWillUnmount() {
    //     this.unmounting = true;
    //
    //     this.figureCallback(this.props.onPurge);
    //
    //     if (this.resizeHandler && isBrowser) {
    //         window.removeEventListener("resize", this.resizeHandler);
    //         this.resizeHandler = null;
    //     }
    //
    //     this.removeUpdateEvents();
    //
    //     Plotly.purge(this.el);
    // }

    attachUpdateEvents() {
        if (!this.el || !this.el.removeListener) {
            return;
        }

        updateEvents.forEach((updateEvent) => {
            this.el.on(updateEvent, this.handleUpdate);
        });
    }

    removeUpdateEvents() {
        if (!this.el || !this.el.removeListener) {
            return;
        }

        updateEvents.forEach((updateEvent) => {
            this.el.removeListener(updateEvent, this.handleUpdate);
        });
    }

    handleUpdate() {
        this.figureCallback(this.props.onUpdate);
    }

    figureCallback(callback: PlotParams["onUpdate"]) {
        if (typeof callback === "function") {
            const { data, layout } = this.el;
            const frames = this.el._transitionData
                ? this.el._transitionData._frames
                : null;
            const figure = { data, layout, frames };
            callback(figure, this.el);
        }
    }

    syncWindowResize(invoke: boolean) {
        if (!isBrowser) {
            return;
        }

        if (this.props.useResizeHandler && !this.resizeHandler) {
            this.resizeHandler = () => Plotly.Plots.resize(this.el);
            window.addEventListener("resize", this.resizeHandler);
            if (invoke) {
                this.resizeHandler();
            }
        } else if (!this.props.useResizeHandler && this.resizeHandler) {
            window.removeEventListener("resize", this.resizeHandler);
            this.resizeHandler = null;
        }
    }

    getRef(el: HTMLDivElement) {
        this.el = el;
        if (this.props.debug && isBrowser) {
            // @ts-ignore

            window.gd = this.el;
        }
    }

    // Attach and remove event handlers as they're added or removed from props:
    syncEventHandlers() {
        const events: EventTypes[] = eventNames.map((n: EventName) => `on${n}`);
        events.forEach((eventName) => {
            const props = this.props;
            const prop = props[eventName];
            const handler = this.handlers[eventName];
            const hasHandler = Boolean(handler);

            if (prop && !hasHandler) {
                this.addEventHandler(eventName, prop);
            } else if (!prop && hasHandler) {
                // Needs to be removed:
                this.removeEventHandler(eventName);
            } else if (prop && hasHandler && prop !== handler) {
                // replace the handler
                this.removeEventHandler(eventName);
                this.addEventHandler(eventName, prop);
            }
        });
    }

    addEventHandler(eventName: EventTypes, prop: any) {
        this.handlers[eventName] = prop;
        this.el.on(
            this.getPlotlyEventName(eventName),
            this.handlers[eventName],
        );
    }

    removeEventHandler(eventName: EventTypes) {
        this.el.removeListener(
            this.getPlotlyEventName(eventName),
            this.handlers[eventName],
        );
        delete this.handlers[eventName];
    }

    getPlotlyEventName(eventName: EventName) {
        return "plotly_" + eventName.toLowerCase();
    }

    render() {
        return (
            <Box
                data-test-id="charty"
                width="100%"
                height="100%"
                id={this.props.divId}
                style={this.props.style}
                ref={this.getRef}
                className={this.props.className}
            />
        );
    }
}

Charty.defaultProps = {
    debug: false,
    useResizeHandler: false,
    data: [],
    style: { position: "relative", display: "inline-block" },
};
