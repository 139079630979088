// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import tinyColor from "tinycolor2";

const TOO_LIGHT_FACTOR = 0.8;

export const tooLight = (color: string) => {
    const hslColor = tinyColor(color).toHsl();
    return hslColor.l > TOO_LIGHT_FACTOR;
};
