import { type ParsedLocation, redirect } from "@tanstack/react-router";

import type { RemoteConfig } from "@app/contexts/ConfigContext/OIDCAndConfig.tsx";
import { routes } from "@app/Routes/routes.ts";

import { Role } from "../domain/auth";

export type RouterContext = {
    /**
     * Undefined in case if user not authenticated yet
     */
    role: Role | undefined;
    userId: string | undefined;
    config: RemoteConfig;
};

export type BeforeLoadParams = {
    context: RouterContext;
    location: ParsedLocation;
};

export const notAuthGoToLogin = async (params: BeforeLoadParams) => {
    if (!params.context.role) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect({ to: routes.login.url });
    }
};

export const preventNavToLoginOnceAuthenticated = async (
    params: BeforeLoadParams,
) => {
    if (params.context.role && location.pathname.endsWith(routes.login.url)) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect({ to: routes.user.home.url });
    }
};

export const isAgentOrGoHome = async (params: BeforeLoadParams) => {
    if (params.context.role !== Role.AGENT) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect({ to: routes.user.home.url });
    }
};

export const hasAccessToPlotting = async (params: BeforeLoadParams) => {
    const userId = params.context.userId;
    const plotting = params.context.config.featureFlags?.plotting;
    if (!userId || !plotting?.[userId]) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect({ to: routes.user.home.url });
    }
};

export const isUserOrGoHome = async (params: BeforeLoadParams) => {
    if (params.context.role !== Role.USER) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw redirect({ to: routes.agent.home.url });
    }
};

export const ensureAuthAndAgent = async (params: BeforeLoadParams) => {
    await notAuthGoToLogin(params);
    await isAgentOrGoHome(params);
    return;
};
export const ensureAuthAndUser = async (params: BeforeLoadParams) => {
    await notAuthGoToLogin(params);
    await isUserOrGoHome(params);
};
