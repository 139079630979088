import type { FC } from "react";

import { Box } from "@chakra-ui/react";

import type { BeamtimeStatusType } from "@app/domain/api/agent/beamtime.ts";
import {
    BeamtimeStatus,
    BeamtimeStatusC,
} from "@app-components/beamtime/BeamtimeStatus.tsx";

import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

type Props = {
    value?: BeamtimeStatusType;
    onChange: (value?: BeamtimeStatusType) => void;
};

const statusOptions = [
    {
        label: "All",
        rawLabel: "All",
        value: "All" as const,
    },
    {
        label: "draft",
        rawLabel: "draft",
        value: BeamtimeStatus.DRAFT,
    },
    {
        label: "published",
        rawLabel: "published",
        value: BeamtimeStatus.PUBLISHED,
    },
    {
        label: "active",
        rawLabel: "active",
        value: BeamtimeStatus.ACTIVE,
    },
    {
        label: "completed",
        rawLabel: "completed",
        value: BeamtimeStatus.COMPLETED,
    },
    {
        label: "cancelled",
        rawLabel: "cancelled",
        value: BeamtimeStatus.CANCELED,
    },
];

export const BeamtimeStatusFilter: FC<Props> = ({ value, onChange }) => {
    return (
        <Box>
            <SingleSelect<{
                value: BeamtimeStatusType | "All";
                label: string;
                rawLabel: string;
            }>
                placeholder="Filter by status"
                value={statusOptions.find((opt) => opt.value === value)}
                options={statusOptions}
                formatOptionLabel={(data) => {
                    return <BeamtimeStatusC status={data.value} />;
                }}
                onChange={(newValue) => {
                    onChange(
                        newValue?.value === "All" ? undefined : newValue?.value,
                    );
                }}
            />
        </Box>
    );
};
