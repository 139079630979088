import type { FC } from "react";
import { memo } from "react";

import { Box, Center } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import { Beamtime } from "@app/domain/api/agent/beamtime.ts";

import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { BeamtimeSync } from "./BeamtimeSync.tsx";

const routeApi = new RouteApi({ id: "/agent/beamtimes/$beamtimeId" });

export const BeamtimeSyncContainer: FC = () => {
    const params = routeApi.useParams();
    const beamtimeParams = {
        beamtimeId: params.beamtimeId,
    };

    const { data: beamtime, isLoading: isLoadingBeamtime } =
        Beamtime.useAdminGet(beamtimeParams);

    if (!beamtime && isLoadingBeamtime) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    if (!beamtime?.data) {
        return <Box>Beamtime not found</Box>;
    }

    return <BeamtimeSync beamtime={beamtime.data} />;
};

BeamtimeSyncContainer.displayName = "BeamtimeSyncContainer";

export const BeamtimeSyncPage = memo(() => {
    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <BeamtimeSyncContainer />
            </Box>
        </PageLayout>
    );
});

BeamtimeSyncPage.displayName = "BeamtimeSyncPage";
