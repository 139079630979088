import type { FC } from "react";

import { Center } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { RequestDetail } from "@app/pages/user/requestDetails/RequestDetail.tsx";
import { GenericErrorUser } from "@app-components/shell/errors/GenericError/GenericError.user.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { isFetchError404 } from "@mt-tools/fetch.ts";

const routeApi = new RouteApi({
    id: "/organizations/$orgId/measurements/$orderId",
});

export const RequestDetailsContainer: FC = () => {
    const params = routeApi.useParams();
    const { currentOrg } = useOrgContext();

    const deleteTask = MeasurementTask.useDeleteAll();

    const requestQuery = Request.useGet({
        organizationId: currentOrg,
        measurementGroupId: params.orderId,
    });

    const samplesQuery = Sample.useGetAll({
        params: {
            path: {
                organizationId: currentOrg,
            },
        },
    });

    const taskOfGroupQuery = MeasurementTask.useGetAll({
        path: {
            organizationId: currentOrg,
        },
        query: {
            filter: {
                measurementGroupId: params.orderId,
            },
        },
    });

    const deleteTasks = async (data: {
        measurementTaskIds: string[];
        organizationId: string;
    }) => {
        return await deleteTask.mutateAsync(data);
    };

    if (!requestQuery.data || !samplesQuery.data || !taskOfGroupQuery.data) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    if (
        isFetchError404(requestQuery.error) ||
        isFetchError404(taskOfGroupQuery.error)
    ) {
        return <NotFound />;
    }

    if (requestQuery.error || taskOfGroupQuery.error) {
        return <GenericErrorUser />;
    }

    return (
        <RequestDetail
            samples={samplesQuery.data}
            measurementTasksOfGroup={taskOfGroupQuery.data}
            measurementGroup={requestQuery.data}
            deleteTasks={deleteTasks}
        />
    );
};

RequestDetailsContainer.displayName = "MeasurementGroupDetailsContainer";
