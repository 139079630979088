import type { FC, PropsWithChildren } from "react";
import { memo } from "react";

import { useQuery } from "@tanstack/react-query";

import { OrgContext } from "@app/contexts/OrgContext/OrgContext.tsx";

import { selfOrganizationsQueryKey } from "src/api/client";

import { useAPI } from "../APIContext/useApiContext";

export const OrgContextContainer: FC<PropsWithChildren> = memo(
    ({ children }) => {
        const api = useAPI();

        const organizationsQuery = useQuery({
            queryKey: selfOrganizationsQueryKey(),
            queryFn: () => api.publicClient.GET("/self/organizations"),
        });

        if (organizationsQuery.isLoading || !organizationsQuery.data) {
            return null;
        }

        return (
            <OrgContext
                organizations={organizationsQuery.data.data?.items ?? []}
            >
                {children}
            </OrgContext>
        );
    },
);

OrgContextContainer.displayName = "OrgContextContainer";
