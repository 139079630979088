import type { FC, ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import type { MeasurementGroupStatusType } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";

import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

type MeasurementGroupStatusFilterProps = {
    value?: MeasurementGroupStatusType;
    onChange: (value?: MeasurementGroupStatusType) => void;
};

const statusOptions: {
    rawLabel: string;
    label: ReactNode;
    value: MeasurementGroupStatusType;
}[] = [
    {
        rawLabel: MeasurementGroupStatus.Draft,
        label: <GroupStatus status={MeasurementGroupStatus.Draft} />,
        value: MeasurementGroupStatus.Draft,
    },
    {
        rawLabel: MeasurementGroupStatus.Submitted,
        label: <GroupStatus status={MeasurementGroupStatus.Submitted} />,
        value: MeasurementGroupStatus.Submitted,
    },
    {
        rawLabel: MeasurementGroupStatus.Accepted,
        label: <GroupStatus status={MeasurementGroupStatus.Accepted} />,
        value: MeasurementGroupStatus.Accepted,
    },
    {
        rawLabel: MeasurementGroupStatus.Scheduled,
        label: <GroupStatus status={MeasurementGroupStatus.Scheduled} />,
        value: MeasurementGroupStatus.Scheduled,
    },
    {
        rawLabel: MeasurementGroupStatus.Completed,
        label: <GroupStatus status={MeasurementGroupStatus.Completed} />,
        value: MeasurementGroupStatus.Completed,
    },
    {
        rawLabel: MeasurementGroupStatus.Declined,
        label: (
            <>
                <GroupStatus status={MeasurementGroupStatus.Declined} />
            </>
        ),
        value: MeasurementGroupStatus.Declined,
    },
];

export const MeasurementGroupStatusFilter: FC<
    MeasurementGroupStatusFilterProps
> = ({ value, onChange }) => {
    return (
        <Box>
            <SingleSelect<{
                rawLabel: string;
                label: ReactNode;
                value: MeasurementGroupStatusType | null;
            }>
                isClearable
                placeholder="Filter by status"
                value={statusOptions.find((o) => o.value === value)}
                onChange={(newValue) => onChange(newValue?.value ?? undefined)}
                options={statusOptions}
            />
        </Box>
    );
};
