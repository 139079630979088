import React from "react";

import type {
    AlertProps,
    ToastId,
    ToastPosition,
    UseToastOptions,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { runIfFn } from "@chakra-ui/utils";

import { Snackbar } from "@mt-hooks/Snackbar";
import { AlertSpinner } from "@mt-hooks/Snackbar/AlertSpinner.tsx";

export interface SnackbarProps
    extends Omit<AlertProps, "id" | "title" | "position"> {
    id?: ToastId;
    title?: React.ReactNode;
    icon?: React.ReactNode;
    action?: React.ReactNode;
    description?: React.ReactNode;
    position?: ToastPosition;
    isClosable?: boolean;
    onClose?: () => void;
}

export interface UseSnackbarOptions extends UseToastOptions {
    icon?: React.ReactNode;
    action?: React.ReactNode;
    variant?: // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    "snackbar" | "subtle" | "solid" | "left-accent" | "top-accent" | string;
}

export type SnackbarOptions = UseSnackbarOptions | string;

export interface SnackbarPromiseOptions {
    loading?: SnackbarOptions;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    success: SnackbarOptions | ((data: any) => SnackbarOptions);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: SnackbarOptions | ((error: any) => SnackbarOptions);
}

/**
 * The snackbar component is used to give feedback after certain actions.
 *
 * @see Docs https://saas-ui.dev/docs/components/feedback/snackbar
 */
export function useSnackbarCore(defaultOptions?: UseSnackbarOptions) {
    const toast = useToast(defaultOptions);

    const parseOptions = React.useCallback(
        (options: SnackbarOptions): UseSnackbarOptions => {
            if (typeof options === "string") {
                return {
                    title: options,
                };
            }
            return options;
        },
        [],
    );

    return React.useMemo(() => {
        const snackbar = (options: SnackbarOptions): ToastId => {
            const opts = parseOptions(options);
            return toast({
                render: (props) => (
                    <Snackbar {...defaultOptions} {...opts} {...props} />
                ),
                ...opts,
            });
        };

        snackbar.info = (options: SnackbarOptions) =>
            snackbar({
                status: "info",
                ...parseOptions(options),
            });

        snackbar.success = (options: SnackbarOptions) =>
            snackbar({
                status: "success",
                ...parseOptions(options),
            });

        snackbar.error = (options: SnackbarOptions) =>
            snackbar({
                status: "error",
                ...parseOptions(options),
            });

        /**
         * A utility function to show a loading spinner while a promise resolves.
         * `success` and `error` accept an optional function that receives the result or error of the promise.
         *
         * if `error` is a function, it will not throw the error, and you can handle it in the callback function.
         */
        snackbar.promise = async (
            promise: Promise<unknown>,
            { loading, success, error }: SnackbarPromiseOptions,
        ) => {
            let toastId: ToastId | undefined;
            if (loading) {
                const options = parseOptions(loading);
                toastId = snackbar({
                    status: "info",
                    duration: null,
                    icon: <AlertSpinner />,
                    ...options,
                });
            }
            return await promise
                .then((result) => {
                    const options: UseSnackbarOptions = {
                        status: "success",
                        duration: 5000,
                        ...parseOptions(runIfFn(success, result)),
                    };
                    if (toastId) {
                        snackbar.update(toastId, options);
                    } else {
                        snackbar(options);
                    }
                    return result;
                })
                .catch((e) => {
                    const options: UseSnackbarOptions = {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                        title: e.name,
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
                        description: e.description,
                        status: "error",
                        duration: 5000,
                        ...parseOptions(runIfFn(error, e)),
                    };

                    if (toastId) {
                        snackbar.update(toastId, options);
                    } else {
                        snackbar(options);
                    }

                    if (typeof error !== "function") {
                        throw e;
                    }
                });
        };

        snackbar.update = (toastId: ToastId, options: UseToastOptions) => {
            return toast.update(toastId, {
                render: (props) => (
                    <Snackbar {...defaultOptions} {...options} {...props} />
                ),
                ...options,
            });
        };
        snackbar.isActive = toast.isActive;
        snackbar.close = toast.close;
        snackbar.closeAll = toast.closeAll;

        return snackbar;
    }, [toast, defaultOptions, parseOptions]);
}
