import type { FC } from "react";

import { Box, Tag, Text, Tooltip } from "@chakra-ui/react";

import type { PackageModel } from "@app/domain/api/package.ts";
import {
    carrierCodeToLabel,
    getColorSchemeForShippingStatus,
    shippingStatusToLabel,
} from "@app/domain/api/package.ts";

import { onEnter } from "@mt-tools/interaction.ts";

import { Icon } from "@mt-design/icons.tsx";

type PackagesProps = {
    pakets: PackageModel[];
    onClick: (p: PackageModel) => void;
};

export const Packages: FC<PackagesProps> = ({ pakets, onClick }) => {
    return pakets.map((p) => {
        return (
            <Box
                key={p.id}
                justifyContent="center"
                alignItems="center"
                display="flex"
                maxWidth="96px"
                gap="8px"
                p="4px"
            >
                <Tooltip label={shippingStatusToLabel(p.status)}>
                    <Tag
                        whiteSpace="nowrap"
                        overflow="hidden"
                        onKeyUp={onEnter(() => onClick(p))}
                        cursor="pointer"
                        tabIndex={0}
                        onClick={() => onClick(p)}
                        variant="solid"
                        bg={getColorSchemeForShippingStatus(p)}
                        color="white"
                    >
                        <Icon.Package />
                        <Text ml="8px">{carrierCodeToLabel(p.carrier)}</Text>
                    </Tag>
                </Tooltip>
            </Box>
        );
    });
};
