import { type FC, useEffect, useState } from "react";

import {
    Box,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import { Measurement } from "@app/domain/api/measurement";
import { Request } from "@app/domain/api/request.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import type { MeasurementGroupModel } from "@app/domain/index.ts";
import { DownloadModal } from "@app/pages/user/requestDetails/Download.modal.tsx";
import { routes } from "@app/Routes/routes.ts";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { TaskContainer } from "@app-components/measurementTask/Task.container.tsx";
import { CrystalliteSizePrediction } from "@app-components/requestResult/CrystalliteSizePrediction";
import { DataSidebar } from "@app-components/requestResult/DataSidebar.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { StaticField } from "@mt-components/Input/StaticField.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

const getInitialTab = (
    xrdTaskId?: string,
    pdfTaskId?: string,
    saxsTaskId?: string,
) => {
    if (xrdTaskId) return 0;
    if (pdfTaskId) return 1;
    if (saxsTaskId) return 2;

    return 0;
};

type Props = {
    orgId: string;
    measurementGroup: MeasurementGroupModel;
    sampleTasks: SampleTasksModel[];
    selectedSample: SampleTasksModel;
    onSelectSample: (sampleId: string) => void;
};

const ActionID = {
    downloadData: "DOWNLOAD_DATA",
    downloadAux: "DOWNLOAD_AUX",
    downloadReport: "DOWNLOAD_REPORT",
};

export const RequestResultInnerContainer: FC<Props> = ({
    orgId,
    measurementGroup,
    sampleTasks,
    selectedSample,
    onSelectSample,
}) => {
    const [ref] = useScrollbar<HTMLDivElement>();
    const downloadDisclosure = useDisclosure();

    const { mutateAsync: downloadAuxFiles } = Request.useDownloadAuxFiles();
    const { mutateAsync: downloadReport } = Request.useDownloadReport();

    const xrdTaskId = selectedSample.tasks.hrxrpd?.taskId;
    const pdfTaskId = selectedSample.tasks.tspdf?.taskId;
    const saxsTaskId = selectedSample.tasks.saxs?.taskId;

    const [activeTab, setActiveTab] = useState(
        getInitialTab(xrdTaskId, pdfTaskId, saxsTaskId),
    );

    useEffect(() => {
        setActiveTab(getInitialTab(xrdTaskId, pdfTaskId, saxsTaskId));
    }, [xrdTaskId, pdfTaskId, saxsTaskId]);

    const xrdMeasurementQuery = Measurement.useGet({
        organizationId: orgId,
        taskId: xrdTaskId,
    });

    const crumbs: Crumb[] = [
        {
            id: "req",
            label: (
                <Link
                    to={routes.agent.measurementGroups.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                    }}
                >
                    <Text textStyle="hx">Requests</Text>
                </Link>
            ),
        },
        {
            id: "details",
            label: (
                <Link
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    to={routes.agent.measurementGroupDetail.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                        orderId: measurementGroup.id,
                    }}
                >
                    <GroupStatus status={measurementGroup.status} />
                    <Text ml="8px" display="inline">
                        {measurementGroup.name}
                    </Text>
                </Link>
            ),
        },
        {
            id: "results",
            isCurrentPage: true,
            label: "Results",
        },
    ];

    return (
        <>
            <DownloadModal
                measurementGroup={measurementGroup}
                onClose={downloadDisclosure.onClose}
                isOpen={downloadDisclosure.isOpen}
            />
            <Box
                overflow="hidden"
                id="measurement-group-details-main"
                mx="auto"
                display="flex"
                flexDirection="row"
                h="100%"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    flex="1 0 350px"
                    overflow="hidden"
                    pb={0}
                    pr={0}
                >
                    <Box flex="0 0 88px" overflow="hidden" pr="32px">
                        <PageHeader
                            crumbs={crumbs}
                            topActions={[
                                {
                                    id: "download",
                                    label: "Download",
                                    isAction: true,
                                    isPrimary: true,
                                    onClick: () => {},
                                    items: [
                                        {
                                            id: ActionID.downloadData,
                                            isPrimary: true,
                                            label: "Data",
                                            loadingLabel: "Preparing",
                                            onClick: downloadDisclosure.onOpen,
                                        },
                                        {
                                            id: ActionID.downloadAux,
                                            label: "Auxiliary files",
                                            isDisabled:
                                                !measurementGroup.files.length,
                                            onClick: () =>
                                                downloadAuxFiles({
                                                    measurementGroupId:
                                                        measurementGroup.id,
                                                    organizationId:
                                                        measurementGroup.organizationId,
                                                }),
                                        },
                                        {
                                            id: ActionID.downloadReport,
                                            isPrimary: false,
                                            isDisabled:
                                                !measurementGroup.hasReport,
                                            label: "Report",
                                            onClick: () =>
                                                downloadReport(
                                                    measurementGroup,
                                                ),
                                        },
                                    ],
                                },
                            ]}
                        />
                    </Box>

                    <HStack gap="12px">
                        <StaticField
                            title="Sample-ID"
                            value={selectedSample.name || ""}
                        />

                        <StaticField
                            title="Substance"
                            value={selectedSample.substance || ""}
                        />

                        <StaticField
                            title="Composition"
                            value={selectedSample.composition || ""}
                        />

                        <StaticField
                            title="Form"
                            value={selectedSample.composition || ""}
                        />
                    </HStack>

                    {xrdMeasurementQuery.data?.id ? (
                        <CrystalliteSizePrediction
                            organizationId={selectedSample.organizationId}
                            xrdMeasurementId={xrdMeasurementQuery.data.id}
                            isLoading={xrdMeasurementQuery.isLoading}
                        />
                    ) : null}
                    <Divider pr="32px" />

                    <Box
                        ref={ref}
                        alignSelf="stretch"
                        height="calc(100% - 84px)"
                    >
                        <Tabs
                            pt="4px"
                            px="4px"
                            isFitted
                            h="100%"
                            fontSize="md"
                            index={activeTab}
                            onChange={setActiveTab}
                        >
                            <TabList>
                                <Tab isDisabled={!xrdTaskId}>XRD </Tab>
                                <Tab isDisabled={!pdfTaskId}>TS-PDF</Tab>
                                <Tab isDisabled={!saxsTaskId}>SAXS</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {xrdTaskId && (
                                        <TaskContainer
                                            taskId={xrdTaskId}
                                            orgId={orgId}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    {pdfTaskId && (
                                        <TaskContainer
                                            taskId={pdfTaskId}
                                            orgId={orgId}
                                        />
                                    )}
                                </TabPanel>
                                <TabPanel>
                                    {saxsTaskId && (
                                        <TaskContainer
                                            taskId={saxsTaskId}
                                            orgId={orgId}
                                        />
                                    )}
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </PageLayout>
                <DataSidebar
                    orgId={orgId}
                    measurementGroupId={measurementGroup.id}
                    samples={sampleTasks}
                    selectedSampleId={selectedSample.id}
                    onSelectSample={onSelectSample}
                />
            </Box>
        </>
    );
};

RequestResultInnerContainer.displayName = "MeasurementGroupDetailData";
