import type { FC, MouseEventHandler, ReactNode } from "react";

import { Button, IconButton, Tooltip } from "@chakra-ui/react";

export const ChartButton: FC<{
    isDisabled?: boolean;
    type: "icon" | "complex";
    title: ReactNode;
    label: string;
    onClick: MouseEventHandler;
}> = ({ isDisabled, type, label, title, onClick }) => {
    if (type === "icon") {
        return (
            <Tooltip label={label} variant="dark">
                <IconButton
                    my={2}
                    isDisabled={isDisabled}
                    colorScheme="gray"
                    aria-label={label}
                    icon={<>{title}</>}
                    mx="2"
                    onClick={onClick}
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip label={label}>
            <Button
                my={2}
                isDisabled={isDisabled}
                mx="2"
                onClick={onClick}
                colorScheme="gray"
            >
                {title}
            </Button>
        </Tooltip>
    );
};
