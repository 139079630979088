import { useEffect } from "react";
import useHotjar from "react-use-hotjar";

import { useRemoteConfigContext } from "@app/contexts/ConfigContext/ConfigContext.tsx";
import { Role } from "@app/domain/auth.ts";

import * as Sentry from "@sentry/react";

export const useUserHotjar = (
    userId: string | undefined,
    role: Role | undefined,
) => {
    const config = useRemoteConfigContext();
    const { identifyHotjar } = useHotjar();

    useEffect(() => {
        if (!userId || !role) return;

        if (config.environment === "prod") {
            identifyHotjar(userId, { isAgent: role === Role.AGENT });
        }
    }, [config, userId, role, identifyHotjar]);
};

export const useUserSentry = (userId: string | undefined) => {
    useEffect(() => {
        if (!userId) return;

        Sentry.setUser({ id: userId });
    }, [userId]);
};
