import type { FC } from "react";

import { Tag } from "@chakra-ui/react";

export const BeamtimeStatus = {
    DRAFT: "draft",
    ACTIVE: "active",
    COMPLETED: "completed",
    CANCELED: "canceled",
    PUBLISHED: "published",
} as const;

type Props = {
    status: string; // "created" | ... @todo let's consider using a union of string literals
};

const getColorScheme = (status: string) => {
    switch (status) {
        case BeamtimeStatus.DRAFT:
            return "gray";
        case BeamtimeStatus.PUBLISHED:
            return "blue";
        case BeamtimeStatus.ACTIVE:
            return "yellow";
        case BeamtimeStatus.COMPLETED:
            return "green";
        case BeamtimeStatus.CANCELED:
            return "red";
        default:
            return "gray";
    }
};

export const BeamtimeStatusC: FC<Props> = ({ status }) => {
    return (
        <Tag variant="solid" colorScheme={getColorScheme(status)}>
            {status}
        </Tag>
    );
};
