// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC } from "react";

import { connectToContainer } from "lib";
import PropTypes from "prop-types";

import Field from "./Field";

import RadioBlocks from "../widgets/RadioBlocks";

type RadioProps = {
    options: [];
    center?: boolean;
    fullValue: unknown;
    updatePlot: any;
};

export const UnconnectedRadio: FC<RadioProps> = (props) => (
    <Field {...props}>
        <RadioBlocks
            options={props.options}
            activeOption={props.fullValue}
            onOptionChange={props.updatePlot}
        />
    </Field>
);

UnconnectedRadio.displayName = "UnconnectedRadio";

UnconnectedRadio.propTypes = {
    center: PropTypes.bool,
    fullValue: PropTypes.any,
    options: PropTypes.array.isRequired,
    updatePlot: PropTypes.func,
    ...Field.propTypes,
};

// for better appearance <Radio> overrides <Field> {center: false}
// default prop. This can be overridden manually using props for <Radio>.
UnconnectedRadio.defaultProps = {
    center: true,
};

UnconnectedRadio.displayName = "UnconnectedRadio";

export default connectToContainer(UnconnectedRadio);
