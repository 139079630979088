import { anatomy } from "./create-anatomy";

export const appShellAnatomy = anatomy("app-shell").parts(
    "container",
    "inner",
    "main",
);

export const navGroupAnatomy = anatomy("nav-group").parts(
    "container",
    "title",
    "icon",
    "content",
);

export const navItemAnatomy = anatomy("nav-item").parts(
    "item",
    "link",
    "inner",
    "icon",
    "label",
);

export const sidebarAnatomy = anatomy("sidebar").parts(
    "container",
    "overlay",
    "section",
    "toggleWrapper",
    "toggle",
);

export const propertyAnatomy = anatomy("property").parts(
    "property",
    "label",
    "value",
);

export const timelineAnatomy = anatomy("timeline").parts(
    "container",
    "item",
    "separator",
    "icon",
    "dot",
    "track",
    "content",
);
