import type { FC } from "react";

import { Box, Text } from "@chakra-ui/react";

import type { SampleModel } from "@app/domain/api/sample.ts";
import { keyToName } from "@app/domain/services/sample/ioMapping.ts";

import { renderValue } from "@mt-tools/formatter/renderValue.tsx";

type SamplePropertiesProps = {
    sample: SampleModel;
};

const properties: (keyof SampleModel)[] = [
    "name",
    "qrCode",
    "casNumber",
    "substance",
    "composition",
    "form",
];

export const SampleProperties: FC<SamplePropertiesProps> = ({ sample }) => {
    return (
        <>
            {properties.map((p) => {
                return (
                    <Box key={p} mt="16px">
                        <Text color="gray.600" fontSize="12px">
                            {keyToName[p]}
                        </Text>
                        <Text
                            lineHeight="21px"
                            height="21px"
                            color="gray.800"
                            fontSize="14px"
                        >
                            {renderValue(sample[p])}
                        </Text>
                    </Box>
                );
            })}
        </>
    );
};
