import { useContext } from "react";

import { UserContext } from "@app/contexts/UserContext.tsx";

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUserContext must be used within a UserContext");
    }
    return context;
};
