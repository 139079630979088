import { mergeDeepRight } from "ramda";
import { z } from "zod";

import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";

const atomsString =
    "A[cglmrstu]|B[aehikr]?|C[adeflmnorsu]?|D[bsy]|E[rsu]|F[elmr]?|G[ade]|H[efgos]?|I[nr]?|Kr?|L[airuv]|M[cdgnot]|N[abdehiop]?|O[gs]?|P[abdmortu]?|R[abefghnu]|S[bcegimnr]?|T[abcehilms]|U|V|W|Xe|Yb?|Z[nr]";

// matches Fe2CoAl2O4
export const stoichiometryRegex = new RegExp(
    `^((${atomsString})([0-9]+(.[0-9]+)?)?)+$`,
);

export const formOptions = [
    "polycristalline",
    "gel",
    "powder",
    "polymer",
    "metallic",
    "nanoparticles",
    "alloy",
    "glass",
    "composite",
    "other",
] as const satisfies string[];

const maxLengthFactory = (number: number) => {
    return {
        value: number,
        message: `${number} characters maximal`,
    };
};

function minLengthFactory(number: number) {
    return {
        value: number,
        message: `At least ${number} characters`,
    };
}

export const maxLength120 = maxLengthFactory(120);
export const maxLength160 = maxLengthFactory(160);
export const maxLength60 = maxLengthFactory(60);
export const maxLength340 = maxLengthFactory(340);
export const minLength3 = minLengthFactory(3);

export const casRegex = /^[0-9]{2,7}-[0-9]{2}-[0-9]$/;
export const qrCodePattern =
    /(^MOTR_[A-Z]{4}_[0-9]{2}$|^ESRF_[A-Z]{2,4}_[0-9]{2}$)/;

export const stoichiometryValidation = {
    value: stoichiometryRegex,
    message: "Please provide a valid composition",
};

const qrCodeValidation = {
    value: qrCodePattern,
    message: "Please use the format MOTR_XXXX_00 or ESRF_XX[XX]_00",
};

export const zodSchema = z
    .object({
        name: z
            .string()
            .min(minLength3.value, minLength3.message)
            .max(maxLength60.value, maxLength60.message),

        qrCode: z
            .string()
            .regex(qrCodeValidation.value, qrCodeValidation.message)
            .optional(),
        substance: z
            .string()
            .min(minLength3.value, minLength3.message)
            .max(maxLength120.value, maxLength120.message),
        casNumber: z.string().regex(casRegex, "e.g. 7647-14-5").optional(),
        composition: z
            .string()
            .regex(
                stoichiometryValidation.value,
                stoichiometryValidation.message,
            ),
        formDescription: z
            .string()
            .max(maxLength340.value, maxLength340.message)
            .optional(),
        form: z.enum(formOptions),
        toxic: z.boolean(),
        flammable: z.boolean(),
        corrosive: z.boolean(),
        airSensitive: z.boolean(),
        oxidizing: z.boolean(),
        otherHazards: z.boolean(),
        otherHazardsDescription: z.string().optional(),
        //
        sampleHandlingRisk: z.boolean(),
        sampleHandlingRiskDescription: z.string().optional(),
        containsHazardousSubstances: z.boolean(),
        containsHazardousSubstancesDescription: z.string().optional(),
    })
    .superRefine((values, context) => {
        if (
            values.otherHazards &&
            !(
                values.otherHazardsDescription &&
                values.otherHazardsDescription.length >= 3
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Please add more detail about these hazards",
                path: ["otherHazardsDescription"],
            });
        }

        if (
            values.sampleHandlingRisk &&
            !(
                values.sampleHandlingRiskDescription &&
                values.sampleHandlingRiskDescription.length >= 3
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Please add more detail about these hazards",
                path: ["sampleHandlingRiskDescription"],
            });
        }

        if (
            values.containsHazardousSubstances &&
            !(
                values.containsHazardousSubstancesDescription &&
                values.containsHazardousSubstancesDescription.length >= 3
            )
        ) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: "Please add more detail about these hazards",
                path: ["containsHazardousSubstancesDescription"],
            });
        }
    });
export type NewSchema = z.infer<typeof zodSchema>;

export const defaultValues: NewSchema = {
    name: "",
    qrCode: "",
    casNumber: "",
    substance: "",
    composition: "",
    formDescription: "",
    form: "other",
    toxic: false,
    corrosive: false,
    airSensitive: false,
    oxidizing: false,
    flammable: false,
    //
    otherHazards: false,
    otherHazardsDescription: "",
    //
    sampleHandlingRisk: false,
    sampleHandlingRiskDescription: "",
    containsHazardousSubstances: false,
    containsHazardousSubstancesDescription: "",
};

export const mapDBSampleToForm = (
    data: Omit<SampleModel, "id">,
): Omit<NewSchema, "id"> => {
    return mergeDeepRight(defaultValues, {
        name: data.name,
        form: data.form as (typeof formOptions)[0],
        casNumber: data.casNumber,
        qrCode: data.qrCode,
        substance: data.substance,
        composition: data.composition,
        formDescription: data.formDescription,
        airSensitive: data.airSensitive,
        toxic: data.toxic,
        flammable: data.flammable,
        corrosive: data.corrosive,
        oxidizing: data.oxidizing,
        //
        otherHazards: data.otherHazards,
        otherHazardsDescription: data.otherHazardsDescription,
        //
        sampleHandlingRisk: data.sampleHandlingRisk,
        sampleHandlingRiskDescription: data.sampleHandlingRiskDescription,
        //
        containsHazardousSubstances: data.containsHazardousSubstances,
        containsHazardousSubstancesDescription:
            data.containsHazardousSubstancesDescription,
        //
    });
};

export const mapFormToSampleType = (
    data: Omit<NewSchema, "id">,
): CreateSamplePayload => ({
    name: data.name,
    qrCode: data.qrCode,
    substance: data.substance,
    casNumber: data.casNumber?.length ? data.casNumber : undefined,
    composition: data.composition,
    form: data.form,
    formDescription: data.formDescription ?? "",
    //
    toxic: data.toxic,
    flammable: data.flammable,
    corrosive: data.corrosive,
    oxidizing: data.oxidizing,
    airSensitive: data.airSensitive,
    otherHazards: data.otherHazards,
    otherHazardsDescription: data.otherHazardsDescription ?? "",
    //
    containsHazardousSubstances: data.containsHazardousSubstances,
    containsHazardousSubstancesDescription:
        data.containsHazardousSubstancesDescription ?? "",

    sampleHandlingRisk: data.sampleHandlingRisk,
    sampleHandlingRiskDescription: data.sampleHandlingRiskDescription ?? "",
});
