import type { FC } from "react";
import { useMemo } from "react";

import { Box, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type {
    MeasurementGroupModel,
    MeasurementGroupStatusType,
} from "@app/domain";
import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { routes } from "@app/Routes/routes.ts";
import { MeasurementsGroupEmptyStateAgent } from "@app-components/measurementGroup/MeasurementGroupEmptyState.agent.tsx";
import { MeasurementGroupsTable } from "@app-components/measurementGroup/MeasurementGroups.table.tsx";
import { RequestModalAgent } from "@app-components/modals/measurement/MeasurementGroup.agent.modal.tsx";

import type { Action } from "@mt-components/Layout/Actions.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { byId } from "@mt-tools/iterating/filter.ts";

type Props = {
    measurementGroups: MeasurementGroupModel[];
    organizations: OrganizationModel[];
    beamtimes: BeamtimeModel[];
    onSelectStatus: (status?: MeasurementGroupStatusType) => void;
    selectedStatus?: MeasurementGroupStatusType;
};

export const Requests: FC<Props> = ({
    measurementGroups,
    organizations,
    beamtimes,
    selectedStatus,
    onSelectStatus,
}) => {
    const requestDisclosure = useDisclosure();

    const navigate = useNavigate();
    const organizationsById = useMemo(
        () => byId(organizations),
        [organizations],
    );

    const beamtimesById = useMemo(() => byId(beamtimes), [beamtimes]);

    const goToRequest = (group: MeasurementGroupModel) => {
        void navigate({
            to: routes.agent.measurementGroupDetail.url,
            params: {
                orderId: group.id,
                orgId: group.organizationId,
            },
        });
    };

    const actions: Action[] = [
        {
            id: "create_request",
            isPrimary: true,
            onClick: requestDisclosure.onToggle,
            label: "Create",
        },
    ];
    return (
        <>
            {requestDisclosure.isOpen && (
                <RequestModalAgent
                    isOpen={requestDisclosure.isOpen}
                    onClose={requestDisclosure.onClose}
                />
            )}
            {measurementGroups.length === 0 ? (
                <>
                    <Box
                        w="100%"
                        h="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <MeasurementsGroupEmptyStateAgent />
                    </Box>
                </>
            ) : (
                <PageLayout display="flex" flexDir="column" overflow="hidden">
                    <PageHeader
                        crumbs={[
                            {
                                id: "req",
                                label: "Requests",
                            },
                        ]}
                        actions={actions}
                    />
                    <MeasurementGroupsTable
                        onRowClick={goToRequest}
                        getNavParams={(data: MeasurementGroupModel) => ({
                            to: routes.agent.measurementGroupDetail.url,
                            from: routes.agent.measurementGroups.url,
                            params: {
                                orgId: data.organizationId,
                                orderId: data.id,
                            },
                        })}
                        organizationsById={organizationsById}
                        measurementGroups={measurementGroups}
                        beamtimesById={beamtimesById}
                        selectedStatus={selectedStatus}
                        onSetStatusFilter={onSelectStatus}
                    />
                </PageLayout>
            )}
        </>
    );
};

Requests.displayName = "Requests";
