import type { SampleModel } from "@app/domain/api/sample.ts";

export const keyToName: { [k in NonNullable<keyof SampleModel>]: string } = {
    name: "Sample-ID",
    id: "ID",
    organizationId: "Organization ID",
    containsHazardousSubstancesDescription:
        "Contains Hazardous Substances Description",
    sampleHandlingRiskDescription: "Sample Handling Risk Description",
    otherHazardsDescription: "Other Hazards Description",
    formDescription: "Form Description",
    createdAt: "Created At",
    updatedAt: "Updated At",
    createdBy: "Created By",
    qrCode: "QR Code",
    substance: "Substance",
    casNumber: "CAS Number",
    composition: "Composition",
    form: "Form",

    flammable: "Flammable",
    corrosive: "Corrosive",
    oxidizing: "Oxidizing",
    toxic: "Toxic",
    airSensitive: "Air Sensitive",
    otherHazards: "Other Hazards",
    sampleHandlingRisk: "Sample Handling Risk",
    containsHazardousSubstances: "Contains Hazardous Substances",
} as const;

export const agentSampleTableSpec = [
    {
        key: "id",
        label: keyToName.id,
        format: (s) => s.id,
    },
    {
        key: "name",
        label: keyToName.name,
        format: (s) => (s.name || "").toString(),
    },
    {
        key: "qrCode",
        label: keyToName.qrCode,
        format: (s) => (s.qrCode || "").toString(),
    },
    {
        key: "substance",
        label: keyToName.substance,
        format: (s) => (s.substance || "").toString(),
    },
    {
        key: "casNumber",
        label: keyToName.casNumber,
        format: (s) => (s.casNumber || "").toString(),
    },
    {
        key: "composition",
        label: keyToName.composition,
        format: (s) => (s.composition || "").toString(),
    },
    {
        key: "form",
        label: keyToName.form,
        format: (s) => s.form.toString(),
    },
    {
        key: "formDescription",
        label: keyToName.formDescription,
        format: (s) => (s.formDescription || "").toString(),
    },
    {
        key: "toxic",
        label: keyToName.toxic,
        format: (s) => (s.toxic || false).toString(),
    },
    {
        key: "flammable",
        label: keyToName.flammable,
        format: (s) => {
            return (s.flammable || false).toString();
        },
    },
    {
        key: "corrosive",
        label: keyToName.corrosive,
        format: (s) => (s.corrosive || false).toString(),
    },
    {
        key: "oxidizing",
        label: keyToName.oxidizing,
        format: (s) => (s.oxidizing || false).toString(),
    },
    {
        key: "airSensitive",
        label: keyToName.airSensitive,
        format: (s) => (s.airSensitive || false).toString(),
    },
    {
        key: "otherHazards",
        label: keyToName.otherHazards,
        format: (s) => (s.otherHazards || false).toString(),
    },
    {
        key: "sampleHandlingRisk",
        label: keyToName.sampleHandlingRisk,
        format: (s) => (s.sampleHandlingRisk || false).toString(),
    },
    {
        key: "sampleHandlingRiskDescription",
        label: "Details",
        format: (s) => (s.sampleHandlingRiskDescription || "").toString(),
    },
    {
        key: "containsHazardousSubstances",
        label: keyToName.containsHazardousSubstances,
        format: (s) => (s.containsHazardousSubstances || false).toString(),
    },
    {
        key: "containsHazardousSubstancesDescription",
        label: keyToName.containsHazardousSubstancesDescription,
        format: (s) =>
            (s.containsHazardousSubstancesDescription || "").toString(),
    },
] satisfies {
    key: keyof SampleModel;
    label: string;
    format: (v: SampleModel) => string;
}[];

export const userSampleExportSpec = [
    {
        key: "name",
        label: keyToName.name,
        format: (s) => (s.name || "").toString(),
    },
    // {
    //     key: "qrCode",
    //     label: keyToName["qrCode"],
    //     format: (s) => (s.qrCode || "").toString(),
    // },
    {
        key: "substance",
        label: keyToName.substance,
        format: (s) => (s.substance || "").toString(),
    },
    {
        key: "casNumber",
        label: keyToName.casNumber,
        format: (s) => (s.casNumber || "").toString(),
    },
    {
        key: "composition",
        label: keyToName.composition,
        format: (s) => (s.composition || "").toString(),
    },
    {
        key: "form",
        label: keyToName.form,
        format: (s) => s.form.toString(),
    },
    {
        key: "formDescription",
        label: keyToName.formDescription,
        format: (s) => (s.formDescription || "").toString(),
    },
    {
        key: "toxic",
        label: keyToName.toxic,
        format: (s) => (s.toxic || "").toString(),
    },
    {
        key: "flammable",
        label: keyToName.flammable,
        format: (s) => (s.flammable || false).toString(),
    },
    {
        key: "corrosive",
        label: keyToName.corrosive,
        format: (s) => (s.corrosive || false).toString(),
    },
    {
        key: "oxidizing",
        label: keyToName.oxidizing,
        format: (s) => (s.oxidizing || false).toString(),
    },
    {
        key: "airSensitive",
        label: keyToName.airSensitive,
        format: (s) => (s.airSensitive || false).toString(),
    },
    {
        key: "otherHazards",
        label: keyToName.otherHazards,
        format: (s) => (s.otherHazards || false).toString(),
    },
    {
        key: "otherHazardsDescription",
        label: keyToName.otherHazards,
        format: (s) => (s.otherHazardsDescription || "").toString(),
    },
    {
        key: "sampleHandlingRisk",
        label: keyToName.sampleHandlingRisk,
        format: (s) => (s.sampleHandlingRisk || false).toString(),
    },
    {
        key: "sampleHandlingRiskDescription",
        label: "Details",
        format: (s) => (s.sampleHandlingRiskDescription || "").toString(),
    },
    {
        key: "containsHazardousSubstances",
        label: keyToName.containsHazardousSubstances,
        format: (s) => (s.containsHazardousSubstances || false).toString(),
    },
    {
        key: "containsHazardousSubstancesDescription",
        label: keyToName.containsHazardousSubstancesDescription,
        format: (s) =>
            (s.containsHazardousSubstancesDescription || "").toString(),
    },
] satisfies {
    key: keyof SampleModel;
    label: string;
    format?: (v: SampleModel) => string;
}[];
