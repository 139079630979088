import React, {
    type FC,
    type PropsWithChildren,
    useCallback,
    useMemo,
} from "react";

import { LoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/Context.ts";

import { useDelay } from "@mt-hooks/useDelay.ts";

import { isLocalEnvironment } from "@mt-tools/env.ts";

export const LoadingProvider: FC<
    PropsWithChildren<{ isInitiallyLoading: boolean; initKeys: string[] }>
> = ({ children, initKeys }) => {
    // @todo adjust delay when running e2e tests
    const done = useDelay(isLocalEnvironment() ? 50 : 1000);

    const [loadingKeys, setState] = React.useState<string[]>(initKeys);

    const removeLoading = useCallback((key: string) => {
        setState((state) => state.filter((l) => l !== key));
    }, []);

    const ctx = useMemo(() => {
        return {
            isLoading: loadingKeys.length > 0 || !done,
            loadingKeys,
            removeLoading,
        };
    }, [loadingKeys, removeLoading, done]);

    return (
        <LoadingContext.Provider value={ctx}>
            {children}
        </LoadingContext.Provider>
    );
};

LoadingProvider.displayName = "LoadingProvider";
