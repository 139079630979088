import type { RefObject } from "react";
import { useEffect, useRef, useState } from "react";
import { useIntersection } from "react-use";

export type Options = IntersectionObserverInit;

/**
 * Extended react-use useIntersection to only trigger the callback once.
 * Instead of an intersection it returns a boolean whether it had intersected
 */
export const useIntersectedOnce = (
    ref: RefObject<HTMLElement>,
    options: Options,
): boolean => {
    const mockRef = useRef(null);
    const [hasIntersectedOnce, setHasIntersectedOnce] = useState(false);
    const intersection = useIntersection(
        /**
         * By conditionally passing the ref to the useIntersection hook, we can disable the hook
         * @see https://github.com/streamich/react-use/blob/master/src/useIntersection.ts#L11
         */
        hasIntersectedOnce ? mockRef : ref,
        options,
    );

    useEffect(() => {
        if (!hasIntersectedOnce && intersection?.isIntersecting) {
            setHasIntersectedOnce(true);
        }
    }, [hasIntersectedOnce, intersection?.isIntersecting]);

    return hasIntersectedOnce;
};
