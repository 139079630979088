import type { MeasurementMethod } from "@app/domain/api/measurementTask.ts";

export const methodKeyToName: {
    [k in MeasurementMethod]: { short: string; long: string };
} = {
    hrxrpd: {
        short: "HR-XRPD",
        long: "High Resolution X-ray Powder Diffraction",
    },
    tspdf: {
        short: "TS-PDF",
        long: "Total Scattering Pair Distribution Function",
    },
    saxs: {
        short: "SAXS",
        long: "Small Angle X-ray Scattering",
    },
} as const;
