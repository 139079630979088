import type { FetchResponse } from "openapi-fetch";

type Operation = {
    requestBody: ApplicationJsonType<unknown>;
};

type ApplicationJsonType<GData> = {
    content: {
        "application/json": GData;
    };
};

export type ExtractRequestBody<
    GResponses extends ApplicationJsonType<unknown>,
> = GResponses extends ApplicationJsonType<infer Data> ? Data : never;

export type RequestBodyForOperation<GOperation extends Operation> =
    ExtractRequestBody<GOperation["requestBody"]>;

export class FetchError extends Error {
    constructor(
        public res: Response,
        message?: string,
    ) {
        super(message);
    }
}

export const defaultHandling = <T, O>(response: FetchResponse<T, O>) => {
    if (response.response.ok) {
        return response.data;
    }

    throw new FetchError(
        response.response,
        JSON.stringify(response.error ?? ""),
    );
};

export type DefaultParams = {
    path: {
        organizationId: string;
    };
};

export const applyOrg = (orgId: string): DefaultParams => ({
    path: {
        organizationId: orgId,
    },
});

export const isFetchError404 = (error: Error | FetchError | null) => {
    return isFetchError(error) && error.res.status === 404;
};

export const isFetchError = (err: Error | null): err is FetchError => {
    return err instanceof FetchError;
};
