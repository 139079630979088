// Supports weights 100-900
// import "@fontsource-variable/open-sans/wdth-italic.css";
// import "@fontsource/ibm-plex-sans";
// import "@fontsource-variable/exo-2";
// import "@fontsource/inter/100.css";
// import "@fontsource/inter/200.css";
// import "@fontsource/inter/300.css";
// import "@fontsource/inter/400.css";
// import "@fontsource/inter/500.css";
// import "@fontsource/inter/600.css";
// import "@fontsource/inter/700.css";
// import "@fontsource/inter/800.css";
// import "@fontsource/inter/900.css";

// const getDefaultFontSize = (defaultFontSize: number): number => {
//     const elements = document.getElementsByTagName("html");
//
//     const element = elements[0] as HTMLElement | undefined;
//
//     if (element) {
//         const style = window.getComputedStyle(element);
//         const fontSizeString = style.fontSize.split("px")[0];
//         try {
//             return parseInt(fontSizeString, 10) || defaultFontSize;
//         } catch (e) {
//             return defaultFontSize;
//         }
//     }
//     return defaultFontSize;
// };

export const DEFAULT_FONT_SIZE = 16;
// export const BODY_FALLBACK_FONT_SIZE = 14;
// export const getUserFontSize = () => getDefaultFontSize(DEFAULT_FONT_SIZE);
// export const getHtmlFontSize = () => getUserFontSize() * (62.5 / 100);

// export const pixelToRem = (pixels: number): string =>
//     `${(pixels / getHtmlFontSize()).toFixed(2)}rem`;

// const fontSizes = {
//     bigHeading: pixelToRem(64),
//     h1: pixelToRem(42.72),
//     h2: pixelToRem(34.18),
//     h3: pixelToRem(27.32),
//     h4: pixelToRem(17.5),
//     h5: pixelToRem(13.5),
//     body: pixelToRem(14),
//     small: pixelToRem(11.2),
//     tiny: pixelToRem(8.69),
// };
//
// const fontWeights = {
//     regular: 400,
//     medium: 500,
//     semiBold: 600,
//     bold: 700,
//     extraBold: 800,
// };

export enum FontFamily {
    PRIMARY = "'Exo 2 Variable', Arial, sans-serif",
    SECONDARY = "'Exo 2 Variable', sans-serif",
}

export const textStyles = {
    h1: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "48px",
        lineHeight: "120%",
    },
    h2: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "40px",
        lineHeight: "120%",
    },
    h3: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "32px",
        lineHeight: "120%",
    },
    h4: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "120%",
    },
    h5: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "160%",
    },
    h5_600: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "160%",
    },
    h6: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "140%",
    },
    h7: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "140%",
    },
    h6_600: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "16px",
        lineHeight: "140%",
    },
    hx: {
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "120%",
    },
    T1: {
        // paragraph
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "140%",
    },
    T2: {
        // paragraph
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "140%",
    },
    T2LH100: {
        // Navigation item
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "100%",
    },
    T2W500H180: {
        // Stepper title
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "180%",
    },
    T2LH120: {
        // Label T2
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "120%",
    },
    T2W500LH100: {
        // Button T2
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "14px",
        lineHeight: "100%",
    },
    T3: {
        // Meta
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "140%",
    },
    T3LH100: {
        // Meta
        fontFamily: FontFamily.PRIMARY,
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "100%",
    },
};
