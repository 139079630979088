import type { FC } from "react";
import { useMemo, useState } from "react";

import { flatten, head, isNotNil } from "ramda";
import type { Promisable, ValueOf } from "type-fest";

import {
    Box,
    Center,
    chakra,
    HStack,
    IconButton,
    Text,
    Tooltip,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type {
    MeasurementGroupModel,
    MeasurementGroupStatusType,
    MeasurementMethodType,
} from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import * as Beamtime from "@app/domain/api/beamtime.ts";
import {
    MeasurementTask,
    type MeasurementTaskModel,
} from "@app/domain/api/measurementTask.ts";
import type { PackageModel } from "@app/domain/api/package.ts";
import { Package } from "@app/domain/api/package.ts";
import { Request } from "@app/domain/api/request.ts";
import { type SampleModel } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { mergeSamplesWithTasks } from "@app/domain/api/transformer.tsx";
import { PackageModal } from "@app/pages/user/packages/Package.modal.tsx";
import { DownloadModal } from "@app/pages/user/requestDetails/Download.modal.tsx";
import { MeasurementTasks } from "@app/pages/user/requestDetails/MeasurementTasks.tsx";
import { Process } from "@app/pages/user/requestDetails/Process.tsx";
import { Results } from "@app/pages/user/requestDetails/Results.tsx";
import { SubmitRequestModal } from "@app/pages/user/requestDetails/SubmitRequest.modal.tsx";
import { routes } from "@app/Routes/routes.ts";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { SampleTaskSidebar } from "@app-components/measurementGroup/SampleTaskSidebar/SampleTaskSidebar.tsx";
import { MeasurementGroupModal } from "@app-components/modals/measurement/MeasurementGroup.modal.tsx";
import { MeasurementTaskModal } from "@app-components/modals/measurement/MeasurementTask.modal.tsx";
import { MeasurementTaskUpdateModal } from "@app-components/modals/measurement/MeasurementTaskUpdate.modal.tsx";
import { Packages } from "@app-components/package/Packages.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs";
import { Properties } from "@mt-components/Display/Properties/Properties.tsx";
import { TableSearchInput } from "@mt-components/Input/TableSearchInput.tsx";
import type { Action } from "@mt-components/Layout/Actions.tsx";
import { Actions } from "@mt-components/Layout/Actions.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useDisclosureWithStorage } from "@mt-hooks/useDisclosureWithStorage.ts";
import { useDebouncedSearch } from "@mt-hooks/useSearch.tsx";
import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { byId } from "@mt-tools/iterating/filter.ts";

import { useRoleContext } from "src/App/contexts/RoleContext";
import { Role } from "src/App/domain/auth";
import { useConfirm } from "src/packages/hooks/useConfirm.tsx";
import { Icon } from "src/packages/solid-design/icons";

type Props = {
    measurementGroup: MeasurementGroupModel;
    measurementTasksOfGroup: MeasurementTaskModel[];
    samples: SampleModel[];
    deleteTasks: (params: {
        measurementTaskIds: string[];
        organizationId: string;
    }) => Promisable<void>;
};

const ActionID = {
    submit: "SUBMIT",
    selectSample: "SELECT",
    inspect: "INSPECT",
    edit: "EDIT",
    delete: "DELETE",
    removeSamples: "REMOVE_SAMPLES",
    download: "DOWNLOAD",
    downloadData: "DOWNLOAD_DATA",
    downloadAux: "DOWNLOAD_AUX",
    downloadReport: "DOWNLOAD_REPORT",
};

const enabledActions: Record<
    MeasurementGroupStatusType,
    ValueOf<typeof ActionID>[]
> = {
    [MeasurementGroupStatus.Draft]: [
        ActionID.submit,
        ActionID.selectSample,
        ActionID.edit,
        ActionID.delete,
        ActionID.removeSamples,
    ],
    [MeasurementGroupStatus.Submitted]: [],
    [MeasurementGroupStatus.Accepted]: [],
    [MeasurementGroupStatus.Scheduled]: [],
    [MeasurementGroupStatus.Completed]: [
        ActionID.inspect,
        ActionID.download,
        ActionID.downloadReport,
        ActionID.downloadAux,
        ActionID.downloadData,
    ],
    [MeasurementGroupStatus.Declined]: [],
};

export const RequestDetail: FC<Props> = ({
    measurementGroup,
    measurementTasksOfGroup,
    samples,
    deleteTasks,
}) => {
    const roleCtx = useRoleContext();
    const navigate = useNavigate();
    const { currentOrg } = useOrgContext();
    const snack = useSnackbar();
    const confirm = useConfirm();

    const createPackageDisclosure = useDisclosure();
    const resultsdisclosure = useDisclosure();
    const sampleModalDisclosure = useDisclosure();
    const submitModalDisclosure = useDisclosure();

    const [packageToView, setPackageToView] = useState<
        PackageModel | undefined
    >();

    const [sampleSelection, setSelectedSample] = useState<string[]>([]);

    const [taskToUpdate, setTaskToUpdate] = useState<
        SampleTasksModel | undefined
    >(undefined);
    const [toEdit, setToEdit] = useState<null | MeasurementGroupModel>(null);

    const sidebarDisclosure = useDisclosureWithStorage(
        LocalStorageKeys.measurementGroupSampleTaskSidebar,
    );

    const { mutateAsync: downloadAuxFiles } = Request.useDownloadAuxFiles();
    const { mutateAsync: downloadReport } = Request.useDownloadReport();

    const downloadDisclosure = useDisclosure();

    const beamtimeQuery = Beamtime.useGet(measurementGroup.beamtimeId);

    const updateRequest = Request.useUpdate();
    const deleteRequest = Request.useDelete();
    const createTask = MeasurementTask.useCreate();
    const packagesQuery = Package.useGetAll({
        params: {
            path: {
                organizationId: measurementGroup.organizationId,
            },
            query: {
                filter: {
                    measurementGroupId: measurementGroup.id,
                },
            },
        },
    });

    const sampleTasks = useMemo(
        () => mergeSamplesWithTasks(samples, measurementTasksOfGroup),
        [samples, measurementTasksOfGroup],
    );
    const sampleTasksById = useMemo(() => byId(sampleTasks), [sampleTasks]);

    const {
        filteredOrders: filteredSampleTasks,
        onClear,
        onChange,
    } = useDebouncedSearch<SampleTasksModel>({
        isUncontrolled: true,
        items: sampleTasks,
        keys: ["name", "qrCode", "composition"],
    });

    const onDelea = async (id: string) => {
        void navigate({
            params: {
                orgId: currentOrg,
            },
            to: routes.user.measurementGroups.url,
        });
        await deleteRequest
            .mutateAsync({
                measurementGroupId: id,
                organizationId: currentOrg,
            })
            .then(() => {
                snack.success({
                    title: "Success",
                    description: "Measurement request deleted",
                });
            });
    };

    const onDelete = (id: string) => {
        confirm({
            title: "Delete Request",
            body: (
                <Center>
                    <Text display="block">
                        Are you sure you want to delete the measurement request?
                    </Text>
                </Center>
            ),
            onConfirm: () => onDelea(id),
            onCancel: () => {},
        });
    };

    const onToggleSidebar = () => {
        if (sidebarDisclosure.isOpen) {
            sidebarDisclosure.onClose();
        } else {
            sidebarDisclosure.onOpen();
        }
    };

    const onCreate = async (data: {
        sampleId: string;
        method: MeasurementMethodType;
    }) => {
        await createTask.mutateAsync({
            params: {
                path: {
                    organizationId: currentOrg,
                },
            },
            payload: {
                ...data,
                measurementGroupId: measurementGroup.id,
            },
        });
    };

    const onEditName = async ({ name }: { name: string }) => {
        await updateRequest
            .mutateAsync({
                organizationId: currentOrg,
                measurementGroupId: measurementGroup.id,
                patchMeasurementGroupPayload: {
                    name,
                },
            })
            .then(() => {
                setToEdit(null);
                snack.success("Measurement request updated");
            });
    };

    const selectedSampleTasks = sampleSelection
        .map((id) => sampleTasksById[id])
        .filter(isNotNil);

    const goToSample = async (sampleId: string) => {
        await navigate({
            to: routes.user.samples.url,
            params: {
                orgId: currentOrg,
            },
            search: {
                samples: [sampleId],
            },
        });
    };

    const filterAllowedActions = (a: Action<ValueOf<typeof ActionID>>) => {
        const allowed = enabledActions[measurementGroup.status];
        return a.id ? allowed.includes(a.id) : false;
    };

    const onSelectSample = (sampleId: string) => {
        setSelectedSample([sampleId]);
    };

    const onOpenData = () => {
        const selectedSampleId = head(sampleSelection);
        const sampleId = head(sampleTasks)?.id;

        if (selectedSampleId) {
            setSelectedSample([selectedSampleId]);
        } else if (sampleId) {
            setSelectedSample([sampleId]);
        }

        resultsdisclosure.onToggle();
    };

    const actions = [
        {
            id: ActionID.selectSample,
            label: "Select sample",
            isTruncated: true,
            hasTooltip: true,
            isPrimary: measurementTasksOfGroup.length === 0,
            isDisabled:
                measurementGroup.status !== MeasurementGroupStatus.Draft,
            onClick: sampleModalDisclosure.onToggle,
        },
        {
            id: ActionID.removeSamples,
            label: "Remove samples",
            isDisabled: !selectedSampleTasks.length,
            onClick: async () => {
                const tasks = flatten(
                    selectedSampleTasks.map((s) =>
                        Object.values(s.tasks).map((t) => t.taskId),
                    ),
                );
                await deleteTasks({
                    measurementTaskIds: tasks,
                    organizationId: measurementGroup.organizationId,
                });
            },
        },
        {
            id: ActionID.submit,
            label: "Submit measurement request",
            isTruncated: true,
            hasTooltip: true,
            isPrimary:
                measurementGroup.status === MeasurementGroupStatus.Draft &&
                measurementTasksOfGroup.length > 0,
            isDisabled:
                measurementGroup.status !== MeasurementGroupStatus.Draft ||
                measurementTasksOfGroup.length === 0,
            onClick: () => submitModalDisclosure.onOpen(),
        },

        {
            id: ActionID.download,
            label: "Download",
            isAction: true,
            isPrimary: false,
            onClick: () => {},
            items: [
                {
                    id: ActionID.downloadData,
                    isPrimary: true,
                    label: "Data",
                    loadingLabel: "Preparing",
                    onClick: downloadDisclosure.onOpen,
                },
                {
                    id: ActionID.downloadAux,
                    label: "Auxiliary files",
                    isDisabled: !measurementGroup.files.length,
                    onClick: () =>
                        downloadAuxFiles({
                            measurementGroupId: measurementGroup.id,
                            organizationId: measurementGroup.organizationId,
                        }),
                },
                {
                    id: ActionID.downloadReport,
                    isPrimary: false,
                    isDisabled: !measurementGroup.hasReport,
                    label: "Report",
                    onClick: () => downloadReport(measurementGroup),
                },
            ],
        },
        {
            id: ActionID.inspect,
            isTruncated: true,
            hasTooltip: true,
            label: resultsdisclosure.isOpen
                ? "Close results"
                : "Inspect results",
            isPrimary:
                measurementGroup.status === MeasurementGroupStatus.Completed,
            onClick: onOpenData,
        },
    ].filter(filterAllowedActions);

    const menuActions = [
        {
            id: ActionID.edit,
            label: "Edit request",
            isDisabled:
                measurementGroup.status !== MeasurementGroupStatus.Draft,
            onClick: () => setToEdit(measurementGroup),
        },
        {
            id: ActionID.delete,
            label: "Delete request",
            isDisabled:
                measurementGroup.status !== MeasurementGroupStatus.Draft,
            onClick: () => onDelete(measurementGroup.id),
        },
    ].filter(filterAllowedActions);

    const breadcrumbs: Crumb[] = [
        {
            id: "req",
            label: (
                <Link
                    to={routes.user.measurementGroups.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                    }}
                >
                    Requests
                </Link>
            ),
        },
        {
            id: "details",
            label: (
                <Link
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    to={routes.user.measurementGroupDetail.url}
                    params={{
                        orgId: measurementGroup.organizationId,
                        orderId: measurementGroup.id,
                    }}
                >
                    <GroupStatus status={measurementGroup.status} />
                    <chakra.span ml="8px" display="inline">
                        {measurementGroup.name}
                    </chakra.span>
                </Link>
            ),
        },
    ];

    return (
        <>
            <PackageModal
                canEdit={["status", "carrier", "trackingLink", "message"]}
                statusOptions={["draft", "dispatched"]}
                isOpen={createPackageDisclosure.isOpen}
                onCancel={createPackageDisclosure.onClose}
                requestId={measurementGroup.id}
                orgId={measurementGroup.organizationId}
            />

            {Boolean(packageToView) && (
                <PackageModal
                    isOpen={Boolean(packageToView)}
                    canDelete
                    canEdit={
                        packageToView?.status === "draft"
                            ? [
                                  "status" as const,
                                  "carrier" as const,
                                  "trackingLink" as const,
                                  "message" as const,
                              ]
                            : [
                                  "carrier" as const,
                                  "trackingLink" as const,
                                  "message" as const,
                              ]
                    }
                    statusOptions={["draft", "dispatched"]}
                    initialData={packageToView}
                    onCancel={() => setPackageToView(undefined)}
                    requestId={measurementGroup.id}
                    orgId={measurementGroup.organizationId}
                />
            )}
            <MeasurementGroupModal
                isOpen={Boolean(toEdit)}
                onSubmit={onEditName}
                initialData={toEdit ?? undefined}
                onCancel={() => setToEdit(null)}
            />
            {submitModalDisclosure.isOpen && (
                <SubmitRequestModal
                    {...submitModalDisclosure}
                    request={measurementGroup}
                />
            )}
            {taskToUpdate && (
                <MeasurementTaskUpdateModal
                    isOpen={Boolean(taskToUpdate)}
                    sampleTask={taskToUpdate}
                    onCancel={() => setTaskToUpdate(undefined)}
                    onSuccess={() => setTaskToUpdate(undefined)}
                    measurementGroupId={measurementGroup.id}
                />
            )}
            <MeasurementTaskModal
                orgId={measurementGroup.organizationId}
                isOpen={sampleModalDisclosure.isOpen}
                onCancel={sampleModalDisclosure.onClose}
                onSubmit={onCreate}
                onClose={sampleModalDisclosure.onClose}
                existingMeasurementTasks={measurementTasksOfGroup}
                samples={samples}
            />

            <DownloadModal
                measurementGroup={measurementGroup}
                onClose={downloadDisclosure.onClose}
                isOpen={downloadDisclosure.isOpen}
            />

            <HStack
                id="measurement-group-details-main"
                mx="auto"
                w="100%"
                h="100%"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    flex="1 0 350px"
                    overflowX="auto"
                    overflowY="auto"
                >
                    <Box overflow="hidden">
                        <PageHeader crumbs={breadcrumbs} />
                    </Box>
                    <Box display="flex" flexDirection="column" flex={1}>
                        <Box mt="24px" mb="24px" mx="0" minW="600px">
                            <Text mb="6px" textStyle="h6">
                                Status
                            </Text>
                            <Process status={measurementGroup.status} />
                        </Box>

                        {measurementGroup.status !==
                        MeasurementGroupStatus.Draft ? (
                            <Box>
                                <Text mb="0" textStyle="h6">
                                    Info
                                </Text>

                                <Properties
                                    items={[
                                        {
                                            id: "quitation",
                                            label: "Quotation",
                                            value: measurementGroup.quotationNumber,
                                        },
                                        {
                                            id: "message",
                                            label: "Message",
                                            value: measurementGroup.message,
                                            valueProps: {
                                                maxHeight: "180px",
                                                overflow: "hidden",
                                                wordBreak: "break-all",
                                            },
                                        },
                                        {
                                            id: "tracking",
                                            label: (
                                                <HStack width="100%" gap="8px">
                                                    <Box>Shipments</Box>
                                                    <Tooltip label="Add tracking information about your shipped samples here">
                                                        <Icon.Info size="14px" />
                                                    </Tooltip>
                                                </HStack>
                                            ),
                                            valueProps: {
                                                pl: "4px",
                                            },
                                            value: (
                                                <HStack
                                                    alignItems="center"
                                                    gap="12px"
                                                >
                                                    <IconButton
                                                        colorScheme="gray"
                                                        size="xs"
                                                        aria-label="add shipping info"
                                                        onClick={
                                                            createPackageDisclosure.onToggle
                                                        }
                                                        icon={
                                                            <Icon.Plus
                                                                color="currentColor"
                                                                size="20px"
                                                            />
                                                        }
                                                    />
                                                    <Packages
                                                        onClick={
                                                            setPackageToView
                                                        }
                                                        pakets={
                                                            packagesQuery.data ||
                                                            []
                                                        }
                                                    />
                                                </HStack>
                                            ),
                                        },
                                        {
                                            id: "beamtime-date",
                                            label: "Beamtime date",
                                            value: beamtimeQuery.data?.data
                                                ?.date
                                                ? formatDate(
                                                      beamtimeQuery.data.data
                                                          .date,
                                                  )
                                                : undefined,
                                        },
                                    ]}
                                />
                            </Box>
                        ) : null}

                        <VStack
                            overflowY="auto"
                            alignItems="stretch"
                            flex={1}
                            h="100%"
                        >
                            <Actions
                                id="group-details-actions"
                                mt="12px"
                                actions={actions}
                                menuActions={menuActions}
                            />
                            {/*<Divider />*/}
                            <VStack
                                className="FOO"
                                flex={1}
                                alignItems="stretch"
                                justifyContent="flex-start"
                            >
                                <Box flexGrow={0}>
                                    <TableSearchInput
                                        onReset={onClear}
                                        onChange={onChange}
                                        placeholder="Search by property"
                                        maxWidth="unset"
                                    />
                                </Box>

                                <Box flex={1}>
                                    {resultsdisclosure.isOpen ? (
                                        <Results
                                            measurementGroup={measurementGroup}
                                            sampleTasks={sampleTasks}
                                            filteredSamplesTasks={
                                                filteredSampleTasks
                                            }
                                            onSelectSample={onSelectSample}
                                            sampleSelection={sampleSelection}
                                        />
                                    ) : (
                                        <MeasurementTasks
                                            measurementTasks={
                                                measurementTasksOfGroup
                                            }
                                            onOpenSampleModal={
                                                sampleModalDisclosure.onOpen
                                            }
                                            onSelectSamples={setSelectedSample}
                                            sampleTasks={filteredSampleTasks}
                                            measurementGroup={measurementGroup}
                                            sampleSelection={sampleSelection}
                                        />
                                    )}
                                </Box>
                            </VStack>
                        </VStack>
                    </Box>
                </PageLayout>
                {sampleTasks.length ? (
                    <SampleTaskSidebar
                        hideHazards
                        canViewSample
                        goToSample={goToSample}
                        canEdit={
                            roleCtx.role === Role.USER &&
                            measurementGroup.status ===
                                MeasurementGroupStatus.Draft
                        }
                        isOpen={Boolean(sidebarDisclosure.isOpen)}
                        sampleTasks={selectedSampleTasks}
                        measurementGroup={measurementGroup}
                        onToggle={onToggleSidebar}
                        onSelectToEdit={setTaskToUpdate}
                    />
                ) : null}
            </HStack>
        </>
    );
};

RequestDetail.displayName = "MeasurementGroupDetail";
