// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { Component } from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import { Box, Text } from "@chakra-ui/react";

type RadioBlocksProps = {
    onOptionChange: (newValue: Option["value"]) => void;
    activeOption?: string;
    options: Option[];
    alignment: string;
};

type Option = {
    label: string;
    value: string | boolean | number;
    icon: React.ComponentType;
    disabled?: boolean;
};

type State = {
    activeOption?: Option["value"];
};

class RadioBlocks extends Component<RadioBlocksProps, State> {
    constructor(props: RadioBlocksProps) {
        super(props);
        this.state = { activeOption: this.props.activeOption };
        this.handleChange = this.handleChange.bind(this);
        this.renderOption = this.renderOption.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // Reset the value to the graph's actual value
        if (nextProps.activeOption !== this.state.activeOption) {
            this.setState({
                activeOption: nextProps.activeOption,
            });
        }
    }

    handleChange(newValue: Option["value"]) {
        this.setState({ activeOption: newValue });
        this.props.onOptionChange(newValue);
    }

    renderOption(option: Option) {
        const { label, value, icon: Icon } = option;
        const defaultActive = this.state.activeOption === value;

        // const optionClass = classnames('radio-block__option', {
        //   'radio-block__option--active': defaultActive,
        // });

        return (
            <Box
                h="24px"
                py="2px"
                width="100%"
                bg={defaultActive ? "gray.500" : "white"}
                display="flex"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                cursor="pointer"
                key={value}
                onClick={() => this.handleChange(value)}
            >
                {Icon ? <Icon className="radio-block__icon" /> : null}
                {label ? (
                    <Text
                        as="span"
                        display="block"
                        // textStyle="T2"
                        color={defaultActive ? "white" : "gray.800"}
                    >
                        {label}
                    </Text>
                ) : null}
            </Box>
        );
    }

    render() {
        const optionList = this.props.options.map(this.renderOption);

        const groupClass = classnames("radio-block", "radio-block__group", {
            "radio-block__group--center": this.props.alignment === "center",
        });

        return (
            <Box
                border="solid 1px"
                borderColor="gray.200"
                overflow="hidden"
                borderRadius="6px"
                className={groupClass}
            >
                {optionList}
            </Box>
        );
    }
}

RadioBlocks.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.bool,
                PropTypes.number,
            ]).isRequired,
            label: PropTypes.string,
            icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
            disabled: PropTypes.bool,
        }),
    ),
    onOptionChange: PropTypes.func.isRequired,
    activeOption: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    radioClassName: PropTypes.string,

    // One of right, left, center
    alignment: PropTypes.string,
};

export default RadioBlocks;
