import type { BaseOption } from "@mt-components/Input/Select/Select.tsx";

type FilterOptionOption<Option> = {
    readonly label: string;
    readonly value: string;
    readonly data: Option;
};

export const dfo = <SelectOption extends BaseOption>(
    option: FilterOptionOption<SelectOption>,
    inputValue: string,
): boolean => {
    return option.data.rawLabel
        .toLowerCase()
        .includes(inputValue.trim().toLowerCase());
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCreatableOption = (candidte: any) => "__isNew__" in candidte;

export const defaultFilterOption = <SelectOption extends BaseOption>(
    option: FilterOptionOption<SelectOption>,
    inputValue: string,
): boolean => {
    return (
        isCreatableOption(option.data) ||
        option.data.rawLabel
            .toLowerCase()
            .includes(inputValue.trim().toLowerCase())
    );
};
export const toValueLabel = (value: { id: string; name: string }) => ({
    value: value.id,
    label: value.name,
    rawLabel: value.name,
});
