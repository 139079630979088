import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import { defaultHandling } from "@mt-tools/fetch.ts";

type Responses = operations["getAdminAuthzRecords"]["responses"];
type GetAdminOrganizationAuthzRecordsBody =
    Responses["200"]["content"]["application/json"];

type GetOrganizationAuthzRecordsBody =
    operations["getOrganizationAuthzRecords"]["responses"]["200"]["content"]["application/json"];

export type OrganizationAuthzRecord =
    components["schemas"]["OrganizationAuthzRecord"];

export const fetchAuthRecord = async (
    api: APIContextValue["client"],
): Promise<
    | undefined
    | null
    | Record<string, undefined>
    | components["schemas"]["GetAdminAuthzRecordsBody"]
> => {
    const response = await api.GET("/admin/authz");
    if (response.response.status === 403) {
        return {};
    }

    if (response.response.ok) {
        return response.data!;
    }

    return null;
};

const useGetAdminRecord =
    (): UseQueryResult<GetAdminOrganizationAuthzRecordsBody> => {
        const api = useAPI();
        return useQuery({
            queryKey: [QueryKeys.admin_authz],
            // only fetch once
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: Infinity,
            queryFn: async () => {
                try {
                    return await fetchAuthRecord(api.client);
                } catch {
                    return {};
                }
            },
        });
    };

const useGetOrganizationRecords = (
    orgId: string,
): UseQueryResult<GetOrganizationAuthzRecordsBody> => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.organization_authz, orgId],
        queryFn: async () => {
            try {
                const response = await api.client.GET(
                    "/organizations/{organizationId}/authz",
                    {
                        params: {
                            path: {
                                organizationId: orgId,
                            },
                        },
                    },
                );

                return defaultHandling(response);
            } catch {
                return {};
            }
        },
    });
};

const useRemoveUserFromOrg = (orgId: string) => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: { userId: string }) => {
            const response = await api.client.DELETE(
                "/organizations/{organizationId}/authz",
                {
                    params: {
                        path: {
                            organizationId: orgId,
                        },
                        query: {
                            userId: data.userId,
                        },
                    },
                },
            );
            await client.invalidateQueries({
                queryKey: [QueryKeys.organization_authz, orgId],
            });
            return response;
        },
    });
};

const useInviteUser = (orgId: string) => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            email: string;
            role: components["schemas"]["OrganizationRole"];
        }) => {
            const response = await api.client.POST(
                "/rpc/organizations/{organizationId}/addUser",
                {
                    params: {
                        path: {
                            organizationId: orgId,
                        },
                    },
                    body: data,
                },
            );
            await client.invalidateQueries({
                queryKey: [QueryKeys.organization_authz, orgId],
            });
            return response;
        },
    });
};

const useChangeUserRole = (orgId: string) => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            userId: string;
            role: components["schemas"]["OrganizationRole"];
        }) => {
            try {
                const response = await api.client.POST(
                    "/rpc/organizations/{organizationId}/setUserRole",
                    {
                        params: {
                            path: {
                                organizationId: orgId,
                            },
                        },
                        body: data,
                    },
                );
                await client.invalidateQueries({
                    queryKey: [QueryKeys.organization_authz, orgId],
                });
                return defaultHandling(response);
            } catch {
                return {};
            }
        },
    });
};

export const Authz = {
    useGetAdminRecord,
    useGetOrganizationRecords,
    useInviteUser,
    useRemoveUserFromOrg,
    useChangeUserRole,
};
