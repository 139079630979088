import { useContext } from "react";

import type {
    OrgContextValue,
    OrgContextValueRaw,
} from "@app/contexts/OrgContext/OrgContext.tsx";
import { OContext } from "@app/contexts/OrgContext/OrgContext.tsx";

export const useOrgContextRaw = (): OrgContextValueRaw => useContext(OContext);

export const useOrgContext = (): OrgContextValue => {
    const value = useContext(OContext);

    if (!value.currentOrg) {
        throw new Error("No organization set");
    }

    return value as OrgContextValue;
};
