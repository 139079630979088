import { Box, Button, Center, Text } from "@chakra-ui/react";

import type { Fn } from "@mt-tools/types.ts";

import { Icon } from "@mt-design/icons.tsx";

type Props = { amount: number; onClick?: Fn };

export const MultiSelectionView = ({ amount, onClick }: Props) => (
    <Center h="100%">
        <Box display="flex" flexDirection="column" alignItems="center">
            <Icon.SamplesImage width="90px" h="90px" />
            <Center mt="16px">
                <Text color="gray.600" textStyle="T2">
                    {amount} samples selected
                </Text>
            </Center>
            {onClick && (
                <Button
                    mt="24px"
                    size="md"
                    colorScheme="blue"
                    onClick={onClick}
                >
                    Create measurements
                </Button>
            )}
        </Box>
    </Center>
);
