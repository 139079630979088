import type { FC } from "react";

import { useNavigate } from "@tanstack/react-router";

import { routes } from "@app/Routes/routes.ts";

import { GenericError } from "@mt-components/states/Error/GenericError.tsx";

type Props = {
    hideButton?: boolean;
    target?: string;
};

export const GenericErrorUser: FC<Props> = ({ target, hideButton }) => {
    const navigate = useNavigate();

    return (
        <GenericError
            onClick={
                !hideButton
                    ? () => {
                          void navigate({
                              to: target ?? routes.user.home.url,
                          });
                      }
                    : undefined
            }
        />
    );
};
