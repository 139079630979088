import { csv2json } from "json-2-csv";
import { fromPairs, groupBy, toPairs } from "ramda";
import { v4 } from "uuid";
import { type SafeParseError, type SafeParseSuccess, z } from "zod";

import type { SampleModel } from "@app/domain/api/sample.ts";
import {
    casRegex,
    formOptions,
    qrCodePattern,
    stoichiometryRegex,
} from "@app/domain/services/sample/utils.ts";

import { endOfLine } from "@mt-tools/io/endOfLine.ts";

export const sampleSchema = z
    .object({
        name: z.coerce.string(),
        qrCode: z
            .union([z.string().regex(qrCodePattern), z.literal("")])
            .optional(),
        substance: z.coerce.string(),
        casNumber: z.union([
            z.string().regex(casRegex).optional(),
            z.literal("").transform(() => undefined),
        ]),
        composition: z.string().regex(stoichiometryRegex),
        form: z.enum(formOptions),
        formDescription: z.coerce.string().default(""),
        airSensitive: z.boolean().default(false),
        corrosive: z.boolean().default(false),
        flammable: z.boolean().default(false),
        oxidizing: z.boolean().default(false),
        toxic: z.boolean().default(false),
        otherHazards: z.boolean().default(false),
        otherHazardsDescription: z.coerce.string().default(""),
        sampleHandlingRisk: z.boolean().default(false),
        sampleHandlingRiskDescription: z.coerce.string().default(""),
        containsHazardousSubstances: z.boolean().default(false),
        containsHazardousSubstancesDescription: z.coerce.string().default(""),
    })
    .strict();

export type ImportResult = {
    separator: string;
    valid?: (SafeParseSuccess<z.infer<typeof sampleSchema>> & {
        raw: object;
        id: string;
    })[];
    invalid?: (SafeParseError<z.infer<typeof sampleSchema>> & {
        raw: object;
        id: string;
    })[];
    raw?: unknown[];
};

export type Comma = "," | ";" | "|" | "\t";

const parseFALSE = (value: unknown) => (value === "FALSE" ? false : value);
const parseTRUE = (value: unknown) => (value === "TRUE" ? true : value);
const parseEMPTYSTRING = (value: unknown) => (value === "" ? false : value);
const parseUNDEFINED = (value: unknown) =>
    typeof value === "undefined" ? false : value;

const compose =
    (funcs: ((...value: unknown[]) => unknown)[]) => (value: unknown) => {
        return funcs.reduce((agg, currentValue) => {
            return currentValue(agg);
        }, value);
    };

const booleanParser = compose([
    parseFALSE,
    parseEMPTYSTRING,
    parseTRUE,
    parseUNDEFINED,
]);

export const detectSeparator = (input: string): Comma => {
    const separators = [",", ";", "|", "\t"];
    const idx = separators
        .map((separator) => input.indexOf(separator))
        .reduce((prev, cur) =>
            prev === -1 || (cur !== -1 && cur < prev) ? cur : prev,
        );
    return (input[idx] || ",") as Comma;
};

export const parseIntoSamplePayloads = (ctx: string) => {
    const eol = endOfLine(ctx);
    const separator = detectSeparator(ctx);

    if (!eol) {
        // @todo add proper error handling
        throw new Error("No EOL found");
    }

    const rawData = csv2json(ctx, {
        delimiter: {
            field: separator,
            eol,
        },
        wrapBooleans: true,
    }) as Record<string, unknown>[];
    if (rawData.length >= 500) {
        return "Please import you samples in batches of 100 or less.";
    }

    const validatedSamples = rawData
        .map((sample: Record<string, unknown>) => {
            const keys: (keyof SampleModel)[] = [
                "toxic",
                "flammable",
                "oxidizing",
                "corrosive",
                "airSensitive",
                "sampleHandlingRisk",
                "otherHazards",
                "containsHazardousSubstances",
            ];

            const newSample = fromPairs(
                toPairs(sample).map((keyValue) => {
                    const key = keyValue[0];
                    if ((keys as string[]).includes(key)) {
                        const value = keyValue[1];
                        const newValue = booleanParser(value);

                        return [key, newValue];
                    }

                    return keyValue;
                }),
            );

            return newSample;
        })
        .map((sample) => {
            return {
                raw: sample,
                id: v4(),
                ...sampleSchema.safeParse(sample),
            };
        });

    const spreadElements = groupBy(
        (s) => (s.success ? "valid" : "invalid"),
        validatedSamples,
    );

    const result: ImportResult = {
        separator,
        valid: spreadElements.valid as ImportResult["valid"],
        invalid: spreadElements.invalid as ImportResult["invalid"],
        raw: rawData as unknown[],
    };

    return result;
};
