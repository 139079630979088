import { createContext, memo, type PropsWithChildren, useEffect } from "react";

import { useLatestRef } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";

import { type publicSchema, selfQueryKey } from "src/api/client";

import { useAPI } from "./APIContext/useApiContext";
import { useLoadingContext } from "./GlobalLoader/LoadingProvider/useLoadingContext";

export type User = {
    id: string;
    name?: string;
    email: string;
    avatarUrl?: string;
};

export type Self = publicSchema.components["schemas"]["Self"];

export const authzLoadingKey = "authz";
export const UserContext = createContext<Self | null>(null);

UserContext.displayName = "UserContext";

function usePublicSelfQuery() {
    const api = useAPI();
    return useQuery({
        queryKey: [...selfQueryKey(), "public"],
        queryFn: () => api.publicClient.GET("/self"),
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });
}

export const UserContextProvider = memo((props: PropsWithChildren) => {
    const ctx = useLoadingContext();
    const ctxRef = useLatestRef(ctx);

    const publicSelfQuery = usePublicSelfQuery();

    useEffect(() => {
        if (!publicSelfQuery.isLoading) {
            ctxRef.current.removeLoading(authzLoadingKey);
        }
    }, [publicSelfQuery.isLoading, ctxRef]);

    const self = publicSelfQuery.data?.data;

    if (publicSelfQuery.isLoading) return <div>Loading self...</div>;

    if (!self) return <div>Couldn&apos;t load self</div>;

    return (
        <UserContext.Provider value={self}>
            {props.children}
        </UserContext.Provider>
    );
});
UserContextProvider.displayName = "UserContextProvider";
