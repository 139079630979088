import React from "react";

import PropTypes from "prop-types";

import {
    ColorPicker,
    Dropdown,
    LayoutPanel,
    Numeric,
    PlotlyFold,
    PlotlySection,
    Text,
    VisibilitySelect,
} from "../components";

const StyleLayoutPanel = (props, { localize: _ }) => (
    <LayoutPanel {...props}>
        <PlotlyFold name={"Defaults"}>
            <ColorPicker label={"Plot Background"} attr="plot_bgcolor" />
            <ColorPicker label={"Margin Color"} attr="paper_bgcolor" />
            {/*<PlotlySection name={"Colorscales"} attr="colorway">*/}
            {/*    <ColorwayPicker*/}
            {/*        label={"Categorical"}*/}
            {/*        attr="colorway"*/}
            {/*        disableCategorySwitch*/}
            {/*        labelWidth={80}*/}
            {/*    />*/}
            {/*    <ColorscalePicker*/}
            {/*        label={"Sequential"}*/}
            {/*        attr="colorscale.sequential"*/}
            {/*        disableCategorySwitch*/}
            {/*        labelWidth={80}*/}
            {/*    />*/}
            {/*    <ColorscalePicker*/}
            {/*        label={"Diverging"}*/}
            {/*        attr="colorscale.diverging"*/}
            {/*        initialCategory="divergent"*/}
            {/*        disableCategorySwitch*/}
            {/*        labelWidth={80}*/}
            {/*    />*/}
            {/*    <ColorscalePicker*/}
            {/*        label={"Negative Sequential"}*/}
            {/*        attr="colorscale.sequentialminus"*/}
            {/*        disableCategorySwitch*/}
            {/*        labelWidth={80}*/}
            {/*    />*/}
            {/*</PlotlySection>*/}
            <PlotlySection name="Text" attr="font.family">
                {/*<FontSelector*/}
                {/*    label={"Typeface"}*/}
                {/*    attr="font.family"*/}
                {/*    clearable={false}*/}
                {/*/>*/}
                <Numeric label={"Base Font Size"} attr="font.size" units="px" />
                <ColorPicker label={"Font Color"} attr="font.color" />
                <Dropdown
                    label={"Number format"}
                    attr="separators"
                    options={[
                        { label: _("1,234.56"), value: ".," },
                        { label: _("1 234.56"), value: ", " },
                        { label: _("1 234,56"), value: ", " },
                        { label: _("1.234,56"), value: ",." },
                    ]}
                    clearable={false}
                />
                {/*<Dropdown*/}
                {/*    label={"Uniform Text Mode"}*/}
                {/*    attr="uniformtext.mode"*/}
                {/*    options={[*/}
                {/*        { label: "Off", value: false },*/}
                {/*        { label: "Show", value: "show" },*/}
                {/*        { label: "Hide", value: "hide" },*/}
                {/*    ]}*/}
                {/*    clearable={false}*/}
                {/*/>*/}
                {/*<Numeric*/}
                {/*    label={"Uniform Text Size Minimum"}*/}
                {/*    attr="uniformtext.minsize"*/}
                {/*    units="px"*/}
                {/*/>*/}
            </PlotlySection>
        </PlotlyFold>

        <PlotlyFold name={"Title"}>
            <Text label="Plot Title" attr="title.text" />
            {/*<FontSelector*/}
            {/*    label="Typeface"*/}
            {/*    attr="title.font.family"*/}
            {/*    clearable={false}*/}
            {/*/>*/}
            <Numeric label={"Font Size"} attr="title.font.size" units="px" />
            <ColorPicker label={"Font Color"} attr="title.font.color" />
            <Numeric
                label={"Horizontal Position"}
                showSlider
                step={0.02}
                attr="title.x"
            />
        </PlotlyFold>

        {/*<PlotlyFold name={"Modebar"}>*/}
        {/*    <Radio*/}
        {/*        label={"Orientation"}*/}
        {/*        attr="modebar.orientation"*/}
        {/*        options={[*/}
        {/*            { label: "Horizontal", value: "h" },*/}
        {/*            { label: "Vertical", value: "v" },*/}
        {/*        ]}*/}
        {/*    />*/}
        {/*    <ColorPicker label={"Icon Color"} attr="modebar.color" />*/}
        {/*    <ColorPicker*/}
        {/*        label={"Active Icon Color"}*/}
        {/*        attr="modebar.activecolor"*/}
        {/*    />*/}
        {/*    <ColorPicker label={"Background Color"} attr="modebar.bgcolor" />*/}
        {/*</PlotlyFold>*/}
        <PlotlyFold name={"Size and Margins"}>
            <VisibilitySelect
                attr="autosize"
                label={"Size"}
                options={[
                    { label: "Auto", value: true },
                    { label: "Custom", value: false },
                ]}
                showOn={false}
                defaultOpt={true}
            >
                <Numeric label={"Fixed Width"} attr="width" units="px" />
                <Numeric label={"Fixed height"} attr="height" units="px" />
            </VisibilitySelect>
            <Numeric label={"Top"} attr="margin.t" units="px" />
            <Numeric label={"Bottom"} attr="margin.b" units="px" />
            <Numeric label={"Left"} attr="margin.l" units="px" />
            <Numeric label={"Right"} attr="margin.r" units="px" />
            <Numeric label={"Padding"} attr="margin.pad" units="px" />
        </PlotlyFold>
        {/*<PlotlyFold name={"Interactions"}>*/}
        {/*    <PlotlySection name={"Drag"} attr="dragmode">*/}
        {/*        <Dropdown*/}
        {/*            label={"Mode"}*/}
        {/*            attr="dragmode"*/}
        {/*            options={[*/}
        {/*                { label: "Zoom", value: "zoom" },*/}
        {/*                { label: "Select", value: "select" },*/}
        {/*                { label: "Pan", value: "pan" },*/}
        {/*                { label: "Lasso", value: "lasso" },*/}
        {/*                { label: "Orbit", value: "orbit" },*/}
        {/*                { label: "Turntable", value: "turntable" },*/}
        {/*            ]}*/}
        {/*            clearable={false}*/}
        {/*        />*/}
        {/*        <Dropdown*/}
        {/*            label={"Select Direction"}*/}
        {/*            attr="selectdirection"*/}
        {/*            options={[*/}
        {/*                { label: "Any", value: "any" },*/}
        {/*                { label: "Horizontal", value: "h" },*/}
        {/*                { label: "Vertical", value: "v" },*/}
        {/*                { label: "Diagonal", value: "d" },*/}
        {/*            ]}*/}
        {/*            clearable={false}*/}
        {/*        />*/}
        {/*    </PlotlySection>*/}
        {/*    <PlotlySection name={"Click"} attr="clickmode">*/}
        {/*        <Flaglist*/}
        {/*            label={"Mode"}*/}
        {/*            attr="clickmode"*/}
        {/*            options={[*/}
        {/*                { label: "Click Event", value: "event" },*/}
        {/*                { label: "Select Data Point", value: "select" },*/}
        {/*            ]}*/}
        {/*        />*/}
        {/*    </PlotlySection>*/}
        {/*    <PlotlySection name={"Hover"}>*/}
        {/*        <HovermodeDropdown label={"Mode"} attr="hovermode">*/}
        {/*            <Dropdown*/}
        {/*                label={"Text Alignment"}*/}
        {/*                attr="hoverlabel.align"*/}
        {/*                options={[*/}
        {/*                    { label: "Auto", value: "auto" },*/}
        {/*                    { label: "Left", value: "left" },*/}
        {/*                    { label: "Right", value: "right" },*/}
        {/*                ]}*/}
        {/*                clearable={false}*/}
        {/*            />*/}
        {/*            <HoverColor*/}
        {/*                label={"Background Color"}*/}
        {/*                attr="hoverlabel.bgcolor"*/}
        {/*                defaultColor="#FFF"*/}
        {/*                handleEmpty*/}
        {/*            />*/}
        {/*            <HoverColor*/}
        {/*                label={"Border Color"}*/}
        {/*                attr="hoverlabel.bordercolor"*/}
        {/*                defaultColor="#000"*/}
        {/*                handleEmpty*/}
        {/*            />*/}
        {/*            <FontSelector*/}
        {/*                label={"Typeface"}*/}
        {/*                attr="hoverlabel.font.family"*/}
        {/*                clearable*/}
        {/*            />*/}
        {/*            <Numeric*/}
        {/*                label={"Font Size"}*/}
        {/*                attr="hoverlabel.font.size"*/}
        {/*            />*/}
        {/*            <HoverColor*/}
        {/*                label={"Font Color"}*/}
        {/*                attr="hoverlabel.font.color"*/}
        {/*                defaultColor="#000"*/}
        {/*                handleEmpty*/}
        {/*            />*/}
        {/*        </HovermodeDropdown>*/}
        {/*    </PlotlySection>*/}
        {/*</PlotlyFold>*/}
        {/*<PlotlyFold name={"Meta Text"}>*/}
        {/*    <DataSelector label={"Custom Data"} attr="meta" />*/}
        {/*    <Info>*/}
        {/*        <p>*/}
        {/*            {_(*/}
        {/*                "You can refer to the items in this column in any text fields of the editor like so: ",*/}
        {/*            )}*/}
        {/*        </p>*/}
        {/*        <p>*/}
        {/*            {_("Ex: ")}*/}
        {/*            <span*/}
        {/*                style={{*/}
        {/*                    letterSpacing: "1px",*/}
        {/*                    fontStyle: "italic",*/}
        {/*                    userSelect: "text",*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                {_("My custom title %{meta[1]}")}*/}
        {/*            </span>*/}
        {/*        </p>*/}
        {/*    </Info>*/}
        {/*</PlotlyFold>*/}
    </LayoutPanel>
);

StyleLayoutPanel.contextTypes = {
    localize: PropTypes.func,
};

export default StyleLayoutPanel;
