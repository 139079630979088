import type { FC } from "react";

import { Icon } from "@mt-design/icons.tsx";

type OnOffProps = {
    isOn?: boolean;
    onToggle?: (isOn: boolean) => void;
};

export const OnOff: FC<OnOffProps> = ({ isOn, onToggle }) => {
    const onClick = () => onToggle?.(!isOn);
    return isOn ? (
        <Icon.Shown onClick={onClick} />
    ) : (
        <Icon.Hidden onClick={onClick} />
    );
};
