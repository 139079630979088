import type { FC } from "react";
import { useState } from "react";

import { Box } from "@chakra-ui/react";

import { Organization } from "@app/domain/api/organization.ts";
import { Request } from "@app/domain/api/request";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { SelectField } from "@mt-components/Input/SelectField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

export type FormShape = {
    name: string;
    orgId: string;
};

type Props = {
    isOpen: boolean;
    initialData?: FormShape;
    onClose: () => void;
};
export const RequestModalAgent: FC<Props> = ({
    isOpen,
    initialData,
    onClose,
}) => {
    const organizationsQuery = Organization.useAdminGetAll();
    const createRequest = Request.useAdminCreate();

    const [selectedOrg, setSelectedOrg] = useState<string | undefined>(
        initialData ? initialData.orgId : undefined,
    );
    const [name, setName] = useState<string | undefined>(initialData?.name);

    const isValid = selectedOrg && name;

    const onSubmit = async () => {
        if (!selectedOrg || !name) {
            return;
        }
        await createRequest.mutateAsync({
            organizationId: selectedOrg,
            data: {
                name,
            },
        });
        onClose();
    };

    return (
        <Modal
            id="create-measurement-request"
            isOpen={isOpen}
            onClose={() => {
                onClose();
                setSelectedOrg(undefined);
                setName(undefined);
            }}
            header="Create request"
            buttonRight={
                <LoadingButton
                    colorScheme="blue"
                    isDisabled={!isValid}
                    onClick={onSubmit}
                >
                    Submit
                </LoadingButton>
            }
        >
            <Box display="flex" flexDirection="column" gap="24px" my="24px">
                <InputField
                    label="Request name"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />

                <SelectField
                    label="Organization"
                    onChange={(event) =>
                        setSelectedOrg(
                            event.target.value === "None"
                                ? undefined
                                : event.target.value,
                        )
                    }
                    value={selectedOrg ?? "None"}
                    options={
                        organizationsQuery.data
                            ?.map((o) => ({
                                value: o.id,
                                label: o.name,
                            }))
                            .concat([
                                {
                                    value: "None",
                                    label: "None",
                                },
                            ]) ?? []
                    }
                />
            </Box>
        </Modal>
    );
};
