import type { FC, PropsWithChildren } from "react";

import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";

import { isLocalEnvironment } from "@mt-tools/env.ts";

export const PreventRenderingWhileLoading: FC<PropsWithChildren> = ({
    children,
}) => {
    const ctx = useLoadingContext();
    if (ctx.isLoading) {
        return isLocalEnvironment() ? (
            "This should not be visible"
        ) : (
            <>{null}</>
        );
    }

    return <>{children}</>;
};

PreventRenderingWhileLoading.displayName = "PreventRenderingWhileLoading";
