import { FontFamily, textStyles } from "@mt-design/fontStyles.ts";

export const mtblue = {
    "50": "#eff6ff",
    "100": "#dbeafe",
    "200": "#bfdbfe",
    "300": "#93c5fd",
    "400": "#60a5fa",
    "500": "#3b82f6",
    "600": "#2563eb",
    "700": "#1d4ed8",
    "800": "#1e40af",
    "900": "#1e3a8a",
    "950": "#172554",
};

export const packageBrown = "#785832";

export const customOverrides = {
    fonts: {
        heading: FontFamily.PRIMARY,
        body: FontFamily.SECONDARY,
    },
    fontSizes: {
        "xs": "0.75rem",
        "sm": "0.8125rem",
        "md": "0.875rem",
        "lg": "1",
        "xl": "1.125rem",
        "2xl": "1.5rem",
        "3xl": "1.875rem",
        "4xl": "2.25rem",
        "5xl": "3rem",
        "6xl": "3.75rem",
        "7xl": "4.5rem",
        "8xl": "6rem",
        "9xl": "8rem",
    },
    fontWeights: {
        hairline: 100,
        thin: 200,
        light: 300,
        normal: 400,
        medium: 500,
        semibold: 600,
        bold: 700,
        extrabold: 800,
        black: 900,
    },
    lineHeights: {
        "normal": "normal",
        "none": 1,
        "shorter": 1.25,
        "short": 1.375,
        "base": 1.5,
        "tall": 1.625,
        "taller": "2",
        "3": ".75rem",
        "4": "1rem",
        "5": "1.25rem",
        "6": "1.5rem",
        "7": "1.75rem",
        "8": "2rem",
        "9": "2.25rem",
        "10": "2.5rem",
    },
    letterSpacings: {
        tighter: "-0.05em",
        tight: "-0.025em",
        normal: "0",
        wide: "0.025em",
        wider: "0.05em",
        widest: "0.1em",
    },
    textStyles,
    colors: {
        primary: mtblue,
        mt: {
            // Generated with https://www.tints.dev/blue/C32E22
            50: "#FAE6E5",
            100: "#F7D2CF",
            200: "#EEA59F",
            300: "#E67870",
            400: "#DE4A40",
            500: "#C32E22",
            600: "#9C241B",
            700: "#751B14",
            800: "#4E120E",
            900: "#270907",
            950: "#110403",
        },
        mt2: {
            // Generated with https://www.tints.dev/blue/C32E22
            50: "#424242",
            100: "#424242",
            200: "#424242",
            300: "#424242",
            400: "#424242",
            500: "#424242",
            600: "#424242",
            700: "#424242",
            800: "#424242",
            900: "#424242",
            950: "#424242",
        },
        secondary: {
            50: "#EEECE8",
            100: "#DCD9D0",
            200: "#B7B09F",
            300: "#948970",
            400: "#635C4A",
            500: "#332F26",
            600: "#29261E",
            700: "#201E18",
            800: "#14130F",
            900: "#0C0B09",
            950: "#060504",
        },
        mtblue: mtblue,
        blue: {
            "50": mtblue["100"],
            "100": mtblue["200"],
            "200": mtblue["300"],
            "300": mtblue["400"],
            "400": mtblue["500"],
            "500": mtblue["600"],
            "600": mtblue["700"],
            "700": mtblue["800"],
            "800": mtblue["900"],
            "900": mtblue["950"],
            "950": mtblue["950"],
        },
    },
};
