import type { FC } from "react";
import { useLocalStorage } from "react-use";

import * as plotly from "plotly.js";

import { Box } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { Artifact } from "@app/domain/api/artifact.ts";
import type { Plot, PlotDB } from "@app/pages/agent/plots/PlotsPage.tsx";

// import "overlayscrollbars/overlayscrollbars.css";
import "@chart-editor/styles/main.scss";
import { PlotlyEditor, traceTypes } from "@chart-editor/index.tsx";

type Dataset = {
    id: string;
    x: number[];
    y: number[];
    e?: number[];
};

const isObject = <GData extends string | object | undefined>(
    cand: GData,

    // @ts-ignore
): cand is object => {
    if (typeof cand === "object") {
        return true;
    }

    return false;
};

const config = { editable: false };

type Value = ReturnType<typeof traceTypes>[number]["value"];
const allowedTraceTypes: Value[] = ["line", "scatter"];
const reducedTracesTypes = () =>
    traceTypes().filter(({ value }) => allowedTraceTypes.includes(value));

const isDataSet = (d: Partial<Dataset>): d is Dataset => Boolean(d.x && d.y);

const routeApi = new RouteApi({
    id: "/agent/organization/$orgId/plots/$plotId",
});

const usePlot = (plotId: string) => {
    const [plotsDB, setPlotsDB] = useLocalStorage<PlotDB>(
        LocalStorageKeys.plots,
        {},
    );

    if (!plotsDB) {
        return null;
    }

    return {
        data: plotsDB[plotId],
        update: (
            data: Plot["plot"]["data"],
            layout: Plot["plot"]["layout"],
        ) => {
            setPlotsDB((prev): PlotDB | undefined => {
                if (!prev) {
                    return prev;
                }

                const prevPlot = prev[plotId];
                if (!prevPlot) {
                    return prev;
                }
                const plot: Plot = {
                    ...prevPlot,
                    id: plotId,
                    artifacts: prevPlot.artifacts,
                    name: isObject(layout?.title)
                        ? (layout.title.text ?? "")
                        : (layout?.title ?? ""),
                    plot: {
                        data,
                        layout,
                    },
                };
                return {
                    ...prev,
                    [plotId]: plot,
                };
            });
        },
    };
};

export const PlotDetailPage: FC = () => {
    const params = routeApi.useParams();

    const foo = usePlot(params.plotId);

    const artis = foo?.data?.artifacts;

    const quer = Artifact.useGetAllData({
        isDisabled: !artis,
        organizationId: params.orgId,
        artifacts: (foo?.data?.artifacts || []).map((id) => ({ id })),
    });

    const data = quer.data;

    if (!foo || !data) {
        return;
    }

    const filteredData = data.filter(isDataSet);
    const datasources: Record<string, number[] | undefined> = {};

    filteredData.forEach((dataset) => {
        datasources[`${dataset.id}.x`] = dataset.x;
        datasources[`${dataset.id}.y`] = dataset.y;
        datasources[`${dataset.id}.e`] = dataset.e || [];
    });

    // const plotData = filteredData.map(
    //     (dataset, idx): Plotly.PlotData => ({
    //         mode: "lines",
    //         xsrc: `${dataset.id}.x`,
    //         ysrc: `${dataset.id}.y`,
    //         line: {
    //             color: colors[idx],
    //         },
    //         x: dataset.x,
    //         y: dataset.y,
    //     }),
    // );

    const dataSourceOptions = Object.keys(datasources).map((name) => ({
        value: name,
        label: name,
    }));

    return (
        <Box height="100%" className="app">
            <PlotlyEditor
                data={foo.data?.plot.data ?? []}
                frames={[]}
                layout={foo.data?.plot.layout || {}}
                config={config}
                dataSources={datasources}
                dataSourceOptions={dataSourceOptions}
                plotly={plotly}
                onUpdate={foo.update}
                useResizeHandler
                debug
                advancedTraceTypeSelector
                traceTypesConfig={{
                    complex: true,
                    traces: reducedTracesTypes,
                }}
            />
        </Box>
    );
};
