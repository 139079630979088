import PlotlySection from "./PlotlySection";
import propTypes from "prop-types";
import { useMemo, FC, PropsWithChildren } from "react";

type Props = {
    name?: string;
};

type Context = {
    fullContainer: any;
};

const setLocals = (traceType: string) => {
    if (["bar", "histogram", "funnel", "waterfall"].includes(traceType)) {
        return "Bars";
    } else if (
        ["funnelarea", "pie", "sunburst", "treemap"].includes(traceType)
    ) {
        return "Segments";
    } else {
        return "Points";
    }
};

export const TraceMarkerSection: FC<PropsWithChildren<Props>> = (
    props,
    context: Context,
) => {
    const traceType = context.fullContainer.type;
    const name = useMemo(() => setLocals(traceType), [traceType]);

    return <PlotlySection name={name}>{props.children}</PlotlySection>;
};

TraceMarkerSection.contextTypes = {
    fullContainer: propTypes.object,
};
