import type { FC } from "react";

import { Tag } from "@chakra-ui/react";

import { getColorScheme } from "@app-components/measurementGroup/GroupStatus/getColorScheme.tsx";

type OrderStatusProps = {
    status: string; // "created" | ... @todo let's consider using a union of string literals
};

export type StatusColorScheme =
    | "gray"
    | "yellow"
    | "cyan"
    | "teal"
    | "green"
    | "red";

export const GroupStatus: FC<OrderStatusProps> = ({ status }) => {
    return (
        <Tag variant="solid" colorScheme={getColorScheme(status)}>
            {status}
        </Tag>
    );
};
