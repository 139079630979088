import type { FC } from "react";

import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export const SamplesEmptyState: FC = () => (
    <EmptyState
        icon={(props) => <Icon.Sample {...props} />}
        heading="No Samples"
        body="There are no samples, yet."
    />
);
