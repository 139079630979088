import type { FC } from "react";

import { Box, IconButton, Tooltip } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import { routes } from "@app/Routes/routes.ts";
import { BeamtimeStatusC } from "@app-components/beamtime/BeamtimeStatus.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    items: BeamtimeModel[];
    onRowClick?: (data: BeamtimeModel) => void;
};

const columns: ColumnDef<BeamtimeModel>[] = [
    {
        accessorKey: "name" satisfies keyof BeamtimeModel,
        header: "Name",
    },
    {
        accessorKey: "status" satisfies keyof BeamtimeModel,
        header: "Status",
        cell: (cell) => {
            const status = cell.row.original.status;
            return <BeamtimeStatusC status={status} />;
        },
    },
    {
        accessorKey: "date" satisfies keyof BeamtimeModel,
        accessorFn: (originalRow) =>
            originalRow.date ? formatDate(originalRow.date) : "",
        header: "Date",
        cell: (cell) => {
            return cell.row.original.date
                ? formatDate(cell.row.original.date)
                : "";
        },
    },
    {
        accessorKey: "createdAt" satisfies keyof BeamtimeModel,
        header: "Created",
        accessorFn: (originalRow) =>
            originalRow.createdAt ? formatDate(originalRow.createdAt) : "",
        cell: (cell) => {
            const value = cell.row.original.createdAt;
            return formatDate(value);
        },
    },
    {
        accessorKey: "updatedAt" satisfies keyof BeamtimeModel,
        header: "Updated",
        accessorFn: (originalRow) =>
            originalRow.updatedAt ? formatDate(originalRow.updatedAt) : "",
        cell: (cell) => {
            const value = cell.row.original.updatedAt;
            return formatDate(value);
        },
    },
    {
        accessorKey: "nav",
        stopBubbling: true,
        header: " ",
        cell: (cell) => {
            return (
                <Link
                    to={routes.agent.beamtimesDetail.url}
                    params={{ beamtimeId: cell.row.original.id }}
                >
                    <Tooltip label="Go to beamtime">
                        <IconButton
                            colorScheme="gray"
                            size="xs"
                            aria-label="View beamtime"
                            icon={<Icon.ChevronRight />}
                        />
                    </Tooltip>
                </Link>
            );
        },
    },
];

const initialState = {
    sorting: [
        {
            id: "updatedAt",
            desc: true,
        },
    ],
};

export const BeamtimeTable: FC<Props> = ({ items, onRowClick }) => {
    const [ref] = useScrollbar();
    return (
        <Box
            ref={ref}
            width="100%"
            height="100%"
            overflowY="auto"
            overflowX="auto"
        >
            <DataTable
                onRowClick={onRowClick}
                enableRowSelection
                enableMultiRowSelection
                initialState={initialState}
                variant="unstyled"
                columns={columns}
                data={items}
                isSortable
                getRowId={(row) => row.id}
            />
        </Box>
    );
};

BeamtimeTable.displayName = "BeamtimeTable";
