import type { FC } from "react";

import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

type Props = { onClick: () => void };
export const BeamtimeEmptyState: FC<Props> = ({ onClick }) => (
    <EmptyState
        icon={() => <Icon.Beamtime />}
        heading="No Beamtimes"
        body="You haven't created any Beamtimes yet. Start by creating one."
        onClick={onClick}
        buttonLabel="Create beamtime"
    />
);
