import { alertAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(alertAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        backgroundColor: "#040404",
        borderRadius: "999999px",
        color: "white",
        width: "100%",
        maxWidth: {
            base: "300px",
            sm: "300px",
            md: "400px",
        },
        minWidth: {
            base: "300px",
            sm: "300px",
            md: "400px",
        },
    },
    description: {
        color: "darkgrey",
    },
    icon: {
        borderRadius: "9999px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "24px",
        height: "24px",
        padding: "2px",
        border: "solid 1px",
        borderColor: "currentColor",
    },
});

const success = definePartsStyle({
    // define the part you're going to style

    icon: {
        border: "solid 1px purple",
    },
});

export const alertStyles = defineMultiStyleConfig({
    baseStyle,
    variants: {
        success: success,
    },
});

export const alertTheme = {
    components: {
        Alert: alertStyles,
    },
};
