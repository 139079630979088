// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC } from "react";
import React from "react";

import { Box, Text } from "@chakra-ui/react";

type SidebarItemProps = {
    active: boolean;
    label: string;
    onClick: React.MouseEventHandler;
};

export const SidebarItem: FC<SidebarItemProps> = (props) => {
    const { onClick, label, active } = props;
    return (
        <Box
            _hover={
                !active
                    ? {
                          bg: "gray.100",
                      }
                    : {}
            }
            h="40px"
            my="2px"
            borderRadius="6px"
            onClick={onClick}
            py="10px"
            pl="24px"
            pr="2px"
            transitionProperty="background-color"
            transitionDuration="normal"
            textAlign="left"
            backgroundColor={active ? "gray.100" : "white"}
            position="relative"
            overflow="hidden"
            textTransform="capitalize"
            lineHeight="16px"
            fontWeight="400"
            fontSize="14px"
            cursor="pointer"
            color="gray.800"
        >
            <Box>
                <Text textStyle="T2">{label}</Text>
            </Box>
        </Box>
    );
};

SidebarItem.displayName = "SidebarItem";
export default SidebarItem;
