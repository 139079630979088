import type { ReactNode } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";

export const StaticField2 = ({
    title,
    value,
    ...boxProps
}: {
    title: string;
    value: ReactNode;
} & BoxProps) => (
    <Box h="61px" {...boxProps}>
        <Text minWidth="150px" fontWeight="medium" fontSize="md" mb="4px">
            {title}
        </Text>
        {typeof value === "string" || typeof value === "number" ? (
            <Text
                height="36px"
                fontWeight="400"
                fontSize="14px"
                py="6px"
                px="4px"
            >
                {value}
            </Text>
        ) : (
            <Box
                height="36px"
                fontWeight="400"
                fontSize="14px"
                py="6px"
                px="4px"
            >
                {value}
            </Box>
        )}
    </Box>
);
