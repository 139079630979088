import type { FC } from "react";
import { useMemo, useState } from "react";

import { groupBy, sort } from "ramda";

import { Box, Select, VStack } from "@chakra-ui/react";

import { Artifact, type ArtifactModel } from "@app/domain/api/artifact.ts";
import type { MeasurementModel } from "@app/domain/api/measurement.ts";
import type { PDFTypes } from "@app/domain/services/artifact/artifact-service.ts";
import {
    defaultStringRanker,
    getFileExtension,
    isFQArtifact,
    isGRArtifact,
    isPDFType,
    isSQArtifact,
    isSQWithQmaxSmaller30,
    isXYEArtifact,
    mapPdfTypeToLabel,
    PDFType,
    renamePDFArtifacts,
} from "@app/domain/services/artifact/artifact-service.ts";
import { GraphContainer } from "@app/pages/agent/requestResult/GraphContainer.tsx";

export const PDFMeasurementContainer: FC<{
    orgId: string;
    measurement: MeasurementModel;
    onSelectArtifact?: (artifact?: ArtifactModel) => void;
}> = ({ measurement, orgId }) => {
    const [pdfType, setPdfType] = useState<PDFTypes>("gr");

    const artficatsQuery = Artifact.useGetAll({
        params: {
            path: {
                organizationId: orgId,
            },
            query: {
                filter: {
                    measurementId: measurement.id,
                },
            },
        },
    });

    const memoizedArtifacts = useMemo(() => {
        return (
            artficatsQuery.data?.filter(
                (a) =>
                    isGRArtifact(a) ||
                    isFQArtifact(a) ||
                    isXYEArtifact(a) ||
                    (isSQArtifact(a) && !isSQWithQmaxSmaller30(a)),
            ) ?? []
        );
    }, [artficatsQuery]);

    const groupedArtifactes: Partial<Record<PDFTypes, ArtifactModel[]>> =
        groupBy(
            getFileExtension,
            sort(
                (a, b) => defaultStringRanker(a.name, b.name),
                memoizedArtifacts,
            ).filter((a) => isPDFType(getFileExtension(a))),
        );

    const fqWithQmax30 = groupedArtifactes.fq?.find((a) =>
        a.name.includes(".qmax30."),
    );
    const groupedArtifacts = {
        ...groupedArtifactes,
        fq: fqWithQmax30 ? [fqWithQmax30] : [],
    };

    if (!artficatsQuery.data) {
        return null;
    }

    return (
        <VStack
            alignItems="flex-start"
            w="100%"
            mx="auto"
            flex={1}
            overflow="auto"
            minHeight="400px"
        >
            <Box w="250px" px="6px" py="4px" flex="0 0 44px">
                <Select
                    flex="0 0 250px"
                    value={pdfType}
                    onChange={(e) =>
                        setPdfType(
                            isPDFType(e.target.value)
                                ? e.target.value
                                : PDFType.gr,
                        )
                    }
                >
                    {(["xye", "sq", "fq", "gr"] satisfies PDFTypes[]).map(
                        (key) => (
                            <option key={key} value={key}>
                                {mapPdfTypeToLabel(key)}
                            </option>
                        ),
                    )}
                </Select>
            </Box>
            <Box flex={1} w="100%">
                <GraphContainer
                    orgId={orgId}
                    artifacts={(groupedArtifacts[pdfType] ?? []).map((a) => ({
                        ...a,
                        name: renamePDFArtifacts(a),
                    }))}
                />
            </Box>
        </VStack>
    );
};
