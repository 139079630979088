import createClient from "openapi-fetch";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import type { paths } from "@app/domain/api/types/v1";

export type ApiFactoryParams = { token: string | undefined; apiPath: string };

export const buildApiContextValue = (
    params: ApiFactoryParams,
): APIContextValue => {
    const client = createClient<paths>({
        baseUrl: params.apiPath,
        querySerializer: {
            array: {
                style: "form",
                explode: true,
            },
        },
        headers: { Authorization: `Bearer ${params.token}` },
    });

    const publicClient: APIContextValue["publicClient"] = createClient({
        baseUrl: `${params.apiPath}/public`,
        querySerializer: {
            array: {
                style: "form",
                explode: true,
            },
        },
        headers: { Authorization: `Bearer ${params.token}` },
    });
    const agentClient: APIContextValue["agentClient"] = createClient({
        baseUrl: `${params.apiPath}/agent`,
        querySerializer: {
            array: {
                style: "form",
                explode: true,
            },
        },
        headers: { Authorization: `Bearer ${params.token}` },
    });

    return {
        publicClient,
        agentClient,

        client: client,
        token: params.token,
        basePath: params.apiPath,
    };
};
