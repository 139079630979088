import React from "react";

import type { AlertIconProps } from "@chakra-ui/react";
import { chakra, Spinner, useMultiStyleConfig } from "@chakra-ui/react";

export const AlertSpinner: React.FC<AlertIconProps> = (props) => {
    const styles = useMultiStyleConfig("Alert", props);
    return (
        <chakra.span
            display="inherit"
            alignItems="center"
            {...props}
            __css={styles.icon}
        >
            <Spinner size="sm" />
        </chakra.span>
    );
};
