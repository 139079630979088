import type { FC } from "react";

import type { Promisable } from "type-fest";

import {
    Box,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from "@chakra-ui/react";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type { OrganizationModel } from "@app/domain/api/organization.ts";

import { DataTable } from "@mt-components/DataTable.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import { Icon } from "@mt-design/icons.tsx";

import type { publicSchema } from "src/api/client";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { useRoleContext } from "src/App/contexts/RoleContext";
import { Role } from "src/App/domain/auth";

type Props = {
    items: publicSchema.components["schemas"]["Organization"][];
    onGoToOrg: (organizationId: string) => void;
    onEdit: (data: OrganizationModel) => void;
    onDelete: (id: string) => Promisable<unknown>;
    onLeave: (organizationId: string) => void;
};

export const OrganizationsTable: FC<Props> = (props) => {
    const roleCtx = useRoleContext();
    const orgContext = useOrgContext();

    return (
        <Box
            h="100%"
            overflow="hidden"
            w="100%"
            overflowX="auto"
            overflowY="auto"
        >
            <Box width="100%" h="100%">
                <DataTable
                    onRowClick={(org) => props.onGoToOrg(org.id)}
                    initialState={{
                        sorting: [
                            {
                                id: "createdAt",
                                desc: true,
                            },
                        ],
                    }}
                    variant="unstyled"
                    columns={[
                        {
                            accessorKey: "name",
                            header: "Name",
                            cell: (cell) => {
                                const name = cell.row.original.name;
                                const id = cell.row.original.id;

                                return (
                                    <>
                                        <OrganizationLink
                                            label={name}
                                            organizationId={id}
                                        ></OrganizationLink>

                                        {orgContext.currentOrg === id ? (
                                            <Box
                                                display="inline-block"
                                                fontSize="14px"
                                                fontStyle="italic"
                                                ml="20px"
                                            >
                                                active
                                            </Box>
                                        ) : null}
                                    </>
                                );
                            },
                        },
                        {
                            accessorKey: "createdAt",
                            header: "Created",
                            cell: (cell) => {
                                return formatDate(cell.row.original.createdAt);
                            },
                        },
                        {
                            accessorKey: "menu",
                            header: " ",
                            cell: (cell) => {
                                const value = cell.row.original.id;

                                return roleCtx.role === Role.AGENT ? null : (
                                    <Menu>
                                        <MenuButton
                                            bg="white"
                                            aria-label="Menu"
                                            colorScheme="gray"
                                            as={IconButton}
                                            icon={<Icon.Menu />}
                                        />
                                        <MenuList>
                                            <MenuItem
                                                onClick={() =>
                                                    orgContext.setCurrentOrg(
                                                        value,
                                                    )
                                                }
                                            >
                                                Activate
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() =>
                                                    props.onEdit(
                                                        cell.row.original,
                                                    )
                                                }
                                            >
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    props.onLeave(
                                                        cell.row.original.id,
                                                    );
                                                }}
                                            >
                                                Leave
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                );
                            },
                        },
                    ]}
                    data={props.items}
                    isSortable
                />
            </Box>
        </Box>
    );
};

OrganizationsTable.displayName = "OrganizationsTable";
