import type { FC } from "react";

import { Box } from "@chakra-ui/react";

import type { SampleModel } from "@app/domain/api/sample.ts";
import { SampleHazards } from "@app-components/sample/SampleHazards.tsx";
import { SampleProperties } from "@app-components/sample/SampleProperties.tsx";
import { MultiSelectionView } from "@app-components/sample/Sidebar/MultiSelection.view.tsx";
import { NoSelectionView } from "@app-components/sample/Sidebar/NoSelection.view.tsx";

import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Footer } from "@mt-components/Layout/Sidebar/Footer.tsx";
import { Sidebar } from "@mt-components/Layout/Sidebar/Sidebar.tsx";
import { Title } from "@mt-components/Layout/Title.tsx";
import { SidebarSection } from "@mt-components/Sidebar";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

type SampleSidebarProps = {
    isOpen?: boolean;
    samples: SampleModel[];
    onToggle: () => void;
};

export const SampleSidebar: FC<SampleSidebarProps> = ({
    isOpen,
    onToggle,
    samples,
}) => {
    const [ref] = useScrollbar<HTMLDivElement>();

    return (
        <Sidebar
            isOpen={isOpen}
            boxShadow="none"
            borderLeft="solid 1px"
            borderColor="gray.200"
            height="100%"
            px="12px"
        >
            {isOpen && samples.length === 0 && (
                <SidebarSection flex="1">
                    <NoSelectionView />
                </SidebarSection>
            )}

            {isOpen && samples.length > 1 && (
                <SidebarSection flex="1">
                    <MultiSelectionView amount={samples.length} />
                </SidebarSection>
            )}

            {isOpen && samples.length === 1 && (
                <>
                    <SidebarSection overflow="hidden">
                        <Title textStyle="h5" title={samples[0].name} />
                    </SidebarSection>
                    <Divider px="12px" my={0} mt="0 !important" />
                    <SidebarSection flex="1" overflow="hidden">
                        <Box overflowY="auto" ref={ref} height="100%">
                            <SampleProperties sample={samples[0]} />
                            <Divider my={0} />
                            <SampleHazards sample={samples[0]} />
                        </Box>
                    </SidebarSection>
                </>
            )}

            <Footer
                flex="0 0 32px"
                mt="auto"
                isOpen={isOpen}
                onToggle={onToggle}
            />
        </Sidebar>
    );
};
