import { useRef } from "react";

import { Box, Text } from "@chakra-ui/react";
import type { Table } from "@tanstack/react-table";

import { LocalStorageKeys } from "@app/config.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import {
    type ColumnDef,
    DataTable,
    makeWidth,
} from "@mt-components/DataTable.tsx";
import { TableSearchInput } from "@mt-components/Input/TableSearchInput.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Footer } from "@mt-components/Layout/Sidebar/Footer.tsx";
import { Sidebar } from "@mt-components/Layout/Sidebar/Sidebar.tsx";
import { SidebarSection } from "@mt-components/Sidebar";

import { useDisclosureWithStorage } from "@mt-hooks/useDisclosureWithStorage.ts";
import { useSearch } from "@mt-hooks/useSearch.tsx";

import { toId } from "@mt-tools/iterating/filter.ts";

const initialState = {
    sorting: [
        {
            id: "qrCode",
            desc: true,
        },
    ],
};

const columns: ColumnDef<SampleModel>[] = [
    {
        accessorKey: "name",
        header: "Sample-ID",

        // @ts-ignore
        thProps: makeWidth("120px"),
    },
    {
        accessorKey: "qrCode",
        header: "QR-Code",

        // @ts-ignore
        thProps: makeWidth("120px"),
    },
] satisfies ColumnDef<SampleModel>[];

export const DataSidebar = (props: {
    orgId: string;
    selectedSampleId: string;
    measurementGroupId: string;
    samples: SampleTasksModel[];
    onSelectSample: (sampleId: string) => void;
}) => {
    const { isOpen, onToggle } = useDisclosureWithStorage(
        LocalStorageKeys.measurementGroupDataSidebar,
    );

    const tableRef = useRef<Table<SampleModel>>(null);

    const { searchTerm, filteredOrders, onClear, onChange } = useSearch({
        keys: ["name", "qrCode"],
        items: props.samples,
    });

    return (
        <Sidebar
            isOpen={isOpen}
            boxShadow="none"
            borderLeft="solid 1px"
            borderColor="gray.200"
            height="100%"
        >
            {isOpen && (
                <SidebarSection flex={1} direction="column" overflow="hidden">
                    <Box
                        display="flex"
                        flexDirection="column"
                        h="100%"
                        overflow="hidden"
                    >
                        <Box flex="0 0 140px" p="4px" border="none">
                            <TableSearchInput
                                value={searchTerm}
                                onChange={onChange}
                                placeholder="Search by name"
                                onReset={onClear}
                            />
                            <Divider />
                            <Text
                                color="gray.600"
                                fontSize="12px"
                                fontWeight="400"
                                lineHeight="100%"
                            >
                                All samples of the group
                            </Text>
                        </Box>
                        <Box h="100%" flex={1} overflowY="hidden">
                            <Box h="100%" w="100%" overflow="hidden">
                                <DataTable<SampleModel>
                                    variant="unstyled"
                                    instanceRef={tableRef}
                                    isSortable
                                    getRowId={toId}
                                    initialState={initialState}
                                    state={{
                                        rowSelection: {
                                            [props.selectedSampleId]: true,
                                        },
                                    }}
                                    onRowClick={(row) => {
                                        props.onSelectSample(row.id);
                                    }}
                                    onRowSelectionChange={() => {}}
                                    columns={columns}
                                    data={filteredOrders}
                                />
                            </Box>
                        </Box>
                    </Box>
                </SidebarSection>
            )}
            <Footer
                flex="0 0 32px"
                mt="auto"
                isOpen={isOpen}
                onToggle={onToggle}
            />
        </Sidebar>
    );
};
