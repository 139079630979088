import type { FC } from "react";
import { memo, useState } from "react";

import { Box, Center } from "@chakra-ui/react";

import { Organization } from "@app/domain/api/organization.ts";
import type { PackageModel } from "@app/domain/api/package.ts";
import { Package } from "@app/domain/api/package.ts";
import { Request } from "@app/domain/api/request.ts";
import { PackagesTable } from "@app/pages/agent/packages/Packages.table.tsx";
import { PackageModal } from "@app/pages/user/packages/Package.modal.tsx";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { byId } from "@mt-tools/iterating/filter.ts";

export const PackagesContainer: FC = memo(() => {
    const [packageToEdit, setPackageToEdit] = useState<
        PackageModel | undefined
    >();
    const organizationsQuery = Organization.useAdminGetAll();
    const packagesQuery = Package.useAdminGetAll();
    const getAllRequestsQuery = Request.useAdminGetAll();

    if (
        !packagesQuery.data ||
        !organizationsQuery.data ||
        !getAllRequestsQuery.data
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSpinner />
            </Center>
        );
    }

    const orgsById = byId(organizationsQuery.data);

    const requests = getAllRequestsQuery.data;
    const requestsById = byId(requests);

    const extendedPackages: (PackageModel & {
        organizationName: string;
        requestName: string;
    })[] = packagesQuery.data.map((p) => {
        return {
            ...p,
            organizationName: orgsById[p.organizationId]?.name ?? "",
            requestName: p.measurementGroupId
                ? (requestsById[p.measurementGroupId]?.name ?? "")
                : "",
        };
    });

    return (
        <>
            {packageToEdit && packageToEdit.measurementGroupId && (
                <PackageModal
                    isOpen
                    canDelete={false}
                    canEdit={["status"]}
                    statusOptions={["draft", "dispatched", "received", "lost"]}
                    initialData={packageToEdit}
                    onCancel={() => setPackageToEdit(undefined)}
                    requestId={packageToEdit.measurementGroupId}
                    orgId={packageToEdit.organizationId}
                />
            )}
            <Box
                id="sample-details-main"
                mx="auto"
                display="flex"
                flexDirection="row"
                h="100%"
                overflow="hidden"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    overflowX="auto"
                    flex={1}
                    h="100%"
                    overflow="hidden"
                >
                    <Box
                        display="flex"
                        flexDir="column"
                        w="100%"
                        h="100%"
                        overflow="hidden"
                    >
                        <PageHeader
                            actionsId="samples-page-actions"
                            crumbs={[
                                {
                                    id: "Packages",
                                    label: "Packages",
                                    isCurrentPage: true,
                                },
                            ]}
                            subtitle="All shipments across the app"
                            menuActions={[]}
                        />

                        <PackagesTable
                            packages={extendedPackages}
                            onRowClick={(p) => setPackageToEdit(p)}
                        />
                    </Box>
                </PageLayout>
            </Box>
        </>
    );
});

PackagesContainer.displayName = "PackagesContainer";
