import type { FC } from "react";

import {
    Box,
    Stat,
    StatHelpText,
    StatLabel,
    StatNumber,
} from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import { MeasurementGroupStatus } from "@app/domain";
import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import { Request } from "@app/domain/api/request.ts";
import { routes } from "@app/Routes/routes.ts";

import { Title } from "@mt-components/Layout/Title.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

export const Dashboard: FC = () => {
    const measurementGroupsPage = Request.useAdminGetAll({
        query: {
            filter: {
                status: MeasurementGroupStatus.Submitted,
            },
        },
    });

    const beamtimesPage = Beamtime.useAdminGetAll();

    const beamtimes = beamtimesPage.data?.data ?? [];

    const data = beamtimes
        .filter((b) => b.status === "published")
        .sort((a, b) => {
            if (!a.date || !b.date) {
                return 0;
            }

            if (a.date <= b.date) {
                return -1;
            }

            return 1;
        });

    const nextBeamTime = data.length >= 1 && data[0];

    return (
        <Box
            w="100%"
            h="100%"
            display="flex"
            flexDir="column"
            my="auto"
            overflow="auto"
            p="24px"
            gap="8"
        >
            <Title title="Agent Dashboard" />
            <Box display="flex">
                <Stat flex="1 1 auto">
                    <StatLabel>Submitted Requests</StatLabel>
                    <StatNumber>
                        {measurementGroupsPage.data?.length}
                    </StatNumber>
                    <StatHelpText>
                        <Link
                            to={routes.agent.measurementGroups.url}
                            search={{
                                status: MeasurementGroupStatus.Submitted,
                            }}
                        >
                            Inspect
                        </Link>
                    </StatHelpText>
                </Stat>
                <Stat flex="1 1 auto">
                    <StatLabel>Next Beamtime</StatLabel>
                    <StatNumber>
                        {nextBeamTime
                            ? nextBeamTime.date
                                ? formatDate(nextBeamTime.date)
                                : ""
                            : "No Beamtime coming up"}
                    </StatNumber>
                    <StatHelpText>
                        {nextBeamTime && (
                            <Link
                                to={routes.agent.beamtimesDetail.url}
                                params={{ beamtimeId: nextBeamTime.id }}
                            >
                                Go to
                            </Link>
                        )}
                    </StatHelpText>
                </Stat>
            </Box>
        </Box>
    );
};

Dashboard.displayName = "Dashboard";
