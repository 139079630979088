import pMap from "p-map";
import { flatten, omit, partial } from "ramda";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

const entityKey = QueryKeys.measurementTask;

type MeasurementTaskModel = components["schemas"]["MeasurementTask"];

type MeasurementMethod = MeasurementTaskModel["method"];

type PatchAdminTaskPayload =
    components["schemas"]["PatchMeasurementTaskPayload"];

type PatchAdminMeasurementTaskRequest = {
    params: operations["patchAdminMeasurementTask"]["parameters"];
    payload: PatchAdminTaskPayload;
};

type CreateMeasurementTaskPayload =
    components["schemas"]["CreateMeasurementTaskPayload"];

type CreateMeasurementTaskRequest = {
    params: operations["createMeasurementTask"]["parameters"];
    payload: CreateMeasurementTaskPayload;
};

export type {
    CreateMeasurementTaskPayload,
    MeasurementMethod,
    MeasurementTaskModel,
};

type GetAllTasksParams = operations["getMeasurementTasks"]["parameters"];

const getAllTasks = async (api: APIContextValue, params: GetAllTasksParams) =>
    await api.client.GET("/organizations/{organizationId}/measurement-tasks", {
        params: applyPageSizeDefault(params),
    });

const useGetAll = (params?: GetAllTasksParams & { isActive?: boolean }) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            if (!params || params.isActive === false) {
                return null;
            }

            try {
                const fetcher = partial(getAllTasks, [api]);
                const data = await collectAllPages<
                    MeasurementTaskModel,
                    GetAllTasksParams,
                    typeof fetcher
                >(fetcher, omit(["isActive"], params));
                return data;
            } catch {
                throw new Error("Could not fetch measurement tasks");
            }
        },
    });
};
const agentGetAllTasks = async (
    api: APIContextValue,
    params: operations["getAdminMeasurementTasks"]["parameters"],
) => {
    return await api.client.GET("/admin/measurement-tasks", {
        params: applyPageSizeDefault(params),
    });
};

const useAgentGetAll = (
    params: operations["getAdminMeasurementTasks"]["parameters"],
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            const fetcher = partial(agentGetAllTasks, [api]);

            try {
                const data = await collectAllPages<
                    MeasurementTaskModel,
                    operations["getAdminMeasurementTasks"]["parameters"],
                    typeof fetcher
                >(fetcher, params);
                return data;
            } catch {
                throw new Error("Could not fetch measurement tasks");
            }
        },
    });
};

const useCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async ({
            params,
            payload,
        }: CreateMeasurementTaskRequest) => {
            const r = await api.client.POST(
                "/organizations/{organizationId}/measurement-tasks",
                {
                    params,
                    body: payload,
                },
            );
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useCreateBatch = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizationId: string;
            requestId: string;
            tasks: Omit<CreateMeasurementTaskPayload, "measurementGroupId">[];
        }) => {
            const promisses = data.tasks.map((task) => {
                return api.client.POST(
                    "/organizations/{organizationId}/measurement-tasks",
                    {
                        params: {
                            path: {
                                organizationId: data.organizationId,
                            },
                        },
                        body: {
                            sampleId: task.sampleId,
                            measurementGroupId: data.requestId,
                            method: task.method,
                        },
                    },
                );
            });

            const responses = await Promise.all(promisses);

            await client.invalidateQueries({
                queryKey: [entityKey, QueryKeys.measurementTask],
            });

            return {
                ok: responses.filter((r) => r.response.ok).length,
                notOk: responses.filter((r) => !r.response.ok).length,
            };
        },
    });
};

const useDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            measurementTaskId: string;
            organizationId: string;
        }) => {
            return await api.client.DELETE(
                "/organizations/{organizationId}/measurement-tasks/{measurementTaskId}",
                {
                    params: {
                        path: data,
                    },
                },
            );
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useDeleteAll = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            measurementTaskIds: string[];
            organizationId: string;
        }) => {
            const promisses = data.measurementTaskIds.map(async (d) => {
                return await api.client.DELETE(
                    "/organizations/{organizationId}/measurement-tasks/{measurementTaskId}",
                    {
                        params: {
                            path: {
                                organizationId: data.organizationId,
                                measurementTaskId: d,
                            },
                        },
                    },
                );
            });

            await Promise.all(promisses);

            return;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useMeasurementTasksOfGroups = (measurementGroupIds: string[]) => {
    const api = useAPI();

    return useQuery({
        queryKey: ["measurementTasks", measurementGroupIds],
        queryFn: async () => {
            const allData = await pMap(measurementGroupIds, async (groupId) => {
                const fetcher = partial(agentGetAllTasks, [api]);
                try {
                    const params: operations["getAdminMeasurementTasks"]["parameters"] =
                        {
                            query: {
                                filter: {
                                    measurementGroupId: groupId,
                                },
                            },
                        };
                    const data = await collectAllPages<
                        MeasurementTaskModel,
                        operations["getAdminMeasurementTasks"]["parameters"],
                        typeof fetcher
                    >(fetcher, applyPageSizeDefault(params));
                    return data;
                } catch {
                    throw new Error("Could not fetch measurement tasks");
                }
            });

            return flatten(allData);
        },
    });
};

const useAdminPatch = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async ({
            params,
            payload,
        }: PatchAdminMeasurementTaskRequest) => {
            const r = await api.client.PATCH(
                "/admin/organizations/{organizationId}/measurement-tasks/{measurementTaskId}",
                {
                    params: {
                        path: {
                            organizationId: params.path.organizationId,
                            measurementTaskId: params.path.measurementTaskId,
                        },
                    },
                    body: payload,
                },
            );
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

export const MeasurementTask = {
    useGetAll,
    useCreate,
    useCreateBatch,
    useDelete,
    useDeleteAll,
    agent: {
        useAgentGetAll: useAgentGetAll,
        useMeasurementTasksOfGroups,
        useAdminPatch: useAdminPatch,
    },
};
