import type { FC } from "react";
import { Fragment, useRef, useState } from "react";

import {
    Box,
    Button,
    Center,
    HStack,
    IconButton,
    SimpleGrid,
    Text,
} from "@chakra-ui/react";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import { Sample } from "@app/domain/api/sample.ts";
import type { ImportResult } from "@app/domain/services/sample/sample.ts";
import { parseIntoSamplePayloads } from "@app/domain/services/sample/sample.ts";

import {
    FileUpload,
    FileUploadDropzone,
    FileUploadTrigger,
} from "@mt-components/Input/FileUploadCore";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { readAsText } from "@mt-tools/io/readAsText.ts";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    isOpen: boolean;
    onCancel: () => void;
};

export const UploadSamplesModal: FC<Props> = ({ onCancel, isOpen }) => {
    const uploadRef = useRef<HTMLDivElement | null>(null);
    const { currentOrg } = useOrgContext();
    const snackbar = useSnackbar();
    const [stateFiles, setFiles] = useState<{ acceptedFiles: File[] }>();
    const { mutateAsync } = Sample.useCreateMany();

    const [result, setResult] = useState<ImportResult | undefined>();

    const importFiles = async () => {
        const acceptedFiles = stateFiles?.acceptedFiles;
        if (!acceptedFiles?.length) {
            return;
        }

        const ctx = (await readAsText(acceptedFiles[0])) as string;

        const result: undefined | string | ImportResult =
            parseIntoSamplePayloads(ctx);

        if (!result) {
            snackbar.error("Could not import sample sheet");
            return;
        }

        if (typeof result === "string") {
            snackbar.info(result);
            return;
        }

        if (result.invalid) {
            snackbar.error("Invalid samples found");
        }

        if (result.valid) {
            const payloads = result.valid.map((p) => ({
                body: p.data,
                params: { path: { organizationId: currentOrg } },
            }));

            await mutateAsync(payloads).then(() => {
                snackbar.success("Imported");
            });
        }

        setResult(result);
    };

    return (
        <Modal
            isOpen={isOpen}
            header="Import CSV"
            onClose={onCancel}
            buttonLeft={
                <Button
                    colorScheme={result ? "green" : "gray"}
                    onClick={onCancel}
                >
                    Close
                </Button>
            }
            buttonRight={
                <Button
                    colorScheme="blue"
                    isDisabled={
                        !stateFiles?.acceptedFiles.length || Boolean(result)
                    }
                    onClick={importFiles}
                >
                    Import
                </Button>
            }
        >
            <Box>
                {result ? (
                    <Box w="100%" maxH="400px" overflowY="auto" p="12px">
                        {result.valid?.length && (
                            <>
                                <SimpleGrid columns={2} spacing={2}>
                                    <Text fontWeight="600">
                                        Imported samples
                                    </Text>
                                    <Text fontWeight="600">
                                        {result.valid.length}
                                    </Text>
                                    <Text>Detected Separator</Text>{" "}
                                    <Text>{result.separator}</Text>
                                </SimpleGrid>

                                <SimpleGrid
                                    my="32px"
                                    columns={2}
                                    templateColumns="300px 160px"
                                    spacing={2}
                                >
                                    {result.valid.map((r) => {
                                        return (
                                            <Fragment key={r.id}>
                                                <Text
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"
                                                    whiteSpace="nowrap"
                                                >
                                                    {r.data.name}
                                                </Text>
                                                <Text
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"
                                                    whiteSpace="nowrap"
                                                >
                                                    {r.data.composition}
                                                </Text>
                                            </Fragment>
                                        );
                                    })}
                                </SimpleGrid>
                            </>
                        )}

                        {result.invalid?.length && (
                            <Box
                                mt="32px"
                                display="flex"
                                flexDirection="column"
                                gap="32px"
                            >
                                <Box>
                                    <Text fontWeight="600">
                                        The following {result.invalid.length}{" "}
                                        data sets could not be imported.
                                    </Text>
                                    <Text fontWeight="400">
                                        Please make sure not to use commas in
                                        the data and please inspect the
                                        following hints
                                    </Text>
                                </Box>
                                <Box overflowX="auto" overflow="hidden">
                                    <Box
                                        key="header"
                                        w="100%"
                                        whiteSpace="nowrap"
                                    >
                                        <Box
                                            display="flex"
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            h="32px"
                                            w="100%"
                                        >
                                            <Text
                                                display="inline-block"
                                                w="340px"
                                                overflow="hidden"
                                                textOverflow="ellipsis"
                                                fontWeight="600"
                                            >
                                                Name
                                            </Text>
                                            <Text
                                                fontWeight="600"
                                                display="inline-block"
                                                w="240px"
                                            >
                                                Error in
                                            </Text>
                                        </Box>
                                    </Box>
                                    {result.invalid.map((r) => {
                                        return (
                                            <Box
                                                key={r.id}
                                                w="100%"
                                                whiteSpace="nowrap"
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    h="40px"
                                                    w="100%"
                                                >
                                                    <Text
                                                        display="inline-block"
                                                        w="340px"
                                                        overflow="hidden"
                                                        textOverflow="ellipsis"
                                                    >
                                                        {"name" in r.raw &&
                                                        typeof r.raw.name ===
                                                            "string"
                                                            ? r.raw.name
                                                            : JSON.stringify(
                                                                  r.raw,
                                                              )}
                                                    </Text>
                                                    <Text
                                                        display="inline-block"
                                                        w="240px"
                                                    >
                                                        {JSON.stringify(
                                                            r.error.errors[0]
                                                                .path[0],
                                                        )}
                                                    </Text>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        )}
                    </Box>
                ) : (
                    <FileUpload
                        ref={uploadRef}
                        maxFileSize={1024 * 1024}
                        maxFiles={1}
                        accept=".csv"
                        onFileChange={setFiles}
                    >
                        {({
                            files,
                            clearFiles,
                        }: {
                            files: File[];
                            clearFiles: () => void;
                        }) => (
                            <FileUploadDropzone>
                                {files.length === 0 && (
                                    <>
                                        <Text fontSize="md">
                                            Drop your sample sheet here.{" "}
                                            <Text fontSize="md">
                                                Make sure to use the template
                                                for importing your samples.
                                            </Text>
                                        </Text>
                                    </>
                                )}
                                {!files.length ? (
                                    <FileUploadTrigger
                                        colorScheme="blue"
                                        as={Button}
                                    >
                                        Upload CSV
                                    </FileUploadTrigger>
                                ) : (
                                    <HStack w="100%">
                                        <SimpleGrid
                                            maxWidth="70%"
                                            mx="auto"
                                            columns={2}
                                            templateColumns="2fr 1fr"
                                            spacing={2}
                                        >
                                            <Text mr="74px" fontSize="sm">
                                                {files[0].name}
                                            </Text>
                                            <Center>
                                                <IconButton
                                                    mx="auto"
                                                    colorScheme="gray"
                                                    aria-label="delete"
                                                    icon={<Icon.Delete />}
                                                    onClick={() => {
                                                        setFiles(undefined);
                                                        clearFiles();
                                                    }}
                                                />
                                            </Center>
                                        </SimpleGrid>
                                    </HStack>
                                )}
                            </FileUploadDropzone>
                        )}
                    </FileUpload>
                )}
            </Box>
        </Modal>
    );
};
