import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import {
    use2SDownloadQuery,
    use2SDownloadSnackbar,
} from "src/App/domain/2s-download";
import type { operations } from "src/App/domain/api/types/v1";
import { downloadFileNative } from "src/packages/tools/io/downloadFile";

import { serializeUrlSearchParams } from "@seamapi/url-search-params-serializer";

export const useArtifactsDownloader = (
    organizationId: string,
    filter: Required<
        NonNullable<
            operations["downloadOrganizationArtifacts"]["parameters"]["query"]
        >
    >["filter"],
) => {
    const snackbar = use2SDownloadSnackbar();

    const api = useAPI();

    const downloadQuery = use2SDownloadQuery({
        async fn() {
            const q = serializeUrlSearchParams({ ...filter });

            return await fetch(
                `${api.basePath}/organizations/${organizationId}/artifacts/download?${q}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${api.token}`,
                    },
                },
            );
        },
        onError() {
            snackbar.errorCollect();
        },
        onCollectProgress(data) {
            const progress = (data.totalProcessed / data.total) * 100;

            if (Math.round(progress) === 100) {
                snackbar.progressZip(0);
                return;
            }

            snackbar.progressCollect(progress);
        },
        onZipProgress(data) {
            const progress = (data.loaded / data.total) * 100;
            snackbar.progressZip(progress);
        },
        onDownloadUrl(url) {
            snackbar.progressDownloadUrl();
            downloadFileNative(url);
        },
    });

    return () => {
        snackbar.open();
        downloadQuery.run();
    };
};
