import { createContext } from "react";

import type createClient from "openapi-fetch";

import type { paths } from "@app/domain/api/types/v1";

import type * as agentSchema from "../../../api/gen/schema-agent";
import type * as publicSchema from "../../../api/gen/schema-public";

type ApiClient = ReturnType<typeof createClient<paths>>;
export type APIContextValue = {
    client: ApiClient;

    publicClient: ReturnType<typeof createClient<publicSchema.paths>>;
    agentClient: ReturnType<typeof createClient<agentSchema.paths>>;

    token: string | undefined;
    basePath: string;
};

export const AContext = createContext<APIContextValue | null>(null);
