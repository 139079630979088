import type { FC } from "react";
import { useMemo } from "react";

import { Box } from "@chakra-ui/react";

import type { OrganizationModel } from "@app/domain/api/organization.ts";

import { toValueLabel } from "@mt-components/Input/Select/helpers.ts";
import type { BaseOption } from "@mt-components/Input/Select/Select.tsx";
import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

import { getByValue } from "@mt-tools/iterating/filter.ts";

type OrganizationFilterProps = {
    options: OrganizationModel[];
    value?: string;
    onChange: (value?: string) => void;
    placeholder?: string;
};

export const OrganizationFilter: FC<OrganizationFilterProps> = ({
    options: opts,
    value,
    onChange,
    placeholder,
}) => {
    const options = useMemo(() => opts.map(toValueLabel), [opts]);

    const selected = useMemo(
        () => (value ? options.find(getByValue(value)) : undefined),
        [value, options],
    );

    return (
        <Box>
            <SingleSelect<BaseOption>
                isClearable
                placeholder={placeholder ?? "Filter by organization"}
                value={selected}
                options={options}
                onChange={(newValue) => {
                    onChange(newValue?.value ?? undefined);
                }}
            />
        </Box>
    );
};
