// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from "react";

import { connectToContainer } from "lib";
import PropTypes from "prop-types";

import Field from "./Field";

import ColorPickerWidget from "../widgets/ColorPicker";

export const UnconnectedColorPicker = (props) => {
    const [isEmpty, setIsEmppty] = useState(
        !props.fullValue && props.handleEmpty,
    );

    if (isEmpty) {
        return (
            <Field {...props}>
                <div className="js-test-info">
                    {
                        "This color is computed from other parts of the figure but you can"
                    }{" "}
                    <a
                        onClick={() => {
                            setIsEmppty(false);
                            props.updatePlot(props.defaultColor);
                        }}
                    >
                        override it
                    </a>
                    .
                </div>
            </Field>
        );
    }

    return (
        <Field {...props}>
            <ColorPickerWidget
                selectedColor={props.fullValue}
                onColorChange={props.updatePlot}
            />
        </Field>
    );
};

UnconnectedColorPicker.propTypes = {
    fullValue: PropTypes.any,
    updatePlot: PropTypes.func,
    handleEmpty: PropTypes.bool,
    defaultColor: PropTypes.string,
    ...Field.propTypes,
};

UnconnectedColorPicker.contextTypes = {
    localize: PropTypes.func,
};

UnconnectedColorPicker.displayName = "UnconnectedColorPicker";

export default connectToContainer(UnconnectedColorPicker);
