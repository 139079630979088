import type { FC } from "react";
import { useState } from "react";

import type { Promisable } from "type-fest";
import { z } from "zod";

import { Box } from "@chakra-ui/react";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

const schema = z.object({
    name: z
        .string()
        .max(128, "128 characters maximum")
        .min(3, "at least 3 characters"),
});

export type FormShape = {
    name: string;
};

type Props = {
    isOpen: boolean;
    initialData?: FormShape;
    onSubmit: (params: FormShape) => Promisable<void>;
    onCancel: () => void;
};
export const MeasurementGroupModal: FC<Props> = ({
    isOpen,
    initialData,
    onSubmit,
    onCancel,
}) => {
    const [name, setName] = useState<string | undefined>(initialData?.name);

    const result = schema.safeParse({
        name,
    });

    const isTouchedAndError = Boolean(name && result.error);

    return (
        <Modal
            id="create-measurement-request"
            isOpen={isOpen}
            onClose={() => {
                onCancel();
                setName(undefined);
            }}
            header="Create request"
            buttonRight={
                <LoadingButton
                    colorScheme="blue"
                    isDisabled={!result.success}
                    onClick={async () => {
                        if (result.success && name) {
                            await onSubmit({ name });
                            setName(undefined);
                        }
                    }}
                >
                    Submit
                </LoadingButton>
            }
        >
            <Box my="24px">
                <InputField
                    label="Name"
                    value={name}
                    placeholder="Provide a descriptive name for your request"
                    help="e.g. Study on NaCl"
                    onChange={(e) => setName(e.target.value)}
                    errorMessage={
                        isTouchedAndError
                            ? result.error?.errors[0].message
                            : undefined
                    }
                />
            </Box>
        </Modal>
    );
};
