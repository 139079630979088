import type { ReactNode } from "react";

import {
    Box,
    HStack,
    Icon,
    type PlacementWithLogical,
    Spinner,
    Text,
    Tooltip,
} from "@chakra-ui/react";

export const StaticField = ({
    title,
    value,
    isLoading,
    tooltip,
    tooltipPlacement,
    info,
    infoPlacement,
}: {
    title: string;
    value: ReactNode;
    isLoading?: boolean;
    tooltip?: string;
    tooltipPlacement?: PlacementWithLogical;
    info?: string;
    infoPlacement?: PlacementWithLogical;
}) => (
    <Box>
        <HStack>
            <Text minWidth="150px" fontWeight="semibold">
                {title}
            </Text>
            {info ? (
                <Tooltip label={info} placement={infoPlacement}>
                    <Icon></Icon>
                </Tooltip>
            ) : null}
        </HStack>
        {isLoading ? (
            <Spinner size="sm"></Spinner>
        ) : (
            <Text lineHeight="140%" fontWeight="400" fontSize="14px">
                {tooltip ? (
                    <Tooltip label={tooltip} placement={tooltipPlacement}>
                        {value}
                    </Tooltip>
                ) : (
                    value
                )}
            </Text>
        )}
    </Box>
);
