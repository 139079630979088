import type { FC } from "react";
import { useMemo, useState } from "react";

import { identity, isNotNil } from "ramda";

import { Box, Checkbox, IconButton, Tooltip } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import type {
    MeasurementGroupModel,
    MeasurementGroupStatusType,
} from "@app/domain";
import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { BeamtimeFilter } from "@app-components/beamtime/BeamtimeFilter.tsx";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { MeasurementGroupStatusFilter } from "@app-components/measurementGroup/MeasurementGroupStatusFilter.tsx";
import { OrganizationFilter } from "@app-components/organization/OrganizationFilter.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { NoSearchMatch } from "@mt-components/Display/NoSearchMatch.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import { Icon } from "@mt-design/icons.tsx";

import { useSearch } from "src/packages/hooks/useSearch.tsx";

type OrganizationsById = Record<string, OrganizationModel | undefined>;
type BeamtimesById = Record<string, BeamtimeModel | undefined>;

export type MeasurementGroupModalWithOrg = MeasurementGroupModel & {
    organizationName: string;
    beamtimeName: string;
};

type Props = {
    selectedStatus?: MeasurementGroupStatusType;
    onSetStatusFilter: (status?: MeasurementGroupStatusType) => void;
    organizationsById?: OrganizationsById;
    beamtimesById?: BeamtimesById;
    measurementGroups: MeasurementGroupModel[];
    onRowClick: (measurementGroup: MeasurementGroupModel) => void;
    getNavParams: (measurementGroup: MeasurementGroupModel) => {
        to: string;
        from: string;
        params: {
            orgId: string;
            orderId: string;
        };
    };
};

type TableMeta = {
    getNavParams: (measurementGroup: MeasurementGroupModel) => {
        to: string;
        from: string;
        params: {
            orgId: string;
            orderId: string;
        };
    };
};

const columns: ColumnDef<MeasurementGroupModalWithOrg>[] = [
    {
        accessorKey: "name",
        header: "Name",
        thProps: makeWidth("300px"),
    },
    {
        accessorKey: "status",
        header: "Status",
        cell: (cell) => {
            const status = cell.row.original.status;
            return <GroupStatus status={status} />;
        },
        thProps: makeWidth("160px"),
    },
    {
        accessorKey: "organization",
        header: "Organization",
        accessorFn: (originalRow) => originalRow.organizationName,
        thProps: makeWidth("200px"),
    },
    {
        accessorKey: "beamtime",
        header: "Beamtime",
        accessorFn: (originalRow) => originalRow.beamtimeName,
    },
    {
        accessorKey: "hasReport",
        header: "Report",
        cell: (cell) => {
            const value = !!cell.getValue();
            return (
                <Checkbox
                    isReadOnly
                    colorScheme="green"
                    defaultChecked={value}
                />
            );
        },
        thProps: makeWidth("95px"),
    },
    {
        accessorKey: "quotationNumber",
        header: "Quotation",
        cell: (cell) => {
            const value = !!cell.getValue();
            return (
                <Checkbox
                    isReadOnly
                    colorScheme="green"
                    defaultChecked={value}
                />
            );
        },
        thProps: makeWidth("95px"),
    },
    {
        accessorKey: "createdAt" satisfies keyof MeasurementGroupModalWithOrg,
        header: "Created",
        cell: (cell) => {
            const value = cell.row.original.createdAt;
            return formatDate(value);
        },
        accessorFn: (originalRow) =>
            originalRow.createdAt ? formatDate(originalRow.createdAt) : "",
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "updatedAt" satisfies keyof MeasurementGroupModalWithOrg,
        header: "Updated",
        accessorFn: (originalRow) =>
            originalRow.updatedAt ? formatDate(originalRow.updatedAt) : "",
        cell: (cell) => {
            const value = cell.row.original.updatedAt;
            return formatDate(value);
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "menu",
        stopBubbling: true,
        header: " ",
        cell: (cell) => {
            const meta = cell.table.options.meta as TableMeta;

            const params = meta.getNavParams(cell.row.original);

            return (
                <Link {...params}>
                    <Tooltip label="Go to request">
                        <IconButton
                            colorScheme="gray"
                            size="xs"
                            aria-label="View request"
                            icon={<Icon.ChevronRight />}
                        />
                    </Tooltip>
                </Link>
            );
        },
    },
];

const initialState = {
    sorting: [
        {
            id: "updatedAt",
            desc: true,
        },
    ],
};

export const MeasurementGroupsTable: FC<Props> = ({
    measurementGroups,
    organizationsById,
    beamtimesById,
    getNavParams,
    onRowClick,
    selectedStatus,
    onSetStatusFilter,
}) => {
    const organizations = useMemo(
        () =>
            organizationsById
                ? Object.values(organizationsById).filter(isNotNil)
                : [],
        [organizationsById],
    );

    const beamtimes = useMemo(
        () =>
            beamtimesById ? Object.values(beamtimesById).filter(isNotNil) : [],
        [beamtimesById],
    );
    // const [statusFilter, setStatusFilter] = useState<
    //     MeasurementGroupStatusType | undefined
    // >(undefined);

    const [orgFilter, setOrgFilter] = useState<string | undefined>(undefined);

    const [beamtimeFilter, setBeamtimeFilter] = useState<string | undefined>(
        undefined,
    );

    const extendedRequests: MeasurementGroupModalWithOrg[] =
        measurementGroups.map((g) => {
            const orgName = organizationsById
                ? organizationsById[g.organizationId]?.name
                : "";

            const beamtimeName =
                beamtimesById && g.beamtimeId
                    ? beamtimesById[g.beamtimeId]?.name
                    : "";

            return {
                ...g,
                organizationName: orgName ?? "",
                beamtimeName: beamtimeName ?? "",
            };
        });

    const { searchTerm, filteredOrders, onClear, onChange } =
        useSearch<MeasurementGroupModalWithOrg>({
            items: extendedRequests,
            keys: ["name", "status"],
        });

    const filtered =
        selectedStatus || orgFilter || beamtimeFilter
            ? filteredOrders.filter(
                  ({ status, organizationId, beamtimeId }) => {
                      const result = [
                          selectedStatus ? status === selectedStatus : null,
                          orgFilter ? organizationId === orgFilter : null,
                          beamtimeFilter ? beamtimeId === beamtimeFilter : null,
                      ].filter(isNotNil);

                      return result.every(identity<boolean>);
                  },
              )
            : filteredOrders;

    return (
        <Box
            display="flex"
            flexDir="column"
            w="100%"
            h="100%"
            overflow="hidden"
        >
            <SearchFilterBar
                value={searchTerm}
                onReset={onClear}
                onChange={onChange}
                placeholder="Search by title or status"
                filter={
                    <>
                        <MeasurementGroupStatusFilter
                            value={selectedStatus}
                            onChange={onSetStatusFilter}
                        />
                        {organizationsById && (
                            <OrganizationFilter
                                value={orgFilter}
                                onChange={setOrgFilter}
                                options={organizations}
                            />
                        )}
                        {beamtimesById && (
                            <BeamtimeFilter
                                value={beamtimeFilter}
                                onChange={setBeamtimeFilter}
                                options={beamtimes}
                            />
                        )}
                    </>
                }
            />
            <Box flexGrow={1} overflow="auto" width="100%">
                {filtered.length ? (
                    <DataTable<MeasurementGroupModalWithOrg>
                        onRowClick={onRowClick}
                        variant="unstyled"
                        isSortable
                        initialState={initialState}
                        state={{
                            columnVisibility: {
                                organization: Boolean(organizationsById),
                                beamtime: Boolean(beamtimesById),
                            },
                        }}
                        columns={columns}
                        data={filtered}
                        meta={{ getNavParams }}
                    />
                ) : (
                    <NoSearchMatch searchTerm={searchTerm} />
                )}
            </Box>
        </Box>
    );
};
