// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, PropsWithChildren } from "react";
import React, { cloneElement, Component, useState } from "react";

import PropTypes from "prop-types";

import { Box } from "@chakra-ui/react";

import SidebarGroup from "./sidebar/SidebarGroup";

import sortMenu from "../lib/sortMenu";
import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

type PanelMenuWrapperContextProps = {
    setPanel: (group: string, panel: string) => void;
};

class PanelMenuWrapperContext extends Component<
    PropsWithChildren<PanelMenuWrapperContextProps>,
    any
> {
    getChildContext() {
        return {
            setPanel: this.props.setPanel,
        };
    }

    render() {
        return this.props.children;
    }
}

PanelMenuWrapperContext.childContextTypes = {
    setPanel: PropTypes.func,
};

type PanelOrderItem = { group: string; name: string };

type PanelsWithSidebarProps = PropsWithChildren<{
    menuPanelOrder?: PanelOrderItem[];
}>;

const computeMenuOptions = (props: PanelsWithSidebarProps) => {
    const { children, menuPanelOrder } = props;
    const sections = [];
    const groupLookup = {};
    let groupIndex;
    const childrenArray = sortMenu(
        React.Children.toArray(children),
        menuPanelOrder,
    );

    childrenArray.forEach((child) => {
        if (!child) {
            return;
        }
        const group = child.props.group;
        const name = child.props.name;

        if (group && name) {
            let obj;
            if (groupLookup.hasOwnProperty(group)) {
                groupIndex = groupLookup[group];
                obj = sections[groupIndex];
            } else {
                groupLookup[group] = sections.length;
                obj = { name: group, panels: [] };
                sections.push(obj);
            }
            obj.panels.push(name);
        }

        if ((child.type.plotly_editor_traits || {}).sidebar_element) {
            sections.push(child);
        }
    });

    return sections;
};

const PanelsWithSidebar: FC<PanelsWithSidebarProps> = (props) => {
    const opts = computeMenuOptions(props);
    const firstSidebarGroup = opts.filter((o) => o.panels)[0];

    const [state, setState] = useState({
        group: firstSidebarGroup.name,
        panel: firstSidebarGroup.panels[0],
    });

    const setPanel = (group: string, panel: string) => {
        setState({ group, panel });
    };

    const menuOpts = computeMenuOptions(props);

    return (
        <PanelMenuWrapperContext setPanel={setPanel}>
            <Box bg="white" display="flex" flexGrow={1} overflow="hidden">
                <Box
                    borderRight="solid 1px"
                    borderRightColor="gray.200"
                    height="100%"
                    minWidth="110px"
                    maxWidth="110px"
                    width="100%"
                    textAlign="center"
                    backgroundColor="white"
                    overflowY="auto"
                    overflowX="hidden"
                >
                    {menuOpts.map((section, i) => {
                        if (
                            section.type &&
                            (section.type.plotly_editor_traits || {})
                                .sidebar_element
                        ) {
                            return cloneElement(section, { key: i });
                        }
                        return (
                            <SidebarGroup
                                key={i}
                                selectedGroup={state.group}
                                selectedPanel={state.panel}
                                group={section.name}
                                panels={section.panels}
                                onChangeGroup={setPanel}
                            />
                        );
                    })}
                </Box>
                {React.Children.map(props.children, (child, i) =>
                    child === null ||
                    state.group !== child.props.group ||
                    state.panel !== child.props.name
                        ? null
                        : cloneElement(child, { key: i }),
                )}
            </Box>
        </PanelMenuWrapperContext>
    );
};

export default PanelsWithSidebar;
