import type { FC } from "react";

import { Box, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { ResultsPreview } from "@app-components/measurementGroup/SampleTaskSidebar/ResultsPreview.tsx";
import { SampleInfo } from "@app-components/sample/SampleInfo.tsx";

import { Actions } from "@mt-components/Layout/Actions.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Title } from "@mt-components/Layout/Title.tsx";
import { SidebarSection } from "@mt-components/Sidebar";

import { useScrollbar } from "@mt-hooks/useScrollbars.tsx";

import { Icon } from "@mt-design/icons.tsx";

type SampleTaskSidebarViewSingleProps = {
    canEdit?: boolean;
    canViewSample?: boolean;
    hideHazards?: boolean;
    sampleTask: SampleTasksModel;
    measurementGroup: MeasurementGroupModel;
    onSelectToEdit?: (sampleTask: SampleTasksModel) => void;
    goToSample?: (sampleId: string) => void;
    onOpenQRCodeReader?: (sampleTask: SampleTasksModel) => void;
};
export const SingleSelectionView: FC<SampleTaskSidebarViewSingleProps> = ({
    canEdit,
    canViewSample,
    hideHazards,
    sampleTask,
    measurementGroup,
    onSelectToEdit,
    onOpenQRCodeReader,
    goToSample,
}) => {
    const [ref] = useScrollbar();
    const xrdTaskId: string | undefined = sampleTask.tasks.hrxrpd?.taskId;
    const pdfTaskId: string | undefined = sampleTask.tasks.tspdf?.taskId;

    return (
        <>
            <SidebarSection flex="0 0 40px">
                <Title title={sampleTask.name} />
                <Actions
                    actions={[
                        {
                            id: "edit",
                            label: "Edit",
                            isDisabled: !canEdit,
                            isPrimary: true,
                            onClick: () => onSelectToEdit?.(sampleTask),
                        },
                        {
                            id: "view",
                            label: "View sample",
                            isDisabled: !canViewSample,
                            onClick: () => goToSample?.(sampleTask.id),
                        },
                        {
                            id: "set-qr-code",
                            label: (
                                <>
                                    <Icon.QRCode />
                                </>
                            ),
                            isHidden: !canEdit || !onOpenQRCodeReader,
                            onClick: () => onOpenQRCodeReader?.(sampleTask),
                        },
                    ]}
                />
            </SidebarSection>
            <Box overflowY="auto">
                <SidebarSection flex="1" overflow="hidden">
                    <Box ref={ref} h="100%" overflowY="auto">
                        <SampleInfo
                            hideHazards={hideHazards}
                            measurementGroup={measurementGroup}
                            sample={sampleTask}
                        />
                    </Box>
                </SidebarSection>

                {measurementGroup.status ===
                    MeasurementGroupStatus.Completed && (
                    <SidebarSection>
                        <Divider />
                        {xrdTaskId ? (
                            <Box my="8px">
                                <Text fontWeight="semibold" mb="8px">
                                    XRD
                                </Text>
                                <ResultsPreview
                                    taskId={xrdTaskId}
                                    orgId={measurementGroup.organizationId}
                                />
                            </Box>
                        ) : null}
                        {pdfTaskId ? (
                            <Box my="8px">
                                <Text fontWeight="semibold" mb="8px">
                                    TS-PDF
                                </Text>
                                <ResultsPreview
                                    taskId={pdfTaskId}
                                    orgId={measurementGroup.organizationId}
                                />
                            </Box>
                        ) : null}
                    </SidebarSection>
                )}
            </Box>
        </>
    );
};
