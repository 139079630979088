import type { FC } from "react";
import { useMemo } from "react";

import { Box } from "@chakra-ui/react";

import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";

import { toValueLabel } from "@mt-components/Input/Select/helpers.ts";
import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

import { getByValue } from "@mt-tools/iterating/filter.ts";

type BeamtimeFilterProps = {
    options: BeamtimeModel[];
    value?: string;
    onChange: (value?: string) => void;
};

export const BeamtimeFilter: FC<BeamtimeFilterProps> = ({
    options,
    value,
    onChange,
}) => {
    const options1 = useMemo(() => options.map(toValueLabel), [options]);

    const selected = useMemo(
        () => (value ? options1.find(getByValue(value)) : undefined),
        [value, options1],
    );

    return (
        <Box width="180px" maxW="180px">
            <SingleSelect
                isClearable
                onChange={(newValue) => onChange(newValue?.value ?? undefined)}
                options={options1}
                value={selected}
                placeholder="Filter by beamtime"
            />
        </Box>
    );
};
