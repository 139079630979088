import type { ComponentType, FC, ReactNode } from "react";
import { memo } from "react";

import type { ImageProps, ResponsiveObject } from "@chakra-ui/react";
import {
    Box,
    Card,
    CardBody,
    Center,
    Stat,
    StatLabel,
    StatNumber,
    Text,
    Tooltip,
    useBreakpoint,
    useDisclosure,
} from "@chakra-ui/react";
import type { LinkProps, RegisteredRouter } from "@tanstack/react-router";
import { Link } from "@tanstack/react-router";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import { MeasurementGroupStatus } from "@app/domain";
import * as Beamtime from "@app/domain/api/beamtime.ts";
import { Request } from "@app/domain/api/request.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { BeamtimesPanel } from "@app/pages/user/dashboard/panels.tsx";
import { routes } from "@app/Routes/routes.ts";
import { BeamtimeStatus } from "@app-components/beamtime/BeamtimeStatus.tsx";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { Footer } from "@mt-components/Layout/Sidebar/Footer.tsx";
import { Sidebar } from "@mt-components/Layout/Sidebar/Sidebar.tsx";
import { SidebarSection } from "@mt-components/Sidebar";

import { Icon } from "@mt-design/icons.tsx";

const Action: FC<{
    scale?: ResponsiveObject<number> | number;
    isDisabled?: boolean;
    Icon: ComponentType<ImageProps>;
    body: string;
    linkProps?: object; // @todo this is not type-safe
}> = ({ scale = 1, isDisabled, Icon, body, linkProps }) => {
    const breakpoint = useBreakpoint();

    const scaleValue =
        (typeof scale === "object" ? scale[breakpoint] : scale) ?? 1;

    const disabledStyles = isDisabled
        ? {
              opacity: 0.5,
              filter: "grayscale(0.9)",
          }
        : {};

    return (
        <Card
            minW={`${200 * scaleValue}px`}
            w={`${200 * scaleValue}px`}
            h={`${200 * scaleValue}px`}
            rounded="16px"
            bg="#FFF"
            border="solid 1px"
            borderColor="gray.300"
            borderRadius="8px"
            // shadow="0px 8px 16px 0px rgba(0, 0, 0, 0.12);"
        >
            <Tooltip label={body}>
                <CardBody
                    width="100%"
                    as={Link}
                    p={{ sm: "6px", md: "12px" }}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="10%"
                    aria-label={body}
                    disabled={isDisabled}
                    {...linkProps}
                >
                    <Icon
                        w={`${104 * scaleValue}px`}
                        h={`${104 * scaleValue}px`}
                        draggable={false}
                        {...disabledStyles}
                    />
                    <Center w="100%" overflow="hidden">
                        <Text
                            whiteSpace="nowrap"
                            display="block"
                            color="gray.600"
                            fontSize="14px"
                            overflow="hidden"
                            width="100%"
                            textAlign="center"
                            textOverflow="ellipsis"
                        >
                            {body}
                        </Text>
                    </Center>
                </CardBody>
            </Tooltip>
        </Card>
    );
};

const StatPanel = (props: {
    body: ReactNode;
    label: ReactNode;
    icon: ReactNode;
    tooltip?: string;
    onGoTo?: () => void;
}) => {
    return (
        <Tooltip openDelay={1500} label={props.tooltip}>
            <Box
                w="250px"
                h="90px"
                border="solid 1px"
                borderColor="gray.300"
                borderRadius="8px"
                px="2"
                py="8px"
                display="flex"
                flexWrap="nowrap"
                alignItems="flex-start"
                justifyContent="center"
            >
                <Box h="100%" p="4px">
                    <Center
                        w="32px"
                        h="32px"
                        border="solid 1px"
                        color="mtblue.700"
                        borderColor="gray.300"
                        borderRadius="8px"
                    >
                        {props.icon}
                    </Center>
                </Box>
                <Stat px="8px" position="relative">
                    <StatLabel>{props.label}</StatLabel>
                    <StatNumber display="inline-block" w="100%">
                        {props.body}
                    </StatNumber>

                    {props.onGoTo ? (
                        <Box
                            position="absolute"
                            bottom="0"
                            right="4px"
                            tabIndex={0}
                            cursor="pointer"
                            onClick={props.onGoTo}
                        >
                            <Icon.ArrowRight />
                        </Box>
                    ) : null}
                </Stat>
            </Box>
        </Tooltip>
    );
};

function apostrophize(name: string) {
    const lastChar = name[name.length - 1];

    return lastChar === "s" ? `${name}'` : `${name}'s`;
}

export const Dashboard: FC = memo(() => {
    const orgContext = useOrgContext();
    const sidebarDisclosure = useDisclosure({ defaultIsOpen: true });

    const beamtimesQuery = Beamtime.useGetAll();
    const submittedRequestsQuery = Request.useGetAllB({
        path: {
            organizationId: orgContext.currentOrg,
        },
        query: {
            filter: {
                status: MeasurementGroupStatus.Submitted,
            },
        },
    });

    const acceptedRequestsQuery = Request.useGetAllB({
        path: {
            organizationId: orgContext.currentOrg,
        },
        query: {
            filter: {
                status: MeasurementGroupStatus.Accepted,
            },
        },
    });
    const samples = Sample.useGetAll({
        params: {
            path: {
                organizationId: orgContext.currentOrg,
            },
        },
    });

    if (
        !samples.data ||
        !beamtimesQuery.data?.data ||
        !submittedRequestsQuery.data ||
        !acceptedRequestsQuery.data
    ) {
        return null;
    }

    const sampleProps = {
        to: routes.user.samples.url,
        search: {
            samples: [],
        },
        params: {
            orgId: orgContext.currentOrg,
        },
    } satisfies LinkProps<RegisteredRouter["routeTree"]>;

    const requestProps = {
        to: routes.user.measurementGroups.url,
        search: {
            samples: [],
        },
        params: {
            orgId: orgContext.currentOrg,
        },
    } satisfies LinkProps<RegisteredRouter["routeTree"]>;

    const acceptedRequests = acceptedRequestsQuery.data.items;

    return (
        <Box
            id="sample-details-main"
            mx="auto"
            display="flex"
            flexDirection="row"
            h="100%"
            overflow="hidden"
        >
            <PageLayout
                display="flex"
                flexDirection="row"
                id="center-box"
                overflowX="auto"
                flex={1}
                h="100%"
                overflow="hidden"
            >
                <Box
                    display="flex"
                    flexDir="column"
                    w="100%"
                    h="100%"
                    overflowY="hidden"
                    gap="24px"
                >
                    <PageHeader
                        actionsId="dashboard-page-header"
                        title={`${apostrophize(orgContext.currentOrgName)} Dashboard`}
                        subtitle="Here's an overview of your requests and samples"
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap="24px"
                        overflowY="auto"
                    >
                        <Box
                            flexShrink={0}
                            boxSizing="border-box"
                            overflow="hidden"
                            display="flex"
                            flexDirection="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            flexWrap="wrap"
                            gap={{ base: "12px", md: "12px", lg: "24px" }}
                        >
                            <Action
                                scale={0.85}
                                linkProps={sampleProps}
                                Icon={Icon.SamplesImage}
                                body="Add samples"
                            />

                            <Action
                                scale={0.85}
                                linkProps={requestProps}
                                Icon={Icon.MeasurementsImage}
                                body="Start measurements"
                            />

                            {/*<Action*/}
                            {/*    scale={0.75}*/}
                            {/*    isDisabled*/}
                            {/*    Icon={Icon.AnalyzeImage}*/}
                            {/*    body="Analyze data"*/}
                            {/*/>*/}
                        </Box>
                        {samples.data.length === 0 && (
                            <>
                                <Text
                                    textAlign="left"
                                    overflow="hidden"
                                    flex="0 0 20px"
                                    display="inline-block"
                                    color="gray.500"
                                    fontSize="14px"
                                    fontWeight="400"
                                    // textAlign="center"
                                    // maxW="800px"
                                    // w="100%"
                                >
                                    Tip: Begin by adding samples. You can easily
                                    start new measurements later by assigning
                                    your samples.
                                </Text>
                            </>
                        )}
                        {Boolean(
                            samples.data.length ||
                                submittedRequestsQuery.data.items.length ||
                                acceptedRequestsQuery.data.items.length,
                        ) && (
                            <Box display="flex" gap="24px" flexWrap="wrap">
                                <Link
                                    search={{ samples: [] }}
                                    to={routes.user.samples.url}
                                    params={{ orgId: orgContext.currentOrg }}
                                >
                                    <StatPanel
                                        icon={<Icon.Sample />}
                                        body={samples.data.length}
                                        label="Samples"
                                    />
                                </Link>
                                <Link
                                    to={routes.user.measurementGroups.url}
                                    params={{ orgId: orgContext.currentOrg }}
                                    search={{
                                        status: MeasurementGroupStatus.Submitted,
                                    }}
                                >
                                    <StatPanel
                                        icon={<Icon.Request />}
                                        body={
                                            submittedRequestsQuery.data.items
                                                .length
                                        }
                                        label="Pending requests"
                                        tooltip="These requests were not yet inspected by our staff."
                                    />
                                </Link>
                                <Link
                                    to={routes.user.measurementGroups.url}
                                    params={{ orgId: orgContext.currentOrg }}
                                    search={{
                                        status: MeasurementGroupStatus.Accepted,
                                    }}
                                >
                                    <StatPanel
                                        icon={<Icon.Request />}
                                        body={acceptedRequests.length}
                                        label="Accepted requests"
                                        tooltip="These requests were inspected by our staff and will be scheduled to the next measurement slots."
                                    />
                                </Link>
                            </Box>
                        )}
                    </Box>
                </Box>
            </PageLayout>
            <Sidebar
                isOpen={sidebarDisclosure.isOpen}
                boxShadow="none"
                borderLeft="solid 1px"
                borderColor="gray.200"
                height="100%"
                // pl={sidebarDisclosure.isOpen ? "12px" : 0}
                pl="12px"
                overflowY="auto"
            >
                {sidebarDisclosure.isOpen && (
                    <SidebarSection pr={0}>
                        <BeamtimesPanel
                            title="Upcoming Beamtimes"
                            beamtimes={beamtimesQuery.data.data.filter(
                                (beamtime) =>
                                    beamtime.status ===
                                    BeamtimeStatus.PUBLISHED,
                            )}
                        />
                    </SidebarSection>
                )}
                <Footer
                    flex="0 0 32px"
                    mt="auto !important"
                    isOpen={sidebarDisclosure.isOpen}
                    onToggle={sidebarDisclosure.onToggle}
                />
            </Sidebar>
        </Box>
    );
});

Dashboard.displayName = "Dashboard";
