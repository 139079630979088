import type { FC, ReactNode } from "react";
import { memo } from "react";
import { useLocalStorage } from "react-use";

import {
    Box,
    IconButton,
    Image,
    Select,
    Spacer,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useMatchRoute } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { useRemoteConfigContext } from "@app/contexts/ConfigContext/ConfigContext.tsx";
import {
    useOrgContext,
    useOrgContextRaw,
} from "@app/contexts/OrgContext/useOrgContext.ts";
import { useUserContext } from "@app/contexts/useUserContext.tsx";
import { routes } from "@app/Routes/routes.ts";

import { ToggleIcon } from "@mt-components/icon/ToggleIcon.tsx";
import {
    Sidebar as SaasUISidebar,
    SidebarSection,
} from "@mt-components/Sidebar";
import { NavGroup } from "@mt-components/Sidebar/NavGroup/NavGroup.tsx";
import { NavItem } from "@mt-components/Sidebar/NavGroup/NavItem.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { selfOrganizationsQueryKey } from "src/api/client";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";

import swirl from "@mt-assets/swirl_simple_brown.svg";

type NavItem = {
    name: string;
    href: string;
    icon: ReactNode;
    fuzzyMatch?: boolean;
    useOrgId?: boolean;
};

const settings: NavItem[] = [
    {
        name: "Organizations",
        href: routes.organizations.url,
        icon: <Icon.Organization />,
        fuzzyMatch: false,
    },
    {
        name: "Profile",
        href: routes.profile.url,
        icon: <Icon.Profile />,
        fuzzyMatch: false,
    },
    {
        name: "Support",
        href: routes.user.support.url,
        icon: <Icon.Info />,
        fuzzyMatch: false,
    },
];

const operations: NavItem[] = [
    {
        name: "Home",
        href: routes.user.home.url,
        icon: <Icon.Home />,
    },
    {
        name: "Samples",
        href: routes.user.samples.url,
        useOrgId: true,
        icon: <Icon.Sample />,
        fuzzyMatch: true,
    },
    {
        name: "Measurements",
        href: routes.user.measurementGroups.url,
        icon: <Icon.Request />,
        fuzzyMatch: true,
    },
    {
        name: "Plots",
        href: routes.user.plots.url,
        useOrgId: true,
        icon: <Icon.Charts />,
        fuzzyMatch: true,
    },
] as const;

const Group: FC<{
    showGroup?: boolean;
    title: string;
    items: NavItem[];
}> = ({ showGroup, title, items }) => {
    const matchRoute = useMatchRoute();
    const orgContext = useOrgContextRaw();

    if (!showGroup) {
        return (
            <>
                {items.map((item) => {
                    return (
                        <NavItem
                            size="md"
                            as={Link}
                            to={item.href}
                            params={{
                                orgId: orgContext.currentOrg,
                            }}
                            key={item.name}
                            icon={item.icon}
                            isActive={matchRoute({
                                to: item.href,
                                fuzzy: item.fuzzyMatch,
                            })}
                        >
                            {item.name}
                        </NavItem>
                    );
                })}
            </>
        );
    }

    return (
        <NavGroup title={title} isCollapsible={false}>
            {items.map((item) => {
                return (
                    <NavItem
                        size="md"
                        textStyle="T2L100"
                        as={Link}
                        to={item.href}
                        params={{
                            orgId: orgContext.currentOrg,
                        }}
                        key={item.name}
                        icon={item.icon}
                        isActive={matchRoute({
                            to: item.href,
                            fuzzy: item.fuzzyMatch,
                        })}
                    >
                        {item.name}
                    </NavItem>
                );
            })}
        </NavGroup>
    );
};

Group.displayName = "Group";

export const SidebarUser: FC = memo(() => {
    const api = useAPI();
    const organizationsQuery = useQuery({
        queryKey: selfOrganizationsQueryKey(),
        queryFn: () => api.publicClient.GET("/self/organizations"),
    });

    const remoteConfig = useRemoteConfigContext();
    const orgContext = useOrgContext();
    const [value, setValue] = useLocalStorage(
        LocalStorageKeys.mainSidebar,
        true,
    );
    const disclosure = {
        isOpen: Boolean(value),
        onOpen: () => {
            setValue(true);
        },
        onClose: () => {
            setValue(false);
        },
        onToggle: () => {
            setValue(!value);
        },
    };
    const user = useUserContext();

    return (
        <SaasUISidebar
            overflowY="auto"
            bg="gray.50"
            isOpen={disclosure.isOpen}
            toggleBreakpoint={false}
            colorScheme="white"
            borderRight="unset"
            // borderRightColor="gray.100"
            maxWidth="232px"
            minW="64px"
            variant={disclosure.isOpen ? "default" : "compact"}
            h="100%"
            display="flex"
            flexDirection="column"
        >
            <SidebarSection aria-label="Head">
                <Link
                    to={routes.user.home.url}
                    params={{ orgId: orgContext.currentOrg }}
                >
                    <Box
                        display="flex"
                        height="68px"
                        overflow="hidden"
                        alignItems="center"
                    >
                        <Image
                            pl={disclosure.isOpen ? "8px" : 0}
                            maxWidth={disclosure.isOpen ? "50px" : "40px"}
                            src={swirl}
                            alt="Momentum Transfer"
                        />
                        {disclosure.isOpen && (
                            <Text
                                as="span"
                                h="34px"
                                fontSize="18px"
                                display="inline-block"
                                fontWeight="500"
                                lineHeight="16px"
                                pl="12px"
                            >
                                Momentum Transfer
                            </Text>
                        )}
                    </Box>
                </Link>
            </SidebarSection>
            <SidebarSection aria-label="Organization">
                <Tooltip label={orgContext.currentOrgName}>
                    <Box>
                        <Text
                            color="gray.500"
                            fontSize="13"
                            pl="12px"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            m={0}
                        >
                            Organization
                        </Text>
                        {!disclosure.isOpen ? (
                            <Text
                                h="24px"
                                fontSize="20px"
                                fontWeight="600"
                                overflow="hidden"
                                textOverflow="clip"
                                whiteSpace="nowrap"
                            >
                                {orgContext.currentOrgName}
                            </Text>
                        ) : (
                            <Select
                                placeholder="Select Organization"
                                value={orgContext.currentOrg}
                                onChange={(e) => {
                                    orgContext.setCurrentOrg(e.target.value);
                                }}
                            >
                                {organizationsQuery.data?.data?.items.map(
                                    (org) => (
                                        <option key={org.id} value={org.id}>
                                            {org.name}
                                        </option>
                                    ),
                                )}
                            </Select>
                        )}
                    </Box>
                </Tooltip>
            </SidebarSection>
            <SidebarSection aria-label="Main">
                <Group
                    showGroup={disclosure.isOpen}
                    title="General"
                    items={operations.filter(
                        (o) =>
                            o.name !== "Plots" ||
                            Boolean(
                                remoteConfig.featureFlags?.plotting?.[user.id],
                            ),
                    )}
                />
                <Group
                    showGroup={disclosure.isOpen}
                    title="Settings"
                    items={settings}
                />
            </SidebarSection>
            <SidebarSection mt="auto !important" direction="row">
                <Spacer />
                <IconButton
                    onClick={disclosure.onToggle}
                    variant="ghost"
                    size="sm"
                    icon={<ToggleIcon showLeft={!disclosure.isOpen} />}
                    aria-label="Toggle Sidebar"
                />
            </SidebarSection>
        </SaasUISidebar>
    );
});

SidebarUser.displayName = "SidebarUser";
