import type { ChangeEvent, FC } from "react";
import { useMemo, useState } from "react";

import { equals, fromPairs, toPairs } from "ramda";

import type { BoxProps } from "@chakra-ui/react";
import {
    Box,
    Button,
    Center,
    chakra,
    Checkbox,
    SimpleGrid,
    Text,
    Tooltip,
} from "@chakra-ui/react";

import type { MeasurementMethod as MeasurementMethodType } from "@app/domain";
import { MeasurementMethod } from "@app/domain";
import type { MeasurementTaskModel } from "@app/domain/api/measurementTask.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import { StaticField } from "@mt-components/Input/StaticField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { messages } from "@mt-assets/messages.ts";

type ExistingTask = { checked: boolean; taskId: string; type: "existing" };

type NewTask = { checked: boolean; taskId: null; type: "new" };

type Props = {
    isOpen: boolean;
    orgId: string;
    requestId: string;
    sampleTasks: SampleTasksModel[];
    onClose: () => void;
};
type FormShape = {
    [k in MeasurementMethodType]: ExistingTask | NewTask;
} & {
    name: string;
};

const Indicator: FC<BoxProps> = (props) => (
    <Box
        position="absolute"
        top="-6px"
        right="44px"
        w="6px"
        h="6px"
        zIndex={2}
        borderRadius="100%"
        {...props}
    />
);

type State = { [k in string]: FormShape };

export const UpdateMeasurementTaskBatchModal: FC<Props> = ({
    isOpen,
    orgId,
    requestId,
    onClose,
    sampleTasks = [],
}) => {
    const snackbar = useSnackbar();
    const createTask = MeasurementTask.useCreate();
    const deleteTask = MeasurementTask.useDelete();

    const initialState: State = sampleTasks.reduce(
        (accumulator, currentValue): State => {
            const xrdTaskId = currentValue.tasks.hrxrpd?.taskId;
            const tspdfTaskId = currentValue.tasks.tspdf?.taskId;
            const saxsTaskId = currentValue.tasks.saxs?.taskId;
            return {
                ...accumulator,
                [currentValue.id]: {
                    name: currentValue.name,
                    [MeasurementMethod.Hrxrpd]: xrdTaskId
                        ? {
                              taskId: xrdTaskId,
                              checked: true,
                              type: "existing",
                          }
                        : {
                              taskId: null,
                              checked: false,
                              type: "new",
                          },
                    [MeasurementMethod.Tspdf]: tspdfTaskId
                        ? {
                              taskId: tspdfTaskId,
                              checked: true,
                              type: "existing",
                          }
                        : {
                              taskId: null,
                              checked: false,
                              type: "new",
                          },
                    [MeasurementMethod.Saxs]: saxsTaskId
                        ? {
                              taskId: saxsTaskId,
                              checked: true,
                              type: "existing",
                          }
                        : {
                              taskId: null,
                              checked: false,
                              type: "new",
                          },
                },
            };
        },
        {} as State,
    );

    const close = () => {
        setFormState(initialState);
        onClose();
    };

    const [formState, setFormState] = useState<State>(initialState);
    const didChange = !equals(initialState, formState);
    const reset = () => {
        setFormState(initialState);
    };

    const outcome = useMemo(() => {
        const tasksToCreate: {
            method: MeasurementMethodType;
            sampleId: string;
        }[] = [];
        const tasksToDelete: MeasurementTaskModel["id"][] = [];
        const methods = [
            MeasurementMethod.Hrxrpd,
            MeasurementMethod.Saxs,
            MeasurementMethod.Tspdf,
        ];

        toPairs(formState).forEach(([sampleId, tasksObj]) => {
            methods.forEach((method) => {
                const task = tasksObj[method];
                if (task.type === "new" && task.checked) {
                    tasksToCreate.push({
                        method,
                        sampleId,
                    });
                }

                if (task.type === "existing" && !task.checked) {
                    tasksToDelete.push(task.taskId);
                }
            });
        });

        return {
            tasksToCreate,
            tasksToDelete,
        };
    }, [formState]);

    const submit = async () => {
        const createdSuccess: {
            sampleId: string;
            method: MeasurementMethodType;
        }[] = [];
        const createdFailure: {
            sampleId: string;
            method: MeasurementMethodType;
        }[] = [];

        const deletedSuccess: string[] = [];
        const deletedFailure: string[] = [];

        for (const task of outcome.tasksToCreate) {
            await createTask
                .mutateAsync({
                    params: {
                        path: {
                            organizationId: orgId,
                        },
                    },
                    payload: {
                        method: task.method,
                        sampleId: task.sampleId,
                        measurementGroupId: requestId,
                    },
                })
                .then((response) => {
                    if (response.response.ok) {
                        createdSuccess.push(task);
                    }

                    if (!response.response.ok) {
                        createdFailure.push(task);
                    }
                });
        }

        for (const taskId of outcome.tasksToDelete) {
            await deleteTask
                .mutateAsync({
                    organizationId: orgId,
                    measurementTaskId: taskId,
                })
                .then((response) => {
                    if (response.response.ok) {
                        deletedSuccess.push(taskId);
                    }

                    if (!response.response.ok) {
                        deletedFailure.push(taskId);
                    }
                });
        }

        if (createdSuccess.length) {
            snackbar.success(
                `${createdSuccess.length} ${createdSuccess.length === 1 ? "task" : "tasks"} created`,
            );
        }
        if (createdFailure.length) {
            snackbar.error(
                `${createdFailure.length} ${createdFailure.length === 1 ? "task" : "tasks"} could not be created`,
            );
        }

        if (deletedSuccess.length) {
            snackbar.success(
                `${deletedSuccess.length}  ${deletedSuccess.length === 1 ? "task" : "tasks"} deleted`,
            );
        }

        if (deletedFailure.length) {
            snackbar.error(
                `${deletedFailure.length} ${deletedFailure.length === 1 ? "task" : " tasks"} could not be deleted`,
            );
        }
    };

    const onChangeMethod =
        (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
            const method = e.target.value as MeasurementMethodType;
            setFormState((prev): State => {
                return {
                    ...prev,
                    [id]: {
                        ...prev[id],
                        [method]: {
                            ...prev[id][method],
                            checked: e.target.checked,
                        },
                    },
                };
            });
        };

    const entries = Object.entries(formState);

    const onSetMethodForAll = (
        value: boolean,
        method: MeasurementMethodType,
    ) => {
        setFormState((prev) => {
            const entries = Object.entries(prev);

            const newEntries = entries.map(
                ([id, formData]): [string, FormShape] => {
                    return [
                        id,
                        {
                            ...formData,
                            [method]: {
                                ...formData[method],
                                checked: value,
                            },
                        },
                    ];
                },
            );

            return fromPairs(newEntries);
        });
    };

    const onChangeAllCheckbox =
        (method: MeasurementMethodType) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked;
            onSetMethodForAll(value, method);
        };

    const areAllSelectedForTSPDF = entries.every(
        ([, form]) => form.tspdf.checked,
    );
    const areAllSelectedForXRD = entries.every(
        ([, form]) => form.hrxrpd.checked,
    );
    const areAllSelectedForSAXS = entries.every(
        ([, form]) => form.saxs.checked,
    );

    const areSomeSelectedForXRD = entries.some(
        ([, form]) => form.hrxrpd.checked,
    );
    const areSomeSelectedForPDF = entries.some(
        ([, form]) => form.tspdf.checked,
    );
    const areSomeSelectedForSAXS = entries.some(
        ([, form]) => form.saxs.checked,
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={close}
                onOverlayClick={close}
                header="Batch edit measurement tasks"
                buttonLeft={
                    <>
                        <Button
                            isDisabled={!didChange}
                            colorScheme="gray"
                            onClick={reset}
                        >
                            Reset
                        </Button>
                        <Button colorScheme="gray" onClick={close}>
                            Cancel
                        </Button>
                    </>
                }
                buttonRight={
                    <Button
                        isDisabled={
                            !(
                                areSomeSelectedForPDF ||
                                areSomeSelectedForXRD ||
                                areSomeSelectedForSAXS
                            )
                        }
                        colorScheme="blue"
                        onClick={() => void submit()}
                    >
                        Confirm
                    </Button>
                }
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    fontSize="14px"
                    py="2px"
                    px="2px"
                    overflow="visible"
                    zIndex={30}
                    gap="12px"
                >
                    <Text
                        fontSize="14px"
                        display="block"
                        flex=" 0 0 48px"
                        overflow="hidden"
                    >
                        Checking and unchecking methods on samples will add or
                        remove measurement tasks.
                    </Text>

                    <Text>
                        You can revert your changes through the &#34;reset&#34;
                        button below.
                    </Text>

                    <StaticField
                        title="Legend"
                        value={
                            <Box>
                                <Text as="span" position="relative">
                                    Fields with an{" "}
                                    <chakra.span
                                        position="relative"
                                        fontWeight="600"
                                    >
                                        indicator{" "}
                                        <Indicator
                                            top={0}
                                            right="0"
                                            backgroundColor="green.600"
                                        />{" "}
                                    </chakra.span>
                                    denote already defined measurements, while
                                    not defined measurements are emphasized by
                                    through the following
                                    {"  "}
                                    <chakra.span
                                        position="relative"
                                        fontWeight="600"
                                    >
                                        indicator
                                        <Indicator
                                            top={0}
                                            right="0"
                                            backgroundColor="orange"
                                        />{" "}
                                    </chakra.span>
                                </Text>
                            </Box>
                        }
                    />

                    <Box>
                        <Box
                            display="flex"
                            mt="24px"
                            mb="8px"
                            flex="0 0 84px"
                            justifyContent="space-between"
                            pb="20px"
                            overflow="hidden"
                            borderBottom="solid 1px lightgray"
                        >
                            <Center justifyContent="unset" flex="0 0 120px">
                                <Text display="block" fontSize="14px">
                                    Sample-ID
                                </Text>
                            </Center>
                            <Center flex="0 0 120px">
                                <Checkbox
                                    colorScheme="green"
                                    size="lg"
                                    isIndeterminate={
                                        areSomeSelectedForXRD &&
                                        !areAllSelectedForXRD
                                    }
                                    isChecked={areAllSelectedForXRD}
                                    value={MeasurementMethod.Hrxrpd}
                                    onChange={onChangeAllCheckbox(
                                        MeasurementMethod.Hrxrpd,
                                    )}
                                />

                                <Button
                                    fontSize="14px"
                                    variant="ghost"
                                    textDecor="underline"
                                    colorScheme="secondary"
                                    onClick={() =>
                                        onSetMethodForAll(
                                            !areAllSelectedForXRD,
                                            MeasurementMethod.Hrxrpd,
                                        )
                                    }
                                >
                                    HR-XRPD
                                </Button>
                                <Tooltip label={messages.xrdExplainer}>
                                    <Icon.Info size="14px" />
                                </Tooltip>
                            </Center>
                            <Center flex="0 0 120px">
                                <Checkbox
                                    colorScheme="green"
                                    size="lg"
                                    isIndeterminate={
                                        areSomeSelectedForPDF &&
                                        !areAllSelectedForTSPDF
                                    }
                                    isChecked={areAllSelectedForTSPDF}
                                    onChange={onChangeAllCheckbox(
                                        MeasurementMethod.Tspdf,
                                    )}
                                    value={MeasurementMethod.Tspdf}
                                />

                                <Button
                                    fontSize="14px"
                                    colorScheme="secondary"
                                    variant="ghost"
                                    textDecor="underline"
                                    onClick={() =>
                                        onSetMethodForAll(
                                            !areAllSelectedForTSPDF,
                                            MeasurementMethod.Tspdf,
                                        )
                                    }
                                >
                                    TS-PDF
                                </Button>
                                <Tooltip label={messages.tsPdfExplainer}>
                                    <Icon.Info size="14px" />
                                </Tooltip>
                            </Center>
                            <Center flex="0 0 120px">
                                <Checkbox
                                    colorScheme="green"
                                    size="lg"
                                    value={MeasurementMethod.Saxs}
                                    isIndeterminate={
                                        areSomeSelectedForSAXS &&
                                        !areAllSelectedForSAXS
                                    }
                                    isChecked={areAllSelectedForSAXS}
                                    onChange={onChangeAllCheckbox(
                                        MeasurementMethod.Saxs,
                                    )}
                                />

                                <Button
                                    fontSize="14px"
                                    colorScheme="secondary"
                                    variant="ghost"
                                    textDecor="underline"
                                    onClick={() =>
                                        onSetMethodForAll(
                                            !areAllSelectedForSAXS,
                                            MeasurementMethod.Saxs,
                                        )
                                    }
                                >
                                    SAXS
                                </Button>
                                <Tooltip label={messages.saxsExplainer}>
                                    <Icon.Info size="14px" />
                                </Tooltip>
                            </Center>
                        </Box>
                        <Box
                            overflow="hidden"
                            display="flex"
                            flexDir="column"
                            height="100%"
                        >
                            {entries.map(([id, form], idx) => {
                                return (
                                    <Box
                                        key={id}
                                        justifyContent="space-between"
                                        display="flex"
                                        height="100%"
                                        flex="0 0 53px"
                                        alignItems="center"
                                        borderBottom={
                                            entries.length - 1 !== idx
                                                ? "1px solid lightgray"
                                                : undefined
                                        }
                                    >
                                        <Box
                                            flex=" 0 0 120px"
                                            overflow="hidden"
                                            whiteSpace="nowrap"
                                        >
                                            <Text>{form.name}</Text>
                                        </Box>
                                        <Center
                                            flex=" 0 0 120px"
                                            position="relative"
                                        >
                                            <Indicator
                                                backgroundColor={
                                                    initialState[id][
                                                        MeasurementMethod.Hrxrpd
                                                    ].checked
                                                        ? "green.600"
                                                        : "orange"
                                                }
                                            />
                                            <Checkbox
                                                colorScheme="green"
                                                onChange={onChangeMethod(id)}
                                                size="lg"
                                                value={MeasurementMethod.Hrxrpd}
                                                isChecked={
                                                    form[
                                                        MeasurementMethod.Hrxrpd
                                                    ].checked
                                                }
                                            />
                                        </Center>
                                        <Center
                                            flex=" 0 0 120px"
                                            position="relative"
                                        >
                                            <Indicator
                                                backgroundColor={
                                                    initialState[id][
                                                        MeasurementMethod.Tspdf
                                                    ].checked
                                                        ? "green.600"
                                                        : "orange"
                                                }
                                            />
                                            <Checkbox
                                                colorScheme="green"
                                                onChange={onChangeMethod(id)}
                                                size="lg"
                                                value={MeasurementMethod.Tspdf}
                                                isChecked={
                                                    form[
                                                        MeasurementMethod.Tspdf
                                                    ].checked
                                                }
                                            />
                                        </Center>
                                        <Center
                                            flex=" 0 0 120px"
                                            position="relative"
                                        >
                                            <Indicator
                                                backgroundColor={
                                                    initialState[id][
                                                        MeasurementMethod.Saxs
                                                    ].checked
                                                        ? "green.600"
                                                        : "orange"
                                                }
                                            />
                                            <Checkbox
                                                colorScheme="green"
                                                onChange={onChangeMethod(id)}
                                                size="lg"
                                                value={MeasurementMethod.Saxs}
                                                isChecked={
                                                    form[MeasurementMethod.Saxs]
                                                        .checked
                                                }
                                            />
                                        </Center>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    <Box>
                        <StaticField
                            title="Result"
                            value={
                                <SimpleGrid columns={2}>
                                    <Box>Add</Box>
                                    <Box>{outcome.tasksToCreate.length}</Box>

                                    <Box>Remove</Box>
                                    <Box>{outcome.tasksToDelete.length}</Box>
                                </SimpleGrid>
                            }
                        />
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
