import type { Promisable } from "type-fest";

import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    primaryDisabled: boolean | undefined;
    body: string;
    buttonLabel: string;
    onClick: () => Promisable<unknown>;
};
export const MeasurementTasksEmptyState = ({
    primaryDisabled,
    buttonLabel,
    body,
    onClick,
}: Props) => (
    <EmptyState
        isPrimaryDisabled={primaryDisabled}
        icon={() => <Icon.SamplesImage />}
        heading="No Samples"
        body={body}
        onClick={onClick}
        buttonLabel={buttonLabel}
    />
);
