import type { PropsWithChildren } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, forwardRef } from "@chakra-ui/react";

export const PageLayout = forwardRef(
    ({ children, ...rest }: PropsWithChildren<BoxProps>, ref) => {
        return (
            <Box
                ref={ref}
                h="100%"
                w="100%"
                pt="24px"
                px="32px"
                overflow="hidden"
                {...rest}
            >
                {children}
            </Box>
        );
    },
);
