import * as React from "react";

import type { HTMLChakraProps, ThemingProps } from "@chakra-ui/react";
import { chakra, Collapse, useMultiStyleConfig } from "@chakra-ui/react";
import type { MaybeRenderProp } from "@chakra-ui/react-utils";
import { createContext } from "@chakra-ui/react-utils";
import { cx, runIfFn } from "@chakra-ui/utils";

import type { UseCollapseReturn } from "@mt-components/Sidebar/NavGroup/useCollapse.tsx";
import { useCollapse } from "@mt-components/Sidebar/NavGroup/useCollapse.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { NavGroupStylesProvider, useNavGroupStyles } from "./NavContext.tsx";

const [CollapseProvider, useCollapseContext] = createContext<UseCollapseReturn>(
    {
        name: "UseCollapseReturn",
    },
);

export interface NavGroupTitleProps extends HTMLChakraProps<"div"> {
    leftIcon?: React.ReactElement;
    collapseIcon?: MaybeRenderProp<{ isOpen: boolean }>;
    isCollapsible?: boolean;
}

const NavGroupIcon: React.FC<HTMLChakraProps<"span">> = (props) => {
    const { children, className, ...rest } = props;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _children = React.isValidElement<any>(children)
        ? React.cloneElement(children, {
              "aria-hidden": true,
              "focusable": false,
          })
        : children;

    const _className = cx("sui-nav-group__icon", className);

    return (
        <chakra.span
            display="inline-flex"
            alignSelf="center"
            flexShrink={0}
            {...rest}
            className={_className}
        >
            {_children}
        </chakra.span>
    );
};

export const NavGroupTitle: React.FC<NavGroupTitleProps> = (props) => {
    const {
        leftIcon,
        collapseIcon = ({ isOpen }) =>
            isOpen ? <Icon.ChevronDown /> : <Icon.ChevronRight />,
        children,
        ...rest
    } = props;
    const styles = useNavGroupStyles();

    const { getToggleProps, isOpen, isCollapsible } = useCollapseContext();

    const iconStyles = { marginEnd: 2, ...styles.icon };

    const toggleProps = getToggleProps(rest);

    return (
        <chakra.div
            {...toggleProps}
            __css={styles.title}
            className={cx(
                "saas-nav-group__title",
                props.className,
                toggleProps.className,
            )}
        >
            {leftIcon && (
                <NavGroupIcon __css={iconStyles}>{leftIcon}</NavGroupIcon>
            )}
            <chakra.span flex="1">{runIfFn(children, { isOpen })}</chakra.span>
            {isCollapsible && (
                <NavGroupIcon>{runIfFn(collapseIcon, { isOpen })}</NavGroupIcon>
            )}
        </chakra.div>
    );
};

NavGroupTitle.displayName = "NavGroupTitle";

export interface NavGroupProps
    extends Omit<HTMLChakraProps<"div">, "title">,
        ThemingProps<"SuiNavGroup"> {
    title?: React.ReactNode;
    isCollapsible?: boolean;
    defaultIsOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    icon?: React.ReactElement;
}

export const NavGroupContent: React.FC<HTMLChakraProps<"div">> = (props) => {
    const styles = useNavGroupStyles();
    return (
        <chakra.div
            {...props}
            __css={styles.content}
            className={cx("sui-nav-group__content", props.className)}
        />
    );
};

/**
 * Navigation group containing nav items, used in Sidebar.
 *
 * @see Docs https://saas-ui.dev/docs/components/layout/sidebar
 */
export const NavGroup: React.FC<NavGroupProps> = (props) => {
    const {
        title,
        icon,
        isCollapsible = false,
        defaultIsOpen = true,
        children,
        ...rest
    } = props;
    const styles = useMultiStyleConfig("SuiNavGroup", props);

    const collapse = useCollapse({
        ...props,
        isCollapsible,
        defaultIsOpen,
    });
    const { getCollapseProps } = collapse;

    let header = title;
    if (typeof title === "string") {
        header = <NavGroupTitle leftIcon={icon}>{title}</NavGroupTitle>;
    }

    let content = <NavGroupContent>{children}</NavGroupContent>;

    if (isCollapsible) {
        content = <Collapse {...getCollapseProps()}>{content}</Collapse>;
    }

    return (
        <NavGroupStylesProvider value={styles}>
            <CollapseProvider value={collapse}>
                <chakra.div
                    __css={{
                        userSelect: "none",
                        ...styles.container,
                    }}
                    {...rest}
                    className={cx("sui-nav-group", props.className)}
                    role="group"
                >
                    {header}
                    {content}
                </chakra.div>
            </CollapseProvider>
        </NavGroupStylesProvider>
    );
};

NavGroup.displayName = "NavGroup";
