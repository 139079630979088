import type { FC } from "react";

import { Link } from "@chakra-ui/react";
import { interpolatePath, useNavigate } from "@tanstack/react-router";

import { useRoleContext } from "../contexts/RoleContext";
import { Role } from "../domain/auth";
import { routes } from "../Routes/routes";

export type OrganizationLinkProps = {
    organizationId: string;
    label: string;
};

export const OrganizationLink: FC<OrganizationLinkProps> = (props) => {
    const roleCtx = useRoleContext();
    const navigate = useNavigate();

    const appUrl =
        roleCtx.role === Role.AGENT
            ? routes.agent.organization.url
            : routes.organizationDetails.url;

    return (
        <Link
            href={interpolatePath(appUrl, {
                organizationId: props.organizationId,
                // FIXME: fix orgId vs organizationId path parameters.
                orgId: props.organizationId,
            })}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate({
                    to: appUrl,
                    params: {
                        organizationId: props.organizationId,
                        // FIXME: fix orgId vs organizationId path parameters.
                        orgId: props.organizationId,
                    },
                });
            }}
        >
            {props.label}
        </Link>
    );
};
