import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import type { components, operations } from "@app/domain/api/types/v1";

type BeamtimeStatusType = components["schemas"]["BeamtimeStatus"];
type BeamtimeModel = components["schemas"]["Beamtime"];

type GetAdminBeamtimeRequest =
    operations["getAdminBeamtime"]["parameters"]["path"];

type PatchBeamtimePayload =
    operations["patchAdminBeamtime"]["requestBody"]["content"]["application/json"];

type PatchAdminBeamtimeRequest = {
    id: string;
    data: PatchBeamtimePayload;
};

type CreateBeamtimePayload =
    operations["createAdminBeamtime"]["requestBody"]["content"]["application/json"];

type CreateAdminBeamtimeRequest = CreateBeamtimePayload;

export type {
    BeamtimeModel,
    BeamtimeStatusType,
    CreateAdminBeamtimeRequest,
    CreateBeamtimePayload,
    GetAdminBeamtimeRequest,
    PatchAdminBeamtimeRequest,
    PatchBeamtimePayload,
};

const useAdminGetAll = () => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.beamtime],
        queryFn: async () => await api.client.GET("/admin/beamtimes"),
    });
};

const useAdminGet = (params: GetAdminBeamtimeRequest) => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.beamtime, params.beamtimeId],
        queryFn: async () => {
            if (!params.beamtimeId) {
                return null;
            }
            return await api.client.GET("/admin/beamtimes/{beamtimeId}", {
                params: {
                    path: {
                        beamtimeId: params.beamtimeId,
                    },
                },
            });
        },
    });
};

const useAdminCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateAdminBeamtimeRequest) => {
            const r = await api.client.POST("/admin/beamtimes", {
                body: data,
            });
            await client.invalidateQueries({
                queryKey: [QueryKeys.beamtime],
            });
            return r;
        },
    });
};

const useAdminUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: PatchAdminBeamtimeRequest) => {
            await api.client.PATCH("/admin/beamtimes/{beamtimeId}", {
                params: {
                    path: {
                        beamtimeId: data.id,
                    },
                },
                body: data.data,
            });

            await client.invalidateQueries({
                queryKey: [QueryKeys.beamtime],
            });
            return;
        },
    });
};

const useAdminDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (id: string) => {
            await api.client.DELETE("/admin/beamtimes/{beamtimeId}", {
                params: {
                    path: {
                        beamtimeId: id,
                    },
                },
            });
            return;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [QueryKeys.beamtime],
            });
        },
    });
};

export const Beamtime = {
    useAdminGetAll,
    useAdminGet,
    useAdminCreate,
    useAdminUpdate,
    useAdminDelete,
};
