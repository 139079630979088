import type { FC } from "react";
import { useState } from "react";

import type { ValueOf } from "type-fest";

import { Button, chakra, Stack, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import * as Beamtime from "@app/domain/api/beamtime.ts";
import { Request } from "@app/domain/api/request.ts";
import { formatLabel } from "@app/domain/form";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { SelectField } from "@mt-components/Input/SelectField.tsx";
import { TextField } from "@mt-components/Input/TextField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

type SubmitRequestModalProps = {
    isOpen: boolean;
    onClose: () => void;
    request: MeasurementGroupModel;
};

const QuotationOption = {
    NONE: "none" as const,
    NEED: "need" as const,
    HAVE: "have" as const,
};

type QuotationOptionType = ValueOf<typeof QuotationOption>;

type QuotationState = {
    option: QuotationOptionType;
    number: string | null;
};

function computeValidity(
    quotationOption: QuotationState["option"],
    quotationNumber: QuotationState["number"],
) {
    if (quotationOption === QuotationOption.NONE) {
        return false;
    }

    if (quotationOption === QuotationOption.HAVE) {
        return (
            typeof quotationNumber === "string" && quotationNumber.length >= 5
        );
    }

    return true;
}

const toOption = (item: { id: string; name: string; date?: string }) => {
    const date = item.date ? formatDate(item.date) : "No date";
    return {
        value: item.id,
        label: `${date} - ${item.name}`,
    };
};

export const SubmitRequestModal: FC<SubmitRequestModalProps> = (props) => {
    const snack = useSnackbar();

    const [message, setMessage] = useState("");
    const [quotationState, setQuotationState] = useState<QuotationState>({
        option: QuotationOption.NONE,
        number: null,
    });

    const [selectedBeamtime, setSelectedBeamtime] = useState<null | string>(
        null,
    );

    const beamtimeQuery = Beamtime.useGetAll();
    const { mutateAsync: updateMeasurementGroup, isPending } =
        Request.useUpdate();

    const onSubmit = async () => {
        await updateMeasurementGroup({
            organizationId: props.request.organizationId,
            measurementGroupId: props.request.id,
            patchMeasurementGroupPayload: {
                status: MeasurementGroupStatus.Submitted,
                message,
                quotationNumber: quotationState.number,
                preferredBeamtimeId: selectedBeamtime ?? undefined,
            },
        }).then(() => {
            props.onClose();
            snack.success({
                title: "Measurement request submitted",
                description: "We'll be getting back to you shortly.",
            });
        });
    };

    const onCancel = () => {
        props.onClose();
        setMessage("");
    };

    const isValid = computeValidity(
        quotationState.option,
        quotationState.number,
    );

    if (!beamtimeQuery.data?.data) {
        return null;
    }

    const beamtimeOptions = beamtimeQuery.data.data
        .filter((b) => b.status === "published")
        .map(toOption);
    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            header="Submit request"
            buttonRight={
                <LoadingButton
                    onClick={onSubmit}
                    colorScheme="blue"
                    isDisabled={isPending || !isValid}
                >
                    Submit
                </LoadingButton>
            }
            buttonLeft={
                <Button
                    onClick={onCancel}
                    colorScheme="gray"
                    isDisabled={isPending}
                >
                    Cancel
                </Button>
            }
        >
            <Text fontSize="14px">
                After submission, you will not be able to make edits to your
                measurement request.
                <br />
                <br />
                Once we receive your request, our staff will reach out to you
                about the next steps.
                <br />
                <br />
                Are you all set?
            </Text>
            <Stack direction="column" mt="12px" gap="24px">
                <SelectField
                    formControlProps={{
                        px: 0,
                    }}
                    label="Choose your preferred beamtime and date"
                    value={selectedBeamtime ?? ""}
                    onChange={(e) => {
                        const value = e.target.value;
                        setSelectedBeamtime(value);
                    }}
                    options={[
                        {
                            label: "No preference",
                            value: "none",
                        },
                        ...beamtimeOptions,
                    ]}
                ></SelectField>

                <SelectField
                    formControlProps={{
                        px: 0,
                    }}
                    help={
                        <>
                            Reach out to our{" "}
                            <chakra.a
                                fontWeight="700"
                                href="mailto:support@momentum-tranfer.com"
                            >
                                support
                            </chakra.a>{" "}
                            if you don&apos;t have a quotation, yet
                        </>
                    }
                    label={formatLabel(
                        {
                            label: "Quotation",
                        },
                        "If you do not already have a quotation, please contact us to set it up",
                    )}
                    value={quotationState.option}
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value === QuotationOption.NONE) {
                            return;
                        }
                        setQuotationState((prev): QuotationState => {
                            return {
                                ...prev,
                                option: e.target.value as QuotationOptionType,
                            };
                        });
                    }}
                    options={[
                        {
                            label: "",
                            value: QuotationOption.NONE,
                        },
                        {
                            label: "I have a quotation",
                            value: QuotationOption.HAVE,
                        },
                        {
                            label: "I need a quotation",
                            value: QuotationOption.NEED,
                        },
                    ]}
                ></SelectField>

                <InputField
                    maxLength={20}
                    minLength={6}
                    placeholder="QUOTE-MT-YYYY-XXX"
                    formControlProps={{
                        px: 0,
                        display:
                            quotationState.option === QuotationOption.HAVE
                                ? "block"
                                : "none",
                    }}
                    label={formatLabel(
                        { label: "Quotation number" },
                        "You would have received a quotation number from our support team",
                    )}
                    value={quotationState.number ?? ""}
                    onChange={(e) => {
                        setQuotationState((prev) => {
                            return { ...prev, number: e.target.value };
                        });
                    }}
                />
                <TextField
                    maxLength={360}
                    help="360 characters at most"
                    formControlProps={{
                        px: 0,
                    }}
                    label={formatLabel(
                        {
                            label: "Want to leave a message?",
                        },
                        "Please let us know if any of your samples require special handling considerations or any other notes or requests in relation to your measurements.",
                    )}
                    onChange={(e) => {
                        setMessage(e.target.value);
                    }}
                    value={message}
                />
            </Stack>
        </Modal>
    );
};
