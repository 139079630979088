// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
// @ts-nocheck
import type { FC } from "react";

import { useEditorControlsContext } from "./EditorControls.tsx";

import PanelMenuWrapper from "./components/PanelMenuWrapper";
import {
    GraphCreatePanel,
    StyleAxesPanel,
    StyleLayoutPanel,
    StyleLegendPanel,
    StyleTracesPanel,
} from "./default_panels";

type PanelOrderItem = { group: string; name: string };

type Props = {
    menuPanelOrder?: PanelOrderItem[];
};

type EditorBaseProps = Props;

const DefaultEditorBase: FC<EditorBaseProps> = (props) => {
    const ctx = useEditorControlsContext();
    // const hasTransforms = () => {
    //     return ctx.fullData.some((d) => TRANSFORMABLE_TRACES.includes(d.type));
    // };

    const hasAxes = () => {
        return (
            Object.keys(ctx.fullLayout._subplots).filter(
                (type) =>
                    !["cartesian", "mapbox"].includes(type) &&
                    ctx.fullLayout._subplots[type].length > 0,
            ).length > 0
        );
    };

    // const hasMenus = () => {
    //     const {
    //         fullLayout: { updatemenus = [] },
    //     } = props.context;
    //
    //     return updatemenus.length > 0;
    // };

    // const hasSliders = () => {
    //     const {
    //         layout: { sliders = [] },
    //     } = props.context;
    //
    //     return sliders.length > 0;
    // };

    // const hasColorbars = () => {
    //     return props.context.fullData.some((d) => traceHasColorbar({}, d));
    // };

    const hasLegend = () => {
        return ctx.fullData.some((t) => t.showlegend !== undefined);
    };

    // const hasMaps = () => {
    //     return props.context.fullData.some((d) =>
    //         [...TRACE_TO_AXIS.geo, ...TRACE_TO_AXIS.mapbox].includes(d.type),
    //     );
    // };

    const { menuPanelOrder } = props;

    return (
        <PanelMenuWrapper menuPanelOrder={menuPanelOrder}>
            {/*<DataPanel group="Data" name="Data" />*/}
            <GraphCreatePanel group="Structure" name="Traces" />
            {/*<GraphSubplotsPanel group={'Structure'} name={'Subplots'} />*/}
            {/*{hasTransforms() && (*/}
            {/*  <GraphTransformsPanel group={'Structure'} name={'Transforms'} />*/}
            {/*)}*/}
            <StyleLayoutPanel group="Style" name="General" collapsedOnStart />
            <StyleTracesPanel group="Style" name="Traces" />
            {hasAxes() && (
                <StyleAxesPanel group="Style" name="Axes" collapsedOnStart />
            )}
            {/*{hasMaps() && <StyleMapsPanel group={'Style'} name={'Maps'} />}*/}
            {hasLegend() && <StyleLegendPanel group="Style" name="Legend" />}
            {/*<StyleNotesPanel group={'Annotate'} name={'Text'} />*/}
            {/*<StyleShapesPanel group={'Annotate'} name={'Shapes'} />*/}
            {/*<StyleImagesPanel group={'Annotate'} name={'Images'} />*/}
            {/*{hasSliders() && <StyleSlidersPanel group={'Control'} name={'Sliders'} />}*/}
            {/*{hasMenus() && <StyleUpdateMenusPanel group={'Control'} name={'Menus'} />}*/}
            {/*{children || null}*/}
        </PanelMenuWrapper>
    );
};

DefaultEditorBase.displayName = "DefaultEditorBase";

export default DefaultEditorBase;
