import type { FC } from "react";
import { useMemo } from "react";

import { Box } from "@chakra-ui/react";

import type { SampleModel } from "@app/domain/api/sample.ts";
import { formOptions } from "@app/domain/services/sample/utils.ts";

import { toValueLabel } from "@mt-components/Input/Select/helpers.ts";
import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

import { getByValue } from "@mt-tools/iterating/filter.ts";

type BeamtimeFilterProps = {
    value?: SampleModel["form"];
    onChange: (value?: SampleModel["form"]) => void;
};

const opts = formOptions.map((f) => ({
    id: f,
    name: f,
}));

const options = opts.map(toValueLabel);

export const SampleFormFilter: FC<BeamtimeFilterProps> = ({
    value,
    onChange,
}) => {
    const selected = useMemo(
        () => (value ? options.find(getByValue(value)) : undefined),
        [value],
    );

    return (
        <Box width="180px" maxW="180px">
            <SingleSelect
                isClearable
                onChange={(newValue) => onChange(newValue?.value ?? undefined)}
                options={options}
                value={selected}
                placeholder="Filter by form"
            />
        </Box>
    );
};
