import type { FC, MouseEvent, MouseEventHandler } from "react";
import { useState } from "react";

import type { Promisable } from "type-fest";

import type { ButtonProps } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";

export const LoadingButton: FC<
    ButtonProps & {
        onClick: (
            ...args: Parameters<MouseEventHandler<HTMLButtonElement>>
        ) => Promisable<ReturnType<MouseEventHandler<HTMLButtonElement>>>;
        awaitPromise?: boolean;
    }
> = ({ awaitPromise = true, onClick, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);

    const click = async (e: MouseEvent<HTMLButtonElement>) => {
        if (awaitPromise) {
            setIsLoading(true);
            // eslint-disable-next-line @typescript-eslint/await-thenable
            await onClick(e);
            setIsLoading(false);
        }
    };

    return (
        <Button
            {...props}
            isLoading={isLoading}
            onClick={click}
            loadingText={props.loadingText ?? props.children}
        />
    );
};
