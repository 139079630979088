import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export const DataEmptyState = () => (
    <EmptyState
        icon={(props) => <Icon.LineChart {...props} color="currentColor" />}
        heading="No data"
        body="There is no XRD data available for this method."
    />
);
