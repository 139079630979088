import type { FC, ReactNode } from "react";
import { useRef } from "react";

import {
    Button,
    Center,
    HStack,
    IconButton,
    SimpleGrid,
    Text,
} from "@chakra-ui/react";

import {
    FileUpload as SaasFileUpload,
    FileUploadDropzone,
    FileUploadTrigger,
} from "@mt-components/Input/FileUploadCore";

import { Icon } from "@mt-design/icons.tsx";

import type { FileRejection } from "@zag-js/file-upload";

type FileChangeParams = {
    acceptedFiles: File[];
    rejectedFiles: FileRejection[];
};

type FileUploadProps = {
    uploadLabel: string;
    setFiles: (params?: FileChangeParams) => void;
    clearFiles: () => void;
    accept: string[];
    emptyState?: ReactNode;
    maxFileSize?: number;
    maxFiles?: number;
};

export const FileUpload: FC<FileUploadProps> = ({
    uploadLabel,
    emptyState,
    accept,
    maxFileSize,
    maxFiles,
    setFiles,
}) => {
    const uploadRef = useRef<HTMLDivElement | null>(null);
    return (
        <SaasFileUpload
            ref={uploadRef}
            maxFileSize={maxFileSize ?? 1024 * 1024}
            maxFiles={maxFiles ?? 1}
            accept={accept.join(",")}
            onFileChange={setFiles}
        >
            {({
                files,
                clearFiles,
            }: {
                files: File[];
                clearFiles: () => void;
            }) => (
                <FileUploadDropzone>
                    {files.length === 0 && <>{emptyState}</>}
                    {!files.length ? (
                        <FileUploadTrigger colorScheme="blue" as={Button}>
                            {uploadLabel}
                        </FileUploadTrigger>
                    ) : (
                        <HStack w="100%">
                            <SimpleGrid
                                maxWidth="70%"
                                mx="auto"
                                columns={2}
                                templateColumns="2fr 1fr"
                                spacing={2}
                            >
                                <Text mr="74px" fontSize="sm">
                                    {files[0].name}
                                </Text>
                                <Center>
                                    <IconButton
                                        mx="auto"
                                        colorScheme="gray"
                                        aria-label="delete"
                                        icon={<Icon.Delete />}
                                        onClick={() => {
                                            setFiles();
                                            clearFiles();
                                        }}
                                    />
                                </Center>
                            </SimpleGrid>
                        </HStack>
                    )}
                </FileUploadDropzone>
            )}
        </SaasFileUpload>
    );
};
