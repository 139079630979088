import type { CustomTraceType } from "@chart-editor/pages/plot/types.tsx";
import { Icon } from "@mt-design/icons.tsx";
import { keys } from "ramda";
import { IconType } from "react-icons";
type PlotIcon = Record<CustomTraceType, IconType | undefined>;

export const PlotIcons: PlotIcon = {
    scatter: Icon.ScatterChart,
    line: Icon.LineChart,
};

const FallBackIcon = Icon.LineChart;

export const getIconForChart = (cand: string) => {
    const chartNames = keys(PlotIcons);

    const name = (chartNames as string[]).includes(cand)
        ? (cand as CustomTraceType)
        : undefined;

    const ChartIcon = name && PlotIcons[name] ? PlotIcons[name] : FallBackIcon;

    return ChartIcon ? ChartIcon : FallBackIcon;
};
