import type React from "react";

import { Anchor } from "@mt-components/saas-ui-adapter/Anchor.tsx";
import { useSaas } from "@mt-components/saas-ui-adapter/useSaas.tsx";

export function useLink(): React.ElementType {
    const context = useSaas();
    if (context.linkComponent) {
        return context.linkComponent;
    }
    return Anchor;
}
