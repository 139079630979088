import type React from "react";

import type { Promisable } from "type-fest";

export const onEnter = (
    cb: (e?: React.KeyboardEvent<HTMLElement>) => Promisable<void>,
) => {
    return async (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.code === "Space") {
            await cb(e);
        }

        if (e.code === "Enter") {
            e.preventDefault();
            await cb(e);
        }
    };
};
