import { memo } from "react";

import { Center } from "@chakra-ui/react";

import { Organization } from "@app/domain/api/organization.ts";
import { agent } from "@app/Routes/agentRoutes.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { OrganizationDetail } from "./OrganizationDetail.tsx";

export const OrganizationDetailsPage = memo(() => {
    const params = agent.organization.useParams();
    const organizationId = params.organizationId;
    const orgQuery = Organization.useGet({
        path: {
            organizationId,
        },
    });

    if (!orgQuery.data?.data) {
        return (
            <Center>
                <LoadingSwirl />
            </Center>
        );
    }

    if (orgQuery.error) {
        return <NotFound />;
    }

    return <OrganizationDetail organization={orgQuery.data.data} />;
});

OrganizationDetailsPage.displayName = "OrganizationDetailsPage";
