import type { FC } from "react";
import { memo, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";

import { RouterProvider } from "@tanstack/react-router";

import { useUserHotjar, useUserSentry } from "@app/bootstrap.ts";
import { useRemoteConfigContext } from "@app/contexts/ConfigContext/ConfigContext.tsx";
import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";
import {
    type SimpleQueryOptions,
    useAgentAccessQuery,
    usePublicAccessQuery,
} from "@app/domain/api/self.ts";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";

import { router as router1 } from "src/App/Routes/router.tsx";

import { RoleContext } from "./contexts/RoleContext";
import { UserContextProvider } from "./contexts/UserContext";
import { Role } from "./domain/auth";
// import { agentRoot } from "./Routes/agentRoutes";
import type { RouterContext } from "./Routes/beforeLoad";

export const authLoadingKey = "load-auth";

export const AppRouter: FC = memo(() => {
    const config = useRemoteConfigContext();
    const loadingCtx = useLoadingContext();
    const loadingCtxRef = useLatestRef(loadingCtx);

    const auth = useAuth();

    const queryOptions = useMemo(
        (): SimpleQueryOptions => ({
            enabled: !auth.isLoading && auth.isAuthenticated,
            staleTime: Infinity,
        }),
        [auth.isLoading, auth.isAuthenticated],
    );

    const publicAccessQuery = usePublicAccessQuery(queryOptions);
    const agentAccessQuery = useAgentAccessQuery(queryOptions);

    const role = (() => {
        if (!auth.isAuthenticated) return undefined;
        if (agentAccessQuery.isFetched && agentAccessQuery.data?.status === 200)
            return Role.AGENT;
        if (
            publicAccessQuery.isFetched &&
            publicAccessQuery.data?.status === 200
        )
            return Role.USER;
        return;
    })();
    const userId = auth.user?.profile.sub;

    useUserSentry(userId);
    useUserHotjar(userId, role);

    const context = useMemo(
        (): RouterContext => ({ role, config, userId }),
        [role, config, userId],
    );

    useEffect(() => {
        if (!auth.isLoading) {
            loadingCtxRef.current.removeLoading(authLoadingKey);
        }
    }, [loadingCtxRef, auth.isLoading]);

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (!auth.isAuthenticated) {
        void auth.signinRedirect();
        return <div>Redirecting to signing...</div>;
    }
    if (auth.error) return <div>Oops... {auth.error.message}</div>;

    if (!userId) return <div>Empty user id</div>;
    if (!role) return <div>Couldn&apos;t determine user role</div>;

    return (
        <RoleContext.Provider value={{ role }}>
            <UserContextProvider>
                <RouterProvider router={router1} context={context} />;
            </UserContextProvider>
        </RoleContext.Provider>
    );
});

AppRouter.displayName = "AppRouter";
