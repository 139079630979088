export const readAsText = (file: File) => {
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.addEventListener(
            "load",
            () => {
                resolve(reader.result);
            },
            false,
        );

        reader.addEventListener(
            "error",
            () => {
                reject(new Error("Error reading file"));
            },
            false,
        );

        // const file = files?.acceptedFiles[0];
        reader.readAsText(file);
    });
};
