// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component } from "react";

import { Box } from "@chakra-ui/react";

import { Icon } from "../icons";

type Options = { label: string; value: string; checked: boolean }[];
type Props = {
    options: Options;
    onChange: (options: Options) => void;
    className?: string;
    orientation?: string;
};

type State = {
    options: Options;
};

class CheckboxGroup extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { options: this.props.options };
        this.handleChange = this.handleChange.bind(this);
    }
    static defaultProps = {
        className: "",
    };

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        this.setState({ options: nextProps.options });
    }

    handleChange(i: number) {
        const newOptions = this.props.options.slice();
        newOptions[i] = Object.assign(newOptions[i], {
            checked: !newOptions[i].checked,
        });
        this.props.onChange(newOptions);
    }

    renderOptions() {
        return this.state.options.map((option, i) => {
            return (
                <Box cursor="default" userSelect="none" py="3px" key={i}>
                    <Box
                        h="18px"
                        w="18px"
                        border="solid 1px"
                        borderColor="gray.200"
                        borderRadius="4px"
                        cursor="pointer"
                        display="inline-block"
                        verticalAlign="middle"
                        textAlign="center"
                        position="relative"
                        transition="background-color ease-in-out 150ms"
                        backgroundColor={
                            option.checked ? "gray.600" : undefined
                        }
                        _hover={{
                            backgroundColor: option.checked
                                ? "gray.700"
                                : "gray.100",
                        }}
                        onClick={() => this.handleChange(i)}
                    >
                        {option.checked && (
                            <Box
                                color="gray.800"
                                fontSize="12px"
                                w="100%"
                                h="100%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Icon.Check2 color="white" />
                            </Box>
                        )}
                    </Box>
                    <Box
                        display="inline-block"
                        textAlign="left"
                        verticalAlign="middle"
                        cursor="pointer"
                        lineHeight="20px"
                        color="gray.800"
                        fontSize="12px"
                        pl="6px"
                        // className="checkbox__label"
                        onClick={() => this.handleChange(i)}
                    >
                        {option.label}
                    </Box>
                </Box>
            );
        });
    }

    render() {
        return <Box>{this.renderOptions()}</Box>;
    }
}

// CheckboxGroup.propTypes = {
//     options: PropTypes.arrayOf(
//         PropTypes.shape({
//             label: PropTypes.string.isRequired,
//             value: PropTypes.string.isRequired,
//             checked: PropTypes.bool.isRequired,
//         }),
//     ).isRequired,
//     onChange: PropTypes.func,
//     className: PropTypes.string,
//     orientation: PropTypes.string,
// };

export default CheckboxGroup;
