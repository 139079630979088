import type { FC, KeyboardEventHandler, MouseEventHandler } from "react";
import { useRef } from "react";

import { Box } from "@chakra-ui/react";

import { Image } from "@mt-components/Display/Image/LoadingImage.tsx";

import { useIntersectedOnce } from "@mt-hooks/useIntersectedOnce.ts";

type Props = {
    url: string;
    alt?: string;
    onClick?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
};

export const PreviewImage: FC<Props> = ({ url, alt, onClick, onKeyDown }) => {
    const intersectionRef = useRef(null);
    const hasIntersectedOnce = useIntersectedOnce(intersectionRef, {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
    });

    return (
        <Box
            ref={intersectionRef}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            {hasIntersectedOnce && (
                <Image
                    onClick={onClick}
                    onKeyDown={onKeyDown}
                    alt={alt}
                    src={url}
                    fallback={
                        <Box w="100%" h="100%" p="25px">
                            <Box w="100%" h="100%" bg="gray.100" />
                        </Box>
                    }
                />
            )}
        </Box>
    );
};

PreviewImage.displayName = "CadPreviewImage";
