import { indexBy } from "ramda";

type WithId = { id: string };

type WithValue = { value: string };

export const getById = (id?: string) => (candidate: WithId) =>
    id ? candidate.id === id : false;

export const getByValue = (value?: string) => (candidate: WithValue) =>
    value ? candidate.value === value : false;

export const toId = (o: WithId) => o.id;

export const filterMap = <GData, GTransformed>(
    predicate: (d: GData) => [GTransformed, boolean],
    array: GData[],
) => {
    const result: GTransformed[] = [];

    array.forEach((item) => {
        const [transformed, isKeep] = predicate(item);

        if (isKeep) {
            result.push(transformed);
            return;
        }

        return;
    });

    return result;
};

export const byId = <TData extends WithId>(
    data: TData[],
): Record<string, TData | undefined> => indexBy((c: TData) => c.id, data);
