import { useAPI } from "src/App/contexts/APIContext/useApiContext";

export type * as agentSchema from "./gen/schema-agent";
export type * as publicSchema from "./gen/schema-public";

export const usePublicClient = () => {
    const api = useAPI();
    return api.publicClient;
};

export const useAgentClient = () => {
    const api = useAPI();
    return api.agentClient;
};

const key = (baseKey: string | string[]) => {
    return (key?: string) => [
        ...(Array.isArray(baseKey) ? baseKey : [baseKey]),
        ...(key ? [key] : []),
    ];
};

export const selfQueryKey = () => ["self"];
export const selfOrganizationsQueryKey = () => ["self", "organizations"];
export const SSWQueryKey = key("ssw");
export const usersQueryKey = key("users");
export const usersOrganizationsKey = (userId: string) => [
    ...usersQueryKey(userId),
    "organizations",
];
export const beamtimesQueryKey = key("beamtimes");
export const organizationsQueryKey = key("organizations");
export const organizationsUsersQueryKey = (organizationId: string) => [
    ...organizationsQueryKey(organizationId),
    "users",
];
export const organizationAuthzQueryKey = (
    organizationId: string,
    userId?: string,
) => [...organizationsQueryKey(organizationId), ...key("authz")(userId)];
