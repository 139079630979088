import type { FC } from "react";
import { memo, useEffect } from "react";

import { Box } from "@chakra-ui/react";
import { Outlet } from "@tanstack/react-router";

import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";
import { orgsLoadingKey } from "@app/contexts/OrgContext/OrgContext.tsx";
import { useRoleContext } from "@app/contexts/RoleContext.tsx";
import { Role } from "@app/domain/auth.ts";
import { AppShellAgent } from "@app-components/shell/AgentAppShell.tsx";
import { AppShellUser } from "@app-components/shell/AppShellUser.tsx";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";

import { PreventRenderingWhileLoading } from "../contexts/GlobalLoader/PreventRenderingWhileLoading";
import { OrgContextContainer } from "../contexts/OrgContext/OrgContextContainer";

const RemoveOrgLoadingForAgent: FC = () => {
    const loadingCtx = useLoadingContext();
    const loadingCtxRef = useLatestRef(loadingCtx);

    useEffect(() => {
        loadingCtxRef.current.removeLoading(orgsLoadingKey);
    }, [loadingCtxRef]);

    return null;
};

export const PublicRootComponent = memo(() => {
    const role = useRoleContext();

    return role.role === Role.USER ? (
        <OrgContextContainer>
            <PreventRenderingWhileLoading>
                <AppShellUser>
                    <Box w="100%" height="100%" overflow="hidden">
                        <Outlet />
                    </Box>
                </AppShellUser>
            </PreventRenderingWhileLoading>
        </OrgContextContainer>
    ) : (
        <>
            <RemoveOrgLoadingForAgent />
            <PreventRenderingWhileLoading>
                <AppShellAgent>
                    <Box w="100%" height="100%" overflow="hidden">
                        <Outlet />
                    </Box>
                </AppShellAgent>
            </PreventRenderingWhileLoading>
        </>
    );
});

PublicRootComponent.displayName = "RootComponent";

// export const AgentRootComponent = memo(() => {
//     const loadingCtx = useLoadingContext();
//     const loadingCtxRef = useLatestRef(loadingCtx);
//
//     useEffect(() => {
//         loadingCtxRef.current.removeLoading(orgsLoadingKey);
//     }, [loadingCtxRef]);
//
//     return (
//         <PreventRenderingWhileLoading>
//             <AppShellAgent>
//                 <Box w="100%" height="100%" overflow="hidden">
//                     <Outlet />
//                 </Box>
//             </AppShellAgent>
//         </PreventRenderingWhileLoading>
//     );
// });
//
// AgentRootComponent.displayName = "RootComponent";
