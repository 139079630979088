// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, ReactNode } from "react";
import React, { Component } from "react";

import {
    containerConnectedContextTypes,
    striptags,
    unpackPlotProps,
} from "lib";
import PropTypes from "prop-types";

import { Box, Center, Text } from "@chakra-ui/react";

import { Icon as AppIcon } from "@mt-design/icons.tsx";

import FoldEmpty from "./FoldEmpty";

const determineVisibility = (nextProps, nextContext) => {
    let foldVisible = false;

    React.Children.forEach(nextProps.children, (child) => {
        if (!child || foldVisible) {
            return;
        }

        if (child.props.attr) {
            // attr components force fold open if they are visible
            const plotProps = unpackPlotProps(child.props, nextContext);
            if (child.type.modifyPlotProps) {
                child.type.modifyPlotProps(child.props, nextContext, plotProps);
            }

            foldVisible = foldVisible || plotProps.isVisible;
            return;
        }

        if (!(child.type.plotly_editor_traits || {}).no_visibility_forcing) {
            // non-attr components force visibility (unless they don't via traits)
            foldVisible = true;
            return;
        }
    });

    return foldVisible;
};

type FoldProps = {
    canDelete: boolean;
    children: ReactNode;
    className: string;
    folded: boolean;
    foldInfo: object;
    toggleFold: () => void;
    hideHeader: boolean;
    icon: ReactNode;
    messageIfEmpty: string;
    name: string;
    canMoveUp: boolean;
    canMoveDown: boolean;
    deleteContainer: () => void;
    moveContainer: () => void;
};

export const PlotlyFoldBase: FC<FoldProps> = (props, context) => {
    const foldVisible = determineVisibility(props, context);

    if (!foldVisible && !props.messageIfEmpty) {
        return null;
    }
    const {
        canDelete,
        children,
        className,
        folded,
        foldInfo,
        toggleFold,
        hideHeader,
        icon: Icon,
        messageIfEmpty,
        name,
        canMoveUp,
        canMoveDown,
    } = props;

    const movingControls = (canMoveDown || canMoveUp) && (
        <Center>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                h="27px"
                mr="5px"
            >
                <Box
                    w="18px"
                    color="gray.600"
                    display="block"
                    opacity={canMoveUp ? 1 : 0.3}
                    onClick={(e) => {
                        // prevents fold toggle to happen when clicking on moving arrow controls
                        e.stopPropagation();
                        if (canMoveUp) {
                            if (
                                !context.moveContainer ||
                                typeof context.moveContainer !== "function"
                            ) {
                                throw new Error(
                                    "moveContainer must be a function",
                                );
                            }
                            context.moveContainer("up");
                        }
                    }}
                >
                    <AppIcon.ChevronUp size="16px" color="currentColor" />
                </Box>
                <Box
                    w="18px"
                    color="gray.600"
                    display="block"
                    opacity={canMoveDown ? 1 : 0.3}
                    onClick={(e) => {
                        // prevents fold toggle to happen when clicking on moving arrow controls
                        e.stopPropagation();
                        if (canMoveDown) {
                            if (
                                !context.moveContainer ||
                                typeof context.moveContainer !== "function"
                            ) {
                                throw new Error(
                                    "moveContainer must be a function",
                                );
                            }
                            context.moveContainer("down");
                        }
                    }}
                >
                    <AppIcon.ChevronDown size="16px" color="currentColor" />
                </Box>
            </Box>
        </Center>
    );

    const classes = className ? " " + className : "";

    return (
        <Box w="100%" userSelect="none" mb="12px" className={`fold${classes}`}>
            {!hideHeader && (
                <Box
                    display="flex"
                    justifyContent="space-between"
                    p="0 12px"
                    height="32px"
                    _hover={{
                        cursor: "pointer",
                        backgroundColor: "blackAlpha.400",
                    }}
                    bg={folded ? "blackAlpha.100" : "blackAlpha.100"}
                    color={folded ? "gray.800" : "gray.800"}
                    borderTopLeftRadius="6px"
                    borderTopRightRadius="6px"
                    transition="background-color 0.1s ease-in-out,color 0.1s ease-in-out,border 0.1s ease-in-out;"
                    onClick={toggleFold}
                >
                    <Box display="flex" alignItems="center" flexGrow={1}>
                        <Center
                            color="gray.800"
                            transition="transform 0.1s ease-in-out"
                            transform={`rotate(${folded ? "-90deg" : 0});`}
                            transformOrigin="center center"
                            fontWeight="bold"
                        >
                            <AppIcon.ChevronDown />
                        </Center>
                        <Text
                            ml="2px"
                            textStyle="T2"
                            fontWeight="500"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            letterSpacing="0.2px"
                            maxW="230px"
                        >
                            {striptags(name)}
                        </Text>
                    </Box>
                    {movingControls}
                    {canDelete ? (
                        <Center
                            color="gray.600"
                            transition="color 0.1s ease-in-out"
                            _hover={{
                                color: "gray.900",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                context.deleteContainer(foldInfo);
                            }}
                        >
                            <AppIcon.Close color="currentColor" size="20px" />
                        </Center>
                    ) : null}
                </Box>
            )}
            {!folded && (
                <Box
                    borderStyle="solid"
                    borderColor="gray.200"
                    borderBottomWidth="1px"
                    borderLeftWidth="1px"
                    borderRightWidth="1px"
                    borderBottomLeftRadius="5px"
                    borderBottomRightRadius="5px"
                >
                    {foldVisible ? (
                        children
                    ) : (
                        <FoldEmpty
                            icon={Icon}
                            messagePrimary={messageIfEmpty}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

PlotlyFoldBase.contextTypes = Object.assign(
    {
        deleteContainer: PropTypes.func,
        moveContainer: PropTypes.func,
    },
    containerConnectedContextTypes,
);

export class PlotlyFold extends Component<FoldProps> {
    getChildContext() {
        return {
            foldInfo: this.props.foldInfo ? this.props.foldInfo : null,
        };
    }
    render() {
        return <PlotlyFoldBase {...this.props} />;
    }
}

PlotlyFold.plotly_editor_traits = { foldable: true };

PlotlyFold.childContextTypes = {
    foldInfo: PropTypes.object,
};

PlotlyFold.plotly_editor_traits = {
    foldable: true,
};

export default PlotlyFold;
