import type { FC } from "react";
import { memo, useMemo } from "react";

import { identity, indexBy, toPairs } from "ramda";
import type { Promisable } from "type-fest";

import { Box, Center, useDisclosure } from "@chakra-ui/react";
import type { OnChangeFn } from "@tanstack/react-table";

import { LocalStorageKeys } from "@app/config.ts";
import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { SamplesTable } from "@app/pages/user/samples/Samples.table.tsx";
import { SampleSidebar } from "@app/pages/user/samples/SampleSidebar.tsx";
import { MeasurementTaskBatchModal } from "@app-components/modals/measurement/MeasurementTask.batch.modal.tsx";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { filterMap, toId } from "@mt-tools/iterating/filter.ts";
import type { IndexBoolean } from "@mt-tools/types.ts";

import { Icon } from "@mt-design/icons.tsx";

import { useDisclosureWithStorage } from "src/packages/hooks/useDisclosureWithStorage.ts";

type Props = {
    isDisabled?: boolean;
    maxSamples?: number;
    samples: SampleModel[];
    selectedSamples: {
        list: string[];
        obj: Record<string, boolean>;
    };
    onToggleForm: () => Promisable<unknown>;
    onDeleteSample: (id: string) => Promisable<unknown>;
    onDeleteSamples: (ids: string[]) => Promisable<unknown>;
    onSelectSampleToUpdate: (sample: SampleModel) => void;
    onCreateSample: (payload: CreateSamplePayload) => Promisable<unknown>;
    onSelectSamples: (sampleIds: {
        list: string[];
        obj: Record<string, boolean | undefined>;
    }) => Promisable<unknown>;
    onUploadSamples: () => Promisable<unknown>;
    onDownloadTemplate: () => Promisable<unknown>;
    onDownloadList: (samples: SampleModel[]) => void;
};

export const Samples: FC<Props> = memo(
    ({
        isDisabled,
        samples,
        selectedSamples: selectedSamples,
        onToggleForm,
        onDeleteSample,
        onDeleteSamples,
        onSelectSampleToUpdate,
        onSelectSamples,
        onCreateSample,
        onUploadSamples,
        onDownloadTemplate,
        onDownloadList,
    }) => {
        const sampleBatchDisclosure = useDisclosure();
        const sampleSidebarDisclosure = useDisclosureWithStorage(
            LocalStorageKeys.samplesSidebar,
        );

        // const selectedSamplesObjIds = useMemo(
        //     () => Object.fromEntries(selectedSampleIds.map((id) => [id, true])),
        //     [selectedSampleIds],
        // );

        const samplesById = useMemo(() => indexBy(toId, samples), [samples]);

        const setSelectedSamples: OnChangeFn<IndexBoolean> = (updater) => {
            if (typeof updater === "function") {
                const newRowSelection = updater(selectedSamples.obj);
                const entries = toPairs(newRowSelection);
                const ids = filterMap(identity, entries);
                onSelectSamples({
                    obj: newRowSelection,
                    list: ids,
                });

                return;
            }

            const entries = toPairs(updater);

            const ids = filterMap(identity, entries);
            onSelectSamples({
                obj: updater,
                list: ids,
            });
            return;
        };

        const deleteLabel = selectedSamples.list.length
            ? `Delete selected (${selectedSamples.list.length})`
            : "Delete selected";

        const actions = useMemo(
            () => [
                {
                    id: "import",
                    label: "Import",
                    onClick: onUploadSamples,
                    isAction: true,
                    isTruncated: true,
                    hasTooltip: true,
                    items: [
                        {
                            id: "download-csv",
                            label: "Download CSV template",
                            onClick: onDownloadTemplate,
                            isTruncated: true,
                            hasTooltip: true,
                        },
                        {
                            id: "import-csv",
                            label: "Import CSV",
                            onClick: onUploadSamples,
                            isTruncated: true,
                            hasTooltip: true,
                        },
                    ],
                },
                {
                    id: "add-sample",
                    label: "Add sample",
                    onClick: onToggleForm,
                    isPrimary: true,
                    isTruncated: true,
                    hasTooltip: true,
                },
            ],
            [onUploadSamples, onToggleForm, onDownloadTemplate],
        );
        const menuActions = useMemo(
            () => [
                {
                    id: "download-all",
                    label: "Download all",
                    onClick: () => onDownloadList(samples),
                },
                {
                    id: "delete",
                    label: deleteLabel,
                    isDisabled: selectedSamples.list.length === 0,
                    onClick: () => onDeleteSamples(selectedSamples.list),
                },
            ],
            [
                onDownloadList,
                samples,
                deleteLabel,
                selectedSamples,
                onDeleteSamples,
            ],
        );

        return (
            <>
                {sampleBatchDisclosure.isOpen && (
                    <MeasurementTaskBatchModal
                        isOpen={sampleBatchDisclosure.isOpen}
                        onCancel={sampleBatchDisclosure.onClose}
                        onClose={sampleBatchDisclosure.onClose}
                        // @todo this is unsafe
                        samples={selectedSamples.list.map(
                            (id) => samplesById[id],
                        )}
                    />
                )}
                <Box
                    id="sample-details-main"
                    mx="auto"
                    display="flex"
                    flexDirection="row"
                    h="100%"
                    overflow="hidden"
                >
                    <PageLayout
                        display="flex"
                        flexDirection="column"
                        id="center-box"
                        overflowX="auto"
                        flex={1}
                        h="100%"
                        overflow="hidden"
                    >
                        <Box
                            display="flex"
                            flexDir="column"
                            w="100%"
                            h="100%"
                            overflow="hidden"
                        >
                            <PageHeader
                                crumbs={[
                                    {
                                        id: "samples",
                                        label: "Samples",
                                    },
                                ]}
                                actionsId="samples-page-actions"
                                subtitle="Manage your samples and use them to request measurements"
                                actions={actions}
                                menuActions={menuActions}
                            />

                            {samples.length === 0 ? (
                                <Center
                                    height="100%"
                                    width="100%"
                                    overflowY="auto"
                                    overflowX="auto"
                                    py="8"
                                >
                                    <EmptyState
                                        isPrimaryDisabled={isDisabled}
                                        icon={() => (
                                            <Icon.SamplesImage
                                                height="120px"
                                                width="120px"
                                            />
                                        )}
                                        heading="No Samples"
                                        body="You don't have any samples yet. Start by creating one."
                                        onClick={onToggleForm}
                                        buttonLabel="Create your first sample"
                                    />
                                </Center>
                            ) : (
                                <SamplesTable
                                    samples={samples}
                                    onSelectSampleToUpdate={
                                        onSelectSampleToUpdate
                                    }
                                    onCreateSample={onCreateSample}
                                    onDeleteSample={onDeleteSample}
                                    onSelectSample={setSelectedSamples}
                                    selectedSamples={selectedSamples}
                                />
                            )}
                        </Box>
                    </PageLayout>
                    {Boolean(samples.length) && (
                        <SampleSidebar
                            showToggler
                            isOpen={sampleSidebarDisclosure.isOpen}
                            onToggle={sampleSidebarDisclosure.onToggle}
                            samples={selectedSamples.list.map(
                                (id) => samplesById[id],
                            )}
                            onSelectSampleToEdit={onSelectSampleToUpdate}
                            onDeleteSample={onDeleteSample}
                            onDuplicateSample={onCreateSample}
                            onOpenTasksModal={sampleBatchDisclosure.onOpen}
                        />
                    )}{" "}
                </Box>
            </>
        );
    },
);

Samples.displayName = "Samples";
