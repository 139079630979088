import React from "react";

import { useDisclosure, useId } from "@chakra-ui/react";
import type { PropGetter } from "@chakra-ui/react-utils";
import { cx } from "@chakra-ui/utils";

export interface UseCollapse {
    defaultIsOpen?: boolean;
    isOpen?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
    isCollapsible?: boolean;
}

export const useCollapse = (props: UseCollapse = {}) => {
    const { isCollapsible = true, ...rest } = props;

    const id = `collapse-${useId()}`;

    const { isOpen, onToggle, onOpen, onClose } = useDisclosure(rest);

    const getToggleProps: PropGetter = React.useCallback(
        (props = {}) => {
            const { className, ...rest } = props;
            if (isCollapsible) {
                return {
                    "className": cx("sui-collapse-toggle", className),
                    "onClick": () => onToggle(),
                    "aria-expanded": isOpen.toString(),
                    "aria-controls": id,
                    ...rest,
                };
            }
            return {};
        },
        [isCollapsible, isOpen, id, onToggle],
    );

    const getCollapseProps = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (props: any = {}) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return {
                id,
                in: isOpen,
                ...props,
            };
        },
        [isOpen, id],
    );

    return {
        isCollapsible,
        isOpen,
        getToggleProps,
        getCollapseProps,
        onToggle,
        onOpen,
        onClose,
    };
};

export type UseCollapseReturn = ReturnType<typeof useCollapse>;
