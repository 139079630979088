// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component } from "react";
import type { ColorResult, RGBColor } from "react-color";
import { CustomPicker as customPicker } from "react-color";
import { Hue, Saturation } from "react-color/lib/components/common";
import Fields from "react-color/lib/components/sketch/SketchFields";

import type { ColorInput, Instance as TinyColorInstance } from "tinycolor2";
import tinycolor from "tinycolor2";

import { Box } from "@chakra-ui/react";

// Utility functions for converting ColorPicker color objects or raw strings
// into TinyColor objects.
const extractRGB = (c: ColorResult): ColorInput =>
    typeof c === "object" && "rgb" in c ? c.rgb : c;

const getColorSource = (c: ColorResult): ColorInput =>
    typeof c === "object" && "source" in c && c.source === "hex"
        ? c.hex
        : extractRGB(c);
const toTinyColor = (c: ColorResult): TinyColorInstance =>
    tinycolor(getColorSource(c));

type CustomProps = {
    onChangeComplete: () => void;
    onChange: () => void;
    rgb: RGBColor;
};

class Custom extends Component<CustomProps> {
    render() {
        return (
            <Box width="185px">
                <Box className="colorpicker__controls +flex">
                    <Box w="100%">
                        <Box
                            margin="auto 0"
                            height="10px"
                            margin="3px 0"
                            position="relative"
                            overflow="hidden"
                            border="solid 1px"
                            borderColor="gray.200"
                            cursor="pointer"
                            // className="colorpicker__slider"
                        >
                            <Hue {...this.props} />
                        </Box>
                    </Box>
                </Box>
                <div className="colorpicker__saturation">
                    <Saturation {...this.props} />
                </div>
                <div className="colorpicker__custom-input">
                    <Fields
                        {...this.props}
                        onChange={this.props.onChangeComplete}
                    />
                </div>
            </Box>
        );
    }
}

const CustomColorPicker = customPicker(Custom);

type Props = {
    onColorChange: (color: string) => void;
    selectedColor: string;
};

type State = {
    isVisible: boolean;
};

class ColorPicker extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isVisible: false,
        };

        this.onSelectedColorChange = this.onSelectedColorChange.bind(this);
        this.toggleVisible = this.toggleVisible.bind(this);
    }

    onSelectedColorChange(newColor: ColorResult) {
        // We use our own toTinyColor because this value is a ColorPicker
        // color value which is an object that needs unpacking. We also handle
        // the case where a color string is passed in (just in case).
        const color = toTinyColor(newColor);

        // relayout call only wants a RGB String
        this.props.onColorChange(color.toRgbString());
    }

    toggleVisible() {
        this.setState({ isVisible: !this.state.isVisible });
    }

    render() {
        // We use tinycolor here instead of our own toTinyColor as
        // tinycolor handles `null` values and other weirdness we may
        // expect from user data.
        const selectedColor = tinycolor(this.props.selectedColor);
        const colorText = selectedColor.toHexString();

        // Convert rgba to rgb if necessary
        const rgbString =
            selectedColor._a !== 0
                ? selectedColor.toRgbString()
                : `rgb(${selectedColor._r},${selectedColor._g},${selectedColor._b})`;

        // We need inline style here to assign the background color
        // dynamically.
        const swatchStyle = { backgroundColor: rgbString };

        return (
            <>
                <div className="colorpicker__container">
                    <div className="colorpicker">
                        <div
                            className="colorpicker__swatch +cursor-clickable"
                            style={swatchStyle}
                            onClick={this.toggleVisible}
                        />
                    </div>

                    <Box
                        ml="12px"
                        color="gray.800"
                        fontWeight="600"
                        fontSize="12px"
                        cursor="pointer"
                        display="inline-block"
                        vertical-align="middle"
                        textTransform="uppercase"
                        onClick={this.toggleVisible}
                    >
                        {colorText}
                    </Box>
                </div>

                {this.state.isVisible && (
                    <CustomColorPicker
                        color={rgbString}
                        onChangeComplete={this.onSelectedColorChange}
                    />
                )}
            </>
        );
    }
}

export default ColorPicker;
