import { useContext } from "react";

import { AContext } from "./ApiContextProvider.tsx";

export const useAPI = () => {
    const context = useContext(AContext);
    if (!context) {
        throw new Error("useAPI must be used within a API Context");
    }

    return context;
};
