import type { ChangeEvent, FC, ReactNode } from "react";

import { Box } from "@chakra-ui/react";

import { TableSearchInput } from "@mt-components/Input/TableSearchInput.tsx";

type SearchFilterBarProps = {
    value?: string;
    placeholder?: string;
    onReset: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    filter?: ReactNode;
    isDisabled?: boolean;
};

export const SearchFilterBar: FC<SearchFilterBarProps> = ({
    onReset,
    value,
    onChange,
    placeholder,
    filter,
    isDisabled,
}) => {
    return (
        <Box
            display="flex"
            pt="5px"
            minHeight="44px"
            flexDirection="row"
            alignItems="flex-start"
            p="4px"
            gap="16px"
            flexWrap="wrap"
        >
            <TableSearchInput
                value={value}
                onReset={onReset}
                onChange={onChange}
                placeholder={placeholder}
                isDisabled={isDisabled}
            />
            {filter ? filter : null}
        </Box>
    );
};
