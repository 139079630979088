import type { FC } from "react";
import { useMemo } from "react";

import { Center } from "@chakra-ui/react";
import { RouteApi } from "@tanstack/react-router";

import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { RequestDetails } from "@app/pages/agent/requestDetails/RequestDetails.tsx";
import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

const routeApi = new RouteApi({
    id: "/agent/organizations/$orgId/measurements/$orderId",
});

export const RequestDetailsContainer: FC = () => {
    const params = routeApi.useParams();

    const deleteManyTasks = MeasurementTask.useDeleteAll();
    const requestsQuery = Request.useAdminGet({
        measurementGroupId: params.orderId,
        organizationId: params.orgId,
    });

    const beamtimesQuery = Beamtime.useAdminGetAll();
    const samplesQuery = Sample.useAdminGetAll();
    const samplesOfOrg = useMemo(
        () =>
            samplesQuery.data?.filter(
                (s) => s.organizationId === params.orgId,
            ) || [],
        [samplesQuery.data, params.orgId],
    );

    const { data: tasksOfGroup } = MeasurementTask.agent.useAgentGetAll({
        query: {
            filter: {
                measurementGroupId: params.orderId,
            },
        },
    });

    const measurementGroup = requestsQuery.data;
    if (
        (!measurementGroup && requestsQuery.isLoading) ||
        !beamtimesQuery.data?.data ||
        !samplesQuery.data ||
        !tasksOfGroup
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSwirl />
            </Center>
        );
    }

    if (!measurementGroup) {
        return <NotFound />;
    }

    const deleteTasks = async (taskIds: string[]) => {
        await deleteManyTasks.mutateAsync({
            measurementTaskIds: taskIds,
            organizationId: measurementGroup.organizationId,
        });
    };

    return (
        <RequestDetails
            beamtimes={beamtimesQuery.data.data}
            samples={samplesOfOrg}
            tasksOfGroup={tasksOfGroup}
            measurementGroup={measurementGroup}
            deleteTasks={deleteTasks}
        />
    );
};

RequestDetailsContainer.displayName = "RequestDetailsContainer";
