export const config: {
    hostname: string;
    host: string;
    pathname: string;
} = {
    hostname: window.location.hostname,
    pathname: window.location.pathname,
    host: `${window.location.protocol}//${window.location.host}`,
};

export const LocalStorageKeys = {
    mainSidebar: "MT-sidebar",
    betaBanner: "MT-beta-banner",
    currentOrg: "MT-organization",
    samplesSidebar: "MT-samples-sidebar",
    measurementGroupDataSidebar: "MT-mgroup-data-sidebar",
    measurementGroupSampleTaskSidebar: "MT-mgroup-sample-task-sidebar",
    xrdDisclosure: "MT-mgroup-data-xrd",
    pdfDisclosure: "MT-mgroup-data-pdf",
    saxsDisclosure: "MT-mgroup-data-saxs",
    plots: "MT-plots",
    agent: {
        measurementGroupSampleTaskSidebar:
            "MT-agent-mgroup-sample-task-sidebar",
    },
};
