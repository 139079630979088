import type { FC } from "react";
import { useState } from "react";

import { csv2json } from "json-2-csv";
import { groupBy } from "ramda";

import { Box, Text } from "@chakra-ui/react";

import { Sample } from "@app/domain/api/sample.ts";
import { detectSeparator } from "@app/domain/services/sample/sample.ts";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { FileUpload } from "@mt-components/Input/FileUpload.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { endOfLine } from "@mt-tools/io/endOfLine.ts";
import { readAsText } from "@mt-tools/io/readAsText.ts";
import { MiB } from "@mt-tools/units.tsx";

type QRCodeUpdateModalProps = {
    isOpen: boolean;
    organizationId: string;
    onToggle: () => void;
    id: string;
};

const maxFileSize = 200;
const maxFileSizeWUnit = maxFileSize * MiB;

const contentProps = {
    width: "700px",
    maxWidth: "700px",
};

const noOp = () => {};

type SamplePatch = {
    id: string;
    qrCode: string;
    name: string;
};

export const QRCodeUpdateModal: FC<QRCodeUpdateModalProps> = (props) => {
    const snacknar = useSnackbar();
    const updateSample = Sample.useUpdate();
    const [samplePatch, setSamplePatch] = useState<SamplePatch[]>([]);

    return (
        <Modal
            contentProps={contentProps}
            isOpen={props.isOpen}
            onClose={props.onToggle}
            buttonRight={
                <LoadingButton
                    awaitPromise
                    onClick={async () => {
                        if (samplePatch.length) {
                            const promisses = samplePatch.map(async (patch) => {
                                return await updateSample
                                    .mutateAsync({
                                        body: {
                                            qrCode: patch.qrCode,
                                        },
                                        params: {
                                            path: {
                                                sampleId: patch.id,
                                                organizationId:
                                                    props.organizationId,
                                            },
                                        },
                                    })
                                    .then((r) => {
                                        return r.response.ok;
                                    });
                            });

                            const result = await Promise.all(promisses);
                            const grouped = groupBy((item) => {
                                return item ? "success" : "fail";
                            }, result);

                            if (grouped.success?.length) {
                                snacknar.success({
                                    title: `${grouped.success.length} samples updated`,
                                });
                            }

                            if (grouped.fail?.length) {
                                snacknar.success({
                                    title: `${grouped.fail.length} samples could not be updated`,
                                });
                            }
                            props.onToggle();
                        }
                    }}
                >
                    Assign QR Codes
                </LoadingButton>
            }
            header="Upload QR codes"
        >
            <Box display={samplePatch.length ? "none" : "block"}>
                <FileUpload
                    maxFiles={1}
                    emptyState={
                        <Text fontSize="md">
                            Select file {maxFileSize} Mb max
                        </Text>
                    }
                    maxFileSize={maxFileSizeWUnit}
                    uploadLabel="Select File"
                    setFiles={async (files) => {
                        if (files?.acceptedFiles.length === 1) {
                            const file = files.acceptedFiles[0];
                            const ctx = (await readAsText(file)) as string;

                            const eol = endOfLine(ctx);
                            if (!eol) {
                                // @todo add proper error handling
                                throw new Error("No EOL found");
                            }
                            const separator = detectSeparator(ctx);

                            const rawData = csv2json(ctx, {
                                keys: ["id", "qrCode", "name"],
                                delimiter: {
                                    field: separator,
                                    eol,
                                },
                                wrapBooleans: true,
                            }) as SamplePatch[];

                            setSamplePatch(rawData);

                            return;
                        }
                    }}
                    clearFiles={noOp}
                    accept={[".csv"]}
                />
            </Box>

            <Box p="14px">
                {samplePatch.map((patch) => {
                    return (
                        <Box
                            key={patch.id}
                            display="flex"
                            fontSize="14px"
                            justifyContent="space-between"
                        >
                            <Text flex="0 0 300px">{patch.id}</Text>
                            <Text flex="0 0 120px">{patch.name}</Text>
                            <Text flex="0 0 100px">{patch.qrCode}</Text>
                        </Box>
                    );
                })}
            </Box>
        </Modal>
    );
};
