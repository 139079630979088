import { type FC, useState } from "react";

import { Box } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { useUploadReport } from "@app/domain/api/request";

import { Modal } from "@mt-components/Modal/Modal.tsx";

import { LoadingButton } from "src/packages/components/Button/LoadingButton";
import { FileUpload } from "src/packages/components/Input/FileUpload";

type Props = {
    model: MeasurementGroupModel;
    onClose?: () => void;
    onUploaded?: (isOk: boolean) => void;
};
export const UploadMeasurementGroupReportModal: FC<Props> = (props) => {
    const [file, setFile] = useState<File | undefined>(undefined);

    const uploadReport = useUploadReport();

    return (
        <Modal
            id="upload-report"
            isOpen={true}
            onClose={() => props.onClose && props.onClose()}
            header="Upload report"
            buttonRight={
                <LoadingButton
                    colorScheme="blue"
                    isDisabled={!file}
                    onClick={async () => {
                        const res = await uploadReport.mutateAsync({
                            organizationId: props.model.organizationId,
                            measurementGroupId: props.model.id,
                            file: file!,
                        });

                        props.onUploaded?.(res.ok);
                    }}
                    isLoading={uploadReport.isPending}
                >
                    Upload
                </LoadingButton>
            }
        >
            <Box my="24px">
                <FileUpload
                    maxFiles={1}
                    maxFileSize={1024 * 1024 * 50}
                    uploadLabel="Set .pdf report file"
                    accept={[".pdf"]}
                    setFiles={(files) => {
                        if (!files) {
                            setFile(undefined);
                            return;
                        }

                        setFile(files.acceptedFiles[0]);
                        return;
                    }}
                    clearFiles={() => setFile(undefined)}
                />
            </Box>
        </Modal>
    );
};

UploadMeasurementGroupReportModal.displayName =
    "UploadMeasurementGroupReportModal";
