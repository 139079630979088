// listen for main to transfer the buffer to myWorker

import type { MathMessageEvent } from "./event.ts";

type Data = {
    x: number[];
    y: number[];
};

const normalize = (input: Data): Data => {
    const max = Math.max(...input.y);
    return {
        x: input.x,
        y: input.y.map((d) => d / max),
    };
};

const square = (input: Data): Data => {
    return {
        x: input.x,
        y: input.y.map((d) => d * d),
    };
};

const squareRoot = (input: Data): Data => {
    return {
        x: input.x,
        y: input.y.map((d) => Math.sqrt(d)),
    };
};

self.onmessage = (msg: MathMessageEvent) => {
    // @todo implement input validation
    if (msg.data.name === "square") {
        const input = msg.data.data;
        let output = {};
        Object.entries(input).forEach(([key, data]) => {
            if (!data) {
                return;
            }

            const result = square(data);

            output = {
                ...output,
                [key]: result,
            };
        });

        self.postMessage({ name: "square", data: output });
        return;
    }

    if (msg.data.name === "root") {
        const input = msg.data.data;
        let output = {};
        Object.entries(input).forEach(([key, data]) => {
            if (!data) {
                return;
            }

            const result = squareRoot(data);

            output = {
                ...output,
                [key]: result,
            };
        });

        self.postMessage({ name: "root", data: output });
        return;
    }

    if (msg.data.name === "normalize") {
        const input = msg.data.data;
        let output = {};
        Object.entries(input).forEach(([key, data]) => {
            if (!data) {
                return;
            }

            const result = normalize(data);

            output = {
                ...output,
                [key]: result,
            };
        });

        self.postMessage({ name: "normalize", data: output });
    }
};
