import type { FC } from "react";
import { useMemo } from "react";

import { Box, VStack } from "@chakra-ui/react";

import { Artifact, type ArtifactModel } from "@app/domain/api/artifact.ts";
import type { MeasurementModel } from "@app/domain/api/measurement.ts";
import { GraphContainer } from "@app/pages/agent/requestResult/GraphContainer.tsx";

export const MeasurementContainer: FC<{
    orgId: string;
    measurement: MeasurementModel;
    onSelectArtifact?: (artifact?: ArtifactModel) => void;
}> = ({ measurement, orgId }) => {
    const artficatsQuery = Artifact.useGetAll({
        params: {
            path: {
                organizationId: orgId,
            },
            query: {
                filter: {
                    measurementId: measurement.id,
                },
            },
        },
    });

    const memoizedArtifacts = useMemo(() => {
        return artficatsQuery.data?.filter((a) => a.format === "xye") ?? [];
    }, [artficatsQuery]);

    if (!artficatsQuery.data) {
        return null;
    }

    return (
        <VStack
            alignItems="flex-start"
            w="100%"
            mx="auto"
            flex={1}
            overflow="auto"
            minHeight="400px"
            h="100%"
        >
            <Box display="flex" w="250px" px="6px" py="4px" flex="0 0 44px" />
            <Box w="100%" mx="auto" flex={1} overflow="auto" minHeight="400px">
                <GraphContainer orgId={orgId} artifacts={memoizedArtifacts} />
            </Box>
        </VStack>
    );
};
