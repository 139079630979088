import type { FC } from "react";

import type { ColorScaleType } from "@mt-components/Colorscales/types.ts";

import { DEFAULT_SCALE } from "./constants.ts";

type Props = {
    attr?: string;
    start?: number;
    rot?: number;
    label?: string;
    colorscale?: ColorScaleType;
    scaleLength?: number;
    maxWidth?: number;
    onClick: (colorscale: ColorScaleType, start?: number, rot?: number) => void;
};

export const Colorscale: FC<Props> = (props) => {
    const scale = props.colorscale ? props.colorscale : DEFAULT_SCALE;

    return (
        <div style={{ width: "100%" }} className="colorscale-container">
            {props.label ? (
                <div
                    className="colorscale-label"
                    style={{
                        fontWeight: 600,
                        fontSize: "12px",
                        color: "#2a3f5f",
                        display: "inline-block",
                        width: "25%",
                        textAlign: "start",
                    }}
                >
                    {props.label}
                </div>
            ) : null}
            <div
                className="colorscale-palette-container"
                style={{
                    display: "inline-block",
                    textAlign: "start",
                    width: props.label ? "75%" : "100%",
                }}
            >
                <div
                    className="colorscale-block"
                    style={{
                        fontSize: "0px",
                        display: "inline-block",
                        width: "100%",
                    }}
                    onClick={() => props.onClick(scale, props.start, props.rot)}
                >
                    {scale.map((x, i) => (
                        <div
                            key={i}
                            className="colorscale-swatch"
                            style={{
                                backgroundColor: x,
                                width: "" + 100.0 / scale.length + "%",
                                height: "20px",
                                margin: "0 auto",
                                display: "inline-block",
                                cursor: "pointer",
                            }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Colorscale;
