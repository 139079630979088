import { useMemo } from "react";

import { useFormik } from "formik";
import type { Promisable } from "type-fest";
import { ZodError } from "zod";

import { Box, Text } from "@chakra-ui/react";

import type { OrganizationModel } from "@app/domain/api/organization.ts";
import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { formatLabel, removeEmptyStringValues } from "@app/domain/form";
import type { NewSchema } from "@app/domain/services/sample/utils.ts";
import {
    defaultValues,
    formOptions,
    mapDBSampleToForm,
    mapFormToSampleType,
    zodSchema,
} from "@app/domain/services/sample/utils.ts";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { CheckboxField } from "@mt-components/Input/CheckboxField.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { SelectField } from "@mt-components/Input/SelectField.tsx";
import { TextField } from "@mt-components/Input/TextField.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

export type SampleModalProps = {
    organizations?: OrganizationModel[];
    isOpen: boolean;
    sample?: SampleModel;
    onSubmit: (data: CreateSamplePayload) => Promisable<void>;
    onClose: () => void;
};

const sample = {
    name: {
        label: "Sample-ID",
        help: "Your ID for this sample",
        rules: {
            required: true,
        },
    },
    qr_code: {
        label: "QR Code",
    },
    substance: {
        label: "Substance",
        help: "the common name, e.g. Silicon",
        rules: {
            required: true,
        },
    },
    cas_number: {
        label: "CAS Number",
        help: "e.g. 7647-14-5",
    },
    composition: {
        label: "Composition",
        help: "Please use only element symbols and explicit stoichiometric ratios, e.g. Zr6O4O4H4 rather than Zr6O4(OH)4. If values are not accurately known, please use a best estimate. Do not include variables like (x) or (1-y).",
        rules: {
            required: true,
        },
    },
    form: {
        label: "Form",
    },
    formDescription: {
        label: "Form Description",
    },
    toxic: {
        label: "Is it toxic?",
    },
    corrosive: {
        label: "Is it corrosive?",
    },
    airSensitive: {
        label: "Is it air-sensitive?",
    },
    flammable: {
        label: "Is it flammable?",
    },
    oxidizing: {
        label: "Is it oxidizing?",
    },
    otherHazards: {
        label: "Are there other hazards?",
    },
    otherHazardsDescription: {
        label: "Please provide more detail about other hazards\n",
    },
    sampleHandlingRisk: {
        label: "Are there any risks associated with handling this sample?",
    },
    sampleHandlingRiskDescription: {
        label: "Please provide more detail",
    },
    containsHazardousSubstances: {
        label: "Does this sample contain any hazardous substances?",
    },
    containsHazardousSubstancesDescription: {
        label: "Please provide more detail",
    },
};

const validateForm = (values: NewSchema) => {
    try {
        zodSchema.parse(values);
    } catch (error) {
        if (error instanceof ZodError) {
            return error.formErrors.fieldErrors;
        }
    }
};

export const SampleModal = (props: SampleModalProps) => {
    const snack = useSnackbar();

    const initialValues = useMemo(() => {
        return props.sample ? mapDBSampleToForm(props.sample) : defaultValues;
    }, [props.sample]);

    const form = useFormik({
        validateOnMount: true,
        initialValues: initialValues,
        onSubmit: () => {},
        validate: (values) => {
            const newValues = removeEmptyStringValues<NewSchema>(values);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
            return validateForm(newValues as any);
        },
    });

    const isEdit = props.sample;
    const isEnabled = form.isValid;
    const isDisabled = !isEnabled;

    const get = <Name extends keyof NewSchema>(
        name: Name,
    ): {
        onChange: typeof form.handleChange;
        onBlur: typeof form.handleBlur;
        value: NewSchema[Name];
        name: Name;
        errorMessage: string | undefined;
    } => {
        const thisError = form.errors[name];
        let error: string | undefined;

        if (typeof thisError === "string") {
            error = thisError;
        } else if (
            Array.isArray(thisError) &&
            typeof thisError[0] === "string"
        ) {
            error = thisError[0];
        } else {
            error = undefined;
        }

        return {
            onChange: form.handleChange,
            onBlur: form.handleBlur,
            value: form.values[name],
            name,
            errorMessage: form.touched[name] ? error : undefined,
        };
    };

    const getCheckbox = <Name extends keyof NewSchema>(
        name: Name,
    ): {
        onChange: typeof form.handleChange;
        onBlur: typeof form.handleBlur;
        checked: NewSchema[Name];
        name: Name;
        errorMessage: string | undefined;
    } => {
        const thisError = form.errors[name];
        let error: string | undefined;

        if (typeof thisError === "string") {
            error = thisError;
        } else if (
            Array.isArray(thisError) &&
            typeof thisError[0] === "string"
        ) {
            error = thisError[0];
        } else {
            error = undefined;
        }

        return {
            onChange: form.handleChange,
            onBlur: form.handleBlur,
            checked: form.values[name],
            name,
            errorMessage: form.touched[name] ? error : undefined,
        };
    };

    const close = () => {
        form.resetForm();
        props.onClose();
    };

    const submit = async () => {
        const marshalled = removeEmptyStringValues(form.values);
        // Using any as removeEmptyStringValues messes with the type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-argument
        const isInvalid = validateForm(marshalled as any);
        if (isInvalid) {
            return;
        }

        try {
            await props.onSubmit(mapFormToSampleType(form.values));
            close();
        } catch {
            snack.error({
                title: "An error occurred",
                description: "Your sample could not be added.",
            });
        }
    };

    return (
        props.isOpen && (
            <form onSubmit={submit}>
                <Modal
                    id={isEdit ? "update-sample" : "create-sample"}
                    showProgress
                    isOpen={props.isOpen}
                    motionPreset="none"
                    onClose={props.onClose}
                    onOverlayClick={props.onClose}
                    header={isEdit ? "Update Sample" : "Create Sample"}
                    buttonRight={
                        <LoadingButton
                            awaitPromise
                            onClick={submit}
                            isLoading
                            type="submit"
                            isDisabled={isDisabled}
                            colorScheme="blue"
                        >
                            {isEdit ? "Update" : "Create"}
                        </LoadingButton>
                    }
                >
                    <Box display="flex" flexDirection="column" gap="14px">
                        <Text textStyle="h5">General</Text>
                        <Box display="flex" flexDirection="column" gap="18px">
                            <InputField
                                label={formatLabel(
                                    sample.name,
                                    sample.name.help,
                                )}
                                {...get("name")}
                                help={sample.name.help}
                            />
                            {isEdit ? (
                                <InputField
                                    autoComplete="off"
                                    label={formatLabel({
                                        label: sample.qr_code.label,
                                    })}
                                    {...get("qrCode")}
                                />
                            ) : null}
                            <InputField
                                label={formatLabel(sample.substance)}
                                help={sample.substance.help}
                                {...get("substance")}
                            />
                            <InputField
                                label={formatLabel(sample.cas_number)}
                                {...get("casNumber")}
                                help={sample.cas_number.help}
                            />
                            <InputField
                                label={formatLabel(
                                    sample.composition,
                                    sample.composition.help,
                                )}
                                placeholder="NaCl"
                                {...get("composition")}
                            />

                            <SelectField
                                options={formOptions}
                                label={formatLabel(sample.form)}
                                {...get("form")}
                            />
                            <TextField
                                label={formatLabel(sample.formDescription)}
                                {...get("formDescription")}
                                help="e.g. estimated particle size"
                            />
                        </Box>
                        <Divider my="4px" />
                        <Text textStyle="h5">Hazards</Text>
                        <Box display="flex" flexDirection="column" gap="12px">
                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.toxic)}
                                {...getCheckbox("toxic")}
                            />
                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.flammable)}
                                {...getCheckbox("flammable")}
                            />
                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.corrosive)}
                                {...getCheckbox("corrosive")}
                            />

                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.oxidizing)}
                                {...getCheckbox("oxidizing")}
                            />
                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.airSensitive)}
                                {...getCheckbox("airSensitive")}
                            />

                            <CheckboxField
                                my="4px"
                                label={formatLabel(sample.otherHazards)}
                                {...getCheckbox("otherHazards")}
                            />

                            {form.values.otherHazards && (
                                <TextField
                                    label={formatLabel(
                                        sample.otherHazardsDescription,
                                    )}
                                    {...get("otherHazardsDescription")}
                                />
                            )}

                            <CheckboxField
                                label={formatLabel(sample.sampleHandlingRisk)}
                                {...getCheckbox("sampleHandlingRisk")}
                            />

                            {form.values.sampleHandlingRisk && (
                                <TextField
                                    label={formatLabel(
                                        sample.sampleHandlingRiskDescription,
                                    )}
                                    {...get("sampleHandlingRiskDescription")}
                                />
                            )}

                            <CheckboxField
                                label={formatLabel(
                                    sample.containsHazardousSubstances,
                                )}
                                {...getCheckbox("containsHazardousSubstances")}
                            />

                            {form.values.containsHazardousSubstances && (
                                <TextField
                                    label={formatLabel(
                                        sample.containsHazardousSubstancesDescription,
                                    )}
                                    {...get(
                                        "containsHazardousSubstancesDescription",
                                    )}
                                />
                            )}
                        </Box>
                    </Box>
                </Modal>
            </form>
        )
    );
};
