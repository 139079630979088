// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, PropsWithChildren } from "react";

import PropTypes from "prop-types";

import { LayoutPanel } from "./derived";
import PanelEmpty from "./PanelEmpty";

type Props = {
    visible?: boolean;
    noPadding?: boolean;
};

const TraceRequiredPanel: FC<PropsWithChildren<Props>> = (
    { children, visible = true, ...rest },
    context,
) => {
    const hasTrace =
        context.fullData.filter((trace) => trace.visible).length > 0;

    if (!visible) {
        return null;
    }

    return hasTrace ? (
        <LayoutPanel {...rest}>{children}</LayoutPanel>
    ) : (
        <PanelEmpty heading="Looks like there aren't any traces defined yet.">
            <span>
                {"Go to the "}
                <a onClick={() => context.setPanel("Structure", "Traces")}>
                    Traces
                </a>
                {" panel under Structure to define traces."}
            </span>
        </PanelEmpty>
    );
};

export default TraceRequiredPanel;

TraceRequiredPanel.contextTypes = {
    fullData: PropTypes.array,
    setPanel: PropTypes.func,
};
