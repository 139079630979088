import React, { Component } from "react";

import { connectToContainer } from "lib";
import PropTypes from "prop-types";

import Field from "./Field";

import { DataSelector, MultiColorPicker, Numeric, Radio } from "../index";
import RadioBlocks from "../widgets/RadioBlocks";

class ErrorBars extends Component {
    constructor(props, context) {
        super(props, context);
        this.updatePlot = this.updatePlot.bind(this);
    }

    updatePlot(value) {
        if (value === "symmetric") {
            this.props.updatePlot({
                ...this.props.fullValue,
                visible: true,
                symmetric: true,
            });
        }

        if (value === "asymmetric") {
            this.props.updatePlot({
                ...this.props.fullValue,
                visible: true,
                symmetric: false,
            });
        }

        if (value === "hidden") {
            this.props.updatePlot({
                ...this.props.fullValue,
                visible: false,
            });
        }
    }

    getMode() {
        let mode;

        if (!this.props.fullValue.visible) {
            mode = "hidden";
        }

        if (
            this.props.fullValue.visible &&
            (this.props.fullValue.symmetric ||
                typeof this.props.fullValue.symmetric === "undefined")
        ) {
            // when this.props.fullValue.type === 'sqrt',
            // then this.props.fullValue.symmetric is undefined, but 'sqrt' is only
            // applicable when we want symmetric error bars
            // https://github.com/plotly/plotly.js/issues/2359
            mode = "symmetric";
        }

        if (
            this.props.fullValue.visible &&
            this.props.fullValue.symmetric === false
        ) {
            // it has to be explicitly set to false, because we don't want it to catch
            // cases when it's undefined
            mode = "asymmetric";
        }

        return mode;
    }

    renderModeSelector() {
        return (
            <Field>
                <RadioBlocks
                    alignment="center"
                    onOptionChange={this.updatePlot}
                    activeOption={this.getMode()}
                    options={[
                        { label: "None", value: "hidden" },
                        { label: "Symmetric", value: "symmetric" },
                        { label: "Asymmetric", value: "asymmetric" },
                    ]}
                />
            </Field>
        );
    }

    renderErrorBarControls() {
        const mode = this.getMode();
        const showCustomDataControl = this.props.fullValue.type === "data";

        const styleAttrs = (
            <>
                <Radio
                    label={"Copy Y Style"}
                    attr={`${this.props.attr}.copy_ystyle`}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                />
                <Radio
                    label={"Copy Z Style"}
                    attr={`${this.props.attr}.copy_zstyle`}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                />
                <MultiColorPicker
                    label={"Color"}
                    attr={`${this.props.attr}.color`}
                />
                <Numeric
                    label={"Thickness"}
                    attr={`${this.props.attr}.thickness`}
                />
                <Numeric
                    label={"Crossbar Width"}
                    attr={`${this.props.attr}.width`}
                />
            </>
        );

        if (mode === "symmetric") {
            return (
                <>
                    <Radio
                        label={"Error Type"}
                        attr={`${this.props.attr}.type`}
                        options={[
                            { label: "%", value: "percent" },
                            { label: "Constant", value: "constant" },
                            { label: "√", value: "sqrt" },
                            { label: "Data", value: "data" },
                        ]}
                    />
                    <Numeric
                        label={"Value"}
                        attr={`${this.props.attr}.value`}
                    />
                    {showCustomDataControl ? (
                        <DataSelector
                            label={"Custom Data"}
                            attr={`${this.props.attr}.array`}
                        />
                    ) : null}
                    {styleAttrs}
                </>
            );
        }

        if (mode === "asymmetric") {
            return (
                <>
                    <Radio
                        label={"Error Type"}
                        attr={`${this.props.attr}.type`}
                        options={[
                            { label: "%", value: "percent" },
                            { label: "Constant", value: "constant" },
                            { label: "Data", value: "data" },
                        ]}
                    />
                    <Numeric
                        label={"Value"}
                        attr={`${this.props.attr}.value`}
                    />
                    <Numeric
                        label={"Value (-)"}
                        attr={`${this.props.attr}.valueminus`}
                    />
                    {showCustomDataControl ? (
                        <>
                            <DataSelector
                                label={"Error (+)"}
                                attr={`${this.props.attr}.array`}
                            />
                            <DataSelector
                                label={"Error (-)"}
                                attr={`${this.props.attr}.arrayminus`}
                            />
                        </>
                    ) : null}
                    {styleAttrs}
                </>
            );
        }

        return null;
    }

    render() {
        return (
            <>
                {this.renderModeSelector()}
                {this.renderErrorBarControls()}
            </>
        );
    }
}

ErrorBars.propTypes = {
    attr: PropTypes.string,
    fullValue: PropTypes.object,
    updatePlot: PropTypes.func,
};

export default connectToContainer(ErrorBars);
