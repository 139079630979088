import { useState } from "react";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";

import type { components } from "../types/v1";

type SyncSafeParams = components["schemas"]["SyncSaveESRFPayload"];
export type SyncConnectParams = components["schemas"]["SyncConnectESRFPayload"];

export const useSync = ({
    appendLog,
}: {
    appendLog: (log: string) => void;
}) => {
    const api = useAPI();

    const [isRunning, setIsRunning] = useState(false);
    const [isDone, setIsDone] = useState(false);

    return {
        isRunningSync: isRunning,
        isDoneSync: isDone,
        sync: async (params: SyncSafeParams) => {
            setIsRunning(true);
            const r = await fetch(`${api.basePath}/admin/esrf/sync-save`, {
                method: "POST",
                body: JSON.stringify(params),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${api.token}`,
                },
            });

            const reader = r.body
                ?.pipeThrough(new TextDecoderStream())
                .getReader();

            if (!reader) {
                setIsRunning(false);
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    setIsRunning(false);
                    setIsDone(true);
                    break;
                }
                appendLog(value);
            }
        },
    };
};

export const useConnect = ({
    appendLog,
}: {
    appendLog: (log: string) => void;
}) => {
    const api = useAPI();
    const [isDone, setIsDone] = useState(false);
    const [isRunning, setIsRunning] = useState(false);

    return {
        isRunning,
        isDone,
        connect: async (
            params: Omit<
                SyncConnectParams,
                "masktspdf" | "maskhrxrpd" | "ponitspdf" | "ponihrxrpd"
            > & {
                masktspdf: File;
                maskhrxrpd: File;
                ponihrxrpd: File;
                ponitspdf: File;
            },
        ) => {
            setIsRunning(true);

            const form = new FormData();

            form.append("beamline", params.beamline);
            form.append("maskhrxrpd", params.maskhrxrpd);
            form.append("access", params.access);
            form.append("masktspdf", params.masktspdf);
            form.append("ponihrxrpd", params.ponihrxrpd);
            form.append("beamtimeId", params.beamtimeId);
            form.append("ponitspdf", params.ponitspdf);
            form.append("timecode", params.timecode);
            form.append("json", params.json);

            const r = await fetch(`${api.basePath}/admin/esrf/sync-connect`, {
                method: "POST",
                body: form,
                headers: {
                    Authorization: `Bearer ${api.token}`,
                },
            });

            const reader = r.body
                ?.pipeThrough(new TextDecoderStream())
                .getReader();

            if (!reader) {
                setIsRunning(false);
                return;
            }

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    setIsRunning(false);
                    setIsDone(true);
                    break;
                }
                appendLog(value);
            }
        },
    };
};
