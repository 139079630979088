import { type FC, useState } from "react";
import { useDebounce } from "react-use";

import { Box, Center, HStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import type { OrganizationModel } from "@app/domain/api/agent/organization.ts";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { organizationsQueryKey, useAgentClient } from "src/api/client";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { routes } from "src/App/Routes/routes";
import { DataTable } from "src/packages/components/DataTable";
import { SearchFilterBar } from "src/packages/components/Input/SearchFilterBar";
import { PageHeader } from "src/packages/components/Layout/PageHeader";
import { PageLayout } from "src/packages/components/Layout/PageLayout";
import { formatDate } from "src/packages/tools/formatter/localization";

export const OrganizationsPage: FC = () => {
    const [filter, setFilter] = useState<string | null>(null);
    const [bouncingFilter, setBouncingFilter] = useState<string | null>(null);
    useDebounce(() => setFilter(bouncingFilter), 300, [bouncingFilter]);

    const client = useAgentClient();
    const organizationsQuery = useQuery({
        queryKey: [...organizationsQueryKey(), { filter }],
        queryFn: () =>
            client.GET("/organizations", {
                params: {
                    query: {
                        first: 1000,
                        fuzzy: filter || undefined,
                    },
                },
            }),
    });

    const navigate = useNavigate();

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <PageHeader
                    crumbs={[
                        {
                            id: "organizations",
                            isCurrentPage: true,
                            label: "Organizations",
                        },
                    ]}
                    subtitle="All organizations"
                    menuActions={[]}
                />

                <HStack>
                    <SearchFilterBar
                        onReset={() => setBouncingFilter(null)}
                        onChange={(x) => setBouncingFilter(x.target.value)}
                        placeholder="Filter by name"
                    />
                </HStack>

                <Box flex="1" w="100%" overflow="hidden">
                    {organizationsQuery.isFetching ? (
                        <Center width="100%" h="100%">
                            <LoadingSwirl />
                        </Center>
                    ) : (
                        <DataTable<OrganizationModel>
                            variant="unstyled"
                            isSortable
                            columns={[
                                {
                                    accessorKey: "name",
                                    header: "Name",
                                    cell: (x) => (
                                        <OrganizationLink
                                            label={x.row.original.name}
                                            organizationId={x.row.original.id}
                                        ></OrganizationLink>
                                    ),
                                },
                                {
                                    accessorKey: "id",
                                    header: "Id",
                                },
                                {
                                    accessorKey: "createdAt",
                                    header: "Created At",
                                    cell: (x) =>
                                        formatDate(x.getValue() as string),
                                },
                                {
                                    accessorKey: "updatedAt",
                                    header: "Updated At",
                                    cell: (x) =>
                                        formatDate(x.getValue() as string),
                                },
                            ]}
                            data={organizationsQuery.data?.data?.items ?? []}
                            getRowId={(row) => row.id}
                            onRowClick={(x) => {
                                void navigate({
                                    to: routes.agent.organization.url,
                                    params: { organizationId: x.id },
                                });
                            }}
                        />
                    )}
                </Box>
            </Box>
        </PageLayout>
    );
};
