import type { FC } from "react";
import { useState } from "react";

import type { Promisable } from "type-fest";

import {
    Box,
    Button,
    Center,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    useDisclosure,
} from "@chakra-ui/react";

import { SamplesEmptyState } from "@app-components/sample/SamplesEmptyState.tsx";

import type { ToggleItem } from "@mt-components/Button/ToggleButtonBar.tsx";
import { ToggleButtonBar } from "@mt-components/Button/ToggleButtonBar.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";

import { Icon } from "@mt-design/icons.tsx";

import type { agentSchema } from "src/api/client";
import { BeamtimeSetSSW } from "src/App/components/beamtime/BeamtimeSetSSW.modal";
import { OrganizationLink } from "src/App/components/OrganizationLink";

type TableMeta = {
    // isDisabled?: boolean;
    // onDelete?: (id: string) => void;
    onUpdate: (
        data: agentSchema.components["schemas"]["BeamtimeSample"],
    ) => void;
    // onCreate?: (data: Omit<SampleType, "id">) => void;
};

const wideVisibility = {};
const narrowVisibility = {
    sampleTasks_xrd: true,
    sampleTasks_saxs: true,
    sampleTasks_pdf: true,
    sample_qrCode: true,
    sampleStickWellIds: true,
    sample_casNumber: false,
    sample_substance: true,
    sample_composition: true,
    sample_name: true,
    sample_otherHazards: false,
    sample_toxic: false,
    sample_flammable: false,
    sample_airSensitive: false,
    sample_sampleHandlingRisk: false,
    sample_sampleHandlingRiskDescription: false,
    sample_formDescription: false,
    sample_oxidizing: false,
    sample_corrosive: false,
    sample_containsHazardousSubstances: false,
    sample_containsHazardousSubstancesDescription: false,
};

type Props = {
    isWide?: boolean;
    beamtimeId: string;
    beamtimeSamples: agentSchema.components["schemas"]["BeamtimeSample"][];
    onUpdateSample: (
        data: agentSchema.components["schemas"]["BeamtimeSample"],
    ) => Promisable<void>;
};

export const BeamtimeSamples: FC<Props> = ({
    beamtimeId,
    beamtimeSamples,
    onUpdateSample,
}) => {
    const setSSWDisclosure = useDisclosure();
    const [buttonOption, setButtonOption] = useState("narrow");
    const [currentItem, setCurrentItem] = useState<
        agentSchema.components["schemas"]["BeamtimeSample"] | null
    >(null);

    const tableMeta: TableMeta = {
        onUpdate: onUpdateSample,
    };

    const toggleButtonBar: ToggleItem<"wide" | "narrow">[] = [
        {
            id: "wide",
            label: "Wide table",
            icon: <Icon.FourColumn />,
            ariaLabel: "expand table",
        },
        {
            id: "narrow",
            label: "Narrow table",
            icon: <Icon.TwoColumn />,
            ariaLabel: "collapse table",
        },
    ];

    const withFlagCell = (cell: { getValue(): unknown }) => (
        <YesNoTag
            value={cell.getValue() as boolean}
            colorSchema="rg"
        ></YesNoTag>
    );

    const withTaskTypeCell = (cell: { getValue(): unknown }) => (
        <YesNoTag
            value={cell.getValue() as boolean}
            colorSchema="bg"
        ></YesNoTag>
    );

    const withActions = (
        value: agentSchema.components["schemas"]["BeamtimeSample"],
    ) => {
        return (
            <Menu>
                <MenuButton as={Button} rightIcon={<Icon.ChevronDown />}>
                    Actions
                </MenuButton>
                <MenuList>
                    <MenuItem onClick={() => onUpdateSample(value)}>
                        Edit
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentItem(value);
                            setSSWDisclosure.onOpen();
                        }}
                    >
                        Set Stick Well Id
                    </MenuItem>
                </MenuList>
            </Menu>
        );
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            h="100%"
            w="100%"
            overflowY="auto"
        >
            {currentItem ? (
                <BeamtimeSetSSW
                    beamtimeId={beamtimeId}
                    sampleId={currentItem.sample.id}
                    sampleName={currentItem.sample.name}
                    isOpen={setSSWDisclosure.isOpen}
                    onClose={setSSWDisclosure.onClose}
                ></BeamtimeSetSSW>
            ) : null}

            {Boolean(beamtimeSamples.length) && (
                <Box py="12px">
                    <ToggleButtonBar
                        onToggle={setButtonOption}
                        option={buttonOption}
                        items={toggleButtonBar}
                    />
                </Box>
            )}
            {beamtimeSamples.length > 0 ? (
                <Box flex="1" w="100%" overflow="auto">
                    <DataTable<
                        agentSchema.components["schemas"]["BeamtimeSample"]
                    >
                        variant="unstyled"
                        state={{
                            columnVisibility:
                                buttonOption === "wide"
                                    ? wideVisibility
                                    : narrowVisibility,
                        }}
                        columns={[
                            {
                                accessorKey: "organizationName",
                                header: "Organization",
                                thProps: makeWidth("200px"),
                                cell: (x) => (
                                    <OrganizationLink
                                        organizationId={
                                            x.row.original.sample.organizationId
                                        }
                                        label={x.getValue() as string}
                                    ></OrganizationLink>
                                ),
                            },
                            {
                                accessorKey: "sample.name",
                                header: "Sample-ID",
                                thProps: makeWidth("220px"),
                            },
                            {
                                accessorKey: "sample.qrCode",
                                header: "QR-Code",
                                thProps: makeWidth("160px"),
                            },
                            {
                                accessorKey: "sampleStickWellIds",
                                header: "SSWs",
                                thProps: makeWidth("200px"),
                                cell: (x) => {
                                    const v = x.getValue() as string[];

                                    if (!v.length) {
                                        return (
                                            <>
                                                (0)
                                                <Icon.Failure
                                                    style={{
                                                        paddingLeft: "4px",
                                                        height: "21px",
                                                        display: "inline-block",
                                                        verticalAlign: "bottom",
                                                    }}
                                                />
                                            </>
                                        );
                                    }

                                    return (
                                        <>
                                            {v[0]}
                                            {v.length > 1 ? ", ..." : ""} (
                                            {v.length})
                                            <Icon.Success
                                                style={{
                                                    paddingLeft: "4px",
                                                    height: "21px",
                                                    display: "inline-block",
                                                    verticalAlign: "bottom",
                                                }}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                accessorKey: "sampleTasks.xrd",
                                header: "HR-XRPD",
                                thProps: makeWidth("90px"),
                                cell: withTaskTypeCell,
                            },
                            {
                                accessorKey: "sampleTasks.pdf",
                                header: "TS-PDF",
                                thProps: makeWidth("90px"),
                                cell: withTaskTypeCell,
                            },
                            {
                                accessorKey: "sampleTasks.saxs",
                                header: "SAXS",
                                thProps: makeWidth("90px"),
                                cell: withTaskTypeCell,
                            },
                            {
                                accessorKey: "sample.composition",
                                header: "Composition",
                                thProps: makeWidth("220px"),
                            },
                            {
                                accessorKey: "sample.substance",
                                header: "Substance",
                                thProps: makeWidth("300px"),
                            },
                            {
                                accessorKey: "sample.casNumber",
                                header: "CAS Number",
                                thProps: makeWidth("100px"),
                            },
                            {
                                accessorKey: "sample.form",
                                header: "Form",
                                thProps: makeWidth("140px"),
                            },
                            {
                                accessorKey: "sample.formDescription",
                                header: "Form Description",
                                thProps: makeWidth("300px"),
                            },
                            {
                                accessorKey: "sample.toxic",
                                header: "Toxic?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.flammable",
                                header: "Flammable?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.corrosive",
                                header: "Corrosive?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.oxidizing",
                                header: "Oxidizing?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.airSensitive",
                                header: "Air-sensitive?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.otherHazards",
                                header: "Other hazards?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey: "sample.sampleHandlingRisk",
                                header: "Sample handling risk?",
                                cell: withFlagCell,
                                thProps: makeWidth("60px"),
                            },
                            {
                                accessorKey:
                                    "sample.sampleHandlingRiskDescription",
                                header: "Details",
                                thProps: makeWidth("240px"),
                            },
                            {
                                accessorKey:
                                    "sample.containsHazardousSubstances",
                                header: "Contains hazardous substances?",
                                thProps: makeWidth("60px"),
                                cell: withFlagCell,
                            },
                            {
                                accessorKey:
                                    "sample.containsHazardousSubstancesDescription",
                                header: "Details",
                                thProps: makeWidth("240px"),
                            },
                            {
                                accessorKey: "menu",
                                header: " ",
                                cell: (cell) => withActions(cell.row.original),
                            },
                        ]}
                        data={beamtimeSamples}
                        isSortable
                        meta={tableMeta}
                    />
                </Box>
            ) : (
                <Center w="100%" h="100%">
                    <SamplesEmptyState />
                </Center>
            )}
        </Box>
    );
};

BeamtimeSamples.displayName = "BeamtimeSamples";

const bgSchema = (v: boolean) => (v ? "blue" : "gray");
const rgSchema = (v: boolean) => (v ? "red" : "green");

type YesNoTagProps = {
    value: boolean;
    colorSchema: "bg" | "rg";
};

const YesNoTag: FC<YesNoTagProps> = (props) => {
    const colorSchemaFn = (() => {
        switch (props.colorSchema) {
            case "bg":
                return bgSchema;
            case "rg":
                return rgSchema;
            default:
                throw new Error("Unknown color schema passed");
        }
    })();

    return (
        <Tag variant="solid" colorScheme={colorSchemaFn(props.value)}>
            <>{props.value ? "Yes" : "No"}</>
        </Tag>
    );
};
