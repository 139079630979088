const base = "";

export const routes = {
    profile: {
        url: `${base}/profile` as const,
    },
    agent: {
        home: {
            url: `${base}/agent` as const,
        },
        measurementGroups: {
            url: `${base}/agent/measurements` as const,
        },
        measurementGroupDetail: {
            url: `${base}/agent/organizations/$orgId/measurements/$orderId` as const,
        },
        requestResult: {
            url: `${base}/agent/organizations/$orgId/measurements/$orderId/results` as const,
        },
        samples: {
            url: `${base}/agent/samples` as const,
        },
        beamtimes: {
            url: `${base}/agent/beamtimes` as const,
        },
        beamtimesDetail: {
            url: `${base}/agent/beamtimes/$beamtimeId` as const,
        },
        beamtimeSync: {
            url: `${base}/agent/beamtimes/$beamtimeId/sync` as const,
        },
        plots: {
            url: `${base}/agent/plots` as const,
        },
        plotDetail: {
            url: `${base}/agent/organization/$orgId/plots/$plotId` as const,
        },
        packages: {
            url: `${base}/agent/packages` as const,
        },
        users: {
            url: `${base}/agent/users` as const,
        },
        user: {
            url: `${base}/agent/users/$userId` as const,
        },
        organizations: {
            url: `${base}/agent/organizations` as const,
        },
        organization: {
            url: `${base}/agent/organizations/$organizationId` as const,
        },
    },

    user: {
        home: {
            url: `${base}/` as const,
        },
        samples: {
            url: `${base}/organizations/$orgId/samples` as const,
        },
        measurementGroups: {
            url: `${base}/organizations/$orgId/measurements` as const,
        },
        measurementGroupDetail: {
            url: `${base}/organizations/$orgId/measurements/$orderId` as const,
        },
        requestResult: {
            url: `${base}/organizations/$orgId/measurements/$orderId/result` as const,
        },
        plots: {
            url: `${base}/organizations/$orgId/plots` as const,
        },
        plotDetails: {
            url: `${base}/organizations/$orgId/plots/$plotId` as const,
        },
        support: {
            url: `${base}/support` as const,
        },
    },
    notFound: `${base}/404` as const,
    login: {
        url: `${base}/login` as const,
    },
    organizations: {
        url: `${base}/organizations` as const,
    },
    organizationDetails: {
        url: `${base}/organizations/$orgId` as const,
    },
    signinCallback: {
        url: `${base}/signin-callback` as const,
    },
};

export const authURLS = {
    settings: "/auth/ui/settings",
    recovery: "/auth/ui/recovery",
};

export const externalURLs = {
    faq: "https://momentum-transfer.com/faq",
    helpDesk:
        "https://momentum-transfer.atlassian.net/servicedesk/customer/portal/1",
    recovery: "/auth/ui/recovery",
    samplePrepGuide:
        "https://momentum-transfer.com/sample_preparation_guide.pdf",
};

export const mailto = {
    support: "support@momentum-transfer.com",
};
