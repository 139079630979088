import * as React from "react";

import type { ChakraProviderProps } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import type { Dict } from "@chakra-ui/utils";

export interface SaasContextValue {
    linkComponent?: React.ElementType;
    onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

export const SaasContext = React.createContext<SaasContextValue>({});

export interface SaasProviderProps extends ChakraProviderProps {
    theme?: Dict;
    linkComponent?: React.ElementType;
    children: React.ReactNode;
    onError?: (error: Error, errorInfo: React.ErrorInfo) => void;
}

export function SaasProvider(props: SaasProviderProps) {
    const { theme, linkComponent, onError, children, ...rest } = props;

    const context = {
        linkComponent,
        onError,
    };

    return (
        <SaasContext.Provider value={context}>
            <ChakraProvider {...rest} theme={theme}>
                {children}
            </ChakraProvider>
        </SaasContext.Provider>
    );
}
