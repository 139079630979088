// import chakra theme
import { chakraTheme } from "@mt-design/chakra-theme.ts";

// imports for side-effects
import "@fontsource-variable/exo-2";
import "src/App/App.css";

export const theme = chakraTheme;

export type ThemeType = typeof theme;
