// eslint-disable-next-line simple-import-sort/imports
import type { FC } from "react";
import type { Size } from "react-virtualized-auto-sizer";
import AutoSizer from "react-virtualized-auto-sizer";

import type * as Plotly from "plotly.js";

import { Box } from "@chakra-ui/react";

import { Charty } from "components/Charty.tsx";

type GraphProps = {
    config: Partial<Plotly.Config>;
    layout: Partial<Plotly.Layout>;
    plots: Plotly.Data[];
    activeCharts: number[];
    height: number;
    minHeight?: number;
    minWidth?: number;
};

export const Graph: FC<GraphProps> = ({
    height,
    config,
    layout,
    plots,
    activeCharts,
    minWidth = 0,
    minHeight = 0,
}) => {
    const graphHeightPg = `${height}px`;

    return (
        <Box h="100%" display="block" w="100%" position="relative">
            <Box
                minH={graphHeightPg}
                maxH={graphHeightPg}
                w="100%"
                height="100%"
            >
                <AutoSizer>
                    {({ height, width }: Size) => {
                        const h = height < minHeight ? minHeight : height;
                        const w = width < minWidth ? minWidth : width;

                        return (
                            <Box width={w} height={h}>
                                <Charty
                                    divId="chart"
                                    config={config}
                                    data={plots.filter((_, idx) =>
                                        activeCharts.includes(idx),
                                    )}
                                    layout={{
                                        ...layout,
                                        width: w,
                                        height: h,
                                    }}
                                />
                            </Box>
                        );
                    }}
                </AutoSizer>
            </Box>
        </Box>
    );
};
