import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";

import { downloadFetchBlob } from "@mt-tools/io/downloadFile.ts";

import type { MeasurementGroupModel } from "src/App/domain";

export const downloadReport =
    (api: APIContextValue) => async (model: MeasurementGroupModel) => {
        const res = await api.client.GET(
            "/organizations/{organizationId}/measurement-groups/{measurementGroupId}/report",
            {
                params: {
                    path: {
                        organizationId: model.organizationId,
                        measurementGroupId: model.id,
                    },
                },
                parseAs: "blob",
            },
        );

        downloadFetchBlob(res);
    };
