import type { FC, ReactNode } from "react";

import { Box, Breadcrumb, BreadcrumbItem, Text } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

export type Crumb = {
    isCurrentPage?: boolean;
    label: ReactNode;
    id: string;
};

type CrumbsProps = {
    items: Crumb[];
};

export const Crumbs: FC<CrumbsProps> = ({ items }) => {
    return (
        <Breadcrumb
            spacing="8px"
            separator={<Icon.ChevronRight color="gray.500" />}
        >
            {items.map((i) => {
                return (
                    <BreadcrumbItem
                        h="24px"
                        whiteSpace="nowrap"
                        maxWidth="360px"
                        overflow="hidden"
                        key={i.id}
                        isCurrentPage={i.isCurrentPage}
                        textStyle="hx"
                    >
                        {typeof i.label === "string" ? (
                            <Text
                                maxWidth="340px"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                            >
                                {i.label}
                            </Text>
                        ) : (
                            <Box maxWidth="340px" overflow="hidden">
                                {i.label}
                            </Box>
                        )}
                    </BreadcrumbItem>
                );
            })}
        </Breadcrumb>
    );
};
