import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

import { alertTheme } from "@mt-design/alertOverride.ts";
import { customOverrides } from "@mt-design/baseStyles.ts";
import { baseTheme } from "@mt-design/baseTheme.ts";
import { buttonTheme } from "@mt-design/button.theme.ts";
import { checkBoxTheme } from "@mt-design/checkbox.theme.ts";
import { menuTheme } from "@mt-design/menu.theme.ts";
import { modalTheme } from "@mt-design/modal.theme.ts";
import { components } from "@mt-design/saas-ui";
import { tableTheme } from "@mt-design/tableStyles.ts";
import { tooltipheme } from "@mt-design/tooltipStyles.ts";

import "src/App/App.css";

export const theme2 = extendTheme(baseTheme, customOverrides, {
    components,
});

const theme3 = extendTheme(
    withDefaultColorScheme({ colorScheme: "secondary" }),
    theme2,
);

const theme4 = extendTheme(
    {
        ...buttonTheme,
        shadows: {
            outline: `0 0 0 3px ${customOverrides.colors.secondary["300"]}`,
        },
    },
    tooltipheme,
    theme3,
    tableTheme,
);

// export const theme2 = extendTheme(baseTheme, customOverrides);

export const theme5 = extendTheme(
    buttonTheme,
    checkBoxTheme,
    alertTheme,
    theme4,
);

export const theme6 = extendTheme(theme5, modalTheme);
export const chakraTheme = extendTheme(theme6, menuTheme);
export default chakraTheme;
