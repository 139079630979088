import type { FC, PropsWithChildren } from "react";

import { Box, Center } from "@chakra-ui/react";

import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

export const LoadingOverlay: FC<PropsWithChildren> = ({ children }) => {
    const ctx = useLoadingContext();

    return (
        <>
            <Center
                display={ctx.isLoading ? "flex" : "none"}
                position={ctx.isLoading ? "fixed" : "absolute"}
                height="100%"
                width="100%"
                alignItems="center"
                justifyContent="center"
            >
                <LoadingSwirl />
            </Center>
            <Box
                height="100%"
                visibility={ctx.isLoading ? "hidden" : "visible"}
            >
                {children}
            </Box>
        </>
    );
};

LoadingOverlay.displayName = "LoadingOverlay";
