import type { FC } from "react";

import { Box } from "@chakra-ui/react";

import { NotFound } from "@app-components/shell/errors/NotFound/NotFound.tsx";

import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

export const NotFoundPage: FC = () => {
    return (
        <PageLayout>
            <Box
                overflow="hidden"
                maxW="1200px"
                mx="auto"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <NotFound />
            </Box>
        </PageLayout>
    );
};

NotFoundPage.displayName = "NotFoundPage";
