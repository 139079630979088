/* eslint-disable */
// @ts-nocheck
import type { MouseEvent } from "react";
import React, { memo, useRef } from "react";
import type { TableComponents } from "react-virtuoso";
import { TableVirtuoso } from "react-virtuoso";
import { Icon } from "@mt-design/icons.tsx";
import type {
    SystemStyleObject,
    ThemingProps,
    BoxProps,
} from "@chakra-ui/react";
import {
    chakra,
    Checkbox,
    forwardRef,
    Table,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import type {
    Cell,
    ColumnDef as ColumnDefBase,
    ColumnSort,
    Header,
    Row,
    RowData,
    Table as TableInstance,
    TableOptions,
} from "@tanstack/react-table";
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { useLatestRef } from "@mt-hooks/useLatestRef.tsx";

import type { IndexBoolean } from "@mt-tools/types.ts";

export type ColumnDef<TData extends RowData, TValue = unknown> = ColumnDefBase<
    TData,
    TValue
> & {
    stopBubbling?: boolean;
    thProps?: BoxProps;
};

export type { TableInstance };

export const makeWidth = (value: string) => ({
    minWidth: value,
    maxWidth: value,
    width: value,
});

export interface DataTableProps<Data extends object>
    extends Omit<TableOptions<Data>, "getCoreRowModel">,
        ThemingProps<"Table"> {
    /**
     * The TableInstance reference
     */
    instanceRef?: React.Ref<TableInstance<Data>>;
    /**
     * Enable sorting on all columns
     */
    isSortable?: boolean;
    /**
     * Enable row selection
     */
    isSelectable?: boolean;
    /**
     * Triggers whenever the row selection changes.
     * @params rows The selected row id's
     */
    onSelectedRowsChange?: (rows: Array<string>) => void;
    /**
     * Triggers when sort changed.
     * Use incombination with `manualSortBy` to enable remote sorting.
     */
    onSortChange?: (columns: ColumnSort[]) => void;
    /**
     * The table class name attribute
     */
    className?: string;
    /**
     * Table styles
     */
    sx?: SystemStyleObject;
    onRowClick?: (row: Data) => void;
    testId?: string;
    style?: React.CSSProperties;

    columns: (ColumnDef<Data, any> & { thProps?: TableCellProps })[];
}

const stopBubbling = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
};

const rowSx = {
    transition: "all 150ms ease-out",
};
const hoverRow = {
    bg: "mtblue.100",
    cursor: "pointer",
};

const headerFontStyle = {
    fontWeight: "semibold",
    fontSize: "13px",
    color: "gray.700",
};
const lastChildBorderRadius = {
    borderTopRightRadius: "8px",
    ...headerFontStyle,
};

const firstChildBorderRadius = {
    borderTopLeftRadius: "8px",
    ...headerFontStyle,
};

const components: TableComponents<Row<object>, DataTableProps<object>> = {
    Table: ({ context, ...props }) => {
        return (
            <Table
                data-test-id={context.testId}
                className="sui-data-table"
                size={context?.size}
                variant={context?.variant}
                colorScheme={context?.colorScheme}
                sx={context?.sx}
                {...props}
            />
        );
    },
    TableHead: React.forwardRef(({ ...props }, ref) => (
        <Thead
            ref={ref}
            sx={{
                zIndex: "2 !important",
                borderCollapse: "collapse",
                th: {
                    ...headerFontStyle,
                    ":last-of-type": lastChildBorderRadius,
                    ":first-of-type": firstChildBorderRadius,
                },
            }}
            borderBottomWidth="0"
            {...props}
        />
    )),
    TableRow: ({ context, ...props }) => (
        <Tr
            key={props.item.id}
            width="100%"
            sx={rowSx}
            _hover={(context?.isSelectable || context?.onRowClick) && hoverRow}
            bg={props.item.getIsSelected() ? "mtblue.50" : "unset"}
            {...props}
        />
    ),
};

const selectionColumnId = "selection";

export const DataTable = React.forwardRef(
    <Data extends object>(props: DataTableProps<Data>) => {
        const {
            instanceRef,
            columns,
            isSortable,
            isSelectable,
            onSelectedRowsChange,
            onSortChange,
            colorScheme,
            size,
            variant,
            className,
            sx,
            onRowClick,
            ...rest
        } = props;

        const instance = useReactTable<Data>({
            columns: React.useMemo(() => {
                return getSelectionColumn<Data>(isSelectable).concat(
                    columns.map((column: any) => {
                        if (!column.accessorKey) {
                            column.accessorKey = column.accessor || column.id;
                        }
                        if (!column.header && column.Header) {
                            column.header = column.Header;
                        }
                        if (!column.cell && column.Cell) {
                            column.cell = column.Cell;
                        } else if (!column.cell) {
                            column.cell = DataTableCell;
                        }
                        return column;
                    }),
                );
            }, []),
            enableRowSelection: isSelectable,
            getSortedRowModel: isSortable ? getSortedRowModel() : undefined,
            ...rest,
            getCoreRowModel: getCoreRowModel(),
        });

        const { rows } = instance.getRowModel();

        // This exposes the useTable api through the instanceRef
        React.useImperativeHandle(instanceRef, () => instance, [instanceRef]);

        const state = instance.getState();

        const onSelectedRowsChangeRef = useLatestRef(onSelectedRowsChange);

        const selectionRef = useRef<IndexBoolean | undefined>();

        React.useEffect(() => {
            const diff = JSON.stringify(
                selectionRef.current ?? "",
            ).localeCompare(JSON.stringify(state.rowSelection));

            selectionRef.current = state.rowSelection;

            if (!diff) {
                return;
            }
            onSelectedRowsChangeRef.current?.(Object.keys(state.rowSelection));
        }, [state.rowSelection]);

        const sortRef = useRef();

        React.useEffect(() => {
            const diff = JSON.stringify(sortRef.current ?? "").localeCompare(
                JSON.stringify(state.sorting),
            );
            if (!diff) {
                return;
            }
            onSortChange?.(state.sorting);
        }, [onSortChange, state.sorting]);

        return (
            <TableVirtuoso
                increaseViewportBy={1200}
                context={props}
                components={components}
                data={rows}
                style={props.style}
                itemContent={(_, row) => {
                    return (
                        <>
                            {row.getVisibleCells().map((cell) => {
                                const meta = (cell.column.columnDef.meta ||
                                    {}) as any;
                                return (
                                    <Td
                                        key={cell.id}
                                        {...cell.column.columnDef.thProps}
                                        onClick={() => {
                                            if (cell.column.id === "menu") {
                                                return;
                                            }

                                            void onRowClick?.(row.original);
                                            if (isSelectable) {
                                                if (
                                                    cell.column.id !==
                                                    selectionColumnId
                                                ) {
                                                    instance.setRowSelection(
                                                        () => {
                                                            return {
                                                                [cell.row.id]:
                                                                    !row.getIsSelected(),
                                                            };
                                                        },
                                                    );
                                                }
                                            }
                                        }}
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                        textOverflow="ellipsis"
                                        isNumeric={meta.isNumeric}
                                    >
                                        {flexRender(
                                            cell.column.columnDef.cell,
                                            cell.getContext(),
                                        )}
                                    </Td>
                                );
                            })}
                        </>
                    );
                }}
                fixedHeaderContent={() =>
                    instance.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <DataTableHeader
                                    key={header.id}
                                    header={header}
                                    isSortable={isSortable}
                                />
                            ))}
                        </Tr>
                    ))
                }
            />
        );
    },
) as (<Data extends object>(
    props: DataTableProps<Data> & {
        ref?: React.ForwardedRef<HTMLTableElement>;
    },
) => React.ReactElement) & { displayName?: string };

DataTable.displayName = "DataTable";

export interface DataTableSortProps<Data extends object, TValue> {
    header: Header<Data, TValue>;
}
export const DataTableSort = <Data extends object, TValue>(
    props: DataTableSortProps<Data, TValue>,
) => {
    const { header, ...rest } = props;

    const sorterStyles = {
        ms: 2,
    };

    if (header.id === selectionColumnId) {
        return null;
    }

    const sorted = header.column.getIsSorted();

    return (
        <chakra.span
            __css={sorterStyles}
            {...rest}
            position="absolute"
            left="-2px"
            top="15px"
        >
            {sorted ? (
                sorted === "desc" ? (
                    <Icon.ArrowDown aria-label="sorted descending" />
                ) : (
                    <Icon.ArrowUp aria-label="sorted ascending" />
                )
            ) : (
                ""
            )}
        </chakra.span>
    );
};

DataTableSort.displayName = "DataTableSort";

export interface DataTableHeaderProps<Data extends object, TValue> {
    header: Header<Data, TValue>;
    isSortable?: boolean;
}
export const DataTableHeader = <Data extends object, TValue>(
    props: DataTableHeaderProps<Data, TValue>,
) => {
    const { header, isSortable, ...rest } = props;

    let headerProps = {};

    const enabled = !header.column.getCanSort() ? false : isSortable;

    if (enabled) {
        headerProps = {
            className: "saas-data-table__sortable",
            userSelect: "none",
            cursor: "pointer",
            onClick: header.column.getToggleSortingHandler(),
        };
    }

    const meta = (header.column.columnDef.meta || {}) as any;
    return (
        <Th
            position="relative"
            colSpan={header.colSpan}
            textTransform="none"
            isNumeric={meta.isNumeric}
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            color="gray.800"
            backgroundColor="gray.100"
            borderBottom="none"
            {...headerProps}
            {...rest}
        >
            {flexRender(header.column.columnDef.header, header.getContext())}
            {enabled && <DataTableSort header={header} />}
        </Th>
    );
};

DataTableHeader.displayName = "DataTableHeader";

const getResult = <Data extends object>(
    fn: (row: Data) => string,
    params: Data,
): string => {
    if (typeof fn === "function") {
        return fn(params);
    }
    return fn;
};

export const DataTableCell = memo(
    <Data extends object, TValue>(props: Cell<Data, TValue>) => {
        const { column, row, getValue } = props;

        const meta = (column.columnDef.meta || {}) as any;

        return getValue() || null;
    },
);

DataTableCell.displayName = "DataTableCell";

const DataTableCheckbox = forwardRef((props, ref) => {
    return <Checkbox ref={ref} size="lg" onClick={stopBubbling} {...props} />;
});

DataTableCheckbox.displayName = "DataTableCheckbox";

const getSelectionColumn = <Data extends object>(enabled?: boolean) => {
    return enabled
        ? [
              {
                  id: selectionColumnId,
                  size: 1,
                  header: ({ table }) => (
                      <DataTableCheckbox
                          isChecked={table.getIsAllRowsSelected()}
                          isIndeterminate={table.getIsSomeRowsSelected()}
                          onChange={table.getToggleAllRowsSelectedHandler()}
                          aria-label={
                              table.getIsAllRowsSelected()
                                  ? "Deselect all rows"
                                  : "Select all rows"
                          }
                      />
                  ),
                  cell: ({ row }) => (
                      <DataTableCheckbox
                          isChecked={row.getIsSelected()}
                          isIndeterminate={row.getIsSomeSelected()}
                          aria-label={
                              row.getIsSelected()
                                  ? "Deselect row"
                                  : "Select row"
                          }
                          onChange={row.getToggleSelectedHandler()}
                          onClick={stopBubbling}
                      />
                  ),
                  thProps: {
                      maxWidth: "72px",
                      minWidth: "72px",
                      width: "72px",
                  },
                  // <DataTableCheckbox
                  //     isChecked={row.getIsSelected()}
                  //     isIndeterminate={row.getIsSomeSelected()}
                  //     aria-label={
                  //         row.getIsSelected()
                  //             ? "Deselect row"
                  //             : "Select row"
                  //     }
                  // />
              } as ColumnDef<Data>,
          ]
        : [];
};
