import type { FC } from "react";
import { useState } from "react";

import { Box, Button, FormControl, FormLabel, Input } from "@chakra-ui/react";

import type { OrganizationModel } from "@app/domain/api/organization.ts";

import { Modal } from "@mt-components/Modal/Modal.tsx";

type Props = {
    isOpen: boolean;
    initialData?: OrganizationModel;
    onCreate?: (params: { name: string }) => void;
    onUpdate?: (params: { name: string }) => void;
    onCancel: () => void;
};
export const OrganizationModal: FC<Props> = ({
    onCreate,
    onUpdate,
    onCancel,
    isOpen,
    initialData,
}) => {
    const isUpdate = Boolean(initialData);
    const [title, setTitle] = useState<string>(initialData?.name ?? "");

    const onClick = () => {
        if (!title) {
            return;
        }

        if (initialData?.name) {
            onUpdate?.({
                name: title,
            });
        }

        onCreate?.({
            name: title,
        });
    };

    return (
        <Modal
            header={isUpdate ? "Update Organization" : "New Organization"}
            isOpen={isOpen}
            onClose={onCancel}
            onOverlayClick={onCancel}
            buttonRight={
                <Button
                    colorScheme="blue"
                    isDisabled={!title}
                    onClick={onClick}
                >
                    {isUpdate ? "Confirm" : "Create"}
                </Button>
            }
        >
            <Box w="100%">
                <FormControl onSubmit={onClick}>
                    <FormLabel mt="4">Name</FormLabel>
                    <Input
                        required
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                    />
                </FormControl>
            </Box>
        </Modal>
    );
};
