import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export function MeasurementGroupEmptyState(props: { onClick: () => void }) {
    return (
        <EmptyState
            icon={() => <Icon.MeasurementsImage height="120px" width="120px" />}
            heading="No Requests"
            body="Start with your first measurement."
            onClick={props.onClick}
            buttonLabel="Create request"
        />
    );
}
