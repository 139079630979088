import type { FC, ReactNode } from "react";

import type { BoxProps } from "@chakra-ui/react";

import {
    Property,
    PropertyLabel,
    PropertyList,
    PropertyValue,
} from "@mt-components/Input/Property";

type Prop = {
    id: string;
    label: ReactNode;
    value: ReactNode;
    valueProps?: BoxProps;
};

type PropertiesProps = {
    items: Prop[];
};

export const Properties: FC<PropertiesProps> = ({ items }) => {
    return (
        <PropertyList fontSize="14px" my="12px" w="100%">
            {items.map((x) => {
                return (
                    <Property key={x.id}>
                        <PropertyLabel width="120px">{x.label}</PropertyLabel>
                        <PropertyValue
                            color="gray.800"
                            fontSize="14px"
                            w="100px"
                            textAlign="left"
                            {...x.valueProps}
                        >
                            {x.value}
                        </PropertyValue>
                    </Property>
                );
            })}
        </PropertyList>
    );
};
