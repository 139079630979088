import type { SystemStyleObject } from "@chakra-ui/react";
import { createContext } from "@chakra-ui/react-context";

export const [SidebarStylesProvider, useSidebarStyles] = createContext<
    Record<string, SystemStyleObject>
>({
    name: "SidebarStylesContext",
    hookName: "useSidebarStyles",
    providerName: "<Sidebar />",
});
