import { json2csv } from "json-2-csv";
import type { FetchResponse } from "openapi-fetch";

/**
 * Requires fetch to be in blob mode and response to contain content-disposition header set
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const downloadFetchBlob = (res: FetchResponse<any, any>) => {
    const filename = (() => {
        const header = res.response.headers.get("Content-Disposition");
        const parts = header!.split(";");
        let name = parts[1].split("=")[1];

        if (name.startsWith('"')) {
            name = name.slice(1);
        }

        if (name.endsWith('"')) {
            name = name.slice(0, -1);
        }
        return name;
    })();

    downloadFile(URL.createObjectURL(res.data as unknown as Blob), filename);
};

export const downloadFile = (encodedUri: string, filename: string) => {
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
};

export const downloadFileNative = (url: string) => {
    const a = document.createElement("a");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export type Field<TData> = {
    key: string;
    label: string;
    format: (v: TData) => string;
};

export const formatDataForDownload = <GData>(
    spec: Field<GData>[],
    data: GData[],
) => {
    const data2 = data.map((s) => {
        return spec.reduce((agg, current) => {
            const value = current.format(s);

            return {
                ...agg,
                [current.key]: value,
            };
        }, {});
    });
    const keys = spec.map(({ key }) => key);

    return {
        keys,
        data: data2,
    };
};

export const downloadArrayAsCSV = <TData extends object>(
    data: TData[],
    keys: string[],
    fileName = "sample-list.csv",
) => {
    const csvContent = json2csv(data, {
        keys,
        delimiter: {
            field: ",",
            eol: "\r\n",
        },
    });

    const blob = new Blob([csvContent], { type: "text/plain" });
    const link = document.createElement("a");
    link.setAttribute("href", URL.createObjectURL(blob));

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
};
