import { type FC } from "react";

import { useFormik } from "formik";
import type { Promisable } from "type-fest";
import { z, ZodError } from "zod";

import { Box, Text } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { OrganizationRoles } from "@app/domain/services/organization.ts";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { InputField } from "@mt-components/Input/InputField.tsx";
import { StaticField2 } from "@mt-components/Input/StaticField2.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { organizationAuthzQueryKey, usePublicClient } from "src/api/client";

type Props = {
    isOpen: boolean;
    organizationId: string;
    onClose: () => Promisable<unknown>;
};

const zodSchema = z.object({
    email: z.string().trim().email(),
    role: z.enum([
        OrganizationRoles.OWNER,
        OrganizationRoles.VIEWER,
        OrganizationRoles.COLLABORATOR,
    ]),
});

type Schema = z.infer<typeof zodSchema>;

const validateForm = (values: Schema) => {
    try {
        zodSchema.parse(values);
    } catch (error) {
        if (error instanceof ZodError) {
            return error.formErrors.fieldErrors;
        }
    }
};

export const InviteUserModal: FC<Props> = ({
    organizationId,
    onClose,
    isOpen,
}) => {
    const client = usePublicClient();
    const queryClient = useQueryClient();

    const createAuthzMutation = useMutation({
        mutationFn: async (vars: { email: string }) => {
            const res = await client.POST(
                "/organizations/{organizationId}/authzs",
                {
                    params: { path: { organizationId } },
                    body: {
                        email: vars.email.trim(),
                        role: "owner",
                    },
                },
            );

            if (res.error) {
                throw new Error("Failed to add user");
            }

            return res;
        },
        onSuccess: (_data, vars) => {
            queryClient.invalidateQueries({
                queryKey: organizationAuthzQueryKey(organizationId),
            });
            snackbar.success({
                description: (
                    <>
                        {vars.email} added to the organization as{" "}
                        <Text as="span" fontWeight="600">
                            owner
                        </Text>
                    </>
                ),
            });
            onClose();
        },
        onError: (_error, vars) => {
            snackbar.error({
                description: (
                    <>{vars.email} could not be added to the organization.</>
                ),
            });
            onClose();
        },
    });

    const snackbar = useSnackbar();

    const form = useFormik<Schema>({
        initialValues: {
            email: "",
            role: OrganizationRoles.OWNER,
        },
        onSubmit: () => {},
        validateOnBlur: true,
        validateOnChange: false,
        validate: (values) => {
            return validateForm(values);
        },
    });

    return (
        <Modal
            header="Invite user"
            isOpen={isOpen}
            onClose={onClose}
            onOverlayClick={onClose}
            buttonRight={
                <LoadingButton
                    awaitPromise
                    colorScheme="blue"
                    isDisabled={!form.isValid}
                    onClick={() => {
                        if (!zodSchema.safeParse(form.values).success) {
                            return;
                        }

                        createAuthzMutation.mutate(form.values);
                    }}
                >
                    Confirm
                </LoadingButton>
            }
        >
            <Box w="100%" h="95px" overflow="auto" px="4px" my="12px">
                <Box display="flex" justifyContent="flex-start" gap="12px">
                    <InputField
                        name="email"
                        label="Email address"
                        value={form.values.email}
                        errorMessage={form.errors.email}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    <StaticField2 title="Role" value="Owner" />
                </Box>
            </Box>
        </Modal>
    );
};
