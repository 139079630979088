import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const parts = ["container", "dropzone", "button"];
const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts);

const baseStyle = definePartsStyle({
    dropzone: {
        "display": "flex",
        "flexDirection": "column",
        "alignItems": "center",
        "justifyContent": "center",
        "gap": 4,
        "minHeight": 1,
        "borderRadius": "md",
        "borderWidth": 1,
        "borderStyle": "dashed",
        "py": 4,
        "px": 6,
        "transitionProperty": "common",
        "transitionDuration": "normal",
        "&[data-dragging]:not([data-disabled])": {
            bg: "blackAlpha.100",
            _dark: {
                bg: "whiteAlpha.100",
            },
        },
    },
});

export const fileUploadTheme = defineMultiStyleConfig({
    defaultProps: {},
    baseStyle,
});
