import { partial } from "ramda";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import type { RequestBodyForOperation } from "@mt-tools/fetch.ts";
import { StatusCodes } from "@mt-tools/http/status-codes.ts";

const entityKey = QueryKeys.sample;

type GetSampleRequest = {
    params: operations["getSample"]["parameters"];
};

type CreateSamplePayload = RequestBodyForOperation<operations["createSample"]>;
type CreateSampleRequest = {
    params: operations["createSample"]["parameters"];
    body: CreateSamplePayload;
};

type PatchSamplePayload = RequestBodyForOperation<operations["patchSample"]>;
type PatchSampleRequest = {
    params: operations["patchSample"]["parameters"];
    body: PatchSamplePayload;
};

type DeleteSampleRequest = {
    params: operations["deleteSample"]["parameters"];
};

export type { CreateSamplePayload, PatchSamplePayload };

export type SampleModel = components["schemas"]["Sample"];

async function getAdminSamples(
    api: APIContextValue,
    params: operations["getAdminSamples"]["parameters"],
) {
    return await api.client.GET("/admin/samples", {
        params: applyPageSizeDefault(params),
    });
}

type GetSampleParams = operations["getSamples"]["parameters"];

const fetchSamples = (api: APIContextValue, params: GetSampleParams) => {
    return api.client.GET("/organizations/{organizationId}/samples", {
        params: applyPageSizeDefault(params),
    });
};

const useGetAll = (data: {
    params: operations["getSamples"]["parameters"];
}) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(data)],
        queryFn: async () => {
            const fetcher = partial(fetchSamples, [api]);
            const samples = await collectAllPages<
                SampleModel,
                GetSampleParams,
                typeof fetcher
            >(fetcher, data.params);

            return samples;
        },
    });
};

const useAdminGetAll = (
    request: {
        params?: operations["getAdminSamples"]["parameters"];
    } = {},
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(request)],
        queryFn: async () => {
            const fetcher = partial(getAdminSamples, [api]);

            const data = await collectAllPages<
                SampleModel,
                operations["getAdminSamples"]["parameters"],
                typeof fetcher
            >(fetcher, request.params);
            return data;
        },
    });
};

const useGet = (data: GetSampleRequest) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(data)],
        queryFn: async () => {
            if (!data.params.path.sampleId) {
                return undefined;
            }
            return await api.client.GET(
                "/organizations/{organizationId}/samples/{sampleId}",
                {
                    params: data.params,
                },
            );
        },
    });
};

const useCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: CreateSampleRequest) => {
            const r = await api.client.POST(
                "/organizations/{organizationId}/samples",
                {
                    params: data.params,
                    body: data.body,
                },
            );
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useCreateMany = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (payloads: CreateSampleRequest[]) => {
            const proms = payloads.map((p) => {
                return api.client.POST(
                    "/organizations/{organizationId}/samples",
                    p,
                );
            });
            await Promise.all(proms);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: PatchSampleRequest) => {
            const response = await api.client.PATCH(
                "/organizations/{organizationId}/samples/{sampleId}",
                {
                    params: data.params,
                    body: data.body,
                },
            );
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return response;
        },
    });
};

const useDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: DeleteSampleRequest) => {
            const response = await api.client.DELETE(
                "/organizations/{organizationId}/samples/{sampleId}",
                {
                    params: data.params,
                },
            );
            return response;
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

const useDeleteMany = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (data: {
            organizastionId: string;
            sampleIds: string[];
        }) => {
            const success: string[] = [];
            const usedInMeasurements: string[] = [];
            const unknownError: string[] = [];

            const promisses = data.sampleIds.map(async (id) => {
                const proms = await api.client.DELETE(
                    "/organizations/{organizationId}/samples/{sampleId}",
                    {
                        params: {
                            path: {
                                organizationId: data.organizastionId,
                                sampleId: id,
                            },
                        },
                    },
                );

                return proms;
            });

            const settled = await Promise.allSettled(promisses);

            settled.forEach((s) => {
                if (s.status === "fulfilled" && s.value.response.ok) {
                    success.push("1");
                    return;
                }

                if (
                    s.status === "fulfilled" &&
                    s.value.response.status === StatusCodes.BAD_REQUEST &&
                    s.value.error?.code === "SAMPLE_HAS_TASKS"
                ) {
                    usedInMeasurements.push("1");
                    return;
                }

                unknownError.push("1");
            });

            return {
                success,
                usedInMeasurements,
                unknownError,
            };
        },
        onError: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
        onSuccess: async () => {
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
        },
    });
};

export const Sample = {
    useGetAll,
    useAdminGetAll,
    useGet,
    useCreate,
    useCreateMany,
    useUpdate,
    useDelete,
    useDeleteMany,
};
