import type { FC } from "react";

import { Center } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type { MeasurementGroupStatusType } from "@app/domain";
import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import { Organization } from "@app/domain/api/organization.ts";
import { Request } from "@app/domain/api/request.ts";
import { Requests } from "@app/pages/agent/requests/Requests.tsx";
import { agent } from "@app/Routes/agentRoutes.tsx";

import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

export const RequestsContainer: FC = () => {
    const navigate = useNavigate();
    const { status } = agent.measurementGroups.useSearch();
    const requestsQuery = Request.useAdminGetAll();
    const beamtimeQuery = Beamtime.useAdminGetAll();
    const organizationsQuery = Organization.useAdminGetAll();

    const onSelectStatus = (status?: MeasurementGroupStatusType) => {
        void navigate({
            to: agent.measurementGroups.id,
            search: {
                status,
            },
        });
    };

    if (
        !requestsQuery.data ||
        !organizationsQuery.data ||
        !beamtimeQuery.data?.data
    ) {
        return (
            <Center w="100%" h="100%">
                <LoadingSpinner />
            </Center>
        );
    }

    return (
        <Requests
            measurementGroups={requestsQuery.data}
            organizations={organizationsQuery.data}
            beamtimes={beamtimeQuery.data.data}
            onSelectStatus={onSelectStatus}
            selectedStatus={status}
        />
    );
};

RequestsContainer.displayName = "RequestsContainer";
