import type { MutableRefObject } from "react";
import { useRef, useState } from "react";

import type { IScannerControls } from "@zxing/browser";
import { BrowserQRCodeReader } from "@zxing/browser";

export type UseQrReaderHookProps = {
    /**
     * Media constraints object, to specify which camera and capabilities to use
     */
    constraints?: MediaTrackConstraints;
    scanDelay?: number;
    videoId?: string;
};

type ScanState =
    | {
          type: "initial";
      }
    | {
          type: "reading";
      }
    | {
          type: "success";
          text: string;
      }
    | {
          type: "error";
          message: string;
      };

export const useQrReader = ({
    scanDelay: delayBetweenScanAttempts,
    constraints: video,
    videoId,
}: UseQrReaderHookProps) => {
    const [status, setStatus] = useState<ScanState>({ type: "initial" });
    const controlsRef: MutableRefObject<IScannerControls | null> = useRef(null);
    const scannerRef = useRef<BrowserQRCodeReader>(
        new BrowserQRCodeReader(undefined, {
            delayBetweenScanAttempts,
        }),
    );

    const start = () => {
        if (status.type !== "initial") {
            return;
        }
        setStatus({
            type: "reading",
        });

        void scannerRef.current
            .decodeFromConstraints(
                { video },
                videoId,
                (result, error, ctrls) => {
                    if (error) {
                        setStatus({
                            type: "error",
                            message: error.message,
                        });
                        return;
                    }

                    if (result) {
                        ctrls.stop();
                        setStatus({
                            type: "success",
                            text: result.getText(),
                        });
                    }
                },
            )
            .then((c) => {
                controlsRef.current = c;
            });
    };

    const stop = () => {
        controlsRef.current?.stop();
    };

    return {
        status: status.type,
        start,
        stop,
        scanner: scannerRef.current,
        result: status.type === "success" ? status.text : null,
        error: status.type === "error" ? status.message : null,
    };
};
