import type {
    ChangeEventHandler,
    FC,
    FocusEventHandler,
    ReactNode,
} from "react";

import type { BoxProps, InputProps } from "@chakra-ui/react";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    forwardRef,
    Input,
} from "@chakra-ui/react";

export type InputFieldProps<GElement> = {
    label: ReactNode;
    placeholder?: string;
    name?: string;
    onChange?: ChangeEventHandler<GElement>;
    onBlur?: FocusEventHandler<GElement>;
    value?: string;
    help?: ReactNode;
    errorMessage?: string;
    formControlProps?: BoxProps;
};

export const InputField: FC<InputFieldProps<HTMLInputElement> & InputProps> =
    forwardRef(
        (
            { name, errorMessage, label, help, formControlProps, ...rest },
            ref,
        ) => {
            return (
                <FormControl
                    isInvalid={Boolean(errorMessage)}
                    px="4px"
                    {...formControlProps}
                >
                    <FormLabel mb={1} width="100%">
                        {label}
                    </FormLabel>
                    <Input name={name} ref={ref} {...rest} />
                    {(errorMessage || help) && (
                        <FormHelperText
                            color={!errorMessage ? "gray" : "red.600"}
                        >
                            {!errorMessage ? help : errorMessage}
                        </FormHelperText>
                    )}
                </FormControl>
            );
        },
    );
