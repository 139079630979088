import type { FC } from "react";

import type { TextareaProps } from "@chakra-ui/react";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    forwardRef,
    Textarea,
} from "@chakra-ui/react";

import type { InputFieldProps } from "@mt-components/Input/InputField.tsx";

export const TextField: FC<
    InputFieldProps<HTMLTextAreaElement> & TextareaProps
> = forwardRef(
    ({ errorMessage, label, help, formControlProps, ...rest }, ref) => {
        return (
            <FormControl
                isInvalid={Boolean(errorMessage)}
                px="4px"
                {...formControlProps}
            >
                <FormLabel mb={1} width="100%">
                    {label}
                </FormLabel>
                <Textarea
                    rows={2}
                    minH="64px"
                    maxH="200px"
                    ref={ref}
                    {...rest}
                />
                {(errorMessage || help) && (
                    <FormHelperText color={!errorMessage ? "gray" : "red.600"}>
                        {!errorMessage ? help : errorMessage}
                    </FormHelperText>
                )}
            </FormControl>
        );
    },
);
