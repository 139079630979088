/* eslint-disable */
import React from "react";

import type { HTMLMotionProps } from "framer-motion";
import { motion } from "framer-motion";

import type {
    HTMLChakraProps,
    ResponsiveValue,
    SystemProps,
    SystemStyleObject,
    ThemingProps,
    UseBreakpointOptions,
} from "@chakra-ui/react";
import {
    chakra,
    forwardRef,
    omitThemingProps,
    useBreakpointValue,
    useDisclosure,
    useMultiStyleConfig,
    useTheme,
} from "@chakra-ui/react";
import { cx, dataAttr } from "@chakra-ui/utils";

import { useAppShellContext } from "@mt-components/AppShell/context.tsx";
import { getBreakpoints } from "@mt-components/Sidebar/sidebar-utils.tsx";
import {
    SidebarStylesProvider,
    useSidebarStyles,
} from "@mt-components/Sidebar/sidebarContext.tsx";
import { SidebarProvider } from "@mt-components/Sidebar/useSidebar.tsx";

const normalize = (variant: any, toArray?: (value: any) => any) => {
    if (Array.isArray(variant)) return variant;
    else if (typeof variant === "object") return toArray?.(variant);
    if (variant != null) return [variant];
    return [];
};

export const useResponsiveValue = (
    value: ResponsiveValue<unknown>,
    options?: UseBreakpointOptions,
) => {
    const theme = useTheme();

    const normalized = normalize(value, theme.__breakpoints?.toArrayValue);
    return useBreakpointValue(normalized, options);
};

export interface SidebarOptions {
    /**
     * Spacing between child elements.
     */
    spacing?: SystemProps["margin"];
    /**
     * Define the for the mobile nav. Use `false` to disable the mobile nav.
     *
     * @default "lg"
     */
    toggleBreakpoint?: false | "sm" | "md" | "lg" | "xl" | "2xl";
    /**
     * Control the visibility of the sidebar.
     */
    isOpen?: boolean;
    /**
     * Callback invoked when the sidebar is opened.
     */
    onOpen?: () => void;
    /**
     * Callback invoked when the sidebar is closed.
     */
    onClose?: () => void;
    /**
     * The transition used when opening and closing the sidebar.
     */
    motionPreset?: "slideInOut" | "none";
}

export interface SidebarProps
    extends SidebarOptions,
        Pick<
            HTMLMotionProps<"div">,
            "onAnimationStart" | "onDrag" | "onDragStart" | "onDragEnd"
        >,
        Omit<
            HTMLChakraProps<"div">,
            "css" | "onAnimationStart" | "onDrag" | "onDragStart" | "onDragEnd"
        >,
        ThemingProps<"SuiSidebar"> {}

export interface SidebarSectionProps extends HTMLChakraProps<"div"> {
    direction?: SystemProps["flexDirection"];
}

const MotionBox = chakra(motion.nav);

const motionPresets = {
    slideInOut: {
        enter: {
            left: 0,
            transition: { type: "spring", duration: 0.6, bounce: 0.15 },
        },
        exit: {
            left: "-100%",
        },
    },
    none: {},
};

/**
 * Side navigation, commonly used as the primary navigation
 *
 * @see Docs https://saas-ui.dev/docs/components/layout/sidebar
 */
export const Sidebar = forwardRef<SidebarProps, "nav">((props, ref) => {
    const styles = useMultiStyleConfig("SuiSidebar", props);

    const theme = useTheme();
    const defaultProps = theme.components["SuiSidebar"]?.defaultProps;

    const variant = useResponsiveValue(props.variant ?? defaultProps?.variant, {
        fallback: "base",
    });
    const size = useResponsiveValue(props.size ?? defaultProps?.size, {
        fallback: "base",
    });

    const isCondensed = variant === "compact";

    const {
        spacing = 4,
        children,
        toggleBreakpoint = "lg",
        className,
        motionPreset = "slideInOut",
        isOpen: isOpenProp,
        onOpen: onOpenProp,
        onClose: onCloseProp,
        ...containerProps
    } = omitThemingProps(props);

    const appShell = useAppShellContext();
    const breakpoints = getBreakpoints(toggleBreakpoint);

    const isMobile = useBreakpointValue(breakpoints, {
        fallback: undefined,
    });
    // we check this twice to avoid SSR issues.
    const isMobileInitial = useBreakpointValue(breakpoints);
    const isInitial = typeof isMobile === "undefined";
    const isControlled = typeof isOpenProp !== "undefined";
    const isCollapsible = (isMobile || isControlled) && !isCondensed;

    const disclosure = useDisclosure({
        isOpen: isOpenProp || appShell.isSidebarOpen,
        onOpen: onOpenProp || appShell.openSidebar,
        onClose: onCloseProp || appShell.closeSidebar,
    });

    const { isOpen, onClose, onOpen } = disclosure;

    React.useEffect(() => {
        if ((isInitial && isMobileInitial) || isCondensed || isControlled) {
            // make sure we do not show an initial animation or when this is a compact sidebar
            return;
        }
        isMobileInitial ? onClose() : onOpen();
    }, [isInitial, isCondensed, isMobileInitial]);

    const containerStyles: SystemStyleObject = {
        "& > *:not(style) ~ *:not(style, .sui-resize-handle, .sui-sidebar__toggle-button + *)":
            {
                marginTop: spacing,
            },
        "display": "flex",
        "flexDirection": "column",
        ...(isMobile && isCollapsible
            ? {
                  position: "absolute",
                  zIndex: "modal",
                  top: 0,
                  left: { base: "-100%", lg: "0" },
                  bottom: 0,
              }
            : {
                  position: "relative",
              }),
    };

    const context = {
        ...disclosure,
        breakpoints,
        isMobile,
        variant,
        size,
    };

    const variants =
        motionPresets[isCondensed ? "none" : motionPreset || "none"];

    return (
        <SidebarProvider value={context}>
            <SidebarStylesProvider value={styles}>
                <MotionBox
                    ref={ref}
                    initial={false}
                    animate={
                        !isInitial &&
                        (!isCollapsible || isOpen ? "enter" : "exit")
                    }
                    variants={variants}
                    __css={{
                        ...containerStyles,
                        ...styles.container,
                    }}
                    {...containerProps}
                    id={disclosure.getDisclosureProps().id}
                    className={cx("sui-sidebar", className)}
                    data-compact={dataAttr(isCondensed)}
                    data-collapsible={dataAttr(isMobile && isCollapsible)}
                >
                    {children}
                </MotionBox>
            </SidebarStylesProvider>
        </SidebarProvider>
    );
});

Sidebar.displayName = "Sidebar";
Sidebar.id = "Sidebar";

/**
 * Sidebar section that can contain sidebar items.
 *
 * @see Docs https://saas-ui.dev/docs/components/layout/sidebar
 */
export const SidebarSection: React.FC<SidebarSectionProps> = (props) => {
    const { direction = "column", ...rest } = props;
    const styles = useSidebarStyles();
    const sectionStyles = {
        display: "flex",
        flexDirection: direction,
        ...styles.section,
    };

    return (
        <chakra.div
            __css={sectionStyles}
            {...rest}
            className={cx("sui-sidebar__section", props.className)}
        />
    );
};

SidebarSection.displayName = "SidebarSection";
