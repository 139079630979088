import type { FC } from "react";
import { memo } from "react";

import { Spinner } from "@chakra-ui/react";

export const LoadingSpinner: FC = memo(() => {
    return <Spinner size="xl" />;
});

LoadingSpinner.displayName = "LoadingSpinner";
