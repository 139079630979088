/* eslint-disable react-refresh/only-export-components */
import type {
    ClearIndicatorProps,
    DropdownIndicatorProps,
    GroupBase,
    SelectComponentsConfig,
} from "react-select";

import { Center, IconButton } from "@chakra-ui/react";

import { onEnter } from "@mt-tools/interaction.ts";

import { Icon } from "@mt-design/icons.tsx";

export const ClearIndicator = (props: ClearIndicatorProps) => {
    const {
        clearValue,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <Center ref={ref} {...restInnerProps} color="gray.800" p={0}>
            <IconButton
                size="sm"
                onClick={() => clearValue()}
                onKeyDown={onEnter(clearValue)}
                variant="none"
                backgroundColor="unset"
                _hover={{
                    backgroundColor: "unset",
                }}
                aria-label="open menu"
                icon={<Icon.Close color="currentColor" />}
            />
        </Center>
    );
};
export const DropDownIndicator = ({ innerProps }: DropdownIndicatorProps) => {
    return (
        <Center
            w="36px"
            h="36px"
            {...innerProps}
            color="gray.800"
            cursor="pointer"
            tabIndex={0}
            p={0}
        >
            <IconButton
                p={0}
                variant="ghost"
                aria-label="open menu"
                icon={<Icon.ChevronDown size="18px" color="currentColor" />}
            />
        </Center>
    );
};
export const selectComponents: SelectComponentsConfig<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    GroupBase<any>
> = {
    ClearIndicator,
    DropdownIndicator: DropDownIndicator,
};
