import { z } from "zod";

import { Route } from "@tanstack/react-router";

import type { MeasurementGroupStatusType } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import { Dashboard } from "@app/pages/user/dashboard/Dashboard.tsx";
import { OrganizationDetailsPage } from "@app/pages/user/organizationDetail/OrganizationDetailPage.tsx";
import { OrganizationsPage } from "@app/pages/user/organizations/Organizations.container.tsx";
import { PlotDetailsPage } from "@app/pages/user/plot/PlotDetailsPage.tsx";
import { PlotsPage } from "@app/pages/user/plots/PlotsPage.tsx";
import { RequestDetailsContainer } from "@app/pages/user/requestDetails/RequestDetails.container.tsx";
import { RequestsContainer } from "@app/pages/user/requests/Requests.container.tsx";
import { SamplesContainer } from "@app/pages/user/samples/Samples.container.tsx";
import { SupportPage } from "@app/pages/user/support";
import { publicRoot } from "@app/Routes/publicRoute.tsx";
import { routes } from "@app/Routes/routes.ts";

import { zodEnum } from "@mt-tools/zod.ts";

import {
    ensureAuthAndUser,
    hasAccessToPlotting,
    isUserOrGoHome,
    notAuthGoToLogin,
} from "./beforeLoad.tsx";

const samplesPageSearchSchema = z.object({
    samples: z.array(z.string()).catch([]),
});

const statusTypes = Object.values(
    MeasurementGroupStatus,
) satisfies MeasurementGroupStatusType[];

const requestsPageSearchSchema = z.object({
    status: z.enum(zodEnum(statusTypes)).optional(),
});

export const user = {
    dashboard: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.user.home.url,
        component: Dashboard,
    }),
    measurementGroups: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.user.measurementGroups.url,
        component: RequestsContainer,
        validateSearch: (
            search: Record<string, unknown>,
        ): { status?: MeasurementGroupStatusType } =>
            requestsPageSearchSchema.parse(search),
    }),
    measurementGroupDetail: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.user.measurementGroupDetail.url,
        component: RequestDetailsContainer,
    }),
    samples: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.user.samples.url,
        component: SamplesContainer,
        validateSearch: (
            search: Record<string, unknown>,
        ): { samples: string[] } => samplesPageSearchSchema.parse(search),
    }),
    organizations: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.organizations.url,
        component: OrganizationsPage,
    }),
    organization: new Route({
        beforeLoad: ensureAuthAndUser,
        getParentRoute: () => publicRoot,
        path: routes.organizationDetails.url,
        component: OrganizationDetailsPage,
    }),
    plotRoute: new Route({
        beforeLoad: async (params) => {
            await isUserOrGoHome(params);
            await notAuthGoToLogin(params);
            await hasAccessToPlotting(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.user.plotDetails.url,
        component: PlotDetailsPage,
    }),
    plotsRoute: new Route({
        beforeLoad: async (params) => {
            await isUserOrGoHome(params);
            await notAuthGoToLogin(params);
            await hasAccessToPlotting(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.user.plots.url,
        component: PlotsPage,
    }),
    supportRoute: new Route({
        beforeLoad: async (params) => {
            await isUserOrGoHome(params);
            await notAuthGoToLogin(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.user.support.url,
        component: SupportPage,
    }),
};
