import type { FC } from "react";
import { memo, useCallback, useMemo, useState } from "react";

import { fromPairs } from "ramda";

import { Center, Text, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type {
    CreateSamplePayload,
    PatchSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { userSampleExportSpec } from "@app/domain/services/sample/ioMapping.ts";
import { Samples } from "@app/pages/user/samples/Samples.tsx";
import { routes } from "@app/Routes/routes.ts";
import { SampleModal } from "@app-components/modals/sample/Sample.modal.tsx";
import { UploadSamplesModal } from "@app-components/modals/sample/UploadSamples.modal.tsx";

import { LoadingSwirl } from "@mt-components/states/Loading/LoadingSwirl.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { StatusCodes } from "@mt-tools/http/status-codes.ts";
import {
    downloadArrayAsCSV,
    formatDataForDownload,
} from "@mt-tools/io/downloadFile.ts";

import { user } from "src/App/Routes/userRoutes";
import { useConfirm } from "src/packages/hooks/useConfirm.tsx";

const onDownloadTemplate = () => {
    const data: SampleModel[] = [
        {
            id: "ID",
            organizationId: "",
            corrosive: false,
            flammable: false,
            oxidizing: false,
            toxic: false,
            casNumber: "",
            qrCode: "",
            airSensitive: false,
            otherHazards: false,
            sampleHandlingRisk: false,
            containsHazardousSubstances: false,
            name: "Your sample id",
            substance: "The common name",
            composition: "NaCl",
            form: "powder",
            formDescription: "Additional information about the sample's form",
            otherHazardsDescription:
                "Additional information about other hazards",
            sampleHandlingRiskDescription:
                "Additional information if there's riks handling the sample",
            containsHazardousSubstancesDescription:
                "Additional information if the sample contains hazardous substances",
            createdAt: "",
            updatedAt: "",
        },
    ];

    const downloadable = formatDataForDownload(userSampleExportSpec, data);
    downloadArrayAsCSV(
        downloadable.data,
        downloadable.keys,
        "sample-import-template.csv",
    );
};

const onDownloadList = (samples: SampleModel[]) => {
    const downloadable = formatDataForDownload(userSampleExportSpec, samples);
    downloadArrayAsCSV(downloadable.data, downloadable.keys, "sample-list.csv");
};

export const SamplesContainer: FC = memo(() => {
    const { samples: selectedSampleIds } = user.samples.useSearch();
    const selectedSamples = useMemo(
        () => ({
            list: selectedSampleIds,
            obj: fromPairs(selectedSampleIds.map((id) => [id, true])),
        }),
        [selectedSampleIds],
    );

    const { currentOrg } = useOrgContext();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const createSampleDisclosure = useDisclosure();
    const uploadDisclosure = useDisclosure();

    const confirm = useConfirm({
        title: "Delete samples",
        body: (
            <>
                <Text>
                    Samples which are used for measurements can not be deleted.
                </Text>

                <Text mt="12px">
                    Are you sure you want to delete the selected samples?
                </Text>
            </>
        ),
    });

    const { mutateAsync: create } = Sample.useCreate();
    const { mutateAsync: update } = Sample.useUpdate();
    const samplesQuery = Sample.useGetAll({
        params: {
            path: { organizationId: currentOrg },
        },
    });

    const { mutateAsync: deleteSample } = Sample.useDelete();
    const { mutateAsync: deleteSamples } = Sample.useDeleteMany();

    const [sampleToUpdate, setSampleToUpdate] = useState<SampleModel | null>(
        null,
    );

    const onSelectSamples = useCallback(
        async (sampleSelection: { list: string[] }) => {
            await navigate({
                to: routes.user.samples.url,
                params: {
                    orgId: currentOrg,
                },
                search: {
                    samples: sampleSelection.list,
                },
            });
        },
        [currentOrg, navigate],
    );

    const onCreateSample = useCallback(
        async (data: CreateSamplePayload) => {
            const response = await create({
                params: {
                    path: {
                        organizationId: currentOrg,
                    },
                },
                body: data,
            });

            if (!response.response.ok) {
                snackbar.error({ title: "Could not create sample" });
                return;
            }
            snackbar.success({ title: "Sample created" });
        },
        [create, currentOrg, snackbar],
    );

    const onUpdateSample = useCallback(
        async (id: string, data: PatchSamplePayload) => {
            await update({
                params: {
                    path: {
                        sampleId: id,
                        organizationId: currentOrg,
                    },
                },
                body: data,
            });
        },
        [currentOrg, update],
    );

    const onUpdate = useCallback(
        (data: PatchSamplePayload) => {
            if (!sampleToUpdate?.id) {
                return;
            }
            void onUpdateSample(sampleToUpdate.id, data);
        },
        [sampleToUpdate, onUpdateSample],
    );

    const onDeleteSample = async (id: string) => {
        const execute = async () => {
            const response = await deleteSample({
                params: {
                    path: {
                        sampleId: id,
                        organizationId: currentOrg,
                    },
                },
            });

            if (
                response.response.status === StatusCodes.BAD_REQUEST &&
                response.error?.code === "SAMPLE_HAS_TASKS"
            ) {
                const message = "One sample could not be deleted.";

                snackbar.error({
                    title: "Error deleting sample",
                    description: message,
                });
            }
        };
        confirm({
            onConfirm: async () => {
                void onSelectSamples({ list: [] });
                await execute();
            },
        });
    };

    const onDeleteSamples = useCallback(
        async (ids: string[]) => {
            if (!ids.length) {
                return;
            }
            const onDeletes = async () => {
                const result = await deleteSamples({
                    sampleIds: ids,
                    organizastionId: currentOrg,
                });

                if (result.success.length) {
                    const message =
                        result.success.length === 1
                            ? "One sample deleted."
                            : `${result.success.length} samples deleted.`;

                    snackbar.success({
                        title: "Samples deleted",
                        description: message,
                    });
                }

                if (result.usedInMeasurements.length) {
                    const message =
                        result.usedInMeasurements.length === 1
                            ? "One sample could not be deleted."
                            : `${result.usedInMeasurements.length} samples could not be deleted, because they are used for measurements.`;

                    snackbar.error({
                        title: "Error deleting samples",
                        description: message,
                    });
                }

                if (result.unknownError.length) {
                    const message =
                        result.unknownError.length === 1
                            ? "One sample could not be deleted."
                            : `${result.unknownError.length} samples could not be deleted.`;

                    snackbar.error({
                        title: "Error deleting samples",
                        description: message,
                    });
                }
            };
            confirm({
                onConfirm: () => {
                    void onSelectSamples({ list: [] });
                    void onDeletes();
                },
            });
        },
        [confirm, currentOrg, deleteSamples, snackbar, onSelectSamples],
    );

    return (
        <>
            {createSampleDisclosure.isOpen && (
                <SampleModal
                    isOpen={createSampleDisclosure.isOpen}
                    onClose={createSampleDisclosure.onClose}
                    onSubmit={onCreateSample}
                />
            )}
            {sampleToUpdate && (
                <SampleModal
                    sample={sampleToUpdate}
                    isOpen={Boolean(sampleToUpdate)}
                    onClose={() => setSampleToUpdate(null)}
                    onSubmit={onUpdate}
                />
            )}
            {uploadDisclosure.isOpen && (
                <UploadSamplesModal
                    isOpen={uploadDisclosure.isOpen}
                    onCancel={uploadDisclosure.onClose}
                />
            )}
            {samplesQuery.data ? (
                <Samples
                    // selectedSamples={selectedSamples}
                    selectedSamples={selectedSamples}
                    samples={samplesQuery.data}
                    onSelectSamples={onSelectSamples}
                    onUploadSamples={uploadDisclosure.onOpen}
                    onDeleteSample={onDeleteSample}
                    onDeleteSamples={onDeleteSamples}
                    onToggleForm={createSampleDisclosure.onToggle}
                    onSelectSampleToUpdate={setSampleToUpdate}
                    onCreateSample={onCreateSample}
                    onDownloadList={onDownloadList}
                    onDownloadTemplate={onDownloadTemplate}
                />
            ) : (
                <Center w="100%" h="100%">
                    <LoadingSwirl />
                </Center>
            )}
        </>
    );
});

SamplesContainer.displayName = "SamplesContainer";
