import type { FC, ReactNode } from "react";
import { memo } from "react";
import { useLocalStorage } from "react-use";

import { Box, IconButton, Image, Spacer, Text } from "@chakra-ui/react";
import { Link, useMatchRoute } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { routes } from "@app/Routes/routes.ts";

import { ToggleIcon } from "@mt-components/icon/ToggleIcon.tsx";
import {
    Sidebar as SaasUISidebar,
    SidebarSection,
} from "@mt-components/Sidebar";
import { NavGroup } from "@mt-components/Sidebar/NavGroup/NavGroup.tsx";
import { NavItem } from "@mt-components/Sidebar/NavGroup/NavItem.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { useRoleContext } from "src/App/contexts/RoleContext";
import { Role } from "src/App/domain/auth";

import swirl from "@mt-assets/swirl_simple_brown.svg";

type NavItem = {
    name: string;
    href: string;
    icon: ReactNode;
    fuzzyMatch?: boolean;
    useOrgId?: boolean;
};

const dashboard: NavItem[] = [
    {
        name: "Dashboard",
        href: routes.agent.home.url,
        icon: <Icon.Home />,
    },
];

const admin: NavItem[] = [
    {
        name: "Organizations",
        href: routes.agent.organizations.url,
        icon: <Icon.Organization />,
    },
    {
        name: "Users",
        href: routes.agent.users.url,
        icon: <Icon.Users />,
        fuzzyMatch: true,
    },
    {
        name: "Profile",
        href: routes.profile.url,
        icon: <Icon.Profile />,
        fuzzyMatch: false,
    },
];

const operations: NavItem[] = [
    ...dashboard,
    {
        name: "Beamtimes",
        href: routes.agent.beamtimes.url,
        icon: <Icon.Beamtime />,
        fuzzyMatch: true,
    },
    {
        name: "Requests",
        href: routes.agent.measurementGroups.url,
        useOrgId: true,
        icon: <Icon.Form />,
        fuzzyMatch: true,
    },
    {
        name: "Samples",
        href: routes.agent.samples.url,
        icon: <Icon.Sample />,
        fuzzyMatch: true,
    },
    {
        name: "Packages",
        href: routes.agent.packages.url,
        icon: <Icon.Package />,
        fuzzyMatch: true,
    },
    {
        name: "Plots",
        href: routes.agent.plots.url,
        icon: <Icon.Charts />,
    },
];

const Group: FC<{
    showGroup?: boolean;
    title: string;
    items: NavItem[];
}> = ({ showGroup, title, items }) => {
    const matchRoute = useMatchRoute();

    if (!showGroup) {
        return (
            <>
                {items.map((item) => {
                    return (
                        <NavItem
                            size="md"
                            as={Link}
                            to={item.href}
                            key={item.name}
                            icon={item.icon}
                            isActive={matchRoute({
                                to: item.href,
                                fuzzy: item.fuzzyMatch,
                            })}
                        >
                            {item.name}
                        </NavItem>
                    );
                })}
            </>
        );
    }

    return (
        <NavGroup title={title} isCollapsible={false}>
            {items.map((item) => {
                return (
                    <NavItem
                        size="md"
                        textStyle="T2L100"
                        as={Link}
                        to={item.href}
                        key={item.name}
                        icon={item.icon}
                        isActive={matchRoute({
                            to: item.href,
                            fuzzy: item.fuzzyMatch,
                        })}
                    >
                        {item.name}
                    </NavItem>
                );
            })}
        </NavGroup>
    );
};

Group.displayName = "Group";

export const SidebarAgent: FC = memo(() => {
    const [value, setValue] = useLocalStorage(
        LocalStorageKeys.mainSidebar,
        true,
    );
    const disclosure = {
        isOpen: Boolean(value),
        onOpen: () => {
            setValue(true);
        },
        onClose: () => {
            setValue(false);
        },
        onToggle: () => {
            setValue(!value);
        },
    };
    const roleCtx = useRoleContext();

    return (
        <SaasUISidebar
            overflowY="auto"
            bg={roleCtx.role === Role.USER ? "gray.50" : "mt.50"}
            isOpen={disclosure.isOpen}
            toggleBreakpoint={false}
            colorScheme="white"
            borderRight="unset"
            // borderRightColor="gray.100"
            maxWidth="232px"
            minW="64px"
            variant={disclosure.isOpen ? "default" : "compact"}
            h="100%"
            display="flex"
            flexDirection="column"
        >
            <SidebarSection aria-label="Head">
                <Link to={routes.agent.home.url}>
                    <Box
                        display="flex"
                        height="68px"
                        overflow="hidden"
                        alignItems="center"
                    >
                        <Image
                            pl={disclosure.isOpen ? "8px" : 0}
                            maxWidth={disclosure.isOpen ? "50px" : "40px"}
                            src={swirl}
                            alt="Momentum Transfer"
                        />
                        {disclosure.isOpen && (
                            <Text
                                as="span"
                                h="34px"
                                fontSize="18px"
                                display="inline-block"
                                fontWeight="500"
                                lineHeight="16px"
                                pl="12px"
                            >
                                Momentum Transfer
                            </Text>
                        )}
                    </Box>
                </Link>
            </SidebarSection>
            <SidebarSection aria-label="Main">
                <Group
                    showGroup={disclosure.isOpen}
                    title="General"
                    items={operations}
                />
                <Group
                    showGroup={disclosure.isOpen}
                    title="Administration"
                    items={admin}
                />
            </SidebarSection>
            <SidebarSection mt="auto !important" direction="row">
                <Spacer />
                <IconButton
                    onClick={disclosure.onToggle}
                    variant="ghost"
                    size="sm"
                    icon={<ToggleIcon showLeft={!disclosure.isOpen} />}
                    aria-label="Toggle Sidebar"
                />
            </SidebarSection>
        </SaasUISidebar>
    );
});

SidebarAgent.displayName = "SidebarAgent";
