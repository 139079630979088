import type { ConfirmDialogOptions } from "@app/contexts/ModalsProvider";
import { useModals } from "@app/contexts/ModalsProvider";

// type Fn = () => Promise<void> | void;
export const useConfirm = (options?: ConfirmDialogOptions) => {
    const modals = useModals();

    return (opts: ConfirmDialogOptions) => {
        return modals.confirm({
            confirmProps: {
                colorScheme: "blue",
            },
            cancelProps: {
                colorScheme: "red",
            },
            ...options,
            ...opts,
        });
    };
};
