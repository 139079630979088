import React, { Component } from "react";

import PropTypes from "prop-types";

import {
    AxesFold,
    AxesRange,
    AxisSide,
    ColorPicker,
    Dropdown,
    DTicks,
    DTicksInterval,
    FontSelector,
    LayoutPanel,
    NTicks,
    Numeric,
    PlotlySection,
    Radio,
    Text,
    VisibilitySelect,
} from "../components";

class StyleAxesPanel extends Component {
    render() {
        return (
            <LayoutPanel {...this.props} collapsedOnStart>
                <AxesFold
                    name="Titles"
                    axisFilter={(axis) =>
                        !(
                            axis._name.includes("angular") ||
                            axis._subplot.includes("geo")
                        )
                    }
                >
                    <Text label="Title" attr="title.text" />

                    <Numeric
                        label="Font Size"
                        attr="title.font.size"
                        units="px"
                    />
                    <ColorPicker label="Font Color" attr="title.font.color" />
                </AxesFold>

                <AxesFold name="Range">
                    <PlotlySection name="Range" attr="autorange">
                        <Dropdown
                            attr="type"
                            label="Type"
                            clearable={false}
                            options={[
                                { label: "Linear", value: "linear" },
                                { label: "Log", value: "log" },
                            ]}
                        />
                        <Radio
                            attr="autorange"
                            label="Range"
                            options={[
                                { label: "Auto", value: true },
                                { label: "Custom", value: false },
                            ]}
                        />
                        <AxesRange label="Min" attr="range[0]" />
                        <AxesRange label="Max" attr="range[1]" />
                        <Numeric label="Min" attr="min" />
                    </PlotlySection>

                    <Dropdown
                        label="Direction"
                        attr="direction"
                        options={[
                            { label: "Clockwise", value: "clockwise" },
                            {
                                label: "Counter Clockwise",
                                value: "counterclockwise",
                            },
                        ]}
                        clearable={false}
                    />
                </AxesFold>

                <AxesFold name="Lines">
                    <PlotlySection name="Axis Line" attr="showline">
                        <VisibilitySelect
                            attr="showline"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                            showOn={true}
                            defaultOpt={true}
                        >
                            <Numeric
                                label="Thickness"
                                attr="linewidth"
                                units="px"
                            />
                            <ColorPicker label="Color" attr="linecolor" />

                            <AxisSide label="Position" attr="side" />
                            <Radio
                                label="Mirror Axis"
                                attr="mirror"
                                options={[
                                    { label: "On", value: "ticks" },
                                    { label: "Off", value: false },
                                ]}
                            />
                        </VisibilitySelect>
                    </PlotlySection>
                    <PlotlySection name="Grid Lines" attr="showgrid">
                        <VisibilitySelect
                            attr="showgrid"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                            showOn={true}
                            defaultOpt={true}
                        >
                            <Numeric
                                label="Thickness"
                                attr="gridwidth"
                                units="px"
                            />
                            <ColorPicker label="Color" attr="gridcolor" />

                            <Radio
                                label="Position On"
                                attr="tickson"
                                options={[
                                    { label: "Labels", value: "labels" },
                                    {
                                        label: "Boundaries",
                                        value: "boundaries",
                                    },
                                ]}
                            />

                            <DTicks label="Step Offset" attr="tick0" />
                            <DTicksInterval label="Step Size" attr="dtick" />
                            <NTicks label="Max Number of Lines" attr="nticks" />
                        </VisibilitySelect>
                    </PlotlySection>
                    <PlotlySection name="Zero Line" attr="zeroline">
                        <Radio
                            attr="zeroline"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                        />
                        <Numeric
                            label="Thickness"
                            attr="zerolinewidth"
                            units="px"
                        />
                        <ColorPicker label="Color" attr="zerolinecolor" />
                    </PlotlySection>

                    <PlotlySection name="Axis Background" attr="showbackground">
                        <Radio
                            attr="showbackground"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                        />
                        <ColorPicker label="Color" attr="backgroundcolor" />
                    </PlotlySection>
                </AxesFold>

                <AxesFold
                    name="Tick Labels"
                    axisFilter={(axis) => !axis._subplot.includes("geo")}
                >
                    <PlotlySection name={"Tick Labels"} attr="showticklabels">
                        <VisibilitySelect
                            attr="showticklabels"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                            showOn={true}
                            defaultOpt={true}
                        >
                            <AxisSide label="Position" attr="side" />
                            <Radio
                                label="Auto margins"
                                attr="automargin"
                                options={[
                                    { label: "True", value: true },
                                    { label: "False", value: false },
                                ]}
                            />
                            <Radio
                                label="Position on"
                                attr="tickson"
                                options={[
                                    { label: "Labels", value: "labels" },
                                    {
                                        label: "Boundaries",
                                        value: "boundaries",
                                    },
                                ]}
                            />

                            <Numeric
                                label="Font Size"
                                attr="tickfont.size"
                                units="px"
                            />
                            <ColorPicker
                                label="Font Color"
                                attr="tickfont.color"
                            />
                            <Dropdown
                                label="Angle"
                                attr="tickangle"
                                clearable={false}
                                options={[
                                    { label: "Auto", value: "auto" },
                                    { label: "45", value: 45 },
                                    { label: "90", value: 90 },
                                    { label: "135", value: 135 },
                                    { label: "180", value: 180 },
                                ]}
                            />

                            <Radio
                                label="Tick Spacing"
                                attr="tickmode"
                                options={[
                                    { label: "Auto", value: "auto" },
                                    { label: "Custom", value: "linear" },
                                ]}
                            />

                            <DTicks label="Step Offset" attr="tick0" />
                            <DTicksInterval label="Step Size" attr="dtick" />
                            <NTicks
                                label="Max Number of Labels"
                                attr="nticks"
                            />
                        </VisibilitySelect>
                    </PlotlySection>
                </AxesFold>
                <AxesFold
                    name="Tick Markers"
                    axisFilter={(axis) => !axis._subplot.includes("geo")}
                >
                    <PlotlySection name="Tick Markers" attr="ticks">
                        <VisibilitySelect
                            attr="ticks"
                            options={[
                                { label: "Inside", value: "inside" },
                                { label: "Outside", value: "outside" },
                                { label: "Hide", value: "" },
                            ]}
                            showOn={["inside", "outside"]}
                            defaultOpt="Outside"
                        >
                            <AxisSide label="Position" attr="side" />
                            <Radio
                                label="Position on"
                                attr="tickson"
                                options={[
                                    { label: "Labels", value: "labels" },
                                    {
                                        label: "Boundaries",
                                        value: "boundaries",
                                    },
                                ]}
                            />
                            <Numeric label="Length" attr="ticklen" units="px" />
                            <Numeric
                                label="Width"
                                attr="tickwidth"
                                units="px"
                            />
                            <ColorPicker label="Color" attr="tickcolor" />
                            <Radio
                                label="Tick Spacing"
                                attr="tickmode"
                                options={[
                                    { label: "Auto", value: "auto" },
                                    { label: "Custom", value: "linear" },
                                ]}
                            />

                            <DTicks label="Step Offset" attr="tick0" />
                            <DTicksInterval label="Step Size" attr="dtick" />
                            <NTicks
                                label="Max Number of Markers"
                                attr="nticks"
                            />
                        </VisibilitySelect>
                    </PlotlySection>
                    <PlotlySection
                        name="Multicategory Dividers"
                        attr="showdividers"
                    >
                        <VisibilitySelect
                            attr="showdividers"
                            options={[
                                { label: "Show", value: true },
                                { label: "Hide", value: false },
                            ]}
                            showOn={true}
                        >
                            <Numeric
                                label="Width"
                                attr="dividerwidth"
                                units="px"
                            />
                            <ColorPicker label="Color" attr="dividercolor" />
                        </VisibilitySelect>
                    </PlotlySection>
                </AxesFold>

                <AxesFold
                    name="Timescale Buttons"
                    axisFilter={(axis) =>
                        axis._subplot.includes("xaxis") && axis.type === "date"
                    }
                >
                    <Radio
                        attr="rangeselector.visible"
                        options={[
                            { label: "Show", value: true },
                            { label: "Hide", value: false },
                        ]}
                    />

                    <PlotlySection name="Text">
                        <FontSelector
                            label="Typeface"
                            attr="rangeselector.font.family"
                        />
                        <Numeric
                            label="Font Size"
                            attr="rangeselector.font.size"
                            units="px"
                        />
                        <ColorPicker
                            label="Font Color"
                            attr="rangeselector.font.color"
                        />
                    </PlotlySection>
                    <PlotlySection name="Style">
                        <ColorPicker
                            label="Background Color"
                            attr="rangeselector.bgcolor"
                        />
                        <ColorPicker
                            label="Active Color"
                            attr="rangeselector.activecolor"
                        />
                        <Numeric
                            label="Border Width"
                            attr="rangeselector.borderwidth"
                            units="px"
                        />
                        <ColorPicker
                            label="Border Color"
                            attr="rangeselector.bordercolor"
                        />
                    </PlotlySection>
                    <PlotlySection name="Horizontal Positioning">
                        <Dropdown
                            label="Anchor Point"
                            clearable={false}
                            attr="rangeselector.xanchor"
                            options={[
                                { label: "Auto", value: "auto" },
                                { label: "Left", value: "left" },
                                { label: "Center", value: "center" },
                                { label: "Right", value: "right" },
                            ]}
                        />
                        <Numeric
                            label="Position"
                            step={0.02}
                            attr="rangeselector.x"
                        />
                    </PlotlySection>
                    <PlotlySection name="Vertical Positioning">
                        <Dropdown
                            label="Anchor Point"
                            clearable={false}
                            attr="rangeselector.yanchor"
                            options={[
                                { label: "Auto", value: "auto" },
                                { label: "Top", value: "top" },
                                { label: "Middle", value: "middle" },
                                { label: "Bottom", value: "bottom" },
                            ]}
                        />
                        <Numeric
                            label="Position"
                            step={0.02}
                            attr="rangeselector.y"
                        />
                    </PlotlySection>
                </AxesFold>

                {/*<AxesFold*/}
                {/*    name="Spike Lines"*/}
                {/*    axisFilter={(axis) =>*/}
                {/*        !(*/}
                {/*            axis._subplot.includes("ternary") ||*/}
                {/*            axis._subplot.includes("polar") ||*/}
                {/*            axis._subplot.includes("geo")*/}
                {/*        )*/}
                {/*    }*/}
                {/*>*/}
                {/*    <Radio*/}
                {/*        attr="showspikes"*/}
                {/*        options={[*/}
                {/*            { label: "Show", value: true },*/}
                {/*            { label: "Hide", value: false },*/}
                {/*        ]}*/}
                {/*    />*/}
                {/*    <Radio*/}
                {/*        attr="spikesides"*/}
                {/*        label="Show Sides"*/}
                {/*        options={[*/}
                {/*            { label: "Show", value: true },*/}
                {/*            { label: "Hide", value: false },*/}
                {/*        ]}*/}
                {/*    />*/}

                {/*    <Numeric*/}
                {/*        label="Thickness"*/}
                {/*        attr="spikethickness"*/}
                {/*        units="px"*/}
                {/*    />*/}
                {/*    <ColorPicker label="Color" attr="spikecolor" />*/}
                {/*</AxesFold>*/}
            </LayoutPanel>
        );
    }
}

StyleAxesPanel.contextTypes = {
    fullLayout: PropTypes.object,
    localize: PropTypes.func,
};

export default StyleAxesPanel;
