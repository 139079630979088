import { type FC } from "react";

import { Center, Checkbox, Text } from "@chakra-ui/react";

import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";
import { MeasurementGroupEmptyState } from "@app-components/measurementGroup/MeasurementGroupEmptyState.tsx";

import { DataTable } from "@mt-components/DataTable.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";

import type { agentSchema } from "src/api/client";
import { OrganizationLink } from "src/App/components/OrganizationLink";
import { RequestLink } from "src/App/components/RequestLink";

type BeamtimeRequestsProps = {
    requests: agentSchema.components["schemas"]["BeamtimeMeasurementRequest"][];
};

export const BeamtimeRequests: FC<BeamtimeRequestsProps> = ({ requests }) => {
    return requests.length === 0 ? (
        <Center w="100%" h="100%">
            <MeasurementGroupEmptyState />
        </Center>
    ) : (
        <DataTable<
            agentSchema.components["schemas"]["BeamtimeMeasurementRequest"]
        >
            variant="unstyled"
            initialState={{
                sorting: [
                    {
                        id: "updatedAt",
                        desc: true,
                    },
                ],
            }}
            columns={[
                {
                    accessorKey: "organizationName",
                    header: "Organization",
                    cell: (x) => (
                        <OrganizationLink
                            organizationId={x.row.original.organizationId}
                            label={x.getValue() as string}
                        ></OrganizationLink>
                    ),
                },
                {
                    accessorKey: "name",
                    header: "Name",
                    cell: (x) => (
                        <RequestLink
                            organizationId={x.row.original.organizationId}
                            requestId={x.row.original.id}
                            label={x.getValue() as string}
                        ></RequestLink>
                    ),
                },
                {
                    accessorKey: "status",
                    header: "Status",
                    cell: (cell) => {
                        const status = cell.row.original.status;
                        return <GroupStatus status={status} />;
                    },
                },
                {
                    accessorKey: "hasReport",
                    header: "Report",
                    cell: (cell) => {
                        const value = !!cell.getValue();
                        return (
                            <Checkbox
                                isReadOnly
                                colorScheme="green"
                                defaultChecked={value}
                            />
                        );
                    },
                },
                {
                    accessorKey: "samplesKeys",
                    header: "Samples",
                    cell: (cell) => (
                        <Text>{(cell.getValue() as []).length}</Text>
                    ),
                },
                {
                    accessorKey: "tasksKeys",
                    header: "Measurements",
                    cell: (cell) => (
                        <Text>{(cell.getValue() as []).length}</Text>
                    ),
                },
                {
                    accessorKey: "quotationNumber",
                    header: "Quotation",
                },
                {
                    accessorKey: "updatedAt",
                    header: "Updated at",
                    cell: (cell) => {
                        return formatDate(cell.row.original.updatedAt);
                    },
                },
            ]}
            data={requests}
        />
    );
};
