import type { ChangeEvent, FC } from "react";
import { useState } from "react";

import { equals, isNotNil, toPairs } from "ramda";

import { Box, Button, Checkbox, Stack, Text } from "@chakra-ui/react";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type { MeasurementMethod as MeasurementMethodType } from "@app/domain";
import { MeasurementMethod } from "@app/domain";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import { FieldLabel } from "@mt-components/Input/FieldLabel.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

export type FormShape = {
    hrxrpd?: {
        taskId?: string;
        isChecked?: boolean;
    };
    tspdf?: {
        taskId?: string;
        isChecked?: boolean;
    };
    saxs?: {
        taskId?: string;
        isChecked?: boolean;
    };
};

type Props = {
    isOpen: boolean;
    measurementGroupId: string;
    sampleTask: SampleTasksModel;
    onSuccess: () => void;
    onCancel: () => void;
};
export const MeasurementTaskUpdateModal: FC<Props> = ({
    onSuccess,
    onCancel,
    measurementGroupId,
    isOpen,
    sampleTask,
}) => {
    const snack = useSnackbar();
    const { mutateAsync: createTask } = MeasurementTask.useCreate();
    const { mutateAsync: removeTask } = MeasurementTask.useDelete();
    const { currentOrg } = useOrgContext();
    const tasks = sampleTask.tasks;

    const initForm: FormShape = Object.entries(tasks).reduce(
        (acc, [key, value]) => {
            return {
                ...acc,
                [key]: {
                    taskId: value.taskId,
                    isChecked: Boolean(value.taskId),
                },
            };
        },
        {},
    );

    const [formState, setFormState] = useState<FormShape>(initForm);

    const submit = () => {
        const toCreate = toPairs(formState)
            .filter(isNotNil)
            .filter((e) => e[1]?.isChecked && !e[1].taskId);

        const toDelete = toPairs(formState)
            .filter(isNotNil)
            .filter((e) => !e[1]?.isChecked && e[1]?.taskId);

        const promisses: Promise<unknown>[] = [];
        toDelete.forEach((e) => {
            if (!e[1]?.taskId) {
                return;
            }
            promisses.push(
                removeTask({
                    measurementTaskId: e[1].taskId,
                    organizationId: currentOrg,
                }),
            );
        });

        toCreate.forEach((e) => {
            const entityIdPromise = createTask({
                params: {
                    path: {
                        organizationId: currentOrg,
                    },
                },
                payload: {
                    sampleId: sampleTask.id,
                    method: e[0],
                    measurementGroupId: measurementGroupId,
                },
            });
            promisses.push(entityIdPromise);
        });

        Promise.all(promisses)
            .then(() => {
                snack.success("Measurements updated.");
            })
            .catch(() => {
                snack.error(
                    "Measurements updating failed. Please reload the page and try again later",
                );
            });

        onSuccess();
    };

    const onChangeMethod = (e: ChangeEvent<HTMLInputElement>) => {
        const newMethod = e.target.value as MeasurementMethodType;
        setFormState((prev) => ({
            ...prev,
            [newMethod]: {
                taskId: prev[newMethod]?.taskId,
                isChecked: e.target.checked,
            },
        }));
    };

    const prevMethods = Object.keys(sampleTask.tasks);
    const newMethods = Object.entries(formState)
        .filter((e) => e[1].isChecked)
        .map((e) => e[0]);
    const hasNoChange = equals(prevMethods, newMethods);

    return (
        <>
            <Modal
                isOpen={isOpen}
                header="Measurement specification"
                buttonRight={
                    <Button
                        isDisabled={hasNoChange}
                        colorScheme="blue"
                        onClick={submit}
                    >
                        Confirm
                    </Button>
                }
                onClose={onCancel}
            >
                <Text>
                    Please select the sample and the corresponding methods you
                    would like us to use.
                </Text>
                <Box w="100%">
                    <Box mt="40px">
                        <FieldLabel mb="12px">Method</FieldLabel>
                        <Stack>
                            <Checkbox
                                onChange={onChangeMethod}
                                size="lg"
                                value={MeasurementMethod.Hrxrpd}
                                isChecked={formState.hrxrpd?.isChecked}
                            >
                                High-Resolution X-Ray Powder Diffraction
                                (HR-XRPD)
                            </Checkbox>
                            <Text
                                fontSize="md"
                                display="block"
                                color="gray.600"
                            >
                                High-resolution X-ray powder diffraction
                                (HR-XRPD) enables detailed analysis of
                                crystalline materials by capturing the
                                distribution of atoms within a solid — ideal for
                                investigating pharmaceutical compounds, novel
                                alloys, and nanocrystalline substances. Benefit
                                from accurate phase identification, crystal
                                structure analysis, and purity assessment.
                            </Text>
                            <Box
                                my="16px"
                                borderBottom="solid 2px"
                                borderColor="gray.300"
                            />
                            <Checkbox
                                isChecked={formState.tspdf?.isChecked}
                                onChange={onChangeMethod}
                                size="lg"
                                value={MeasurementMethod.Tspdf}
                            >
                                Total Scattering Pair Distribution Function
                                (TS-PDF)
                            </Checkbox>
                            <Text
                                fontSize="md"
                                display="block"
                                color="gray.600"
                            >
                                Gain insights into the local structure of your
                                materials, regardless of their crystalline
                                state. The total scattering pair distribution
                                function (PDF) analysis provides a comprehensive
                                characterization by delivering information on
                                both short- and long-range order in crystalline,
                                semi-crystalline, and amorphous solids. This
                                method is invaluable for studying complex
                                material systems, including catalysts,
                                batteries, and glasses, enabling an
                                understanding of structure-property
                                relationships at the atomic level.
                            </Text>
                            <Box
                                my="16px"
                                borderBottom="solid 2px"
                                borderColor="gray.300"
                            />
                            <Checkbox
                                isChecked={formState.saxs?.isChecked}
                                onChange={onChangeMethod}
                                size="lg"
                                value={MeasurementMethod.Saxs}
                            >
                                Small Angle Scattering (SAXS)
                            </Checkbox>
                            <Text
                                fontSize="md"
                                display="block"
                                color="gray.600"
                            >
                                Quantify the morphology of nanoparticles,
                                proteins, polymers, and more with small-angle
                                X-ray scattering (SAXS). SAXS is a powerful
                                technique for investigating the size, shape, and
                                distribution of nanometer-scale particles or
                                domains, as well pore structure and the
                                architecture of macromolecules in solutions and
                                solids.
                            </Text>
                        </Stack>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
// Ideal for researchers wanting to study
// structures in their native environment under
// conditions close to real-world applications.
