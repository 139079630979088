import { type ReactNode, useState } from "react";

import type { FileRejection } from "@zag-js/file-upload";

type Params = {
    accept?: string[];
    uploadLabel: string;
    emptyState?: ReactNode;
    maxFileSize?: number;
    maxFiles?: number;
    onChange?: (files: File[]) => void;
};

type FileChangeParams = {
    acceptedFiles: File[];
    rejectedFiles: FileRejection[];
};

export const useFileUpload = (params: Params) => {
    const [files, setFiles] = useState<File[] | null>(null);

    const clearFiles = () => setFiles(null);

    const onChange = (fileChange?: FileChangeParams) => {
        if (fileChange?.acceptedFiles.length === 1) {
            setFiles(fileChange.acceptedFiles);
            params.onChange?.(fileChange.acceptedFiles);
            return;
        }

        if (!files) {
            setFiles(null);
        }
    };

    return {
        files,
        uploadLabel: params.uploadLabel,
        accept: params.accept ?? ["*"],
        setFiles: onChange,
        clearFiles,
    };
};
