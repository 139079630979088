import { Router } from "@tanstack/react-router";

import type { RouterContext } from "@app/Routes/beforeLoad.tsx";
import { createNotFoundRoute } from "@app/Routes/commonRoutes.ts";
import { publicRoot } from "@app/Routes/publicRoute.tsx";
import { routeTree } from "@app/Routes/routeTree.ts";

export const router = new Router({
    routeTree: routeTree,
    notFoundRoute: createNotFoundRoute(publicRoot),
    context: {} as RouterContext,
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
}
