// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
// @ts-nocheck
import React, { Component } from "react";

import {
    RadioBlocks,
    TraceTypeSelector,
    TraceTypeSelectorButton,
} from "components/widgets";
import {
    computeTraceOptionsFromSchema,
    connectToContainer,
    plotlyTraceToCustomTrace,
    traceTypeToPlotlyInitFigure,
} from "lib";
import { TRACES_WITH_GL } from "lib/constants";
import PropTypes from "prop-types";

import { Button, Center, IconButton } from "@chakra-ui/react";

import { Modal } from "@mt-components/Modal/Modal.tsx";

import { UnconnectedDropdown } from "./Dropdown";
import Field from "./Field";

import { Icon } from "../icons";

class TraceSelectorBase extends Component {
    constructor(props, context) {
        super(props, context);

        this.updatePlot = this.updatePlot.bind(this);
        this.setGl = this.setGl.bind(this);
        this.glEnabled = this.glEnabled.bind(this);
        this.setTraceDefaults = this.setTraceDefaults.bind(this);
        this.toggleGlControls = this.toggleGlControls.bind(this);

        this.setTraceDefaults(
            props.container,
            props.fullContainer,
            props.updateContainer,
        );
        this.setLocals(props, context);

        this.state = { showGlControls: false };
    }

    glEnabled() {
        return this.props.container.type &&
            this.props.container.type.endsWith("gl")
            ? "gl"
            : "";
    }

    toggleGlControls() {
        this.setState({ showGlControls: !this.state.showGlControls });
    }

    toggleModal = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    setLocals(props, context) {
        const _ = context.localize;
        if (props.traceOptions) {
            this.traceOptions = props.traceOptions;
        } else if (context.traceTypesConfig) {
            this.traceOptions = context.traceTypesConfig.traces(_);
        } else if (context.plotSchema) {
            this.traceOptions = computeTraceOptionsFromSchema(
                context.plotSchema,
                this.context,
            );
        } else {
            this.traceOptions = [{ label: "Scatter", value: "scatter" }];
        }
        if (props.container) {
            this.fullValue = plotlyTraceToCustomTrace(props.container);
        }
    }

    setTraceDefaults(container, fullContainer, updateContainer, gl) {
        if (container && !container.mode && fullContainer.type === "scatter") {
            updateContainer({
                type:
                    "scatter" +
                    (gl || this.context.glByDefault ? gl : this.glEnabled()),
                mode: fullContainer.mode || "markers",
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        const { container, fullContainer, updateContainer } = nextProps;
        this.setTraceDefaults(container, fullContainer, updateContainer);
        this.setLocals(nextProps, nextContext);
    }

    updatePlot(value) {
        const { updateContainer } = this.props;
        const { glByDefault } = this.context;
        if (updateContainer) {
            updateContainer(
                traceTypeToPlotlyInitFigure(
                    value,
                    this.glEnabled() || glByDefault,
                ),
            );
        }
    }

    setGl(value) {
        const { container, fullContainer, updateContainer } = this.props;
        const gl = "gl";

        this.setTraceDefaults(container, fullContainer, updateContainer, value);

        const traceType =
            this.fullValue.endsWith(gl) && value === ""
                ? this.fullValue.slice(0, -gl.length)
                : this.fullValue;

        updateContainer(traceTypeToPlotlyInitFigure(traceType, value));
    }

    render() {
        const props = Object.assign({}, this.props, {
            fullValue: this.fullValue,
            updatePlot: this.updatePlot,
            options: this.traceOptions,
            clearable: false,
        });
        const { advancedTraceTypeSelector } = this.context;

        const options: { label: string; value: string }[] = [
            { label: "SVG", value: "" },
            { label: "WebGL", value: "gl" },
        ];

        // Check and see if the advanced selector prop is true
        if (advancedTraceTypeSelector) {
            return (
                <div>
                    <Modal
                        isOpen={this.state.isOpen}
                        header="Select trace"
                        onClose={this.toggleModal}
                        buttonRight={
                            <Button
                                colorScheme="blue"
                                onClick={this.toggleModal}
                            >
                                Close
                            </Button>
                        }
                    >
                        <TraceTypeSelector
                            {...props}
                            traceTypesConfig={this.context.traceTypesConfig}
                            glByDefault={this.context.glByDefault}
                        />
                    </Modal>
                    <Field {...props}>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                            }}
                        >
                            <TraceTypeSelectorButton
                                {...props}
                                traceTypesConfig={this.context.traceTypesConfig}
                                handleClick={this.toggleModal}
                            />
                            {/*{!TRACES_WITH_GL.includes(*/}
                            {/*    this.props.container.type,*/}
                            {/*) ? (*/}
                            {/*    ""*/}
                            {/*) : (*/}
                            {/*    <Center pl="4px">*/}
                            {/*        <IconButton*/}
                            {/*            colorScheme="gray"*/}
                            {/*            aria-label="webgl-settings"*/}
                            {/*            icon={<Icon.Cog size="16px" />}*/}
                            {/*            onClick={this.toggleGlControls}*/}
                            {/*        />*/}
                            {/*    </Center>*/}
                            {/*)}*/}
                        </div>
                    </Field>
                    {!(
                        TRACES_WITH_GL.includes(this.props.container.type) &&
                        this.state.showGlControls
                    ) ? (
                        ""
                    ) : (
                        <Field label="Rendering">
                            <RadioBlocks
                                options={options}
                                activeOption={this.glEnabled()}
                                onOptionChange={this.setGl}
                            />
                        </Field>
                    )}
                </div>
            );
        }

        return <UnconnectedDropdown {...props} />;
    }
}

TraceSelectorBase.contextTypes = {
    advancedTraceTypeSelector: PropTypes.bool,
    traceTypesConfig: PropTypes.object,
    plotSchema: PropTypes.object,
    config: PropTypes.object,
    localize: PropTypes.func,
    glByDefault: PropTypes.bool,
};

TraceSelectorBase.propTypes = {
    container: PropTypes.object.isRequired,
    fullContainer: PropTypes.object.isRequired,
    fullValue: PropTypes.any,
    updateContainer: PropTypes.func,
};

export default connectToContainer(TraceSelectorBase);
