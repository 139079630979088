/* eslint-disable @typescript-eslint/no-empty-object-type */
import type { FC } from "react";

// This component is based on @saas-ui/react
import type { HTMLChakraProps } from "@chakra-ui/react";
import { chakra, forwardRef } from "@chakra-ui/react";

export interface TimelineProps extends HTMLChakraProps<"ul"> {}

/**
 * Display a list of events in chronological order.
 *
 * @see Docs https://saas-ui.dev/docs/components/data-display/timeline
 */

const timelineStyles = {
    "position": "relative",
    "listStyle": "none",

    "display": "flex",
    "--timeline-row-start": "minmax(0,1fr)",
    "--timeline-row-end": "minmax(0,1fr)",
    "--timeline-col-start": "auto",
    "--timeline-col-end": "2fr",
    "flexDirection": "column",
    "justifyItems": "center",
};

export const Timeline: FC<TimelineProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <chakra.ul
            {...rest}
            __css={timelineStyles}
            fontSize="md"
            className="sui-timeline"
            data-orientation="vertical"
        >
            {children}
        </chakra.ul>
    );
};

Timeline.displayName = "Timeline";

export interface TimelineItemProps extends HTMLChakraProps<"li"> {}

const itemCss = {
    display: "grid",
    alignItems: "center",
    justifyItems: "start",
    gridTemplateRows: "var(--timeline-row-start, minmax(0,1fr))",
    gridTemplateColumns:
        "var(--timeline-col-start, minmax(0,1fr)) var(--timeline-col-end, minmax(0,1fr))",
    position: "relative",
};

export const TimelineItem = forwardRef<TimelineItemProps, "li">(
    (props, ref) => {
        const { children, ...rest } = props;

        return (
            <chakra.li
                {...rest}
                ref={ref}
                __css={itemCss}
                className="sui-timeline__item"
            >
                {children}
            </chakra.li>
        );
    },
);

TimelineItem.displayName = "TimelineItem";

export interface TimelineContentProps extends HTMLChakraProps<"div"> {}

const contentStyles = {
    flex: 1,
    px: "2",
    _first: {
        gridColumnStart: 1,
    },
    _last: {
        gridColumnStart: 2,
        justifySelf: "start",
    },
};

export const TimelineContent: FC<TimelineContentProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <chakra.div
            {...rest}
            __css={contentStyles}
            className="sui-timeline__content"
        >
            {children}
        </chakra.div>
    );
};

TimelineContent.displayName = "TimelineContent";

export interface TimelineSeparatorProps extends HTMLChakraProps<"div"> {}

const separatorStyles = {
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "flexShrink": 0,

    "mx": 1,
    "minW": "24px",
    "gridColumnStart": 1,
    "gap": 2,
    "height": "100%",
    "_before": {
        content: "''",
        display: "block",
        flex: 1,
        minH: "0.5em",
    },
    "_after": {
        content: "''",
        display: "block",
        flex: 1,
        minH: "0.5em",
    },
    "&:has(.sui-timeline__track:first-of-type):before": {
        display: "none",
    },
    "&:has(.sui-timeline__track:last-of-type):after": {
        display: "none",
    },
};

export const TimelineSeparator: FC<TimelineSeparatorProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <chakra.div
            {...rest}
            __css={separatorStyles}
            className="sui-timeline__separator"
        >
            {children}
        </chakra.div>
    );
};

TimelineSeparator.displayName = "TimelineSeparator";

export interface TimelineDotProps extends HTMLChakraProps<"div"> {}

export const TimelineDot: FC<TimelineDotProps> = (props) => {
    const { children, ...rest } = props;

    const dotStyles = {
        width: "9px",
        height: "9px",
        bg: "currentColor",
        borderRadius: "full",
    };

    return (
        <chakra.div {...rest} __css={dotStyles} className="sui-timeline__dot">
            {children}
        </chakra.div>
    );
};

TimelineDot.displayName = "TimelineDot";

export interface TimelineIconProps extends HTMLChakraProps<"div"> {}

const iconStyles = {
    display: "flex",
    alignItems: "center",
    zIndex: 1,
    color: "gray.300",
    _dark: {
        color: "gray.600",
    },
    minH: "8px",
    minW: "8px",
} as const;

export const TimelineIcon: FC<TimelineIconProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <chakra.div {...rest} __css={iconStyles} className="sui-timeline__icon">
            {children || <TimelineDot />}
        </chakra.div>
    );
};

TimelineIcon.displayName = "TimelineIcon";

export interface TimelineTrackProps extends HTMLChakraProps<"div"> {}

const trackCSS = {
    bg: "gray.300",
    width: "1px",
    flex: 1,
    minH: "0.5em",
    _dark: {
        bg: "gray.600",
    },
} as const;

export const TimelineTrack: FC<TimelineTrackProps> = (props) => {
    const { children, ...rest } = props;

    return (
        <chakra.div {...rest} __css={trackCSS} className="sui-timeline__track">
            {children}
        </chakra.div>
    );
};

TimelineTrack.displayName = "TimelineTrack";
