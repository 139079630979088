import type { ValueOf } from "type-fest";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import { downloadFetchBlob } from "@mt-tools/io/downloadFile.ts";

import { packageBrown } from "@mt-design/baseStyles.ts";

const entityKey = QueryKeys.package;

export type PackageModel = components["schemas"]["Package"];

export type GetPackagesParams = operations["getPackages"]["parameters"];

export type GetAdminPackagesParams =
    operations["getAdminPackages"]["parameters"];

export type DeletePackageParams = operations["deletePackage"]["parameters"];

export type CreatePackageParams = operations["createPackage"]["parameters"];

export type CreatePackagePayload =
    components["schemas"]["CreatePackagePayload"];

export type PatchPackageParams = operations["patchPackage"]["parameters"];

export type PatchPackagePayload = components["schemas"]["PatchPackagePayload"];

export type UploadProformaInvoiceParams =
    operations["uploadPackageProformaInvoice"]["parameters"];

export type DownloadProformaInvoiceParams =
    operations["getPackageProformaInvoice"]["parameters"];

export type DeleteProformaInvoiceParams =
    operations["deletePackageProformaInvoice"]["parameters"];

export function getColorSchemeForShippingStatus(p: PackageModel) {
    if (p.status === ShippingStatus.DISPATCHED) {
        return packageBrown;
    }

    if (p.status === ShippingStatus.RECEIVED) {
        return "green.500";
    }

    if (p.status === ShippingStatus.LOST) {
        return "red.600";
    }

    return "gray.600";
}

export const ShippingStatus = {
    DRAFT: "draft" as const,
    DISPATCHED: "dispatched" as const,
    RECEIVED: "received" as const,
    LOST: "lost" as const,
};

export type ShippingStatusType = ValueOf<typeof ShippingStatus>;

export const statusToLabelMap: Record<
    ValueOf<typeof ShippingStatus>,
    string
> = {
    draft: "Draft",
    dispatched: "Dispatched",
    received: "Received",
    lost: "Lost",
};

export const shippingStatusToLabel = (
    status: ValueOf<typeof ShippingStatus>,
) => {
    return statusToLabelMap[status];
};

export const shippingStatusOptions = [
    "draft",
    "dispatched",
    "received",
    "lost",
] satisfies ["draft", "dispatched", "received", "lost"];

export const Carriers = {
    DHL: "dhl",
    USPS: "usps",
    UPS: "ups",
    FedEx: "fedex",
    GDEX: "gdex",
    HKP: "hkp",
    Other: "other",
} as const;

type CarrierCode = ValueOf<typeof Carriers>;

export const codeToLabelMap: Record<CarrierCode, string> = {
    dhl: "DHL",
    usps: "USPS",
    ups: "UPS",
    fedex: "FedEx",
    gdex: "GDEX",
    hkp: "Hong Kong Post",
    other: "Other",
} as const;

export const carrierCodeToLabel = (code: CarrierCode) => {
    return codeToLabelMap[code];
};

export const carrierOptions = [
    "dhl",
    "fedex",
    "ups",
    "gdex",
    "usps", //  (United States Postal Service)
    "hkp", //  (Hong Kong Post)
    "other",
] as const;

class PackageAPI {
    constructor(private api: ReturnType<typeof useAPI>) {}

    create = (params: CreatePackageParams, data: CreatePackagePayload) => {
        return this.api.client.POST(
            "/organizations/{organizationId}/packages",
            {
                params: params,
                body: data,
            },
        );
    };

    patch = (params: PatchPackageParams, data: PatchPackagePayload) => {
        return this.api.client.PATCH(
            "/organizations/{organizationId}/packages/{packageId}",
            {
                params: params,
                body: data,
            },
        );
    };

    getAll = (params: GetPackagesParams) => {
        return this.api.client.GET("/organizations/{organizationId}/packages", {
            params: applyPageSizeDefault(params),
        });
    };

    adminGetAll = (params: GetAdminPackagesParams) => {
        return this.api.client.GET("/admin/packages", {
            params: applyPageSizeDefault(params),
        });
    };

    delete = (params: DeletePackageParams) => {
        return this.api.client.DELETE(
            "/organizations/{organizationId}/packages/{packageId}",
            {
                params: params,
            },
        );
    };
    uploadProformaInvoice = (
        params: UploadProformaInvoiceParams & { file: File },
    ) => {
        const formData = new FormData();
        formData.append("file", params.file);
        return fetch(
            `${this.api.basePath}/organizations/${params.path.organizationId}/packages/${params.path.packageId}/proforma-invoice`,
            {
                method: "POST",
                body: formData,
                headers: {
                    Authorization: `Bearer ${this.api.token}`,
                },
            },
        );
    };
    downloadProformaInvoice = async (params: DownloadProformaInvoiceParams) => {
        const res = await this.api.client.GET(
            "/organizations/{organizationId}/packages/{packageId}/proforma-invoice",
            {
                params: params,
                parseAs: "blob",
            },
        );

        return downloadFetchBlob(res);
    };

    deleteProformaInvoice = async (params: DeleteProformaInvoiceParams) => {
        const res = await this.api.client.DELETE(
            "/organizations/{organizationId}/packages/{packageId}/proforma-invoice",
            {
                params: params,
            },
        );

        return res;
    };
}

const useGetAll = (params: { params: GetPackagesParams }) => {
    const api = useAPI();
    const apiClient = new PackageAPI(api);
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            try {
                return await collectAllPages<
                    PackageModel,
                    GetPackagesParams,
                    typeof apiClient.getAll
                >(apiClient.getAll, params.params);
            } catch {
                throw new Error("Could not fetch packages");
            }
        },
    });
};

const useAdminGetAll = (params: GetAdminPackagesParams = {}) => {
    const api = useAPI();
    const apiClient = new PackageAPI(api);
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            try {
                return await collectAllPages<
                    PackageModel,
                    GetAdminPackagesParams,
                    typeof apiClient.adminGetAll
                >(apiClient.adminGetAll, params);
            } catch {
                throw new Error("Could not fetch packages");
            }
        },
    });
};

const useCreate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (params: {
            params: CreatePackageParams;
            data: CreatePackagePayload;
        }) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.create(params.params, params.data);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useUpdate = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (params: {
            params: PatchPackageParams;
            data: PatchPackagePayload;
        }) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.patch(params.params, params.data);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useDelete = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (params: { params: DeletePackageParams }) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.delete(params.params);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useUploadProformaInvoice = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (
            params: UploadProformaInvoiceParams & { file: File },
        ) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.uploadProformaInvoice(params);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useDownloadProformaInvoice = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (params: DownloadProformaInvoiceParams) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.downloadProformaInvoice(params);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

const useDeleteProformaInvoice = () => {
    const api = useAPI();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (params: DownloadProformaInvoiceParams) => {
            const apiClient = new PackageAPI(api);
            const r = await apiClient.deleteProformaInvoice(params);
            await client.invalidateQueries({
                queryKey: [entityKey],
            });
            return r;
        },
    });
};

export const Package = {
    useGetAll,
    useAdminGetAll,
    useCreate,
    useUpdate,
    useDelete,
    useUploadProformaInvoice,
    useDownloadProformaInvoice,
    useDeleteProformaInvoice,
};
