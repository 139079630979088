import type { FC } from "react";

import { Box, Text } from "@chakra-ui/react";

import type { SampleModel } from "@app/domain/api/sample.ts";
import { keyToName } from "@app/domain/services/sample/ioMapping.ts";

import {
    Property,
    PropertyLabel,
    PropertyList,
    PropertyValue,
} from "@mt-components/Input/Property";

import { renderValue } from "@mt-tools/formatter/renderValue.tsx";

type SampleHazardsProps = {
    sample: SampleModel;
};

const hazards: { id: keyof SampleModel; field: "boolean" | "text" }[] = [
    { id: "corrosive", field: "boolean" },
    { id: "flammable", field: "boolean" },
    { id: "oxidizing", field: "boolean" },
    { id: "toxic", field: "boolean" },
    { id: "airSensitive", field: "boolean" },

    { id: "otherHazards", field: "boolean" },
    { id: "otherHazardsDescription", field: "text" },
    { id: "sampleHandlingRisk", field: "boolean" },
    { id: "sampleHandlingRiskDescription", field: "text" },
    { id: "containsHazardousSubstances", field: "boolean" },
    { id: "containsHazardousSubstancesDescription", field: "text" },
];

export const SampleHazards: FC<SampleHazardsProps> = ({ sample }) => {
    return (
        <>
            <Text fontSize="12px" color="gray.600">
                Hazards
            </Text>
            <PropertyList>
                {hazards.map((h) => {
                    if (h.field === "boolean") {
                        const sampleProp: unknown = sample[h.id];
                        return (
                            <Property key={h.id}>
                                <PropertyLabel fontSize="14px" w="250px">
                                    {keyToName[h.id]}
                                </PropertyLabel>
                                <PropertyValue
                                    color="gray.800"
                                    fontSize="14px"
                                    w="100px"
                                    textAlign="right"
                                    justifyContent="flex-end"
                                >
                                    {renderValue(sampleProp)}
                                </PropertyValue>
                            </Property>
                        );
                    }

                    return (
                        <Box mt="8px" key={h.id}>
                            <Text fontSize="14px" color="gray.500">
                                {keyToName[h.id]}
                            </Text>
                            <Text color="gray.800" fontSize="14px">
                                {renderValue(sample[h.id])}
                            </Text>
                        </Box>
                    );
                })}
            </PropertyList>
        </>
    );
};
