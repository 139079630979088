import type { FC, ReactNode } from "react";
import { memo } from "react";

import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Card,
    CardBody,
    Center,
    chakra,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import { externalURLs, mailto } from "@app/Routes/routes.ts";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { PageLayout } from "src/packages/components/Layout/PageLayout";

const SupportAction: FC<{
    body: string;
    icon: ReactNode;
    anchorProps: object;
}> = ({ body, icon, anchorProps }) => {
    const scaleValue = 0.75;
    return (
        <Card
            w={`${200 * scaleValue}px`}
            h={`${200 * scaleValue}px`}
            rounded="16px"
            bg="white"
            border="solid 1px"
            borderColor="gray.300"
            borderRadius="8px"
            as="a"
            {...anchorProps}
            // shadow="0px 8px 16px 0px rgba(0, 0, 0, 0.12);"
        >
            <Tooltip label={body}>
                <CardBody
                    p={{ sm: "6px", md: "12px" }}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    gap="10%"
                    aria-label={body}
                >
                    {icon}
                    <Center w="100%" overflow="hidden">
                        <Text
                            whiteSpace="nowrap"
                            display="block"
                            color="gray.600"
                            fontSize="14px"
                            overflow="hidden"
                            width="100%"
                            textAlign="center"
                            textOverflow="ellipsis"
                        >
                            {body}
                        </Text>
                    </Center>
                </CardBody>
            </Tooltip>
        </Card>
    );
};

export const SupportPage: FC = memo(() => {
    return (
        <Box
            id="support-main"
            mx="auto"
            display="flex"
            flexDirection="row"
            h="100%"
            overflow="hidden"
        >
            <PageLayout
                display="flex"
                flexDirection="column"
                id="center-box"
                overflowX="auto"
                flex={1}
                h="100%"
                overflow="hidden"
                maxW="1024px"
            >
                <Box
                    display="flex"
                    flexDir="column"
                    w="100%"
                    h="100%"
                    overflow="hidden"
                >
                    <PageHeader
                        actionsId="dashboard-page-header"
                        title="Support Center"
                        subtitle="Welcome to the Support Center!"
                    />

                    <Box
                        display="flex"
                        flexDir="column"
                        w="100%"
                        h="100%"
                        overflowY="auto"
                        gap="24px"
                    >
                        <Text fontSize="14px">
                            We are here to help you make the most of your
                            experience with{" "}
                            <chakra.span fontStyle="italic">
                                Momentum Transfer
                            </chakra.span>
                            . Whether you have questions, need assistance, or
                            want to provide feedback, our support team is ready
                            to assist you.
                        </Text>

                        <Box>
                            <Text textStyle="h5" my="12px">
                                Contact
                            </Text>
                            <Box display="flex" gap="24px" flexWrap="wrap">
                                <SupportAction
                                    icon={
                                        <Box color="mtblue.600">
                                            <Icon.Globe
                                                color="currentColor"
                                                size="74px"
                                            />
                                        </Box>
                                    }
                                    body="FAQ"
                                    anchorProps={{
                                        href: externalURLs.faq,
                                        target: "_blank",
                                    }}
                                />
                                <SupportAction
                                    icon={
                                        <Box color="mtblue.600">
                                            <Icon.Mail size="74px" />
                                        </Box>
                                    }
                                    body="Mail"
                                    anchorProps={{
                                        href: `mailto:${mailto.support}`,
                                    }}
                                />
                            </Box>
                        </Box>

                        <Text fontSize="14px">
                            Below, you find some of the common questions and
                            answers about the app. For information about the
                            service please visit our{" "}
                            <chakra.a
                                textDecoration="underline"
                                href={externalURLs.faq}
                            >
                                FAQ
                            </chakra.a>{" "}
                            page.
                        </Text>
                        <Accordion fontSize="14px" allowToggle>
                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        How can I reset my password?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    Visit your{" "}
                                    <Link
                                        style={{ textDecoration: "underline" }}
                                        to="/profile"
                                    >
                                        profile page
                                    </Link>{" "}
                                    and find the reset button at the top of the
                                    page.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        How can I close my account?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    Please send us a message and we make sure to
                                    close your account for you.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        How do I contact customer support?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    You can get in touch with easily us through
                                    email. Just send us a mail to{" "}
                                    <chakra.a textDecoration="underline">
                                        {mailto.support}
                                    </chakra.a>
                                    , and we make sure to get back to you as
                                    soon as possible.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        What should I do if I encounter a bug?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    We&apos;d appreciate if you take note of
                                    time and date, and help us with a detailed
                                    description of the situation you were in.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        What are organizations meant for?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    Organizations are the compartments for you
                                    to collaborate with your peers or work for
                                    yourself in isolation. Organizations
                                    encapsulate context and data, and no one
                                    outside our organization can access your
                                    data.
                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionButton>
                                    <Box as="span" flex="1" textAlign="left">
                                        Can I transfer data across
                                        organizations?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                <AccordionPanel pb={4}>
                                    This is not possible right now.
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>

                        <Box>
                            <Text textStyle="h5" my="12px">
                                Documents
                            </Text>
                            <Box display="flex" gap="24px" flexWrap="wrap">
                                <SupportAction
                                    icon={
                                        <Box color="mtblue.600">
                                            <Icon.Document
                                                color="currentColor"
                                                size="74px"
                                            />
                                        </Box>
                                    }
                                    body="Proforma Invoice"
                                    anchorProps={{
                                        href: "/proforma-invoice-form.pdf",
                                        target: "_blank",
                                    }}
                                />
                                <SupportAction
                                    icon={
                                        <Box color="mtblue.600">
                                            <Icon.ClipboardList size="74px" />
                                        </Box>
                                    }
                                    body="Sample Prep Guide"
                                    anchorProps={{
                                        href: externalURLs.samplePrepGuide,
                                    }}
                                />
                                <SupportAction
                                    icon={
                                        <Box color="mtblue.600">
                                            <Icon.Document
                                                color="currentColor"
                                                size="74px"
                                            />
                                        </Box>
                                    }
                                    body="Shipping Instructions"
                                    anchorProps={{
                                        href: "/shipping_instructions_with_address.pdf",
                                        target: "_blank",
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </PageLayout>
        </Box>
    );
});

SupportPage.displayName = "SupportPage";
