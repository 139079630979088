import type { components } from "@app/domain/api/types/v1";

export const MeasurementGroupStatus = {
    Draft: "draft",
    Accepted: "accepted",
    Submitted: "submitted",
    Scheduled: "scheduled",
    Declined: "declined",
    Completed: "completed",
} as const;
export type MeasurementGroupStatusType =
    (typeof MeasurementGroupStatus)[keyof typeof MeasurementGroupStatus];

export const MeasurementMethod = {
    Hrxrpd: "hrxrpd",
    Saxs: "saxs",
    Tspdf: "tspdf",
} as const;
export type MeasurementMethod =
    (typeof MeasurementMethod)[keyof typeof MeasurementMethod];

export type MeasurementMethodType = MeasurementMethod;

export type MeasurementGroupModel = components["schemas"]["MeasurementGroup"];
export type AdminMeasurementGroupModel =
    components["schemas"]["AdminMeasurementGroup"];

export const BeamtimeStatus = {
    Draft: "draft",
    Active: "active",
    Completed: "completed",
    Canceled: "canceled",
    Published: "published",
} as const;
export type BeamtimeStatus =
    (typeof BeamtimeStatus)[keyof typeof BeamtimeStatus];

export type MeasurementTaskModel = components["schemas"]["MeasurementTask"];
