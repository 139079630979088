import { memo } from "react";
import { useAuth } from "react-oidc-context";

import { config } from "@app/config.ts";
import { useUserContext } from "@app/contexts/useUserContext.tsx";
import { Account } from "@app/pages/common/profile/Account.tsx";
import { authURLS, routes } from "@app/Routes/routes.ts";

export const AccountPage = memo(() => {
    const user = useUserContext();
    const auth = useAuth();

    const onLogout = async () => {
        // @todo
        // There is a problem with the synchronization of auth and navigation
        // when nav is too quick the token seems to be still in local storage
        // and we don't get to login but to home
        // clear local storage manually??"
        // navigate({ to: routes.login });
        await auth.signoutRedirect({
            post_logout_redirect_uri: config.host + routes.login.url,
        });
    };

    const onEdit = () => {
        window.location.href = config.host + authURLS.settings;
    };

    const onRecover = () => {
        window.location.href = config.host + authURLS.recovery;
    };

    return (
        <Account
            user={user}
            onRecover={onRecover}
            onEdit={onEdit}
            onLogout={onLogout}
        />
    );
});

AccountPage.displayName = "AccountPage";
