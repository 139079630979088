import type { FC } from "react";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "react-use";

import type { Config, Layout, PlotData } from "plotly.js";
import { indexBy, mergeDeepRight, omit, reduce } from "ramda";

import { Box, Center, HStack, Skeleton, Text } from "@chakra-ui/react";

import type { ArtifactModel } from "@app/domain/api/artifact.ts";
import { Artifact } from "@app/domain/api/artifact.ts";

import { ChartButton } from "@mt-components/Button/ChartButton.tsx";
import { DataTree } from "@mt-components/DataTree/DataTree.tsx";
import { Graph } from "@mt-components/Graph.tsx";

import { usePlotControls } from "@mt-hooks/usePlotControls.tsx";

import { colors } from "@mt-design/chartColors.ts";
import { Icon } from "@mt-design/icons.tsx";

import type { MathMessageEvent } from "@mt-math/event.ts";
import MyMathWorker from "@mt-math/math.ts?worker&inline";

type Data = {
    x: number[];
    y: number[];
};

const w = new MyMathWorker();

const mergePrevAndData = (
    p: Record<string, { id: string } & Partial<PlotData>>,
    event: MathMessageEvent,
): Record<string, { id: string } & Partial<PlotData>> => {
    let newP = p;

    Object.keys(event.data.data).forEach((key) => {
        const data = event.data.data[key];

        if (!data) {
            return;
        }

        newP = {
            ...newP,
            [key]: {
                ...newP[key],
                x: data.x,
                y: data.y,
            },
        };
    });

    return newP;
};

type ChartConfig = {
    layout?: Partial<Layout>;
    config?: Partial<Config>;
};

type ArtifactData = {
    x?: number[] | undefined;
    y?: number[] | undefined;
    e?: number[] | undefined;
    id: string;
};

const prepareData = (
    data: ArtifactData[],
    artifacts: ArtifactModel[],
): Record<string, Partial<PlotData> & { id: string }> => {
    const chaart = data.map(
        (dataset, idx): Partial<PlotData> & { id: string } => {
            const arti = artifacts.find((a) => a.id === dataset.id);

            const multiple = Math.floor(idx / 10);
            const index = multiple <= 1 ? idx : idx - multiple * 10;

            const string = colors[index];
            return {
                id: dataset.id,
                name: arti?.name ?? "",
                x: dataset.x,
                y: dataset.y,
                type: "scattergl",
                mode: "lines",
                marker: { color: string },
            };
        },
    );

    const byId: Record<string, Partial<PlotData> & { id: string }> = indexBy(
        (c) => c.id,
        chaart,
    );

    return byId;
};

type TraceId = string;
type ChartData = Record<string, { id: string } & Partial<PlotData>>;

export const GraphContainer: FC<{
    orgId?: string;
    artifacts: ArtifactModel[];
}> = memo(({ orgId, artifacts }) => {
    const [canRender, setCanRender] = useState(false);
    const originalChartDataRef = useRef<ChartData>({});
    const [ref, { width }] = useMeasure<HTMLDivElement>();

    const allDataQuery = Artifact.useGetAllData(
        orgId
            ? {
                  organizationId: orgId,
                  artifacts,
              }
            : undefined,
    );

    const [selectedCharts, setSelected] = useState<TraceId[]>([]);
    const [chartData, setChartData] = useState<ChartData>({});

    useEffect(() => {
        if (!allDataQuery.data) {
            return;
        }
        setTimeout(() => {
            setCanRender(true);
        }, 200);

        // @fixme
        const byId = prepareData(allDataQuery.data, artifacts);

        originalChartDataRef.current = byId;
        setChartData(byId);
        setActiveCharts(allDataQuery.data.map((_, idx) => idx));
    }, [allDataQuery.data, artifacts]);

    const traces = useMemo(() => Object.values(chartData), [chartData]);

    const [activeCharts, setActiveCharts] = useState<number[]>([0]);

    const [layout] = useState<Partial<Layout>>({
        autosize: true,
        showlegend: false,
        margin: {
            b: 40,
            r: 20,
            l: 80,
            t: 10,
        },
        xaxis: {
            showline: true,
            linewidth: 2,
            tickfont: {
                family: "Exo 2 Variable",
                size: 14,
            },
            autotick: true,
            ticks: "outside",
        },
        yaxis: {
            showline: true,
            linewidth: 2,
            tickfont: {
                family: "Exo 2 Variable",
                size: 14,
            },
            autotick: true,
        },
    });
    const [chartConfig] = useState<ChartConfig>({
        config: {
            responsive: false,
            autosizable: false,
            displayModeBar: false,
            watermark: false,
            displaylogo: false,
            sendData: false,
            showAxisDragHandles: true,
            showTips: false,
        },
    });

    const plotControls = usePlotControls();

    const getDataForSelectedCharts = (idx: TraceId[]) => {
        const input: Record<string, Data> = idx.reduce((acc, i) => {
            return {
                ...acc,
                [i]: chartData[i],
            };
        }, {});
        return input;
    };

    const square = (traceIds: string[]) => {
        const input = getDataForSelectedCharts(traceIds);

        w.addEventListener("message", (event: MathMessageEvent) => {
            setChartData((p) => {
                const newD = mergePrevAndData(p, event);
                return newD;
            });
        });
        w.postMessage({ name: "square", data: input });
    };

    const root = (traceIds: TraceId[]) => {
        const input = getDataForSelectedCharts(traceIds);
        w.addEventListener("message", (event: MathMessageEvent) => {
            setChartData((p) => mergePrevAndData(p, event));
        });
        w.postMessage({ name: "root", data: input });
    };

    const normalize = (traceIds: TraceId[]) => {
        const input = getDataForSelectedCharts(traceIds);
        w.addEventListener("message", (event: MathMessageEvent) => {
            setChartData((p) => mergePrevAndData(p, event));
        });
        w.postMessage({ name: "normalize", data: input });
    };

    const onChangeChartColor = (color: string, traceId: string) => {
        setChartData((p) => {
            return {
                ...p,
                [traceId]: {
                    ...p[traceId],
                    marker: {
                        ...p[traceId].marker,
                        color,
                    },
                },
            };
        });
    };

    const toggleChart = (_: boolean, traceId: number[]) => {
        setActiveCharts((prev) => {
            if (!traceId.length) {
                return [];
            }

            if (traceId.length > 1) {
                if (traceId.every((id) => prev.includes(id))) {
                    return prev.filter((i) => !traceId.includes(i));
                }

                return [...prev, ...traceId];
            }

            const oneTraceId = traceId[0];
            if (prev.includes(oneTraceId)) {
                return prev.filter((i) => i !== oneTraceId);
            }
            return [...prev, oneTraceId];
        });
    };
    // const toggleChart = (_: boolean, idx: number[]) => {
    //     setActiveCharts((prev) => {
    //         if (prev.includes(idx)) {
    //             return prev.filter((i) => i !== idx);
    //         }
    //         return [...prev, idx];
    //     });
    // };

    const resetTraces = (traceIds: string[]) => {
        const oldTraceData: ChartData = reduce(
            (agg: ChartData, traceId: string): ChartData => {
                return {
                    ...agg,
                    [traceId]: {
                        ...omit(["marker", "mode"], agg[traceId]),
                        x: agg[traceId].x,
                        y: agg[traceId].y,
                    },
                };
            },
            originalChartDataRef.current,
        )(traceIds);

        setChartData((p) => {
            const mergeDeepRight1 = mergeDeepRight(
                p,
                oldTraceData,
            ) as ChartData;
            return mergeDeepRight1;
        });
    };

    const toggleSelectChart = (_: boolean, traceId: string[]) => {
        setSelected((prev) => {
            if (!traceId.length) {
                return [];
            }

            if (traceId.length > 1) {
                if (traceId.every((id) => prev.includes(id))) {
                    return prev.filter((i) => !traceId.includes(i));
                }

                return [...prev, ...traceId];
            }

            const oneTraceId = traceId[0];
            if (prev.includes(oneTraceId)) {
                return prev.filter((i) => i !== oneTraceId);
            }
            return [...prev, oneTraceId];
        });
    };

    if (allDataQuery.error) {
        return (
            <Center
                bg="gray.100"
                minHeight="600px"
                maxHeight="600px"
                width="100%"
                overflow="hidden"
            >
                An error occurred. Data can not be rendered.
            </Center>
        );
    }

    if (!allDataQuery.data) {
        return (
            <Skeleton
                // bg="gray.50"
                minHeight="600px"
                maxHeight="600px"
                width="100%"
                overflow="hidden"
            />
        );
    }

    const toolbarHeight = 36;
    const toolbarHeightPx = `${toolbarHeight}px`;

    const isColumnLayout = width <= 800;

    return (
        <Box
            ref={ref}
            display="flex"
            w="100%"
            h="100%"
            minHeight="600px"
            maxHeight="1300px"
            alignItems={isColumnLayout ? "flex-start" : "flex-start"}
            flexDir={isColumnLayout ? "column" : "row"}
        >
            <Box
                alignSelf="flex-start"
                // pt={isColumnLayout ? 0 : "36px"}
                minW="400px"
                width="100%"
                minH="300px"
                maxH="500px"
                maxW={isColumnLayout ? "unset" : "400px"}
                bg="white"
            >
                <DataTree
                    toggleSelectChart={toggleSelectChart}
                    selectedCharts={selectedCharts}
                    activeCharts={activeCharts}
                    toggleChart={toggleChart}
                    onChangeChartColor={onChangeChartColor}
                    traces={traces}
                />
            </Box>
            <Box bg="white" flex="1" height="100%" overflow="hidden">
                <Box pl={isColumnLayout ? 0 : "70px"}>
                    <Text
                        display="block"
                        lineHeight="16¥px"
                        px="12px"
                        py="12px"
                        my="12px"
                        textStyle="T2"
                    >
                        {selectedCharts.length
                            ? null
                            : "Select a dataset in the left panel to enable dynamic transforms. The tools operate on the selected data sets."}
                    </Text>
                    <Box
                        zIndex="10"
                        h="40px"
                        data-test-id="data-button"
                        w="100%"
                        top={0}
                        right={0}
                        minH={toolbarHeightPx}
                        maxH="220px"
                        height="100%"
                        overflowX="auto"
                        overflowY="hidden"
                    >
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            onClick={() => square(selectedCharts)}
                            label="Square"
                            title={
                                <HStack gap={0}>
                                    <Text>Square</Text>
                                    <sup>2</sup>
                                </HStack>
                            }
                        />
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            onClick={() => normalize(selectedCharts)}
                            label="Normalize"
                            title={
                                <HStack>
                                    <Text>Normalize 1</Text>
                                </HStack>
                            }
                        />
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Square root"
                            title={
                                <HStack>
                                    <Text>Square Root</Text>
                                    <Icon.graph.Square />
                                </HStack>
                            }
                            onClick={() => root(selectedCharts)}
                        />
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Resets data (transformations) and color"
                            title={
                                <HStack>
                                    <Text>Reset trace</Text>
                                    <Icon.graph.Reload />
                                </HStack>
                            }
                            onClick={() => resetTraces(selectedCharts)}
                        />
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Restore the original view"
                            title={
                                <HStack>
                                    <Text>Reset layout</Text>
                                    <Icon.graph.Reload />
                                </HStack>
                            }
                            onClick={plotControls.redraw}
                        />

                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Zoom In"
                            title={
                                <HStack>
                                    <Text>Zoom in</Text>
                                    <Icon.graph.ZoomIn />
                                </HStack>
                            }
                            onClick={plotControls.zoomIn}
                        />

                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Zoom out"
                            title={
                                <HStack>
                                    <Text>Zoom out</Text>
                                    <Icon.graph.ZoomOut />
                                </HStack>
                            }
                            onClick={plotControls.zoomOut}
                        />
                        <ChartButton
                            type="complex"
                            isDisabled={!selectedCharts.length}
                            label="Download Image"
                            title={
                                <HStack>
                                    <Text>Download image</Text>
                                    <Icon.graph.Download />
                                </HStack>
                            }
                            onClick={() => plotControls.downloadPreview()}
                        />
                    </Box>
                </Box>
                <Box>
                    {canRender && (
                        <Graph
                            height={600}
                            minHeight={300}
                            minWidth={500}
                            config={chartConfig.config ?? {}}
                            layout={layout}
                            plots={traces}
                            activeCharts={activeCharts}
                        />
                    )}
                </Box>
            </Box>
        </Box>
    );
});

GraphContainer.displayName = "GraphContainer";
