import type { FC } from "react";
import React, { useMemo, useRef } from "react";

import { fromPairs, identity, toPairs } from "ramda";

import { Box, useDimensions } from "@chakra-ui/react";
import type {
    CellContext,
    ColumnDefTemplate,
    OnChangeFn,
    Table,
} from "@tanstack/react-table";

import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";
import {
    greenGray,
    redGray,
} from "@mt-components/Display/YesNoIndicator/schemes.ts/ColorIndicator.tsx";

import { filterMap } from "@mt-tools/iterating/filter.ts";
import type { IndexBoolean } from "@mt-tools/types.ts";

import { YesNoIndicator } from "src/packages/components/Display/YesNoIndicator";

const yesRedNoGray: ColumnDefTemplate<
    CellContext<SampleTasksModel, unknown>
> = (row) => {
    const value = row.getValue(); // @todo not typesafe
    return (
        <YesNoIndicator<"true" | "false">
            config={redGray}
            value={value ? "true" : "false"}
        />
    );
};

interface TableMeta {
    isDisabled?: boolean;
    // measurementsByTaskId: Record<string, MeasurementModel[] | undefined>;
}

const columns: ColumnDef<SampleTasksModel>[] = [
    {
        accessorKey: "name",
        header: "Sample-ID",
    },
    {
        accessorKey: "id",
        header: "ID",
    },
    {
        accessorKey: "qrCode",
        header: "QR-Code",
    },
    {
        accessorKey: "hrxrpd",
        header: "HR-XRPD",
        cell: (cell1) => {
            const value = cell1.row.original.tasks.hrxrpd;
            return (
                <YesNoIndicator<"true" | "false">
                    config={greenGray}
                    value={value ? "true" : "false"}
                />
            );
        },
    },
    {
        accessorKey: "tspdf",
        header: "TS-PDF",
        cell: (cell1) => {
            const value = cell1.row.original.tasks.tspdf;
            return (
                <YesNoIndicator<"true" | "false">
                    config={greenGray}
                    value={value ? "true" : "false"}
                />
            );
        },
    },
    {
        accessorKey: "saxs",
        header: "SAXS",
        cell: (cell1) => {
            const value = cell1.row.original.tasks.saxs;
            return (
                <YesNoIndicator<"true" | "false">
                    config={greenGray}
                    value={value ? "true" : "false"}
                />
            );
        },
    },
    // {
    //     accessorKey: "results",
    //     header: "Results",
    //     cell: (cell) => {
    //         const taskIds = cell.row.original.measurementTaskIds;
    //
    //         const meta = cell.table.options.meta as TableMeta;
    //         const data = taskIds.map(
    //             (id) => meta.measurementsByTaskId[id] ?? [],
    //         );
    //
    //         return flatten(data).length;
    //     },
    // },
    {
        accessorKey: "substance",
        header: "Substance",
    },
    {
        accessorKey: "casNumber",
        header: "CAS Number",
    },
    {
        accessorKey: "composition",
        header: "Composition",
    },

    {
        accessorKey: "form",
        header: "Form",
    },
    {
        accessorKey: "formDescription",
        header: "Form Description",
        size: 300,
    },
    {
        accessorKey: "toxic",
        header: "Toxic?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "flammable",
        header: "Flammable?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "corrosive",
        header: "Corrosive?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "oxidizing",
        header: "Oxidizing?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "airSensitive",
        header: "Air-sensitive?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "otherHazards",
        header: "Other hazards?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "sampleHandlingRisk",
        header: "Sample handling risk?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "sampleHandlingRiskDescription",
        header: "Details",
    },
    {
        accessorKey: "containsHazardousSubstances",
        header: "Contains hazardous substances?",
        cell: yesRedNoGray,
    },
    {
        accessorKey: "containsHazardousSubstancesDescription",
        header: "Details",
    },
] satisfies ColumnDef<SampleTasksModel>[];

type Props = {
    isDisabled?: boolean;
    isWide?: boolean;
    sampleTasks: SampleTasksModel[];
    selectedSampleTaskIds?: string[];
    debug?: boolean;
    tableStyle?: React.CSSProperties;
    onSelectSample: (ids: string[]) => void;
};

export const SampleTaskTable: FC<Props> = ({
    debug,
    isDisabled,
    isWide,
    tableStyle,
    sampleTasks,
    selectedSampleTaskIds,
    onSelectSample,
}) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const dimensions = useDimensions(ref);
    const rowSelection = useMemo(
        () =>
            selectedSampleTaskIds
                ? fromPairs(selectedSampleTaskIds.map((s) => [s, true]))
                : {},
        [selectedSampleTaskIds],
    );

    const tableRef = useRef<Table<SampleTasksModel>>(null);

    const select: OnChangeFn<IndexBoolean> = (updater) => {
        if (typeof updater === "function") {
            const newRowSelection = updater(rowSelection);
            const entries = toPairs(newRowSelection);
            const ids = filterMap(identity, entries);
            onSelectSample(ids);
            return;
        }

        const entries = toPairs(updater);
        const ids = filterMap(identity, entries);

        onSelectSample(ids);
    };

    const tableMeta: TableMeta = {
        isDisabled,
    };

    const visibility = useMemo(() => {
        if (isWide) {
            return {
                toxic: true,
                id: Boolean(debug),
                flammable: true,
                airSensitive: true,
                substance: true,
                sampleHandlingRisk: true,
                casNumber: true,
                sampleHandlingRiskDescription: true,
                formDescription: true,
                oxidizing: true,
                corrosive: true,
                containsHazardousSubstances: true,
                containsHazardousSubstancesDescription: true,
                otherHazards: true,
            };
        }

        return {
            toxic: false,
            id: Boolean(debug),
            flammable: false,
            airSensitive: false,
            substance: false,
            sampleHandlingRisk: false,
            casNumber: false,
            sampleHandlingRiskDescription: false,
            formDescription: false,
            oxidizing: false,
            corrosive: false,
            containsHazardousSubstances: false,
            containsHazardousSubstancesDescription: false,
            otherHazards: false,
        };
    }, [debug, isWide]);

    return (
        <Box minH="500px" height="100%" ref={ref}>
            <DataTable<SampleTasksModel>
                style={{
                    ...tableStyle,
                    height: dimensions?.borderBox.height
                        ? dimensions.borderBox.height - 10
                        : tableStyle?.height,
                }}
                variant="unstyled"
                testId="sample-task-table"
                instanceRef={tableRef}
                isSelectable
                isSortable
                enableMultiRowSelection
                enableRowSelection
                columns={columns}
                data={sampleTasks}
                state={{
                    rowSelection,
                    columnVisibility: visibility,
                }}
                onRowSelectionChange={select}
                getRowId={(row) => row.id}
                meta={tableMeta}
            />
        </Box>
    );
};

SampleTaskTable.displayName = "SampleTask";
