import { useBreakpointValue, useDisclosure } from "@chakra-ui/react";

import {
    type Breakpoints,
    getBreakpoints,
} from "@mt-components/Sidebar/sidebar-utils.tsx";

interface UseAppShellProps {
    toggleBreakpoint?: Breakpoints;
}

export const useAppShell = (props: UseAppShellProps) => {
    const disclosure = useDisclosure();

    const breakpoints = getBreakpoints(props.toggleBreakpoint);

    const isMobile = useBreakpointValue(breakpoints, {
        fallback: props.toggleBreakpoint || "lg",
    });

    return {
        isSidebarOpen: disclosure.isOpen,
        closeSidebar: disclosure.onClose,
        openSidebar: disclosure.onOpen,
        toggleSidebar: disclosure.onToggle,
        isMobile,
    };
};
