// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { PropsWithChildren } from "react";
import React, { cloneElement, Component } from "react";

import update from "immutability-helper";
import PropTypes from "prop-types";

import { Box } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

import PanelEmpty from "./PanelEmpty";
import PanelHeader from "./PanelHeader";

class PanelErrorImpl extends Component {
    render() {
        return (
            <PanelEmpty icon={Icon.Error} heading="Well this is embarrassing.">
                <p>This panel could not be displayed due to an error.</p>
            </PanelEmpty>
        );
    }
}

PanelErrorImpl.contextTypes = {
    localize: PropTypes.func,
};

const PanelError = PanelErrorImpl;

type Props = {
    collapsedOnStart?: boolean;
    canReorder?: boolean;
    showExpandCollapse?: boolean;
    deleteAction?: () => void;
    addAction?: () => void;
    noPadding?: boolean;
};

type State = {
    individualFoldStates: [];
    hasError: boolean;
};

export class Panel extends Component<PropsWithChildren<Props>, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            individualFoldStates: [],
            hasError: false,
        };
    }

    getChildContext() {
        return {
            deleteContainer: this.props.deleteAction
                ? this.props.deleteAction
                : null,
        };
    }

    componentDidCatch() {
        this.setState({ hasError: true });
    }

    toggleFolds = () => {
        const { individualFoldStates } = this.state;
        const hasOpen =
            individualFoldStates.length > 0 &&
            individualFoldStates.some((s) => s !== true);
        this.setState({
            individualFoldStates: individualFoldStates.map(() => hasOpen),
        });
    };

    toggleFold = (index: number) => {
        this.setState(
            update(this.state, { individualFoldStates: { $toggle: [index] } }),
        );
    };

    calculateFolds = () => {
        // to get proper number of child folds and initialize component state
        let numFolds = 0;

        React.Children.forEach(this.props.children, (child) => {
            if (
                ((child && child.type && child.type.plotly_editor_traits) || {})
                    .foldable
            ) {
                numFolds++;
            }
        });

        if (this.state.individualFoldStates.length !== numFolds) {
            const newFoldStates = new Array(numFolds).fill(
                this.props.collapsedOnStart,
            );
            this.setState({
                individualFoldStates: this.props.addAction
                    ? newFoldStates.map((e, i) => i !== numFolds - 1)
                    : newFoldStates,
            });
        }
    };

    componentDidUpdate() {
        this.calculateFolds();
    }
    componentDidMount() {
        this.calculateFolds();
    }

    render() {
        const { individualFoldStates, hasError } = this.state;
        const { canReorder } = this.props;

        if (hasError) {
            return (
                <Box
                    bg="white"
                    border="none"
                    borderRightStyle="solid"
                    borderRightColor="gray.100"
                    borderRightWidth="1px"
                    p="12px"
                    width="390px"
                >
                    <PanelError />
                </Box>
            );
        }

        // console.log("plotly panel children", this.props.children);

        const newChildren = React.Children.map(
            this.props.children,
            (child, index) => {
                if (
                    (
                        (child &&
                            child.type &&
                            child.type.plotly_editor_traits) ||
                        {}
                    ).foldable
                ) {
                    return cloneElement(child, {
                        key: index,
                        folded: individualFoldStates[index] || false,
                        toggleFold: () => this.toggleFold(index),
                        canMoveUp:
                            canReorder &&
                            individualFoldStates.length > 1 &&
                            index > 0,
                        canMoveDown:
                            canReorder &&
                            individualFoldStates.length > 1 &&
                            index !== individualFoldStates.length - 1,
                    });
                }
                return child;
            },
        );

        return (
            <Box
                bg="white"
                border="none"
                borderRightStyle="solid"
                borderRightColor="gray.100"
                borderRightWidth="1px"
                p="12px"
                display="flex"
                flexDirection="column"
                width="390px"
                overflow="hidden"
            >
                <PanelHeader
                    addAction={this.props.addAction}
                    allowCollapse={
                        this.props.showExpandCollapse &&
                        individualFoldStates.length > 1
                    }
                    toggleFolds={this.toggleFolds}
                    hasOpen={individualFoldStates.some((s) => s === false)}
                />
                <Box
                    flex={1}
                    overflowY="auto"
                    overflowX="hidden"
                    display="flex"
                    flexDirection="column"
                    flexGrow={1}
                >
                    {newChildren}
                </Box>
            </Box>
        );
    }
}

Panel.defaultProps = {
    showExpandCollapse: true,
    collapsedOnStart: false,
};

Panel.childContextTypes = {
    deleteContainer: PropTypes.func,
};

class PlotlyPanel extends Panel {}

PlotlyPanel.plotly_editor_traits = {
    no_visibility_forcing: true,
};

export default PlotlyPanel;
