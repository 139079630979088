import { appShellTheme } from "./app-shell";
import { formTheme } from "./form";
import { navGroupTheme } from "./nav-group";
import { navItemTheme } from "./nav-item";
import { navbarTheme } from "./navbar";
import { propertyTheme } from "./property";
import { sidebarTheme } from "./sidebar";
import { timelineTheme } from "./timeline";

export const components = {
    Form: formTheme,
    SuiAppShell: appShellTheme,
    SuiNavGroup: navGroupTheme,
    SuiNavItem: navItemTheme,
    SuiProperty: propertyTheme,
    SuiSidebar: sidebarTheme,
    SuiTimeline: timelineTheme,
    SuiNavbar: navbarTheme,
};
