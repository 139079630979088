import type { FC } from "react";

import { Box, Center, Text } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

export const MultipleSelectionView: FC = () => {
    return (
        <Center h="100%">
            <Box display="flex" flexDirection="column" alignItems="center">
                <Icon.MeasurementsImage width="90px" h="90px" />
                <Center mt="16px">
                    <Text color="gray.600" textStyle="T2">
                        Multiple items selected
                    </Text>
                </Center>
            </Box>
        </Center>
    );
};
