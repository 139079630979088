import { type FC } from "react";

import { Box, Button, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";

import { Modal } from "@mt-components/Modal/Modal.tsx";

import { Request } from "src/App/domain/api/request";
import { LoadingButton } from "src/packages/components/Button/LoadingButton";

type Props = {
    model: MeasurementGroupModel;
    onClose?: () => void;
    onDeleted?: (isOk: boolean) => void;
};
export const DeleteReportModal: FC<Props> = (props) => {
    const deleteReport = Request.useAdminDeleteReport();

    return (
        <Modal
            id="delete-report"
            isOpen={true}
            onClose={() => props.onClose && props.onClose()}
            header="Delete report"
            buttonRight={
                <LoadingButton
                    colorScheme="red"
                    onClick={async () => {
                        const res = await deleteReport.mutateAsync({
                            organizationId: props.model.organizationId,
                            measurementGroupId: props.model.id,
                        });

                        props.onDeleted?.(!res.error);
                    }}
                    isLoading={deleteReport.isPending}
                >
                    Delete
                </LoadingButton>
            }
            buttonLeft={
                <Button
                    colorScheme="white"
                    onClick={() => {
                        props.onClose?.();
                    }}
                >
                    Cancel
                </Button>
            }
        >
            <Box my="24px">
                <Text>Do you want to delete the report?</Text>
            </Box>
        </Modal>
    );
};
