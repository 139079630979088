import { useRef, useState } from "react";

import { EventSourceParserStream } from "eventsource-parser/stream";

import type { ToastId } from "@chakra-ui/react";

import type { components } from "@app/domain/api/types/v1";

import { useSnackbar } from "src/packages/hooks/useSnackbar";

export type DownloadCollectProgress = components["schemas"]["SSEProgressEvent"];
export type DownloadZipProgress = {
    total: number;
    loaded: number;
    part: number;
};

/**
 * Initiates 2 step download process request
 */
export const use2SDownloadQuery = (config: {
    fn: () => Promise<Response>;
    onError?: (error: unknown) => void;
    onFinish?: () => void;
    onDownloadUrl?: (token: string) => void;
    onCollectProgress?: (data: DownloadCollectProgress) => void;
    onZipProgress?: (data: DownloadZipProgress) => void;
}) => {
    const [isRunning, setIsRunning] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState<unknown>(null);
    const [collectProgress, setCollectProgress] =
        useState<DownloadCollectProgress | null>(null);
    const [uploadProgress, setUploadProgress] =
        useState<DownloadZipProgress | null>(null);
    const [downloadUrl, setDownloadUrl] = useState<string | null>(null);

    return {
        isRunning,
        isError,
        error,
        token: downloadUrl,
        collectProgress,
        uploadProgress,
        run: function () {
            setIsRunning(true);

            (async () => {
                const res = await config.fn();

                const reader = res.body
                    ?.pipeThrough(new TextDecoderStream())
                    .pipeThrough(new EventSourceParserStream())
                    .getReader();

                if (!reader) return;

                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                while (true) {
                    const { value, done } = await reader.read();
                    if (done) break;

                    if (value.event === "download-url") {
                        const data = JSON.parse(value.data) as {
                            downloadUrl: string;
                        };
                        setDownloadUrl(data.downloadUrl);
                        config.onDownloadUrl &&
                            config.onDownloadUrl(data.downloadUrl);
                    }

                    if (value.event === "progress") {
                        const data = JSON.parse(
                            value.data,
                        ) as DownloadCollectProgress;

                        setCollectProgress(data);
                        config.onCollectProgress &&
                            config.onCollectProgress(data);
                    }

                    if (value.event === "temp-save-progress") {
                        const data = JSON.parse(
                            value.data,
                        ) as DownloadZipProgress;

                        setUploadProgress(data);
                        config.onZipProgress && config.onZipProgress(data);
                    }
                }
            })()
                .catch((error) => {
                    setIsError(true);
                    setError(error);
                    config.onError && config.onError(error);
                })
                .finally(() => {
                    setIsRunning(false);
                    config.onFinish && config.onFinish();
                });
        },
    };
};

export const use2SDownloadSnackbar = () => {
    const snackbar = useSnackbar();
    const snackbarIdRef = useRef<ToastId>();

    return {
        open() {
            snackbarIdRef.current = snackbar.info({
                duration: null,
                status: "info",
                description: "Starting download",
            });
        },
        close() {
            if (snackbarIdRef.current) {
                snackbar.close(snackbarIdRef.current);
            }
        },
        errorCollect() {
            if (snackbarIdRef.current) {
                snackbar.update(snackbarIdRef.current, {
                    duration: 10000,
                    status: "error",
                    description: "Error occurred while preparing zip archive",
                });
            }
        },
        errorDownload() {
            if (snackbarIdRef.current) {
                snackbar.update(snackbarIdRef.current, {
                    duration: 10000,
                    status: "error",
                    description: "Error while trying to download zip",
                });
            }
        },
        progressCollect(progress: number) {
            if (snackbarIdRef.current) {
                snackbar.update(snackbarIdRef.current, {
                    duration: null,
                    description: `[1/3] Collecting files ${progress.toFixed(0)}%`,
                });
            }
        },
        progressZip(progress: number) {
            if (snackbarIdRef.current) {
                snackbar.update(snackbarIdRef.current, {
                    duration: null,
                    description: `[2/3] Preparing zip ${progress.toFixed(0)}%`,
                });
            }
        },
        progressDownloadUrl() {
            if (snackbarIdRef.current) {
                snackbar.update(snackbarIdRef.current, {
                    duration: 10000,
                    description: "[3/3] Downloading",
                });
            }
        },
    };
};
