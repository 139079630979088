import type { FC, PropsWithChildren } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Text } from "@chakra-ui/react";

export const FieldLabel: FC<PropsWithChildren<BoxProps>> = ({
    children,
    ...rest
}) => {
    return (
        <Text color="gray.600" fontSize="12px" {...rest}>
            {children}
        </Text>
    );
};
