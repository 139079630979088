import type { FC } from "react";
import { memo } from "react";

import { Box, Center, useDisclosure } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type {
    MeasurementGroupModel,
    MeasurementGroupStatusType,
} from "@app/domain";
import { Request } from "@app/domain/api/request.ts";
import { routes } from "@app/Routes/routes.ts";
import { user } from "@app/Routes/userRoutes.tsx";
import { MeasurementGroupEmptyState } from "@app-components/measurementGroup/MeasurementGroupEmptyState2.tsx";
import { MeasurementGroupsTable } from "@app-components/measurementGroup/MeasurementGroups.table.tsx";
import type { FormShape } from "@app-components/modals/measurement/MeasurementGroup.modal.tsx";
import { MeasurementGroupModal } from "@app-components/modals/measurement/MeasurementGroup.modal.tsx";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    groups: MeasurementGroupModel[];
};

export const Requests: FC<Props> = memo(({ groups }) => {
    const searchParams = user.measurementGroups.useSearch({});
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const { currentOrg } = useOrgContext();

    const { mutateAsync: createGroup } = Request.useCreate();
    const { isOpen, onToggle } = useDisclosure();

    const onSubmit = async (data: FormShape) => {
        await createGroup({
            organizationId: currentOrg,
            data,
        }).then((r) => {
            if (r.response.ok) {
                snackbar.success({
                    title: "Measurement request created",
                });
                return;
            }
            snackbar.error({
                title: "An error occurred",
                description: "Could not create request",
            });
        });
        onToggle();
    };

    const actions = [
        {
            id: "create",
            label: (
                <Box display="flex" gap="8px" alignItems="center">
                    <Icon.Plus size="20" /> Create
                </Box>
            ),
            isPrimary: true,
            onClick: onToggle,
        },
    ];

    const goToRequest = async (group: MeasurementGroupModel) => {
        await navigate({
            to: routes.user.measurementGroupDetail.url,
            params: {
                orgId: currentOrg,
                orderId: group.id,
            },
        });
    };

    const onSetStatusFilter = (status?: MeasurementGroupStatusType) => {
        void navigate({
            to: routes.user.measurementGroups.url,
            params: {
                orgId: currentOrg,
            },
            search: {
                status,
            },
        });
    };

    return (
        <>
            <MeasurementGroupModal
                isOpen={isOpen}
                onSubmit={onSubmit}
                onCancel={onToggle}
            />
            {groups.length === 0 ? (
                <Center w="100%" h="100%" overflow="auto">
                    <MeasurementGroupEmptyState onClick={onToggle} />
                </Center>
            ) : (
                <PageLayout display="flex" flexDir="column" overflow="hidden">
                    <PageHeader
                        actions={actions}
                        crumbs={[
                            {
                                id: "requests",
                                label: "Requests",
                            },
                        ]}
                    />
                    <MeasurementGroupsTable
                        getNavParams={(data: MeasurementGroupModel) => ({
                            to: routes.user.measurementGroupDetail.url,
                            from: routes.user.measurementGroups.url,
                            params: {
                                orgId: data.organizationId,
                                orderId: data.id,
                            },
                        })}
                        onRowClick={goToRequest}
                        measurementGroups={groups}
                        selectedStatus={searchParams.status}
                        onSetStatusFilter={onSetStatusFilter}
                    />
                </PageLayout>
            )}
        </>
    );
});

Requests.displayName = "Requests";
