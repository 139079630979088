import type { FC } from "react";

import type { ImageProps } from "@chakra-ui/react";
import { Image } from "@chakra-ui/react";

import esrf from "./ESRF.svg";

import analysis from "@mt-assets/measurement.png";
import measurement2 from "@mt-assets/measurement_2.png";
import samples from "@mt-assets/samples.png";

export const MeasurementsImage: FC<ImageProps> = (props) => (
    <Image w="110px" {...props} src={measurement2} />
);
export const SamplesImage: FC<ImageProps> = (props) => (
    <Image w="110px" {...props} src={samples} />
);

export const AnalyzeImage: FC<ImageProps> = (props) => (
    <Image w="110px" {...props} src={analysis} />
);

export const ESRFIcon: FC<ImageProps> = (props) => (
    <Image {...props} src={esrf} />
);
