import type { FC } from "react";

import { Box } from "@chakra-ui/react";

import svg from "@mt-assets/swirl_spinner.svg";

export const LoadingSwirl: FC = () => {
    return (
        <Box width="100px" height="100px">
            <img src={svg} width="90" height="90" alt="swirl" />
        </Box>
    );
};
