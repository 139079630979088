import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export function OrganizationEmptyState(props: { onClick: () => void }) {
    return (
        <EmptyState
            icon={(props) => <Icon.Organization {...props} />}
            heading="No Organizations"
            body="You haven't created any Organizations yet. Start by creating one."
            onClick={props.onClick}
            buttonLabel="Create Organization"
        />
    );
}
