export function createCrystalliteSizePredictionKey(
    orgId: string | undefined,
    measurementId: string | undefined,
) {
    return [
        QueryKeys.organization,
        orgId,
        QueryKeys.measurement,
        "crystallite-size",
        measurementId,
    ];
}

export const QueryKeys = {
    sample: "sample",
    beamtime: "beamtime",
    request: "request",
    measurementGroup: "measurementGroup",
    measurementTask: "measurementTask",
    measurement: "measurement",
    artifact: "artifact",
    artifactData: "artifactData",
    profile: "profile",
    quote: "quote",
    balance: "balance",
    organization: "organization",
    organization_authz: "organization_authz",
    admin_authz: "admin_authz",
    users: "users",
    package: "package",
} as const;
