import { type FC, useRef, useState } from "react";
import { useDebounce } from "react-use";

import {
    Avatar,
    Box,
    Button,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Tag,
    VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

import { LoadingSpinner } from "@mt-components/states/Loading/LoadingSpinner.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { type agentSchema, usersQueryKey } from "src/api/client";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { routes } from "src/App/Routes/routes";
import { DataTable } from "src/packages/components/DataTable";
import { SearchFilterBar } from "src/packages/components/Input/SearchFilterBar";
import { PageHeader } from "src/packages/components/Layout/PageHeader";
import { PageLayout } from "src/packages/components/Layout/PageLayout";

export const UsersPage: FC = () => {
    const navigateToUser = useNavigate();

    const tableRef = useRef(null);
    const [filter, setFilter] = useState<string>("");
    const [debouncedFilter, setDebouncedFilter] = useState<string>("");
    useDebounce(() => setFilter(debouncedFilter), 300, [debouncedFilter]);

    const [endCursor, setEndCursor] = useState<string | null>();

    const snackbar = useSnackbar();

    const api = useAPI();
    const usersQuery = useQuery({
        queryKey: [...usersQueryKey(), filter, endCursor],
        queryFn: () => {
            return api.agentClient.GET("/index/users", {
                params: {
                    query: {
                        userName: filter || undefined,
                        after: endCursor ?? undefined,
                        first: 50,
                    },
                },
            });
        },
    });
    const indexRecords = usersQuery.data?.data?.items ?? [];

    const queryClient = useQueryClient();
    const userStateMutation = useMutation({
        mutationFn: (vars: {
            userId: string;
            state: agentSchema.components["schemas"]["UserState"];
        }) => {
            return api.agentClient.PUT("/users/{userId}/state", {
                params: { path: { userId: vars.userId } },
                body: { state: vars.state },
            });
        },
        onSuccess: (_data, vars) => {
            const indexRecord = indexRecords.find(
                (x) => x.user.id === vars.userId,
            );
            if (!indexRecord) return;

            snackbar.success(
                `User ${indexRecord.user.email} state changed to ${vars.state}`,
            );

            queryClient.invalidateQueries({ queryKey: usersQueryKey() });
        },
        onError: (_data, vars) => {
            const indexRecord = indexRecords.find(
                (x) => x.user.id === vars.userId,
            );
            if (!indexRecord) return;

            snackbar.error(`User ${indexRecord.user.email} state change error`);
        },
    });

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <PageHeader
                    actionsId="users-page-actions"
                    crumbs={[
                        {
                            id: "users",
                            isCurrentPage: true,
                            label: "Users",
                        },
                    ]}
                    subtitle="Users of the momentum transfer platform"
                    menuActions={[]}
                />

                <HStack>
                    <SearchFilterBar
                        value={debouncedFilter}
                        onReset={() => setDebouncedFilter("")}
                        onChange={(e) =>
                            setDebouncedFilter(e.currentTarget.value)
                        }
                        placeholder="Fuzzy search by username and email"
                        isDisabled={userStateMutation.isPending}
                    />
                    <Button
                        isDisabled={
                            !usersQuery.data?.data?.info.endCursor ||
                            userStateMutation.isPending
                        }
                        onClick={() =>
                            setEndCursor(
                                usersQuery.data?.data?.info.endCursor ?? null,
                            )
                        }
                    >
                        Next
                    </Button>
                    <Button
                        isDisabled={
                            usersQuery.isPending ||
                            (!filter && !endCursor) ||
                            userStateMutation.isPending
                        }
                        onClick={() => {
                            setEndCursor(null);
                            setFilter("");
                            setDebouncedFilter("");
                        }}
                    >
                        Reset
                    </Button>
                </HStack>

                <Box flex="1" w="100%" overflow="hidden">
                    {usersQuery.isFetching || userStateMutation.isPending ? (
                        <VStack justifyContent="center" h="200px">
                            <LoadingSpinner />
                        </VStack>
                    ) : (
                        <DataTable
                            variant="unstyled"
                            instanceRef={tableRef}
                            isSortable
                            columns={[
                                {
                                    accessorKey: "email",
                                    header: "Email",
                                    cell: (cell) => {
                                        const { avatarUrl, email } =
                                            cell.row.original.user;

                                        return (
                                            <HStack>
                                                <Avatar
                                                    size="sm"
                                                    src={avatarUrl}
                                                />
                                                <Box>{email}</Box>
                                            </HStack>
                                        );
                                    },
                                },
                                {
                                    accessorKey: "name",
                                    header: "Name",
                                },
                                {
                                    accessorKey: "id",
                                    header: "Id",
                                },
                                {
                                    accessorKey: "state",
                                    header: "Status",
                                    cell: (cell) => {
                                        const bg =
                                            cell.row.original.state === "active"
                                                ? "green.500"
                                                : "red.500";
                                        const text =
                                            cell.row.original.state === "active"
                                                ? "Active"
                                                : "Inactive";

                                        return (
                                            <Tag bg={bg} color="white">
                                                {text}
                                            </Tag>
                                        );
                                    },
                                },
                                {
                                    accessorKey: "menu",
                                    header: " ",
                                    cell: (cell) => {
                                        const userId =
                                            cell.row.original.user.id;
                                        const state = cell.row.original.state;

                                        return (
                                            <Menu>
                                                <MenuButton
                                                    bg="white"
                                                    aria-label="Menu"
                                                    colorScheme="gray"
                                                    as={IconButton}
                                                    icon={<Icon.Menu />}
                                                />
                                                <MenuList>
                                                    <MenuItem
                                                        onClick={() => {
                                                            userStateMutation.mutate(
                                                                {
                                                                    userId,
                                                                    state:
                                                                        state ===
                                                                        "active"
                                                                            ? "inactive"
                                                                            : "active",
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        {state === "active"
                                                            ? "Deactivate"
                                                            : "Activate"}
                                                    </MenuItem>
                                                </MenuList>
                                            </Menu>
                                        );
                                    },
                                },
                            ]}
                            data={indexRecords}
                            getRowId={(row) => row.user.id}
                            onRowClick={(x) => {
                                void navigateToUser({
                                    to: routes.agent.user.url,
                                    params: { userId: x.user.id },
                                });
                            }}
                        />
                    )}
                </Box>
            </Box>
        </PageLayout>
    );
};
