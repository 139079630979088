import type { FC } from "react";
import { memo, useMemo, useRef, useState } from "react";

import { identity, isNotNil } from "ramda";

import { Box } from "@chakra-ui/react";
import type {
    CellContext,
    ColumnDefTemplate,
    OnChangeFn,
    Table,
} from "@tanstack/react-table";

import { Organization } from "@app/domain/api/organization.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import { OrganizationFilter } from "@app-components/organization/OrganizationFilter.tsx";
import { SampleFormFilter } from "@app-components/sample/SampleFormFilter.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { redGray } from "@mt-components/Display/YesNoIndicator/schemes.ts/ColorIndicator.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";

import { formatDate } from "@mt-tools/formatter/localization.ts";
import { toId } from "@mt-tools/iterating/filter.ts";
import type { IndexBoolean } from "@mt-tools/types.ts";

import { YesNoIndicator } from "src/packages/components/Display/YesNoIndicator";
import { useDebouncedSearch } from "src/packages/hooks/useSearch.tsx";

const yesRedNoGray: ColumnDefTemplate<CellContext<SampleModel, unknown>> = (
    row,
) => {
    const value = row.getValue(); // @todo not typesafe
    return (
        <YesNoIndicator<"true" | "false">
            config={redGray}
            value={value ? "true" : "false"}
        />
    );
};

const columns: ColumnDef<SampleModel & { organizationName?: string }>[] = [
    {
        accessorKey: "name",
        header: "Sample-ID",
        thProps: makeWidth("220px"),
    },
    {
        accessorKey: "organization",
        header: "Organization",
        accessorFn: (originalRow) => originalRow.organizationName,
        thProps: makeWidth("200px"),
    },
    {
        accessorKey: "qrCode",
        header: "QR-Code",
        thProps: makeWidth("160px"),
    },
    {
        accessorKey: "substance",
        header: "Substance",
        thProps: makeWidth("300px"),
    },
    {
        accessorKey: "casNumber",
        header: "CAS Number",
        thProps: makeWidth("100px"),
    },
    {
        accessorKey: "composition",
        header: "Composition",
        thProps: makeWidth("220px"),
    },
    {
        accessorKey: "form",
        header: "Form",
        thProps: makeWidth("100px"),
    },
    {
        accessorKey: "formDescription",
        header: "Form Description",
        thProps: makeWidth("300px"),
    },
    {
        accessorKey: "toxic",
        header: "Toxic?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "flammable",
        header: "Flammable?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "corrosive",
        header: "Corrosive?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "oxidizing",
        header: "Oxidizing?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "airSensitive",
        header: "Air-sensitive?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "otherHazards",
        header: "Other hazards?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "sampleHandlingRisk",
        header: "Sample handling risk?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "sampleHandlingRiskDescription",
        header: "Details",
        thProps: makeWidth("240px"),
    },
    {
        accessorKey: "containsHazardousSubstances",
        header: "Contains hazardous substances?",
        cell: yesRedNoGray,
        thProps: makeWidth("60px"),
    },
    {
        accessorKey: "containsHazardousSubstancesDescription",
        header: "Details",
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "createdAt" satisfies keyof SampleModel,
        header: "Created",
        accessorFn: (originalRow) =>
            originalRow.createdAt ? formatDate(originalRow.createdAt) : "",
        cell: (cell) => {
            return formatDate(cell.row.original.createdAt);
        },
        thProps: makeWidth("140px"),
    },
    {
        accessorKey: "updatedAt" satisfies keyof SampleModel,
        header: "Updated",
        accessorFn: (originalRow) =>
            originalRow.updatedAt ? formatDate(originalRow.updatedAt) : "",
        cell: (cell) => {
            return formatDate(cell.row.original.updatedAt);
        },
        thProps: makeWidth("140px"),
    },
] satisfies ColumnDef<SampleModel & { organizationName?: string }>[];

const initialState = {
    sorting: [
        {
            id: "updatedAt",
            desc: true,
        },
    ],
};

type Props = {
    isDisabled?: boolean;
    samples: SampleModel[] & { organizationName?: string };
    sampleSelection: { list: string[]; byId: IndexBoolean };
    onSelectSample: OnChangeFn<IndexBoolean>;
};

export const SamplesTable: FC<Props> = memo(
    ({ samples, sampleSelection, onSelectSample }) => {
        const tableRef = useRef<Table<SampleModel>>(null);
        const organizationsQuery = Organization.useAdminGetAll();

        const [orgFilter, setOrgFilter] = useState<string | undefined>(
            undefined,
        );

        const [sampleFormFilter, setSampleFormFilter] = useState<
            SampleModel["form"] | undefined
        >(undefined);

        const { filteredOrders, onClear, onChange } =
            useDebouncedSearch<SampleModel>({
                items: samples,
                keys: [
                    "name",
                    "qrCode",
                    "substance",
                    "casNumber",
                    "composition",
                ],
            });

        const filtered = useMemo(() => {
            return orgFilter || sampleFormFilter
                ? filteredOrders.filter(({ organizationId, form }) => {
                      const result = [
                          orgFilter ? organizationId === orgFilter : null,
                          sampleFormFilter ? sampleFormFilter === form : null,
                      ].filter(isNotNil);

                      return result.every(identity<boolean>);
                  })
                : filteredOrders;
        }, [orgFilter, sampleFormFilter, filteredOrders]);

        const state = useMemo(
            () => ({
                rowSelection: sampleSelection.byId,
            }),
            [sampleSelection.byId],
        );

        return (
            <Box
                data-test-id="samples-table"
                display="flex"
                flexDirection="column"
                h="100%"
                overflow="hidden"
                w="100%"
            >
                <SearchFilterBar
                    onReset={onClear}
                    onChange={onChange}
                    placeholder="Search by property"
                    filter={
                        <>
                            <OrganizationFilter
                                value={orgFilter}
                                onChange={setOrgFilter}
                                options={organizationsQuery.data ?? []}
                            />
                            <SampleFormFilter
                                value={sampleFormFilter}
                                onChange={setSampleFormFilter}
                            />
                        </>
                    }
                />
                <Box flex="1" w="100%" overflow="hidden">
                    <DataTable<SampleModel>
                        variant="unstyled"
                        instanceRef={tableRef}
                        isSelectable
                        enableMultiRowSelection
                        isSortable
                        getRowId={toId}
                        initialState={initialState}
                        state={state}
                        onRowSelectionChange={onSelectSample}
                        columns={columns}
                        data={filtered}
                    />
                </Box>
            </Box>
        );
    },
);

SamplesTable.displayName = "SamplesTable";
