import type { FC } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";

export const AlphaBanner: FC<BoxProps> = (props) => (
    <Box px="24px" bg="blue.50" p="12px" {...props}>
        <Text mb="12px" textStyle="h5" fontWeight="700">
            alpha version
        </Text>
        <Text textStyle="T2LH120">
            This is an early access version of the plotting studio. It can be
            rough around the edges.
        </Text>

        <Text textStyle="T2LH120" fontWeight="700">
            Be aware the plots are only saved in your devices&apos; local
            storage
        </Text>
    </Box>
);
