import { agent } from "@app/Routes/agentRoutes.tsx";
import {
    createLoginRoute,
    createProfileRoute,
    createSigningCallback,
} from "@app/Routes/commonRoutes.ts";
import { publicRoot } from "@app/Routes/publicRoute.tsx";
import { user } from "@app/Routes/userRoutes.tsx";

export const routeTree = publicRoot.addChildren([
    createLoginRoute(publicRoot),
    createProfileRoute(publicRoot),
    createSigningCallback(publicRoot),
    ...Object.values(user),
    ...Object.values(agent),
]);
