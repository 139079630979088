import React from "react";

import { chakra } from "@chakra-ui/react";

export const Anchor: React.FC<
    React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    >
> = (props) => <chakra.a {...props} />;
