import type { ChangeEvent, FC } from "react";

import type { Color, PlotData } from "plotly.js";
import { pluck } from "ramda";

import { Box, IconButton, Tooltip } from "@chakra-ui/react";

import { ColorPicker } from "@mt-components/Colorscales/ColorPicker.tsx";
import { CheckboxField } from "@mt-components/Input/CheckboxField.tsx";

import { OnOff } from "./OnOff.tsx";

type TraceId = string;
type Props = {
    traces: ({ id: TraceId } & Partial<{
        name: string;
        marker: PlotData["marker"];
    }>)[];
    selectedCharts: TraceId[];
    activeCharts: number[];
    toggleSelectChart: (checked: boolean, ids: TraceId[]) => void;
    onChangeChartColor: (color: string, id: TraceId) => void;
    toggleChart: (isOn: boolean, idx: number[]) => void;
};

const isSimpleColor = (
    color: Color | Color[] | undefined,
): color is string | undefined =>
    typeof color === "string" || typeof color === "undefined";

export const DataTree: FC<Props> = ({
    toggleSelectChart,
    traces,
    activeCharts,
    toggleChart,
    selectedCharts,
    onChangeChartColor,
}) => {
    const areAllSelected = selectedCharts.length === traces.length;
    return (
        <Box
            minW="220px"
            display="flex"
            flexDirection="column"
            flex="0 0 220px"
            height="100%"
        >
            <Box
                key="all"
                mr="2px"
                display="flex"
                alignItems="center"
                flexDirection="row"
                width="100%"
                pr="8px"
                flex="0 0 60px"
                overflow="hidden"
                borderBottom="solid 1px"
                borderColor="gray.300"
            >
                <CheckboxField
                    label="Select all"
                    onChange={() =>
                        toggleSelectChart(!areAllSelected, pluck("id", traces))
                    }
                    isIndeterminate={Boolean(
                        selectedCharts.length &&
                            selectedCharts.length !== traces.length,
                    )}
                    isChecked={areAllSelected && selectedCharts.length > 0}
                />

                <Box ml="auto" mr="4px"></Box>
                <Tooltip label="Toggle visibility" placement="right">
                    <IconButton
                        onClick={() => {
                            const isOn = activeCharts.length === traces.length;
                            toggleChart(
                                isOn,
                                traces.map((_, idx) => idx),
                            );
                        }}
                        variant="ghost"
                        size="sm"
                        icon={
                            <OnOff
                                isOn={activeCharts.length === traces.length}
                            />
                        }
                        aria-label="Toggle Sidebar"
                    />
                </Tooltip>
            </Box>
            {traces.map((trace, idx) => {
                return (
                    <Box
                        key={trace.name}
                        mr="2px"
                        display="flex"
                        alignItems="center"
                        flexDirection="row"
                        width="100%"
                        pr="8px"
                        flex="0 0 60px"
                        overflow="hidden"
                    >
                        <Tooltip label="Select" placement="left">
                            <Box>
                                <CheckboxField
                                    w="100%"
                                    h="40px"
                                    label={trace.name}
                                    mr="12px"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>,
                                    ) => {
                                        toggleSelectChart(e.target.checked, [
                                            trace.id,
                                        ]);
                                    }}
                                    isChecked={selectedCharts.includes(
                                        trace.id,
                                    )}
                                />
                            </Box>
                        </Tooltip>
                        {/*<Text fontSize="md">{sample.name}</Text>*/}
                        <Box ml="auto" mr="4px">
                            <ColorPicker
                                value={
                                    isSimpleColor(trace.marker?.color)
                                        ? trace.marker?.color
                                        : "black"
                                }
                                onChange={(color) =>
                                    onChangeChartColor(color, trace.id)
                                }
                            />
                        </Box>
                        <Tooltip label="Toggle visibility" placement="right">
                            <IconButton
                                onClick={() => {
                                    const isOn = !activeCharts.includes(idx);
                                    toggleChart(isOn, [idx]);
                                }}
                                variant="ghost"
                                size="sm"
                                icon={
                                    <OnOff isOn={activeCharts.includes(idx)} />
                                }
                                aria-label="Toggle Sidebar"
                            />
                        </Tooltip>
                    </Box>
                );
            })}
        </Box>
    );
};
