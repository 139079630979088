export function computeTraceOptionsFromSchema(schema, context) {
    // Filter out Polar "area" type as it is fairly broken and we want to present
    // scatter with fill as an "area" chart type for convenience.
    const traceTypes = Object.keys(schema.traces).filter(
        (t) => !["area", "scattermapbox"].includes(t),
    );

    const traceOptions = [
        {
            value: "scatter",
            label: "Scatter",
        },
        {
            value: "box",
            label: "Box",
        },
        {
            value: "bar",
            label: "Bar",
        },
        {
            value: "heatmap",
            label: "Heatmap",
        },
        {
            value: "histogram",
            label: "Histogram",
        },
        {
            value: "histogram2d",
            label: "2D Histogram",
        },
        {
            value: "histogram2dcontour",
            label: "2D Contour Histogram",
        },
        {
            value: "pie",
            label: "Pie",
        },
        {
            value: "contour",
            label: "Contour",
        },
        {
            value: "scatterternary",
            label: "Ternary Scatter",
        },
        {
            value: "violin",
            label: "Violin",
        },
        {
            value: "scatter3d",
            label: "3D Scatter",
        },
        {
            value: "surface",
            label: "Surface",
        },
        {
            value: "mesh3d",
            label: "3D Mesh",
        },
        {
            value: "cone",
            label: "Cone",
        },
        {
            value: "streamtube",
            label: "Streamtube",
        },
        {
            value: "scattergeo",
            label: "Atlas Map",
        },
        {
            value: "choropleth",
            label: "Choropleth",
        },
        {
            value: "scattergl",
            label: "Scatter GL",
        },
        {
            value: "pointcloud",
            label: "Point Cloud",
        },
        {
            value: "heatmapgl",
            label: "Heatmap GL",
        },
        {
            value: "parcoords",
            label: "Parallel Coordinates",
        },
        {
            value: "sankey",
            label: "Sankey",
        },
        {
            value: "table",
            label: "Table",
        },
        {
            value: "carpet",
            label: "Carpet",
        },
        {
            value: "scattercarpet",
            label: "Carpet Scatter",
        },
        {
            value: "contourcarpet",
            label: "Carpet Contour",
        },
        {
            value: "ohlc",
            label: "OHLC",
        },
        {
            value: "candlestick",
            label: "Candlestick",
        },
        {
            value: "scatterpolar",
            label: "Polar Scatter",
        },
        {
            value: "scatterpolargl",
            label: "Polar Scatter GL",
        },
        {
            value: "barpolar",
            label: "Polar Bar",
        },
        {
            value: "isosurface",
            label: "Isosurface",
        },
    ].filter((obj) => traceTypes.indexOf(obj.value) !== -1);

    const traceIndex = (traceType) =>
        traceOptions.findIndex((opt) => opt.value === traceType);

    traceOptions.splice(
        traceIndex("scatter") + 1,
        0,
        { label: "Line", value: "line" },
        { label: "Area", value: "area" },
        { label: "Timeseries", value: "timeseries" },
    );

    traceOptions.splice(traceIndex("scatter3d") + 1, 0, {
        label: "3D Line",
        value: "line3d",
    });

    if (context.config && context.config.mapboxAccessToken) {
        traceOptions.push({
            value: "scattermapbox",
            label: "Satellite Map",
        });
    }

    return traceOptions;
}
