import type { ReactElement, ReactNode } from "react";

import { Box, IconButton, Tooltip } from "@chakra-ui/react";

const getRounded = (isFirst: boolean, isLast: boolean) => {
    if (isFirst) {
        return {
            roundedBottomRight: 0,
            roundedTopRight: 0,
        };
    }

    if (isLast) {
        return {
            roundedBottomLeft: 0,
            roundedTopLeft: 0,
        };
    }

    return {};
};

export type ToggleItem<TData> = {
    id: TData;
    icon: ReactElement;
    label: ReactNode;
    ariaLabel: string;
};

type ToggleButtonBarProps<TData> = {
    onToggle: (option: TData) => void;
    option: string;
    items: ToggleItem<TData>[];
};

export function ToggleButtonBar<TData extends string | number>(
    props: ToggleButtonBarProps<TData>,
) {
    const { items, onToggle, option } = props;
    return (
        <Box minWidth={`${items.length * 32}px`}>
            {items.map((i, index) => {
                const isFirst = index === 0;
                const isLast = index === items.length - 1;

                return (
                    <Tooltip key={i.id} label={i.label}>
                        <IconButton
                            width="32px"
                            colorScheme="gray"
                            {...getRounded(isFirst, isLast)}
                            onClick={() => onToggle(i.id)}
                            isActive={i.id === option}
                            icon={i.icon}
                            aria-label={i.ariaLabel}
                        />
                    </Tooltip>
                );
            })}
        </Box>
    );
}
