import React from "react";

import {
    ColorPicker,
    Dropdown,
    Numeric,
    PlotlyFold,
    PlotlySection,
    Radio,
    Text,
    TextEditor,
    TraceRequiredPanel,
} from "../components";

const StyleLegendPanel = (props) => (
    <TraceRequiredPanel>
        <PlotlyFold name="Legend">
            <Radio
                attr="showlegend"
                options={[
                    { label: "Show", value: true },
                    { label: "Hide", value: false },
                ]}
            />

            <PlotlySection name="Legend Title">
                <Text label="Title" attr="legend.title.text" />
                {/*<FontSelector*/}
                {/*    label="Typeface"*/}
                {/*    attr="legend.title.font.family"*/}
                {/*/>*/}
                <Numeric
                    label="Size"
                    attr="legend.title.font.size"
                    units="px"
                />
                <ColorPicker label="Color" attr="legend.title.font.color" />
            </PlotlySection>
            <PlotlySection name="Text">
                {/*<FontSelector label="Typeface" attr="legend.font.family" />*/}
                <Numeric label="Size" attr="legend.font.size" units="px" />
                <ColorPicker label="Color" attr="legend.font.color" />
            </PlotlySection>
            <PlotlySection name="Legend Box">
                <Numeric
                    label="Border Width"
                    attr="legend.borderwidth"
                    units="px"
                />
                <ColorPicker label="Border Color" attr="legend.bordercolor" />
                <ColorPicker label="Background Color" attr="legend.bgcolor" />
            </PlotlySection>
            <PlotlySection name="Horizontal Positioning">
                <Dropdown
                    label="Anchor Point"
                    clearable={false}
                    attr="legend.xanchor"
                    options={[
                        { label: "Auto", value: "auto" },
                        { label: "Left", value: "left" },
                        { label: "Center", value: "center" },
                        { label: "Right", value: "right" },
                    ]}
                />
                <Numeric
                    label="Position"
                    showSlider
                    step={0.02}
                    attr="legend.x"
                />
            </PlotlySection>
            <PlotlySection name="Vertical Positioning">
                <Dropdown
                    label="Anchor Point"
                    clearable={false}
                    attr="legend.yanchor"
                    options={[
                        { label: "Auto", value: "auto" },
                        { label: "Top", value: "top" },
                        { label: "Middle", value: "middle" },
                        { label: "Bottom", value: "bottom" },
                    ]}
                />
                <Numeric
                    label="Position"
                    showSlider
                    step={0.02}
                    attr="legend.y"
                />
                <Dropdown
                    label="Text Alignment"
                    clearable={false}
                    attr="legend.valign"
                    options={[
                        { label: "Top", value: "top" },
                        { label: "Middle", value: "middle" },
                        { label: "Bottom", value: "bottom" },
                    ]}
                />
            </PlotlySection>
            <PlotlySection name="Orientation">
                <Radio
                    attr="legend.orientation"
                    options={[
                        { label: "Vertical", value: "v" },
                        { label: "Horizontal", value: "h" },
                    ]}
                />
            </PlotlySection>
            {/*<PlotlySection name="Traces">*/}
            {/*    <Dropdown*/}
            {/*        label="Trace Order"*/}
            {/*        attr="legend.traceorder"*/}
            {/*        options={[*/}
            {/*            { label: "Normal", value: "normal" },*/}
            {/*            { label: "Reversed", value: "reversed" },*/}
            {/*            { label: "Grouped", value: "grouped" },*/}
            {/*            {*/}
            {/*                label: "Reversed and Grouped",*/}
            {/*                value: "reversed+grouped",*/}
            {/*            },*/}
            {/*        ]}*/}
            {/*    />*/}
            {/*    <Dropdown*/}
            {/*        label="Item Sizing"*/}
            {/*        attr="legend.itemsizing"*/}
            {/*        options={[*/}
            {/*            { label: "Trace", value: "trace" },*/}
            {/*            { label: "Constant", value: "constant" },*/}
            {/*        ]}*/}
            {/*    />*/}
            {/*    <Numeric*/}
            {/*        label="Gap Between Groups"*/}
            {/*        attr="legend.tracegroupgap"*/}
            {/*        units="px"*/}
            {/*    />*/}
            {/*</PlotlySection>*/}
        </PlotlyFold>
    </TraceRequiredPanel>
);

export default StyleLegendPanel;
