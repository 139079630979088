import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const {
    definePartsStyle: defineCheckboxPartsStyle,
    defineMultiStyleConfig: defineCheckboxMultiStyleConfig,
} = createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const checkboxBaseStyle = defineCheckboxPartsStyle({
    control: {
        borderRadius: 3, // change the border radius of the control
    },
});

const checkboxStyles = defineCheckboxMultiStyleConfig({
    baseStyle: checkboxBaseStyle,
});

export const checkBoxTheme = {
    components: { Checkbox: checkboxStyles },
};
