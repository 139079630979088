import type { ChakraTheme } from "@chakra-ui/react";

import { customOverrides } from "@mt-design/baseStyles.ts";
import { textStyles } from "@mt-design/fontStyles.ts";

const buttonStyles: Partial<ChakraTheme> = {
    components: {
        Button: {
            baseStyle: {
                ...textStyles.T2W500LH100,
            },
            sizes: {
                sm: {
                    fontSize: textStyles.T2W500LH100.fontSize,
                },
            },
            variants: {
                outline: {
                    borderWidth: "2px",
                },
            },
        },
    },
};

// @todo: the theming is not perfect yet
export const buttonTheme = {
    ...buttonStyles,
    shadows: {
        outline: `0 0 0 3px ${customOverrides.colors.secondary["300"]}`,
    },
};
