import { createContext, useContext } from "react";

import type { Role } from "../domain/auth";

export type RoleContextValue = {
    role: Role;
};

export const RoleContext = createContext<RoleContextValue | null>(null);

export const useRoleContext = (): RoleContextValue => {
    const ctx = useContext(RoleContext);
    if (!ctx) {
        throw new Error(
            "useRoleContext needs to be wrapped in a context providder",
        );
    }
    return ctx;
};
