import type { FC } from "react";
import { useState } from "react";

import { toPairs } from "ramda";

import { Box, Button, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { useArtifactsDownloader } from "@app-components/requestResult/ArtifactsDownloader.tsx";

import { CheckboxField } from "@mt-components/Input/CheckboxField.tsx";
import { Accordion } from "@mt-components/Layout/Accordion/Accordion.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

type DownloadModalProps = {
    measurementGroup: MeasurementGroupModel;
    onClose: () => void;
    isOpen: boolean;
};

type DownloadState = {
    raw: boolean;
    plot: boolean;
    intermediate: boolean;
    target: boolean;
};

export const DownloadModal: FC<DownloadModalProps> = ({
    measurementGroup,
    isOpen,
    onClose,
}) => {
    const [downloadState, setDownloadState] = useState<DownloadState>({
        raw: false,
        intermediate: false,
        plot: true,
        target: true,
    });

    const downloadArtifacts = useArtifactsDownloader(
        measurementGroup.organizationId,
        {
            kind: toPairs<DownloadState>(downloadState)
                .filter((d) => d[1])
                .map((d) => d[0]),
            measurementGroupId: measurementGroup.id,
        },
    );

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;

        setDownloadState((prev) => {
            return {
                ...prev,
                [name]: e.target.checked,
            };
        });
    };

    return (
        <Modal
            isOpen={isOpen}
            header="Download your results"
            onClose={onClose}
            bodyProps={{
                position: "relative",
            }}
            buttonRight={
                <Button
                    colorScheme="blue"
                    onClick={() => {
                        downloadArtifacts();
                        onClose();
                    }}
                >
                    Download
                </Button>
            }
        >
            <Text textStyle="T2" my="12px">
                Specify the granularity of your data download
            </Text>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
            >
                <Accordion
                    hasBottomBorder
                    title={
                        <CheckboxField
                            label="Raw data"
                            name="raw"
                            isChecked={downloadState.raw}
                            onChange={onChange}
                            size="md"
                        />
                    }
                    body={
                        <Text
                            mt="8px"
                            pl="29px"
                            pr="15px"
                            fontSize="md"
                            display="block"
                            color="gray.600"
                        >
                            Unprocessed detector images
                        </Text>
                    }
                />
                <Accordion
                    hasBottomBorder
                    title={
                        <CheckboxField
                            label="Intermediate steps"
                            name="intermediate"
                            isChecked={downloadState.intermediate}
                            onChange={onChange}
                            size="md"
                        />
                    }
                    body={
                        <Text
                            mt="8px"
                            pl="29px"
                            pr="15px"
                            fontSize="md"
                            display="block"
                            color="gray.600"
                        >
                            Intermediate files of each step of the processing
                            pipeline, including partially corrected files:
                            integrated, background substracted and offset
                            corrections can be followed.
                        </Text>
                    }
                />
                <Accordion
                    hasBottomBorder
                    title={
                        <CheckboxField
                            label="Plots"
                            name="plot"
                            isChecked={downloadState.plot}
                            onChange={onChange}
                            size="md"
                        />
                    }
                    body={
                        <Text
                            mt="8px"
                            pl="29px"
                            pr="15px"
                            fontSize="md"
                            display="block"
                            color="gray.600"
                        >
                            Preview images of some datasets
                        </Text>
                    }
                />

                <Accordion
                    hasBottomBorder={false}
                    title={
                        <CheckboxField
                            label="Processed (corrected) data"
                            name="target"
                            isChecked={downloadState.target}
                            onChange={onChange}
                            size="md"
                        />
                    }
                    body={
                        <Text
                            mt="8px"
                            pl="29px"
                            pr="15px"
                            fontSize="md"
                            display="block"
                            color="gray.600"
                        >
                            The final dataset. Reduced, integrated, background
                            subtracted, offset corrected dataset ready for
                            further for analysis.
                        </Text>
                    }
                />
            </Box>
        </Modal>
    );
};
