/* eslint-disable */
// @ts-nocheck
import tinyColor from "tinycolor2";
import { getIconForChart } from "@chart-editor/assets/plotIcons.tsx";
import { templateString } from "plotly.js/src/lib";
import { Box } from "@chakra-ui/react";
import { getColorscale } from "@mt-components/Colorscales";
import { ComponentType } from "react";

export const TOO_LIGHT_FACTOR = 0.8;

export function clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
}

export function getDisplayName(WrappedComponent: ComponentType) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export function tooLight(color: string) {
    const hslColor = tinyColor(color).toHsl();
    return hslColor.l > TOO_LIGHT_FACTOR;
}

export function renderTraceIcon(trace: string) {
    if (!trace) {
        return null;
    }
    const gl = "gl";
    const componentName = trace.endsWith(gl)
        ? trace.slice(0, -gl.length)
        : trace;

    const lower = componentName.toLowerCase();

    const ChartIcon = getIconForChart(lower);

    return () => (
        <Box fill="none" w="100%" h="100%">
            <ChartIcon size="100%" />
        </Box>
    );
}

export function transpose(originalArray: unknown[][]) {
    // if we want to transpose a uni dimensional array
    if (originalArray.every((a) => !Array.isArray(a))) {
        return originalArray.map((a) => [a]);
    }

    let longestArrayItem = Array.isArray(originalArray[0])
        ? originalArray[0].length
        : 1;

    originalArray.forEach((a) => {
        // if it's not an array, it's a string
        const length = Array.isArray(a) ? a.length : 1;
        if (length > longestArrayItem) {
            longestArrayItem = length;
        }
    });

    const newArray = new Array(longestArrayItem);

    for (let outerIndex = 0; outerIndex < originalArray.length; outerIndex++) {
        if (!Array.isArray(originalArray[outerIndex])) {
            originalArray[outerIndex] = [originalArray[outerIndex]];
        }

        for (let innerIndex = 0; innerIndex < longestArrayItem; innerIndex++) {
            // ensure we have an array to push to
            if (!Array.isArray(newArray[innerIndex])) {
                newArray[innerIndex] = [];
            }

            const value =
                typeof originalArray[outerIndex][innerIndex] !== "undefined"
                    ? originalArray[outerIndex][innerIndex]
                    : null;
            newArray[innerIndex].push(value);
        }
    }

    return newArray;
}

export const specialTableCase = (
    traceType: string,
    srcAttributePath: string,
) => {
    /* Just more user friendly
     * Table traces have many configuration options,
     * The below attributes can be 2d or 1d and will affect the plot differently
     * EX:
     * header.values = ['Jan', 'Feb', 'Mar'] => will put data in a row
     * header.values = [['Jan', 1], ['Feb', 2], ['Mar', 3]] => will create 3 columns
     * 1d arrays affect columns
     * 2d arrays affect rows within each column
     */
    return (
        traceType === "table" &&
        [
            "header.valuessrc",
            "header.font.colorsrc",
            "header.font.sizesrc",
            "header.fill.colorsrc",
            "columnwidthsrc",
        ].some((a) => srcAttributePath.endsWith(a))
    );
};

export function maybeTransposeData(
    data: unknown[],
    srcAttributePath: string,
    traceType: string,
) {
    if (!data || (Array.isArray(data) && data.length === 0)) {
        return null;
    }

    const isTransposable2DArray =
        srcAttributePath.endsWith("zsrc") &&
        [
            "contour",
            "contourgl",
            "heatmap",
            "heatmapgl",
            "surface",
            "carpet",
            "contourcarpet",
        ].includes(traceType);

    if (isTransposable2DArray) {
        return transpose(data);
    }

    if (
        specialTableCase(traceType, srcAttributePath) &&
        Array.isArray(data[0]) &&
        data.length === 1
    ) {
        return data[0];
    }

    return data;
}

export function maybeAdjustSrc(
    src: unknown[],
    srcAttributePath: string,
    traceType: string,
    config: unknown,
) {
    if (!src || (Array.isArray(src) && src.length === 0)) {
        return null;
    }

    if (specialTableCase(traceType, srcAttributePath) && src.length === 1) {
        return src[0];
    }

    return config && config.fromSrc
        ? config.fromSrc(src, traceType, srcAttributePath)
        : src;
}

export function adjustColorscale(
    colorscale: string[],
    numberOfNeededColors: number,
    colorscaleType: string,
    config: { repeat?: boolean },
) {
    if (config && config.repeat) {
        if (numberOfNeededColors < colorscale.length) {
            return colorscale.slice(0, numberOfNeededColors);
        }

        const repetitions = Math.ceil(numberOfNeededColors / colorscale.length);
        const newArray = new Array(repetitions).fill(colorscale);
        return newArray
            .reduce((a, b) => {
                return a.concat(b);
            }, [])
            .slice(0, numberOfNeededColors);
    }

    return getColorscale(
        colorscale,
        numberOfNeededColors,
        null,
        null,
        colorscaleType,
    );
}

export function getFullTrace(props, context) {
    let fullTrace = {};
    if (context.fullData && context.data) {
        if (props.fullDataArrayPosition) {
            // fullDataArrayPosition will be supplied in panels that have the canGroup prop
            fullTrace = context.fullData[props.fullDataArrayPosition[0]];
        } else {
            // for all other panels, we'll find fullTrace with the data index
            fullTrace = context.fullData.filter(
                (t) => t && props.traceIndexes[0] === t.index,
            )[0];
        }

        // For transformed traces, we actually want to read in _fullInput because
        // there's original parent information that's more useful to the user there
        // This is true except for fit transforms, where reading in fullData is
        // what we want
        if (
            fullTrace &&
            fullTrace.transforms &&
            !fullTrace.transforms.some((t) =>
                ["moving-average", "fits"].includes(t.type),
            ) &&
            !props.fullDataArrayPosition
        ) {
            fullTrace = fullTrace._fullInput;
        }
    }
    return fullTrace;
}

export function getParsedTemplateString(originalString, context) {
    let text = originalString;

    if (originalString && context) {
        text = templateString(originalString, context);
    }

    return text === "" && originalString ? originalString : text;
}
