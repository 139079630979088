import { useQuery } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";

export const useGetAll = () => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.beamtime],
        queryFn: async () => await api.client.GET("/beamtimes"),
    });
};

export const useGet = (beamtimeId?: string) => {
    const api = useAPI();
    return useQuery({
        queryKey: [QueryKeys.beamtime, beamtimeId],
        queryFn: async () => {
            if (!beamtimeId) {
                return null;
            }

            return await api.client.GET("/beamtimes/{beamtimeId}", {
                params: {
                    path: {
                        beamtimeId,
                    },
                },
            });
        },
    });
};
