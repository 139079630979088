import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, extendTheme } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    overlay: {
        background: "rgb(0,0,0,0.1)",
        backdropFilter: "blur(0.5px)",
    },
});

export const modalStyles = defineMultiStyleConfig({
    baseStyle,
});

export const modalTheme = extendTheme({
    components: { Modal: modalStyles },
});
