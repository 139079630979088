import { useEffect, useMemo, useRef } from "react";

import type { UseOverlayScrollbarsParams } from "overlayscrollbars-react";
import { useOverlayScrollbars } from "overlayscrollbars-react";
import { mergeDeepRight } from "ramda";

import "overlayscrollbars/overlayscrollbars.css";

export const useScrollbar = <GElement extends HTMLElement = HTMLDivElement>(
    params: UseOverlayScrollbarsParams = {},
) => {
    const opts: UseOverlayScrollbarsParams = useMemo(() => {
        return mergeDeepRight<
            UseOverlayScrollbarsParams,
            UseOverlayScrollbarsParams
        >(
            {
                options: {
                    scrollbars: {
                        theme: "os-theme-dark",
                        autoHide: "leave",
                    },
                },
            },
            params,
        );
    }, [params]);

    const timeout =
        (typeof params.defer === "object" && params.defer.timeout) || 300;

    const ref = useRef<GElement>(null);
    const [initialize, instance] = useOverlayScrollbars(opts);

    useEffect(() => {
        setTimeout(() => {
            if (ref.current) initialize(ref.current);
        }, 500);
    }, [initialize, timeout]);

    return [ref, instance];
};
