// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, PropsWithChildren } from "react";

import { Box } from "@chakra-ui/react";

import Field from "./Field";

type Props = {
    className: string;
};

const Info: FC<PropsWithChildren<Props>> = (props) => (
    <Field {...props}>
        <Box
            textStyle="T3"
            className={`js-test-info ${props.className ? props.className : ""}`}
        >
            {props.children}
        </Box>
    </Field>
);
Info.displayName = "Info";

export default Info;

// @ts-ignore
Info.plotly_editor_traits = {
    no_visibility_forcing: true,
};

Info.propTypes = {
    ...Field.propTypes,
};
