import type { FC, ReactNode } from "react";
import { useState } from "react";
import Select from "react-select";

import { isNotNil, uniq, without } from "ramda";

import { Box, Button, IconButton, Text } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { Artifact } from "@app/domain/api/artifact.ts";
import { Measurement } from "@app/domain/api/measurement.ts";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import { Sample } from "@app/domain/api/sample.ts";

import { selectComponents } from "@mt-components/Input/Select/components.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { getById } from "@mt-tools/iterating/filter.ts";

import { Icon } from "@mt-design/icons.tsx";

import type { CSSObject } from "@emotion/react";

type DatapickerProps = {
    isOpen: boolean;
    onClose: () => void;
    onSelect: (params: { artifacts: string[]; orgId: string }) => void;
};

export type SelectOption = {
    label: string | ReactNode;
    value: string;
};

const selectStyles = {
    container: (base: CSSObject): CSSObject => ({
        ...base,
        width: "100%",
    }),
};

export const Datapicker: FC<DatapickerProps> = (props) => {
    const [selectedArtifacts, setSelectedArtifacts] = useState<string[]>([]);
    const [request, setRequest] = useState<
        (MeasurementGroupModel & SelectOption) | null
    >(null);
    const [sample, setSample] = useState<(SelectOption & SampleModel) | null>(
        null,
    );
    const [method, setMethod] = useState<SelectOption | null>(null);

    const goToPlots = () => {
        if (!sample?.organizationId) {
            return;
        }
        props.onSelect({
            orgId: sample.organizationId,
            artifacts: selectedArtifacts,
        });
    };

    // all requests
    const requestsQuery = Request.useAdminGetAll();

    // all samples
    const samplesQuery = Sample.useAdminGetAll({});

    // method methods
    const tasksQuery = MeasurementTask.useGetAll(
        request?.organizationId && request.id
            ? {
                  isActive: Boolean(request.organizationId),
                  path: {
                      organizationId: request.organizationId,
                  },
                  query: {
                      filter: {
                          measurementGroupId: request.id,
                      },
                  },
              }
            : undefined,
    );
    // all tasks
    const allTasks = tasksQuery.data;

    // user selected task
    const tasksWithSelectedMethod =
        allTasks?.filter((t) => t.method === method?.value) || [];

    const measurements = Measurement.useGetAllForTasks(
        sample
            ? {
                  isDisabled: !tasksWithSelectedMethod.length,
                  path: {
                      organizationId: sample.organizationId,
                  },
                  sampleId: sample.value,
                  measurementTaskIds:
                      allTasks
                          ?.filter((t) => t.method === method?.value)
                          .map((t) => t.id) ?? [],
              }
            : undefined,
    );

    const filteredMeasurements = measurements.data;

    const artifacts = Artifact.useGetAll(
        request
            ? {
                  params: {
                      path: {
                          organizationId: request.organizationId,
                      },
                      query: {
                          filter: {
                              measurementGroupId: request.value,
                          },
                      },
                  },
              }
            : undefined,
    );

    const filteredDownArtifacts =
        artifacts.data?.filter(
            (d) =>
                d.measurementId &&
                d.format === "xye" &&
                filteredMeasurements
                    ?.map((d) => d.id)
                    .includes(d.measurementId),
        ) ?? [];

    const sampleIdsOfRequest = uniq(
        allTasks?.map((t) => t.sampleId).filter(isNotNil) ?? [],
    );

    const tasksForSample = sample?.value
        ? allTasks?.filter((t) => t.sampleId === sample.value)
        : [];

    const methodsForSample = (tasksForSample?.map((t) => t.method) ?? []).map(
        (m) => ({ value: m, label: m }),
    );

    const samplesOfRequest: SampleModel[] = sampleIdsOfRequest
        .map((id) => samplesQuery.data?.find(getById(id)))
        .filter(isNotNil);

    const reqOpts =
        requestsQuery.data?.map((r) => {
            return {
                ...r,
                value: r.id,
                label: r.name,
            };
        }) ?? [];

    const sampleOpts = samplesOfRequest.map((r) => {
        return {
            ...r,
            value: r.id,
            label: r.name,
        };
    });

    const selectHeight = 160;
    return (
        <Modal
            header="Select dataset"
            isOpen={props.isOpen}
            onClose={props.onClose}
            buttonRight={
                <Button
                    isDisabled={!selectedArtifacts.length}
                    onClick={goToPlots}
                    colorScheme="blue"
                >
                    Use {selectedArtifacts.length} datasets
                </Button>
            }
        >
            <Box
                minH={`${selectHeight + 120}px`}
                display="flex"
                flexDirection="column"
                gap="12px"
            >
                <Box display="flex">
                    <Box flex="0 0 120px"> Request </Box>
                    <Box flex="0 0 240px" zIndex={1000}>
                        <Select<MeasurementGroupModel & SelectOption>
                            components={selectComponents}
                            maxMenuHeight={selectHeight + reqOpts.length * 50}
                            // styles={{
                            //     menuList: (base) => ({
                            //         ...base,
                            //         position: "relative",
                            //         zIndex: 1000,
                            //     }),
                            //     menuPortal: (base) => ({
                            //         ...base,
                            //         border: "solid 1px red",
                            //         zIndex: 1000,
                            //     }),
                            //     menu: (base) => ({
                            //         ...base,
                            //         position: "relative",
                            //         zIndex: 1000,
                            //     }),
                            // }}
                            value={request}
                            isClearable
                            options={reqOpts}
                            onChange={(newValue) => {
                                if (!newValue) {
                                    setRequest(null);
                                    setSample(null);
                                    setMethod(null);
                                    return;
                                }
                                setSample(null);
                                setMethod(null);
                                setRequest(newValue);
                            }}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex="0 0 120px"> Sample </Box>

                    <Box flex="0 0 240px">
                        <Select<SelectOption & SampleModel>
                            maxMenuHeight={
                                selectHeight + sampleOpts.length * 50
                            }
                            components={selectComponents}
                            styles={selectStyles}
                            value={sample}
                            isClearable
                            isDisabled={!request}
                            options={sampleOpts}
                            onChange={(newValue) => {
                                if (!newValue) {
                                    setSample(null);
                                    setMethod(null);
                                    return;
                                }

                                setSample(null);
                                setMethod(null);
                                setSample(newValue);
                            }}
                        />
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex="0 0 120px"> Method </Box>
                    <Box flex="0 0 240px">
                        <Select<SelectOption>
                            maxMenuHeight={
                                selectHeight + methodsForSample.length * 50
                            }
                            value={method}
                            components={selectComponents}
                            styles={selectStyles}
                            isClearable
                            isDisabled={!request || !sample}
                            options={methodsForSample}
                            onChange={setMethod}
                        />
                    </Box>
                </Box>
            </Box>
            <Box>
                <Text textStyle="h5" fontWeight={600}>
                    Datasets
                </Text>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    my="12px"
                >
                    <Text>Selected Artifacts {selectedArtifacts.length}</Text>
                    <Button
                        onClick={() => setSelectedArtifacts([])}
                        colorScheme="gray"
                    >
                        Clear selected
                    </Button>
                </Box>

                <Box display="flex" flexDirection="column" gap="16px">
                    {filteredDownArtifacts.map((a) => {
                        const isSelected = selectedArtifacts.includes(a.id);
                        return (
                            <Box
                                key={a.id}
                                display="flex"
                                justifyContent="space-between"
                            >
                                <Box flex="0 0 340px">{a.name}</Box>
                                <IconButton
                                    colorScheme={
                                        isSelected ? "green" : "mtblue"
                                    }
                                    onClick={() =>
                                        setSelectedArtifacts((prev) =>
                                            prev.includes(a.id)
                                                ? without([a.id], prev)
                                                : [...prev, a.id],
                                        )
                                    }
                                    icon={
                                        isSelected ? (
                                            <Icon.Check size="20" />
                                        ) : (
                                            <Icon.Plus size="20" />
                                        )
                                    }
                                    aria-label=""
                                />
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Modal>
    );
};
