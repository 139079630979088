import React from "react";

import { Box, Button, Center, chakra } from "@chakra-ui/react";

import { useQrReader } from "@mt-components/QrReader/useQRCodeReader.tsx";

export type QrReaderProps = {
    /**
     * Media track constraints object, to specify which camera and capabilities to use
     */
    constraints?: MediaTrackConstraints;
    /**
     * Called when an error occurs.
     */
    onResult: (qrCode: string) => void;

    /**
     * Property that represents the scan period
     */
    scanDelay?: number;
    /**
     * Property that represents the ID of the video element
     */
    videoId?: string;
    /**
     * Property that represents an optional className to modify styles
     */
};

export const QrReader: React.FC<QrReaderProps> = ({
    constraints = {
        facingMode: "user",
    },
    scanDelay = 1500,
    onResult,
    videoId = "video",
}) => {
    const reader = useQrReader({
        constraints,
        scanDelay,
        videoId,
    });

    return (
        <Box w="100%" h="400px">
            <Center
                width="100%"
                height="300px"
                overflow="hidden"
                position="relative"
            >
                {Boolean(reader.status !== "success") && (
                    <chakra.video
                        muted
                        bg="gray.100"
                        id={videoId}
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        display="block"
                        overflow="hidden"
                        transform={
                            constraints.facingMode === "user"
                                ? "scaleX(-1)"
                                : "none"
                        }
                    />
                )}

                {Boolean(reader.status === "success") && (
                    <Center flexDir="column" w="100%" h="100%" bg="gray.50">
                        <Box>{reader.result}</Box>
                        <Button
                            mt="12px"
                            onClick={() => {
                                if (reader.result) {
                                    return onResult(reader.result);
                                }
                            }}
                        >
                            Use QrCode
                        </Button>
                    </Center>
                )}
            </Center>
            <Center gap="12px" p="24px">
                {Boolean(reader.status === "initial") && (
                    <Button onClick={() => reader.start()}>Scan</Button>
                )}
                {Boolean(reader.status === "reading") && (
                    <Button onClick={() => reader.stop()}>Stop</Button>
                )}
            </Center>
        </Box>
    );
};

QrReader.displayName = "QrReader";
