// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, PropsWithChildren } from "react";

import PropTypes from "prop-types";

import { Box, Button, useDisclosure } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    addAction?: {
        label: string;
        handler: (() => void)[];
    };
    allowCollapse: boolean;
    toggleFolds: () => void;
    hasOpen: boolean;
};

export const PanelHeader: FC<PropsWithChildren<Props>> = (
    {
        children,
        toggleFolds,
        hasOpen,
        allowCollapse,

        addAction,
    },
    context,
) => {
    const { isOpen: addPanelOpen, onToggle: togglePanel } = useDisclosure({
        defaultIsOpen: false,
    });

    // dropdown is styled with same styles as react-select component - see _dropdown.scss
    return !children && !addAction && !allowCollapse ? null : (
        <Box mb="12px" display="flex" flexShrink={0}>
            {children && children.length ? (
                <Box flexGrow={1}>{children}</Box>
            ) : null}
            <Box display="flex" width="100%" justifyContent="space-between">
                {allowCollapse ? (
                    <Button
                        leftIcon={
                            hasOpen ? <Icon.Minimize /> : <Icon.Maximize />
                        }
                        variant="ghost"
                        onClick={toggleFolds}
                    >
                        {hasOpen ? "Collapse All" : "Expand All"}
                    </Button>
                ) : (
                    <Box />
                )}

                {addAction ? (
                    <Box alignSelf="flex-end">
                        <Button
                            colorScheme="blue"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            overflow="hidden"
                            variant="primary"
                            onClick={
                                Array.isArray(addAction.handler)
                                    ? togglePanel
                                    : () => addAction.handler(context)
                            }
                            leftIcon={<Icon.Plus size="16px" />}
                        >
                            {addAction.label}
                        </Button>
                        {addPanelOpen && (
                            <Box bg="red" className="Select">
                                <div className="Select-menu-outer">
                                    <div className="Select-menu">
                                        {addAction.handler.map(
                                            ({ label, handler }) => (
                                                <div
                                                    className="Select-option"
                                                    key={label}
                                                    onClick={() => {
                                                        handler(context);
                                                        togglePanel();
                                                    }}
                                                >
                                                    {label}
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </div>
                            </Box>
                        )}
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

PanelHeader.contextTypes = {
    layout: PropTypes.object,
    fullContainer: PropTypes.object,
    onUpdate: PropTypes.func,
    updateContainer: PropTypes.func,
};

PanelHeader.propTypes = {
    addAction: PropTypes.object,
    allowCollapse: PropTypes.bool,
    children: PropTypes.node,
    hasOpen: PropTypes.bool,
    toggleFolds: PropTypes.func,
};

export default PanelHeader;
