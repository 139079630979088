import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

export function MeasurementsGroupEmptyStateAgent() {
    return (
        <EmptyState
            icon={() => <Icon.MeasurementsImage />}
            heading="No Requests"
            body="There are no requests yet."
            buttonLabel=""
        />
    );
}
