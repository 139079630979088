import type { FC } from "react";
import { memo } from "react";
import { useLocalStorage } from "react-use";

import type Plotly from "plotly.js";
import { isNotNil } from "ramda";
import { v4 as uuid } from "uuid";

import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    Center,
    Divider,
    Heading,
    Stack,
    useDisclosure,
} from "@chakra-ui/react";
import { Link, useNavigate } from "@tanstack/react-router";

import { LocalStorageKeys } from "@app/config.ts";
import { routes } from "@app/Routes/routes.ts";
import { Datapicker } from "@app-components/widgets/Datapicker/Datapicker.tsx";

import type { Action } from "@mt-components/Layout/Actions.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";
import { EmptyState } from "@mt-components/states/Empty/EmptyState.tsx";

import { Icon } from "@mt-design/icons.tsx";

const PlotCard: FC<{ title: string; orgId: string; plotId: string }> = ({
    title,
    orgId,
    plotId,
}) => {
    return (
        <Card maxW="150px">
            <CardBody>
                <Stack mt="1" spacing="3">
                    <Heading size="md">{title}</Heading>
                </Stack>
            </CardBody>
            <Divider />
            <CardFooter>
                <ButtonGroup spacing="2">
                    <Button
                        size="xs"
                        as={Link}
                        params={{
                            orgId,
                            plotId,
                        }}
                        to={routes.agent.plotDetail.url}
                        variant="solid"
                        colorScheme="blue"
                    >
                        Open
                    </Button>
                    <Button size="xs" variant="ghost" colorScheme="red">
                        Delete
                    </Button>
                </ButtonGroup>
            </CardFooter>
        </Card>
    );
};

PlotCard.displayName = "PlotCard";

export type Plot = {
    id: string;
    name: string;
    artifacts: string[];
    orgId: string;
    plot: {
        data?: Plotly.PlotData[];
        layout?: Partial<Plotly.Layout>;
    };
};

export type PlotDB = Record<string, Plot | undefined>;
export const PlotsPage: FC = memo(() => {
    const navigate = useNavigate();
    const [plotDB, setPlotDB] = useLocalStorage<PlotDB>(
        LocalStorageKeys.plots,
        {},
    );
    const createPlotDisclosure = useDisclosure();
    const plots = plotDB ? Object.values(plotDB).filter(isNotNil) : [];

    const actions: Action[] = [
        {
            id: "create-plot",
            label: "Create",
            onClick: createPlotDisclosure.onToggle,
            isTruncated: true,
            hasTooltip: true,
            isPrimary: true,
        },
    ];

    const onClose = (params: { artifacts: string[]; orgId: string }) => {
        const plot: Plot = {
            id: uuid(),
            name: "new Plot",
            artifacts: params.artifacts,
            orgId: params.orgId,
            plot: {},
        };

        setPlotDB((prev) => {
            return {
                ...prev,
                [plot.id]: plot,
            };
        });
        createPlotDisclosure.onClose();
        void navigate({
            to: routes.agent.plotDetail.url,
            params: {
                orgId: params.orgId,
                plotId: plot.id,
            },
        });
    };

    return (
        <>
            {Boolean(createPlotDisclosure.isOpen) && (
                <Datapicker
                    isOpen={createPlotDisclosure.isOpen}
                    onClose={createPlotDisclosure.onClose}
                    onSelect={onClose}
                />
            )}
            <Box
                id="plots-page"
                mx="auto"
                display="flex"
                flexDirection="row"
                h="100%"
                overflow="hidden"
            >
                <PageLayout
                    display="flex"
                    flexDirection="column"
                    id="center-box"
                    overflowX="auto"
                    flex={1}
                    h="100%"
                    overflow="hidden"
                >
                    {plots.length === 0 ? (
                        <Center
                            height="100%"
                            width="100%"
                            overflowY="auto"
                            overflowX="auto"
                            py="8"
                        >
                            <EmptyState
                                icon={() => <Icon.AnalyzeImage />}
                                heading="No Plots"
                                body="You don't have any plots yet. Start by creating one."
                                onClick={createPlotDisclosure.onToggle}
                                buttonLabel="Create your first plot"
                            />
                        </Center>
                    ) : (
                        <Box
                            display="flex"
                            flexDir="column"
                            w="100%"
                            h="100%"
                            overflow="hidden"
                        >
                            <PageHeader
                                actionsId="samples-page-actions"
                                crumbs={[
                                    {
                                        id: "plots",
                                        label: "Plots",
                                    },
                                ]}
                                subtitle="Create plots to visualze your data"
                                actions={actions}
                            />
                            {plots.map((plot) => {
                                return (
                                    <PlotCard
                                        plotId={plot.id}
                                        orgId={plot.orgId}
                                        key={plot.id}
                                        title={plot.name}
                                    />
                                );
                            })}
                        </Box>
                    )}
                </PageLayout>
            </Box>
        </>
    );
});

PlotsPage.displayName = "PlotsPage";
