import type { FC, PropsWithChildren } from "react";

import { Box } from "@chakra-ui/react";

import { AppShell as SaasUIAppShell } from "@mt-components/AppShell";

import { SidebarAgent } from "./Sidebar.agent.tsx";

export const AppShellAgent: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SaasUIAppShell height="100%" bg="mt.50" sidebar={<SidebarAgent />}>
            <Box
                as="main"
                w="100%"
                h="calc(100% - 16px)"
                overflow="hidden"
                flex="1"
                borderStyle="solid"
                m="8px"
                mr="0"
                ml="0"
                borderWidth="1px"
                borderColor="gray.200"
                bg="white"
                borderLeftRadius="8px"
                borderBottomRightRadius="0"
                borderTopRightRadius="0"
            >
                {children}
            </Box>
        </SaasUIAppShell>
    );
};
