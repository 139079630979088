type Page<Item extends object> = {
    endCursor: string;
    startCursor: string;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    items?: Item[];
};

type PageQuery = {
    first?: number | undefined;
    after?: string | undefined;
};

type BaseParams = {
    path?: object;
    query?: PageQuery;
};

type Fetcher<Item extends object, Params extends BaseParams> = (
    params: Params,
) => Promise<{
    response: Response;
    data?: Page<Item>;
    error?: Error;
}>;

/*
 * This function collects all pages of data from a paginated API endpoint.
 * It takes a fetcher function and a params object as arguments.
 * The fetcher function should be a function that makes a request to the paginated API endpoint.

 *
 * @param func - The fetcher function that makes a request to the paginated API endpoint.
 * @param params - The params object containing the path and query parameters for the API request.
 * @returns A promise that resolves to an array of all items collected from all pages.
 * @throws An error if an error occurs during the collection process.
 */
export const collectAllPages = async <
    Item extends object = object,
    Params extends BaseParams = BaseParams,
    Func extends Fetcher<Item, Params> = Fetcher<Item, Params>,
>(
    func: Func,
    params: Params = {} as Params,
) => {
    try {
        const paginationState: {
            isComplete: boolean;
            nextCursor: string | undefined;
        } = {
            isComplete: false,
            nextCursor: undefined,
        };

        const items: Item[] = [];
        while (!paginationState.isComplete) {
            const response = await func({
                path: params.path,
                query: {
                    ...params.query,
                    first: params.query?.first || 1000,
                    after: paginationState.nextCursor,
                },
            } as Params);

            if (!response.response.ok) {
                throw new Error("Could not collect all items");
            }

            const page = response.data;

            const pageItems = page?.items;
            if (pageItems) {
                items.push(...pageItems);
            }

            paginationState.isComplete = !page?.hasNextPage;
            paginationState.nextCursor = page?.endCursor;
        }
        return items;
    } catch {
        throw new Error("Could not collect all items");
    }
};
