// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { BsFillFileEarmarkFill, BsThreeDotsVertical } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import {
    LuArrowDown,
    LuArrowLeft,
    LuArrowRight,
    LuArrowUp,
    LuChartLine,
    LuChartScatter,
    LuCheck,
    LuChevronDown,
    LuChevronLeft,
    LuChevronRight,
    LuChevronUp,
    LuCog,
    LuDownload,
    LuEye,
    LuEyeOff,
    LuFile,
    LuFlaskConical,
    LuHouse,
    LuInfo,
    LuMaximize2,
    LuMicroscope,
    LuMinimize2,
    LuPlus,
    LuQrCode,
    LuRefreshCcw,
    LuSearch,
    LuSquareCheck,
    LuTally2,
    LuTally4,
    LuTelescope,
    LuTrash,
    LuUser,
    LuUsers,
    LuX,
    LuZoomIn,
    LuZoomOut,
    LuCircleCheck,
    LuReceiptText,
    LuLink,
} from "react-icons/lu";
import { MdErrorOutline } from "react-icons/md";
import { TbSquareRoot } from "react-icons/tb";

const NUller = () => null;
export const Icon = {
    AnalyzeImage: NUller,
    ArrowUp: LuArrowUp,
    ArrowDown: LuArrowDown,
    ArrowLeft: LuArrowLeft,
    ArrowRight: LuArrowRight,
    Beamtime: LuTelescope,
    Charts: LuChartLine,
    Check: LuSquareCheck,
    Check2: LuCheck,
    ChevronDown: LuChevronDown,
    ChevronLeft: LuChevronLeft,
    ChevronRight: LuChevronRight,
    ChevronUp: LuChevronUp,
    Cog: LuCog,
    CloudDone: LuCircleCheck,
    Close: LuX,
    Cross: ImCross,
    Delete: LuTrash,
    Error: MdErrorOutline,
    Form: LuReceiptText,
    File: LuFile,
    File2: BsFillFileEarmarkFill,
    FourColumn: LuTally4,
    Hidden: LuEyeOff,
    Home: LuHouse,
    Info: LuInfo,
    LineChart: LuChartScatter,
    Link: LuLink,
    MeasurementsImage: NUller,
    Maximize: LuMaximize2,
    Minimize: LuMinimize2,
    Menu: BsThreeDotsVertical,
    Organization: LuUsers,
    // Organization: LuUsers,
    Plus: LuPlus,
    Profile: LuUser,
    QRCode: LuQrCode,
    Request: LuMicroscope,
    Sample: LuFlaskConical,
    SamplesImage: NUller,
    Search: LuSearch,
    Shown: LuEye,
    TwoColumn: LuTally2,
    graph: {
        Square: TbSquareRoot,
        Download: LuDownload,
        ZoomIn: LuZoomIn,
        ZoomOut: LuZoomOut,
        Reload: LuRefreshCcw,
    },
};
