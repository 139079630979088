import type { ComponentType } from "react";
import { useState } from "react";

import type { Promisable } from "type-fest";

import { Box } from "@chakra-ui/react";

import { Organization } from "@app/domain/api/organization.ts";
import { OrganizationFilter } from "@app-components/organization/OrganizationFilter.tsx";

import { LoadingButton } from "@mt-components/Button/LoadingButton.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

export const SelectOrgModal = (props: {
    onSubmit: (orgId: string) => void;
    onClose: () => void;
}) => {
    const organizationsQuery = Organization.useAdminGetAll();
    const [selectedOrg, setSelectedOrg] = useState<string | undefined>();

    return (
        <Modal
            id="Select organization"
            showProgress
            isOpen
            motionPreset="none"
            onClose={props.onClose}
            onOverlayClick={props.onClose}
            header="Select organization"
            buttonRight={
                <LoadingButton
                    awaitPromise
                    onClick={() => {
                        if (!selectedOrg) {
                            return;
                        }
                        props.onSubmit(selectedOrg);
                    }}
                    isDisabled={!selectedOrg}
                    colorScheme="blue"
                >
                    Select
                </LoadingButton>
            }
        >
            <Box display="flex" flexDirection="column" gap="14px" minH="320px">
                <OrganizationFilter
                    options={organizationsQuery.data ?? []}
                    onChange={setSelectedOrg}
                    value={selectedOrg}
                    placeholder="Select an organization"
                />
            </Box>
        </Modal>
    );
};

type ChildProps = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: (...args: any[]) => Promisable<void>;
    onClose: () => Promisable<void>;
};

type Props<C extends ChildProps> = {
    Component: ComponentType<C>;
};

/*
 This component wraps an arbitrary component whose props are is compatible with the specified requirement.

 The purpose is to extend an userflow with an organization selection step.
 e.g. creating an entity for an organization.
 - Choose organization
 - fill entity creation form
 - execute the creation with entity data and orgId

 It proxies the component's props, and extends it with an additional orgId attribute.

 */
export const AdminOrgContainer = <T extends ChildProps>({
    Component,
    onSubmit,
    onClose,
    ...props
}: Props<T> &
    Omit<T, "onSubmit"> & {
        onSubmit: (params: {
            originalArgs: Parameters<T["onSubmit"]>;
            orgId: string;
        }) => Promisable<void>;
    }) => {
    const childProps = props as unknown as T;

    const [selectedOrg, setSelectedOrg] = useState<string | undefined>();
    const submit = async (...args: Parameters<T["onSubmit"]>) => {
        if (!selectedOrg) {
            return;
        }
        await onSubmit({
            originalArgs: args,
            orgId: selectedOrg,
        });
    };

    return !selectedOrg ? (
        <SelectOrgModal onSubmit={setSelectedOrg} onClose={onClose} />
    ) : (
        <Component {...childProps} onSubmit={submit} onClose={onClose} />
    );
};
