import type { FC, ReactNode } from "react";

import { Box, Collapse, IconButton, useDisclosure } from "@chakra-ui/react";

import { Divider } from "@mt-components/Layout/Divider.tsx";

import { Icon } from "@mt-design/icons.tsx";

type AccordionProps = {
    hasBottomBorder?: boolean;
    title: ReactNode;
    body: ReactNode;
};

export const Accordion: FC<AccordionProps> = ({
    title,
    hasBottomBorder,
    body,
}) => {
    const disclosure = useDisclosure();
    return (
        <Box>
            <Box display="flex" justifyContent="space-between">
                {title}
                <IconButton
                    variant="ghost"
                    icon={
                        disclosure.isOpen ? (
                            <Icon.ChevronUp />
                        ) : (
                            <Icon.ChevronDown />
                        )
                    }
                    aria-label=""
                    onClick={disclosure.onToggle}
                />
            </Box>
            <Collapse in={disclosure.isOpen}>
                <Box display="flex" flexDir="row" flexWrap="wrap" width="100%">
                    {body}
                </Box>
                {hasBottomBorder && <Divider />}
            </Collapse>
        </Box>
    );
};
