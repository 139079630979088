import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import {
    use2SDownloadQuery,
    use2SDownloadSnackbar,
} from "src/App/domain/2s-download";
import { downloadFileNative } from "src/packages/tools/io/downloadFile";

export const useReportTemplateDownloader = (
    organizationId: string,
    requestId: string,
) => {
    const snackbar = use2SDownloadSnackbar();

    const api = useAPI();

    const download1 = use2SDownloadQuery({
        fn: async () => {
            return await fetch(
                `${api.basePath}/admin/organizations/${organizationId}/measurement-groups/${requestId}/report-template`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${api.token}`,
                    },
                },
            );
        },
        onError() {
            snackbar.errorCollect();
        },
        onCollectProgress(data) {
            const progress = (data.totalProcessed / data.total) * 100;

            if (Math.round(progress) === 100) {
                snackbar.progressZip(0);
                return;
            }

            snackbar.progressCollect(progress);
        },
        onZipProgress(data) {
            const progress = (data.loaded / data.total) * 100;
            snackbar.progressZip(progress);
        },
        onDownloadUrl(url) {
            snackbar.progressDownloadUrl();

            downloadFileNative(url);
        },
    });

    return () => {
        snackbar.open();
        download1.run();
    };
};
