import { useEffect, useRef } from "react";
import { useAuth } from "react-oidc-context";

export default function Auth() {
    const auth = useAuth();
    const ref = useRef(auth);

    useEffect(() => {
        ref.current = auth;
    }, [auth]);

    useEffect(() => {
        void ref.current.signinRedirect();
    }, []);
}
