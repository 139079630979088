import { type FC, memo } from "react";

import {
    Avatar,
    Box,
    Divider,
    HStack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import type { User } from "@app/contexts/UserContext.tsx";

import type { Action } from "@mt-components/Layout/Actions.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { UploadAvatarModal } from "src/App/components/modals/UploadAvatarModal";

type Props = {
    user: User;
    onLogout: () => void;
    onRecover: () => void;
    onEdit: () => void;
};

const left = {
    w: "250px",
    textStyle: "T2",
    fontWeight: "600",
};

const right = {
    w: "auto",
    textStyle: "T2",
};

const row = {
    flex: "0 0 82px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    textStyle: "T2",
};

export const Account: FC<Props> = memo(
    ({ onRecover, onLogout, onEdit, user }) => {
        const uploadAvatarDisclosure = useDisclosure();

        const actions: Action[] = [
            {
                id: "logout",
                label: "Logout",
                loadingLabel: "logout in progress",
                isPrimary: true,
                onClick: onLogout,
            },
            {
                id: "recover",
                label: "Reset password",
                onClick: onRecover,
            },
            {
                id: "edit",
                label: "Edit",
                onClick: onEdit,
            },
        ];

        return (
            <PageLayout
                display="flex"
                flexDirection="column"
                id="center-box"
                overflowX="auto"
                flex={1}
                h="100%"
                overflow="hidden"
                maxW="1024px"
            >
                <Box
                    display="flex"
                    flexDir="column"
                    w="100%"
                    h="100%"
                    overflow="hidden"
                    gap="24px"
                >
                    <PageHeader
                        actionsId="dashboard-page-header"
                        title={user.name || user.email}
                        subtitle="Manage your details and personal preferences here"
                        actions={actions}
                    />
                    <Text textStyle="h5" fontWeight="600">
                        General
                    </Text>
                    <Box display="flex" flexDirection="column">
                        <Box {...row} display="flex">
                            <Box {...left}>Photo</Box>
                            <HStack {...right} py="12px">
                                <Box
                                    borderRadius="100%"
                                    as="button"
                                    onClick={uploadAvatarDisclosure.onOpen}
                                    p="2px"
                                >
                                    <Avatar size="xl" src={user.avatarUrl} />
                                </Box>
                            </HStack>
                        </Box>
                        <Divider my={0} />
                        <Box {...row} display="flex">
                            <Box {...left}>Name</Box>
                            <Box {...right}>{user.name}</Box>
                        </Box>
                        <Divider my={0} />
                        <Box {...row} display="flex">
                            <Box {...left}>Email</Box>
                            <Box {...right}>{user.email}</Box>
                        </Box>
                    </Box>
                    {uploadAvatarDisclosure.isOpen && (
                        <UploadAvatarModal
                            isOpen={uploadAvatarDisclosure.isOpen}
                            onClose={uploadAvatarDisclosure.onClose}
                            userId={user.id}
                        />
                    )}
                </Box>
            </PageLayout>
        );
    },
);

Account.displayName = "Account";
