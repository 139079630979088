import { redGray } from "@mt-components/Display/YesNoIndicator/schemes.ts/ColorIndicator.tsx";

import { YesNoIndicator } from "src/packages/components/Display/YesNoIndicator";

export const renderValue = (value: unknown) => {
    if (typeof value === "boolean") {
        return (
            <YesNoIndicator<"true" | "false">
                config={redGray}
                value={value ? "true" : "false"}
            />
        );
    }

    if (typeof value === "string") {
        return value;
    }

    if (typeof value === "number") {
        return value.toString();
    }

    return "";
};
