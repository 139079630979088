import type { FC } from "react";

import { Measurement } from "@app/domain/api/measurement.ts";

import { StaticField } from "@mt-components/Input/StaticField";

export const CrystalliteSizePrediction: FC<{
    organizationId: string;
    xrdMeasurementId: string | undefined;
    isLoading: boolean;
}> = ({ isLoading, organizationId, xrdMeasurementId }) => {
    const title = "Crystallite size estimation";
    const info =
        "AI estimated crystallite size based on the XRD diffraction pattern acquired from the measurement";

    const query = Measurement.useCyrstalliteSizePrediction(
        xrdMeasurementId && xrdMeasurementId
            ? {
                  measurementId: xrdMeasurementId,
                  orgId: organizationId,
              }
            : null,
    );
    if (!isLoading && !xrdMeasurementId) {
        return (
            <StaticField
                title={title}
                info={info}
                infoPlacement="right"
                value="It seems like there aren't any suitable measurements for estimation in the request. Only XRD experiments are supported for crystallite size estimation."
            />
        );
    }

    if (isLoading || !query.data?.data) {
        return (
            <StaticField
                isLoading={isLoading || query.isLoading}
                title={title}
                value="-"
                info={info}
                infoPlacement="right"
            />
        );
    }

    const csInNm = query.data.data.cs * 1e9;
    const precissionInNm = query.data.data.precision * 1e9;

    const valueClass = (() => {
        if (csInNm <= 5) return "Ultrasmall nanoparticle (<5 nm)";
        if (csInNm <= 100) return "Nanoparticle (5 nm - 100 nm)";
        return "Microcrystal (>100 nm)";
    })();

    return (
        <StaticField
            isLoading={query.isLoading}
            title={title}
            value={valueClass}
            tooltip={`${csInNm.toFixed(1)} nm (±${precissionInNm.toFixed(1)})`}
            tooltipPlacement="right"
            info={info}
            infoPlacement="right"
        />
    );
};

CrystalliteSizePrediction.displayName = "CrystalliteSizePredictionPanel";
