import type { FC } from "react";
import { useState } from "react";

import { Box, Center, Text } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import type {
    BeamtimeModel,
    BeamtimeStatusType,
} from "@app/domain/api/agent/beamtime.ts";
import { routes } from "@app/Routes/routes.ts";
import { BeamtimeStatusFilter } from "@app-components/beamtime/BeamtimeStatusFilter.tsx";

import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";

import { useSearch } from "src/packages/hooks/useSearch.tsx";

import { BeamtimeTable } from "./Beamtime.table.tsx";

type Props = {
    onToggleForm: () => void;
    beamtimes: BeamtimeModel[];
};

export const BeamtimeC: FC<Props> = ({ onToggleForm, beamtimes }) => {
    const navigate = useNavigate();
    const [statusFilter, setStatusFilter] = useState<
        BeamtimeStatusType | undefined
    >(undefined);

    const { searchTerm, filteredOrders, onClear, onChange } =
        useSearch<BeamtimeModel>({
            items: beamtimes,
            keys: ["name", "status"],
        });

    const filteredBeamtimes = statusFilter
        ? filteredOrders.filter(({ status }) => status === statusFilter)
        : filteredOrders;

    const goToBeamtime = (beamtime: BeamtimeModel) => {
        void navigate({
            to: routes.agent.beamtimesDetail.url,
            params: {
                beamtimeId: beamtime.id,
            },
        });
    };

    return (
        <Box
            display="flex"
            flexDir="column"
            width="100%"
            height="100%"
            overflow="hidden"
        >
            <PageHeader
                crumbs={[
                    {
                        id: "beamtimes",
                        label: "Beamtimes",
                    },
                ]}
                actions={[
                    {
                        id: "create",
                        label: "Create Beamtime",
                        isPrimary: true,
                        onClick: onToggleForm,
                    },
                ]}
            />
            <SearchFilterBar
                value={searchTerm}
                onReset={onClear}
                onChange={onChange}
                placeholder="Search by title or status"
                filter={
                    <BeamtimeStatusFilter
                        value={statusFilter}
                        onChange={setStatusFilter}
                    />
                }
            />
            {filteredBeamtimes.length ? (
                <BeamtimeTable
                    onRowClick={goToBeamtime}
                    items={filteredBeamtimes}
                />
            ) : (
                <Center w="100%" h="100%">
                    <Text>No matches for your search term</Text>
                </Center>
            )}
        </Box>
    );
};

BeamtimeC.displayName = "Beamtime";
