import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";

import { head } from "ramda";

import {
    Center,
    HStack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
} from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { SampleSelect } from "@app/pages/user/requestDetails/SampleSelect.tsx";
import { PDFTaskContainer } from "@app-components/measurementTask/PDFTask.container.tsx";
import { TaskContainer } from "@app-components/measurementTask/Task.container.tsx";

type ResultsProps = {
    measurementGroup: MeasurementGroupModel;
    sampleTasks: SampleTasksModel[];
    selectedSampleTask?: SampleTasksModel;
    onSelectSample: (sampleId: string) => void;
    sampleSelection: string[];
    filteredSamplesTasks: SampleTasksModel[];
};

const getInitialTab = (
    xrdTaskId?: string,
    pdfTaskId?: string,
    saxsTaskId?: string,
) => {
    if (xrdTaskId) {
        return 0;
    }
    if (pdfTaskId) {
        return 1;
    }
    if (saxsTaskId) {
        return 2;
    }

    return 0;
};

export const Results: FC<ResultsProps> = ({
    sampleSelection,
    measurementGroup,
    sampleTasks,
    filteredSamplesTasks,
    onSelectSample,
}) => {
    const selectedSampleTask = useMemo(
        () => sampleTasks.find((st) => st.id === head(sampleSelection)),
        [sampleSelection, sampleTasks],
    );

    const xrdTaskId = selectedSampleTask?.tasks.hrxrpd?.taskId;
    const pdfTaskId = selectedSampleTask?.tasks.tspdf?.taskId;
    const saxsTaskId = selectedSampleTask?.tasks.saxs?.taskId;

    const [activeTab, setActiveTab] = useState(
        getInitialTab(xrdTaskId, pdfTaskId, saxsTaskId),
    );

    useEffect(() => {
        setActiveTab(getInitialTab(xrdTaskId, pdfTaskId, saxsTaskId));
    }, [xrdTaskId, pdfTaskId, saxsTaskId]);

    return (
        <HStack
            minH="880px"
            alignItems="flex-start"
            flex="0 0 250px"
            overflowY="auto"
        >
            <VStack
                minW="250px"
                h="100%"
                alignItems="flex-start"
                overflowY="hidden"
                minH="300px"
                p="6px"
                display="flex"
                flexDir="column"
            >
                <SampleSelect
                    orgId={measurementGroup.organizationId}
                    measurementGroupId={measurementGroup.id}
                    samples={filteredSamplesTasks}
                    selectedSampleId={selectedSampleTask?.id}
                    onSelectSample={onSelectSample}
                />
            </VStack>
            <Tabs
                variant="enclosed"
                isLazy
                pt="4px"
                px="4px"
                isFitted
                h="100%"
                fontSize="md"
                index={activeTab}
                onChange={setActiveTab}
                w="100%"
            >
                <TabList>
                    <Tab isDisabled={!xrdTaskId}>XRD </Tab>
                    <Tab isDisabled={!pdfTaskId}>TS-PDF</Tab>
                    <Tab isDisabled={!saxsTaskId}>SAXS</Tab>
                </TabList>
                <TabPanels h="calc(100% - 40px)">
                    <TabPanel h="100%">
                        {xrdTaskId ? (
                            <TaskContainer
                                taskId={xrdTaskId}
                                orgId={measurementGroup.organizationId}
                            />
                        ) : (
                            <Center flex={1} h="100%" w="100%">
                                ´ This method has not been measured.
                            </Center>
                        )}
                    </TabPanel>
                    <TabPanel h="100%" w="100%">
                        {pdfTaskId && (
                            <PDFTaskContainer
                                taskId={pdfTaskId}
                                orgId={measurementGroup.organizationId}
                            />
                        )}
                    </TabPanel>
                    <TabPanel h="100%">
                        {saxsTaskId && (
                            <TaskContainer
                                taskId={saxsTaskId}
                                orgId={measurementGroup.organizationId}
                            />
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </HStack>
    );
};
