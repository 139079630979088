import { forwardRef, useImperativeHandle, useState } from "react";

import {
    Box,
    Flex,
    IconButton,
    Image,
    Progress,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { useAPI } from "src/App/contexts/APIContext/useApiContext";
import { Icon } from "src/packages/solid-design/icons";

// import { faker } from "@faker-js/faker";

// Mock API call for parsing images
// const mockParseImage = async (file: File) => {
//     return await new Promise<{ data?: ParsedSampleStickWellMapping }>(
//         (resolve) => {
//             setTimeout(
//                 () => {
//                     resolve({
//                         data: {
//                             pairsImage: `data:image/png;base64,${btoa(file.name)}`, // Mock base64 image
//                             decodedPairs: (() => {
//                                 const pairs = [];
//                                 for (
//                                     let i = faker.number.bigInt({
//                                         min: 0,
//                                         max: 16,
//                                     });
//                                     i >= 0;
//                                     i--
//                                 ) {
//                                     pairs.push({
//                                         sample: {
//                                             image: `data:image/png;base64,${btoa(file.name + "-sample")}`,
//                                             data: faker.string.uuid(),
//                                         },
//                                         stickWell: {
//                                             image: `data:image/png;base64,${btoa(file.name + "-stickwell")}`,
//                                             data: faker.string.uuid(),
//                                         },
//                                     });
//                                 }
//                                 return pairs;
//                             })(),
//                         },
//                     });
//                 },
//                 faker.number.int({ min: 500, max: 2000 }),
//             ); // Simulate network delay
//         },
//     );
// };

type ParsedSampleStickWellMappingItem = {
    image: string;
    data?: string;
};

type ParsedSampleStickWellMapping = {
    pairsImage?: string;
    decodedPairs: {
        sample: ParsedSampleStickWellMappingItem;
        stickWell: ParsedSampleStickWellMappingItem;
    }[];
};

export type ParseStatus = "new" | "parsing" | "error" | "success";

export type BeamtimeSSWFromImageParseItemProps = {
    image: File;

    onRemove: () => void;
    onParseSuccess: (data: ParsedSampleStickWellMapping) => void;
    onParseError: (error: unknown) => void;
};

export type BeamtimeSSWFromImageParseItemRef = {
    parseAsync: () => Promise<void>;
};

export const BeamtimeSSWFromImageParseItem = forwardRef<
    BeamtimeSSWFromImageParseItemRef,
    BeamtimeSSWFromImageParseItemProps
>((props, ref) => {
    const [status, setStatus] = useState<ParseStatus>("new");

    const api = useAPI();

    const parseImageMutation = useMutation({
        mutationFn: async () => {
            const formData = new FormData();
            formData.append("file", props.image);

            const res = await api.agentClient.POST(
                "/sample-stick-wells/rpc/parseSampleStickWellsFromImage",
                {
                    bodySerializer: (x) => x,
                    // @ts-expect-error wrong typing from generator
                    body: formData,
                },
            );

            // const res = await mockParseImage(props.image);

            return res;
        },
        onMutate() {
            setStatus("parsing");
        },
        onSuccess(data) {
            if (!data.data) {
                props.onParseError("Emoty data");
                setStatus("error");
                return;
            }

            props.onParseSuccess(data.data);
            setStatus("success");
        },
        onError(error) {
            setStatus("error");
            props.onParseError(error);
        },
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                async parseAsync() {
                    await parseImageMutation.mutateAsync();
                },
            } satisfies BeamtimeSSWFromImageParseItemRef;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [parseImageMutation.mutate],
    );

    return (
        <Box borderWidth="1px" borderRadius="md" mb="4" bg="gray.50">
            {/* Header with filename, status, and remove button */}
            <Box bg="gray.100" borderRadius="md">
                <Flex justifyContent="space-between" alignItems="center" p="2">
                    <Flex alignItems="center">
                        <Text fontWeight="bold">{props.image.name}</Text>
                        <Text
                            color={
                                status === "success"
                                    ? "green.500"
                                    : status === "error"
                                      ? "red.500"
                                      : "gray.500"
                            }
                            fontWeight="bold"
                            width="80px"
                            pl="4"
                        >
                            {status}
                        </Text>
                    </Flex>
                    <IconButton
                        aria-label="Remove image"
                        icon={<Icon.Delete />}
                        variant="ghost"
                        size="sm"
                        onClick={() => props.onRemove()}
                    />
                </Flex>
                {status === "parsing" && (
                    <Progress size="xs" colorScheme="blue" isIndeterminate />
                )}
            </Box>

            {/* Image and Parsed Data Section */}
            <Flex p="4">
                {/* Original Image */}
                <Box flex="1">
                    <Box mb="4">
                        <Text mb="1">Original</Text>
                        <Image
                            src={URL.createObjectURL(props.image)}
                            alt="Original Image"
                            maxW="400px"
                            borderRadius="md"
                            objectFit="contain"
                        />
                    </Box>
                    <Box>
                        <Text mb="1">Parsed Image</Text>
                        {parseImageMutation.data?.data?.pairsImage ? (
                            <Image
                                src={parseImageMutation.data.data.pairsImage}
                                alt="Parsed Image"
                                borderRadius="md"
                                maxW="400px"
                                objectFit="contain"
                            />
                        ) : (
                            <Text color="gray.500">
                                No parsed image available
                            </Text>
                        )}
                    </Box>
                </Box>

                {/* Parsed Image */}
                {/* <Box flex="1" mr="4">
                    
                </Box> */}

                {/* Parsed Data Table */}
                <Box flex="3">
                    <Text mb="1">Parse Details</Text>
                    {parseImageMutation.data?.data ? (
                        <Table size="sm" variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Sample Code Image</Th>
                                    <Th>Sample Value</Th>
                                    <Th>Stick Well Code Image</Th>
                                    <Th>Stick Well Value</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {parseImageMutation.data.data.decodedPairs.map(
                                    (pair, i) => (
                                        <Tr key={i}>
                                            <Td>
                                                <Image
                                                    src={pair.sample.image}
                                                    alt="Sample Code Image"
                                                    maxH="100px"
                                                />
                                            </Td>
                                            <Td>{pair.sample.data}</Td>
                                            <Td>
                                                <Image
                                                    src={pair.stickWell.image}
                                                    alt="Stick Well Code Image"
                                                    maxH="100px"
                                                />
                                            </Td>
                                            <Td>{pair.stickWell.data}</Td>
                                        </Tr>
                                    ),
                                )}
                            </Tbody>
                        </Table>
                    ) : (
                        <Text color="gray.500">No parsed data available</Text>
                    )}
                </Box>
            </Flex>
        </Box>
    );
});

BeamtimeSSWFromImageParseItem.displayName = "BeamtimeSetSSWParseItem";
