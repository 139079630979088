import type { FC } from "react";

import { Icon } from "@mt-design/icons.tsx";

type ToggleIconProps = {
    showLeft: boolean;
};

export const ToggleIcon: FC<ToggleIconProps> = ({ showLeft }) => {
    return showLeft ? <Icon.ArrowRight /> : <Icon.ArrowLeft />;
};
