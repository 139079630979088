import type { FC } from "react";
import { memo } from "react";

import { Box, useDisclosure } from "@chakra-ui/react";

import type { CreateBeamtimePayload } from "@app/domain/api/agent/beamtime.ts";
import { Beamtime } from "@app/domain/api/agent/beamtime.ts";
import { BeamtimeC } from "@app/pages/agent/beamtimes/Beamtime.tsx";
import { BeamtimeEmptyState } from "@app-components/beamtime/BeamtimeEmptyState.tsx";
import { BeamtimeModal } from "@app-components/modals/beamtime/Beamtime.modal.tsx";

import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

export const BeamtimesContainer: FC = () => {
    const { mutateAsync: submit } = Beamtime.useAdminCreate();
    const { isOpen, onToggle, onClose } = useDisclosure();

    const { data: beamtimesQuery, isLoading } = Beamtime.useAdminGetAll();

    const onSubmit = async (data: CreateBeamtimePayload) => {
        await submit(data);
    };

    const submittt = async (data: CreateBeamtimePayload) => {
        await onSubmit(data);
        onClose();
    };

    if (!beamtimesQuery && isLoading) {
        return (
            <>
                {isOpen && (
                    <BeamtimeModal
                        isOpen={isOpen}
                        onCreate={submittt}
                        onCancel={onToggle}
                    />
                )}
            </>
        );
    }

    if (!beamtimesQuery?.data?.length) {
        return (
            <>
                {isOpen && (
                    <BeamtimeModal
                        isOpen={isOpen}
                        onCreate={submittt}
                        onCancel={onToggle}
                    />
                )}
                <Box
                    w="100%"
                    h="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                >
                    <BeamtimeEmptyState onClick={onToggle} />
                </Box>
            </>
        );
    }

    return (
        <>
            {isOpen && (
                <BeamtimeModal
                    isOpen={isOpen}
                    onCreate={submittt}
                    onCancel={onToggle}
                />
            )}
            <BeamtimeC
                beamtimes={beamtimesQuery.data}
                onToggleForm={onToggle}
            />
        </>
    );
};

BeamtimesContainer.displayName = "BeamtimesContainer";

export const BeamtimesPage = memo(() => {
    return (
        <PageLayout>
            <Box
                overflow="hidden"
                mx="auto"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <BeamtimesContainer />
            </Box>
        </PageLayout>
    );
});

BeamtimesPage.displayName = "BeamtimesPage";
