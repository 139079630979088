import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";

// define styles for custom variant
const colorfulVariant = defineStyle(() => {
    return {
        bg: "gray.900",
        color: "white",
        borderRadius: "8px",
    };
});

// define custom variants
const variants = {
    dark: colorfulVariant,
};

// export the component theme
export const tooltipStyles = defineStyleConfig({
    variants,
    defaultProps: { variant: "dark" },
});

export const tooltipheme = extendTheme({
    components: {
        Tooltip: tooltipStyles,
    },
});
