import React from "react";

import PropTypes from "prop-types";

import Dropdown from "./Dropdown";

const FontSelector = (props, context) => (
    <Dropdown
        {...props}
        options={context.fontOptions.map(({ value, label }) => ({
            label: <span style={{ fontFamily: value }}>{label}</span>,
            value,
        }))}
    />
);

FontSelector.propTypes = {
    ...Dropdown.propTypes,
};

FontSelector.defaultProps = { clearable: false };

FontSelector.contextTypes = {
    fontOptions: PropTypes.array,
};

export default FontSelector;
