import { flatten, partial } from "ramda";

import { useQuery } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { applyPageSizeDefault } from "@app/domain/api/tools/applyPageSizeDefault.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

import { createCrystalliteSizePredictionKey, QueryKeys } from "./queryKeys.ts";

const entityKey = QueryKeys.measurement;

export type MeasurementModel = components["schemas"]["Measurement"];

type GetAllParams = operations["getMeasurements"]["parameters"];

const getMeasurements = (api: APIContextValue, params: GetAllParams) =>
    api.client.GET("/organizations/{organizationId}/measurements", {
        params: applyPageSizeDefault(params),
    });

const useGetAll = (params?: GetAllParams) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            if (!params) {
                return null;
            }

            const fetcher = partial(getMeasurements, [api]);

            try {
                const data = await collectAllPages<
                    MeasurementModel,
                    GetAllParams,
                    typeof fetcher
                >(fetcher, params);

                return data;
            } catch {
                throw new Error("Could not fetch measurements");
            }
        },
    });
};

const useGet = (params: {
    organizationId: string;
    taskId: string | undefined;
}) => {
    const api = useAPI();
    return useQuery({
        queryKey: [
            "organization",
            params.organizationId,
            "task",
            params.taskId,
        ],
        queryFn: async () => {
            return await api.client
                .GET("/organizations/{organizationId}/measurements", {
                    params: {
                        path: { organizationId: params.organizationId },
                        query: {
                            filter: { measurementTaskId: params.taskId },
                        },
                    },
                })
                .then((x) => x.data?.items[0] ?? null);
        },
        enabled: !!params.taskId,
    });
};

const useGetAllForTasks = (
    params?: GetAllParams & { isDisabled?: boolean } & {
        measurementTaskIds: string[];
        sampleId: string;
    },
) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(params)],
        queryFn: async () => {
            if (!params || !params.path.organizationId || params.isDisabled) {
                return null;
            }

            const fetcher = partial(getMeasurements, [api]);

            try {
                const promisses = params.measurementTaskIds.map((taskId) => {
                    return collectAllPages<
                        MeasurementModel,
                        GetAllParams,
                        typeof fetcher
                    >(fetcher, {
                        path: {
                            organizationId: params.path.organizationId,
                        },
                        query: {
                            filter: {
                                sampleId: params.sampleId,
                                measurementTaskId: taskId,
                            },
                        },
                    });
                });
                const responses = await Promise.all(promisses);
                const measurements = flatten(responses);
                return measurements;
            } catch {
                throw new Error("Could not fetch measurements for task ids");
            }
        },
    });
};

const useCyrstalliteSizePrediction = (
    params: {
        orgId: string;
        measurementId: string;
    } | null,
) => {
    const api = useAPI();
    return useQuery({
        queryKey: createCrystalliteSizePredictionKey(
            params?.orgId,
            params?.measurementId,
        ),

        queryFn: () => {
            if (!params?.orgId || !params.measurementId) {
                return null;
            }

            return api.client.POST("/rpc/predictCrystalliteSize", {
                body: {
                    organizationId: params.orgId,
                    measurementId: params.measurementId,
                },
            });
        },
    });
};

export const Measurement = {
    useGetAll,
    useGet,
    useGetAllForTasks,
    useCyrstalliteSizePrediction,
};
