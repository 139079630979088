// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, PropsWithChildren } from "react";
import React from "react";

import { Box, Center, Text } from "@chakra-ui/react";

import { Icon as AppIcon } from "@mt-design/icons.tsx";

import { textStyles } from "@mt-design/fontStyles.ts";

type Props = {
    heading: string;
    icon?: React.ComponentType;
};
export const PanelMessage: FC<PropsWithChildren<Props>> = (props) => {
    const { children, icon: Icon = AppIcon.LineChart } = props;
    const heading = props.heading || "";

    return (
        <Center flexDirection="column" justifyContent="center">
            {Boolean(Icon) && (
                <Box pt="48px" mb="24px" color="gray.500">
                    <Icon color="currentColor" size="48px" />
                </Box>
            )}
            {Boolean(heading) && (
                <div>
                    <Text textStyle="h4" {...textStyles.h4}>
                        {heading}
                    </Text>
                </div>
            )}
            <Box>
                <Box color="gray.600" textStyle="T1">
                    {children}
                </Box>
            </Box>
        </Center>
    );
};
PanelMessage.displayName = "PanelMessage";

const PanelEmpty: FC<PropsWithChildren<Props>> = (props) => (
    <Box width="310px" height="100%" padding="24px">
        <PanelMessage {...props} />
    </Box>
);

PanelEmpty.displayName = "PanelEmpty";

export default PanelEmpty;
