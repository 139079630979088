import type { ChangeEvent, FC } from "react";
import { useState } from "react";

import pMap from "p-map";
import { toPairs } from "ramda";

import {
    Box,
    Button,
    Center,
    Checkbox,
    FormLabel,
    Text,
    Tooltip,
} from "@chakra-ui/react";

import type { MeasurementMethod as MeasurementMethodType } from "@app/domain";
import { MeasurementMethod } from "@app/domain";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import { SelectField } from "@mt-components/Input/SelectField.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { filterMap } from "@mt-tools/iterating/filter.ts";

import { Icon } from "@mt-design/icons.tsx";

import { messages } from "@mt-assets/messages.ts";

type Props = {
    sourceRequestId: string;
    isOpen: boolean;
    orgId: string;
    sampleTasksToMove: SampleTasksModel[];
    onClose: () => void;
};

type TaskId = string;
type State = { [k in TaskId]: boolean };

const filterTasksByMethodAndMapToID =
    (method: MeasurementMethodType) =>
    (s: SampleTasksModel): [string, boolean] => {
        const taskId = s.tasks[method]?.taskId;
        if (taskId) {
            return [taskId, true];
        }

        return ["", false];
    };

export const SelectTasksFromSampleTasks: FC<Props> = ({
    sourceRequestId,
    isOpen,
    orgId,
    onClose,
    sampleTasksToMove = [],
}) => {
    const snack = useSnackbar();
    const [value, setValue] = useState<string | undefined>(undefined);
    const tasksForMigration: State = sampleTasksToMove.reduce(
        (accumulator, currentValue): State => {
            const current: Record<string, boolean | undefined> = {};

            if (currentValue.tasks.hrxrpd?.taskId) {
                current[currentValue.tasks.hrxrpd.taskId] = true;
            }

            if (currentValue.tasks.saxs?.taskId) {
                current[currentValue.tasks.saxs.taskId] = true;
            }

            if (currentValue.tasks.tspdf?.taskId) {
                current[currentValue.tasks.tspdf.taskId] = true;
            }

            return {
                ...accumulator,
                ...current,
            };
        },
        {},
    );

    const [formState, setFormState] = useState<State>(tasksForMigration);
    const patchAdminTask = MeasurementTask.agent.useAdminPatch();
    const requestQuery = Request.useAdminGetAll({
        query: {
            filter: {
                organizationId: orgId,
            },
        },
    });

    const allHRXRPDTasks = filterMap(
        filterTasksByMethodAndMapToID(MeasurementMethod.Hrxrpd),
        sampleTasksToMove,
    );

    const allPDFTasks = filterMap(
        filterTasksByMethodAndMapToID(MeasurementMethod.Tspdf),
        sampleTasksToMove,
    );

    const allSAXSTasks = filterMap(
        filterTasksByMethodAndMapToID(MeasurementMethod.Saxs),
        sampleTasksToMove,
    );

    const close = () => {
        setFormState(tasksForMigration);
        onClose();
    };

    const submit = async () => {
        const tasksToMove = toPairs(formState)
            .filter((entry) => entry[1])
            .map((entry) => entry[0]);

        if (!value || !tasksToMove.length) {
            return;
        }

        const success: string[] = [];
        const failed: string[] = [];

        await pMap(tasksToMove, async (taskId) => {
            try {
                const res = await patchAdminTask.mutateAsync({
                    params: {
                        path: {
                            organizationId: orgId,
                            measurementTaskId: taskId,
                        },
                    },
                    payload: {
                        measurementGroupId: value,
                    },
                });

                if (res.response.ok) {
                    success.push(taskId);
                } else {
                    failed.push(taskId);
                }
            } catch {
                failed.push(taskId);
            }
        })
            .then(() => {
                if (success.length) {
                    snack.success(
                        `Migrated ${success.length === 1 ? "one task" : `${success.length} tasks`}`,
                    );
                }

                if (failed.length) {
                    snack.error(
                        `Migration ${failed.length === 1 ? "one task" : `${success.length} tasks`} failed`,
                    );
                }
            })
            .finally(() => onClose());
    };

    const onSelectTaskForMigration = (id: string, checked: boolean) => {
        setFormState((prev) => {
            return {
                ...prev,
                [id]: checked,
            };
        });
    };

    const entries = Object.entries(formState);

    const onSetMethodForAll = (
        value: boolean,
        method: MeasurementMethodType,
    ) => {
        const allTasksOfMethod = filterMap(
            filterTasksByMethodAndMapToID(method),
            sampleTasksToMove,
        );
        setFormState((prev) => {
            const newState = {
                ...prev,
            };

            allTasksOfMethod.forEach((task) => {
                newState[task] = value;
            });

            return newState;
        });
    };

    const onChangeAllCheckbox =
        (method: MeasurementMethodType) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked;
            onSetMethodForAll(value, method);
        };

    const areAllSelectedForXRD = Boolean(
        allHRXRPDTasks.every((s) => formState[s]),
    );
    const areAllSelectedForTSPDF = Boolean(
        allPDFTasks.every((s) => formState[s]),
    );
    const areAllSelectedForSAXS = Boolean(
        allSAXSTasks.length && allSAXSTasks.every((s) => formState[s]),
    );

    const areSomeSelectedForXRD = Boolean(
        allHRXRPDTasks.length && allHRXRPDTasks.some((s) => formState[s]),
    );
    const areSomeSelectedForPDF = Boolean(
        allPDFTasks.length && allPDFTasks.some((s) => formState[s]),
    );
    const areSomeSelectedForSAXS = Boolean(
        allSAXSTasks.length && allSAXSTasks.some((s) => formState[s]),
    );

    const isFormValid =
        (areSomeSelectedForPDF ||
            areSomeSelectedForXRD ||
            areSomeSelectedForSAXS) &&
        value;

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={close}
                onOverlayClick={close}
                header="Move measurement tasks"
                buttonLeft={
                    <Button colorScheme="gray" onClick={close}>
                        Cancel
                    </Button>
                }
                buttonRight={
                    <Button
                        isDisabled={!isFormValid}
                        colorScheme="blue"
                        onClick={() => void submit()}
                    >
                        Confirm
                    </Button>
                }
            >
                <Box
                    display="flex"
                    flexDirection="column"
                    fontSize="14px"
                    py="2px"
                    px="2px"
                    overflow="visible"
                    zIndex={30}
                >
                    <FormLabel>Measurement request</FormLabel>
                    <Text
                        mt="18px"
                        fontSize="16px"
                        display="block"
                        flex=" 0 0 48px"
                        overflow="hidden"
                        fontWeight="600"
                    >
                        Selected (checked) methods will be moved to the request
                        of your choice
                    </Text>

                    <SelectField
                        label="Target request"
                        onChange={(event) =>
                            setValue(
                                event.target.value === "None"
                                    ? undefined
                                    : event.target.value,
                            )
                        }
                        value={value ?? "None"}
                        options={(requestQuery.data ?? [])
                            .filter((r) => r.id !== sourceRequestId)
                            .map((o) => ({
                                value: o.id,
                                label: o.name,
                            }))
                            .concat([
                                {
                                    value: "None",
                                    label: "None",
                                },
                            ])}
                    />

                    <Box
                        display="flex"
                        mt="24px"
                        mb="8px"
                        flex="0 0 84px"
                        justifyContent="space-between"
                        pb="20px"
                        overflow="hidden"
                        borderBottom="solid 1px lightgray"
                    >
                        <Center justifyContent="unset" flex="0 0 120px">
                            <Text display="block" fontSize="14px">
                                Sample-ID
                            </Text>
                        </Center>
                        <Center flex="0 0 120px">
                            <Checkbox
                                colorScheme="green"
                                size="lg"
                                isIndeterminate={
                                    areSomeSelectedForXRD &&
                                    !areAllSelectedForXRD
                                }
                                isChecked={areAllSelectedForXRD}
                                value={MeasurementMethod.Hrxrpd}
                                onChange={onChangeAllCheckbox(
                                    MeasurementMethod.Hrxrpd,
                                )}
                            />

                            <Button
                                fontSize="14px"
                                variant="ghost"
                                textDecor="underline"
                                colorScheme="secondary"
                                onClick={() =>
                                    onSetMethodForAll(
                                        !areAllSelectedForXRD,
                                        MeasurementMethod.Hrxrpd,
                                    )
                                }
                            >
                                HR-XRPD
                            </Button>
                            <Tooltip label={messages.xrdExplainer}>
                                <Icon.Info size="14px" />
                            </Tooltip>
                        </Center>
                        <Center flex="0 0 120px">
                            <Checkbox
                                colorScheme="green"
                                size="lg"
                                isIndeterminate={
                                    areSomeSelectedForPDF &&
                                    !areAllSelectedForTSPDF
                                }
                                isChecked={areAllSelectedForTSPDF}
                                onChange={onChangeAllCheckbox(
                                    MeasurementMethod.Tspdf,
                                )}
                            />

                            <Button
                                fontSize="14px"
                                colorScheme="secondary"
                                variant="ghost"
                                textDecor="underline"
                                onClick={() =>
                                    onSetMethodForAll(
                                        !areAllSelectedForTSPDF,
                                        MeasurementMethod.Tspdf,
                                    )
                                }
                            >
                                TS-PDF
                            </Button>
                            <Tooltip label={messages.tsPdfExplainer}>
                                <Icon.Info size="14px" />
                            </Tooltip>
                        </Center>
                        <Center flex="0 0 120px">
                            <Checkbox
                                colorScheme="green"
                                size="lg"
                                value={MeasurementMethod.Saxs}
                                isIndeterminate={
                                    areSomeSelectedForSAXS &&
                                    !areAllSelectedForSAXS
                                }
                                isChecked={areAllSelectedForSAXS}
                                onChange={onChangeAllCheckbox(
                                    MeasurementMethod.Saxs,
                                )}
                            />

                            <Button
                                fontSize="14px"
                                colorScheme="secondary"
                                variant="ghost"
                                textDecor="underline"
                                onClick={() =>
                                    onSetMethodForAll(
                                        !areAllSelectedForSAXS,
                                        MeasurementMethod.Saxs,
                                    )
                                }
                            >
                                SAXS
                            </Button>
                            <Tooltip label={messages.saxsExplainer}>
                                <Icon.Info size="14px" />
                            </Tooltip>
                        </Center>
                    </Box>
                    <Box
                        overflow="hidden"
                        display="flex"
                        flexDir="column"
                        height="100%"
                    >
                        {sampleTasksToMove.map((sampleTask, idx) => {
                            return (
                                <Box
                                    key={sampleTask.id}
                                    justifyContent="space-between"
                                    display="flex"
                                    height="100%"
                                    flex="0 0 53px"
                                    alignItems="center"
                                    borderBottom={
                                        entries.length - 1 !== idx
                                            ? "1px solid lightgray"
                                            : undefined
                                    }
                                >
                                    <Box
                                        flex=" 0 0 120px"
                                        overflow="hidden"
                                        whiteSpace="nowrap"
                                    >
                                        <Text>{sampleTask.name}</Text>
                                    </Box>
                                    <Center flex=" 0 0 120px">
                                        <Checkbox
                                            isDisabled={
                                                !sampleTask.tasks.hrxrpd?.taskId
                                            }
                                            colorScheme="green"
                                            onChange={(e) => {
                                                if (
                                                    !sampleTask.tasks.hrxrpd
                                                        ?.taskId
                                                ) {
                                                    return;
                                                }
                                                onSelectTaskForMigration(
                                                    sampleTask.tasks.hrxrpd
                                                        .taskId,
                                                    e.target.checked,
                                                );
                                            }}
                                            size="lg"
                                            value={MeasurementMethod.Hrxrpd}
                                            isChecked={
                                                sampleTask.tasks.hrxrpd?.taskId
                                                    ? formState[
                                                          sampleTask.tasks
                                                              .hrxrpd.taskId
                                                      ]
                                                    : false
                                            }
                                        />
                                    </Center>
                                    <Center flex=" 0 0 120px">
                                        <Checkbox
                                            isDisabled={
                                                !sampleTask.tasks.tspdf?.taskId
                                            }
                                            colorScheme="green"
                                            onChange={(e) => {
                                                if (!sampleTask.tasks.tspdf) {
                                                    return;
                                                }
                                                onSelectTaskForMigration(
                                                    sampleTask.tasks.tspdf
                                                        .taskId,
                                                    e.target.checked,
                                                );
                                            }}
                                            size="lg"
                                            value={MeasurementMethod.Tspdf}
                                            isChecked={
                                                sampleTask.tasks.tspdf?.taskId
                                                    ? formState[
                                                          sampleTask.tasks.tspdf
                                                              .taskId
                                                      ]
                                                    : false
                                            }
                                        />
                                    </Center>
                                    <Center flex=" 0 0 120px">
                                        <Checkbox
                                            isDisabled={
                                                !sampleTask.tasks.saxs?.taskId
                                            }
                                            colorScheme="green"
                                            onChange={(e) => {
                                                if (
                                                    !sampleTask.tasks.tspdf
                                                        ?.taskId
                                                ) {
                                                    return;
                                                }
                                                onSelectTaskForMigration(
                                                    sampleTask.tasks.tspdf
                                                        .taskId,
                                                    e.target.checked,
                                                );
                                            }}
                                            size="lg"
                                            value={MeasurementMethod.Saxs}
                                            isChecked={
                                                sampleTask.tasks.saxs?.taskId
                                                    ? formState[
                                                          sampleTask.tasks.saxs
                                                              .taskId
                                                      ]
                                                    : false
                                            }
                                        />
                                    </Center>
                                </Box>
                            );
                        })}
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
