import type { FC } from "react";

import { Button, Center, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { routes } from "@app/Routes/routes.ts";

type Props = {
    target?: string;
};
export const NotFound: FC<Props> = ({ target }) => {
    const navigate = useNavigate();
    return (
        <Center w="100%" h="100%">
            <VStack alignItems="flex-start" gap="12px">
                <Text as="h2" textStyle="h3">
                    404
                </Text>

                <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    Uh-oh!
                </p>

                <p className="mt-4 text-gray-500">
                    We can&apos;t find that page.
                </p>

                <Button
                    colorScheme="secondary"
                    onClick={() => {
                        void navigate({
                            to: target ?? routes.user.home.url,
                        });
                    }}
                >
                    Try again later
                </Button>
            </VStack>
        </Center>
    );
};
