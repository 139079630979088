import pMap from "p-map";
import { isNotNil } from "ramda";

import { useQuery } from "@tanstack/react-query";

import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";

const useUser = (userId: string) => {
    const api = useAPI();

    return useQuery({
        queryKey: ["users", userId],
        // only fetch once
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        queryFn: async () => {
            return await api.client.GET("/admin/users/{userId}", {
                params: {
                    path: {
                        userId,
                    },
                },
            });
        },
    });
};

const useUsers = (userIds: string[]) => {
    const api = useAPI();

    return useQuery({
        queryKey: ["users", JSON.stringify(userIds)],
        // only fetch once
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: Infinity,
        queryFn: async () => {
            const users = await pMap(userIds, async (userId) => {
                const response = await api.client.GET("/admin/users/{userId}", {
                    params: {
                        path: {
                            userId,
                        },
                    },
                });

                if (!response.response.ok) {
                    throw new Error("Could not fetch user");
                }
                return response.data;
            });

            return users.filter(isNotNil);
        },
    });
};

export const Identity = {
    useUser,
    useUsers,
};
