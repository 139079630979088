// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
// @ts-nocheck
import type { FC, ReactNode } from "react";
import React, { Component } from "react";

import {
    plotlyTraceToCustomTrace,
    renderTraceIcon,
    traceTypeToPlotlyInitFigure,
} from "lib";
import { TRACES_WITH_GL } from "lib/constants";
import PropTypes from "prop-types";

import { Box, Center, Text } from "@chakra-ui/react";

type ItemProps = {
    item: {
        label: string;
        value: string;
        icon: ReactNode;
    };
    complex?: boolean;
    active?: boolean;
    handleClick: React.MouseEventHandler;
};

const Item: FC<ItemProps> = ({ item, active, handleClick, complex }) => {
    const { label, value, icon } = item;
    const SimpleIcon = renderTraceIcon(icon ? icon : value);
    const ComplexIcon = renderTraceIcon(icon ? icon : value, "TraceType");

    return (
        <Box
            p="8px"
            display="flex"
            flexDir="column"
            alignItems="center"
            overflow="hidden"
            borderRadius="8px"
            _hover={{ bg: "gray.100" }}
            cursor="pointer"
            width="100px"
            height="100px"
            bg={active ? "gray.100" : "white"}
            onClick={handleClick}
        >
            <div>
                {!complex && (
                    <Box h="70px" w="70px" className="trace-item__image__svg">
                        <SimpleIcon />
                    </Box>
                )}
                {complex && (
                    <Box
                        h="70px"
                        w="70px"
                        className="trace-item__image__wrapper"
                    >
                        <ComplexIcon />
                    </Box>
                )}
            </div>
            <Text display="block" textAlign="center" fontSize="14px">
                {label}
            </Text>
        </Box>
    );
};

Item.propTypes = {
    item: PropTypes.object,
    active: PropTypes.bool,
    complex: PropTypes.bool,
    handleClick: PropTypes.func,
    actions: PropTypes.func,
    showActions: PropTypes.bool,
};

class TraceTypeSelector extends Component {
    constructor(props) {
        super(props);

        this.selectAndClose = this.selectAndClose.bind(this);
    }

    selectAndClose(value) {
        const {
            updateContainer,
            glByDefault,
            fullContainer: { type },
        } = this.props;
        const computedValue = traceTypeToPlotlyInitFigure(value);
        if (
            ((type && type.endsWith("gl")) ||
                (!TRACES_WITH_GL.includes(type) && glByDefault)) &&
            TRACES_WITH_GL.includes(computedValue.type) &&
            !computedValue.type.endsWith("gl")
        ) {
            computedValue.type += "gl";
        }
        updateContainer(computedValue);
        this.context.handleClose();
    }

    render() {
        const { fullValue } = this.props;

        const {
            traceTypesConfig: { traces, complex },
        } = this.props;

        return (
            <>
                <Center display="flex" gap="48px" flexWrap="wrap">
                    {traces().map((item) => (
                        <Item
                            key={item.value}
                            complex={complex}
                            active={fullValue === item.value}
                            item={item}
                            handleClick={() => this.selectAndClose(item.value)}
                            style={{ display: "inline-block" }}
                        />
                    ))}
                </Center>
            </>
        );
    }
}

TraceTypeSelector.propTypes = {
    updateContainer: PropTypes.func,
    fullValue: PropTypes.string,
    fullContainer: PropTypes.object,
    glByDefault: PropTypes.bool,
    traceTypesConfig: PropTypes.object,
};
TraceTypeSelector.contextTypes = {
    handleClose: PropTypes.func,
    localize: PropTypes.func,
    mapBoxAccess: PropTypes.bool,
    chartHelp: PropTypes.object,
};

export class TraceTypeSelectorButton extends Component {
    render() {
        const {
            handleClick,
            container,
            traceTypesConfig: { traces },
        } = this.props;

        const inferredType = plotlyTraceToCustomTrace(container);
        const { label, icon, value } = traces().find(
            (type) => type.value === inferredType,
        );

        const Icon = renderTraceIcon(icon ? icon : value);

        return (
            <Box
                border="solid 1px"
                borderColor="gray.200"
                className="trace-type-select-button"
                onClick={handleClick ? handleClick : null}
            >
                <div className="trace-type-select-button__icon">
                    <Icon />
                </div>
                {label}
            </Box>
        );
    }
}

TraceTypeSelectorButton.propTypes = {
    handleClick: PropTypes.func.isRequired,
    container: PropTypes.object,
    traceTypesConfig: PropTypes.object.isRequired,
};
TraceTypeSelectorButton.contextTypes = {
    localize: PropTypes.func,
};

export default TraceTypeSelector;
