// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component } from "react";

import PropTypes from "prop-types";

import { Box } from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

// import isNumeric from "fast-isnumeric";
import { isNumeric } from "@mt-tools/typeguards.ts";

import EditableText from "./EditableText";

import type { ScrollEvent } from "@react-types/shared";

export const UP_ARROW = 38;
export const DOWN_ARROW = 40;

type Props = {
    editableClassName?: string;
    placeholder?: string;
    value?: string | number;
    max?: number;
    min?: number;
    integerOnly?: boolean;
    showArrows?: boolean;
    showSlider?: boolean;
    defaultValue?: string | number;
    step?: number;
    stepmode?: string;
    onUpdate: (value: number) => void;
};

type State = {
    numericInputClassName?: string;
    value?: string | number;
};

export default class NumericInput extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            value: props.value,
            numericInputClassName: this.getNumericInputClassName(props.value),
        };

        this.onChange = this.onChange.bind(this);
        this.updateValue = this.updateValue.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onWheel = this.onWheel.bind(this);
    }

    getNumericInputClassName(value?: string | number) {
        return isNumeric(value) || value === ""
            ? `${
                  this.props.editableClassName
                      ? this.props.editableClassName
                      : ""
              }`
            : `+error ${
                  this.props.editableClassName
                      ? this.props.editableClassName
                      : ""
              }`;
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (nextProps.value !== this.state.value) {
            this.setState({ value: nextProps.value });
        }
    }

    onKeyDown(e: KeyboardEvent) {
        switch (e.keyCode) {
            case UP_ARROW:
                this.incrementValue("increase");
                break;
            case DOWN_ARROW:
                this.incrementValue("decrease");
                break;
            default:
                break;
        }
    }

    onWheel(e: ScrollEvent) {
        e.stopPropagation();
        e.preventDefault();
        if (e.deltaY > 0) {
            this.incrementValue("increase");
        } else {
            this.incrementValue("decrease");
        }
    }

    onChange(value: string | number) {
        this.setState({
            value,
            numericInputClassName: this.getNumericInputClassName(value),
        });
    }

    updateValue(newValue?: string | number) {
        const { max, min, integerOnly } = this.props;
        let updatedValue = newValue;

        if (updatedValue === "") {
            this.setState({
                value: this.props.value,
                numericInputClassName: this.getNumericInputClassName(
                    this.props.value,
                ),
            });
            return;
        }

        // When the user blurs on non-numeric data reset the component
        // to the last known good value (this.props.value).
        if (!isNumeric(updatedValue)) {
            this.setState({
                value: updatedValue,
                numericInputClassName:
                    this.getNumericInputClassName(updatedValue),
            });
            return;
        }

        updatedValue = Number(updatedValue);
        if (integerOnly) {
            updatedValue = Math.floor(updatedValue);
        }

        if (isNumeric(min)) {
            updatedValue = Math.max(min, updatedValue);
        }

        if (isNumeric(max)) {
            updatedValue = Math.min(max, updatedValue);
        }

        this.props.onUpdate(updatedValue);
    }

    incrementValue(direction: string) {
        const {
            defaultValue,
            min,
            step = 1,
            stepmode = "absolute",
        } = this.props;
        const { value } = this.state;

        let valueUpdate;
        if (isNumeric(value)) {
            const x = parseFloat(value);
            const absMode = stepmode === "absolute";
            if (direction === "increase") {
                valueUpdate = absMode ? x + step : x * (1 + step);
            } else {
                valueUpdate = absMode ? x - step : x / (1 + step);
            }
        } else {
            // if we are multi-valued and the user is incrementing or decrementing
            // update with some sane value so we can "break" out of multi-valued mode.
            if (isNumeric(defaultValue)) {
                valueUpdate = defaultValue;
            } else {
                // TODO smarter handling depending if user decrements or increments?
                valueUpdate = min || 0;
            }
        }

        // incrementers blur the line between blur and onChange.
        this.updateValue(valueUpdate);
    }

    renderArrows() {
        if (!this.props.showArrows || this.props.showSlider) {
            return null;
        }

        return (
            <Box
                display="inline-block"
                maxH="32px"
                ml="6px"
                mr="12px"
                verticalAlign="middle"
                boxSizing="border-box"
            >
                <Box
                    cursor="pointer"
                    backgroundColor="gray.200"
                    border="solid 1px"
                    borderColor="gray.200"
                    borderRadius=" 1px"
                    lineHeight="12px"
                    textAlign="center"
                    maxHeight="13px"
                    mb="2px"
                    color="gray.600"
                    onClick={this.incrementValue.bind(this, "increase")}
                >
                    <Icon.ChevronUp size="12px" color="currentColor" />
                    {/*<CarretUpIcon className="numeric-top-caret-modifier" />*/}
                </Box>
                <Box
                    mt="2px"
                    cursor="pointer"
                    backgroundColor="gray.200"
                    border="solid 1px"
                    borderColor="gray.200"
                    borderRadius=" 1px"
                    lineHeight="12px"
                    textAlign="center"
                    maxHeight="13px"
                    color="gray.600"
                    onClick={this.incrementValue.bind(this, "decrease")}
                >
                    <Icon.ChevronDown size="12px" color="currentColor" />
                </Box>
            </Box>
        );
    }

    renderSlider() {
        if (!this.props.showSlider) {
            return null;
        }

        return null;
        // react-rangeslider. Ugly
        //     <Slider
        //         min={this.props.min}
        //         max={this.props.max}
        //         step={this.props.step}
        //         value={parseFloat(this.state.value)}
        //         onChange={this.updateValue}
        //         tooltip={false}
        //     />
    }

    render() {
        return (
            <Box
                lineHeight="20px"
                maxW="100%"
                flex={1}
                display="flex"
                alignItems="center;"
                color="gray.800"
                sx={{
                    input: {
                        display: "inline-block",
                        border: "solid 1px",
                        borderColor: "gray.200",
                        cursor: "text",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: "white",
                        whiteSpace: "nowrap",
                        textAlign: "left",
                        borderRadius: "4px",
                        padding: "6px",
                        fontSize: "inherit",
                        color: "inherit",
                        fontFamily: "inherit",
                        width: "62px",
                    },
                }}
            >
                <EditableText
                    className={this.state.numericInputClassName}
                    placeholder={this.props.placeholder}
                    text={this.state.value}
                    type="text"
                    onChange={this.onChange}
                    onUpdate={this.updateValue}
                    onKeyDown={this.onKeyDown}
                    onWheel={this.onWheel}
                />
                {this.renderArrows()}
                {this.renderSlider()}
                {this.props.units ? this.props.units : null}
            </Box>
        );
    }
}

NumericInput.propTypes = {
    defaultValue: PropTypes.any,
    editableClassName: PropTypes.string,
    integerOnly: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showArrows: PropTypes.bool,
    showSlider: PropTypes.bool,
    step: PropTypes.number,
    stepmode: PropTypes.string,
    value: PropTypes.any,
    units: PropTypes.string,
};

NumericInput.defaultProps = {
    showArrows: true,
};
