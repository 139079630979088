import type { FC } from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, Divider as ChakraDivider } from "@chakra-ui/react";

export const Divider: FC<BoxProps> = (props) => {
    return (
        <Box my="16px" py="16px" {...props}>
            <ChakraDivider borderColor="gray.400" />
        </Box>
    );
};
