import type { FC, MouseEventHandler, ReactNode } from "react";
import React from "react";

import { Box, Button, HStack, VStack } from "@chakra-ui/react";

export interface EmptyStateProps {
    isPrimaryDisabled?: boolean;
    isSecondaryDisabled?: boolean;
    icon?: React.ComponentType;
    heading: ReactNode;
    body: ReactNode;
    buttonLabel?: ReactNode;
    secondaryButtonLabel?: ReactNode;
    onClick?: MouseEventHandler;
    onSecondaryClick?: MouseEventHandler;
}

export const EmptyState: FC<EmptyStateProps> = ({
    isPrimaryDisabled,
    isSecondaryDisabled,
    buttonLabel,
    secondaryButtonLabel,
    heading,
    icon,
    onClick,
    onSecondaryClick,
    body,
}) => {
    return (
        <VStack>
            <VStack flexDir="column" gap="12px">
                <Box w="100px" h="100px" as={icon} />
                <Box textStyle="h4" fontWeight="500">
                    {heading}
                </Box>
                <Box textStyle="T1" color="gray.800">
                    {body}
                </Box>
                <HStack>
                    {secondaryButtonLabel && (
                        <Button
                            isDisabled={isSecondaryDisabled}
                            onClick={onSecondaryClick}
                            variant="ghost"
                        >
                            {secondaryButtonLabel}
                        </Button>
                    )}
                    {buttonLabel && (
                        <Button
                            isDisabled={isPrimaryDisabled}
                            colorScheme="blue"
                            onClick={onClick}
                        >
                            {buttonLabel}
                        </Button>
                    )}
                </HStack>
            </VStack>
        </VStack>
    );
};
