const getBrowserLocales = (options = {}) => {
    const defaultOptions = {
        languageCodeOnly: false,
    };
    const opt = {
        ...defaultOptions,
        ...options,
    };
    const browserLocales =
        typeof navigator.languages === "undefined"
            ? [navigator.language]
            : navigator.languages;

    return browserLocales.map((locale) => {
        const trimmedLocale = locale.trim();
        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
    });
};

// @todo: read from user profile
const locales = getBrowserLocales();
const dateFormatter = new Intl.DateTimeFormat(locales[0]);

export const formatDate = (value: string) => {
    const date = new Date(value);
    return dateFormatter.format(date);
};
