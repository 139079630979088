import type { FC } from "react";
import { useState } from "react";

import pMap from "p-map";

import { Box, Button, Progress, Spinner, Text } from "@chakra-ui/react";

import { Request } from "@app/domain/api/request.ts";

import { FileUpload } from "@mt-components/Input/FileUpload.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { MiB } from "@mt-tools/units.tsx";

import { DataTable } from "src/packages/components/DataTable";
import { Icon } from "src/packages/solid-design/icons";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    measurementRequests: { organizationId: string; id: string; name: string }[];
};

type Row = {
    name: string;
    status: undefined | "loading" | "ok" | { error: string };
};

const maxFileSize = 200;
const maxFileSizeWUnit = maxFileSize * MiB;

export const UploadAuxFileModal: FC<Props> = (props) => {
    const uploadMutation = Request.useUploadAuxFiles();

    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [file, setFile] = useState<File | null>(null);
    const [rows, setRows] = useState<Row[]>(
        props.measurementRequests.map((x) => {
            return {
                name: x.name,
                status: undefined,
            };
        }),
    );

    const progressPercent =
        rows.filter((x) => !!x.status && x.status !== "loading").length /
        rows.length;

    return (
        <Modal
            isOpen={props.isOpen}
            onClose={props.onClose}
            buttonRight={
                <Button
                    isDisabled={!file || isUploading}
                    isLoading={isUploading && progressPercent < 1}
                    onClick={async () => {
                        if (!file) throw Error("File not set");

                        setRows(
                            rows.map((x) => {
                                return { ...x, status: "loading" };
                            }),
                        );

                        // Intentionally not resetting upload
                        setIsUploading(true);
                        await pMap(
                            props.measurementRequests,
                            async (mr, mri) => {
                                try {
                                    await uploadMutation.mutateAsync({
                                        organizationId: mr.organizationId,
                                        measurementGroupId: mr.id,
                                        file: file,
                                    });

                                    setRows((rows) =>
                                        rows.map((x, i) => {
                                            if (i !== mri) return x;
                                            return { ...x, status: "ok" };
                                        }),
                                    );
                                } catch (error) {
                                    setRows((rows) =>
                                        rows.map((x, i) => {
                                            if (i !== mri) return x;
                                            return {
                                                ...x,
                                                status: {
                                                    error: (error as Error)
                                                        .message,
                                                },
                                            };
                                        }),
                                    );
                                }
                            },
                            { concurrency: 2 },
                        );
                    }}
                >
                    Upload
                </Button>
            }
            header="Add auxiliary files"
        >
            <FileUpload
                maxFiles={1}
                maxFileSize={maxFileSizeWUnit}
                uploadLabel={`Select file ${maxFileSize} Mb max`}
                setFiles={(files) => {
                    if (files?.acceptedFiles.length === 1) {
                        setFile(files.acceptedFiles[0]);
                        return;
                    }

                    if (!files) {
                        setFile(null);
                    }
                }}
                clearFiles={() => setFile(null)}
                accept={[".zip"]}
            />
            <Box padding="15px 0 15px 0">
                <Text>Progress:</Text>
                {isUploading ? (
                    <Progress
                        value={progressPercent * 100}
                        colorScheme="blue"
                    ></Progress>
                ) : (
                    "Not started"
                )}
            </Box>
            <DataTable<Row>
                style={{
                    height: "300px",
                }}
                columns={[
                    { accessorKey: "name", header: "Name" },
                    {
                        accessorKey: "status",
                        header: "Status",
                        cell: (x) => {
                            const v = x.row.original.status;
                            if (!v) return;

                            if (v === "loading")
                                return <Spinner size="s"></Spinner>;

                            if (typeof v === "object") {
                                return (
                                    <Text>
                                        <Icon.Error></Icon.Error>
                                        {v.error}
                                    </Text>
                                );
                            }

                            return <Icon.Success></Icon.Success>;
                        },
                    },
                ]}
                data={rows}
            ></DataTable>
        </Modal>
    );
};

UploadAuxFileModal.displayName = "UploadAuxFileModal";
