import { type FC, useState } from "react";

import {
    Box,
    Button,
    Center,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";

import type { OrganizationModel } from "@app/domain/api/organization.ts";
import type { OrganizationRole } from "@app/domain/services/organization.ts";
import { OrganizationRoles } from "@app/domain/services/organization.ts";
import { routes } from "@app/Routes/routes.ts";
import { InviteUserModal } from "@app-components/modals/organization/InviteUser.modal.tsx";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { Crumbs } from "@mt-components/Crumbs/Crumbs.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { Icon } from "@mt-design/icons.tsx";

import { organizationAuthzQueryKey, usePublicClient } from "src/api/client";
import { RoleTag } from "src/App/components/RoleTag";
import { useUserContext } from "src/App/contexts/useUserContext";
import { LoadingSpinner } from "src/packages/components/states/Loading/LoadingSpinner";

type Props = {
    isFormOpen: boolean;
    organizationId: string;
    organization: OrganizationModel;
    onToggleForm: () => void;
};

export const OrganizationDetail: FC<Props> = ({
    organization,
    organizationId,
}) => {
    const currentUser = useUserContext();
    const inviteDisclosure = useDisclosure();

    const [filter, setFilter] = useState("");

    const client = usePublicClient();
    const queryClient = useQueryClient();
    const organizationAuthzQuery = useQuery({
        queryKey: organizationAuthzQueryKey(organizationId),
        queryFn: () =>
            client.GET("/organizations/{organizationId}/authzs", {
                params: {
                    path: { organizationId },
                    query: { first: 1000 },
                },
            }),
    });
    const deleteAuthzMutation = useMutation({
        mutationFn: (data: { userId: string }) =>
            client.DELETE("/organizations/{organizationId}/authzs/{userId}", {
                params: { path: { organizationId, ...data } },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: organizationAuthzQueryKey(organizationId),
            });
        },
    });

    const allItems = organizationAuthzQuery.data?.data?.items ?? [];
    const filteredItems = allItems.filter(
        (x) => x.user.email.includes(filter) || x.user.name?.includes(filter),
    );
    const userAuthz = allItems.find((x) => x.user.id === currentUser.id);
    const owners = allItems.filter(
        (x) => x.role === OrganizationRoles.OWNER,
    ).length;

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
        {
            id: "detail",
            label: organization.name,
            isCurrentPage: true,
        },
    ];

    return (
        <>
            <InviteUserModal
                organizationId={organizationId}
                isOpen={inviteDisclosure.isOpen}
                onClose={inviteDisclosure.onToggle}
            />
            <PageLayout>
                <Box
                    overflow="hidden"
                    mx="auto"
                    display="flex"
                    flexDirection="column"
                    w="100%"
                    h="100%"
                >
                    <Box>
                        <Crumbs items={crumbs} />
                        <PageHeader
                            mt="12px"
                            title="Member Management"
                            subtitle="Manage your team members and their permissions here"
                            actions={[]}
                        />
                    </Box>
                    <Text fontSize="md">
                        <b>Note:</b> Please note, that organization must have at
                        least one owner. If you are the only owner of the
                        organization - you can&apos;t leave it. Consider
                        ownership transfer first or organization delete.
                    </Text>
                    <Box
                        mb="14px"
                        display="flex"
                        flexWrap="wrap"
                        w="100%"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Box
                            as="div"
                            display="block"
                            textStyle="h5"
                            flex="0 0 240px"
                        >
                            <Text as="span">All members </Text>
                            <Text as="span" color="gray.400">
                                {filteredItems.length}
                            </Text>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <SearchFilterBar
                                placeholder="Search by email or role"
                                onReset={() => setFilter("")}
                                onChange={(x) => setFilter(x.target.value)}
                            />
                            <Button
                                colorScheme="blue"
                                gap="8px"
                                onClick={inviteDisclosure.onOpen}
                            >
                                <Icon.Plus size="20" /> Invite User
                            </Button>
                        </Box>
                    </Box>
                    <Box flex={1} h="300px">
                        {organizationAuthzQuery.isFetching ? (
                            <Center>
                                <LoadingSpinner />
                            </Center>
                        ) : (
                            <DataTable
                                // FIXME: This is to force remounting, as otherwise neither useEffect, useMemo, useState for owners doesn't work.
                                key={`table-owners-${owners}`}
                                state={{
                                    columnVisibility: {
                                        menu:
                                            userAuthz?.role ===
                                            OrganizationRoles.OWNER,
                                    },
                                }}
                                variant="unstyled"
                                data={filteredItems}
                                columns={[
                                    {
                                        accessorKey: "user.email",
                                        header: "Email",
                                    },
                                    {
                                        accessorKey: "role",
                                        header: "Role",
                                        cell: (x) => (
                                            <RoleTag
                                                role={x.getValue<OrganizationRole>()}
                                            ></RoleTag>
                                        ),
                                    },
                                    {
                                        id: "menu",
                                        header: "",
                                        thProps: makeWidth("80px"),
                                        cell: (x) => {
                                            return (
                                                <AuthzMenu
                                                    canRemove={
                                                        owners > 1 &&
                                                        x.row.original.user
                                                            .id !==
                                                            currentUser.id
                                                    }
                                                    onRemove={() => {
                                                        deleteAuthzMutation.mutate(
                                                            {
                                                                userId: x.row
                                                                    .original
                                                                    .user.id,
                                                            },
                                                        );
                                                    }}
                                                ></AuthzMenu>
                                            );
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Box>
                </Box>
            </PageLayout>
        </>
    );
};

OrganizationDetail.displayName = "OrganizationDetail";

const AuthzMenu: FC<{
    canRemove: boolean;
    onRemove: () => void;
}> = (props) => {
    return (
        <Menu>
            <MenuButton
                bg="white"
                aria-label="Menu"
                colorScheme="gray"
                as={IconButton}
                icon={<Icon.Menu />}
            />
            <MenuList>
                <MenuItem
                    key="remove"
                    onClick={() => props.onRemove()}
                    isDisabled={!props.canRemove}
                >
                    Remove
                </MenuItem>
            </MenuList>
        </Menu>
    );
};
