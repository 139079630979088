import type { FC } from "react";

import { Box } from "@chakra-ui/react";
import { Link } from "@tanstack/react-router";

import type { BeamtimeModel } from "@app/domain/api/agent/beamtime.ts";
import { routes } from "@app/Routes/routes.ts";

import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";

import { SyncContent } from "./Sync.tsx";

type BeamtimeDetailProps = {
    beamtime: BeamtimeModel;
};

export const BeamtimeSync: FC<BeamtimeDetailProps> = ({ beamtime }) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            px="12px"
            h="100%"
            overflowY="auto"
        >
            <PageHeader
                crumbs={[
                    {
                        id: "beamtimes",
                        label: (
                            <Link to={routes.agent.beamtimes.url}>
                                Beamtimes
                            </Link>
                        ),
                    },
                    {
                        id: "beamtime-detail",
                        label: (
                            <Link
                                params={{ beamtimeId: beamtime.id }}
                                to={routes.agent.beamtimesDetail.url}
                            >
                                {beamtime.name}
                            </Link>
                        ),
                    },
                    {
                        id: "sync-connerct",
                        label: "Sync & Connect",
                    },
                ]}
                actions={[]}
            />

            <SyncContent beamtimeId={beamtime.id} />
        </Box>
    );
};
