import React from "react";

import { has } from "ramda";

import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    ButtonGroup,
    chakra,
    CloseButton,
} from "@chakra-ui/react";

import type { SnackbarProps } from "@mt-hooks/useSnackbar2.tsx";

import { useTheme } from "@mt-design/useTheme.ts";

export const Snackbar: React.FC<SnackbarProps> = (props) => {
    const {
        status,
        variant,
        id,
        title,
        icon,
        isClosable,
        onClose,
        description,
        action,
    } = props;

    const theme = useTheme();

    // Use the snackbar variant if it exists, otherwise default to solid
    const defaultVariant = has("snackbar", theme.components.Alert.variants)
        ? "snackbar"
        : "solid";

    const alertTitleId =
        typeof id !== "undefined" ? `toast-${id}-title` : undefined;

    return (
        <Alert
            status={status}
            variant={variant || defaultVariant}
            id={id !== "undefined" ? `toast-${id}` : undefined}
            alignItems="start"
            borderRadius="md"
            boxShadow="lg"
            textAlign="start"
            width="auto"
            aria-labelledby={alertTitleId}
        >
            {icon === null ? null : icon ? icon : <AlertIcon />}
            <chakra.div flex="1" maxWidth="100%">
                {title && <AlertTitle id={alertTitleId}>{title}</AlertTitle>}
                {description && (
                    <AlertDescription display="block">
                        {description}
                    </AlertDescription>
                )}
            </chakra.div>
            {(action || isClosable) && (
                <ButtonGroup size="xs" variant="ghost" ms="2">
                    {action}
                    {isClosable && <CloseButton size="sm" onClick={onClose} />}
                </ButtonGroup>
            )}
        </Alert>
    );
};
