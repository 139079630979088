import type { ChangeEvent } from "react";
import { useState } from "react";

import { z } from "zod";

import {
    Box,
    Button,
    Progress,
    SimpleGrid,
    Spacer,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from "@chakra-ui/react";

import { useConnect, useSync } from "@app/domain/api/agent/esrf.ts";

import { Field } from "@mt-components/Input/Field.tsx";
import { FileUpload } from "@mt-components/Input/FileUpload.tsx";

type SyncModalProps = {
    beamtimeId: string;
};

type SaveForm = {
    beamtimeEsrfId: string;
    beamlineId: string;
    beamtimeId: string;
    accessId: string;
};

type ConnectForm = {
    termstspdf: string;
    termshrxrpd: string;
};

const syncSchema = z.object({
    accessId: z.string().min(1),
    beamlineId: z.string().min(1),
    beamtimeId: z.string().min(1),
    beamtimeEsrfId: z.string().min(1),
});

const connectSchema = z.object({
    termshrxrpd: z.string().min(1),
    termstspdf: z.string().min(1),
});

// const poni = `
// poni_version: 2
// Distance: 0.2998702293253568
// Poni1: 0.2505125671113666
// Poni2: 0.23463871407730735
// Rot1: -0.006024288874035534
// Rot2: -0.003675992295007966
// Rot3: -2.0652560092124806e-10
// Wavelength: 1.6531226457760035e-11
// `;
const initialConnectForm: ConnectForm = {
    termshrxrpd: "0.00000496391258,-0.0000805929833,0.00028542788,0",
    termstspdf: "0.00000101787305,-0.0000737692414,0.000627336051,0",
};

export const SyncContent = ({ beamtimeId }: SyncModalProps) => {
    const initialSaveForm: SaveForm = {
        beamtimeId,
        accessId: "testaccess",
        beamtimeEsrfId: "testtimecode",
        beamlineId: "testbeamline",
    };

    const [tsPdfMask, setTspdfMAsk] = useState<File | undefined>(undefined);
    const [hrxrpdMask, setHrxrpdMask] = useState<File | undefined>(undefined);

    const [tsPdfPoni, setTspdfPoni] = useState<File | undefined>(undefined);
    const [hrxrpdPoni, setHrxrpdPoni] = useState<File | undefined>(undefined);

    const [logs, setLogs] = useState<string[]>([]);
    const [connectLog, setConnectLogs] = useState<string[]>([]);

    const [saveForm, setSaveForm] = useState<SaveForm>(initialSaveForm);

    const [connectform, setConnectForm] =
        useState<ConnectForm>(initialConnectForm);

    const appendLog = (log: string) => setLogs((logs) => [log, ...logs]);
    const appendConnectLog = (log: string) =>
        setConnectLogs((logs) => [log, ...logs]);

    const { sync, isRunningSync, isDoneSync } = useSync({ appendLog });
    const {
        connect,
        isDone: isDoneConnect,
        isRunning: isRunningConnect,
    } = useConnect({
        appendLog: appendConnectLog,
    });

    const isSyncSaveFormComplete = syncSchema.safeParse(saveForm).success;
    const isConnectFormComplete =
        syncSchema.safeParse(saveForm).success &&
        connectSchema.safeParse(connectform).success &&
        tsPdfMask &&
        tsPdfPoni &&
        hrxrpdMask &&
        hrxrpdPoni;

    const submitSync = (form: SaveForm) => {
        if (!syncSchema.safeParse(form).success) {
            return;
        }

        void sync({
            access: form.accessId,
            beamline: form.beamlineId,
            timecode: form.beamtimeEsrfId,
        });

        setSaveForm(form);
    };

    const onConnect = () => {
        if (
            !syncSchema.safeParse(saveForm).success ||
            !connectSchema.safeParse(connectform).success ||
            !tsPdfMask ||
            !hrxrpdMask ||
            !tsPdfPoni ||
            !hrxrpdPoni
        ) {
            return;
        }

        const json = {
            termstspdf: connectform.termstspdf.split(",").map(parseFloat),
            termshrxrpd: connectform.termshrxrpd.split(",").map(parseFloat),
        };

        void connect({
            access: saveForm.accessId,
            beamline: saveForm.beamlineId,
            timecode: saveForm.beamtimeEsrfId,
            beamtimeId,
            masktspdf: tsPdfMask,
            maskhrxrpd: hrxrpdMask,
            ponitspdf: tsPdfPoni,
            ponihrxrpd: hrxrpdPoni,
            json: JSON.stringify(json),
        });
    };

    const onChangeSave = (e: ChangeEvent<HTMLInputElement>) => {
        setSaveForm((data) => {
            return {
                ...data,
                [e.target.name]: e.target.value,
            };
        });
    };

    const onChangeConnect = (
        e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setConnectForm((data) => {
            return {
                ...data,
                [e.target.name]: e.target.value,
            };
        });
    };

    return (
        <VStack alignItems="stretch" h="100%" overflow="hidden">
            <Text pb="12px" px="4px" overflow="hidden">
                (1) Sync data to our storage and (2) process data connecting the
                results to our data model
            </Text>
            <Tabs variant="enclosed" flex={1} overflow="hidden">
                <TabList height="48px">
                    <Tab px="12px">Save</Tab>
                    <Tab px="12px">Connect</Tab>
                </TabList>
                <TabPanels h="calc(100% - 64px)">
                    <TabPanel h="100%" overflowY="auto">
                        <Box h="100%" w="100%">
                            <Box h="80px">
                                <Progress
                                    size="md"
                                    mb="12px"
                                    isIndeterminate={isRunningSync}
                                    value={isDoneSync ? 100 : 0}
                                    colorScheme="green"
                                />
                                <Button
                                    mx="12px"
                                    onClick={() => submitSync(saveForm)}
                                    isDisabled={!isSyncSaveFormComplete}
                                    colorScheme={isDoneSync ? "green" : "blue"}
                                >
                                    Sync Save
                                </Button>
                            </Box>

                            <SimpleGrid columns={5} spacing={4}>
                                <Box
                                    h="100%"
                                    gridColumnStart="1"
                                    gridColumnEnd="3"
                                    px="4px"
                                >
                                    <Text fontSize="18px" fontWeight="600">
                                        Beamtime Info
                                    </Text>
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="beamlineId"
                                        onChange={onChangeSave}
                                        value={saveForm.beamlineId}
                                        isDisabled={isRunningSync}
                                        name="beamlineId"
                                        label="Beamline ID"
                                        help="The ID of the beamline at the ESRF, (id31)"
                                        isRequired
                                    />
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="accessId"
                                        value={saveForm.accessId}
                                        onChange={onChangeSave}
                                        isDisabled={isRunningSync}
                                        name="accessId"
                                        label="Access ID"
                                        help="The access ID, (in1176)"
                                        isRequired
                                    />
                                    <Field
                                        type="text"
                                        my="8px"
                                        onChange={onChangeSave}
                                        value={saveForm.beamtimeEsrfId}
                                        key="beamtimeEsrfId"
                                        isDisabled={isRunningSync}
                                        name="beamtimeEsrfId"
                                        label="Time code"
                                        help="The ID/date of the beamtime, (20240306)"
                                        isRequired
                                    />
                                </Box>
                                <Box
                                    h="100%"
                                    gridColumnStart="3"
                                    gridColumnEnd="6"
                                    overflowY="auto"
                                >
                                    <Field
                                        formControlProps={{
                                            height: "100%",
                                        }}
                                        my={0}
                                        height="calc(100% - 39px)"
                                        type="textarea"
                                        key="safe-log"
                                        value={logs.join("\n")}
                                        isDisabled={isRunningSync}
                                        name="safe-log"
                                        label="Logs"
                                    />
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </TabPanel>
                    <TabPanel h="100%" overflow="hidden">
                        <Box h="100%" w="100%" overflow="hidden">
                            <Box h="80px">
                                <Progress
                                    size="md"
                                    mb="12px"
                                    isIndeterminate={isRunningConnect}
                                    value={isDoneConnect ? 100 : 0}
                                    colorScheme="green"
                                />
                                <Button
                                    mx="12px"
                                    colorScheme={
                                        isDoneConnect ? "green" : "blue"
                                    }
                                    isDisabled={!isConnectFormComplete}
                                    onClick={onConnect}
                                >
                                    Connect
                                </Button>
                            </Box>
                            <SimpleGrid
                                height="calc(100% - 80px)"
                                columns={5}
                                spacing={4}
                                overflow="hidden"
                            >
                                <Box
                                    h="100%"
                                    gridColumnStart="1"
                                    gridColumnEnd="3"
                                    overflowY="auto"
                                    px="4px"
                                >
                                    <Text fontSize="18px" fontWeight="600">
                                        Beamtime Info
                                    </Text>
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="beamlineId"
                                        onChange={onChangeSave}
                                        value={saveForm.beamlineId}
                                        isDisabled={isRunningSync}
                                        name="beamlineId"
                                        label="Beamline ID"
                                        help="The ID of the beamline at the ESRF, (id31)"
                                        isRequired
                                    />
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="accessId"
                                        value={saveForm.accessId}
                                        onChange={onChangeSave}
                                        isDisabled={isRunningSync}
                                        name="accessId"
                                        label="Access ID"
                                        help="The access ID, (in1176)"
                                        isRequired
                                    />
                                    <Field
                                        type="text"
                                        my="8px"
                                        onChange={onChangeSave}
                                        value={saveForm.beamtimeEsrfId}
                                        key="beamtimeEsrfId"
                                        isDisabled={isRunningSync}
                                        name="beamtimeEsrfId"
                                        label="Time code"
                                        help="The ID/date of the beamtime, (20240306)"
                                        isRequired
                                    />

                                    <Spacer my="64px" />
                                    <Text fontSize="18px" fontWeight="600">
                                        HR-XRPD
                                    </Text>
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="termshrxrpd"
                                        onChange={onChangeConnect}
                                        value={connectform.termshrxrpd}
                                        isDisabled={isRunningConnect}
                                        name="termshrxrpd"
                                        label="Polynomial correction"
                                        help="e.g. 0.00000496391258,-0.0000805929833,0.00028542788,0"
                                        isRequired
                                    />

                                    <FileUpload
                                        maxFiles={1}
                                        maxFileSize={1024 * 1024 * 20}
                                        uploadLabel="Set HR-XRPD poni file"
                                        accept={[".poni"]}
                                        setFiles={(files) => {
                                            if (
                                                files?.acceptedFiles.length ===
                                                1
                                            ) {
                                                setHrxrpdPoni(
                                                    files.acceptedFiles[0],
                                                );
                                                return;
                                            }

                                            if (!files) {
                                                setHrxrpdPoni(undefined);
                                            }
                                        }}
                                        clearFiles={() =>
                                            setHrxrpdPoni(undefined)
                                        }
                                    />

                                    <FileUpload
                                        maxFiles={1}
                                        maxFileSize={1024 * 1024 * 20}
                                        uploadLabel="Set HR-XRPD mask file"
                                        accept={[".edf"]}
                                        setFiles={(files) => {
                                            if (
                                                files?.acceptedFiles.length ===
                                                1
                                            ) {
                                                setHrxrpdMask(
                                                    files.acceptedFiles[0],
                                                );
                                                return;
                                            }

                                            if (!files) {
                                                setHrxrpdMask(undefined);
                                            }
                                        }}
                                        clearFiles={() =>
                                            setHrxrpdMask(undefined)
                                        }
                                    />

                                    <Spacer my="64px" />
                                    <Text fontSize="18px" fontWeight="600">
                                        TS-PDF
                                    </Text>
                                    <Field
                                        type="text"
                                        my="8px"
                                        key="termstspdf"
                                        value={connectform.termstspdf}
                                        onChange={onChangeConnect}
                                        isDisabled={isRunningConnect}
                                        name="termstspdf"
                                        label="Polynomial correction"
                                        help="e.g. 0.00000101787305,-0.0000737692414,0.000627336051,0"
                                        isRequired
                                    />
                                    <FileUpload
                                        maxFiles={1}
                                        maxFileSize={1024 * 1024 * 20}
                                        uploadLabel="Set TS-PDF poni file"
                                        accept={[".poni"]}
                                        setFiles={(files) => {
                                            if (
                                                files?.acceptedFiles.length ===
                                                1
                                            ) {
                                                setTspdfPoni(
                                                    files.acceptedFiles[0],
                                                );
                                                return;
                                            }

                                            if (!files) {
                                                setTspdfPoni(undefined);
                                            }
                                        }}
                                        clearFiles={() =>
                                            setTspdfPoni(undefined)
                                        }
                                    />
                                    <FileUpload
                                        maxFiles={1}
                                        maxFileSize={1024 * 1024 * 20}
                                        uploadLabel="Set TS-PDF mask file"
                                        accept={[".edf"]}
                                        setFiles={(files) => {
                                            if (
                                                files?.acceptedFiles.length ===
                                                1
                                            ) {
                                                setTspdfMAsk(
                                                    files.acceptedFiles[0],
                                                );
                                                return;
                                            }

                                            if (!files) {
                                                setTspdfMAsk(undefined);
                                            }
                                        }}
                                        clearFiles={() =>
                                            setTspdfMAsk(undefined)
                                        }
                                    />
                                </Box>
                                <Box
                                    h="100%"
                                    gridColumnStart="3"
                                    gridColumnEnd="6"
                                    overflowY="auto"
                                >
                                    <Field
                                        formControlProps={{
                                            height: "100%",
                                        }}
                                        my={0}
                                        height="calc(100% - 29px)"
                                        type="textarea"
                                        key="connect-log"
                                        value={connectLog.join("\n")}
                                        isDisabled={isRunningConnect}
                                        name="connect-log"
                                        label="Connect log"
                                    />
                                </Box>
                            </SimpleGrid>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    );
};
