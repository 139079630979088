import type { FC, ReactNode } from "react";

import type {
    BoxProps,
    FormControlProps,
    InputProps as ChakraInputProps,
    TextareaProps as ChakraTextareaProps,
} from "@chakra-ui/react";
import {
    FormControl,
    FormHelperText,
    FormLabel,
    Input,
    Text,
    Textarea,
} from "@chakra-ui/react";

type FieldProps = {
    isDisabled: boolean;
    isRequired?: boolean;
    name: string;
    label: ReactNode;
    help?: ReactNode;
    value: string;
    my: BoxProps["my"];
};

type InputProps = FieldProps &
    ChakraInputProps & { type: "text" } & {
        formControlProps?: FormControlProps;
    };

type TextareaProps = FieldProps &
    ChakraTextareaProps & { type: "textarea" } & {
        formControlProps?: FormControlProps;
    };

export const Field: FC<InputProps | TextareaProps> = (props) => {
    if (props.type === "textarea") {
        const { my, isRequired, label, help, formControlProps, ...rest } =
            props;
        return (
            <FormControl my={my} {...formControlProps}>
                {Boolean(label) && (
                    <FormLabel>
                        <Text display="inline-block">{label}</Text>
                        {isRequired && (
                            <Text display="inline-block" color="red.600">
                                *
                            </Text>
                        )}
                    </FormLabel>
                )}
                <Textarea required={isRequired} {...rest} />
                {Boolean(help) && <FormHelperText>{help}</FormHelperText>}
            </FormControl>
        );
    }

    const { my, isRequired, label, help, formControlProps, ...rest } = props;
    return (
        <FormControl my={my} {...formControlProps}>
            {Boolean(label) && (
                <FormLabel>
                    <Text display="inline-block">{label}</Text>
                    {isRequired && (
                        <Text display="inline-block" color="red.600">
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            <Input required={isRequired} {...rest} />
            {Boolean(help) && <FormHelperText>{help}</FormHelperText>}
        </FormControl>
    );
};
