import type { FC, PropsWithChildren } from "react";
import { memo, useEffect, useMemo, useRef } from "react";
import { AuthProvider, type AuthProviderProps } from "react-oidc-context";
import useHotjar from "react-use-hotjar";

import { WebStorageStateStore } from "oidc-client-ts";
import { z } from "zod";

import { useQuery } from "@tanstack/react-query";

import { config } from "@app/config.ts";
import { useLoadingContext } from "@app/contexts/GlobalLoader/LoadingProvider/useLoadingContext.tsx";

import { isLocalEnvironment } from "@mt-tools/env.ts";

import { ConfigContext } from "./ConfigContext.tsx";

import * as Sentry from "@sentry/react";

export const initDataLoadingKey = `init-data-${Date.now()}`;

export type RemoteConfig = {
    oryClientId: string;
    idpURL: string;
    environment: "local" | "dev" | "prod";
    sentryURL: string;
    hotjarId: number;
    apiPath: string;
    featureFlags?: {
        plotting?: Record<string, { id?: string } | undefined>;
        proforma?: boolean;
    };
};

const configSchema = z.object({
    oryClientId: z.string(),
    idpURL: z.string(),
    environment: z.enum(["local", "dev", "prod"]),
    sentryURL: z.string(),
    hotjarId: z.number(),
    apiPath: z.string(),
    featureFlags: z
        .object({
            plotting: z
                .record(
                    z.string(),
                    z.object({
                        id: z.string(),
                        comment: z.string(),
                    }),
                )
                .optional(),
            proforma: z.boolean().optional(),
        })
        .optional(),
});

const useInitData = () =>
    useQuery<unknown, unknown, RemoteConfig>({
        queryKey: ["init-data"],
        staleTime: Infinity,
        queryFn: async () => {
            const r = await fetch("/config.json", {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "cache-control": "no-cache",
                },
            });

            const rawJson: unknown = await r.json();
            const result = configSchema.safeParse(rawJson);

            if (result.success) {
                return result.data;
            }

            return null;
        },
    });

export const OIDCAndConfig: FC<PropsWithChildren> = memo(({ children }) => {
    const isSentryInitialized = useRef(false);
    const loadingContext = useLoadingContext();
    const ctx = useRef(loadingContext);
    const { data: remoteConfig, isLoading: isLoadingRemoteConfig } =
        useInitData();

    const { initHotjar } = useHotjar();

    useEffect(() => {
        if (remoteConfig?.hotjarId) {
            initHotjar(remoteConfig.hotjarId, 6, false);
            return;
        }
    }, [initHotjar, remoteConfig?.hotjarId]);

    useEffect(() => {
        if (!remoteConfig) {
            return;
        }

        if (!remoteConfig.sentryURL) {
            return;
        }

        if (isSentryInitialized.current) {
            return;
        }

        if (!isLocalEnvironment()) {
            Sentry.init({
                environment: remoteConfig.environment,
                dsn: remoteConfig.sentryURL,
                integrations: [
                    Sentry.browserTracingIntegration(),
                    Sentry.replayIntegration({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],
                // Performance Monitoring
                tracesSampleRate: 1.0, //  Capture 100% of the transactions
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ["*momentum-transfer.com"],
                // Session Replay
                replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            });
        }

        isSentryInitialized.current = true;
    }, [remoteConfig]);

    useEffect(() => {
        if (!isLoadingRemoteConfig) {
            ctx.current.removeLoading(initDataLoadingKey);
            return;
        }
    }, [isLoadingRemoteConfig]);

    // NOTE: Problems with current oidc component:
    // https://github.com/authts/oidc-client-ts/issues/1618
    const oidcConfig = useMemo(
        (): AuthProviderProps => ({
            client_id: remoteConfig?.oryClientId,
            automaticSilentRenew: true,
            prompt: "login",
            scope: "openid profile email profile offline_access",
            authority: remoteConfig?.idpURL,
            redirect_uri: `${config.host}/signin-callback`,
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            onSigninCallback: (): void => {
                window.history.replaceState(
                    {},
                    document.title,
                    config.pathname,
                );
            },
        }),
        [remoteConfig?.idpURL, remoteConfig?.oryClientId],
    );

    if (isLoadingRemoteConfig || !remoteConfig) {
        // should  not happen as the global loader should catch the case when the config is still loading
        // but just in case
        return null;
    }

    return (
        <ConfigContext.Provider value={remoteConfig}>
            <AuthProvider {...oidcConfig}>{children}</AuthProvider>
        </ConfigContext.Provider>
    );
});

OIDCAndConfig.displayName = "OIDCAndConfig";
