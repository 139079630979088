import type { ChangeEvent, FC } from "react";
import { FiSearch } from "react-icons/fi";

import type { BoxProps } from "@chakra-ui/react";
import {
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/react";

import { Icon } from "@mt-design/icons.tsx";

type Props = {
    value?: string;
    placeholder?: string;
    onReset: () => void;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
    isDisabled?: boolean;
    width?: BoxProps["width"];
    maxWidth?: BoxProps["maxWidth"];
};

export const TableSearchInput: FC<Props> = ({
    onChange,
    onReset,
    value,
    placeholder,
    isDisabled,
    width,
    maxWidth,
}) => {
    return (
        <InputGroup maxWidth={maxWidth ?? "220px"} width={width ?? "100%"}>
            <InputLeftElement pointerEvents="none">
                <FiSearch />
            </InputLeftElement>

            <Input
                type="search"
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                isDisabled={isDisabled ?? false}
            />

            {value && (
                <InputRightElement>
                    {/* <Icon.Close onClick={onReset}/> */}
                    <IconButton
                        variant="none"
                        aria-label="Reset search input"
                        icon={<Icon.Close />}
                        onClick={onReset}
                        isDisabled={isDisabled ?? false}
                    />
                </InputRightElement>
            )}
        </InputGroup>
    );
};
