import type { FC } from "react";

import { indexBy } from "ramda";

import { Avatar, Box, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "@tanstack/react-router";

import type { OrganizationAuthzRecord } from "@app/domain/api/authz.ts";
import { Authz } from "@app/domain/api/authz.ts";
import type { OrganizationModel } from "@app/domain/api/organization.ts";
import { OrganizationRoles } from "@app/domain/services/organization.ts";
import { routes } from "@app/Routes/routes.ts";

import type { Crumb } from "@mt-components/Crumbs/Crumbs.tsx";
import { Crumbs } from "@mt-components/Crumbs/Crumbs.tsx";
import { DataTable } from "@mt-components/DataTable.tsx";
import { SearchFilterBar } from "@mt-components/Input/SearchFilterBar.tsx";
import { PageHeader } from "@mt-components/Layout/PageHeader.tsx";
import { PageLayout } from "@mt-components/Layout/PageLayout.tsx";

import { useSearch } from "@mt-hooks/useSearch.tsx";

import { organizationsUsersQueryKey } from "src/api/client";
import { RoleTag } from "src/App/components/RoleTag";
import { useAPI } from "src/App/contexts/APIContext/useApiContext";

type Props = {
    organization: OrganizationModel;
};

export const OrganizationDetail: FC<Props> = ({ organization }) => {
    const navigate = useNavigate();
    const orgAuthzQuery = Authz.useGetOrganizationRecords(organization.id);

    const api = useAPI();
    const organizationUsersQuery = useQuery({
        queryKey: organizationsUsersQueryKey(organization.id),
        queryFn: () => {
            return api.agentClient.GET("/index/users", {
                params: {
                    query: {
                        organizationId: organization.id,
                        first: 1000,
                    },
                },
            });
        },
    });

    const authRecordByUserId: Record<
        string,
        OrganizationAuthzRecord | undefined
    > = indexBy((record) => record.userId, orgAuthzQuery.data ?? []);

    const members =
        organizationUsersQuery.data?.data?.items.map((x) => ({
            ...x,
            role:
                authRecordByUserId[x.user.id]?.role ?? OrganizationRoles.VIEWER,
        })) ?? [];

    const search = useSearch<(typeof members)[0]>({
        items: members,
        keys: [(x) => x.user.name, (x) => x.user.email, "role"],
    });

    const crumbs: Crumb[] = [
        {
            id: "organizations",
            label: (
                <Link to={routes.agent.organizations.url}>
                    <Text textStyle="hx">Organizations</Text>
                </Link>
            ),
        },
        {
            id: "detail",
            label: organization.name,
            isCurrentPage: true,
        },
    ];

    return (
        <PageLayout>
            <Box
                overflow="hidden"
                mx="auto"
                display="flex"
                flexDirection="column"
                w="100%"
                h="100%"
            >
                <Crumbs items={crumbs} />
                <PageHeader
                    mt="12px"
                    title={`${organization.name}'s members`}
                    actions={[]}
                />
                <Box
                    mb="14px"
                    display="flex"
                    flexWrap="wrap"
                    w="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        as="div"
                        display="block"
                        textStyle="h5"
                        flex="0 0 240px"
                    >
                        <Text as="span">All members </Text>
                    </Box>
                    <Box display="flex" alignItems="center">
                        <SearchFilterBar
                            placeholder="Search by email or role"
                            value={search.searchTerm}
                            onReset={search.onClear}
                            onChange={search.onChange}
                        />
                    </Box>
                </Box>
                <Box h="300px">
                    <DataTable
                        state={{
                            columnVisibility: {
                                menu: false,
                            },
                        }}
                        variant="unstyled"
                        data={search.filteredOrders}
                        columns={[
                            {
                                accessorKey: "avatar",
                                header: "Avatar",
                                cell: (cell) => {
                                    return (
                                        <Box>
                                            <Avatar
                                                size="sm"
                                                src={
                                                    cell.cell.row.original.user
                                                        .avatarUrl
                                                }
                                            />
                                        </Box>
                                    );
                                },
                            },
                            {
                                accessorKey: "user.name",
                                header: "Name",
                            },
                            {
                                accessorKey: "user.email",
                                header: "Email",
                            },
                            {
                                accessorKey: "role",
                                header: "Role",
                                cell: (ctx) => (
                                    <RoleTag
                                        role={ctx.cell.row.original.role}
                                    ></RoleTag>
                                ),
                            },
                        ]}
                        onRowClick={(row) => {
                            void navigate({
                                to: routes.agent.user.url,
                                params: { userId: row.user.id },
                            });
                        }}
                    />
                </Box>
            </Box>
        </PageLayout>
    );
};

OrganizationDetail.displayName = "OrganizationDetail";
