import type { FC } from "react";

import type { SidebarProps } from "@mt-components/Sidebar";
import { Sidebar as SaasUISidebar } from "@mt-components/Sidebar";

export const Sidebar: FC<SidebarProps> = (props) => {
    return (
        <SaasUISidebar
            h="100%"
            toggleBreakpoint={false}
            colorScheme="white"
            maxW="3800px !important"
            flex={props.isOpen ? "0 0 380px" : "0 0 63px"}
            variant={props.isOpen ? "default" : "compact"}
            overflow="hidden"
            display="flex"
            flexDirection="column"
            py="12px"
            bg="gray.50"
            {...props}
        />
    );
};
