import { type FC, useEffect } from "react";

import { useNavigate } from "@tanstack/react-router";

import { routes } from "@app/Routes/routes.ts";

export const SigninCallbackPage: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        void navigate({ to: routes.user.home.url });
    });

    return <div>Signing in...</div>;
};
