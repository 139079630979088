import type { FC } from "react";

import { Box, Text } from "@chakra-ui/react";

import type { MeasurementGroupStatusType } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import { getColorScheme } from "@app-components/measurementGroup/GroupStatus/getColorScheme.tsx";

import { Steps, StepsItem } from "@mt-components/Stepper";

type MyStepsProps = {
    status: MeasurementGroupStatusType;
};

const statusToStep = {
    [MeasurementGroupStatus.Draft]: 0,
    [MeasurementGroupStatus.Submitted]: 1,
    [MeasurementGroupStatus.Accepted]: 2,
    [MeasurementGroupStatus.Scheduled]: 3,
    [MeasurementGroupStatus.Completed]: 4,
    [MeasurementGroupStatus.Declined]: 5,
};

const descriptionProps = {
    display: {
        md: "initial",
        base: "none",
    },
    as: "span" as const,
    textStyle: "T3",
};

const textProps = {
    as: "span" as const,
    textStyle: "T2W500H180" as const,
    color: "gray" as const,
};

export const Process: FC<MyStepsProps> = ({ status }) => {
    return (
        <Box display="flex" width="100%" color="gray.100">
            <Steps
                flex="1 1 60%"
                maxWidth="1250px"
                colorScheme={getColorScheme(status)}
                size="sm"
                variant="subtle"
                step={statusToStep[status]}
            >
                <StepsItem title={<Text {...textProps}>Draft</Text>} />
                <StepsItem
                    textStyle="T3"
                    title={<Text {...textProps}>Submitted</Text>}
                    description={
                        <Text {...descriptionProps}>Evaluation pending</Text>
                    }
                />
                <StepsItem
                    textStyle="T3"
                    title={<Text {...textProps}>Accepted</Text>}
                    description={
                        <Text {...descriptionProps}>Evaluation pending</Text>
                    }
                />
                <StepsItem
                    title={<Text {...textProps}>Scheduled</Text>}
                    description={
                        <Text {...descriptionProps}>Date determined</Text>
                    }
                />
                <StepsItem
                    isCompleted
                    title={<Text {...textProps}>Completed</Text>}
                    description={
                        <Text {...descriptionProps}>
                            Measurements completed
                        </Text>
                    }
                />
            </Steps>
        </Box>
    );
};
