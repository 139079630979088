import type { FC } from "react";
import { StrictMode } from "react";

import { AppRouter, authLoadingKey } from "@app/AppRouter.tsx";
import { APIContext } from "@app/contexts/APIContext/APIContext.tsx";
import {
    initDataLoadingKey,
    OIDCAndConfig,
} from "@app/contexts/ConfigContext/OIDCAndConfig.tsx";
import { CustomQueryClientProvider } from "@app/contexts/CustomQueryClientProvider.tsx";
import { LoadingOverlay } from "@app/contexts/GlobalLoader/LoadingOverlay.tsx";
import { LoadingProvider } from "@app/contexts/GlobalLoader/LoadingProvider/LoadingProvider.tsx";
import { ModalsProvider } from "@app/contexts/ModalsProvider";
import { orgsLoadingKey } from "@app/contexts/OrgContext/OrgContext.tsx";

import { SaasProvider } from "@mt-components/saas-ui-adapter/SaasProvider.tsx";

import { isLocalEnvironment } from "@mt-tools/env.ts";

import { theme } from "@mt-design/theme.ts";

if (isLocalEnvironment()) {
    // @ts-ignore
    window.theme = theme;
}

const toastOptions = {
    defaultOptions: {
        variant: "subtle",
    },
};

export const App: FC = () => {
    return (
        <StrictMode>
            <CustomQueryClientProvider>
                <SaasProvider theme={theme} toastOptions={toastOptions}>
                    <ModalsProvider>
                        <LoadingProvider
                            isInitiallyLoading={true}
                            initKeys={[
                                initDataLoadingKey,
                                orgsLoadingKey,
                                authLoadingKey,
                            ]}
                        >
                            <LoadingOverlay>
                                <OIDCAndConfig>
                                    <APIContext>
                                        <AppRouter />
                                    </APIContext>
                                </OIDCAndConfig>
                            </LoadingOverlay>
                        </LoadingProvider>
                    </ModalsProvider>
                </SaasProvider>
            </CustomQueryClientProvider>
        </StrictMode>
    );
};
