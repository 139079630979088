// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, ReactNode } from "react";
import type { Props as ReactSelectProps } from "react-select";

import { connectToContainer } from "lib";

import type { FieldProps } from "./Field";
import Field from "./Field";

import DropdownWidget from "../widgets/Dropdown";

export type UnconnectedDropdownProps = {
    multiValued?: boolean;
    backgroundDark?: boolean;
    components?: ReactSelectProps["components"];
    clearable?: boolean;
    fullValue?: unknown;
    options?: { value: string | null; label: ReactNode }[];
    updatePlot?: () => void;
    disabled?: boolean;
} & FieldProps;

export const UnconnectedDropdown: FC<UnconnectedDropdownProps> = (props) => {
    let placeholder;
    if (props.multiValued) {
        placeholder = props.fullValue;
    }

    return (
        <Field {...props}>
            <DropdownWidget
                backgroundDark={props.backgroundDark}
                options={props.options}
                value={props.fullValue}
                onChange={props.updatePlot}
                clearable={props.clearable}
                placeholder={placeholder}
                disabled={props.disabled}
                components={props.components}
            />
        </Field>
    );
};

UnconnectedDropdown.displayName = "UnconnectedDropdown";

export default connectToContainer(UnconnectedDropdown);
