import type { FC } from "react";

import { Box, IconButton, Spacer } from "@chakra-ui/react";

import { ToggleIcon } from "@mt-components/icon/ToggleIcon.tsx";
import type { SidebarSectionProps } from "@mt-components/Sidebar";

type Props = {
    isOpen?: boolean;
    onToggle: () => void;
} & SidebarSectionProps;

export const Footer: FC<Props> = ({ isOpen, onToggle, ...rest }) => (
    <Box flex="0 0 32px" {...rest}>
        <IconButton
            onClick={onToggle}
            variant="ghost"
            size="sm"
            icon={<ToggleIcon showLeft={Boolean(isOpen)} />}
            aria-label="Toggle Sidebar"
        />
        <Spacer />
    </Box>
);
