import React from "react";

import type {
    HTMLChakraProps,
    SystemStyleObject,
    ThemingProps,
} from "@chakra-ui/react";
import {
    Flex,
    forwardRef,
    omitThemingProps,
    useMultiStyleConfig,
} from "@chakra-ui/react";
import { cx } from "@chakra-ui/utils";

import {
    AppShellProvider,
    StylesProvider,
} from "@mt-components/AppShell/context.tsx";
import { useAppShell } from "@mt-components/AppShell/useAppShell.tsx";

export interface AppShellProps
    extends HTMLChakraProps<"div">,
        ThemingProps<"SuiAppShell"> {
    /**
     * The top header navigation
     */
    navbar?: React.ReactNode;
    /**
     * Main sidebar, positioned on the left
     */
    sidebar?: React.ReactElement;
    /**
     * Secondary sidebar, positioned on the right
     */
    aside?: React.ReactNode;
    /**
     * The footer
     */
    footer?: React.ReactNode;
    /**
     * The main content
     */
    children: React.ReactNode;
    mainRef?: React.RefObject<HTMLDivElement>;
}

/**
 * The App Shell defines the main structure of your app.
 *
 * @see Docs https://saas-ui.dev/docs/components/layout/app-shell
 */
export const AppShell = forwardRef<AppShellProps, "div">((props, ref) => {
    const styles = useMultiStyleConfig("SuiAppShell", props);

    const {
        navbar,
        sidebar,
        aside,
        footer,
        children,
        mainRef,
        ...containerProps
    } = omitThemingProps(props);

    const containerStyles: SystemStyleObject = {
        flexDirection: "column",
        ...styles.container,
    };

    const innerStyles: SystemStyleObject = {
        flex: 1,
        minHeight: 0, // make sure child flex divs get correct height.
        minWidth: 0, // make sure child flex divs get correct width.
        ...styles.inner,
    };

    const mainStyles: SystemStyleObject = {
        flex: 1,
        flexDirection: "column",
        minWidth: 0, // make sure child flex divs get correct width.
        ...styles.main,
    };

    const isSidebar =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
        React.isValidElement(sidebar) && (sidebar as any).type.id === "Sidebar";

    const context = useAppShell({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        toggleBreakpoint: isSidebar
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
              (sidebar as any)?.props.toggleBreakpoint
            : undefined,
    });

    return (
        <AppShellProvider value={context}>
            <StylesProvider value={styles}>
                <Flex
                    ref={ref}
                    {...containerProps}
                    sx={containerStyles}
                    className={cx("sui-app-shell", props.className)}
                >
                    {navbar}
                    <Flex sx={innerStyles} className="saas-app-shell__inner">
                        {sidebar}
                        <Flex
                            ref={mainRef}
                            sx={mainStyles}
                            className="saas-app-shell__main"
                        >
                            {children}
                        </Flex>
                        {aside}
                    </Flex>
                    {footer}
                </Flex>
            </StylesProvider>
        </AppShellProvider>
    );
});

AppShell.displayName = "AppShell";
