import { z } from "zod";

import { Route } from "@tanstack/react-router";

import type { MeasurementGroupStatusType } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import { BeamtimeDetailsPage } from "@app/pages/agent/beamtimeDetails/BeamtimeDetails.container.tsx";
import { BeamtimesPage } from "@app/pages/agent/beamtimes/Beamtimes.container.tsx";
import { BeamtimeSyncPage } from "@app/pages/agent/beamtimeSync/BeamtimeSync.container.tsx";
import { Dashboard } from "@app/pages/agent/dashboard";
import { OrganizationDetailsPage } from "@app/pages/agent/organization/OrganizationPage.tsx";
import { OrganizationsPage } from "@app/pages/agent/organizations/OrganizationsPage.tsx";
import { PackagesContainer } from "@app/pages/agent/packages/Packages.container.tsx";
import { PlotDetailPage } from "@app/pages/agent/plot/PlotDetailPage.tsx";
import { PlotsPage } from "@app/pages/agent/plots/PlotsPage.tsx";
import { RequestDetailsContainer } from "@app/pages/agent/requestDetails/RequestDetails.container.tsx";
import { RequestResultContainer } from "@app/pages/agent/requestResult/RequestResult.container.tsx";
import { RequestsContainer } from "@app/pages/agent/requests/Requests.container";
import { SamplesContainer } from "@app/pages/agent/samples/Samples.container.tsx";
import { UserPage } from "@app/pages/agent/UserDetail/UserPage.tsx";
import { UsersPage } from "@app/pages/agent/users/UsersPage.tsx";
import {
    ensureAuthAndAgent,
    isAgentOrGoHome,
    notAuthGoToLogin,
} from "@app/Routes/beforeLoad.tsx";
import { publicRoot } from "@app/Routes/publicRoute.tsx";
import { routes } from "@app/Routes/routes.ts";

import { zodEnum } from "@mt-tools/zod.ts";

const samplesPageSearchSchema = z.object({
    samples: z.array(z.string()).catch([]),
});

const measurementGroupDataSchema = z.object({
    sample: z.string().catch(""),
});

const statusTypes = Object.values(
    MeasurementGroupStatus,
) satisfies MeasurementGroupStatusType[];

const requestsPageSearchSchema = z.object({
    status: z.enum(zodEnum(statusTypes)).optional(),
});

export const agent = {
    dashboard: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.home.url,
        component: Dashboard,
    }),
    measurementGroups: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.measurementGroups.url,
        component: RequestsContainer,
        validateSearch: (
            search: Record<string, unknown>,
        ): { status?: MeasurementGroupStatusType } =>
            requestsPageSearchSchema.parse(search),
    }),
    measurementGroupDetail: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.measurementGroupDetail.url,
        component: RequestDetailsContainer,
    }),
    requestResult: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.requestResult.url,
        component: RequestResultContainer,
        validateSearch: (search: Record<string, unknown>): { sample: string } =>
            measurementGroupDataSchema.parse(search),
    }),
    beamtimesRoute: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.beamtimes.url,
        component: BeamtimesPage,
    }),
    beamtimeDetailsRoute: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.beamtimesDetail.url,
        component: BeamtimeDetailsPage,
    }),
    beamtimeSyncRoute: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.beamtimeSync.url,
        component: BeamtimeSyncPage,
    }),
    samples: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.samples.url,
        component: SamplesContainer,
        validateSearch: (
            search: Record<string, unknown>,
        ): { samples: string[] } => samplesPageSearchSchema.parse(search),
    }),
    organizations: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.organizations.url,
        component: OrganizationsPage,
    }),
    organization: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.organization.url,
        component: OrganizationDetailsPage,
    }),
    plotRoute: new Route({
        beforeLoad: async (params) => {
            await notAuthGoToLogin(params);
            await isAgentOrGoHome(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.agent.plotDetail.url,
        component: PlotDetailPage,
    }),
    plotsRoute: new Route({
        beforeLoad: async (params) => {
            await notAuthGoToLogin(params);
            await isAgentOrGoHome(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.agent.plots.url,
        component: PlotsPage,
    }),
    packages: new Route({
        beforeLoad: async (params) => {
            await notAuthGoToLogin(params);
            await isAgentOrGoHome(params);
        },
        getParentRoute: () => publicRoot,
        path: routes.agent.packages.url,
        component: PackagesContainer,
    }),
    users: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.users.url,
        component: UsersPage,
    }),
    user: new Route({
        beforeLoad: ensureAuthAndAgent,
        getParentRoute: () => publicRoot,
        path: routes.agent.user.url,
        component: UserPage,
    }),
};
