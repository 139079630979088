// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable */
// @ts-nocheck

import type { ReactNode } from "react";
import { Component } from "react";

import { Box } from "@chakra-ui/react";

import { LINK } from "./configuration";
import StyleButton from "./StyleButton";

type Props = {
    onToggle: () => void;
    styles: {
        label: ReactNode;
        value: string;
    }[];

    // A draft-js DraftInlineStyle instance
    // https://facebook.github.io/draft-js/docs/api-reference-editor-state.html#getcurrentinlinestyle

    currentStyle: any;
    linkIsSelected: boolean;
};

class StyleButtonGroup extends Component<Props> {
    render() {
        const { currentStyle, linkIsSelected, styles, onToggle } = this.props;

        const isActive = (currentStyle: any, value: string) => {
            if (value === LINK) {
                return linkIsSelected;
            }

            if (typeof currentStyle.has === "function") {
                return currentStyle.has(value);
            }

            return Boolean(currentStyle.value);
        };

        return (
            <Box
                display="flex"
                bg="white"
                sx={{
                    "& .icon-link": {
                        width: "15px",
                        height: "15px",
                        fill: "gray.800",
                        display: "inline-block",
                        transform: "translateY(3px)",
                    },
                }}
                justifyContent="space-evenly"
                fontSize="14px"
                p="6px 0"
                borderBottom="solid 1px"
                borderColor="gray.100"
                // className="rich-text-editor__controls"
            >
                {styles.map(({ label, value }) => (
                    <StyleButton
                        key={value}
                        active={isActive(currentStyle, value)}
                        label={label}
                        onToggle={onToggle}
                        value={value}
                    />
                ))}
            </Box>
        );
    }
}

export default StyleButtonGroup;
