import type { ChangeEvent, FC, ReactNode } from "react";
import { useState } from "react";
import type {
    ClearIndicatorProps,
    DropdownIndicatorProps,
    GroupBase,
    SelectComponentsConfig,
} from "react-select";
import CreatableSelect from "react-select/creatable";

import { fromPairs, pluck, toPairs, uniq } from "ramda";

import {
    Box,
    Button,
    Center,
    Checkbox,
    IconButton,
    Step,
    StepIcon,
    StepIndicator,
    StepNumber,
    Stepper,
    StepSeparator,
    StepStatus,
    StepTitle,
    Tag,
    Text,
    Tooltip,
} from "@chakra-ui/react";
import { useNavigate } from "@tanstack/react-router";

import { useOrgContext } from "@app/contexts/OrgContext/useOrgContext.ts";
import type {
    MeasurementGroupStatusType,
    MeasurementMethod as MeasurementMethodType,
} from "@app/domain";
import { MeasurementGroupStatus, MeasurementMethod } from "@app/domain";
import { MeasurementTask } from "@app/domain/api/measurementTask.ts";
import { Request } from "@app/domain/api/request.ts";
import type { SampleModel } from "@app/domain/api/sample.ts";
import { routes } from "@app/Routes/routes.ts";
import { GroupStatus } from "@app-components/measurementGroup/GroupStatus/GroupStatus.tsx";

import {
    defaultFilterOption,
    isCreatableOption,
} from "@mt-components/Input/Select/helpers.ts";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { Icon } from "@mt-design/icons.tsx";

import type { CSSObject } from "@emotion/react";
import { messages } from "@mt-assets/messages.ts";

type Props = {
    isOpen: boolean;
    samples: SampleModel[];
    onCancel: () => void;
    onClose: () => void;
};
type FormShape = {
    [k in MeasurementMethodType]: boolean;
} & {
    name: string;
};

const defaultValues = {
    [MeasurementMethod.Hrxrpd]: false,
    [MeasurementMethod.Tspdf]: false,
    [MeasurementMethod.Saxs]: false,
};

const styles = {
    control: (base: CSSObject): CSSObject => ({
        ...base,
        zIndex: 9999,
        borderRadius: "6px",
    }),
};

const ClearIndicator = (props: ClearIndicatorProps<SelectOption>) => {
    const {
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <Box
            ref={ref}
            {...restInnerProps}
            color="gray.800"
            cursor="pointer"
            tabIndex={0}
            p={0}
        >
            <IconButton
                p={0}
                variant="ghost"
                aria-label="open menu"
                icon={<Icon.Close size="18px" color="currentColor" />}
            />
        </Box>
    );
};

const DropDownIndicator = ({
    innerProps,
}: DropdownIndicatorProps<SelectOption>) => {
    return (
        <Center
            w="36px"
            h="36px"
            {...innerProps}
            color="gray.800"
            cursor="pointer"
            tabIndex={0}
            p={0}
        >
            <IconButton
                p={0}
                variant="ghost"
                aria-label="open menu"
                icon={<Icon.ChevronDown size="18px" color="currentColor" />}
            />
        </Center>
    );
};

type SelectedOption = {
    label: ReactNode;
    value: string;
    rawLabel: string;
    status?: MeasurementGroupStatusType;
};

type SelectOption = {
    label: string | ReactNode;
    rawLabel: string;
    value: string;
    status?: MeasurementGroupStatusType;
};

const components: SelectComponentsConfig<
    SelectOption,
    false,
    GroupBase<SelectOption>
> = {
    ClearIndicator,
    DropdownIndicator: DropDownIndicator,
};

type State = { [k in string]: FormShape };

const placeholderId = "placeholder-id";

export const MeasurementTaskBatchModal: FC<Props> = ({
    isOpen,
    onClose,
    samples = [],
}) => {
    const { currentOrg } = useOrgContext();
    const snack = useSnackbar();
    const navigate = useNavigate();

    const measurementRequestQuery = Request.useGetAllB({
        path: {
            organizationId: currentOrg,
        },
        query: {
            filter: {
                status: MeasurementGroupStatus.Draft,
            },
        },
    });

    const createTasks = MeasurementTask.useCreateBatch();
    const createRequestAndTasks = Request.useCreateQuick();

    const initialState: State = samples.reduce((accumulator, currentValue) => {
        return {
            ...accumulator,
            [currentValue.id]: {
                name: currentValue.name,
                ...defaultValues,
            },
        };
    }, {});

    const close = () => {
        setFormState(initialState);
        onClose();
    };

    const [activeStep, setActiveStep] = useState(0);
    const [value, setValue] = useState<SelectedOption | null>(null);

    const [formState, setFormState] = useState<State>(initialState);

    const submit = async () => {
        if (!value) {
            return;
        }

        const tasks: { method: MeasurementMethodType; sampleId: string }[] = [];
        toPairs(formState).forEach(([id, form]) => {
            const methods = [
                MeasurementMethod.Hrxrpd,
                MeasurementMethod.Saxs,
                MeasurementMethod.Tspdf,
            ];
            methods.forEach((method) => {
                if (form[method]) {
                    tasks.push({
                        method,
                        sampleId: id,
                    });
                }
            });
        });

        const uniqueSamples = uniq(pluck("sampleId", tasks));

        if (value.value === placeholderId) {
            const r = await createRequestAndTasks.mutateAsync({
                requestName: value.rawLabel,
                tasks: tasks,
                organizationId: currentOrg,
            });

            if (r.response.ok && r.data.id) {
                snack.success({
                    title: "Success",
                    description: `Measurement request created with ${uniqueSamples.length} ${uniqueSamples.length === 1 ? "sample" : "samples"} `,
                    duration: 15000,
                    isClosable: true,
                    action: (
                        <Button
                            variant="subtle"
                            colorScheme="white"
                            onClick={() =>
                                navigate({
                                    to: routes.user.measurementGroupDetail.url,
                                    params: {
                                        orderId: r.data.id,
                                        orgId: currentOrg,
                                    },
                                })
                            }
                        >
                            Go to request
                        </Button>
                    ),
                });
                close();
                return;
            }

            snack.error({
                title: "An error occurred",
                description: "Request could not be created",
            });
            close();
            return;
        }

        await createTasks
            .mutateAsync({
                requestId: value.value,
                organizationId: currentOrg,
                tasks,
            })
            .then((r) => {
                if (r.ok) {
                    snack.success({
                        title: "Samples added to measurement request",
                        duration: 10000,
                        isClosable: true,
                        action: (
                            <Button
                                variant="subtle"
                                colorScheme="white"
                                onClick={() =>
                                    navigate({
                                        to: routes.user.measurementGroupDetail
                                            .url,
                                        params: {
                                            orderId: value.value,
                                            orgId: currentOrg,
                                        },
                                    })
                                }
                            >
                                Go to request
                            </Button>
                        ),
                    });
                    close();
                    return;
                }

                snack.error({
                    title: "An error occurred",
                    description: "Samples could not be added to request",
                });
            });
    };

    const onChangeMethod =
        (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
            const method = e.target.value as MeasurementMethodType;
            setFormState((prev) => {
                return {
                    ...prev,
                    [id]: {
                        ...prev[id],
                        [method]: e.target.checked,
                    },
                };
            });
        };

    const entries = Object.entries(formState);

    const onSetMethodForAll = (
        value: boolean,
        method: MeasurementMethodType,
    ) => {
        setFormState((prev) => {
            const entries = Object.entries(prev);

            const newEntries = entries.map(
                ([id, formData]): [string, FormShape] => {
                    return [
                        id,
                        {
                            ...formData,
                            [method]: value,
                        },
                    ];
                },
            );

            return fromPairs(newEntries);
        });
    };

    const onChangeAllCheckbox =
        (method: MeasurementMethodType) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked;
            onSetMethodForAll(value, method);
        };

    const areAllSelectedForTSPDF = entries.every(([, form]) => form.tspdf);
    const areAllSelectedForXRD = entries.every(([, form]) => form.hrxrpd);
    const areAllSelectedForSAXS = entries.every(([, form]) => form.saxs);

    const areSomeSelectedForXRD = entries.some(([, form]) => form.hrxrpd);
    const areSomeSelectedForPDF = entries.some(([, form]) => form.tspdf);
    const areSomeSelectedForSAXS = entries.some(([, form]) => form.saxs);

    const onSetRequestCreationPayload = async (value: string) => {
        setValue({
            label: value,
            rawLabel: value,
            value: placeholderId,
        });
    };

    const options = (
        measurementRequestQuery.data?.items.filter(
            (group) => group.status === MeasurementGroupStatus.Draft,
        ) || []
    ).map((group) => ({
        label: group.name,
        rawLabel: group.name,
        value: group.id,
        status: group.status,
    }));

    const steps = [{ title: "Select methods" }, { title: "Prepare request" }];

    const areAnyMethodsSelected =
        areSomeSelectedForPDF ||
        areSomeSelectedForXRD ||
        areSomeSelectedForSAXS;
    return (
        <Modal
            isOpen={isOpen}
            onClose={close}
            onOverlayClick={close}
            header="Assign samples to measurement request"
            buttonLeft={
                activeStep === 1 && (
                    <Button colorScheme="gray" onClick={() => setActiveStep(0)}>
                        Previous
                    </Button>
                )
            }
            buttonRight={
                <>
                    {activeStep === 0 && (
                        <Button
                            isDisabled={!areAnyMethodsSelected}
                            colorScheme="gray"
                            onClick={() => setActiveStep(1)}
                        >
                            Continue
                        </Button>
                    )}
                    {activeStep === 1 && (
                        <Button
                            isDisabled={!areAnyMethodsSelected || !value}
                            colorScheme="blue"
                            onClick={() => void submit()}
                        >
                            Confirm
                        </Button>
                    )}
                </>
            }
        >
            <Box
                display="flex"
                minHeight="340px"
                flexDirection="column"
                fontSize="14px"
                py="12px"
                px="2px"
                overflow="visible"
                zIndex={30}
            >
                <Stepper colorScheme="blue" index={activeStep}>
                    {steps.map((step, index) => (
                        <Step key={index}>
                            <StepIndicator>
                                <StepStatus
                                    complete={<StepIcon />}
                                    incomplete={<StepNumber />}
                                    active={<StepNumber />}
                                />
                            </StepIndicator>

                            <Box flexShrink="0">
                                <StepTitle>{step.title}</StepTitle>
                            </Box>

                            <StepSeparator />
                        </Step>
                    ))}
                </Stepper>
                <Box my="24px">
                    {activeStep === 0 && (
                        <Box>
                            <Text
                                fontSize="14px"
                                display="block"
                                flex=" 0 0 48px"
                                overflow="hidden"
                            >
                                Please select the samples and the corresponding
                                methods you would like to use
                            </Text>

                            <Box
                                mt="18px"
                                display="flex"
                                flex="0 0 74px"
                                justifyContent="space-between"
                                // pb="20px"
                                overflow="hidden"
                                borderBottom="solid 1px lightgray"
                            >
                                <Center justifyContent="unset" flex="0 0 120px">
                                    <Text display="block" fontSize="14px">
                                        Sample-ID
                                    </Text>
                                </Center>
                                <Center flex="0 0 120px">
                                    <Checkbox
                                        colorScheme="green"
                                        size="lg"
                                        isIndeterminate={
                                            areSomeSelectedForXRD &&
                                            !areAllSelectedForXRD
                                        }
                                        isChecked={areAllSelectedForXRD}
                                        value={MeasurementMethod.Hrxrpd}
                                        onChange={onChangeAllCheckbox(
                                            MeasurementMethod.Hrxrpd,
                                        )}
                                    />

                                    <Button
                                        fontSize="14px"
                                        variant="ghost"
                                        textDecor="underline"
                                        colorScheme="secondary"
                                        onClick={() =>
                                            onSetMethodForAll(
                                                !areAllSelectedForXRD,
                                                MeasurementMethod.Hrxrpd,
                                            )
                                        }
                                    >
                                        HR-XRPD
                                    </Button>
                                    <Tooltip label={messages.xrdExplainer}>
                                        <Icon.Info size="14px" />
                                    </Tooltip>
                                </Center>
                                <Center flex="0 0 120px">
                                    <Checkbox
                                        colorScheme="green"
                                        size="lg"
                                        isIndeterminate={
                                            areSomeSelectedForPDF &&
                                            !areAllSelectedForTSPDF
                                        }
                                        isChecked={areAllSelectedForTSPDF}
                                        onChange={onChangeAllCheckbox(
                                            MeasurementMethod.Tspdf,
                                        )}
                                        value={MeasurementMethod.Tspdf}
                                    />

                                    <Button
                                        fontSize="14px"
                                        colorScheme="secondary"
                                        variant="ghost"
                                        textDecor="underline"
                                        onClick={() =>
                                            onSetMethodForAll(
                                                !areAllSelectedForTSPDF,
                                                MeasurementMethod.Tspdf,
                                            )
                                        }
                                    >
                                        TS-PDF
                                    </Button>
                                    <Tooltip label={messages.tsPdfExplainer}>
                                        <Icon.Info size="14px" />
                                    </Tooltip>
                                </Center>
                                <Center flex="0 0 120px">
                                    <Checkbox
                                        colorScheme="green"
                                        size="lg"
                                        value={MeasurementMethod.Saxs}
                                        isIndeterminate={
                                            areSomeSelectedForSAXS &&
                                            !areAllSelectedForSAXS
                                        }
                                        isChecked={areAllSelectedForSAXS}
                                        onChange={onChangeAllCheckbox(
                                            MeasurementMethod.Saxs,
                                        )}
                                    />

                                    <Button
                                        fontSize="14px"
                                        colorScheme="secondary"
                                        variant="ghost"
                                        textDecor="underline"
                                        onClick={() =>
                                            onSetMethodForAll(
                                                !areAllSelectedForSAXS,
                                                MeasurementMethod.Saxs,
                                            )
                                        }
                                    >
                                        SAXS
                                    </Button>
                                    <Tooltip label={messages.saxsExplainer}>
                                        <Icon.Info size="14px" />
                                    </Tooltip>
                                </Center>
                            </Box>
                            <Box
                                overflow="hidden"
                                display="flex"
                                flexDir="column"
                                height="100%"
                            >
                                {entries.map(([id, form], idx) => {
                                    return (
                                        <Box
                                            key={id}
                                            justifyContent="space-between"
                                            display="flex"
                                            height="100%"
                                            flex="0 0 53px"
                                            alignItems="center"
                                            borderBottom={
                                                entries.length - 1 !== idx
                                                    ? "1px solid lightgray"
                                                    : undefined
                                            }
                                        >
                                            <Box
                                                flex=" 0 0 120px"
                                                overflow="hidden"
                                                whiteSpace="nowrap"
                                            >
                                                <Text>{form.name}</Text>
                                            </Box>
                                            <Center flex=" 0 0 120px">
                                                <Checkbox
                                                    colorScheme="green"
                                                    onChange={onChangeMethod(
                                                        id,
                                                    )}
                                                    size="lg"
                                                    value={
                                                        MeasurementMethod.Hrxrpd
                                                    }
                                                    isChecked={
                                                        form[
                                                            MeasurementMethod
                                                                .Hrxrpd
                                                        ]
                                                    }
                                                />
                                            </Center>
                                            <Center flex=" 0 0 120px">
                                                <Checkbox
                                                    colorScheme="green"
                                                    onChange={onChangeMethod(
                                                        id,
                                                    )}
                                                    size="lg"
                                                    value={
                                                        MeasurementMethod.Tspdf
                                                    }
                                                    isChecked={
                                                        form[
                                                            MeasurementMethod
                                                                .Tspdf
                                                        ]
                                                    }
                                                />
                                            </Center>
                                            <Center flex=" 0 0 120px">
                                                <Checkbox
                                                    colorScheme="green"
                                                    onChange={onChangeMethod(
                                                        id,
                                                    )}
                                                    size="lg"
                                                    value={
                                                        MeasurementMethod.Saxs
                                                    }
                                                    isChecked={
                                                        form[
                                                            MeasurementMethod
                                                                .Saxs
                                                        ]
                                                    }
                                                />
                                            </Center>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Box>
                    )}

                    {activeStep === 1 && (
                        <Box minH="4px" zIndex="5000">
                            <Text
                                fontSize="14px"
                                display="block"
                                flex=" 0 0 28px"
                                overflow="hidden"
                                pb="8px"
                                pl="2px"
                            >
                                Assign the samples to an existing request or
                                create a new
                            </Text>
                            <CreatableSelect<SelectOption>
                                isClearable
                                maxMenuHeight={160 + samples.length * 50}
                                allowCreateWhileLoading={false}
                                placeholder="Select a request or create a new one by typing a name"
                                filterOption={defaultFilterOption}
                                formatCreateLabel={(inputValue) => (
                                    <>
                                        Create a new measurement request:{" "}
                                        <Text
                                            textAlign="left"
                                            fontWeight="700"
                                            as="span"
                                        >
                                            {inputValue}
                                        </Text>
                                    </>
                                )}
                                getOptionValue={(opt) => opt.value}
                                // @ts-ignore
                                getOptionLabel={(opt) => {
                                    // is not selected from the menu yet
                                    if (isCreatableOption(opt)) {
                                        return (
                                            <Box
                                                display="flex"
                                                justifyContent="space-between"
                                            >
                                                Create new request :
                                                <Text fontWeight="700">
                                                    {opt.value}
                                                </Text>{" "}
                                                <Tag color="purple">new</Tag>
                                            </Box>
                                        );
                                    }

                                    // contains existing requests and the selected, but not yet created request (no status)
                                    return (
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                        >
                                            {opt.rawLabel}{" "}
                                            {opt.status ? (
                                                <GroupStatus
                                                    status={opt.status}
                                                />
                                            ) : (
                                                <Tag color="purple">new</Tag>
                                            )}
                                        </Box>
                                    );
                                }}
                                onChange={(newValue) => {
                                    if (!newValue) {
                                        setValue(null);
                                        return;
                                    }
                                    setValue({
                                        label: newValue.label,
                                        rawLabel: newValue.rawLabel,
                                        value: newValue.value,
                                        status: newValue.status,
                                    });
                                }}
                                onCreateOption={onSetRequestCreationPayload}
                                options={options}
                                value={value}
                                menuPosition="fixed"
                                styles={styles}
                                components={components}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};
