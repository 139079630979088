// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { FC } from "react";

import PropTypes from "prop-types";

import {
    AxesCreator,
    DataSelector,
    TraceAccordion,
    TraceSelector,
    TraceTypeSection,
    ErrorBars,
    Text,
} from "../components";

type Props = {
    group: string;
    name: string;
};

const GraphCreatePanel: FC<Props> = (props, { setPanel }) => {
    return (
        <TraceAccordion
            canAdd
            traceFilterCondition={(t) =>
                !(
                    t.transforms &&
                    t.transforms.some((tr) =>
                        ["fit", "moving-average"].includes(tr.type),
                    )
                )
            }
            canReorder
        >
            <Text label="Name" attr="name" richTextOnly />
            <TraceSelector label="Type" attr="type" show />
            <DataSelector label="Values" attr="values" />
            <DataSelector label="Labels" attr="labels" />
            <DataSelector label="Parents" attr="parents" />

            <DataSelector
                label={{
                    "histogram2d": "X Values",
                    "histogram": "X Values",
                    "*": "X",
                }}
                attr="x"
            />
            <DataSelector
                label={{
                    "histogram2d": "Y Values",
                    "histogram": "Y Values",
                    "*": "Y",
                }}
                attr="y"
            />

            <TraceTypeSection
                name={"Error Bars X"}
                traceTypes={["scatter", "scattergl", "scatter3d", "bar"]}
                mode="trace"
            >
                <ErrorBars attr="error_x" />
            </TraceTypeSection>
            <TraceTypeSection
                name={"Error Bars Y"}
                traceTypes={["scatter", "scattergl", "scatter3d", "bar"]}
                mode="trace"
            >
                <ErrorBars attr="error_y" />
            </TraceTypeSection>
            <TraceTypeSection
                name={"Error Bars Z"}
                traceTypes={["scatter3d"]}
                mode="trace"
            >
                <ErrorBars attr="error_z" />
            </TraceTypeSection>
            <AxesCreator attr="fake_attr" />
        </TraceAccordion>
    );
};

export default GraphCreatePanel;

GraphCreatePanel.contextTypes = {
    setPanel: PropTypes.func,
};
