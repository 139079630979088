import { Tag } from "@chakra-ui/react";

type ColorIndicatorProps<TValue extends string> = {
    config: {
        [k in TValue]: {
            color: string;
            label: string;
            value: boolean;
        };
    };
    value: TValue;
};

export const YesNoIndicator = <TValue extends string>({
    config,
    value,
}: ColorIndicatorProps<TValue>) => {
    return (
        <Tag variant="solid" colorScheme={config[value].color}>
            <>{config[value].label}</>
        </Tag>
    );
};
