import { rootRouteWithContext } from "@tanstack/react-router";

import type { RouterContext } from "@app/Routes/beforeLoad.tsx";
import { preventNavToLoginOnceAuthenticated } from "@app/Routes/beforeLoad.tsx";
import { PublicRootComponent } from "@app/Routes/components.tsx";

export const publicRoot = rootRouteWithContext<RouterContext>()({
    beforeLoad: preventNavToLoginOnceAuthenticated,
    component: PublicRootComponent,
});
