import type { ChangeEvent, FC } from "react";
import { useMemo, useState } from "react";

import { toPairs } from "ramda";

import {
    Box,
    Button,
    Checkbox,
    Stack,
    Text,
    useDisclosure,
} from "@chakra-ui/react";

import type { MeasurementMethod as MeasurementMethodType } from "@app/domain";
import { MeasurementMethod } from "@app/domain";
import type { MeasurementTaskModel } from "@app/domain/api/measurementTask.ts";
import type {
    CreateSamplePayload,
    SampleModel,
} from "@app/domain/api/sample.ts";
import { Sample } from "@app/domain/api/sample.ts";
import { SampleModal } from "@app-components/modals/sample/Sample.modal.tsx";

import { FieldLabel } from "@mt-components/Input/FieldLabel.tsx";
import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";
import { Accordion } from "@mt-components/Layout/Accordion/Accordion.tsx";
import { Divider } from "@mt-components/Layout/Divider.tsx";
import { Modal } from "@mt-components/Modal/Modal.tsx";

import { useSnackbar } from "@mt-hooks/useSnackbar.tsx";

import { messages } from "@mt-assets/messages.ts";

type Props = {
    isOpen: boolean;
    existingMeasurementTasks: MeasurementTaskModel[];
    samples: SampleModel[];
    onSubmit: (params: {
        sampleId: string;
        method: MeasurementMethodType;
    }) => void;
    onCancel: () => void;
    onClose: () => void;
    orgId: string;
};
type FormShape = {
    [MeasurementMethodType.Tspdf]: boolean;
    [MeasurementMethodType.Hrxrpd]: boolean;
    [MeasurementMethodType.Saxs]: boolean;
};

const initialState = {
    [MeasurementMethod.Hrxrpd]: false,
    [MeasurementMethod.Tspdf]: false,
    [MeasurementMethod.Saxs]: false,
};

export const MeasurementTaskModal: FC<Props> = ({
    onSubmit,
    onCancel,
    isOpen,
    onClose,
    existingMeasurementTasks = [],
    samples = [],
    orgId,
}) => {
    const snack = useSnackbar();
    const { mutateAsync: createSample } = Sample.useCreate();

    const sampleModal = useDisclosure();
    const [sampleId, setSampleId] = useState<string | undefined>();
    const [formState, setFormState] = useState<FormShape>(initialState);

    const combinationAlreadyExists = useMemo(
        () =>
            existingMeasurementTasks.find((t) => {
                return (
                    t.sampleId === sampleId &&
                    t.method !== MeasurementMethod.Saxs &&
                    formState[t.method]
                );
            }),
        [existingMeasurementTasks, formState, sampleId],
    );

    const isInvalid =
        !sampleId ||
        !Object.values(formState).some((v) => v) ||
        Boolean(combinationAlreadyExists);

    const submit = async () => {
        if (isInvalid) {
            return;
        }

        const toCreate = toPairs(formState).filter((p) => p[1]);

        const promisses = toCreate.map(([method]) => {
            return onSubmit({
                sampleId: sampleId,
                method: method,
            });
        });

        await Promise.all(promisses)
            .then(() => {
                setFormState(initialState);
                onClose();
            })
            .catch(() => {
                snack.error({
                    title: "Error",
                    description: "Failed to create measurement task",
                });
            });
        setSampleId(undefined);
        setFormState(initialState);
    };

    const onChangeMethod = (e: ChangeEvent<HTMLInputElement>) => {
        const method = e.target.value as MeasurementMethodType;
        setFormState((prev) => ({
            ...prev,
            [method]: e.target.checked,
        }));
    };

    const hasSamples = Boolean(samples.length);

    const onCreateSample = (data: CreateSamplePayload) => {
        void createSample({
            params: {
                path: {
                    organizationId: orgId,
                },
            },
            body: data,
        });
        sampleModal.onClose();
    };

    const onSelectAll = () => {
        setFormState({
            [MeasurementMethod.Hrxrpd]: true,
            [MeasurementMethod.Tspdf]: true,
            [MeasurementMethod.Saxs]: true,
        });
    };

    const onDeselectAll = () => {
        setFormState({
            [MeasurementMethod.Hrxrpd]: false,
            [MeasurementMethod.Tspdf]: false,
            [MeasurementMethod.Saxs]: false,
        });
    };

    const close = () => {
        setFormState(initialState);
        setSampleId(undefined);
        onCancel();
    };

    const allSelected = Object.values(formState).every((f) => f);

    const s = samples
        .map((s) => ({
            value: s.id,
            rawLabel: `${s.name} (${s.composition})`,
            label: `${s.name} (${s.composition})`,
        }))
        .find((o) => o.value === sampleId);

    return (
        <>
            {sampleModal.isOpen && (
                <SampleModal
                    isOpen={sampleModal.isOpen}
                    onSubmit={onCreateSample}
                    onClose={sampleModal.onClose}
                />
            )}
            <Modal
                isOpen={isOpen && !sampleModal.isOpen}
                onClose={close}
                onOverlayClick={close}
                header="Measurement specification"
                buttonLeft={
                    <Button colorScheme="gray" onClick={close}>
                        Cancel
                    </Button>
                }
                buttonRight={
                    <Button
                        isDisabled={isInvalid}
                        colorScheme="blue"
                        onClick={() => void submit()}
                    >
                        Confirm
                    </Button>
                }
            >
                <Box>
                    <Text>
                        Please select the sample and the corresponding methods
                        you would like to use.
                    </Text>
                    <Box w="100%">
                        <Box>
                            {!hasSamples ? (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    width="100%"
                                    // border="solid"
                                >
                                    <Text display="inline-block" my="24px">
                                        As you don`&apos;t have any samples yet,
                                        let`&apos;s create one first.
                                    </Text>
                                    <Button
                                        mx="auto"
                                        colorScheme="blue"
                                        onClick={sampleModal.onToggle}
                                    >
                                        Create sample
                                    </Button>
                                </Box>
                            ) : (
                                <Box mt="20px" mb="12px" zIndex={30}>
                                    <FieldLabel mb="4px">Sample</FieldLabel>
                                    <SingleSelect
                                        control={{
                                            width: "100%",
                                        }}
                                        placeholder="Select a sample"
                                        value={s}
                                        onChange={(value) =>
                                            setSampleId(
                                                value?.value ?? undefined,
                                            )
                                        }
                                        options={samples.map((s) => ({
                                            value: s.id,
                                            label: `${s.name} (${s.composition})`,
                                            rawLabel: `${s.name} (${s.composition})`,
                                        }))}
                                    />
                                    {combinationAlreadyExists && (
                                        <Text fontSize="md" color="mt.500">
                                            This sample and method combination
                                            already exists
                                        </Text>
                                    )}
                                </Box>
                            )}
                        </Box>
                        <Box mt="40px">
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <FieldLabel mb="12px">Methods</FieldLabel>
                                <Button
                                    isDisabled={!hasSamples}
                                    mb="12px"
                                    variant="ghost"
                                    textDecoration="underline"
                                    colorScheme="gray"
                                    onClick={
                                        allSelected
                                            ? onDeselectAll
                                            : onSelectAll
                                    }
                                >
                                    {allSelected
                                        ? "Deselect All"
                                        : "Select all"}
                                </Button>
                            </Box>
                            <Stack>
                                <Accordion
                                    hasBottomBorder={false}
                                    title={
                                        <Checkbox
                                            isDisabled={!hasSamples}
                                            onChange={onChangeMethod}
                                            size="lg"
                                            value={MeasurementMethod.Hrxrpd}
                                            isChecked={
                                                formState[
                                                    MeasurementMethod.Hrxrpd
                                                ]
                                            }
                                        >
                                            High-Resolution X-Ray Powder
                                            Diffraction (HR-XRPD)
                                        </Checkbox>
                                    }
                                    body={
                                        <Text
                                            mt="8px"
                                            pl="29px"
                                            pr="15px"
                                            fontSize="md"
                                            display="block"
                                            color="gray.600"
                                        >
                                            {messages.xrdExplainer}
                                        </Text>
                                    }
                                />
                                <Divider my="2px" />

                                <Accordion
                                    hasBottomBorder={false}
                                    title={
                                        <Checkbox
                                            id="tspdf_task"
                                            isDisabled={!hasSamples}
                                            isChecked={
                                                formState[
                                                    MeasurementMethod.Tspdf
                                                ]
                                            }
                                            onChange={onChangeMethod}
                                            size="lg"
                                            value={MeasurementMethod.Tspdf}
                                        >
                                            Total Scattering Pair Distribution
                                            Function (TS-PDF)
                                        </Checkbox>
                                    }
                                    body={
                                        <Text
                                            mt="8px"
                                            pl="29px"
                                            pr="15px"
                                            fontSize="md"
                                            display="block"
                                            color="gray.600"
                                        >
                                            {messages.tsPdfExplainer}
                                        </Text>
                                    }
                                />

                                <Divider my="2px" />
                                <Accordion
                                    hasBottomBorder={false}
                                    title={
                                        <Checkbox
                                            id="saxs_task"
                                            isDisabled={!hasSamples}
                                            isChecked={
                                                formState[
                                                    MeasurementMethod.Saxs
                                                ]
                                            }
                                            onChange={onChangeMethod}
                                            size="lg"
                                            value={MeasurementMethod.Saxs}
                                        >
                                            Small Angle Scattering (SAXS)
                                        </Checkbox>
                                    }
                                    body={
                                        <Text
                                            mt="8px"
                                            pl="29px"
                                            pr="15px"
                                            fontSize="md"
                                            display="block"
                                            color="gray.600"
                                        >
                                            {messages.saxsExplainer}
                                        </Text>
                                    }
                                />
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};
