import type { FC } from "react";

import { Center, VStack } from "@chakra-ui/react";

import type { MeasurementGroupModel } from "@app/domain";
import { MeasurementGroupStatus } from "@app/domain";
import type { MeasurementTaskModel } from "@app/domain/api/measurementTask.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";
import { MeasurementTasksEmptyState } from "@app-components/measurementTask/MeasurementTasksEmptyState.tsx";
import { SampleTaskTable } from "@app-components/measurementTask/SampleTask.table.tsx";

type Props = {
    measurementTasks: MeasurementTaskModel[];
    sampleTasks: SampleTasksModel[];
    onOpenSampleModal: () => void;
    measurementGroup: MeasurementGroupModel;
    sampleSelection?: string[];
    onSelectSamples: (ids: string[]) => void;
};

const tableStyle = {
    overflowY: "scroll" as const,
};

export const MeasurementTasks: FC<Props> = ({
    sampleTasks,
    sampleSelection,
    onSelectSamples,
    measurementGroup,
    measurementTasks,
    onOpenSampleModal,
}) => {
    const isDisabled = measurementGroup.status !== MeasurementGroupStatus.Draft;
    return measurementTasks.length === 0 ? (
        <Center
            height="100%"
            minWidth="250px"
            overflow="hidden"
            overflowX="auto"
            py="8"
        >
            <MeasurementTasksEmptyState
                primaryDisabled={isDisabled}
                body="Start by adding a samples to the measurement request"
                buttonLabel="Select sample"
                onClick={onOpenSampleModal}
            />
        </Center>
    ) : (
        <VStack alignItems="stretch" h="100%">
            <SampleTaskTable
                tableStyle={tableStyle}
                onSelectSample={onSelectSamples}
                selectedSampleTaskIds={sampleSelection}
                sampleTasks={sampleTasks}
            />
        </VStack>
    );
};

MeasurementTasks.displayName = "MeasurementTasks";
