import type { FC } from "react";

import type { LightboxExternalProps } from "yet-another-react-lightbox";
import { Lightbox as YetAnotherLightBox } from "yet-another-react-lightbox";

import "yet-another-react-lightbox/styles.css";

export const Lightbox: FC<LightboxExternalProps> = (props) => {
    return <YetAnotherLightBox {...props} />;
};
