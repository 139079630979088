// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, ReactNode } from "react";
import { useState } from "react";
import type { Props as ReactSelectProps } from "react-select";

import type { PlotData } from "plotly.js";
import type Plotly from "plotly.js";

import { Box } from "@chakra-ui/react";

import type {
    ChartHelp,
    CustomConfig,
    DataSourceOption,
    DataSources,
    Figure,
    FontOption,
    GraphDiv,
    TraceTypesConfig,
    UpdateFn,
} from "@chart-editor/pages/plot/types.tsx";

import { Charty } from "@chart-components/Charty.tsx";
import CustomEditor from "./CustomEditor.tsx";
import EditorControls from "./EditorControls.tsx";
import { GenericErrorUser } from "@app-components/shell/errors/GenericError/GenericError.user.tsx";

type Props = {
    debug?: boolean;
    hideControls?: boolean;
    glByDefault?: boolean;
    useResizeHandler?: boolean;
    advancedTraceTypeSelector?: boolean;
    showFieldTooltips?: boolean;
    //
    divId?: string;
    dataSourceOptions: DataSourceOption[]; // @todo find correct type
    customConfig?: CustomConfig; // @todo find correct type
    makeDefaultTrace?: () => {
        type: Plotly.PlotType;
        mode: Plotly.PlotData["mode"];
    };
    layout: Partial<Plotly.Layout>;
    data: Partial<PlotData>[];
    plotly: typeof Plotly; //@todo maybe wrong
    frames?: Plotly.Frame[];
    fontOptions?: FontOption[];
    children?: ReactNode;
    config?: Partial<Plotly.Config>;
    chartHelp?: ChartHelp;
    dataSources?: DataSources;
    onUpdate: UpdateFn;
    traceTypesConfig: TraceTypesConfig;
    srcConverters?: {
        toSrc: () => {};
        fromSrc: () => {};
    };
    dataSourceComponents?: ReactSelectProps["components"];
};

const PlotlyEditor: FC<Props> = ({
    advancedTraceTypeSelector,
    chartHelp,
    config,
    customConfig,
    data,
    dataSourceOptions,
    dataSources,
    debug,
    divId,
    fontOptions = [],
    frames,
    glByDefault,
    hideControls = false,
    layout,
    makeDefaultTrace,
    onUpdate,
    plotly,
    showFieldTooltips = false,
    traceTypesConfig,
    useResizeHandler,
}) => {
    const [state, setState] = useState<{
        graphDiv?: GraphDiv;
        hasError: boolean;
    }>({
        graphDiv: undefined,
        hasError: false,
    });

    const onError = () => {
        setState({
            hasError: true,
        });
    };

    const handleRender = (_fig: Readonly<Figure>, graphDiv: GraphDiv) => {
        setState({ graphDiv, hasError: false });
    };

    if (state.hasError) {
        return <GenericErrorUser />;
    }

    return (
        <Box
            display="flex"
            flexGrow={1}
            height="100%"
            maxHeight="100%"
            width="100%"
        >
            {!hideControls && state.graphDiv && (
                <EditorControls
                    graphDiv={state.graphDiv}
                    dataSources={dataSources}
                    dataSourceOptions={dataSourceOptions}
                    plotly={plotly}
                    onUpdate={onUpdate}
                    advancedTraceTypeSelector={advancedTraceTypeSelector}
                    traceTypesConfig={traceTypesConfig}
                    showFieldTooltips={showFieldTooltips}
                    makeDefaultTrace={makeDefaultTrace}
                    glByDefault={glByDefault}
                    mapBoxAccess={Boolean(config && config.mapboxAccessToken)}
                    fontOptions={fontOptions}
                    chartHelp={chartHelp}
                    customConfig={customConfig}
                >
                    <CustomEditor />
                </EditorControls>
            )}
            <Box
                maxW="100%"
                height="100%"
                maxHeight="100%"
                overflow="auto"
                flexGrow="1"
            >
                <Charty
                    data={data}
                    layout={layout}
                    frames={frames}
                    config={config}
                    useResizeHandler={useResizeHandler}
                    debug={debug}
                    onInitialized={handleRender}
                    onUpdate={handleRender}
                    style={{ width: "100%", height: "100%" }}
                    onError={onError}
                />
            </Box>
        </Box>
    );
};

export default PlotlyEditor;
