import { FC, PropsWithChildren, ComponentType } from "react";

type Props = {
    messagePrimary: string;
    messageSecondary: string;
    icon: ComponentType;
};

export const FoldEmpty: FC<PropsWithChildren<Props>> = (props) => {
    const { children, icon: Icon, messagePrimary, messageSecondary } = props;

    return (
        <div className="fold__content__empty">
            {Icon ? (
                <div className="fold__content__empty__icon">
                    <Icon />
                </div>
            ) : null}
            {messagePrimary ? (
                <div className="fold__content__empty__message__primary">
                    {messagePrimary}
                </div>
            ) : null}
            {messageSecondary ? (
                <div className="fold__content__empty__message__secondary">
                    {messageSecondary}
                </div>
            ) : null}
            {children ? children : null}
        </div>
    );
};

export default FoldEmpty;
