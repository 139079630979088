import type { FC } from "react";

import type { CheckboxProps, ResponsiveValue } from "@chakra-ui/react";
import {
    Checkbox,
    FormControl,
    FormHelperText,
    forwardRef,
} from "@chakra-ui/react";

import type { InputFieldProps } from "@mt-components/Input/InputField.tsx";

export const CheckboxField: FC<
    CheckboxProps &
        InputFieldProps<HTMLInputElement> & {
            my?: ResponsiveValue<string>;
            mr?: ResponsiveValue<string>;
        }
> = forwardRef(
    ({ errorMessage, label, my, mr, help, formControlProps, ...rest }, ref) => {
        return (
            <FormControl px="4px" my={my} mr={mr} {...formControlProps}>
                <Checkbox ref={ref} {...rest}>
                    {label}
                </Checkbox>
                {(errorMessage || help) && (
                    <FormHelperText color={!errorMessage ? "gray" : "red.600"}>
                        {!errorMessage ? help : errorMessage}
                    </FormHelperText>
                )}
            </FormControl>
        );
    },
);
