import type { FC, PropsWithChildren } from "react";
import React from "react";

import type { BoxProps } from "@chakra-ui/react";
import { Box, Text } from "@chakra-ui/react";

type TitleProps = {
    title: string;
    textStyle?: BoxProps["textStyle"];
    subtitle?: string;
    actions?: React.ReactNode;
};
export const Title: FC<PropsWithChildren<TitleProps>> = ({
    title,
    textStyle,
}) => (
    <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="flex-start"
    >
        <Text
            as="h2"
            mb="12px"
            textStyle={textStyle ?? "h4"}
            color="gray.800"
            // fontSize="16px"
            fontWeight="semibold"
        >
            {title}
        </Text>
    </Box>
);
