import { partial, pluck, sort } from "ramda";

import { useQuery } from "@tanstack/react-query";

import type { APIContextValue } from "@app/contexts/APIContext/ApiContextProvider.tsx";
import { useAPI } from "@app/contexts/APIContext/useApiContext.tsx";
import { QueryKeys } from "@app/domain/api/queryKeys.ts";
import { collectAllPages } from "@app/domain/api/tools/getAllData.ts";
import type { components, operations } from "@app/domain/api/types/v1";

const entityKey = QueryKeys.artifact;
const entityKeyData = QueryKeys.artifactData;

type ArtifactModel = components["schemas"]["Artifact"];

export type { ArtifactModel };

const getAllArtifacts = async (
    api: APIContextValue,
    params: operations["getArtifacts"]["parameters"],
) => {
    return await api.client.GET("/organizations/{organizationId}/artifacts", {
        params: params,
    });
};

const useGetAll = (request?: {
    params: operations["getArtifacts"]["parameters"];
}) => {
    const api = useAPI();
    return useQuery({
        queryKey: [entityKey, JSON.stringify(request)],
        staleTime: Infinity,
        queryFn: async () => {
            if (!request) {
                return null;
            }

            const fetcher = partial(getAllArtifacts, [api]);
            try {
                const artifacts = await collectAllPages<
                    ArtifactModel,
                    operations["getArtifacts"]["parameters"],
                    typeof fetcher
                >(fetcher, request.params);
                return artifacts;
            } catch {
                throw new Error("Could not fetch admin requests");
            }
        },
    });
};

const useGetData = (params?: {
    organizationId: string;
    artifactId?: string;
}) => {
    const api = useAPI();
    return useQuery({
        staleTime: Infinity,
        queryKey: [entityKeyData, JSON.stringify(params)],
        queryFn: async () => {
            if (!params?.artifactId || !params.organizationId) {
                return null;
            }

            const response = await api.client.GET(
                "/organizations/{organizationId}/artifacts/{artifactId}/data",
                {
                    params: {
                        path: {
                            organizationId: params.organizationId,
                            artifactId: params.artifactId,
                        },
                    },
                },
            );

            if (response.response.ok) {
                return response.data;
            }

            return undefined;
        },
    });
};

const defaultSorter = (a: string, b: string) => {
    if (a < b) {
        return -1;
    }

    if (a === b) {
        return 0;
    }
    return 1;
};

const useGetAllData = (params?: {
    isDisabled?: boolean;
    organizationId: string;
    artifacts?: { id: string }[];
}) => {
    const api = useAPI();

    const artifactIds = pluck("id", params?.artifacts ?? []);

    const artifactsSorted = sort(defaultSorter, artifactIds);
    return useQuery({
        queryKey: [
            entityKeyData,
            params?.organizationId,
            artifactsSorted,
            params?.isDisabled,
            params?.artifacts,
        ],
        staleTime: Infinity,
        queryFn: async () => {
            if (params?.isDisabled) {
                return null;
            }

            const artifacts = params?.artifacts;
            const organizationId = params?.organizationId;
            if (!artifacts?.length || !organizationId) {
                return null;
            }

            const promisses = artifacts.map(({ id }) => {
                return api.client.GET(
                    "/organizations/{organizationId}/artifacts/{artifactId}/data",
                    {
                        params: {
                            path: {
                                organizationId: organizationId,
                                artifactId: id,
                            },
                        },
                    },
                );
            });

            const responses = await Promise.all(promisses);

            if (
                responses.some(
                    (r) =>
                        !r.response.ok || responses.length !== artifacts.length,
                )
            ) {
                throw new Error("Could not fetch artifact data");
            }

            return responses.map(({ data }, idx) => ({
                ...artifacts[idx],
                ...data,
            }));
        },
    });
};

export const Artifact = {
    useGetAll,
    useGetAllData,
    useGetData,
};
