import type { FC } from "react";

import type { ShippingStatusType } from "@app/domain/api/package.ts";
import { ShippingStatus } from "@app/domain/api/package.ts";

import { SingleSelect } from "@mt-components/Input/Select/Select.tsx";

type Props = {
    value?: ShippingStatusType;
    onChange: (value?: ShippingStatusType) => void;
};

const statusOptions = [
    {
        label: "All",
        value: null,
    },
    {
        label: "draft",
        value: ShippingStatus.DRAFT,
    },
    {
        label: "dispatched",
        value: ShippingStatus.DISPATCHED,
    },
    {
        label: "received",
        value: ShippingStatus.RECEIVED,
    },
    {
        label: "lost",
        value: ShippingStatus.LOST,
    },
];

export const ShippingStatusFilter: FC<Props> = ({ value, onChange }) => {
    const options = statusOptions.map((o) => ({
        ...o,
        rawLabel: o.label,
    }));
    return (
        <SingleSelect<{
            label: string;
            rawLabel: string;
            value: ShippingStatusType | null;
        }>
            value={options.find((o) => o.value === value)}
            onChange={(newValue) => onChange(newValue?.value ?? undefined)}
            placeholder="Filter by status"
            options={options}
        />
    );
};
