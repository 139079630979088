// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { FC, ReactNode } from "react";
import React from "react";

import classnames from "classnames";
import { bem } from "lib";
import PropTypes from "prop-types";

import { Box, Text } from "@chakra-ui/react";

import { getMultiValueText } from "lib/constants.tsx";
import { FontFamily } from "@mt-design/fontStyles.ts";

import MenuPanel from "../containers/MenuPanel";

// export class FieldDelete extends Component {
//     render() {
//         const { onClick } = this.props;
//         return (
//             <Box bg="red.500" className="field__delete" onClick={onClick}>
//                 <CloseIcon />
//             </Box>
//         );
//     }
// }
// FieldDelete.propTypes = {
//     onClick: PropTypes.func,
// };

export type FieldProps = {
    labelWidth?: number;
    center?: boolean;
    label?: ReactNode;
    units?: string;
    multiValued?: boolean;
    suppressMultiValuedMessage?: boolean;
    children?: ReactNode;
    extraComponent?: ReactNode;
    fieldContainerClassName?: string;
};

const Field: FC<FieldProps> = (
    {
        center = false,
        children,
        label,
        multiValued = false,
        suppressMultiValuedMessage,
        units,
        extraComponent,
        fieldContainerClassName,
        labelWidth,
    },
    context,
) => {
    let fieldClass;
    if (!label) {
        fieldClass = classnames("field__no-title", {
            "field__no-title--center": center,
        });
    } else {
        fieldClass = classnames("field__widget", {
            "field__widget--units": Boolean(units),
        });
    }

    let tooltip = context.attr;
    if (context.description) {
        tooltip +=
            " – " + context.description.replace(/`/g, '"').replace(/\*/g, '"');
    }

    // const containerClassName = classnames(bem('field'), {
    //   [fieldContainerClassName]: Boolean(fieldContainerClassName),
    // });

    return (
        <Box
            display="flex"
            alignItems="center"
            fontSize="12px"
            fontFamily={FontFamily.PRIMARY}
            // justifyContent="center"
            minH="32px"
            p="6px 0"
            borderTop="solid 1px"
            borderColor="gray.100"
            // className={containerClassName}
        >
            {label ? (
                <div
                    className={bem("field", "title")}
                    style={
                        // labelWidth ? { minWidth: labelWidth ?? 90 + "px" } : {}
                        // labelWidth ? { minWidth: labelWidth ?? 90 + "px" } : {}
                        { minWidth: "90px" }
                    }
                >
                    {context.showFieldTooltips ? (
                        <Box
                            width="90px"
                            pl="12px"
                            lineHeight="sm"
                            color="gray.800"
                            aria-label={tooltip}
                            data-microtip-position="bottom-right"
                            data-microtip-size="large"
                            role="tooltip"
                        >
                            <Text textStyle="T3">{label}</Text>
                        </Box>
                    ) : (
                        <Box
                            width="90px"
                            pl="12px"
                            lineHeight="sm"
                            color="gray.800"
                        >
                            <Text>{label}</Text>
                        </Box>
                    )}
                </div>
            ) : null}
            <div className={fieldClass}>
                {children}
                {extraComponent ? extraComponent : null}
                {multiValued && !suppressMultiValuedMessage ? (
                    <MenuPanel
                        label={getMultiValueText("title")}
                        ownline
                        question
                    >
                        <div className="info__title">
                            {getMultiValueText("title")}
                        </div>
                        <div className="info__text">
                            {getMultiValueText("text")}
                        </div>
                        <div className="info__sub-text">
                            {getMultiValueText("subText")}
                        </div>
                    </MenuPanel>
                ) : null}
            </div>
            {units ? (
                <div className={bem("field", "units")}>
                    <div className={bem("field", "units-text")}>{units}</div>
                </div>
            ) : null}
        </Box>
    );
};

// Field.propTypes = {
//     labelWidth: PropTypes.number,
//     center: PropTypes.bool,
//     label: PropTypes.any,
//     units: PropTypes.string,
//     multiValued: PropTypes.bool,
//     suppressMultiValuedMessage: PropTypes.bool,
//     children: PropTypes.node,
//     extraComponent: PropTypes.any,
//     fieldContainerClassName: PropTypes.string,
// };

Field.contextTypes = {
    description: PropTypes.string,
    attr: PropTypes.string,
    showFieldTooltips: PropTypes.bool,
};

export default Field;
