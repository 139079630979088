import { useRef } from "react";

import { Box } from "@chakra-ui/react";
import type { Table } from "@tanstack/react-table";

import type { SampleModel } from "@app/domain/api/sample.ts";
import type { SampleTasksModel } from "@app/domain/api/transformer.tsx";

import type { ColumnDef } from "@mt-components/DataTable.tsx";
import { DataTable, makeWidth } from "@mt-components/DataTable.tsx";

import { toId } from "@mt-tools/iterating/filter.ts";

const initialState = {
    sorting: [
        {
            id: "qrCode",
            desc: true,
        },
    ],
};

const columns: ColumnDef<SampleModel>[] = [
    {
        accessorKey: "name",
        header: "Sample-ID",

        // @ts-ignore
        thProps: makeWidth("120px"),
    },
    // {
    //     accessorKey: "qrCode",
    //     header: "QR-Code",
    //
    //     // @ts-ignore
    //     thProps: makeWidth("120px"),
    // },
] satisfies ColumnDef<SampleModel>[];

export const SampleSelect = (props: {
    orgId: string;
    selectedSampleId?: string;
    measurementGroupId: string;
    samples: SampleTasksModel[];
    onSelectSample: (sampleId: string) => void;
}) => {
    const tableRef = useRef<Table<SampleModel>>(null);

    return (
        <Box h="100%" maxH="760px" overflow="hidden" w="100%">
            <Box h="760px" w="220px">
                <Box h="100%" w="100%" overflow="hidden">
                    <DataTable<SampleModel>
                        variant="unstyled"
                        instanceRef={tableRef}
                        isSortable
                        getRowId={toId}
                        initialState={initialState}
                        state={{
                            rowSelection: props.selectedSampleId
                                ? {
                                      [props.selectedSampleId]: true,
                                  }
                                : {},
                        }}
                        onRowClick={(row) => {
                            props.onSelectSample(row.id);
                        }}
                        onRowSelectionChange={() => {}}
                        columns={columns}
                        data={props.samples}
                    />
                </Box>
            </Box>
        </Box>
    );
};

SampleSelect.displayName = "SampleSelect";
